import { SessionDocResponseObject } from "@aaa/emember/store-types"
import { z } from "zod"

export namespace Cybersource {
  export interface ExecutePaymentResponse {
    response: {
      status: 201 | 400 | 502
      text: string
      req: {
        method: "POST" | "GET"
        url: string
        data: string
        headers: {
          signature: string
          "user-agent": string
          date: string
          host: string
          "content-type": string
          "v-c-merchant-id": string
          digest: string
          accept: string
        }
      }
      header: {
        "cache-control": string
        "strict-transport-security": string
        "v-c-correlation-id": string
        pragma: string
        "x-opnet-transaction-trace": string
        connection: string
        "content-type": string
        expires: string
        "x-response-time": string
        "content-length": string
      }
    }
    data: {
      processorInformation: {
        cardVerification: {
          resultCodeRaw: string // "X",
          resultCode: string // "X"
        }
        merchantNumber: string // "000178056838992",
        avs: {
          code: string // "2"
          codeRaw: string // "I1"
        }
        responseCode: string // "63"
        approvalCode: string
        networkTransactionId: string
        transactionId: string
      }
      pointOfSaleInformation: {
        terminalId: string // "08077387"
      }
      errorInformation: {
        message: string // "Decline - General decline of the card. No other information provided by the issuing bank.",
        reason: string // "PROCESSOR_DECLINED"
      }
      clientReferenceInformation: {
        code: string // "JOINj1lGryuXjKpPkQL"
      }
      paymentInsightsInformation: {
        responseInsights: {
          categoryCode: string // "02",
          category: string // "ISSUER_CANNOT_APPROVE_AT_THIS_TIME"
        }
      }
      status: "AUTHORIZED" | "DECLINED" | string // "DECLINED",
      issuerInformation: {
        responseRaw: string // "0110322001800A8000020000000000000185001130001255258477000159543638484D414B574435384A36333038303737333837008000483134412020202020202020202020202020202020202020203030303030303030303030303030303030303030303030300018323253455256204E4F5420414C4C4F57454400033438200003343958"
      }
      id: string // "6697671747806591504977",
      _links: {
        self: {
          method: string // "GET",
          href: string // "/pts/v2/payments/6697671747806591504977"
        }
      }
    }
    customerId: string
    request: {
      processingInformation: {
        capture: true
        actionTokenTypes: string[]
        // "paymentInstrument",
        // "customer"
        commerceIndicator: string // "internet",
        actionList: string[]
        // "TOKEN_CREATE"
      }
      clientReferenceInformation: {
        transactionId: string // "JOINj1lGryuXjKpPkQL",
        code: string // "JOINj1lGryuXjKpPkQL"
      }
      orderInformation: {
        amountDetails: {
          totalAmount: string // "185.0",
          currency: string // "USD"
        }
        billTo: {
          lastName: string // "User",
          address2: string // "",
          country: string // "US",
          buildingNumber: string // "",
          email: string // "testfive@aaa.com",
          firstName: string // "Test",
          administrativeArea: string // "IN",
          address1: string // "10 Test Street",
          locality: string // "CityTest",
          district: string // "IN",
          postalCode: string // "46222",
          phoneNumber: string // "337-468-3783"
        }
      }
      tokenInformation: {
        transientTokenJwt: string // "eyJraWQiOiIwMzd0MFNWek1NeGpQNThqM3BqOUhOTm9tOTVPMFQ1byIsImFsZyI6IlJTMjU2In0.eyJkYXRhIjp7ImV4cGlyYXRpb25ZZWFyIjoiMjAyNCIsIm51bWJlciI6IjQxMTExMVhYWFhYWDExMTEiLCJleHBpcmF0aW9uTW9udGgiOiIwNCIsInR5cGUiOiIwMDEifSwiaXNzIjoiRmxleC8wNCIsImV4cCI6MTY2OTc2ODA3MywidHlwZSI6Im1mLTAuMTEuMCIsImlhdCI6MTY2OTc2NzE3MywianRpIjoiMUM1WEhMU1NDSDFOM0hYOThHR1pZSkU5U0NPTUpCS0RCUzJCRVE5VUlYWkREMFlCWU8wNDYzODZBMzg5RTUzMSIsImNvbnRlbnQiOnsicGF5bWVudEluZm9ybWF0aW9uIjp7ImNhcmQiOnsiZXhwaXJhdGlvblllYXIiOnsidmFsdWUiOiIyMDI0In0sIm51bWJlciI6eyJtYXNrZWRWYWx1ZSI6IlhYWFhYWFhYWFhYWDExMTEiLCJiaW4iOiI0MTExMTEifSwic2VjdXJpdHlDb2RlIjp7fSwiZXhwaXJhdGlvbk1vbnRoIjp7InZhbHVlIjoiMDQifSwidHlwZSI6eyJ2YWx1ZSI6IjAwMSJ9fX19fQ.GRks6xHhszz3cP9AUgnJ5refXVHOkw5nZ1tma0xKQXY55YvGmjnEn9SMnHXSO8On9kujBikKptm1G3A116Q8r_EBxkyGlef8nKao-U8u62mhaK7uQS4W0otKIR1DjugHli5RjPvVnBcuFPZJWoP8pB9-hpMpwqCcxZNqlZFnIYSoGEneSx9l1qlFBxLy7AjzGtqKQk1KR5IhVx_JQSAT3RuiUL9Sv95ZHRM1jsGIK7BlCFB3R7mZfCHNAF1Eg72XgIgohEq0p06GmDG0g-dHiB_jNmozIXc0-_Klx8a3JOMkGYdomHeeKLNfwgkkrlyqCBv3V-K_P48aT_x2NBSupw"
      }
    }
    orderId: string
    status?: string // "INVALID_REQUEST" actually from InvalidRequestError
    reason?: string // "DUPLICATE_REQUEST", actually from InvalidRequestError
  }

  export interface ExecutePaymentResponseObject extends SessionDocResponseObject {
    error: ExecutePaymentResponse
    response: ExecutePaymentResponse
  }

  export enum PaymentErrorReason {
    PROCESSOR_DECLINED = "PROCESSOR_DECLINED",
    EXCEEDS_CREDIT_LIMIT = "EXCEEDS_CREDIT_LIMIT",
    EXPIRED_CARD = "EXPIRED_CARD",
    GENERIC = "GENERIC",
    INSUFFICIENT_FUND = "INSUFFICIENT_FUND",
    INVALID_CVN = "INVALID_CVN",
    INVALID_DATA = "INVALID_DATA",
    PAYMENT_REFUSED = "PAYMENT_REFUSED",
    MISSING_FIELD = "MISSING_FIELD",
  }

  export const CyberSourcePaymentErrorDetailsSchema = z.object({
    reason: z.nativeEnum(PaymentErrorReason),
    field: z.string(),
  })

  export const CyberSourcePaymentErrorSchema = z.object({
    submitTimeUtc: z.string(),
    reason: z.nativeEnum(PaymentErrorReason),
    details: z.array(CyberSourcePaymentErrorDetailsSchema),
    data: z.object({
      clientReferenceInformation: z.object({
        code: z.string(),
      }),
      errorInformation: z.object({
        message: z.string(),
        reason: z.nativeEnum(PaymentErrorReason),
      }),
    }),
    id: z.string(),
    message: z.string(),
    status: z.string(),
  })

  export type CyberSourcePaymentError = z.infer<typeof CyberSourcePaymentErrorSchema>
}
