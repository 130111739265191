import { Inject, Injectable } from "@angular/core"
import firebase from "firebase/compat/app"
import "firebase/firestore"
import { combineLatestWith, debounceTime, filter, Observable, of, takeWhile, tap, withLatestFrom } from "rxjs"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { WINDOW } from "@ng-web-apis/common"
import { JoinRenewService, OpStatus } from "../../join-renew.service"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../services/state"
import {
  connectSuiteMembershipCodes,
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteRecostValidateChangeLevelEventPayload,
  MembershipConnectSuiteRecostValidateChangeLevelResponse,
} from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import { EventName } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  AccountMembershipView, CheckoutStep, HOOSIER_RX_STATE, HoosierService, HoosierState,
  SessionDocResponse, SessionDocResponseObject, SummaryItem,
} from "../hoosier.service"
import { FormGroup } from "@angular/forms"
import { PricePreviewsService } from "./price-previews"
import { Membership } from "../services/member-info"
import { AlertGroup, AlertMessage, AlertMessageService, AlertType } from "../../../../services/alert-message"
import { ValidationData } from "../services/validation-data"
import Timestamp = firebase.firestore.Timestamp
import { AccountStatus } from "../components/flows/account"
import { FormStatus } from "../../../../services/form"
import { NzNotificationService } from "ng-zorro-antd/notification"

export interface ValidateLevelResponseObject extends SessionDocResponseObject {
  error: ValidateLevelError
  // meta: SessionDocMeta
  response: ValidateLevelResponse
}

export interface ValidateLevelResponse extends MembershipConnectSuiteRecostValidateChangeLevelResponse {
  executionData: string
  validationData: ValidationData
}

export interface ValidateLevelError {
  // errorCode: number
  // error: string
}

interface ValidateMembership extends Membership {
  // costSummary?: {
  //   autoRenewDiscount: string
  //   discountAmount: string
  //   solicitationDiscount: string
  //   solicitationRequiresAutoRenew: string
  //   totalCost: string
  // }
}

/**
 * recostValidateRenew for:
 *   quickRenew (with memberNumber, lastName, and postalCode)
 *   myAccountRenew (with logged in member)
 */

@Injectable({
  providedIn: "root",
})
export class ValidateLevelService {
  form: FormGroup
  numberOfRetries: number = 0

  constructor(
    private pricePreviewsService: PricePreviewsService,
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private joinRenewService: JoinRenewService,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
    private alertMessageService: AlertMessageService,
    private notification: NzNotificationService,
  ) {
    this.form = hoosierService.form
    rxEffects.register(this.recostValidateLevel$)
    rxEffects.register(this.VALIDATE_KEY$)
    rxEffects.register(this.VALIDATE$)
    rxEffects.register(this.retryOnError$)
    rxEffects.register(this.activeChange$)
  }

  get recostValidateLevel$(): Observable<any> {
    const autoRenewForm = this.form.get(["changeLevelPayload", "autoRenew"])
    if (autoRenewForm) {
      return autoRenewForm.valueChanges
        .pipe(
          debounceTime(100), // debounce is required, or else we get previous autoRenew value
          tap(autoRenew => {
            if (autoRenew !== null) {
              const changeLevelPayload: MembershipConnectSuiteRecostValidateChangeLevelEventPayload = this.form.get("changeLevelPayload")?.value
              if (changeLevelPayload) {
                this.recostValidateLevel(changeLevelPayload)
              }
            }
          }),
        )
    }
    return of(null)
  }

  retryOnError$ = this.hoosierState.select("VALIDATE_LEVEL_STATUS")
    .pipe(
      takeWhile(() =>  this.numberOfRetries < 3),
      debounceTime(10000),
      tap(VALIDATE_LEVEL_STATUS => {
        if (VALIDATE_LEVEL_STATUS === OpStatus.IS_ERROR) {
          this.alertMessageService.set(AlertMessage.ERROR_RETRY, AlertType.INFO)
          const changeLevelPayload = this.form.get("changeLevelPayload")?.value
          if (changeLevelPayload) {
            this.recostValidateLevel(changeLevelPayload, true)
          }
        }
      }),
    )

  VALIDATE_KEY$ = this.hoosierState.select("VALIDATE_LEVEL_KEY")
    .pipe(
      combineLatestWith(
        this.hoosierState.select("sessionDoc", "responses", "membership", "connectsuite"),
      ),
      tap(([VALIDATE_CHANGE_LEVEL_KEY, connectsuite]: [string | null, SessionDocResponse]) => {
        if (VALIDATE_CHANGE_LEVEL_KEY && connectsuite[VALIDATE_CHANGE_LEVEL_KEY]) {
          this.hoosierState.set("VALIDATE_LEVEL_KEY", () => null)

          const responseTime = Timestamp.now().toMillis() - parseInt(VALIDATE_CHANGE_LEVEL_KEY)
          if (this.globalState.get("adminUser")) {
            console.log(responseTime, "milliseconds - VALIDATE_LEVEL")
          }
          const validateResponseObject = connectsuite[VALIDATE_CHANGE_LEVEL_KEY] as ValidateLevelResponseObject
          const isError = validateResponseObject.meta.isError

          if (!isError) {
            this.alertMessageService.clearAll(AlertGroup.ERROR)

            // this.hoosierState.set("activeSection", () => AccountSection.MEMBERSHIP)
            // this.hoosierState.set("activeView", () => AccountMembershipView.CHANGE_LEVEL_CONFIRMATION)

            this.hoosierState.set("VALIDATE_LEVEL_STATUS", () => OpStatus.SUCCESS)
            this.hoosierState.set("VALIDATE_LEVEL", () => validateResponseObject)
            this.hoosierState.set("VALIDATE_LEVEL_ERROR", () => null)
          }

          if (isError) {
            this.hoosierState.set("VALIDATE_LEVEL_STATUS", () => OpStatus.IS_ERROR)
            this.hoosierState.set("VALIDATE_LEVEL", () => null)
            this.hoosierState.set("VALIDATE_LEVEL_ERROR", () => validateResponseObject)
          }
        }
      }),
    )

  VALIDATE$ = this.hoosierState.select("VALIDATE_LEVEL")
    .pipe(
      filter((response): response is ValidateLevelResponseObject => !!response),
      withLatestFrom(
        this.hoosierState.select("activeView"),
        this.hoosierState.select("activeCheckoutStep"),
        this.hoosierState.select("accountDetails", (accountDetails) => accountDetails.status),
      ),
      tap(([response, activeView, activeCheckoutStep, membershipStatus ]) => {
        const { validationData} = response.response;
        const totalCost = Number(validationData.attributes.totalCost)
        const membershipType = validationData.membership.primaryMember.attributes.membershipType
        const membershipCode = connectSuiteMembershipCodes.find(membershipCode => membershipCode.membershipType === membershipType)
        const balance = Number(validationData.attributes.balance)

        if (activeView === AccountMembershipView.LEVEL && activeCheckoutStep === CheckoutStep.VALIDATE) {
          const summaryItems: SummaryItem[] = [];

          if (membershipStatus === AccountStatus.PENDING) {
            const primaryMember = {
              amount: Number(validationData.membership.primaryMember.duesComponent.attributes.componentAmount),
              label: "AAA " + membershipCode?.label + " Primary",
            }
            const associateMembers  = (validationData.membership.associateMember || [])?.reduce<SummaryItem[]>((acc, associate) => ([...acc, {
              label: "AAA " + membershipCode?.label + " Associate",
              amount: Number(associate.duesComponent.attributes.componentAmount),
            }]), [])

            summaryItems.push(primaryMember, ...associateMembers);
          }

          if (totalCost !== undefined && membershipCode?.label) {
            this.form.get(["paymentPayload", "executionData", "amountDetails", "totalAmount"])?.setValue(balance)
            this.hoosierState.set("summary", state => {
              state.summary.totalCost = totalCost
              state.summary.balance = balance
              state.summary.membershipCodeLabel = membershipCode?.label
              state.summary.items = summaryItems

              return state.summary
            })
            this.hoosierState.set("activeCheckoutStep", () => CheckoutStep.PAYMENT)
          }
        }
      }),
    )

  activeChange$ = this.hoosierState.select("activeChange").pipe(
    combineLatestWith(
      this.hoosierState.select("formStatus"),
      this.hoosierState.select("VALIDATE_LEVEL_STATUS"),
      this.hoosierState.select("activeCheckoutStep"),
      this.hoosierState.select("activeDestination"),
    ),
    tap(([activeChange, formStatus, VALIDATE_LEVEL_STATUS, activeCheckoutStep, activeDestination]) => {
      if (
        activeChange &&
        activeCheckoutStep === CheckoutStep.PAYMENT &&
        formStatus !== FormStatus.PENDING &&
        VALIDATE_LEVEL_STATUS === OpStatus.SUCCESS
      ) {
        this.hoosierState.set("activeChange", () => null)

        const hasError = this.paymentSectionHasError()
        if (hasError) {
          console.log(this.form)
        }
        if (!hasError) {
          this.hoosierState.set("activeCheckoutStep", () => activeDestination as CheckoutStep)
        }
      }
    }),
  )

  paymentSectionHasError() {
    let hasError = false
    if (!this.form?.get(["creditCard", "expirationMonth"])?.value) {
      this.notification.blank("", "Please choose your Credit Card Expiration Month")
      hasError = true
    }
    if (!this.form?.get(["creditCard", "expirationYear"])?.value) {
      this.notification.blank("", "Please choose your Credit Card Expiration Year")
      hasError = true
    }
    if (!this.form?.get(["creditCard", "numberIsValid"])?.value) {
      this.notification.blank("", "Please enter a valid credit card number")
      hasError = true
    }
    if (!this.form?.get(["creditCard", "securityCodeIsValid"])?.value) {
      this.notification.blank("", "Please enter 3-digit credit card verification code")
      hasError = true
    }
    if (
      !this.form?.get("useMemberInfoForBilling")?.value &&
      !this.form?.get(["paymentPayload", "executionData", "billTo"])?.valid
    ) {
      hasError = true
    }

    return hasError
  }

  recostValidateLevel(
    changeLevelPayload: MembershipConnectSuiteRecostValidateChangeLevelEventPayload,
    retry?: boolean,
  ): void {
    if (retry) {
      this.numberOfRetries++
    }
    if (!retry) {
      this.numberOfRetries = 0
    }
    const responseKeyTimestampString = Timestamp.now().toMillis().toString()
    this.hoosierState.set("VALIDATE_LEVEL_KEY", () => responseKeyTimestampString)
    this.hoosierState.set("VALIDATE_LEVEL_STATUS", () => OpStatus.RUNNING)

    changeLevelPayload.responseKey = responseKeyTimestampString
    changeLevelPayload.method = MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_LEVEL

    if (this.globalState.get("adminUser")) {
      // console.log(changeLevelPayload)
    }
    this.joinRenewService.sendToEventCoordinatorReceiver(EventName.MEMBERSHIP_QUERY, changeLevelPayload)
      .then(response => {
        if (this.globalState.get("adminUser")) {
          console.log(EventName.MEMBERSHIP_QUERY + "---" + MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_LEVEL + "---" + responseKeyTimestampString + "---" + response)
        }
      })
      .catch(error => {
        this.hoosierState.set("VALIDATE_LEVEL_STATUS", () => OpStatus.FAILED)
        // console.log(error)
      })
  }

}
