import { Injectable } from "@angular/core"
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/compat/firestore"
import { BehaviorSubject, Observable, of, Subscription } from "rxjs"
import { tap } from "rxjs/operators"

export interface SmartWeatherSession {
  memberNumber: string | undefined
  pusherId: number | undefined
  updated: number,
  state: string,
}

@Injectable({
  providedIn: "root"
})
export class StateSyncService {
  private stateSubject: BehaviorSubject<SmartWeatherSession> | BehaviorSubject<null> | undefined
  state$: Observable<SmartWeatherSession | null> | undefined
  private swSessionCollectionRef: AngularFirestoreCollection<SmartWeatherSession | null> | undefined
  private swSessionDocRef: AngularFirestoreDocument<SmartWeatherSession | null> | undefined
  private swSession$: Observable<SmartWeatherSession | null | undefined> | undefined
  private swSessionSubscription: Subscription | undefined
  memberNumber: string | undefined
  pusherId: number | undefined

  constructor(
    private afs: AngularFirestore,
  ) {
  }

  setupService(clubId: string, memberNumber: string): void {
    this.memberNumber = memberNumber
    this.pusherId = (Date.now() * 10000) + Math.floor(Math.random() * Math.floor(10000))
    this.stateSubject = new BehaviorSubject(null)
    this.state$ = this.stateSubject.asObservable()

    this.swSessionCollectionRef = this.afs
      .collection("wss-aaa-web")
      .doc(clubId)
      .collection("apps")
      .doc("smart-weather")
      .collection("sessions")
    this.swSessionDocRef = this.swSessionCollectionRef
      .doc(memberNumber)
    this.swSession$ = this.swSessionDocRef
      .valueChanges()
      .pipe(
        tap((sessionDoc: SmartWeatherSession | undefined | null) => {
          if (sessionDoc && this.stateSubject) {
            const state = JSON.parse(sessionDoc.state)
            if (sessionDoc.pusherId !== this.pusherId) {
              // console.log(state)
              this.stateSubject.next(state as never)
            }
          }
        })
      ) || of(null)
    this.swSessionSubscription = this.swSession$.subscribe()
  }

  unsubscribe(): void {
    if (this.swSessionSubscription) {
      this.swSessionSubscription?.unsubscribe()
    }
  }

  pushState(state: never): void {
    // if (state.context.locations) console.log('location.name[]: ' + [...state.context.locations.map(location => location.name)])
    const stateDoc: SmartWeatherSession = {
      memberNumber: this.memberNumber,
      pusherId: this.pusherId,
      updated: Date.now(),
      state: JSON.stringify(state),
    }
    this.swSessionDocRef
      ?.set(stateDoc, { merge: true })
      .then(() => {
        //
      }).catch(() => {
      //
    })
  }

}
