import { Component, EventEmitter, inject, Input, Output } from "@angular/core"
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms"
import { NzIconModule } from "ng-zorro-antd/icon"
import { CommonModule } from "@angular/common"
import { LetModule } from "@ngrx/component"
import { FormInputComponent } from "../form/controls/input"
import { FormSelectComponent } from "../form/controls/select"
import { ButtonComponent } from "../ava-button/ava-button.component"
import { FormDateComponent } from "../form/controls/date"
import { FormPasswordComponent } from "../form/controls/password"
import { FormRadioComponent } from "../form/controls/radio"
import { FormTelComponent } from "../form/controls/tel"
import { GiftRecipientInfoFormVm } from "./gift-recipient-info-form.vm"
import { GiftRecipientForm } from "./gift-recipient-info-form.model"
import { MembershipAssociateFormComponent } from "../membership-associate-form"
import { FormCheckBoxComponent } from "../form/controls/checkbox"
import { NzToolTipModule } from "ng-zorro-antd/tooltip"
import { getGiftRecipientFormErrors } from "../../../store/form-messages"
import { Store } from "@ngrx/store"
import { AvaIconComponent } from "../ava-icon/ava-icon.component"
import { AppStore } from "@aaa/emember/store-types"
import { nameSuffix } from "../../../constants/name-suffix"
import { FormTextAreaComponent } from "../form/controls/textarea"
import { sendCardTo } from "../../../constants/send-card-to"
import { getMedicalPlanText } from "@aaa/emember/store-membership"
import { AccidentalMedicalCoverageComponent } from "../accidental-medical-coverage/accidental-medical-coverage"
import { AvaDialogService } from "../services/ava-dialog.service"
import { SmartyAddressComponent } from "../form/controls/smarty-address.component"

@Component({
  selector: "ava-gift-recipient-info-form",
  templateUrl: "./gift-recipient-info-form.html",
  styleUrls: ["./gift-recipient-info-form.scss"],
  imports: [
    FormInputComponent,
    FormSelectComponent,
    ReactiveFormsModule,
    NzIconModule,
    ButtonComponent,
    FormDateComponent,
    FormPasswordComponent,
    FormRadioComponent,
    FormTelComponent,
    LetModule,
    CommonModule,
    MembershipAssociateFormComponent,
    FormCheckBoxComponent,
    NzToolTipModule,
    AvaIconComponent,
    FormTextAreaComponent,
    SmartyAddressComponent,
  ],
  standalone: true,
})
export class GiftRecipientInfoFormComponent {
  dialog = inject(AvaDialogService)
  formVm = inject(GiftRecipientInfoFormVm)
  controlContainer = inject(ControlContainer)
  store = inject(Store<AppStore>)
  @Input() memberStates = []
  @Input() showGiftOption: boolean | null = false
  @Input() showAccidentMedicalPlan: null | boolean = false
  @Input() accidentMedicalPlanPrice = 0
  @Input() showAddressAutocomplete = false
  @Output() accidentMedicalChanged = new EventEmitter()
  readonly nameSuffix = nameSuffix
  readonly sendCardTo = sendCardTo
  errorMessages$ = this.store.select(getGiftRecipientFormErrors)
  medicalPlanText$ = this.store.select(getMedicalPlanText)
  get formGroup(): FormGroup<GiftRecipientForm> {
    if (this.controlContainer) {
      return this.controlContainer.control as FormGroup<GiftRecipientForm>
    }

    return this.formVm.create()
  }

  tooltipClicked() {
    this.dialog.openComponent({
      nzContent: AccidentalMedicalCoverageComponent,
      nzFooter: null,
    })
  }
}
