<div #componentWidth>
  <ng-container *ngIf="globalState$ | async as g">
    <ng-container *ngIf="hoosierState$ | async as h">
      <div
        style="display: flex; gap: 16px; flex-direction: column; border: none; outline: none; border-radius: 10px;"
        [style.padding.px]="invalidFlexMicroForm || h.PAYMENT_ERROR ? 20 : undefined"
        [style.box-shadow]="invalidFlexMicroForm || h.PAYMENT_ERROR ? 'inset 0 0 0 2px red' : undefined"
      >

        <!--
            <div style="display: flex; gap: 10px">
              <ava-hoosier-input style="flex: 1" [options]="inputOptions.firstName"></ava-hoosier-input>
              <ava-hoosier-input style="flex: 1" [options]="inputOptions.lastName"></ava-hoosier-input>
            </div>
        -->

        <div
          style="display: flex; gap: 10px"
          [style.flex-direction]="flexDirection"
        >
          <ng-container *ngIf="!g.environment.ngServe || g.environment.emulator">
            <ava-hoosier-payment-flex-micro-form
              style="flex: 1"
              *ngIf="h.PAYMENT_CAPTURE_CONTEXT"
              [flexDirection]="flexDirection"
            ></ava-hoosier-payment-flex-micro-form>
            <ng-container *ngIf="!h.PAYMENT_CAPTURE_CONTEXT">
              <nz-skeleton-element nzType="input" [nzActive]="true" style="flex: 1; width: 100%"></nz-skeleton-element>
              <nz-skeleton-element nzType="input" [nzActive]="true" style="flex: 1; width: 100%"></nz-skeleton-element>
            </ng-container>
          </ng-container>
        </div>
        <div
          style="display: flex; gap: 10px"
          [style.flex-direction]="flexDirection"
        >
          <ava-hoosier-select
            style="flex: 1" [options]="options.expirationMonth"
          ></ava-hoosier-select>
          <ava-hoosier-select
            style="flex: 1" [options]="options.expirationYear"
          ></ava-hoosier-select>
        </div>

        <ng-container *ngIf="invalidFlexMicroForm">
          <div *ngIf="form && !form.get(['creditCard', 'numberIsValid']).value" style="color: red">
            {{ options.creditCardNumberIsValid.errorMessage }}
          </div>
          <div *ngIf="form && !form.get(['creditCard', 'securityCodeIsValid']).value" style="color: red">
            {{ options.creditCardSecurityCodeIsValid.errorMessage }}
          </div>
        </ng-container>

        <ng-container
          *ngIf="form.get(['creditCard', 'numberIsValid']).errors?.notAllowedCartType;"
        >
          <div style="color: red">
            This credit card type is not accepted. Please use Visa, Mastercard,
            or American Express.
          </div>
        </ng-container>

        <ng-container *ngIf="h.PAYMENT_ERROR">
          <div>
            {{ h.PAYMENT_ERROR_MESSAGE }}
          </div>
        </ng-container>
      </div>

    </ng-container>
  </ng-container>
</div>
