<style>
  img {
    width: 100%;
    height: auto;
  }

  .user-info__contact-details {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 50% 50%;
  }

  @media (max-width: 760px) {
    .user-info__contact-details {
      grid-template-columns: 100%;
    }
  }
</style>
<div #container>
  <div
    *ngIf="containerWidth && containerWidth >= 760"
    style="text-align: center; margin: 10px; font-size: 30px; font-weight: 400"
  >
    Complete your appointment
  </div>

  <form
    nz-form
    nzLayout="vertical"
    *ngIf="agent"
    (ngSubmit)="formSubmit(formService.form[formId])"
    [formGroup]="formService.form[formId]"
  >
    <div style="display: grid; grid-gap: 20px; justify-items: center; padding: 40px" [ngStyle]="gridTemplateColumns">
      <div style="width: 210px">
        <img
          src="{{ agent.value.image }}"
          [alt]="'Travel Agent: ' + agent.value.fullName"
          style="border-radius: 10px"
        />
      </div>

      <div style="width: 100%; display: grid; grid-gap: 10px">
        <div style="font-weight: 200; display: grid; grid-gap: 20px; align-content: flex-start">
          <div>
            <div style="font-size: 24px; font-weight: 500">{{ agent.value.fName }} {{ agent.value.lName }}</div>
            <div *ngFor="let type of agentTypes">
              {{ type.text }}
            </div>
          </div>
          <div *ngIf="agentOffice">
            <div *ngIf="agentOffice.name" style="font-size: 20px; font-weight: 500">
              {{ agentOffice.name }}
            </div>
            <div>
              {{ agentOffice.addrLine1 }}
            </div>
            <div>
              {{ agentOffice.addrLine2 }}
            </div>
            <div>
              <a href="tel:{{ agent.value.phone }}">{{ agent.value.phone }}</a>
            </div>
          </div>
        </div>

        <div style="font-size: 24px; font-weight: 500">Your Contact Details</div>

        <div class="user-info__contact-details">
          <nz-form-item>
            <nz-form-label nzFor="firstName"> First Name </nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please use letters only.">
              <input
                nz-input
                type="text"
                id="firstName"
                formControlName="firstName"
                autocomplete="given-name"
                (ngModelChange)="ngModelChangeFirstName($event, validating)"
              />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzFor="lastName"> Last Name </nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please use letters only.">
              <input
                nz-input
                type="text"
                id="lastName"
                formControlName="lastName"
                autocomplete="family-name"
                (ngModelChange)="ngModelChangeLastName($event, validating)"
              />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzFor="email"> Email </nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please enter a valid email address.">
              <input
                nz-input
                id="email"
                formControlName="email"
                autocomplete="email"
                (ngModelChange)="ngModelChangeEmail($event, validating)"
              />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzFor="phone"> Phone </nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please enter a valid (10 digit) phone number.">
              <input
                nz-input
                id="phone"
                formControlName="phone"
                autocomplete="tel"
                (ngModelChange)="ngModelChangePhone($event, validating)"
              />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngIf="formValues.appMode === 'TRAVEL'">
            <nz-form-label> Estimated Departure </nz-form-label>
            <nz-form-control>
              <nz-range-picker formControlName="departure" [nzDisabledDate]="disabledDate"></nz-range-picker>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div style="font-size: 24px; font-weight: 500">Additional Comments</div>

        <div>
          <nz-form-item>
            <nz-form-label nzFor="comment" style="visibility: hidden"> Comment </nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="comment.errors?.errorMessage">
              <textarea nz-input rows="4" id="comment" formControlName="comment" autocomplete="off"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <ava-appointment-card [formId]="formId">
        <button nz-button type="submit" nzType="primary" [disabled]="formService.form[formId].invalid && validating">
          BOOK NOW
        </button>
      </ava-appointment-card>
    </div>
  </form>
</div>
