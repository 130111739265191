import { formatDate } from "@angular/common"

export const parseDateToApi = (usDateFormat?: string) => {
  try {
    const result = formatDate(usDateFormat || "", "yyyyMMdd", "en")

    return result || ""
  } catch (e) {
    return ""
  }
}
