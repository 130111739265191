import { createFeatureSelector, createSelector } from "@ngrx/store"
import { RouterReducerState, getRouterSelectors } from "@ngrx/router-store"
import { RouterStateUrl } from "./custom-route-serializer"
import has from "lodash/has"

export const ROUTER_FEATURE_KEY = "router"

const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>(ROUTER_FEATURE_KEY)
export const {
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getRouterSelectors(getRouterState)

export const getRouterData = createSelector(getRouterState, (state) => state.state.data)
export const getRouterQueryParams = createSelector(getRouterState, (state) => state?.state?.queryParams || {})

export const isUrlStartsWith = (startsWith: string) => createSelector(selectUrl, (url) => url.startsWith(startsWith))
export const getBreadcrumbData = createSelector(getRouterData, (data) => data?.breadcrumb || [])
export const getShowGiftImage = createSelector(getRouterData, (data) => !!data?.showImage)

export const getExistQueryParam = (name: string) =>
  createSelector(getRouterQueryParams, (queryParams) => has(queryParams, name))

export const getMembershipUpgradeQueryParams = createSelector(getRouterQueryParams, (queryParams) => ({
  level: queryParams.level,
  rv: queryParams.rv === "true",
}))
export const getQueryParamMembershipLevel = createSelector(getRouterQueryParams, (queryParams) => {
  const rv = queryParams?.rv === "true"
  const membershipLevel = queryParams.membershiplevel && String(queryParams.membershiplevel).toUpperCase()

  return { membershipLevel, rv }
})

export const getQueryPromoCode = createSelector(
  getExistQueryParam("promo"),
  getRouterQueryParams,
  (promoCode, queryParams) => (promoCode ? (queryParams["promo"] as string) : ""),
)

export const getCouponCode = createSelector(getRouterQueryParams, (queryParams) => {
  return queryParams["couponcode"] || queryParams["couponCode"] || ""
})

export const getProgramCode = createSelector(getRouterQueryParams, (queryParams) => {
  return queryParams["programcode"] || queryParams["programCode"] || ""
})

export const getQueryCouponCode = createSelector(getCouponCode, getProgramCode, (couponCode, programCode) => ({
  couponCode,
  programCode,
}))
