import { createFeatureSelector, createSelector } from "@ngrx/store"
import { PaymentInitializeStatus, PaymentState, PaymentStatus } from "./payment.model"
import { getClubId } from "@aaa/emember/store-membership"
import { ClubApp } from "@aaa/emember/types"

export const PAYMENT_FEATURE_KEY = "payment"
export const getPaymentState = createFeatureSelector<PaymentState>(PAYMENT_FEATURE_KEY)
export const getPaymentType = createSelector(getPaymentState, ({ paymentType }) => paymentType)
export const getPaymentFormValues = createSelector(getPaymentState, ({ formValues }) => formValues)
export const getPaymentAutoRenewValue = createSelector(getPaymentFormValues, (formValues) => !!formValues?.autoRenew)
export const getSaveARMessage = createSelector(getClubId, (clubId) => {
  return `When it's time to renew your membership, your annual dues will automatically be charged to the credit card
  approximately ${clubId === ClubApp.MidStates ? "30" : "20"} days prior to your membership expiration date.`
})
export const getPaymentStatus = createSelector(getPaymentState, ({ paymentStatus }) => paymentStatus)
export const getPaymentToken = createSelector(getPaymentState, ({ paymentToken }) => paymentToken)
export const getPaymentGenerateStatus = createSelector(getPaymentState, ({ generateStatus }) => generateStatus)
export const getPaymentSessionToken = createSelector(getPaymentState, ({ sessionToken }) => sessionToken)
export const getPaymentInitializeStatus = createSelector(getPaymentState, ({ initializeStatus }) => initializeStatus)
export const getIsPaymentGenerating = createSelector(
  getPaymentGenerateStatus,
  (status) => status === PaymentInitializeStatus.RUNNING,
)
export const getPayment = createSelector(getPaymentToken, getPaymentFormValues, (token, formValues) => ({
  token,
  formValues,
}))
export const getIsLoadingPaymentForm = createSelector(
  getPaymentState,
  ({ initializeStatus, generateStatus }) =>
    initializeStatus === PaymentInitializeStatus.RUNNING || generateStatus === PaymentInitializeStatus.RUNNING,
)
export const getPaymentIsReady = createSelector(getPaymentStatus, (status) => status === PaymentStatus.READY)
export const getPaymentInitializedSucceeded = createSelector(
  getPaymentIsReady,
  getPaymentSessionToken,
  getPaymentInitializeStatus,
  getPaymentType,
  (isReady, session, initializedStatus, paymentType) => ({
    isReady,
    session,
    initializedStatus,
    paymentType,
  }),
)
