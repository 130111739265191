import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { first, forkJoin, from, map, of, switchMap, withLatestFrom } from "rxjs"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Store } from "@ngrx/store"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import firebase from "firebase/compat/app"
import { catchError } from "rxjs/operators"
import { getClubId } from "@aaa/emember/store-membership"
import { SessionActions } from "./session.actions"
import { SessionDoc } from "./session.models"
import { getSessionId } from "./session.selectors"
import { WindowRefService } from "../../modules/share/services/window-ref.service"
import IdTokenResult = firebase.auth.IdTokenResult

@Injectable({ providedIn: "root" })
export class SessionEffects {
  store = inject(Store)
  angularFirestore = inject(AngularFirestore)
  afAuth = inject(AngularFireAuth)
  windowRef = inject(WindowRefService)
  actions$ = inject(Actions)

  initSessionToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.initialize),
      switchMap(() =>
        forkJoin({
          idTokenResult: this.getTokenId().pipe(first()),
          sessionId: this.getSessionId().pipe(first()),
        }).pipe(
          map(({ idTokenResult, sessionId }) => SessionActions.initializeSucceeded({ sessionId, idTokenResult })),
        ),
      ),
    ),
  )

  sessionDocumentsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.initializeSucceeded),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getClubId)))),
      switchMap(([{ sessionId }, clubId]) => {
        const sessionDocRef = this.angularFirestore
          .collection("wss-aaa-web")
          .doc(clubId)
          .collection("apps")
          .doc("join-renew")
          .collection("sessions")
          .doc<SessionDoc>(sessionId)

        return sessionDocRef.valueChanges().pipe(map((sessionDocs) => SessionActions.setDocs({ sessionDocs })))
      }),
    ),
  )

  getSessionId() {
    return of(null)
      .pipe(switchMap(() => of(null).pipe(withLatestFrom(this.store.select(getSessionId)))))
      .pipe(map(([, sessionId]) => sessionId || this.angularFirestore.createId()))
  }

  getTokenId() {
    const token = this.windowRef.nativeWindow?.metaData?.user?.token || ""

    if (!token) {
      return of(null)
    }

    return this.afAuth.idTokenResult.pipe(
      switchMap((idTokenResult: IdTokenResult | null) => {
        if (!idTokenResult && token) {
          return from(this.afAuth.signInWithCustomToken(token)).pipe(
            switchMap(() => this.afAuth.idTokenResult),
            catchError((error) => {
              const errorCode = error.code
              // FirebaseAuthErrors
              console.log(errorCode)

              return of(null)
            }),
          )
        }

        return of(idTokenResult)
      }),
    )
  }
}
