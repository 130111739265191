import { Injectable } from "@angular/core"
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, } from "@angular/common/http"
import { Observable, throwError } from "rxjs"
import { catchError } from "rxjs/operators"

/**
 * Intercepts the HTTP responses, and in case that an error/exception is thrown, handles it
 * and extract the relevant information of it.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * Intercepts an outgoing HTTP request, executes it and handles any error that could be triggered in execution.
   * @see HttpInterceptor
   * @param request the outgoing HTTP request
   * @param next a HTTP request handler
   */
  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    // console.log(request)
    return next.handle(request)
      .pipe(
        catchError(error => {
          // console.log('error caught: ', error)
          // console.log(error.error)
          // console.log(error.error.status)

          // console.log(error.message)
          // console.log(error.statusText)
          // console.log(error.url)


          return throwError(error)
          // return throwError(() => this.generalErrorHandler(error, caught))
/*
          // Checking if it is an Authentication Error (401)
          if (error.status === 401) {
            alert('Access Denied');
            // <Log the user out of your application code>
            this.router.navigate([ 'login-page-route' ]);
            // return throwError(error);
          }
          // If it is not an authentication error, just throw it
          // return throwError(error);
*/


          //   let errMsg: string;
          //   if (errorResponse instanceof HttpErrorResponse) {
          //     const err = errorResponse.message || JSON.stringify(errorResponse.error)
          //     errMsg = `${errorResponse.status} - ${errorResponse.statusText || ''} Details: ${err}`
          //   } else {
          //     errMsg = errorResponse.message ? errorResponse.message : errorResponse.toString()
          //   }
          //   return throwError(errMsg)
        })
      )
  }

  generalErrorHandler(error: any, caught: Observable<any>): Observable<any> {
    if( error.error.status == "INVALID_TOKEN" || error.error.status == "MAX_TOKEN_ISSUE_REACHED"){
      console.log('token has expired')
      // this.logout()
    }
    return error
  }
}

/**
 * Provider POJO for the interceptor
 */
export const HttpErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true,
}
