import { createFeatureSelector, createSelector } from "@ngrx/store"
import { RequestStatus } from "../../../types/request-status"
import { MembershipUpdateState } from "./membership-update.models"
import { getClubOption } from "@aaa/emember/store-membership"
import { getErrorMessage } from "../generic-errors"
import { OfferFilter } from "../price-offers/helpers/types"
import { createMembershipOfferSummary } from "../price-offers/helpers/create-membership-offer-summary"
import { getPaymentFormValues } from "@aaa/emember/store-payment"
import { AccountDetailsSelectors } from "../../modules/share/ngrx-helpers/account-details.selectors"

export const MEMBERSHIP_UPDATE_FEATURE_KEY = "membership-update"

export const getMembershipUpdateFormState = createFeatureSelector<MembershipUpdateState>(MEMBERSHIP_UPDATE_FEATURE_KEY)
export const getMembershipUpdateExecutionData = createSelector(
  getMembershipUpdateFormState,
  ({ executionData }) => executionData,
)
export const getMembershipUpdateAccountDetails = createSelector(
  getMembershipUpdateFormState,
  ({ accountDetails }) => accountDetails,
)
export const getMembershipUpdateTotalCost = createSelector(getMembershipUpdateFormState, ({ totalCost }) => totalCost)
export const getMembershipUpdateAvailableOffers = createSelector(
  getMembershipUpdateAccountDetails,
  (accountDetails) => accountDetails?.availableOffers || [],
)
export const MembershipUpdateAccountSelector = AccountDetailsSelectors(getMembershipUpdateAccountDetails, getClubOption)
export const getMembershipUpdateExecutionError = createSelector(
  getMembershipUpdateFormState,
  ({ executionError }) => executionError,
)
export const getMembershipUpdateExecutionRequestStatus = createSelector(
  getMembershipUpdateFormState,
  ({ executionRequestStatus }) => executionRequestStatus,
)
export const getMembershipUpdateFormActiveStep = createSelector(
  getMembershipUpdateFormState,
  ({ formActiveStep }) => formActiveStep,
)
export const getMembershipUpdateFormValues = createSelector(getMembershipUpdateFormState, ({ formValues }) => {
  const newFormValues = { ...formValues }

  if (!newFormValues.associates) {
    newFormValues.associates = []
  }

  return newFormValues
})
export const getMembershipUpdateOfferFilter = createSelector(
  getMembershipUpdateFormValues,
  getPaymentFormValues,
  (formValues, paymentFormValues) => {
    const formAssociateMedicalRider = (formValues?.associates || []).map((associate) => {
      return { medicalRider: !!associate.accidentMedicalPlan }
    })

    const filter: OfferFilter = {
      associates: formAssociateMedicalRider,
      autoRenew: !!paymentFormValues?.autoRenew,
      promoCode: !!formValues.promoCode,
      medicalRider: !!formValues.accidentMedicalPlan,
    }

    return filter
  },
)
export const getMembershipUpdateSelectedOffers = createSelector(
  getMembershipUpdateAvailableOffers,
  getMembershipUpdateOfferFilter,
  createMembershipOfferSummary,
)
export const getMembershipUpdateValidationRequestStatus = createSelector(
  getMembershipUpdateFormState,
  ({ validationRequestStatus }) => validationRequestStatus,
)
export const getMembershipUpdateErrors = createSelector(
  getMembershipUpdateFormState,
  ({ validationError, executionError }) => validationError || executionError,
)
export const getMembershipUpdateErrorsMessage = createSelector(
  getMembershipUpdateErrors,
  getClubOption,
  (error, options) => getErrorMessage(error, options),
)
export const getMembershipUpdateIsLoadingValidation = createSelector(
  getMembershipUpdateValidationRequestStatus,
  (status) => status === RequestStatus.RUNNING,
)
export const getMembershipUpdateValidateFailed = createSelector(
  getMembershipUpdateValidationRequestStatus,
  (status) => status === RequestStatus.FAILED,
)
export const getMembershipUpdateIsLoadingExecution = createSelector(
  getMembershipUpdateExecutionRequestStatus,
  (status) => status === RequestStatus.RUNNING,
)
