import { FormArray, FormControl, FormGroup } from "@angular/forms"
import { BillingForm } from "../../modules/share/billing-form"
import { MembershipAssociateForm } from "../../modules/share/membership-associate-form"
import { FormGroupValue } from "../../modules/share/form.utils"
import { AccountDetails } from "@aaa/emember/store-types"
import { RequestStatus } from "../../../types/request-status"
import { RequestError } from "../generic-errors"

export enum MembershipAssociateAddStep {
  ASSOCIATE = "associate",
  PAYMENT = "payment",
  CONFIRMATION = "confirmation",
}

export interface MembershipAssociateAddForm {
  associates: FormArray<FormGroup<MembershipAssociateForm>>
  billing: FormGroup<BillingForm>
  promoCode: FormControl<string>
}

export interface MembershipAssociateAddState {
  formActiveStep: MembershipAssociateAddStep
  formValues: FormGroupValue<MembershipAssociateAddForm>
  validationRequestStatus: RequestStatus
  validationError: RequestError | null
  executionData: any
  executionRequestStatus: RequestStatus
  executionError: RequestError | null
  accountDetails: AccountDetails | null
}
