import { Inject, Injectable } from "@angular/core"
import { RxState } from "@rx-angular/state"
import { GLOBAL_RX_STATE, GlobalState } from "./state"
import { RxEffects } from "@rx-angular/state/effects"
import { tap } from "rxjs"

// export interface Option {
//   target: TargetOption
// }

// export interface OptionType {
//   target: TargetType
// }

// export interface OptionTypes {
//   target: TargetTypes
// }

// interface TargetType {
//   label: string
//   value: string
// }

// export type TargetTypes = Record<TargetOption, TargetType>
// export type TargetOption = "self" | "new" | "modal" | "phone"

export enum ScreenSize {
  DESKTOP = "DESKTOP",
  TABLET = "TABLET",
  MOBILE = "MOBILE"
}

export enum Breakpoint {
  VERY_SMALL = "VERY_SMALL",
  SMALL = "SMALL",
  SMALL_MEDIUM = "SMALL_MEDIUM",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  VERY_LARGE = "VERY_LARGE",
}

export enum BreakpointWidth {
  VERY_SMALL = 320,
  SMALL = 545,
  SMALL_MEDIUM = 640,
  MEDIUM = 768,
  LARGE = 992,
  VERY_LARGE = 1200
}

export interface Breakpoints {
  small: number
  medium: number
  tablet: number
  large: number
}

export interface ContentStyles {
  left: {
    px: number
  }
  width: {
    px: number
  }
}

export interface Border {
  boxShadow: string
  border: string
  borderRadiusPx: number
  outline: string
}


@Injectable({
  providedIn: "root"
})
export class Style {

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rxEffects: RxEffects,
  ) {
    rxEffects.register(this.breakpoints$)
    // this.globalState.set({
    //   breakpoints: this.breakpoints
    // })
  }

  breakpoints$ = this.globalState.select("pageWidth")
    .pipe(
      tap(pageWidth => {
        if (pageWidth) {
          if (pageWidth < BreakpointWidth.SMALL) {
            this.globalState.set("screenSize", () => ScreenSize.MOBILE)
          }
          if (pageWidth >= BreakpointWidth.SMALL && pageWidth < BreakpointWidth.LARGE) {
            this.globalState.set("screenSize", () => ScreenSize.TABLET)
          }
          if (pageWidth >= BreakpointWidth.LARGE) {
            this.globalState.set("screenSize", () => ScreenSize.DESKTOP)
          }
        }
      })
    )

  /**
   * component width breakpoints
   */
  get breakpoints(): Breakpoints {
    return {
      small: 545,
      medium: 768,
      tablet: 992,
      large: 1200
    }
  }

  get border(): Border {
    return {
      boxShadow: "inset 0 0 0 4px green",
      border: "none",
      borderRadiusPx: 10,
      outline: "none"
    }
  }

  /**
   * these mirror the existing container class in v3 drupal sites
   * they maintain an outside margin to keep the content in a consistent column-like layout
   * within the larger background image and background color width
   */
  contentStyles = (blockWidth: number): ContentStyles => {
    let contentWidth = blockWidth
    let contentMargin = 0
    if (blockWidth >= this.breakpoints.medium) {
      contentMargin = (blockWidth - 720) / 2
      contentWidth = 720
    }
    if (blockWidth >= this.breakpoints.tablet) {
      contentMargin = (blockWidth - 960) / 2
      contentWidth = 960
    }
    if (blockWidth >= this.breakpoints.large) {
      contentMargin = (blockWidth - 1156) / 2
      contentWidth = 1156
    }
    return {
      left: {
        px: contentMargin
      },
      width: {
        px: contentWidth
      }
    }
  }

}
