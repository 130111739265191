import { inject, Injectable } from "@angular/core"
import { WindowRefService } from "./window-ref.service"

@Injectable({ providedIn: "root" })
export class GoogleTagManagerService {
  windowRef = inject(WindowRefService)

  init(trackingCode?: string) {
    if (trackingCode) {
      const gtmContent =
        "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', '" +
        trackingCode +
        "');"
      const head = document.getElementsByTagName("head")[0]
      const gtmNode = document.createElement("script")
      gtmNode.textContent = gtmContent
      gtmNode.type = "text/javascript"
      head.append(gtmNode)

      const body = this.windowRef.nativeWindow.document.getElementsByTagName("body")[0]
      const gtmNoNode = this.windowRef.nativeWindow.document.createElement("noscript")
      const iframe = this.windowRef.nativeWindow.document.createElement("iframe")

      iframe.src = "https://www.googletagmanager.com/ns.html?id=" + trackingCode
      iframe.height = "0"
      iframe.width = "0"
      iframe.style.display = "none"
      iframe.style.visibility = "hidden"

      gtmNoNode.append(iframe)
      body.append(gtmNoNode)
    }
  }
}
// This example of google tag manager
// ;(function (w, d, s, l, i) {
//   w[l] = w[l] || []
//   w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
//   var f = d.getElementsByTagName(s)[0],
//     j = d.createElement(s),
//     dl = l != "dataLayer" ? "&l=" + l : ""
//   j.async = true
//   j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
//   f.parentNode.insertBefore(j, f)
// })(window, document, "script", "dataLayer", "GTM-P9JBKTCN")
