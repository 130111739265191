import { Inject, Injectable } from "@angular/core"
import { AngularFireAnalytics } from "@angular/fire/compat/analytics"
import firebase from "firebase/compat/app"
import "firebase/analytics"
import EventParams = firebase.analytics.EventParams
import { WindowRefService } from "../modules/share/services/window-ref.service"
import { GLOBAL_RX_STATE, GlobalState } from "./state"
import { RxState } from "@rx-angular/state"
import CustomParams = firebase.analytics.CustomParams
import EventName = firebase.analytics.EventName

export interface AnalyticsEvent {
  eventCategory: string
  eventAction: string
  eventLabel: string
  eventValue: number
}

export interface AnalyticsCustomEvent {
  eventName: string
  eventParams: CustomParams
  options?: firebase.analytics.AnalyticsCallOptions
}

export interface AnalyticsPageViewEvent {
  eventName: EventName.PAGE_VIEW
  eventParams?: {
    page_title?: string // default value is automatically sent, use this to override
    page_location?: string // default value is automatically sent, use this to override
    page_path?: string
    [key: string]: any
  }
  options?: firebase.analytics.AnalyticsCallOptions
}

export interface AnalyticsPurchaseEvent {
  eventName: EventName.PURCHASE | string
  eventParams?: {
    value?: EventParams["value"]
    currency?: EventParams["currency"]
    transaction_id: EventParams["transaction_id"]
    tax?: EventParams["tax"]
    shipping?: EventParams["shipping"]
    items?: EventParams["items"]
    coupon?: EventParams["coupon"]
    affiliation?: EventParams["affiliation"]
    [key: string]: any
  }
  options?: firebase.analytics.AnalyticsCallOptions
}

export interface AnalyticsRefundEvent {
  eventName: EventName.REFUND
  eventParams?: {
    value?: EventParams["value"]
    currency?: EventParams["currency"]
    transaction_id: EventParams["transaction_id"]
    tax?: EventParams["tax"]
    shipping?: EventParams["shipping"]
    items?: EventParams["items"]
    coupon?: EventParams["coupon"]
    affiliation?: EventParams["affiliation"]
    [key: string]: any
  }
  options?: firebase.analytics.AnalyticsCallOptions
}

export interface AnalyticsScreenViewEvent {
  eventName: EventName.SCREEN_VIEW
  eventParams?: {
    firebase_screen: EventParams["firebase_screen"]
    firebase_screen_class: EventParams["firebase_screen_class"]
    [key: string]: any
  }
  options?: firebase.analytics.AnalyticsCallOptions
}

@Injectable({
  providedIn: "root"
})
export class AnalyticsService {

  constructor(
    private window: WindowRefService,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private angularFireAnalytics: AngularFireAnalytics,
  ) {
    if (globalState.get("environment", "ngServe") || globalState.get("windowMetaData", "webEnv") === "APP_TEST") {
      angularFireAnalytics.setAnalyticsCollectionEnabled(false).then()
    }

    const analyticsCustomEvent: AnalyticsCustomEvent = {
      eventName: "page_view",
      eventParams: {
        // page_title: document.title, // drupal and multiblock is pre-populating this automatically
        // page_location: window.location.href, // drupal and multiblock is pre-populating this automatically
        page_path: window.nativeWindow.location.pathname.toLowerCase()
      }
    }
    if (this.globalState.get("adminUser")) {
      console.log(analyticsCustomEvent)
    }
    // this.sendCustomEventToFirebaseAnalytics(analyticsCustomEvent)
  }

  get ga(): (send: string, event1: string, event?: AnalyticsEvent | string) => void {
    return this.window.nativeWindow["ga"]
  }

  sendPage(page: string): void {
    // // @ts-ignore
    // this.window.gtag('event', 'page_view', {
    //   page_path: page,
    //   'send_to': this.window['metaData'].gaId
    // })

    if (this.ga) {
      this.ga("wssAaaTracker.set", "page", page)
      this.ga("wssAaaTracker.send", "pageview")
    }
  }

  sendEvent(event: AnalyticsEvent): void {
    if (this.ga) {
      this.ga("wssAaaTracker.send", "event", event)
    }

    // // @ts-ignore
    // this.window.gtag('event', eventAction, {
    //   'event_category': eventCategory + '-1',
    //   'event_label': eventLabel,
    //   'value': eventValue,
    //   'send_to': this.window['metaData'].gaId
    // })

    // // @ts-ignore
    // this.window.gtag('event', eventAction, {
    //   'event_category': eventCategory + '-2',
    //   'event_label': eventLabel,
    //   'value': eventValue
    // })


  }

  sendFirebaseCustomEvent(analyticsCustomEvent: AnalyticsCustomEvent): void {
    this.angularFireAnalytics.logEvent(analyticsCustomEvent.eventName, analyticsCustomEvent.eventParams).then()
  }

  sendFirebasePageViewEvent(analyticsEvent: AnalyticsPageViewEvent): void {
    this.angularFireAnalytics.logEvent(analyticsEvent.eventName, analyticsEvent.eventParams).then()
  }

  sendFirebaseScreenViewEvent(analyticsEvent: AnalyticsScreenViewEvent): void {
    this.angularFireAnalytics.logEvent(analyticsEvent.eventName, analyticsEvent.eventParams).then()
  }

  sendFirebasePurchaseEvent(analyticsEvent: AnalyticsPurchaseEvent): void {
    this.angularFireAnalytics.logEvent(analyticsEvent.eventName, analyticsEvent.eventParams).then()
  }

  /**
   * For more info on preset log event types, see node_modules/firebase/index.d.ts
   * line 4571: declare namespace firebase.analytics
   */

}
