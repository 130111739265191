import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: "cardIcon",
  standalone: true,
})
export class CardIconPipe implements PipeTransform {
  cardIcons: { [key: string]: any } = {
    "000": "icons:000-generic-credit-card",
    visa: "icons:001-visa",
    mastercard: "icons:002-mastercard",
    amex: "icons:003-amex",
    discover: "icons:004-discover",
    diners: "icons:005-diners",
  }

  transform(cardType?: string): string {
    return (cardType && this.cardIcons[cardType]) || this.cardIcons["000"]
  }
}
