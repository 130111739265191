import { Injectable } from "@angular/core"

export interface CybersourceResponses {
  [responseKey: string]: CybersourceResponse
}

export type CybersourceResponse = CaptureContextResponse | JoinPaymentResponse

export type CaptureContextResponse = string

export type JoinPaymentResponse = any

/**
 * 5 steps
 *   1. trigger PAYMENT_QUERY with captureContext method
 *   2. listen to cybersourceDoc for captureContext response
 *   3. build microforms
 *   4. using expiration month and year, create microform token
 *   5. submit token result to PAYMENT_QUERY with submitPayment method
 */

/**
 * https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ/api_reference/module_flex.html
 */

export interface Flex {
  // eslint-disable-next-line @typescript-eslint/no-misused-new
  new (captureContextResponse: string):Flex
  microform(options: { styles: Record<string, Record<string, string>> }) :FlexMicroform
}

export interface FlexMicroform {
  createField: (name: string, options: { placeholder: string }) => MicroformField
  createToken: (options: Record<string, string>, callback: (err: Error, token: string) => void) => any
}

export interface MicroformField {
  load: (f: string) => void
  unload: () => void
  on: (event: string, callback: (data: any) => void) => void
}

@Injectable({
  providedIn: "root"
})
export class CybersourceService {
  flexMicroForm: FlexMicroform | undefined

}
