import { Component, EventEmitter, forwardRef, inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core"
import {
  ReactiveFormsModule,
  NG_VALUE_ACCESSOR,
  ControlContainer,
  FormControl,
  ControlValueAccessor,
} from "@angular/forms"
import { CommonModule } from "@angular/common"
import { Subject } from "rxjs"

@Component({
  selector: "ava-form-checkbox",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckBoxComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, ReactiveFormsModule],
  standalone: true,
  template: `
    <label
      tabindex="0"
      [ngClass]="{ 'is-disabled': isDisabled || formControl.disabled }"
      [attr.for]="id"
      class="ava-checkbox"
    >
      <input [attr.id]="id" type="checkbox" class="ava-checkbox__input" [formControl]="formControl" />
      <div class="ava-checkbox__icon"></div>
      <span
        class="ava-checkbox__label"
        (keydown.space)="changeValue()"
        (keydown.enter)="changeValue()"
        (click)="changeValue()"
      >
        {{ formLabel }}
      </span>
    </label>
  `,
  styles: [
    `
      @import "../../../../styles/ava-responsive";

      :host {
        padding-right: 1rem;
      }

      .ava-checkbox {
        display: flex;
        position: relative;
        padding-left: 1.5rem;
        cursor: pointer;

        @include tabOutline();

        &__icon:after {
          content: "";
          position: absolute;
          display: none;
        }

        &__input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        &__icon {
          position: absolute;
          top: 2px;
          left: 0;
          height: 1rem;
          width: 1rem;
          background-color: #fff;
          border: 1px solid var(--btn-primary-color);
        }

        &__input:checked ~ &__icon:after {
          display: block;
        }

        &__icon:after {
          left: 0.25rem;
          top: 0.1rem;
          width: 0.4rem;
          height: 0.6rem;
          border: solid var(--btn-primary-color);
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }

        &.is-disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    `,
  ],
})
export class FormCheckBoxComponent implements ControlValueAccessor, OnChanges {
  container = inject(ControlContainer)
  @Input() formLabel = ""
  @Input() formControlName = ""
  @Input() isDisabled = false
  @Output() changed = new EventEmitter()
  alive$ = new Subject()
  id = "check-" + Math.random().toString(36).substr(2, 10)
  get formControl() {
    return this.container.control?.get(this.formControlName) as FormControl
  }

  changeFn: (value: boolean) => void = (value) => {}
  touchFn: (value: boolean) => void = (value) => {}

  changeValue() {
    const isDisabled = this.isDisabled || this.formControl.disabled

    if (!isDisabled) {
      this.changed.emit(this.formControl.value)
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.setDisable(isDisabled)
  }

  registerOnChange(fn: any) {
    this.changeFn = fn
  }

  registerOnTouched(fn: any) {
    this.touchFn = fn
  }

  writeValue(value: boolean) {
    this.formControl.setValue(value)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisabled) {
      this.setDisable(this.isDisabled)
    }
  }

  setDisable(isDisabled: boolean) {
    if (this.formControl.enabled && isDisabled) {
      this.formControl.disable({ onlySelf: true, emitEvent: false })
    } else if (this.formControl.disabled && !isDisabled) {
      this.formControl.enable({ onlySelf: true, emitEvent: false })
    }
  }
}
