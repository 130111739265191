import { createActionGroup, props } from "@ngrx/store"
import {
  AddMissedMembershipOptions,
  MembershipLevelOffer,
  MembershipsLevel,
  PriceOffersQuery,
} from "./price-offers.model"
import { RequestError } from "../generic-errors"
import { MembershipOfferItem } from "./helpers/types"

export const PriceOffersActions = createActionGroup({
  source: "Price Offers",
  events: {
    Load: props<{ query: PriceOffersQuery }>(),
    "Load Succeeded": props<{
      levels: MembershipsLevel[]
      levelOffers: MembershipLevelOffer
      rawLevelOffers: MembershipLevelOffer
    }>(),
    "Load Failed": props<{
      error: RequestError
      levelOffers?: MembershipLevelOffer
      levels?: MembershipsLevel[]
    }>(),
    Update: props<{
      levels: MembershipsLevel[]
      levelOffers: MembershipLevelOffer
    }>(),
    "Set Offers": props<{ levelOffers: MembershipLevelOffer }>(),
    "Update And Insert": props<{ membershipType: string; componentOffers: Partial<MembershipOfferItem>[] }>(),
    "Update Optionals": props<{
      membershipType: string
      // set new optionals
      optionalOffers: MembershipOfferItem[]
      // some filter
      filter?: {
        // if in actions missed will add from raw offers
        addIfMissed?: AddMissedMembershipOptions[]
      }
    }>(),
  },
})
