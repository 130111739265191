import { createFeatureSelector, createSelector } from "@ngrx/store"
import { getClubOption } from "@aaa/emember/store-membership"
import { ClubApp } from "@aaa/emember/types"
import { RequestStatus } from "../../../types/request-status"
import { MembershipAutoRenewState } from "./membership-auto-renew.models"
import { getErrorMessage } from "../generic-errors"

export const MEMBERSHIP_AUTO_RENEW_FEATURE_KEY = "membership-auto-renew"

export const getMembershipAutoRenewFormState = createFeatureSelector<MembershipAutoRenewState>(
  MEMBERSHIP_AUTO_RENEW_FEATURE_KEY,
)
export const getMembershipAutoRenewUpdateCardRequestStatus = createSelector(
  getMembershipAutoRenewFormState,
  ({ updateCardRequestStatus }) => updateCardRequestStatus,
)
export const getMembershipAutoRenewFormActiveStep = createSelector(
  getMembershipAutoRenewFormState,
  ({ formActiveStep }) => formActiveStep,
)
export const getMembershipAutoRenewFormValues = createSelector(
  getMembershipAutoRenewFormState,
  ({ formValues }) => formValues,
)
export const getMembershipAutoRenewAddCardRequestStatus = createSelector(
  getMembershipAutoRenewFormState,
  ({ addCardRequestStatus }) => addCardRequestStatus,
)
export const getMembershipAutoRenewRemoveRequestStatus = createSelector(
  getMembershipAutoRenewFormState,
  ({ removeCardRequestStatus }) => removeCardRequestStatus,
)
export const getMembershipAutoRenewErrors = createSelector(
  getMembershipAutoRenewFormState,
  ({ addCardError, updateCardError, removeCardError }) => addCardError || updateCardError || removeCardError,
)
export const getMembershipAutoRenewErrorsMessage = createSelector(
  getMembershipAutoRenewErrors,
  getClubOption,
  (error, options) => getErrorMessage(error, options),
)

export const getMembershipAutoRenewMessage = createSelector(
  getClubOption,
  (
    options,
  ) => `Sign up for Automatic Renewal and drive with peace of mind. When it's time to renew your membership, your annual
      dues will automatically be charged to the credit card approximately ${
        options.clubId === ClubApp.MidStates ? "30" : "20"
      } days prior to your membership expiration
      date.`,
)

export const getMembershipAutoRenewIsLoadingAddCard = createSelector(
  getMembershipAutoRenewAddCardRequestStatus,
  (status) => status === RequestStatus.RUNNING,
)
export const getMembershipAutoRenewIsLoadingUpdateCard = createSelector(
  getMembershipAutoRenewUpdateCardRequestStatus,
  (status) => status === RequestStatus.RUNNING,
)
export const getMembershipAutoRenewIsLoadingRemoveCard = createSelector(
  getMembershipAutoRenewRemoveRequestStatus,
  (status) => status === RequestStatus.RUNNING,
)
