import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Router } from "@angular/router"
import { Store } from "@ngrx/store"
import { bufferCount, map, switchMap, tap } from "rxjs/operators"
import { AccountDetails, ValidateSucceededResponseObject } from "@aaa/emember/store-types"
import { concat, first, of, withLatestFrom } from "rxjs"
import { PaymentActions } from "@aaa/emember/store-payment"
import { MobileRenewActions } from "./mobile-renew.actions"
import { getMobileRenewQuery } from "./mobile-renew.selectors"
import { WindowRefService } from "../../modules/share/services/window-ref.service"
import { RequestErrorType } from "../generic-errors"

@Injectable({ providedIn: "root" })
export class MobileRenewEffects {
  actions$ = inject(Actions)
  store = inject(Store)
  router = inject(Router)
  windowRef = inject(WindowRefService)

  setSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MobileRenewActions.recostValidateSucceeded),
      map(({ response }: ValidateSucceededResponseObject<AccountDetails>) =>
        MobileRenewActions.setAccountDetails({ accountDetails: response }),
      ),
    ),
  )

  validateFormStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MobileRenewActions.validate),
        switchMap(({ params }) => {
          const validatePayment$ = of(null).pipe(
              tap(() => this.store.dispatch(PaymentActions.validate())),
              switchMap(() => this.actions$.pipe(ofType(PaymentActions.validateSucceeded), first())),
            ),
            validateGenerateToken$ = of(null).pipe(
              tap(() => this.store.dispatch(PaymentActions.generateToken())),
              switchMap(() => this.actions$.pipe(ofType(PaymentActions.generateTokenSucceeded), first())),
            ),
            validatePay$ = of(null).pipe(
              tap(() => this.store.dispatch(MobileRenewActions.pay({ params }))),
              switchMap(() => this.actions$.pipe(ofType(MobileRenewActions.paySucceeded), first())),
            ),
            validators = [validatePayment$, validateGenerateToken$, validatePay$]

          return concat(...validators).pipe(bufferCount(validators.length))
        }),
      ),
    { dispatch: false },
  )

  paySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MobileRenewActions.paySucceeded),
      map(() => MobileRenewActions.navigateTo({ status: "success" })),
    ),
  )

  payFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MobileRenewActions.payFailed),
      map(({ error }) => {
        if (error.type === RequestErrorType.MembershipCancelled) {
          return MobileRenewActions.navigateTo({ status: "error", errorCode: "membershipIsCanceled" })
        }

        if (error.type === RequestErrorType.PaymentTypeError) {
          return MobileRenewActions.navigateTo({ status: "error", errorCode: "paymentNotProcessed" })
        }

        return MobileRenewActions.navigateTo({ status: "error", errorCode: "membershipLookUpError" })
      }),
    ),
  )

  navigateTo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MobileRenewActions.navigateTo),
        switchMap((status) => of(status).pipe(withLatestFrom(this.store.select(getMobileRenewQuery)))),
        map(([{ status, errorCode }, query]) => {
          let redirectUri = query.redirectUri

          switch (status) {
            case "success":
            case "goBack":
              redirectUri = redirectUri + "?status=" + status
              break
            case "error":
              redirectUri = redirectUri + "?status=" + status + "&errorCode=" + errorCode
              break
          }

          this.windowRef.nativeWindow.location.href = redirectUri
        }),
      ),
    { dispatch: false },
  )
}
