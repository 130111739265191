import {
  Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild, ViewContainerRef,
} from "@angular/core"
import { combineLatest, combineLatestWith, debounceTime, filter, Observable, of } from "rxjs"
import { map, tap } from "rxjs/operators"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { Status } from "../../options/status/service"
import { StorageService } from "../../services/storage"
import { Border, ScreenSize } from "../../services/style"
import { TemplateType } from "../../options/template/service"
import { ImageEditorOptions } from "../../fields/image/service"
import { BlockState } from "../services/state"
import { ConfigService } from "../services/config"
import { Personalize, PersonalizeService } from "../../options/personalize/service"
import { BlockBackground, GLOBAL_RX_STATE, GlobalState, StateService } from "../../services/state"
import { BackgroundImages, BackgroundImagesService } from "../../options/background-images/service"
import { AlignmentType } from "../../options/alignment/service"
import { Block } from "../services/block"
import { DataReadService } from "../../services/data-read"
import { RevisionWriteService } from "../../services/revision-write"
import { FormGroup } from "@angular/forms"
import { FormService } from "../../services/form"

@Component({
  selector: "ava-block-state-wrapper",
  templateUrl: "./state-wrapper.html",
  providers: [RxState, RxEffects],
})
export class BlockStateViewComponent implements OnInit {
  @Input() blockId: string | undefined
  @Input() stateId: string | undefined
  @Input() parentStateId: string | undefined
  @Input() parentColumnIndex: number | undefined
  @Input() parentRowId: string | undefined
  @Input() parentRowIndex: number | undefined
  @ViewChild("containerWidth") containerWidthRef: ElementRef | undefined
  @ViewChild("joinRenewContainer", { read: ViewContainerRef }) joinRenewContainer: any

  parentBlockState: RxState<BlockState> | undefined
  parentBlockState$: Observable<BlockState> | undefined
  blockState$ = this.blockState.select()
  globalState$ = this.globalState.select()
  formGroup: FormGroup | undefined
  previewModeStyles: {
    margin: string | undefined
    width: number | undefined
  } = {
    margin: undefined,
    width: undefined,
  }
  blockVisible: boolean | undefined
  rxEffectIds = {
    blockValueFromBlockEditRevision$: 0,
    blockValueFromBlock$: 0,
    formValueChanges$: 0,
  }

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private blockState: RxState<BlockState>,
    private rxEffects: RxEffects,
    private stateService: StateService,
    private personalizeService: PersonalizeService,
    private configService: ConfigService,
    private storageService: StorageService,
    private backgroundImagesService: BackgroundImagesService,
    private dataReadService: DataReadService,
    private revisionWriteService: RevisionWriteService,
    private formService: FormService,
  ) {
    blockState.set(this.configService.blockStateDefaults)

    rxEffects.register(this.connectEditRevision$)
    rxEffects.register(this.connectForm$)

    rxEffects.register(this.showBlock$)
    rxEffects.register(this.templateType$)
    rxEffects.register(this.blockStyles$)
    rxEffects.register(this.backgroundImages$)
    rxEffects.register(this.hideAllBlockRows$)
  }

  initializeBlock$ = combineLatest([
    this.globalState.select("blocks"),
    this.globalState.select("templateBlocks"),
    this.globalState.select("sharedBlocks"),
  ]).pipe(
    tap(([blocks, templateBlocks, sharedBlocks]) => {
      if (this.blockId) {
        const block = blocks[this.blockId] || templateBlocks[this.blockId] || sharedBlocks[this.blockId] || {}
        if (Object.keys(block).length) {
          if (this.stateService.blocksNotEqual(block, this.blockState.get("block"))) {
            this.blockState.set("block", () => block)
            if (!this.globalState.get("adminMode")) {
              this.blockState.set("blockValue", () => block)
            }
          }
        }
      }
    }),
  )

  connectEditRevision$ = this.globalState.select("adminMode")
    .pipe(
      tap(adminMode => {
        if (adminMode) {
          this.rxEffects.unregister(this.rxEffectIds.blockValueFromBlockEditRevision$)
          this.rxEffectIds.blockValueFromBlockEditRevision$ = this.rxEffects.register(this.blockValueFromBlockEditRevision$)
        }
        if (!adminMode) {
          this.blockState.connect("blockValue", this.blockState.select("block"))
        }

        /**
         * connect blockEditRevision to observe all editRevision changes in the database for this page
         */
        if (adminMode && this.blockId && !this.blockState.get("blockEditRevision")) {
          this.blockState.connect("blockEditRevision",
            this.dataReadService.editRevisionFromDb$(this.blockId)
              .pipe(
                map((snapshot) => {
                  const block = snapshot.payload.data() as Block
                  if (this.blockState && !block && !snapshot.payload.metadata.hasPendingWrites) {
                    /**
                     * Create editRevision Doc in database because it does not yet exist
                     */
                    this.revisionWriteService.updateEditRevisions([this.blockState.get("block")])
                  }
                  return block
                }),
              ),
          )
        }
      }),
    )

  blockValueFromBlockEditRevision$ = this.blockState.select("blockEditRevision")
    .pipe(
      filter(blockEditRevision => !!blockEditRevision && !!this.stateId),
      tap(blockEditRevision => {
        blockEditRevision = blockEditRevision as Block
        this.blockState.set("blockValue", () => blockEditRevision as Block)
        this.stateService.compareBlockAndEditRevision(blockEditRevision, this.blockState.get("block"), this.stateId as string)
      }),
    )

  connectForm$ = combineLatest([
    this.blockState.select("blockEditRevision"),
    this.globalState.select("adminMode"),
  ])
    .pipe(
      filter(([blockEditRevision, adminMode]) => {
        return adminMode && !!blockEditRevision
      }),
      tap(([blockEditRevision]) => {
        if (this.stateId) {
          this.formGroup = this.formService.forms[this.blockState.get("block", "id")]
          blockEditRevision = blockEditRevision as Block
          /**
           * Create the form if it does not yet exist
           * Replace the form when another editor session changes blockEditRevision
           */
          const userSessionId = this.globalState.get("userSession", "sessionId")
          if (this.formGroup) {

            const sessionIdIsDifferent = blockEditRevision.status.session !== userSessionId
            const formIsDifferent = this.stateService.notDeepEqual(blockEditRevision, this.formGroup.getRawValue())
            if (sessionIdIsDifferent && formIsDifferent) {
              blockEditRevision.status.session = userSessionId
              this.formService.addForm(this.formService.newForm(blockEditRevision) as FormGroup)
            }
          }
          if (!this.formGroup) {
            blockEditRevision.status.session = userSessionId
            this.formService.addForm(this.formService.newForm(blockEditRevision) as FormGroup)
            this.formGroup = this.formService.forms[blockEditRevision.id]
          }

          if (this.formGroup && !this.rxEffectIds.formValueChanges$) {
            this.rxEffects.unregister(this.rxEffectIds.formValueChanges$)
            this.rxEffectIds.formValueChanges$ = this.rxEffects.register(this.formValueChanges$)
          }

        }
      }),
    )

  get formValueChanges$(): Observable<any> {
    const formGroup = this.formService.forms[this.blockState.get("block", "id")]
    if (formGroup) {
      return formGroup.valueChanges
        .pipe(
          // filter(formValue => !!formValue && !!this.pageBlockState && this.globalState.get("adminMode")),
          debounceTime(500),
          tap(formValue => {
            const blockEditRevision = this.blockState.get("blockEditRevision")
            if (blockEditRevision && this.stateService.blocksNotEqual(formValue, blockEditRevision)) {
              this.revisionWriteService.updateEditRevisions([formValue])
            }
          }),
        )
    }
    return of(null)
  }


  backgroundImages$ = combineLatest([
    this.blockState.select("blockValue", "options", "backgroundImages"),
    this.blockState.select("blockValue", "template", "type"),
    this.globalState.select("screenSize"),
    this.blockState.select("blockValue", "options", "alignment"),
  ]).pipe(
    tap(([backgroundImages, templateType, screenSize, alignment]) => {
      const optionsArray: ImageEditorOptions[] = this.backgroundImagesService.editorOptionsArray
      const background = this.buildBackground(backgroundImages, optionsArray, screenSize, templateType)

      if (templateType === TemplateType.JOIN_BANNER) {
        // console.log(optionsArray)
      }
      const style = this.blockState.get("style")
      /**
       * default values
       */
      style.position.bottom = undefined
      style.position.top = 0
      style.positionType = "relative"
      /**
       * conditionally overwrite the defaults
       */
      if (background?.url) {
        switch (alignment) {
          case AlignmentType.CENTER:
            style.position.bottom = 0
            style.position.top = undefined
            break
          case AlignmentType.LEFT:
            style.position.bottom = undefined
            style.position.top = 50
            break
          case AlignmentType.RIGHT:
            break
        }
        if (screenSize === ScreenSize.DESKTOP) {
          style.positionType = "absolute"
        }
      }
      this.blockState.set("background", () => background)
      this.blockState.set("style", () => style)
    }),
  )

  blockStyles$ = combineLatest([
    this.globalState.select("pageWidth"),
    this.globalState.select("previewSize"),
    this.globalState.select("adminMode"),
    this.globalState.select("previewMode"),
  ])
    .pipe(
      filter(([pageWidth, previewSize, adminMode, previewMode]) => {
        return adminMode && previewMode && !!previewSize && previewSize <= pageWidth
      }),
      tap(([pageWidth, previewSize]: [number, number, boolean, boolean]) => {
        // console.log(previewMode)
        // console.log(pageWidth)
        // console.log(previewSize)
        const previewModeStyles = this.previewModeStyles
        previewModeStyles.margin = "0 auto"
        previewModeStyles.width = pageWidth
        previewModeStyles.margin = "0 auto"
        previewModeStyles.width = previewSize

        // console.log(blockStyles.width)
        // this.globalState.set({ previewModeStyles: previewModeStyles })
        // this.previewModeStyles = previewModeStyles
        // this.onResize()
      }),
    )

  showBlock$ = this.globalState.select("adminMode")
    .pipe(
      combineLatestWith(
        this.globalState.select("previewMode"),
        this.blockState.select("blockValue", "status"),
        this.globalState.select("location", "search"),
        this.blockState.select("blockValue", "template", "type"),
      ),
      tap((
        [adminMode, previewMode, blockValueStatus, locationSearch, templateType]
          : [boolean, boolean, Status, string, TemplateType | null],
      ) => {
        this.blockState.set("showBlock", () => this.showBlock(adminMode, previewMode, blockValueStatus))
      }),
    )

  templateType$ = this.blockState.select("blockValue", "template", "type")
    .pipe(
      tap((templateType: TemplateType | null) => {
        switch (templateType) {
          case TemplateType.BANNER:
          case TemplateType.BANNER_LARGE:
          case TemplateType.JOIN_BANNER:
            this.blockState.set("fieldsEnabled", oldState => {
              return { backgroundImages: true, ...oldState.fieldsEnabled }
            })
            break
          default:

        }
      }),
    )

  hideAllBlockRows$ = this.globalState.select("hideAllBlockRows")
    .pipe(
      tap(hideAllBlockRows => {
        this.blockVisible = !hideAllBlockRows
      }),
    )

  get rowVisible$(): Observable<any> {
    if (this.parentBlockState && this.stateId) {
      return this.parentBlockState.select("blockValue", "rows", this.stateId, "visibility")
        .pipe(
          combineLatestWith(
            this.globalState.select("screenSize"),
            this.globalState.select("hideAllBlockRows"),
          ),
          tap(([blockVisibility, screenSize, hideAllBlockRows]) => {
            if (hideAllBlockRows) {
              this.blockVisible = false
              return
            }
            if (blockVisibility?.[screenSize] || blockVisibility?.[screenSize] === undefined) {
              this.blockVisible = true
            }
          }),
        )
    }
    return of(null)
  }

  ngOnInit(): void {
    this.rxEffects.register(this.initializeBlock$)
    if (this.stateId) {
      this.stateService.states[this.stateId] = this.blockState
    }
    if (this.parentStateId) {
      this.parentBlockState = this.stateService.states[this.parentStateId]
    }
    this.parentBlockState$ = this.parentBlockState?.select()
    this.blockState.set({
      parentBlockStateId: this.parentStateId,
      parentColumnIndex: this.parentColumnIndex,
      parentRowId: this.parentRowId,
      parentRowIndex: this.parentRowIndex,
    })
    this.globalState.set("blockStateIds", oldState => {
      if (this.stateId && oldState.blockStateIds.indexOf(this.stateId) === -1) {
        oldState.blockStateIds.push(this.stateId)
      }
      return oldState.blockStateIds
    })
    this.blockState.set("blockValue", () => this.blockState.get("block"))
    this.blockState.connect("blockValue", this.blockState.select("block"))

    if (this.parentBlockState && this.stateId) {
      const visibility = this.parentBlockState.get("blockValue", "rows", this.stateId, "visibility")
      if (!visibility) {
        // console.warn("visibility option is missing")
        this.blockVisible = true
      }
    }
    // this.rxEffects.register(this.visibility$)
    this.rxEffects.register(this.rowVisible$)
  }

  @HostListener("window:resize")
  onResize(): void {
    const width = this.containerWidthRef?.nativeElement.getBoundingClientRect().width
    if (width && width !== this.globalState.get("pageWidth")) {
      // this.globalState.set("pageWidth", () => width)

      /*
            const previewSize = this.globalState.get("previewSize")
            if (this.globalState.get("previewMode") && previewSize && previewSize < width) {
              this.globalState.set("pageWidth", () => previewSize)
            }
      */
    }
  }

  get border(): Border {
    const boxShadow = "inset 0 0 0 4px green"
    return {
      boxShadow: "none",
      border: "none",
      borderRadiusPx: 10,
      outline: "none",
    }
  }

  showBlock(adminMode: boolean, previewMode: boolean, status: Status): boolean {
    /**
     * Check for all conditions that hide the block,
     * if none "return false" then pass-through to the "return true" at the end.
     */
    if (!adminMode) {
      /**
       * status is public block.status because we are not in adminMode
       */
      if (status.unpublished) {
        return false
      }
      if (status.protected && !status.passwordAuth) {
        return false
      }
      if (status.personalized) {
        if (this.statusMatches(status.personalize) && this.roleMatches(status.personalize)) {
          return true
        }
        /**
         * hide block
         * personalized is selected, and none of the above conditions match
         */
        return false
      }
    }
    if (adminMode) {
      /**
       * status is form.value.status because we are in adminMode
       */
      if (previewMode) {
        if (status.unpublished) {
          return false
        }
        if (status.deleting) {
          return false
        }
        if (status.personalized) {
          if (this.statusMatches(status.personalize) && this.roleMatches(status.personalize)) {
            return true
          }
          /**
           * hide block
           * personalized is selected, and none of the above conditions match
           */
          return false
        }
      }
      const blockStatus = { protected: false } // not formValue, since we are in adminMode we would need to get this value separately
      if (blockStatus.protected && !status.passwordAuth) {
        // return false
      }
    }
    return true
  }

  roleMatches(personalize: Personalize): boolean | undefined {
    /**
     * show block if user has any of the allowed roles
     */
    const userRoles = this.globalState.get("windowMetaData", "user", "roles")
    if (userRoles) {
      for (const userRole of userRoles) {
        for (const [key, role] of Object.entries(this.personalizeService.membershipLevels)) {
          if (personalize[key] && userRole === role.key) {
            return true
          }
        }
      }
    }
    return
  }

  statusMatches(personalize: Personalize): boolean | undefined {
    /**
     * show block if user status matches any of the allowed statuses
     */
    const userStatus = this.globalState.get("windowMetaData", "user", "status")
    for (const [key, role] of Object.entries(this.personalizeService.membershipStatuses)) {
      if (personalize[key] && role.key === userStatus) {
        return true
      }
    }
    return
  }

  buildBackground(backgroundImages: BackgroundImages | undefined, optionsArray: ImageEditorOptions[], screenSize: ScreenSize, templateType: TemplateType | null): BlockBackground {
    let background: BlockBackground = {
      height: 0,
      width: 0,
      image: undefined,
      url: "",
    }
    if (backgroundImages && templateType) {
      const backgrounds = {
        [ScreenSize.DESKTOP]: this.buildScreenSizeBackground(backgroundImages, optionsArray, ScreenSize.DESKTOP, templateType),
        [ScreenSize.MOBILE]: this.buildScreenSizeBackground(backgroundImages, optionsArray, ScreenSize.MOBILE, templateType),
        [ScreenSize.TABLET]: this.buildScreenSizeBackground(backgroundImages, optionsArray, ScreenSize.TABLET, templateType),
      }
      switch (screenSize) {
        case ScreenSize.DESKTOP:
          background = backgrounds[ScreenSize.DESKTOP]
          break
        case ScreenSize.TABLET:
          if (templateType === TemplateType.JOIN_BANNER) // disable background image
            break
          background = backgrounds[ScreenSize.TABLET]
            ? backgrounds[ScreenSize.TABLET]
            : backgrounds[ScreenSize.DESKTOP]
          break
        case ScreenSize.MOBILE:
          if (templateType === TemplateType.JOIN_BANNER) // disable background image
            break
          background = backgrounds[ScreenSize.MOBILE]
            ? backgrounds[ScreenSize.MOBILE]
            : backgrounds[ScreenSize.TABLET]
              ? backgrounds[ScreenSize.TABLET]
              : backgrounds[ScreenSize.DESKTOP]
          break
      }
    }
    return background
  }

  buildScreenSizeBackground(backgroundImages: BackgroundImages, optionsArray: ImageEditorOptions[], screenSize: ScreenSize, templateType: TemplateType): BlockBackground {
    const image = backgroundImages[screenSize]
    const options = optionsArray.find(options => options.screenSize === screenSize && options.templateType === templateType)
    let background: BlockBackground = {
      height: 0,
      width: 0,
      image: undefined,
      url: "",
    }
    if (options && image.filePath) {
      background = {
        height: options.maxHeight.px,
        width: options.maxWidth.px,
        image: image,
        url: this.getBgUrl(image.filePath),
      }
    }
    return background
  }

  getBgUrl(filePath: string): string {
    return "url(" + this.getFileUrl(filePath) + ")"
  }

  getFileUrl(path: string): string {
    return this.storageService.getFileUrl(path)
  }

}
