import { AppEnv, ClubApp, Environment } from "@aaa/emember/types"

/**
 * copy/pasted from firebase project settings
 */
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBwQj6n9BXpAV52bzPt8fyxcxdEm7u9rZ0",
  authDomain: "aaa-minneapolis-2.firebaseapp.com",
  databaseURL: "https://aaa-minneapolis-2-default-rtdb.firebaseio.com",
  projectId: "aaa-minneapolis-2",
  storageBucket: "aaa-minneapolis-2.appspot.com",
  messagingSenderId: "554359023362",
  appId: "1:554359023362:web:9d768073fd9ea8069d841b",
  measurementId: "G-LP52R90NN7",
}

export const environment: Environment = {
  production: true,
  emulator: false,
  ngServe: false,
  appEnv: AppEnv.TEST,
  cloudFunctionsURL: "https://test.minneapolis.millisite.com",
  cloudStorageURL: `https://storage.googleapis.com/${firebaseConfig.storageBucket}`,
  clubId: ClubApp.Minneapolis,
  firebaseConfig: firebaseConfig,
  SMARTY_WEBSITE_KEY: "",
  paymentConfig: {
    cyberSourceSessionTimeout: 60 * 1000 * 10, // ten minutes
  },
  aaaRestWsUrl: "https://ww2.aaa.com",
  baseHref: "https://test.minneapolis.millisite.com/",
  zipcode: "55344",
}
