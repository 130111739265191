import { createFeatureSelector, createSelector } from "@ngrx/store"
import { MembershipOffersState } from "./membership-offers.models"
import { range } from "../utils/range"
import {
  getHasPromoCodePriceOffer,
  getPriceOfferLevelOffers,
  getPriceOfferLevels,
  getPriceOfferRawLevelOffers,
  PriceOffersQuery,
} from "../price-offers"
import { getClubId, getZipcode } from "../membership"
import { ClubApp } from "@aaa/emember/types"
import { getQueryCouponCode, getQueryPromoCode } from "../router/router.selectors"
import { compareLevels } from "../../constants/compares-charts/compares-charts"
import { OfferFilter } from "../price-offers/helpers/types"
import { createMembershipOfferSummary } from "../price-offers/helpers/create-membership-offer-summary"
import { environment } from "../../../environments/environment"
import {
  getPreviewMembershipCard,
  getPreviewMembershipOnSaleCard,
} from "../../modules/share/constants/membership-cards"
import { membershipOffers } from "../../constants/membership-offers/membership-offers"

export const MEMBERSHIP_OFFERS_FEATURE_KEY = "membership-offers"

export const getMembershipOffersState = createFeatureSelector<MembershipOffersState>(MEMBERSHIP_OFFERS_FEATURE_KEY)
export const getMembershipOfferQuantity = createSelector(getMembershipOffersState, ({ quantity }) => quantity)
export const getMembershipOfferPage = createSelector(getMembershipOffersState, ({ page }) => page)
export const getPriceOfferMembershipDefaultFilter = createSelector(
  getMembershipOfferQuantity,
  getHasPromoCodePriceOffer,
  (quantity, promoCode) => {
    const filter: OfferFilter = {
      promoCode: promoCode,
      medicalRider: false,
      autoRenew: false,
      associates: range(quantity - 1).map(() => {
        return { medicalRider: false }
      }),
    }
    return filter
  },
)
export const getMembershipOfferPriceQuery = createSelector(
  getZipcode,
  getQueryPromoCode,
  getQueryCouponCode,
  (zipCode, promoCode, couponCode) => {
    const query: PriceOffersQuery = {
      promoCode: promoCode || "",
      zipCode: zipCode || "",
      couponCode: couponCode.couponCode || "",
      programCode: couponCode.programCode || "",
    }

    return query
  },
)
export const getMembershipOfferSummary = (membershipType: string) =>
  createSelector(
    getPriceOfferLevels,
    getPriceOfferLevelOffers,
    getPriceOfferMembershipDefaultFilter,
    (memberships, membershipOffers, filter) => {
      const currentMembershipType = memberships.find((m) => m.membershipType === membershipType)?.membershipType

      if (currentMembershipType && membershipOffers) {
        const currentMembershipOffers = membershipOffers[currentMembershipType]

        return createMembershipOfferSummary(currentMembershipOffers, filter)
      }

      return null
    },
  )
export const getPriceMembershipLevel = (membershipType: string) =>
  createSelector(getMembershipOfferSummary(membershipType), getClubId, (offerSummary, clubId) => {
    let price = 0

    if (offerSummary) {
      price += offerSummary.primary ? offerSummary.primary.amount : 0

      if ([ClubApp.Northampton].includes(clubId)) {
        price += offerSummary.optional.reduce((acc, offer) => offer.amount + acc, 0)
        price += offerSummary.optionalPrimary.reduce((acc, offer) => offer.amount + acc, 0)
      }

      price += offerSummary.associates.reduce((acc, { associate, associateOptional }) => {
        let sum = 0
        sum += associate.reduce((acc, offer) => offer.amount + acc, 0)

        if ([ClubApp.Northampton, ClubApp.Shelby, ClubApp.SouthJersey].includes(clubId)) {
          sum += associateOptional.reduce((acc, offer) => offer.amount + acc, 0)
        }

        return sum + acc
      }, 0)
    }

    return price
  })
export const getPreviewMembershipLevelCard = (level: string) =>
  createSelector(getClubId, () => getPreviewMembershipCard(environment)[level] || "")

export const getPreviewMembershipOnSale = (level: string) =>
  createSelector(
    getClubId,
    getMembershipOfferPage,
    (clubId, page) => getPreviewMembershipOnSaleCard(clubId, page)[level] || "",
  )

export const getMembershipOfferTotalPlans = createSelector(getClubId, (clubId) => {
  switch (clubId) {
    case ClubApp.Northampton:
    case ClubApp.MidStates:
    case ClubApp.Hoosier:
    case ClubApp.Northway:
      return range(3)
    case ClubApp.Shelby:
      return range(2)
  }

  return []
})

export const getMembershipOfferCompares = createSelector(
  getPriceOfferLevels,
  getPriceOfferRawLevelOffers,
  getClubId,
  (levels, levelOffers, clubId) => compareLevels[clubId](levels, levelOffers),
)
export const getMembershipOfferDescription = createSelector(getClubId, getMembershipOfferPage, (clubId, page) =>
  membershipOffers[clubId](page),
)

export const getMembershipOfferDiscountInfo = createSelector(getMembershipOfferPage, getClubId, (page, clubId) => {
  switch (page) {
    case "offers":
      if (clubId === ClubApp.Northampton) {
        return "All discounts will be applied to your total at checkout."
      }

      return ""
    case "gift-offers":
      return ""
  }

  return ""
})

export const getMembershipOfferBackgroundImgUrl = createSelector(getMembershipOfferPage, (page) => {
  switch (page) {
    case "offers":
      return `${environment.cloudStorageURL}/${environment.clubId}/emember/revision/offer-emember-roadside.jpg`
    case "gift-offers":
      return `${environment.cloudStorageURL}/${environment.clubId}/emember/revision/gift-offer-emember-roadside.jpg`
  }

  return ""
})
