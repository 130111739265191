import { Component, Inject, OnInit } from "@angular/core"
import { RxState } from "@rx-angular/state"
import {
  AccountMembershipView,
  CheckoutStep,
  HOOSIER_RX_STATE,
  HoosierState,
} from "../../hoosier.service"
import { AccountStatus } from "../flows/account"
import { FormGroup } from "@angular/forms"
import { RxEffects } from "@rx-angular/state/effects"
import { combineLatestWith, tap } from "rxjs"
import { PricePreviewsResponseObject } from "../../events/price-previews"
import { MemberLookupResponseObject } from "../../events/member-lookup"
import { LinkService } from "../../../../../services/link"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../../services/state"
import { ChangeLevelFormOptionsService } from "../../form-field-options/change-level"
import { OpStatus } from "../../../join-renew.service"
import { TransitionService } from "../../services/transition"
import {
  connectSuiteMembershipCodes,
  ConnectSuiteMembershipLevel,
  MembershipConnectSuiteRecostValidateChangeLevelEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipConnectSuite"

export enum Action {
  DOWNGRADE = "DOWNGRADE",
  NONE = "NONE",
  REINSTATE = "REINSTATE",
  RENEW = "RENEW",
  UPGRADE = "UPGRADE",
  UPGRADE_NOT_ALLOWED_WITH_RV = "UPGRADE_NOT_ALLOWED_WITH_RV",
}

export interface Card {
  action: Action
  cta: string
  description: {
    title: string
    towingMiles: string
  },
  newLevel: MembershipConnectSuiteRecostValidateChangeLevelEventPayload["newLevel"],
  price: {
    associate: number
    primary: number
  }
}

@Component({
  selector: "ava-hoosier-account-membership-level",
  templateUrl: "./level.html",
})
export class LevelComponent implements OnInit {
  protected readonly AccountStatus = AccountStatus;
  globalState$ = this.globalState.select()
  hoosierState$ = this.hoosierState.select()
  membershipStatus$ = this.hoosierState.select("accountDetails", (accountDetails) => accountDetails.status)
  options = this.changeLevelFormOptionsService.options
  form: FormGroup | undefined
  Action = Action
  AccountMembershipView = AccountMembershipView
  CheckoutStep = CheckoutStep
  OpStatus = OpStatus
  backgroundColors = {
    PREMIER: "#0C2B53",
    PLUS: "#123d74",
    CLASSIC: "#25538F",
  }
  cards: Card[] = []
  cardsText = [
    {
      towingMiles: "3 miles",
      level: ConnectSuiteMembershipLevel.CLASSIC,
      rv: false,
    },
    {
      towingMiles: "100 miles",
      level: ConnectSuiteMembershipLevel.PLUS,
      rv: false,
    },
    {
      towingMiles: "100 miles",
      level: ConnectSuiteMembershipLevel.PLUS,
      rv: true,
    },
    {
      towingMiles: "200 miles",
      level: ConnectSuiteMembershipLevel.PREMIER,
      rv: false,
    },
  ]
  // alert: {
  //   message: string
  //   type: AlertType
  // } = null
  downgradePopConfirmTitle = "To change your membership benefits, please call 1-844-462-2246."
  // downgradePopConfirmTitle = "Please confirm your request to downgrade your membership coverage effective on " + h.accountDetails.expiresDateFormatted + "."

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private linkService: LinkService,
    private changeLevelFormOptionsService: ChangeLevelFormOptionsService,
    private transitionService: TransitionService,
  ) {
    if (hoosierState.get("activeCheckoutStep") !== CheckoutStep.CONFIRMATION) {
      hoosierState.set("activeCheckoutStep", () => null)
    }
  }

  cards$ = this.hoosierState.select("PRICE_PREVIEWS")
    .pipe(
      combineLatestWith(
        this.hoosierState.select("MEMBER_LOOKUP"),
      ),
      tap(([PRICE_PREVIEWS, MEMBER_LOOKUP]: [PricePreviewsResponseObject | null, MemberLookupResponseObject | null]) => {
        const cards: Card[] = []
        if (PRICE_PREVIEWS && MEMBER_LOOKUP) {
          const currentComponentCode = MEMBER_LOOKUP.response.memberInfo.membership.primaryMember.duesComponent.attributes.componentCode
          const currentMembershipCode = connectSuiteMembershipCodes
            .find(code => code.duesComponentCode === currentComponentCode)

          // const prices = PRICE_PREVIEWS.response.promoNoEnrollPrices
          const prices = PRICE_PREVIEWS.response.noPromoNoEnrollPrices

          const currentPricePreviewsPrimaryFee = prices
            .find(pricePreview => pricePreview.level === currentMembershipCode?.level && pricePreview.rv === currentMembershipCode?.rv)
            ?.primaryFee

          for (const pricePreview of prices) {
            const pricePreviewMembershipCode = connectSuiteMembershipCodes
              .find(code => code.level === pricePreview.level && code.rv === pricePreview.rv)
            const pricePreviewsPrimaryFee = prices
              .find(pricePreview => pricePreview.level === pricePreviewMembershipCode?.level && pricePreview.rv === pricePreviewMembershipCode?.rv)
              ?.primaryFee

            const action = currentComponentCode === pricePreviewMembershipCode?.duesComponentCode
              ? this.hoosierState.get("accountDetails").status === AccountStatus.PENDING
                ? Action.RENEW // current membership is PENDING
                : Action.NONE // current membership is ACTIVE or EXPIRED/CANCELLED (not PENDING)
              : pricePreviewsPrimaryFee && currentPricePreviewsPrimaryFee && pricePreviewsPrimaryFee > currentPricePreviewsPrimaryFee
                ? this.hoosierState.get("accountDetails", "code")?.rv // higher cost than current membership
                  ? Action.UPGRADE_NOT_ALLOWED_WITH_RV
                  : Action.UPGRADE
                : Action.DOWNGRADE // lower cost than current membership
            const card: Card = {
              action: action,
              cta: action === Action.UPGRADE ? "Upgrade" : action === Action.DOWNGRADE ? "Downgrade" : "",
              description: {
                title: connectSuiteMembershipCodes
                  .find(code => code.level === pricePreview.level && code.rv === pricePreview.rv)
                  ?.label || "",
                towingMiles: this.cardsText
                  .find(cardText => cardText.level === pricePreview.level && cardText.rv === pricePreview.rv)
                  ?.towingMiles || "",
              },
              newLevel: {
                level: pricePreview.level,
                rv: pricePreview.rv,
              },
              price: {
                associate: pricePreview.associateFee,
                primary: pricePreview.primaryFee,
              },
            }
            cards.push(card)
          }
        }
        this.cards = cards
      }),
    )

  ngOnInit(): void {
    this.form = this.hoosierState.get("form")
    this.rxEffects.register(this.cards$)
  }

  changeStep(event: Event, previousStep: CheckoutStep, nextStep: CheckoutStep, status: OpStatus): void {
    event.stopPropagation()
    this.transitionService.changeStep(previousStep, nextStep, status)
  }
}
