import {
  AfterContentInit,
  Directive,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core"
import { ControlContainer } from "@angular/forms"
import { Subject, takeUntil } from "rxjs"
import { distinctUntilChanged, tap } from "rxjs/operators"
import isEqual from "lodash/isEqual"
import { differenceBetween } from "../../utils/difference-between"

@Directive({
  selector: "[avaConnectForm]",
  standalone: true,
})
export class ConnectFormDirective implements OnChanges, OnInit, OnDestroy, AfterContentInit {
  controlContainer = inject(ControlContainer)
  @Input() formValues: any
  @Output() formValuesChanged = new EventEmitter<any>()
  @Output() formStatusChanged = new EventEmitter<any>()
  @Output() afterContentInit = new EventEmitter<any>()

  alive$ = new Subject()

  get control() {
    return this.controlContainer?.control
  }

  ngOnInit() {
    this.control?.valueChanges
      ?.pipe(
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        tap((values) => this.formValuesChanged.emit(values)),
        takeUntil(this.alive$),
      )
      .subscribe()
    this.control?.statusChanges
      ?.pipe(
        tap((status) => this.formStatusChanged.emit(status)),
        takeUntil(this.alive$),
      )
      .subscribe()
  }

  ngAfterContentInit() {
    this.afterContentInit.emit()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formValues) {
      const diff = differenceBetween(this.control?.getRawValue(), this.formValues)

      if (Object.keys(diff).length) {
        this.control?.patchValue(diff, { emitEvent: false })
      }
    }
  }

  ngOnDestroy() {
    this.alive$.next(null)
    this.alive$.complete()
    this.control?.reset()
  }
}
