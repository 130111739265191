import { Inject, Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { AngularFireAnalytics } from "@angular/fire/compat/analytics"
import firebase from "firebase/compat/app"
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms"
import { Block, BlockReference, Column } from "../block/services/block"
import { FormService } from "./form"
import { GLOBAL_RX_STATE, GlobalState, StateService } from "./state"
import { DataReadService } from "./data-read"
import { RevisionReadService } from "./revision-read"
import { RevisionWriteService } from "./revision-write"
import { BlockService } from "../block/services/block"
import { RxState } from "@rx-angular/state"
import { LOCATION, WINDOW } from "@ng-web-apis/common"
import { StorageService } from "./storage"
import { AngularFireStorage } from "@angular/fire/compat/storage"
import { FieldType } from "./field"
import Timestamp = firebase.firestore.Timestamp
import WriteBatch = firebase.firestore.WriteBatch
import Firestore = firebase.firestore.Firestore
import Storage = firebase.storage.Storage

export interface CreateBlockOptions {
  newBlockId: string
  newRowIndex: number | undefined
  sourceBlock: Block | undefined
  sourceStateId: string | undefined
  template: boolean
  parentId: string | undefined
  updateParent: boolean
  importing: boolean
  importDb: Firestore | undefined
  importClubId: string
  importBlockId: string
  importProjectId: string
  importStorage: Storage | undefined
}

@Injectable({
  providedIn: "root",
})
export class DataWriteService {
  // pageBlock: PageBlock
  // blocks: { [key: string]: Block } = {}
  // metaData = this.window.metaData
  // clubId = this.window.metaData.clubId

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(LOCATION)
    private location: Location,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private afs: AngularFirestore,
    private afStorage: AngularFireStorage,
    private angularFireAnalytics: AngularFireAnalytics,
    private dataReadService: DataReadService,
    private domWindow: Window,
    private formService: FormService,
    private revisionReadService: RevisionReadService,
    private revisionWriteService: RevisionWriteService,
    private stateService: StateService,
    private storageService: StorageService,
    private multiblockService: BlockService,
  ) {
  }

  transferBlocks(blocks: Block[]): Promise<void> {
    const batch = this.afs.firestore.batch()
    for (const block of blocks) {
      block.status.session = this.globalState.get("userSession", "sessionId")
      batch.set(
        this.dataReadService.blockRef.doc(block.id).ref, block,
      )
    }
    return batch.commit()
  }

  removeOldBlocks(blocks: Block[]): Promise<void> {
    const batch = this.afs.firestore.batch()
    for (const block of blocks) {
      batch.delete(
        this.dataReadService.oldBlockRef
          .doc(block.id).ref,
      )
    }
    return batch.commit()
  }

  simpleDeleteBlock(blockId: string): void {
    const batch = this.afs.firestore.batch()
    this.deleteBlock(batch, blockId)
    batch.commit().then()
  }

  /**
   * TODO: delete shared blocks if in SharedBlockEditMode
   */
  private deleteBlock(batch: WriteBatch, blockId: string): WriteBatch {
    /**
     * Don't delete SharedBlocks
     */
    if (this.globalState.get("sharedBlocks")[blockId]) {
      return batch
    }

    batch.delete(
      this.dataReadService.blockRef
        .doc(blockId).ref,
    )
    batch.delete(
      this.dataReadService.revisionRef
        .doc(blockId).ref,
    )

    /**
     * Find all of this block's children if there are any.
     */
    /*
        const block = this.stateService.state[blockId].form.value
        if (block.columns?.length) {
          for (const column of block.columns) {
            if (column.rows?.length) {
              for (const rowId of column.rows) {
                /!**
                 * Recursively delete:
                 *   children of Page container
                 *   children of Multiblock block
                 *
                 * TODO: Add more types (if needed) to these recursive delete operations.
                 *!/
                if (block.blockType === "container") {
                  this.deleteBlock(batch, rowId)
                }
                if (block.containerType === "multiblock" && block.rows[rowId].type === "multiblock") {
                  this.deleteBlock(batch, block.rows[rowId].blockRef)
                }
              }
            }
          }
        }
    */
    return batch
  }

  async createBlock(options: CreateBlockOptions): Promise<void> {
    let batch = this.afs.firestore.batch()
    batch = await this.createBlockBatch(batch, options)
    return batch.commit()
  }

  async createBlockBatch(batch: WriteBatch, options: CreateBlockOptions): Promise<WriteBatch> {
    // console.log(options)
    let sourceBlockState = undefined
    let sourceForm = undefined
    if (options.sourceStateId) {
      sourceBlockState = this.stateService.states[options.sourceStateId]
      sourceForm = this.formService.forms[options.sourceStateId]
    }
    const parentBlockStateId = sourceBlockState?.get("parentBlockStateId") || options.parentId
    const parentColumnIndex = sourceBlockState?.get("parentColumnIndex") || 0
    // const parentRowIndex = sourceBlockState.get("parentRowIndex")
    // if (!sourceForm?.value && !options.sourceBlock) {
    //   return
    // }
    const sourceBlock = options.sourceBlock || sourceForm?.value
    if (!sourceBlock) {
      return batch
    }

    const newBlockId = options.newBlockId || this.afs.createId()
    const newBlock = JSON.parse(JSON.stringify(sourceBlock))

    /**
     * make changes to parentForm
     * skip if creating:
     *   template
     *   recursive nested
     *   page
     */
    if (options.updateParent && parentBlockStateId) {
      const newRowId = this.afs.createId()
      const newRow: BlockReference = {
        blockId: newBlockId,
        fieldType: FieldType.BLOCK_REFERENCE,
      }
      const parentForm = this.formService.forms[parentBlockStateId]
      const parentColumnRowsFormArray = parentForm?.get(["columns", parentColumnIndex, "rows"]) as FormArray
      const parentRowsFormGroup = parentForm?.get(["rows"]) as FormGroup
      if (parentForm && parentColumnRowsFormArray && parentRowsFormGroup) {
        const newRowIndex = options.newRowIndex === undefined ? parentColumnRowsFormArray.controls.length : options.newRowIndex

        parentColumnRowsFormArray.insert(newRowIndex, new FormControl(newRowId))
        parentRowsFormGroup.addControl(newRowId, this.formService.newForm(newRow) as AbstractControl)
        this.revisionWriteService.updateEditRevisions([parentForm.getRawValue()])
      }
    }

    /**
     * make changes to sharedBlockForm
     */
    if (newBlock.blockShared) {
      /*
            this.stateService.state[newRowId].form.get(["pathName"]).reset()
            const sharedBlockPathNamesFormArray = this.stateService.state[newRowId].form.get(["pathNames"]) as FormArray
            if (sharedBlockPathNamesFormArray.value.indexOf(this.dataReadService.pathName) === -1) {
              sharedBlockPathNamesFormArray.push(new FormControl(this.dataReadService.pathName))
            }
      */
    }

    /**
     * fields can have references to other blocks
     * we need to recreate the references and copy these nested blocks
     * this includes all block.columns[].rows[] and all block.rows{}
     * the copy step recursively uses this createBlockBatch() function
     */
    newBlock.columns = []
    newBlock.rows = {}
    if (sourceBlock.columns?.length) {
      for (const sourceColumn of sourceBlock.columns) {
        // const newColumn: Column = this.multiblockService.newColumn
        const rows = []
        for (const [sourceRowIndex, sourceRowId] of sourceColumn.rows.entries()) {
          const newNestedRowId = this.afs.createId()
          rows.push(newNestedRowId)
          const newNestedRow = JSON.parse(JSON.stringify(sourceBlock.rows[sourceRowId]))
          newBlock.rows[newNestedRowId] = newNestedRow

          switch (sourceBlock.rows[sourceRowId].fieldType) {
            case FieldType.IMAGE:
            case FieldType.VIDEO:
              /**
               * copy external project file into this project bucket
               */
              /*
                                const firebaseConfig = this.globalState.get("environment", "firebaseConfig")
                                if (options.importing &&
                                  (firebaseConfig.projectId !== options.importProjectId || this.metaData.clubId !== options.importClubId)
                                ) {
                                  newBlock.rows[newNestedRowId].filePath = ""

                                  const imageRef = options.importStorage.ref(sourceBlock.rows[sourceRowId].filePath)
                                  /!**
                                   * NOT IMPORTING IMAGES AS IS, GETTING PERMISSIONS ERROR, user will need to re-upload images
                                   *
                                   * SDK version 9.5 provides better method for getting image, refactor this when available
                                   * https://firebase.google.com/docs/storage/web/download-files#download_data_directly_from_the_sdk
                                   *!/
                                  imageRef.getDownloadURL()
                                    .then((url) => {
                                      console.log(url)
                                      const xhr = new XMLHttpRequest()
                                      xhr.responseType = 'blob'
                                      xhr.onload = (event) => {
                                        console.log(event)
                                        const image = xhr.response

                                        const newFilePath = this.newFilePath
                                        const task = this.afStorage
                                          .upload(newFilePath, image, { cacheControl: "public, max-age=31104000" })
                                        task
                                          .snapshotChanges()
                                          .pipe(
                                            catchError(() => {
                                              alert("Unable to upload, a file with this filename already exists.")
                                              return of(undefined)
                                            })
                                          )
                                          .subscribe(snap => {
                                            if (snap) {
                                              if (snap.state === "success") {
                                                console.log("snap.state success")
                                                newBlock.rows[newNestedRowId].filePath = newFilePath
                                              }
                                            }
                                          })
                                      }
                                      xhr.open('GET', url)
                                      xhr.send()
                                    })
                                    .catch((error) => {
                                      console.log(error)
                                      // Handle any errors
                                    })
                                }
              */
              break
            case FieldType.BLOCK_REFERENCE: {
              const newNestedBlockId = this.afs.createId()
              const createBlockOptions: CreateBlockOptions = {
                newBlockId: newNestedBlockId,
                newRowIndex: sourceRowIndex,
                parentId: undefined,
                sourceBlock: {} as Block,
                sourceStateId: sourceRowId,
                template: false,
                updateParent: false,
                importing: options.importing,
                importDb: options.importDb,
                importClubId: options.importClubId,
                importBlockId: options.importBlockId,
                importProjectId: options.importProjectId,
                importStorage: options.importStorage,
              }
              const sourceRow = sourceBlock.rows[sourceRowId] as BlockReference
              newNestedRow.blockId = newNestedBlockId
              console.log("blockRef")
              /**
               * sourceRowId is also the sourceStateId for that nested block.
               */
              if (options.importing && options.importDb) {
                await options.importDb
                  .collection("wss-aaa-web")
                  .doc(options.importClubId)
                  .collection("multiblock")
                  .doc(sourceRow.blockId)
                  .get()
                  .then((querySnapshot) => {
                    createBlockOptions.sourceStateId = ""
                    createBlockOptions.importBlockId = sourceRow.blockId
                    createBlockOptions.sourceBlock = querySnapshot.data() as Block
                    console.log(createBlockOptions.sourceBlock)
                  })
                  .catch((error) => {
                    alert("failed importing from the source, the source data may be missing, have may have mis-configured the import data or you may be offline. Please try again.")
                    createBlockOptions.sourceStateId = ""
                    createBlockOptions.importBlockId = ""
                    createBlockOptions.sourceBlock = {} as Block
                    console.error(error)
                    console.error(sourceRow.blockId)
                  })

              }
              batch = await this.createBlockBatch(batch, createBlockOptions)
              break
            }
          }
          console.log(newBlock.rows[newNestedRowId])
        }
        const newColumn: Column = {
          accordion: sourceColumn.accordion,
          label: sourceColumn.label,
          width: sourceColumn.width,
          rows: rows,
        }
        newBlock.columns.push(newColumn)
      }
    }

    /**
     * create new block
     */
    // console.log(newBlock)
    if (!newBlock.blockShared) {
      newBlock.blockTemplate = options.template
      newBlock.id = newBlockId
      newBlock.pathName = options.template ? "" : this.globalState.get("location", "pathname")
      newBlock.pathNames = []
      newBlock.status = {
        created: Timestamp.now(),
        personalize: {},
        personalized: false,
        protected: false,
        revised: Timestamp.now(),
        session: this.globalState.get("userSession", "sessionId"),
        unpublished: false,
      }
      // this.stateService.newState(newBlockId)
      // console.log(newBlock)
      batch.set(this.dataReadService.blockRef.doc(newBlockId).ref, newBlock)
      batch.set(this.dataReadService.revisionRef.doc(newBlockId).ref, newBlock)
    }

    // }
    return batch
  }

  /**
   * Save all blocks on the page (blocks and SharedBlocks, but not TemplateBlocks).
   */
  saveAllEditRevisionsToBlocks(): Promise<void> {
    const stateIds: string[] = []
    for (const stateId in this.globalState.get("blockStateIdsChanged")) {
      const blockState = this.stateService.states[stateId]
      if (blockState && !blockState.get("blockValue", "blockTemplate")) {
        stateIds.push(stateId)
      }
    }
    return this.saveEditRevisionsToBlocks(stateIds)
  }

  /**
   * Saving edit-revisions to public-blocks.
   * Delete block if edit-revision is marked for deletion.
   *
   * This is where we can save individual blocks, SharedBlocks and TemplateBlocks.
   *
   * TODO: when saving a single block, we may need to manage the parent block's reference to any deletions.
   *
   */
  saveEditRevisionsToBlocks(stateIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch()
    for (const stateId of stateIds) {
      const formValue = this.formService.forms[this.stateService.states[stateId].get("block", "id")]?.getRawValue()
      if (formValue) {
        formValue.status.session = this.globalState.get("userSession", "sessionId")
        batch.set(this.dataReadService.blockRef.doc(formValue.id).ref, formValue)
      }
    }
    return batch.commit()
  }

  /**
   * Save public-blocks to edit-revisions.
   */
  cancelAllEdits(): Promise<void> {
    const stateIds: string[] = []
    for (const stateId in this.globalState.get("blockStateIdsChanged")) {
      const blockState = this.stateService.states[stateId]
      if (blockState && !blockState.get("blockValue", "blockTemplate")) {
        stateIds.push(stateId)
      }
    }
    return this.cancelEdits(stateIds)
  }

  cancelEdits(stateIds: string[]): Promise<void> {
    let batch = this.afs.firestore.batch()
    for (const stateId of stateIds) {
      delete this.formService.forms[stateId]
      const block = this.stateService.states[stateId].get("block")
      // let form = this.formService.forms[stateId]
      // const blockId = form.value.id
      // const block = this.globalState.get("blocks")[form.value.id]
      /**
       * Discard edit-revision by writing public-block to edit-revision
       */
      if (block) {
        const blockCopy = JSON.parse(JSON.stringify(block))
        blockCopy.status.session = "reset"
        // console.log(blockState.get("block", "columns")[0].rows)
        // form = this.formService.newForm(blockCopy) as FormGroup
        // this.formService.forms[stateId] = form
        // form.setValue(blockCopy)
        // console.log(form.get("columns.0.rows").value)
        // this.revisionReadService.reloadForm(stateId, blockId, blockCopy)
        batch = this.revisionWriteService.updateEditRevisionsBatch(batch, [blockCopy])
      }
    }
    /**
     * reset global state for modified state ids, will get re-evaluated after batch.commit() finishes
     * this did not do the trick, still did not work
     *
     * TODO: need to remove the stateId from modifiedBlockStates for the block that becomes an orphan
     */
    const blockStateIdsChanged = this.globalState.get("blockStateIdsChanged")
    const resetModifiedBlockIds = {} as { [key: string]: boolean }
    for (const modifiedBlockIdKey in blockStateIdsChanged) {
      resetModifiedBlockIds[modifiedBlockIdKey] = false
    }
    this.globalState.set("modified", () => false)
    this.globalState.set("blockStateIdsChanged", () => resetModifiedBlockIds)
    return batch.commit()
  }

}
