export enum FeatureName {
  ACCOUNT_INFORMATION = "ACCOUNT_INFORMATION",
  ACCOUNT_INFORMATION_HOME_ADDRESS = "ACCOUNT_INFORMATION_HOME_ADDRESS",
  ACCOUNT_INFORMATION_PHONE_NUMBER = "ACCOUNT_INFORMATION_PHONE_NUMBER",
  ACCOUNT_INFORMATION_EMAIL = "ACCOUNT_INFORMATION_EMAIL",
  ACCOUNT_INFORMATION_PASSWORD = "ACCOUNT_INFORMATION_PASSWORD",
  ACCOUNT_MEMBERSHIP = "ACCOUNT_MEMBERSHIP",
  ACCOUNT_INSURANCE = "ACCOUNT_INSURANCE",
  ACCOUNT_TRAVEL = "ACCOUNT_TRAVEL",
  ACCOUNT_FINANCIAL = "ACCOUNT_FINANCIAL",
  ACCOUNT_EMAIL_PREFERENCES = "ACCOUNT_EMAIL_PREFERENCES",
  ACCOUNT_LOGOUT = "ACCOUNT_LOGOUT",

  ADDRESS_AUTOCOMPLETE = "ADDRESS_AUTOCOMPLETE",

  ACCOUNT_MEMBERSHIP_RENEW = "ACCOUNT_MEMBERSHIP_RENEW",
  ACCOUNT_MEMBERSHIP_CARD = "ACCOUNT_MEMBERSHIP_CARD",
  ACCOUNT_MEMBERSHIP_AUTO_RENEW = "ACCOUNT_MEMBERSHIP_AUTO_RENEW",
  ACCOUNT_MEMBERSHIP_CHANGE_PLAN = "ACCOUNT_MEMBERSHIP_CHANGE_PLAN",
  ACCOUNT_MEMBERSHIP_UPDATE = "ACCOUNT_MEMBERSHIP_UPDATE",
  ACCOUNT_MEMBERSHIP_ASSOCIATE_VIEW = "ACCOUNT_MEMBERSHIP_ASSOCIATE_VIEW",
  ACCOUNT_MEMBERSHIP_ASSOCIATE_ADD = "ACCOUNT_MEMBERSHIP_ASSOCIATE_ADD",
  ACCOUNT_MEMBERSHIP_HOME_ADDRESS = "ACCOUNT_MEMBERSHIP_HOME_ADDRESS",
  ACCOUNT_MEMBERSHIP_PHONE_NUMBER = "ACCOUNT_MEMBERSHIP_PHONE_NUMBER",
  ACCOUNT_MEMBERSHIP_EMAIL = "ACCOUNT_MEMBERSHIP_EMAIL",
  ACCOUNT_MEMBERSHIP_CANCEL = "ACCOUNT_MEMBERSHIP_CANCEL",
  ACCOUNT_MEMBERSHIP_GIFT = "ACCOUNT_MEMBERSHIP_GIFT",

  MEMBERSHIP_ASSOCIATE_AUTO_RENEW = "MEMBERSHIP_ASSOCIATE_AUTO_RENEW",
  MEMBERSHIP_ASSOCIATE_PROMO_CODE = "MEMBERSHIP_ASSOCIATE_PROMO_CODE",

  QUICK_RENEW_PATCH_FORM_FROM_URL = "QUICK_RENEW_PATCH_FORM_FROM_URL",
}

export type Flag = {
  active: boolean
  name: FeatureName
}

export type FeatureFlagsState = {
  loaded: boolean
  flags: { [key: string]: Flag }
}
