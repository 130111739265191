import { MembershipCard } from "@aaa/emember/store-types"
import { ClubApp, Environment } from "@aaa/emember/types"
import { MembershipOfferPage } from "@aaa/emember/store-membership-offers"

export const getMembershipBlankCard = (env: Environment) => {
  const blankCards: MembershipCard[] = []

  if ([ClubApp.MidStates, ClubApp.Northampton].includes(env.clubId)) {
    blankCards.push({
      membershipLabel: "Premier RV",
      textColor: "black",
      filePath: `${env.cloudStorageURL}/${env.clubId}/emember/revision/premier-rv-300.png`,
    })
  }

  if (
    [ClubApp.MidStates, ClubApp.Hoosier, ClubApp.Northampton, ClubApp.Northway, ClubApp.SouthJersey].includes(
      env.clubId,
    )
  ) {
    blankCards.push({
      membershipLabel: "Premier",
      textColor: "black",
      filePath: `${env.cloudStorageURL}/${env.clubId}/emember/revision/premier-300.png`,
    })
  }

  if ([ClubApp.MidStates, ClubApp.Hoosier, ClubApp.Northampton, ClubApp.Northway].includes(env.clubId)) {
    blankCards.push({
      membershipLabel: "Plus RV",
      textColor: "black",
      filePath: `${env.cloudStorageURL}/${env.clubId}/emember/revision/plus-rv-300.png`,
    })
  }

  blankCards.push({
    membershipLabel: "Plus",
    textColor: "black",
    filePath: `${env.cloudStorageURL}/${env.clubId}/emember/revision/plus-300.png`,
  })

  blankCards.push({
    membershipLabel: [ClubApp.Hoosier].includes(env.clubId) ? "Classic" : "Basic",
    textColor: "black",
    filePath: `${env.cloudStorageURL}/${env.clubId}/emember/revision/basic-300.png`,
  })

  return blankCards
}

export const getPreviewMembershipCard = (env: Environment): { [key: string]: string } => {
  const BASIC = `${env.cloudStorageURL}/${env.clubId}/emember/revision/preview-basic.png`
  const PLUS = `${env.cloudStorageURL}/${env.clubId}/emember/revision/preview-plus.png`
  const PREMIER = `${env.cloudStorageURL}/${env.clubId}/emember/revision/preview-premier.png`

  return { CLASSIC: BASIC, BASIC, PLUS, PREMIER }
}

export const getPreviewMembershipOnSaleCard = (
  clubId: ClubApp,
  page: MembershipOfferPage,
): { [key: string]: string } => {
  let BASIC = "",
    PLUS = "",
    PREMIER = ""

  if (clubId === ClubApp.Hoosier) {
    BASIC = page == "gift-offers" ? "" : ""
    PLUS = page == "gift-offers" ? "MOST POPULAR" : "MOST POPULAR"
    PREMIER = page == "gift-offers" ? "" : ""
  }

  return { CLASSIC: BASIC, BASIC, PLUS, PREMIER }
}
