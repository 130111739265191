import { FormGroupValue } from "../../modules/share/form.utils"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { RequestError } from "../generic-errors"

export type PaymentType = "cybersource" | "shift4" | ""

export enum PaymentStatus {
  READY = "READY",
  INACTIVE = "INACTIVE",
  EMPTY = "EMPTY",
}
export enum PaymentInitializeStatus {
  RUNNING = "RUNNING",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
  EMPTY = "EMPTY",
}
export type PaymentState = {
  formValues: FormGroupValue<PaymentForm>
  paymentStatus: PaymentStatus
  paymentType: PaymentType
  error: RequestError | null
  initializeStatus: PaymentInitializeStatus
  generateStatus: PaymentInitializeStatus
  sessionToken: string
  paymentToken: string
}

export type Shift4ErrorType = {
  type: "validation_error"
  code: "incomplete_number" | "incomplete_expiry" | "invalid_expiry" | "incomplete_cvc"
  message: "string"
}
