<ng-container *ngIf="globalState$ | async as g">

  <div style="text-align: center">
    <div style="font-size: 60px; font-weight: 400">
      Thank You!
    </div>
    <div style="font-size: 32px; font-weight: 400">
      Welcome to AAA.
    </div>
    <div style="font-size: 32px; font-weight: 400; padding-bottom: 16px;">
      We’re happy to have you in the club.
    </div>
    <div style="font-size: 24px; font-weight: 200">
      Look for an email soon confirming your purchase.
    </div>
    <div style="font-size: 24px; font-weight: 200; padding-bottom: 16px;">
      Your Membership benefits may be used 24 hours from purchase date.
    </div>
  </div>
  <div style="margin: auto; max-width: 600px;">
    <div
      style="display: flex; gap: 16px; flex-direction: column; padding: 16px"
    >

      <ng-container *ngFor="let member of members; let membersIndex = index; let last = last">
        <div
          style="display: flex"
          [style.flex-direction]="flexDirection"
        >
          <div style="flex: 1">
            {{ membersIndex === 0 ? "Primary Member:" : "Household Member:" }}
            {{ membersIndex > 0 ? membersIndex.toString() : "" }}
          </div>
          <div style="flex: 1; font-weight: 600">
            {{ member.fullName }}
          </div>
        </div>

        <div
          style="display: flex"
          [style.flex-direction]="flexDirection"
        >
          <div style="flex: 1">
            Membership Level:
          </div>
          <div style="flex: 1">
            {{ member.membershipLevel }}
          </div>
        </div>

        <div
          style="display: flex"
          [style.flex-direction]="flexDirection"
        >
          <div style="flex: 1">
            Membership Number:
          </div>
          <div style="flex: 1">
            {{ member.membershipNumber }}
          </div>
        </div>

        <div
          style="display: flex"
          [style.flex-direction]="flexDirection"
        >
          <div style="flex: 1">
            Valid Thru:
          </div>
          <div style="flex: 1">
            {{ member.expirationDate }}
          </div>
        </div>

        <div
          style="display: flex"
          [style.flex-direction]="flexDirection"
        >
          <div style="flex: 1">
            User ID:
          </div>
          <div style="flex: 1">
            {{ member.membershipUserId }}
          </div>
        </div>

        <nz-divider *ngIf="!last"></nz-divider>

      </ng-container>
    </div>

  </div>
</ng-container>
