import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { JoinActions } from "./join.actions"
import { NotificationService } from "../../modules/share/notification/notification.service"
import { JoinFormVm } from "../../modules/join/components/join-form/join-form.vm"
import { bufferCount, map, switchMap, tap } from "rxjs/operators"
import { getFormValidationErrors, updateTreeValidity } from "../../modules/share/form.utils"
import { PaymentActions } from "../payment/payment.actions"
import { AlertCriticalErrorComponent } from "../../modules/share/alerts/alert-critical-error"
import { concat, delay, filter, first, from, of, race, withLatestFrom } from "rxjs"
import { Store } from "@ngrx/store"
import { getJoinFormPage, getJoinValidationError } from "./join.selectors"
import { Router } from "@angular/router"
import { RequestErrorType } from "../generic-errors"
import { setFormControlError } from "../../modules/share/form/validators/ser-form-control-errros"

@Injectable({ providedIn: "root" })
export class JoinEffects {
  actions$ = inject(Actions)
  store = inject(Store)
  joinFormVm = inject(JoinFormVm)
  router = inject(Router)
  notification = inject(NotificationService)

  validateFormStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.validateFormStep),
      switchMap(({ validateStep, currentStep, formValues }) => {
        const validateGiftRecipientInfo = currentStep === "giftReceiverInfo"
        const validateMemberInfo = currentStep === "memberInfo" && ["paymentInfo", "summary"].includes(validateStep)
        const validatePayment = currentStep === "paymentInfo" && ["summary"].includes(validateStep)

        if (validateGiftRecipientInfo) {
          const validateGiftRecipientInfo$ = of(null).pipe(
            tap(() => this.store.dispatch(JoinActions.validateGiftRecipient())),
            switchMap(() => this.actions$.pipe(ofType(JoinActions.validateGiftRecipientSucceeded), first())),
          )
          const validators = [validateGiftRecipientInfo$]

          // wait unit all passed
          return concat(...validators).pipe(
            bufferCount(validators.length),
            map(() => JoinActions.setFormActiveStep({ activeStep: "memberInfo" })),
          )
        }

        if (validateMemberInfo) {
          const stopStepErrorTypes = [RequestErrorType.JoinFormError, RequestErrorType.MembershipAlreadyExistingError]
          const validateMembershipInfo$ = of(null).pipe(
            tap(() => this.store.dispatch(JoinActions.validateMemberInfo())),
            switchMap(() => this.actions$.pipe(ofType(JoinActions.validateMemberInfoSucceeded), first())),
          )
          const validateRecost$ = of(null).pipe(
            tap(() => this.store.dispatch(JoinActions.recostValidate({ formValues }))),
            switchMap(() =>
              race(
                this.actions$.pipe(ofType(JoinActions.recostValidateSucceeded)),
                this.actions$
                  .pipe(ofType(JoinActions.recostValidateFailed))
                  .pipe(filter(({ error }) => !stopStepErrorTypes.includes(error.type))),
              ).pipe(first()),
            ),
          )

          const validators = [validateMembershipInfo$, validateRecost$]

          // wait unit all passed
          return concat(...validators).pipe(
            bufferCount(validators.length),
            map(() => JoinActions.setFormActiveStep({ activeStep: "paymentInfo" })),
          )
        }

        if (validatePayment) {
          const validateBillingInfo$ = of(null).pipe(
            tap(() => this.store.dispatch(JoinActions.validateBillingInfo())),
            switchMap(() => this.actions$.pipe(ofType(JoinActions.validateBillingInfoSucceeded), first())),
          )

          const validatePayment$ = of(null).pipe(
            tap(() => this.store.dispatch(PaymentActions.validate())),
            switchMap(() => this.actions$.pipe(ofType(PaymentActions.validateSucceeded), first())),
          )

          const validateGenerateToken$ = of(null).pipe(
            tap(() => this.store.dispatch(PaymentActions.generateToken())),
            switchMap(() => this.actions$.pipe(ofType(PaymentActions.generateTokenSucceeded), first())),
          )

          const validators = [validateBillingInfo$, validatePayment$, validateGenerateToken$]

          return concat(...validators).pipe(
            bufferCount(validators.length),
            map(() => JoinActions.setFormActiveStep({ activeStep: "summary" })),
          )
        }

        return of(JoinActions.setFormActiveStep({ activeStep: validateStep }))
      }),
    ),
  )

  validateGiftRecipientInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.validateGiftRecipient),
      map(() => {
        const invalidGiftInfoForm = this.joinFormVm.giftInfo.status !== "VALID"
        const invalidAssociateForm = this.joinFormVm.associates.status !== "VALID"

        if (invalidGiftInfoForm || invalidAssociateForm) {
          updateTreeValidity(this.joinFormVm.giftInfo)
          updateTreeValidity(this.joinFormVm.associates)

          return JoinActions.validateGiftRecipientInfoFailed()
        }

        return JoinActions.validateGiftRecipientSucceeded()
      }),
    ),
  )

  showGiftRecipientInfoNotifications$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(JoinActions.validateGiftRecipientInfoFailed, JoinActions.validateGiftRecipientSucceeded),
        tap(() => this.notification.clear()),
        tap(() => {
          if (this.joinFormVm.memberInfo.controls.membershipAssociates.invalid) {
            this.notification.show("Please complete Associates Information")
          }
          if (this.joinFormVm.giftInfo.controls.account.controls.email.invalid) {
            this.notification.show("Please complete Email")
          }
          if (this.joinFormVm.giftInfo.controls.account.controls.phone.invalid) {
            this.notification.show("Please complete Phone")
          }
          if (this.joinFormVm.giftInfo.controls.account.controls.birthday.invalid) {
            this.notification.show("Please complete Birthday")
          }
          if (this.joinFormVm.giftInfo.controls.account.controls.zipcode.invalid) {
            this.notification.show("Please complete Zip Code")
          }
          if (this.joinFormVm.giftInfo.controls.account.controls.city.invalid) {
            this.notification.show("Please complete City")
          }
          if (this.joinFormVm.giftInfo.controls.account.controls.address1.invalid) {
            this.notification.show("Please complete Address")
          }
          if (this.joinFormVm.giftInfo.controls.account.controls.lastName.invalid) {
            this.notification.show("Please complete Last Name")
          }
          if (this.joinFormVm.giftInfo.controls.account.controls.firstName.invalid) {
            this.notification.show("Please complete First Name")
          }
        }),
      ),
    { dispatch: false },
  )

  validateStepMemberInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.validateMemberInfo),
      map(() => {
        const validationErrors = getFormValidationErrors(this.joinFormVm.memberInfo)
        const skipValidationPromoError = validationErrors.filter(
          (validationError) => validationError.controlName !== "promoCode",
        )

        if (this.joinFormVm.memberInfo.status !== "VALID" && skipValidationPromoError.length) {
          updateTreeValidity(this.joinFormVm.memberInfo)

          return JoinActions.validateMemberInfoFailed()
        }

        return JoinActions.validateMemberInfoSucceeded()
      }),
    ),
  )

  showMemberInfoNotifications$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(JoinActions.validateMemberInfoFailed, JoinActions.validateMemberInfoSucceeded),
        tap(() => this.notification.clear()),
        tap(() => {
          if (this.joinFormVm.memberInfo.controls.membershipAssociates.invalid) {
            this.notification.show("Please complete Associates Information")
          }
          if (this.joinFormVm.memberInfo.controls.account.controls.password.invalid) {
            this.notification.show("Please complete Password")
          }
          if (this.joinFormVm.memberInfo.controls.account.controls.email.invalid) {
            this.notification.show("Please complete Email")
          }
          if (this.joinFormVm.memberInfo.controls.account.controls.phone.invalid) {
            this.notification.show("Please complete Phone")
          }
          if (this.joinFormVm.memberInfo.controls.account.controls.birthday.invalid) {
            this.notification.show("Please complete Birthday")
          }
          if (this.joinFormVm.memberInfo.controls.account.controls.zipcode.invalid) {
            this.notification.show("Please complete Zip Code")
          }
          if (this.joinFormVm.memberInfo.controls.account.controls.city.invalid) {
            this.notification.show("Please complete City")
          }
          if (this.joinFormVm.memberInfo.controls.account.controls.address1.invalid) {
            this.notification.show("Please complete Address")
          }
          if (this.joinFormVm.memberInfo.controls.account.controls.lastName.invalid) {
            this.notification.show("Please complete Last Name")
          }
          if (this.joinFormVm.memberInfo.controls.account.controls.firstName.invalid) {
            this.notification.show("Please complete First Name")
          }
        }),
      ),
    { dispatch: false },
  )

  validatePaymentStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.validateBillingInfo),
      map(() => {
        const billingInvalid = this.joinFormVm.billing.status !== "VALID"

        if (billingInvalid) {
          updateTreeValidity(this.joinFormVm.billing)

          return JoinActions.validateBillingInfoFailed()
        }

        return JoinActions.validateBillingInfoSucceeded()
      }),
    ),
  )

  addAssociate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.addAssociate),
      map(({ quantity }) => {
        for (let i = 1; i <= quantity; i++) {
          this.joinFormVm.createAssociate()
        }
      }),
      map(() => {
        const formValues = this.joinFormVm.formGroup.value
        return JoinActions.updateRecostValidate({ formValues })
      }),
    ),
  )

  removeAssociate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.removeAssociate),
      map(({ index }) => this.joinFormVm.removeAssociate(index)),
      map(() => {
        const formValues = this.joinFormVm.formGroup.value
        return JoinActions.updateRecostValidate({ formValues })
      }),
    ),
  )

  changedMembershipLevel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(JoinActions.changedMembershipLevel),
        switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getJoinFormPage)))),
        switchMap(([{ level, rv }, page]) => {
          const queryParams = { membershiplevel: level, rv: rv }
          const path = page === "join" ? "join" : "gift"

          return from(this.router.navigate([path], { queryParams, queryParamsHandling: "merge" }))
        }),
      ),
    { dispatch: false },
  )

  showErrorAlertPayJoinFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(JoinActions.payFailed),
        filter(({ error }) => error.type === RequestErrorType.MembershipError),
        map(() => this.notification.openFromComponent(AlertCriticalErrorComponent)),
      ),
    { dispatch: false },
  )

  setConfirmedMembersFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.payFailed),
      filter(({ error }) => [RequestErrorType.MembershipError].includes(error.type)),
      map(() => JoinActions.setConfirmedMembers({ members: [] })),
    ),
  )

  removePromoCodeErrors = createEffect(
    () =>
      this.actions$.pipe(
        ofType(JoinActions.recostValidateSucceeded),
        switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getJoinValidationError)))),
        filter(
          ([, error]) =>
            ![
              RequestErrorType.MembershipInvalidPromoCode,
              RequestErrorType.PricePreviewInvalidPromoCode,
              RequestErrorType.PricePreviewExpiredPromoCode,
            ].includes(error?.type),
        ),
        filter(() => !!this.joinFormVm.promoCode.errors),
        tap(() => {
          this.joinFormVm.promoCode.clearValidators()
          updateTreeValidity(this.joinFormVm.promoCode)
        }),
      ),
    { dispatch: false },
  )

  setPaymentFormActive = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.payFailed),
      filter(({ error }) =>
        [
          RequestErrorType.PaymentExpiredCard,
          RequestErrorType.PaymentInsufficientFund,
          RequestErrorType.PaymentInvalidCvv,
          RequestErrorType.PaymentInvalidData,
          RequestErrorType.PaymentPaymentRefused,
        ].includes(error.type),
      ),
      map(() => JoinActions.setFormActiveStep({ activeStep: "paymentInfo" })),
    ),
  )

  retryRecostWhenPromoCodeInvalid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.recostValidateFailed),
      filter(({ error }) =>
        [
          RequestErrorType.MembershipInvalidPromoCode,
          RequestErrorType.PricePreviewInvalidPromoCode,
          RequestErrorType.PricePreviewExpiredPromoCode,
        ].includes(error.type),
      ),
      tap(() => {
        this.joinFormVm.promoCode.setValidators(setFormControlError({ invalidPromoCode: true }))
        updateTreeValidity(this.joinFormVm.promoCode)
      }),
      map(() => {
        const formValues = structuredClone(this.joinFormVm.formGroup.value)

        if (formValues?.memberInfo?.membership?.promoCode) {
          formValues.memberInfo.membership.promoCode = ""
        }

        return JoinActions.retryRecostValidate({ formValues })
      }),
      delay(1000),
    ),
  )

  redirectConfirmationPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(JoinActions.setConfirmedMembers),
        switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getJoinFormPage)))),
        tap(([, page]) => {
          if (page === "join") {
            this.router.navigate(["join", "confirmation"])
          }

          if (page === "gift") {
            this.router.navigate(["gift", "confirmation"])
          }
        }),
      ),
    { dispatch: false },
  )

  stopPaymentTimer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.paySucceeded),
      map(() => PaymentActions.reset()),
    ),
  )

  resetForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(JoinActions.resetForm),
        tap(() => this.joinFormVm.resetForm()),
      ),
    { dispatch: false },
  )
}
