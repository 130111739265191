import { createFeatureSelector, createSelector } from "@ngrx/store"
import { RequestStatus } from "../../../types/request-status"
import { MembershipUpgradePlanState } from "./membership-upgrade-plan.models"
import { getClubOption, getMembershipCodes } from "../membership/membership.selectors"
import { getErrorMessage } from "../generic-errors"
import { AccountDetailsSelectors } from "../../modules/share/ngrx-helpers/account-details.selectors"

export const MEMBERSHIP_UPGRADE_PLAN_FEATURE_KEY = "membership-upgrade-plan"

export const getMembershipUpgradePlanFormState = createFeatureSelector<MembershipUpgradePlanState>(
  MEMBERSHIP_UPGRADE_PLAN_FEATURE_KEY,
)
export const getMembershipUpgradePlanExecutionData = createSelector(
  getMembershipUpgradePlanFormState,
  ({ executionData }) => executionData,
)
export const getMembershipUpgradeAccountDetails = createSelector(
  getMembershipUpgradePlanFormState,
  ({ accountDetails }) => accountDetails,
)
export const MembershipUpgradeAccountSelector = AccountDetailsSelectors(
  getMembershipUpgradeAccountDetails,
  getClubOption,
)
export const getMembershipUpgradePlanExecutionError = createSelector(
  getMembershipUpgradePlanFormState,
  ({ executionError }) => executionError,
)
export const getMembershipUpgradePlanExecutionRequestStatus = createSelector(
  getMembershipUpgradePlanFormState,
  ({ executionRequestStatus }) => executionRequestStatus,
)
export const getMembershipUpgradePlanFormActiveStep = createSelector(
  getMembershipUpgradePlanFormState,
  ({ formActiveStep }) => formActiveStep,
)
export const getMembershipUpgradePlanFormValues = createSelector(
  getMembershipUpgradePlanFormState,
  ({ formValues }) => formValues,
)
export const getMembershipUpgradePlan = createSelector(
  getMembershipUpgradePlanFormValues,
  getMembershipCodes,
  (formValues, membershipCodes) => {
    const membershipCode = membershipCodes.find((membershipCode) => {
      return membershipCode.level === formValues.level && membershipCode.rv === formValues.rv
    })

    return membershipCode
  },
)
export const getMembershipUpgradePlanSameBillingAddress = createSelector(
  getMembershipUpgradePlanFormValues,
  (formValues) => !!formValues.billing?.sameBillingAddress,
)
export const getMembershipUpgradePlanValidationRequestStatus = createSelector(
  getMembershipUpgradePlanFormState,
  ({ validationRequestStatus }) => validationRequestStatus,
)
export const getMembershipUpgradePlanErrors = createSelector(
  getMembershipUpgradePlanFormState,
  ({ validationError, executionError }) => validationError || executionError,
)
export const getMembershipUpgradePlanErrorsMessage = createSelector(
  getMembershipUpgradePlanErrors,
  getClubOption,
  (error, options) => getErrorMessage(error, options),
)
export const getMembershipUpgradePlanIsLoadingValidation = createSelector(
  getMembershipUpgradePlanValidationRequestStatus,
  (status) => status === RequestStatus.RUNNING,
)
export const getMembershipUpgradePlanValidateFailed = createSelector(
  getMembershipUpgradePlanValidationRequestStatus,
  (status) => status === RequestStatus.FAILED,
)
export const getMembershipUpgradePlanIsLoadingExecution = createSelector(
  getMembershipUpgradePlanExecutionRequestStatus,
  (status) => status === RequestStatus.RUNNING,
)
