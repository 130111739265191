import { Component, HostBinding, Input, ViewEncapsulation } from "@angular/core"
import { NgIf } from "@angular/common"
import { FieldSpanProps } from "../types"

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[ava-render-span]",
  template: `
    <ng-container *ngIf="props">
      {{ props?.text }}
    </ng-container>
  `,
  standalone: true,
  imports: [NgIf],
  encapsulation: ViewEncapsulation.None,
})
export class RenderSpanComponent {
  @Input() props?: FieldSpanProps
  @HostBinding("class") get getClass() {
    return this.props?.cls || ""
  }
}
