<nz-alert
  *ngIf="message || description"
  class="ava-alert"
  nzBanner
  [nzShowIcon]="nzShowIcon"
  [nzType]="type"
  [nzMessage]="messageTemplate"
  [nzDescription]="description ? descriptionTemplate : ''"
  [nzCloseable]="closeable"
/>
<ng-template #messageTemplate>
  <div [innerHTML]="message"></div>
</ng-template>
<ng-template #descriptionTemplate>
  <div [innerHTML]="description"></div>
</ng-template>
