import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core"
import { FieldComponent, FieldComponentType, FieldSectionProps } from "./types"
import { JsonPipe, NgForOf, NgIf, NgSwitch, NgSwitchCase } from "@angular/common"
import { RenderIconComponent } from "./components/render-icon/render-icon.component"
import { RenderParagraphComponent } from "./components/render-paragraph/render-paragraph.component"
import { RenderSpanComponent } from "./components/render-span.component"
import { RenderH2Component } from "./components/render-h2.component"
import { RenderH3Component } from "./components/render-h3.component"

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[ava-render]",
  templateUrl: "./render.component.html",
  standalone: true,
  imports: [
    NgForOf,
    NgSwitchCase,
    NgSwitch,
    RenderIconComponent,
    RenderParagraphComponent,
    JsonPipe,
    NgIf,
    RenderSpanComponent,
    RenderH2Component,
    RenderH3Component,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenderComponent {
  readonly FieldComponentType = FieldComponentType
  @Input() fields: FieldComponent[] = []
  @Input() props?: FieldSectionProps

  @HostBinding("class") get getClass() {
    return this.props?.cls || ""
  }
}
