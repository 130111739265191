import { Component } from "@angular/core"
import { SmartWeatherModule } from "../modules/smart-weather/smart-weather.module"

@Component({
  selector: "ava-smart-weather-custom-element",
  template: `
    <ava-smart-weather></ava-smart-weather>
  `,
  standalone: true,
  imports: [
    SmartWeatherModule
  ],
})
export class SmartWeatherCustomElementComponent {
}
