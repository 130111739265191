import { createReducer, on } from "@ngrx/store"
import { PaymentActions } from "./payment.actions"
import { PaymentInitializeStatus, PaymentState, PaymentStatus } from "./payment.model"

export const initialStatePayment: PaymentState = {
  formValues: { autoRenew: true },
  paymentType: "cybersource",
  paymentStatus: PaymentStatus.EMPTY,
  error: null,
  initializeStatus: PaymentInitializeStatus.EMPTY,
  generateStatus: PaymentInitializeStatus.EMPTY,
  sessionToken: "",
  paymentToken: "",
}

export const paymentReducer = createReducer(
  initialStatePayment,
  on(PaymentActions.initCardForm, (state, { paymentType }) => ({
    ...state,
    paymentType,
    paymentStatus: PaymentStatus.READY,
    initializeStatus: PaymentInitializeStatus.RUNNING,
    error: null,
  })),
  on(PaymentActions.initCardFormSucceeded, (state, { sessionToken }) => ({
    ...state,
    sessionToken,
    initializeStatus: PaymentInitializeStatus.SUCCESS,
    error: null,
  })),
  on(PaymentActions.initCardFormFailed, (state, { error }) => ({
    ...state,
    error,
    sessionToken: "",
    initializeStatus: PaymentInitializeStatus.FAILED,
  })),
  on(PaymentActions.generateToken, (state) => ({
    ...state,
    error: null,
    // paymentToken: "",
    generateStatus: PaymentInitializeStatus.RUNNING,
  })),
  on(PaymentActions.validateSucceeded, (state, { token }) => ({
    ...state,
    error: null,
    paymentToken: token || "",
  })),
  on(PaymentActions.generateTokenFailed, (state, { error }) => ({
    ...state,
    error,
    paymentToken: "",
    generateStatus: PaymentInitializeStatus.FAILED,
  })),
  on(PaymentActions.generateTokenSucceeded, (state, { paymentToken }) => ({
    ...state,
    paymentToken,
    error: null,
    generateStatus: PaymentInitializeStatus.SUCCESS,
  })),
  on(PaymentActions.changedAutoRenew, PaymentActions.setAutorenew, (state, { value }) => ({
    ...state,
    formValues: { ...state.formValues, autoRenew: value },
  })),
  on(PaymentActions.changedStatus, (state, { paymentStatus }) => ({ ...state, paymentStatus })),
  on(PaymentActions.changedForm, (state, { values }) => ({ ...state, formValues: values })),
  on(PaymentActions.reset, (state) => ({ ...state, ...initialStatePayment })),
)
