import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { AccountDetails, PhoneType } from "../types/types"
import { AccountAddressValues, AccountEmailValues, AccountPhoneValues } from "./account.model"
import { FormMode } from "../../modules/share/form.utils"
import { RequestError } from "../generic-errors"

export const AccountActions = createActionGroup({
  source: "Account",
  events: {
    // Address
    "Edit Address": emptyProps(),
    "Update Address": props<{ data: Partial<AccountAddressValues> }>(),
    "Update Address Succeeded": props<{ accountDetails?: AccountDetails }>(),
    "Update Address Failed": props<{ error: RequestError }>(),
    "Remove Address Error": emptyProps(),
    // Phone
    "Edit Phone Type": props<{ phoneType: PhoneType | null }>(),
    "Update Phone": props<{ data: Partial<AccountPhoneValues> }>(),
    "Update Phone Succeeded": props<{ accountDetails?: AccountDetails }>(),
    "Update Phone Failed": props<{ error: RequestError }>(),
    "Remove Phone Error": emptyProps(),
    // Email
    "Update Email": props<{ data: Partial<AccountEmailValues> }>(),
    "Update Email Succeeded": props<{ accountDetails?: AccountDetails }>(),
    "Update Email Failed": props<{ error: RequestError }>(),
    "Edit Email Form": props<{ formMode: FormMode }>(),
    "Remove Email Error": emptyProps(),
  },
})
