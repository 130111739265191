import { NotificationComponent } from "./notification.component"
import { ToastrModule } from "ngx-toastr"
import { NgModule } from "@angular/core"
import { NgIf } from "@angular/common"

@NgModule({
  declarations: [NotificationComponent],
  imports: [
    ToastrModule.forRoot({
      toastComponent: NotificationComponent,
      timeOut: 4000,
      progressBar: false,
      closeButton: true,
    }),
    NgIf,
  ],
})
export class NotificationModule {}
