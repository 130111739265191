import { createReducer, on } from "@ngrx/store"
import { QuickRenewFormState } from "./quick-renew.models"
import { QuickRenewActions } from "./quick-renew.actions"
import { RequestStatus } from "../../../types/request-status"

export const initialStateQuickRenew: QuickRenewFormState = {
  validationRequestStatus: RequestStatus.UNSENT,
  validationError: null,
  executionData: null,
  executionRequestStatus: RequestStatus.UNSENT,
  executionError: null,
  accountDetails: null,
}

export const quickRenewReducer = createReducer(
  initialStateQuickRenew,
  on(QuickRenewActions.recostValidate, (state) => ({
    ...state,
    validationRequestStatus: RequestStatus.RUNNING,
    validationError: null,
    executionData: null,
  })),
  on(QuickRenewActions.recostValidateSucceeded, (state, { executionData }) => ({
    ...state,
    executionData,
    validationError: null,
    validationRequestStatus: RequestStatus.SUCCESS,
  })),
  on(QuickRenewActions.recostValidateFailed, (state, { error }) => ({
    ...state,
    validationError: error,
    validationRequestStatus: RequestStatus.FAILED,
    executionData: "",
  })),
  on(QuickRenewActions.pay, (state) => ({
    ...state,
    executionRequestStatus: RequestStatus.RUNNING,
    executionError: null,
  })),
  on(QuickRenewActions.paySucceeded, (state) => ({
    ...state,
    executionError: null,
    executionRequestStatus: RequestStatus.SUCCESS,
  })),
  on(QuickRenewActions.payFailed, (state, { error }) => ({
    ...state,
    executionError: error,
    executionRequestStatus: RequestStatus.FAILED,
  })),
  on(QuickRenewActions.setAccountDetails, (state, { accountDetails }) => ({ ...state, accountDetails })),
  on(QuickRenewActions.resetPayment, (state) => ({
    ...state,
    validationRequestStatus: initialStateQuickRenew.validationRequestStatus,
    validationError: initialStateQuickRenew.validationError,
    executionData: initialStateQuickRenew.executionData,
    executionError: initialStateQuickRenew.executionError,
    accountDetails: initialStateQuickRenew.accountDetails,
  })),
  on(QuickRenewActions.resetConfirmation, (state) => ({
    ...state,
    executionRequestStatus: initialStateQuickRenew.executionRequestStatus,
  })),
)
