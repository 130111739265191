import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core"
import { ControlContainer, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms"
import { NzIconModule } from "ng-zorro-antd/icon"
import { CommonModule } from "@angular/common"
import { JoinPage } from "@aaa/emember/store-join"
import { FormInputComponent } from "../form/controls/input"
import { FormSelectComponent } from "../form/controls/select"
import { ButtonComponent } from "../ava-button/ava-button.component"
import { FormDateComponent } from "../form/controls/date"
import { FormPasswordComponent } from "../form/controls/password"
import { FormRadioComponent } from "../form/controls/radio"
import { FormTelComponent } from "../form/controls/tel"
import { MemberInfoFormVm } from "./member-info-form.vm"
import { MemberInfoForm } from "./member-info-form.model"
import { MembershipAssociateFormComponent } from "../membership-associate-form"
import { FormCheckBoxComponent } from "../form/controls/checkbox"
import { NzToolTipModule } from "ng-zorro-antd/tooltip"
import { getMembershipFormErrors } from "../../../store/form-messages"
import { Store } from "@ngrx/store"
import { AvaIconComponent } from "../ava-icon/ava-icon.component"
import { getMedicalPlanText } from "@aaa/emember/store-membership"
import { AccidentalMedicalCoverageComponent } from "../accidental-medical-coverage/accidental-medical-coverage"
import { AvaDialogService } from "../services/ava-dialog.service"
import { AppStore } from "@aaa/emember/store-types"
import { nameSuffix } from "../../../constants/name-suffix"
import { SmartyAddressComponent } from "../form/controls/smarty-address.component"

@Component({
  selector: "ava-member-info-form",
  templateUrl: "./member-info-form.html",
  styles: [
    `
      @import "../../../styles/ava-responsive";

      .ava-join-memberInfo {
        padding-bottom: 16px;

        &__suffix {
          max-width: 100px;
          width: 100%;
        }

        @include screenTabletDown() {
          .ava-join-memberInfo__group-controls {
            flex-direction: column;
          }
        }
      }
    `,
  ],
  imports: [
    FormInputComponent,
    FormSelectComponent,
    ReactiveFormsModule,
    NzIconModule,
    ButtonComponent,
    FormDateComponent,
    FormPasswordComponent,
    FormRadioComponent,
    FormTelComponent,
    CommonModule,
    MembershipAssociateFormComponent,
    FormCheckBoxComponent,
    NzToolTipModule,
    AvaIconComponent,
    SmartyAddressComponent,
  ],
  providers: [MemberInfoFormVm],
  standalone: true,
})
export class MemberInfoFormComponent implements OnInit {
  readonly nameSuffix = nameSuffix
  dialog = inject(AvaDialogService)
  memberInfoFormVm = inject(MemberInfoFormVm)
  controlContainer = inject(ControlContainer)
  store = inject(Store<AppStore>)
  @Input() accidentMedicalPlanPrice = 0
  @Input() showAccidentMedicalPlan = false
  @Input() showAddressAutocomplete = false
  @Input() showPassword = false
  @Input() page: JoinPage = "join"
  @Input() showPhoneNumber = false
  @Input() showBirthday = false
  @Input() requiredPhone = false
  @Input() showCardFormat = false
  @Input() cardTypes = []
  @Input() memberStates = []
  @Output() accidentMedicalChanged = new EventEmitter()
  @Output() dateChange = new EventEmitter()

  errorMessages$ = this.store.select(getMembershipFormErrors)
  medicalPlanText$ = this.store.select(getMedicalPlanText)
  get formGroup(): FormGroup<MemberInfoForm> {
    if (this.controlContainer) {
      return this.controlContainer.control as FormGroup<MemberInfoForm>
    }

    return this.memberInfoFormVm.create()
  }

  ngOnInit() {
    if (this.showPassword === false) {
      this.clearPasswordValidators()
    }

    if (this.showCardFormat === false) {
      this.clearCardFormatValidators()
    }

    if (this.requiredPhone === true) {
      this.makeRequirePhoneNumber()
    }

    if (this.page === "gift") {
      this.clearStateAsyncValidator()
    }
  }

  clearStateAsyncValidator() {
    this.formGroup.controls.account.controls.zipcode.setAsyncValidators([])
  }

  makeRequirePhoneNumber() {
    this.formGroup.controls.account.controls.phone.addValidators([Validators.required])
  }

  clearPasswordValidators() {
    this.formGroup.controls.account.controls.password.setValidators([])
  }

  clearCardFormatValidators() {
    this.formGroup.controls.membership.controls.cardFormat.setValidators([])
  }

  tooltipClicked() {
    this.dialog.openComponent({ nzContent: AccidentalMedicalCoverageComponent, nzFooter: null })
  }
}
