import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { OfficeAgentsComponent } from "./office-agents.component"
import { NzButtonModule } from "ng-zorro-antd/button"
import { NzDividerModule } from "ng-zorro-antd/divider"
import { NzCardModule } from "ng-zorro-antd/card"

@NgModule({
  exports: [
    OfficeAgentsComponent
  ],
  declarations: [
    OfficeAgentsComponent,
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    NzDividerModule,
    NzCardModule,
  ]
})
export class OfficeAgentsModule {
}
