import { Component, Inject, OnInit } from "@angular/core"
import { BatteryQuoterService } from "./battery-quoter.service"
import { AnalyticsEvent, AnalyticsScreenViewEvent, AnalyticsService } from "../../services/analytics"
import { environment } from "../../../environments/environment"
import { LOCATION } from "@ng-web-apis/common"
import { FormComponent } from "./components/form/form.component"
import { ResultsComponent } from "./components/results/results.component"
import { NoResultsComponent } from "./components/no-results/no-results.component"
import { CommonModule } from "@angular/common"
import firebase from "firebase/compat/app"
import EventName = firebase.analytics.EventName

@Component({
  selector: "ava-battery-quoter",
  templateUrl: "./battery-quoter.component.html",
  standalone: true,
  imports: [
    ResultsComponent,
    NoResultsComponent,
    BatteryQuoterComponent,
    FormComponent,
    CommonModule,
  ],
})
export class BatteryQuoterComponent implements OnInit {
  environment = environment

  constructor(
    @Inject(LOCATION)
    private location: Location,
    public bs: BatteryQuoterService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.bs.resetMake()
    this.bs.getMakes()
    this.bs.setComponentState("init", this.bs.ComponentState.FORM)

    const eventCustomElement: AnalyticsEvent = {
      eventCategory: "Custom Element",
      eventAction: "Supported",
      eventLabel: "<ava-battery-quoter>" + this.location.origin.toLowerCase() + this.location.pathname.toLowerCase(),
      eventValue: 1
    }
    this.analyticsService.sendEvent(eventCustomElement)

    const analyticsScreenViewEvent: AnalyticsScreenViewEvent = {
      eventName: EventName.SCREEN_VIEW,
      eventParams: {
        firebase_screen: "Battery-Quote",
        firebase_screen_class: "Battery-Quote",
        action: this.location.pathname.toLowerCase()
      },
    }
    this.analyticsService.sendFirebaseScreenViewEvent(analyticsScreenViewEvent)
  }

}
