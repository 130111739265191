import { Mzp } from "./mzp.type"
import { MembershipAssociateDetails } from "@aaa/emember/store-types"
import { MzpMembershipLevel, MzpPhoneType } from "@aaa/interface-joinRenew-membership-membershipMzp"

export const getMzpPrimaryCustomerInfo = (memberInfo: Mzp.MemberInfo): Mzp.MemberLookupCustomer => {
  const primaryMemberList = memberInfo.membership.memberList.find((l) => l.memberTypeCd === "P")
  const customer = memberInfo.customers.find(
    (c) => c.customerId === primaryMemberList?.customerId,
  ) as Mzp.MemberLookupCustomer

  return customer
}

export const getMzpCustomerPhone = (customerInfo: Mzp.MemberLookupCustomer, phoneType: MzpPhoneType) => {
  return (customerInfo?.customerPhoneList || []).find((phoneList) => phoneList.phoneTypeCd === phoneType)?.phone || ""
}

export const getMzpMembershipCode = (
  membership: Mzp.MemberInfo["membership"],
): {
  label: string
  level: MzpMembershipLevel
  rv: boolean
  membershipType: string
  duesComponentCode: string
} | null => {
  const membershipCode = Mzp.membershipCodes.find((code) => code.membershipType === membership.coverageLevelCd)

  if (membershipCode) {
    return {
      label: membershipCode.label,
      level: membershipCode.level,
      rv: membershipCode.rv,
      membershipType: membershipCode.membershipType,
      duesComponentCode: membershipCode.duesComponentCode,
    }
  }

  return null
}

export const getMzpPrimaryMemberListInfo = (
  memberList: Mzp.MemberLookupInfoMemberList[],
): Mzp.MemberLookupInfoMemberList =>
  (memberList || []).find((item) => item.memberTypeCd === "P") as Mzp.MemberLookupInfoMemberList

export const getMzpMemberListInfoById = (
  memberKy: number,
  memberList: Mzp.MemberLookupInfoMemberList[],
): Mzp.MemberLookupInfoMemberList =>
  (memberList || []).find((item) => item.memberKy === memberKy) as Mzp.MemberLookupInfoMemberList

export const getMzpBalanceTotal = ({ membership, balances }: Mzp.MemberInfo) => {
  const membershipTotal = balances.find((b) => b.key === membership.membershipKy)?.amount || 0
  const memberListTotal = membership.memberList.reduce((amount, memberList) => {
    const balance = balances.find((b) => b.key === memberList.membershipKy && b.type === "Member")?.amount || 0

    return amount + balance
  }, 0)

  return membershipTotal + memberListTotal
}

export const getMzpMembershipPrimary = ({ customers, membership }: Mzp.MemberInfo): MembershipAssociateDetails => {
  const result = (membership.memberList || [])
    .filter((m) => m.memberTypeCd == "P")
    .map((m) => {
      const custumer = customers.find((c) => c.customerId === m.customerId)

      return {
        dob: "",
        email: (custumer?.customerEmailList && custumer?.customerEmailList[0]?.email) || "",
        firstName: custumer?.firstName || "",
        lastName: custumer?.lastName || "",
        membershipNumber: "438" + "238" + m.membershipId + "" + m.associateId + "" + m.checkDigitNr,
        middleIntial: custumer?.middleName || "",
        nameSuffix: "",
        removeAssociate: false,
        // Need to implement
        offerItems: [],
      }
    })

  return result[0] as MembershipAssociateDetails
}
export const getMzpMembershipAssociates = ({ customers, membership }: Mzp.MemberInfo): MembershipAssociateDetails[] => {
  return (membership.memberList || [])
    .filter((m) => m.memberTypeCd !== "P")
    .map((m) => {
      const custumer = customers.find((c) => c.customerId === m.customerId)

      return {
        dob: "",
        email: (custumer?.customerEmailList && custumer?.customerEmailList[0]?.email) || "",
        firstName: custumer?.firstName || "",
        lastName: custumer?.lastName || "",
        membershipNumber: "438" + "238" + m.membershipId + "" + m.associateId + "" + m.checkDigitNr,
        middleIntial: custumer?.middleName || "",
        nameSuffix: "",
        removeAssociate: false,
        // Need to implement
        offerItems: [],
      }
    })
}
