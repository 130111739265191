import { Component, inject } from "@angular/core"
import { Store } from "@ngrx/store"
import { getClubOption } from "@aaa/emember/store-membership"
import { AsyncPipe, NgIf } from "@angular/common"
import { AppStore } from "@aaa/emember/store-types"

@Component({
  selector: "ava-alert-critical-error",
  template: ` <div style="font-size: 24px; font-weight: 400; text-align: center">
    We are experiencing technical difficulties. Please try again later or call member services at
    <a *ngIf="clubOption$ | async as clubOption" href="tel:{{ clubOption.phoneNumber }}">
      {{ clubOption.phoneNumberView }}
    </a>
    for immediate support.
  </div>`,
  standalone: true,
  imports: [AsyncPipe, NgIf],
})
export class AlertCriticalErrorComponent {
  store = inject(Store<AppStore>)
  clubOption$ = this.store.select(getClubOption)
}
