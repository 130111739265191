import { Component, Input, OnInit } from "@angular/core"
import { StateMachineService } from "../../../services/state-machine.service"
import { FormService } from "../../../services/form.service"
import { FormValues } from "@aaa/interface-agentScheduler"

@Component({
  selector: "ava-confirmation",
  templateUrl: "./confirmation.component.html"
})
export class ConfirmationComponent implements OnInit {
  @Input() formId: string | undefined
  formValues: FormValues | undefined
  showConfirmation: boolean = false

  constructor(
    private sms: StateMachineService,
    private formService: FormService,
  ) {
  }

  ngOnInit(): void {
    if (this.formId) {
      this.formValues = this.formService.formValues[this.formId]
    }
  }

  cancelButton(): void {
    if (this.formValues?.overrides.appointmentId) {
      this.showConfirmation = true
    }
  }

  handleCancel(): void {
    this.showConfirmation = false
  }

  handleOk(): void {
    this.sms.sendEvent("RESTART")
    this.showConfirmation = false
  }
}
