import { inject, Injectable } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { MemberInfoFormVm } from "@aaa/emember/share/member-info-form"
import { BillingFormVm } from "../../../share/billing-form"
import { JoinForm } from "@aaa/emember/store-join"
import { MembershipAssociateVm } from "../../../share/membership-associate-form"
import { GiftRecipientInfoFormVm } from "../../../share/gift-recipient-info-form"

@Injectable({ providedIn: "root" })
export class JoinFormVm {
  fb = inject(FormBuilder)
  memberInfoFormVm = inject(MemberInfoFormVm)
  billingFormVm = inject(BillingFormVm)
  giftRecipientInfoFormVm = inject(GiftRecipientInfoFormVm)
  membershipAssociateVm = inject(MembershipAssociateVm)
  formGroup = this.fb.group<JoinForm>({
    memberInfo: this.memberInfoFormVm.create(),
    billing: this.billingFormVm.create(),
    giftInfo: this.giftRecipientInfoFormVm.create(),
  })

  get memberInfo() {
    return this.formGroup.controls.memberInfo
  }

  get giftInfo() {
    return this.formGroup.controls.giftInfo
  }

  get account() {
    return this.memberInfo.controls.account
  }

  get promoCode() {
    return this.memberInfo.controls.membership.controls.promoCode
  }

  get billing() {
    return this.formGroup.controls.billing
  }

  get associates() {
    return this.memberInfo.controls.membershipAssociates
  }

  resetForm() {
    this.memberInfo.controls.membershipAssociates.clear()
    this.formGroup.reset()
  }

  createAssociate() {
    const associate = this.membershipAssociateVm.createAssociate()
    this.memberInfo.controls.membershipAssociates.push(associate)
  }

  removeAssociate(index: number) {
    if (this.memberInfo.controls.membershipAssociates.at(index)) {
      this.memberInfo.controls.membershipAssociates.removeAt(index)
    }
  }
}
