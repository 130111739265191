import { Injectable } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { FormArrayOptions, FormService } from "../../services/form"
import { ScreenSize } from "../../services/style"
import { FieldType } from "../../services/field"
import { TemplateType } from "../../options/template/service"
import { TargetOption, TargetType } from "../../services/link"
import { FieldBase } from "../../block/services/block"

export interface Image extends FieldBase {
  altText: string
  filePath: string
  fileType: "image/png" | "image/jpeg" | "image/gif" | null
  targetOption: TargetType
  targetPath: string
}

export interface ImageEditorOptions {
  screenSize?: ScreenSize // new pattern
  templateType?: TemplateType // new pattern

  alt: boolean
  cropper: boolean
  description: string
  dimensions: string
  minWidth: {
    px: number
  }
  maxWidth: {
    px: number
  }
  minHeight: {
    px: number
  }
  maxHeight: {
    px: number
  }
  target: TargetType[]
  targetOption: boolean
  targetPath: boolean

  options?: ImageOption[] // Deprecated
  formArray?: ThisFormArrayOptions // Deprecated
  newForm?: (item: Image) => AbstractControl | undefined // Deprecated
  newItem?: Image // Deprecated

  label: {
    placeholder: string
    plural: string,
    singular: string
  }
}

export interface ImageOption {
  description: string
  dimensions: string
  minWidth: {
    px: number
  }
  maxWidth: {
    px: number
  }
  minHeight: {
    px: number
  }
  maxHeight: {
    px: number
  }
  target: TargetOption[]
}

interface ThisFormArrayOptions extends FormArrayOptions {
  alt: boolean
  cropper: boolean
}


export interface ImageStyle {
  borderRadius: string | undefined
}

export interface ImageStyles {
  // justifyContent: AlignmentType["justifyContent"]
}


@Injectable({
  providedIn: "root"
})
export class ImageService {

  constructor(
    private formService: FormService,
  ) {
  }

  get newItem(): Image {
    return {
      altText: "image",
      filePath: "",
      fileType: null,
      targetOption: TargetType.SELF,
      targetPath: "",
      fieldType: FieldType.IMAGE,
    }
  }

  newForm = (item: Image): AbstractControl | undefined => {
    return this.formService.newForm(item)
  }

  get options(): ImageEditorOptions {
    const options: ImageEditorOptions = {
      alt: true,
      cropper: true,
      description: "900x600",
      dimensions: "900x600",
      minWidth: {
        px: 750
      },
      maxWidth: {
        px: 750
      },
      minHeight: {
        px: 500
      },
      maxHeight: {
        px: 500
      },
      target: [TargetType.SELF, TargetType.MODAL, TargetType.NEW],
      targetOption: true,
      targetPath: true,

      options: [],
      label: {
        placeholder: "",
        plural: "Images",
        singular: "Image"
      },
      formArray: {
        alt: true,
        cropper: true,
        max: 1,
        min: 0,
      },
      newForm: this.newForm,
      newItem: this.newItem,
    }
/*
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        description: "900x600",
        dimensions: "900x600",
        minWidth: {
          px: 750
        },
        maxWidth: {
          px: 750
        },
        minHeight: {
          px: 500
        },
        maxHeight: {
          px: 500
        },
        target: [TargetOption.SELF, TargetOption.MODAL, TargetOption.NEW],
      })
    }
*/
    return options
  }

}
