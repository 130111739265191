export function interfaceJoinRenewJoinRenewLib(): string {
  return "interface-join-renew-join-renew-lib"
}

// import firebase from "firebase/app"
// import "firebase/firestore"
import firebase from "firebase/compat/app"
import Timestamp = firebase.firestore.Timestamp
import { z } from "zod"

/**
 * Storage (realtime database)
 */
export enum Operation {
  JOIN = "JOIN",
  RENEW = "RENEW",
  UPDATE = "UPDATE",
  // CANCEL = "CANCEL",
}

export interface Event {
  afAuthIdToken?: string
  created: Timestamp
  eventName: EventName
  payload: EventPayload
  sessionId: string
  context?: {
    activeFlow?: string,
    activeView?: string,
  }
  updated?: number
}

export enum EventName {
  // payload interface managed by Event Coordinator
  OPERATION_EXECUTE = "OPERATION_EXECUTE",
  OPERATION_COMPLETE = "OPERATION_COMPLETE",

  //payload interface managed by selected payment subsystem
  PAYMENT_QUERY = "PAYMENT_QUERY",

  //payload interface managed by selected membership subsystem
  MEMBERSHIP_QUERY = "MEMBERSHIP_QUERY",

  //payload interface managed by selected notification subsystem
  NOTIFICATION_QUERY = "NOTIFICATION_QUERY",

  //payload interface managed by selected reporting subsystem
  REPORTING_QUERY = "REPORTING_QUERY",

  //internal things like statistic reporting or garbage collector
  SERVICES_QUERY = "SERVICES_QUERY"

}

export const CacheSettingsSchema = z.object({
  cacheType: z.enum(["save" , "clear"])
})
export type CacheSettings  = z.infer<typeof CacheSettingsSchema>

export const SaveCacheSettingsSchema = z.object({
  cacheType: z.enum(["save"]),
  maxAge: z.number(), // epoch ms
  context: z.string().optional()   // when this request is cached, save this context value with it
})
  .extend(CacheSettingsSchema.shape)
export type SaveCacheSettings = z.infer<typeof SaveCacheSettingsSchema>

export const EventPayloadSchema = z.object({
  responseKey: z.string().optional(),
  cacheSettings: CacheSettingsSchema.optional()
})
export type EventPayload = z.infer<typeof EventPayloadSchema>

export interface ClearCacheSettings extends CacheSettings {
  cacheType: "clear",
  clearContext: string    // when this request completes, delete any previously saved cache entry that has this context value
}

/**
 * Event Coordinator Events
 */
export interface OperationExecutePayload extends EventPayload {
  operation: Operation

  paymentEvent?: EventPayload | null
  membershipEvent?: EventPayload
}

export interface OperationCompletePayload extends EventPayload {
  success: boolean
}

export const OperationEventResponseSchema = z.object({
  operation: z.nativeEnum(Operation),
  ok: z.boolean(),
  response: z.any()
})
export interface OperationEventResponse<T = any> {
  operation: Operation
  ok: boolean
  response: T
}
export function makeOperationEventResponse<T>(schema: z.ZodType<T>) {
  return z.object({
    operation: z.nativeEnum(Operation),
    ok: z.boolean(),
    response: schema
  })
}

/**
 * Payment Subsystem Events
 */
export interface PaymentTesterEventPayload extends EventPayload {
  testProperty: string
}

export interface PaymentShift4EventPayload extends EventPayload {
  billingDetails?: Record<string, string>
}

/**
 * Membership Subsystem Events
 */
export interface MembershipTesterEventPayload extends EventPayload {
  testProperty: string
}


/**
 * Global Services Events
 */
export interface ServicesEventPayload extends EventPayload {
  method: "garbageCollection" | "ipify"
}
