import isArray from "lodash/isArray"
import isEqual from "lodash/isEqual"
import isObject from "lodash/isObject"
import transform from "lodash/transform"

export function differenceBetween(origObj: any, newObj: any) {
  function changes(newObj: any, origObj: any) {
    let arrayIndexCounter = 0

    return transform(newObj, (result: any, value, key) => {
      if (!isEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value
      }
    })
  }

  return changes(newObj, origObj)
}
