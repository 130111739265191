import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { MembershipActions } from "./membership.actions"
import { map } from "rxjs/operators"
import { tap } from "rxjs"
import { CookiesService } from "../../modules/share/services/cookies.service"
import { getMembershipBlankCard } from "../../modules/share/constants/membership-cards"

@Injectable({ providedIn: "root" })
export class MembershipEffects {
  actions$ = inject(Actions)
  cookies = inject(CookiesService)

  initialization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipActions.initialize),
      tap(({ env }) => (env.emulator && env.zipcode ? this.cookies.setZipCode(env.zipcode, env.clubId) : "")),
      map(({ env }) => MembershipActions.initializeSucceeded({ env })),
    ),
  )

  setMembershipCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipActions.initializeSucceeded),
      map(({ env }) => {
        const membershipCards = getMembershipBlankCard(env)

        return MembershipActions.setCards({ membershipCards })
      }),
    ),
  )
}
