import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { catchError, map, switchMap } from "rxjs/operators"
import { MembershipUpgradePlan } from "./membership-upgrade-plan.actions"
import { of, withLatestFrom } from "rxjs"
import { getPayment } from "../payment/payment.selectors"
import { MembershipCode, ValidateSucceededResponseObject } from "../types/types"
import { getMembershipUpgradePlan } from "./membership-upgrade-plan.selectors"
import { ExecuteService } from "../services/execute.service"
import { ClubApp } from "@aaa/emember/types"
import { FormGroupValue } from "../../modules/share/form.utils"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { Operation, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { getShortMembershipNumber } from "@aaa/emember/store-account"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import { MembershipUpgradePlanPayParam } from "./membership-upgrade-plan.models"
import {
  MembershipMzpGetMembershipCostsChange,
  MembershipMzpMethod,
  MembershipMzpOperationExecuteEventPayload,
  MzpMembershipLevel,
} from "@aaa/interface-joinRenew-membership-membershipMzp"
import { Mzp } from "../mzp.type"
import { getClearCacheSettings } from "../utils/get-cache-settings"
import { RequestError, RequestErrorType } from "../generic-errors"
import { checkCybersourcePaymentValidation } from "../check-cybersource-payment-validation"
import { AnalyticsPurchaseEvent } from "../../../types/analytics-purchase-event"
import { DataLayerService } from "../../modules/share/services/data-layer.service"
import { AppAnalyticsEvents } from "../../../types/analytics-events"
import { getTransactionId } from "../utils/get-transaction-id"

@Injectable({ providedIn: "root" })
export class MembershipUpgradePlanMzpSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.MidStates]))
  executeService = inject(ExecuteService)
  dataLayer = inject(DataLayerService)

  setSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.recostValidateSucceeded),
      map(({ response }: ValidateSucceededResponseObject<Mzp.MemberInfo>) => {
        const accountDetails = new Mzp.AccountInfo(response)
        return MembershipUpgradePlan.setAccountDetails({ accountDetails })
      }),
    ),
  )

  recostValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.recostValidate),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.select(getMembershipUpgradePlan), this.store.select(getShortMembershipNumber)),
        ),
      ),
      switchMap(([, membershipCode, membershipNumber]) =>
        this.recostValidation(membershipCode as MembershipCode, membershipNumber).pipe(
          map((res) => MembershipUpgradePlan.recostValidateSucceeded(res)),
          catchError((error) => of(MembershipUpgradePlan.recostValidateFailed({ error }))),
        ),
      ),
    ),
  )

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.pay),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getPayment)))),
      switchMap(([{ params }, payment]) =>
        this.pay(params, payment).pipe(
          map(() => MembershipUpgradePlan.paySucceeded()),
          catchError((error) => of(MembershipUpgradePlan.payFailed({ error }))),
        ),
      ),
    ),
  )

  pay(params: MembershipUpgradePlanPayParam, payment: { token: string; formValues: FormGroupValue<PaymentForm> }) {
    const membershipEvent: MembershipMzpOperationExecuteEventPayload = {
      executionData: params.executionData,
      cacheSettings: getClearCacheSettings(),
      method: MembershipMzpMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
    }
    const paymentPayload: PaymentCybersourceOperationExecuteEventPayload = {
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(params.formValues.billing?.billingTo?.address1 || ""),
          address2: String(params.formValues.billing?.billingTo?.address2 || ""),
          administrativeArea: String(params.formValues.billing?.billingTo?.state || ""),
          buildingNumber: "",
          country: "US",
          district: String(params.formValues.billing?.billingTo?.state || ""),
          email: String(params.accountDetails?.email || "fallback@avagate.com"),
          firstName: String(params.formValues.billing?.billingTo?.firstName || ""),
          lastName: String(params.formValues.billing?.billingTo?.lastName || ""),
          locality: String(params.formValues.billing?.billingTo?.city || ""),
          phoneNumber: String(params.accountDetails?.phone.cell || ""),
          postalCode: String(params.formValues.billing?.billingTo?.zipcode || ""),
        },
        amountDetails: {
          totalAmount: String(params.totalCost),
          currency: "USD",
        },
        creditCardBrandedName: payment.formValues?.card?.cardName || "",
      },
    }
    const payload: OperationExecutePayload = {
      membershipEvent: membershipEvent,
      paymentEvent: paymentPayload,
      operation: Operation.UPDATE,
    }

    return this.executeService.execute<Mzp.MemberAssociateAddPayResponseObject>(payload).pipe(
      map(({ validateObject, paymentObject, operationObject }) => {
        // Todo: implement membership error and payment error message
        const paymentError = !!paymentObject?.meta?.isError

        if (paymentError) {
          checkCybersourcePaymentValidation(paymentObject)
        }

        if (!validateObject) {
          throw new RequestError(RequestErrorType.UpgradedMembership, validateObject)
        }

        if (operationObject?.error) {
          throw new RequestError(RequestErrorType.UpgradedMembership, operationObject)
        }

        const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
          currency: "USD",
          transaction_id: getTransactionId(paymentObject),
          value: params.totalCost,
          items: [
            { quantity: 1, price: params.totalCost, item_id: "primary", item_name: AppAnalyticsEvents.AccountUpgrade },
          ],
          context: "ava-store " + AppAnalyticsEvents.AccountUpgrade,
        }
        this.dataLayer.purchaseEvent(analyticsEventParams)

        return new Mzp.AccountInfo(validateObject.response.mzpResponse)
      }),
    )
  }

  recostValidation(membershipCode: MembershipCode, memberNumber: string) {
    const payload: MembershipMzpGetMembershipCostsChange = {
      memberNumber,
      flow: "LEVEL",
      method: MembershipMzpMethod.GET_MEMBERSHIP_COSTS_CHANGE,
      membershipLevel: membershipCode.level as MzpMembershipLevel,
      rv: membershipCode.rv,
    }

    return this.executeService.membershipQuery<Mzp.MembershipAddAssociateRecostValidationResponseObject>(payload).pipe(
      map((validateObject) => {
        const membershipError = !!validateObject?.error

        if (membershipError) {
          throw new RequestError(RequestErrorType.UpgradedMembership, validateObject)
        }

        return {
          response: validateObject.response.validationData,
          executionData: validateObject.response.executionData,
        }
      }),
    )
  }
}
