import { inject } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivateChildFn, Router } from "@angular/router"
import { has } from "lodash-es"

export const checkExistMembershipLevel: CanActivateChildFn = ({ queryParams }: ActivatedRouteSnapshot) => {
  const router = inject(Router)
  const hasMembershipLevelQueryParam = has(queryParams, "membershiplevel")

  if (!hasMembershipLevelQueryParam) {
    router.navigate(["offers"], { queryParams })

    return false
  }

  return true
}

export default checkExistMembershipLevel
