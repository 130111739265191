import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import {
  MembershipNumberForm,
  QuickRenewForm,
  QuickRenewFormVm,
} from "../../modules/quick-renew/services/quick-renew-form.vm"
import { catchError, map, switchMap, tap } from "rxjs/operators"
import { QuickRenewActions } from "./quick-renew.actions"
import { Observable, of, withLatestFrom } from "rxjs"
import { Operation } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  getQuickRenewAccountDetails,
  getQuickRenewExecutionData,
  QuickRenewAccountDetails,
} from "./quick-renew.selectors"
import { FormGroupValue } from "../../modules/share/form.utils"
import { getPayment } from "@aaa/emember/store-payment"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import { AccountDetails, ValidateSucceededResponseObject } from "../types/types"
import {
  MembershipMzpGetMembershipCostsRenew,
  MembershipMzpMethod,
  MembershipMzpOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipMzp"
import { getMembershipStatus } from "../utils/get-membership-status"
import { AccountStatus } from "../../modules/join-renew/hoosier/components/flows/account"
import { ExecutePaymentResponseObject } from "../../modules/join-renew/hoosier/services/payment"
import { Mzp } from "../mzp.type"
import { ExecuteService } from "../services/execute.service"
import { ClubApp } from "@aaa/emember/types"
import { RequestError, RequestErrorType } from "../generic-errors"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { getClearCacheSettings } from "../utils/get-cache-settings"
import { checkCybersourcePaymentValidation } from "../check-cybersource-payment-validation"
import { checkMembershipErrorsMzpSystem } from "../check-membership-errors-mzp-system"
import { DataLayerService } from "../../modules/share/services/data-layer.service"
import { AnalyticsPurchaseEvent } from "../../../types/analytics-purchase-event"
import { AppAnalyticsEvents } from "../../../types/analytics-events"
import { getTransactionId } from "../utils/get-transaction-id"

@Injectable({ providedIn: "root" })
export class QuickRenewMzpSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.MidStates]))
  formVm = inject(QuickRenewFormVm)
  executeService = inject(ExecuteService)
  dataLayer = inject(DataLayerService)

  setSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.recostValidateSucceeded),
      map(({ response }: ValidateSucceededResponseObject<Mzp.MemberQuickRenewResponseObject>) => {
        const accountDetails = new Mzp.AccountInfo(response.response.validationData)

        return QuickRenewActions.setAccountDetails({ accountDetails })
      }),
    ),
  )

  updatePaymentCardHolderInformation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuickRenewActions.recostValidateSucceeded),
        tap(({ response }: ValidateSucceededResponseObject<Mzp.MemberQuickRenewResponseObject>) => {
          const customer = response.response.validationData.customers.find((customer) => customer.memberTypeCd === "P")
          this.formVm.paymentCardHolderFormGroup.patchValue({
            firstName: customer?.firstName,
            lastName: customer?.lastName,
          })
        }),
      ),
    { dispatch: false },
  )

  recostValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.recostValidate),
      switchMap(() =>
        this.recostValidate(this.formVm.membershipFormGroup.value).pipe(
          map((res) => QuickRenewActions.recostValidateSucceeded(res)),
          catchError((error) => of(QuickRenewActions.recostValidateFailed({ error }))),
        ),
      ),
    ),
  )

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.pay),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(getQuickRenewAccountDetails),
            this.store.select(getQuickRenewExecutionData),
            this.store.select(QuickRenewAccountDetails.getBalance),
            this.store.select(getPayment),
          ),
        ),
      ),
      switchMap(([, accountDetails, executionData, totalCost, payment]) =>
        this.pay(this.formVm.formGroup.value, accountDetails, executionData, totalCost, payment).pipe(
          map(() => QuickRenewActions.paySucceeded()),
          catchError((error) => of(QuickRenewActions.payFailed({ error }))),
        ),
      ),
    ),
  )

  pay(
    formValues: FormGroupValue<QuickRenewForm>,
    accountDetails: AccountDetails | null,
    executionData: string,
    totalCost: number,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
  ) {
    const membershipEvent: MembershipMzpOperationExecuteEventPayload = {
        cacheSettings: getClearCacheSettings(),
        executionData: executionData,
        method: MembershipMzpMethod.OPERATION_EXECUTE,
        operation: Operation.RENEW,
      },
      paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
        executionData: {
          flexMicroFormToken: payment.token,
          billTo: {
            address1: String(accountDetails?.address.street1),
            address2: String(accountDetails?.address.street2),
            administrativeArea: String(accountDetails?.address.state),
            buildingNumber: "",
            country: "US",
            district: String(accountDetails?.address.state),
            email: String(accountDetails?.email || "fallback@avagate.com"),
            firstName: formValues.paymentCardHolderFormGroup?.firstName || "",
            lastName: formValues.paymentCardHolderFormGroup?.lastName || "",
            locality: String(accountDetails?.address.city),
            phoneNumber: String(accountDetails?.phone.home),
            postalCode: String(accountDetails?.address.zip),
          },
          amountDetails: { totalAmount: String(totalCost), currency: "USD" },
          creditCardBrandedName: payment.formValues.card?.cardName || "",
        },
        method: PaymentCybersourceMethod.OPERATION_EXECUTE,
        operation: Operation.RENEW,
      },
      payload = {
        operation: Operation.RENEW,
        membershipEvent,
        paymentEvent,
      }

    return this.executeService.execute<Mzp.JoinExecuteResponseObject, ExecutePaymentResponseObject>(payload).pipe(
      map(({ validateObject, paymentObject }) => {
        const paymentError = !!paymentObject?.meta?.isError

        if (paymentError) {
          checkCybersourcePaymentValidation(paymentObject.error)
        }

        const membershipError = !!validateObject?.meta?.isError
        if (membershipError) {
          checkMembershipErrorsMzpSystem(validateObject.error, validateObject)
        }

        const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
          currency: "USD",
          transaction_id: getTransactionId(paymentObject),
          value: totalCost,
          membershipLevel: accountDetails?.code?.level,
          items: [
            { quantity: 1, price: totalCost, item_id: "primary", item_name: AppAnalyticsEvents.QuickRenewRenewal },
          ],
          context: "ava-store " + AppAnalyticsEvents.QuickRenewRenewal,
        }
        this.dataLayer.purchaseEvent(analyticsEventParams)

        return {
          membership: validateObject,
          payment: paymentObject,
        }
      }),
    )
  }

  recostValidate(
    membershipNumber: FormGroupValue<MembershipNumberForm>,
  ): Observable<ValidateSucceededResponseObject<Mzp.MemberQuickRenewResponseObject>> {
    const payload: MembershipMzpGetMembershipCostsRenew = {
      method: MembershipMzpMethod.GET_MEMBERSHIP_COSTS_RENEW,
      memberNumber: String(membershipNumber?.membershipNumber).slice(6, 13),
      promoData: { promoCode: "", couponCode: "", programCode: "" },
    }

    return this.executeService.membershipQuery(payload).pipe(
      map((validateObject) => {
        const executionData = validateObject.response?.executionData || ""
        const isError = validateObject.meta.isError

        if (isError) {
          throw new RequestError(RequestErrorType.MembershipError, validateObject)
        }

        if (getMembershipStatus(validateObject.response.validationData.membership.status) !== AccountStatus.PENDING) {
          throw new RequestError(RequestErrorType.MembershipNotExpiredYet, validateObject)
        }

        return { executionData, response: validateObject }
      }),
    )
  }
}
