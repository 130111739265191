import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { getMembershipNumber, MembershipActions } from "@aaa/emember/store-membership"
import { map, withLatestFrom } from "rxjs"
import { AccountActions } from "./account.actions"

@Injectable({ providedIn: "root" })
export class AccountEffects {
  actions$ = inject(Actions)
  store = inject(Store)

  loadMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AccountActions.updateAddressSucceeded,
        AccountActions.updatePhoneSucceeded,
        AccountActions.updateEmailSucceeded,
      ),
      withLatestFrom(this.store.select(getMembershipNumber)),
      map(([{ accountDetails }, membershipNumber]) => {
        if (accountDetails) {
          return MembershipActions.updateAccountDetails({ accountDetails })
        }

        return MembershipActions.load({ membershipNumber: String(membershipNumber) })
      }),
    ),
  )
}
