import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { filter, map, of, switchMap } from "rxjs"
import { MembershipActions } from "./membership.actions"
import { SaveCacheSettings } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  MembershipConnectSuiteMemberLookupEventPayload,
  MembershipConnectSuiteMethod,
} from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import { Store } from "@ngrx/store"
import { catchError, tap } from "rxjs/operators"
import { getCurrentTimeStamp } from "../utils/get-current-timestamp"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import { ConnectSuite } from "../connect-suite.type"
import { ExecuteService } from "../services/execute.service"
import { AccountStatus, ClubApp } from "@aaa/emember/types"
import { LocationRefService } from "../../modules/share/services/location-ref.service"
import { RequestError, RequestErrorType } from "../generic-errors"
import { WindowRefService } from "../../modules/share/services/window-ref.service"

export enum MemberShipResponseCode {
  SUCCESS = "000", // success
  CANCELLED = "024", // cancelled
  INVALID = "020", // invalid membership number
  INCORRECT = "022", // We were unable to locate your membership using the number provided.
}

@Injectable({ providedIn: "root" })
export class MembershipConnectSuiteSystemEffects {
  store = inject(Store)
  executeService = inject(ExecuteService)
  windowRef = inject(WindowRefService)
  location = inject(LocationRefService)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Hoosier, ClubApp.SouthJersey]))

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MembershipActions.logout),
        tap(() => (this.location.nativeLocation.href = this.location.nativeLocation.origin + "/account/logout")),
      ),
    { dispatch: false },
  )

  setMembershipCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipActions.initializeSucceeded),
      map(() => MembershipActions.setCodes({ membershipCodes: ConnectSuite.membershipCodes })),
    ),
  )

  loadMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipActions.load),
      switchMap(({ membershipNumber }) =>
        this.loadMemberShip(membershipNumber).pipe(
          map((accountDetails) => MembershipActions.loadSucceeded({ accountDetails })),
          catchError((error) => of(MembershipActions.loadFailed({ error }))),
        ),
      ),
    ),
  )

  // When account it is cancelled we need to read user data from metadata object
  setAccountDetailsFromMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipActions.loadFailed),
      filter(({ error }) => error.type === RequestErrorType.MembershipCancelled),
      map(() => {
        const accountDetails = this.getMetaDataAccountInfo(AccountStatus.CANCELLED)

        return MembershipActions.updateAccountDetails({ accountDetails })
      }),
    ),
  )

  loadMemberShip(membershipNumber: string) {
    const cacheSettings: SaveCacheSettings = {
      cacheType: "save",
      context: membershipNumber,
      maxAge: getCurrentTimeStamp(-1 * (24 * 3600 * 1000)), // get latest cache up to 1 days ago
    }
    const payload: MembershipConnectSuiteMemberLookupEventPayload = {
      cacheSettings: cacheSettings,
      memberNumber: membershipNumber,
      method: MembershipConnectSuiteMethod.MEMBER_LOOKUP,
    }

    return this.executeService.membershipQuery<ConnectSuite.MemberLookupResponseObject>(payload).pipe(
      map((memberLookupResponseObject) => {
        const memberInfo = memberLookupResponseObject.response?.memberInfo
        const memberLookupErrorObject = memberLookupResponseObject.error
        const responseCode = memberInfo?.attributes?.responseCode || memberLookupErrorObject?.responseCode

        switch (responseCode) {
          case MemberShipResponseCode.CANCELLED:
            throw new RequestError(RequestErrorType.MembershipCancelled, memberLookupResponseObject)
          case MemberShipResponseCode.SUCCESS: {
            return new ConnectSuite.AccountInfo(memberLookupResponseObject.response?.memberInfo)
          }
          case MemberShipResponseCode.INVALID:
          case MemberShipResponseCode.INCORRECT:
          default:
            throw new RequestError(RequestErrorType.MembershipError, memberLookupResponseObject)
        }
      }),
    )
  }

  getMetaDataAccountInfo(status: AccountStatus): ConnectSuite.AccountInfo {
    const accountDetails = new ConnectSuite.AccountInfo()
    const metaData = this.windowRef.nativeWindow.metaData

    accountDetails.firstName = metaData.user.firstName
    accountDetails.lastName = metaData.user.lastName
    accountDetails.memberNumber = metaData.user.memberNumber
    accountDetails.email = metaData.user.email
    accountDetails.status = status

    return accountDetails
  }
}
