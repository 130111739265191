import { Component, Inject, Input, OnInit } from "@angular/core"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { GLOBAL_RX_STATE, GlobalState, StateService } from "../../../../../services/state"
import { tap } from "rxjs/operators"
import { ScreenSize } from "../../../../../services/style"
import { HOOSIER_RX_STATE, HoosierState } from "../../hoosier.service"
import { BlockState } from "../../../../../block/services/state"
import { Observable, of } from "rxjs"
import { ThemeService } from "../../../../../options/theme/service"
import { ColorService, ColorType } from "../../../../../services/color"
import { Store } from "@ngrx/store"
import { AppStore } from "@aaa/emember/store-types"

@Component({
  selector: "ava-price-chart-member-quantity-view",
  templateUrl: "./view.html",
  providers: [RxState, RxEffects],
})
export class PriceChartMemberQuantityViewComponent implements OnInit {
  @Input() stateId: string | undefined
  @Input() rowId: string | undefined
  blockState: RxState<BlockState> | undefined
  styleColor: string | undefined
  // memberQuantity$ = this.store.select(getPricePreviewMemberQuantity)

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private stateService: StateService,
    private themeService: ThemeService,
    private colorService: ColorService,
    private store: Store<AppStore>,
  ) {}

  get fontColor$(): Observable<any> {
    return (
      this.blockState?.select("blockValue", "options", "theme").pipe(
        tap((themeType) => {
          let styleColor = this.colorService.color(ColorType.C_10)?.rgba // black for mobile sizes
          if (this.globalState.get("screenSize") === ScreenSize.DESKTOP) {
            styleColor = this.themeService.theme(themeType)?.fontColor
          }
          this.styleColor = styleColor
        }),
      ) || of(null)
    )
  }

  ngOnInit(): void {
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId]
    }
    this.rxEffects.register(this.fontColor$)
  }

  changePrices(direction: "increment" | "decrement"): void {
    if (direction === "increment") {
      // this.store.dispatch(PricePreviewActions.increaseMemberQuantity())
    } else {
      // this.store.dispatch(PricePreviewActions.decreaseMemberQuantity())
    }

    this.globalState.set("tokenRefresh", () => Date.now())
  }
}
