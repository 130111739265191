import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { QuickRenewFormStep } from "./quick-renew.models"
import { AccountDetails, ValidateSucceededResponseObject } from "../types/types"
import { validateFormStep } from "../../modules/share/ngrx-helpers"
import { RequestError } from "../generic-errors"

export const QuickRenewActions = createActionGroup({
  source: "Quick Renew",
  events: {
    ...validateFormStep<QuickRenewFormStep>(),
    "Validate Membership": emptyProps(),
    "Validate Membership Succeeded": emptyProps(),
    "Validate Membership Failed": emptyProps(),
    "Validate Payment Holder": emptyProps(),
    "Validate Payment Holder Succeeded": emptyProps(),
    "Validate Payment Holder Failed": emptyProps(),
    "Recost Validate": emptyProps(),
    "Recost Validate Succeeded": props<ValidateSucceededResponseObject>(),
    "Recost Validate Failed": props<{ error: RequestError }>(),
    Pay: emptyProps(),
    "Pay Succeeded": emptyProps(),
    "Pay Failed": props<{ error: RequestError }>(),
    "Reset Payment": emptyProps(),
    "Reset Confirmation": emptyProps(),
    "Set Account Details": props<{ accountDetails: AccountDetails }>(),
  },
})
