import { Injectable } from "@angular/core"
import { AssociateMember, Member, Membership, PrimaryMember } from "./member-info"

@Injectable({
  providedIn: "root"
})
export class FixMemberNamesService {

  fixMemberNames(membership: Membership): Membership {
    if (membership.primaryMember) {
      membership.primaryMember = this.fixMemberName(membership.primaryMember) as PrimaryMember
    }
    if (membership.associateMember) {
      for (const [index, member] of membership.associateMember.entries()) {
        membership.associateMember[index] = this.fixMemberName(member) as AssociateMember
      }
    }
    return membership
  }

  /**
   * Relies on membership object structure and the componentDescription name matching firstName and lastName
   */
  fixMemberName(member: Member): Member {
    if (member.duesComponent?.attributes?.componentDescription) {
      if (member.attributes?.firstName) {
        const indexOf_FirstName = member.duesComponent.attributes.componentDescription
          .toUpperCase().indexOf(member.attributes.firstName)
        if (indexOf_FirstName >= 0) {
          member.attributes.firstName = member.duesComponent.attributes.componentDescription
            .slice(indexOf_FirstName, indexOf_FirstName + member.attributes.firstName.length)
        }
      }
      if (member.attributes?.lastName) {
        const indexOf_LastName = member.duesComponent.attributes.componentDescription
          .toUpperCase().indexOf(member.attributes.lastName)
        if (indexOf_LastName >= 0) {
          member.attributes.lastName = member.duesComponent.attributes.componentDescription
          .slice(indexOf_LastName, indexOf_LastName + member.attributes.lastName.length)
        }
      }
    }
    return member
  }

}
