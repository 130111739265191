import { Component, inject, OnDestroy, OnInit } from "@angular/core"
import { FormBuilder, FormControl } from "@angular/forms"
import { getZipcodeFormErrors } from "../../../../store/form-messages"
import { Store } from "@ngrx/store"
import { AppStore } from "@aaa/emember/store-types"
import {
  getZipCodeInClubFailed,
  getZipCodeInClubIsLoading,
  getZipCodeInClubSuccess,
  ValidatorsActions,
} from "@aaa/emember/store-validators"
import { ValidatorService } from "../../../share/form/validators/validator.service"
import { filter, Subject, takeUntil, tap, withLatestFrom } from "rxjs"
import { getClubId, getClubOption, getZipcodeFormDescription } from "@aaa/emember/store-membership"
import { CookiesService } from "../../../share/services/cookies.service"
import { ClubApp } from "@aaa/emember/types"

@Component({
  selector: "ava-zipcode-form",
  templateUrl: "./zipcode-form.component.html",
  styles: [
    `
      :host {
        display: flex;
        padding: 1rem;
        background: white;

        .zipcode-control {
          &__error {
            color: red;
          }
        }
      }
    `,
  ],
})
export class ZipcodeFormComponent implements OnInit, OnDestroy {
  readonly ClubApp = ClubApp
  store = inject(Store<AppStore>)
  fb = inject(FormBuilder)
  validators = inject(ValidatorService)
  cookies = inject(CookiesService)
  alive$ = new Subject()
  formGroup = this.initFormGroup()
  clubOption$ = this.store.select(getClubOption)
  zipcodeFormDescription$ = this.store.select(getZipcodeFormDescription)
  errorMessages$ = this.store.select(getZipcodeFormErrors)
  isLoading$ = this.store.select(getZipCodeInClubIsLoading)
  success$ = this.store.select(getZipCodeInClubSuccess)
  failed$ = this.store.select(getZipCodeInClubFailed)

  ngOnInit() {
    this.success$
      .pipe(
        withLatestFrom(this.store.select(getClubId)),
        filter(() => !!this.formGroup.value.zipcode),
        tap(([, clubId]) => this.cookies.setZipCode(String(this.formGroup.value.zipcode), clubId)),
        takeUntil(this.alive$),
      )
      .subscribe()
  }

  submitForm() {
    const { zipcode = "" } = this.formGroup.value

    if (zipcode) {
      this.store.dispatch(ValidatorsActions.zipCodeInClub({ zipcode }))
    }
  }

  private initFormGroup() {
    return this.fb.group(
      {
        zipcode: new FormControl("", {
          nonNullable: true,
          validators: [this.validators.zipCodeFormat()],
        }),
      },
      { updateOn: "blur" },
    )
  }

  ngOnDestroy() {
    this.alive$.next(null)
    this.alive$.complete()
  }
}
