import { inject, Injectable } from "@angular/core"
import { DocumentRefService } from "./document-ref.service"

@Injectable({ providedIn: "root" })
export class CookiesService {
  document = inject(DocumentRefService)
  /**
   * get cookie
   * @param {string} name
   * @returns {string}
   */
  getCookie(name: string) {
    const cookies: Array<string> = decodeURIComponent(this.document.nativeDocument.cookie).split(";")
    const cookiesSize: number = cookies.length
    const cookieName = `${name}=`
    let c: string

    for (let i = 0; i < cookiesSize; i += 1) {
      c = cookies[i].replace(/^\s+/g, "")

      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length)
      }
    }

    return ""
  }

  /**
   * set cookie
   * @param {string} name
   * @param {string} value
   * @param {number} expireDays
   * @param {string} path
   */
  setCookie(name: string, value: string, expireDays: number, path: string = "") {
    const expireDate: Date = new Date()

    expireDate.setTime(expireDate.getTime() + expireDays * 24 * 60 * 60 * 1000)

    const expires = `expires=${expireDate.toUTCString()}`
    const cpath = path ? `; path=${path}` : ""

    if (!this.getCookie(name)) {
      this.document.nativeDocument.cookie = `${name}=${value}; ${expires}${cpath}; SameSite=Lax`
    }
  }

  getZipInfo() {
    let [zipcode, , clubId] = decodeURI(this.getCookie("zipcode")).split("|")

    while (String(clubId || "").length < 3) {
      clubId = "0" + (clubId || "")
    }

    if (!/^\d+$/.test(zipcode) || String(zipcode).length !== 5) {
      zipcode = ""
    }

    return { zipcode, clubId }
  }

  setZipCode(zipcode: string, clubId: string, device = "PC") {
    return this.setCookie("zipcode", `${zipcode}|AAA|${clubId}|${device}`, 1)
  }
}
