import { Inject, Injectable } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { combineLatestWith, tap } from "rxjs"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { FieldType } from "../../../../../services/field"
import { FormService } from "../../../../../services/form"
import { Link, LinkService, TargetOption, TargetType } from "../../../../../services/link"
import { Image, ImageEditorOptions, ImageService } from "../../../../../fields/image/service"
import { FieldBase } from "../../../../../block/services/block"
import { HOOSIER_RX_STATE, HoosierState, JoinSection } from "../../hoosier.service"

export interface PriceChartCard extends FieldBase {
  description: {
    title?: string
    towingMiles?: string
    cta: string
    ctaStyle?: string
    price: number
  }
  flag?: {
    enabled: boolean
    text: string
  }
  image?: {
    attributes: Image
    enabled: boolean
  }
  link?: Link
  membership: {
    // selectedDestinationPath: "GIFT" | "JOIN"
    // levels: string[]
    // levelsWithRv: string[]
    selectedLevel: string
    selectedRv: boolean
  }
}

export interface PriceChartOptions {
  membershipLevels: string[]
}

@Injectable({
  providedIn: "root"
})
export class PriceChartCardService {
  // levels: string[] = []
  // levelsWithRv: string[] = []
  priceChartCardLink: Link | undefined
  cardClicked: any

  constructor(
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private formService: FormService,
    private imageService: ImageService,
    private linkService: LinkService,
  ) {
    rxEffects.register(this.pricePreviews$)
  }

  pricePreviews$ = this.hoosierState.select("PRICE_PREVIEWS")
    .pipe(
      combineLatestWith(
        this.hoosierState.select("activeDestination")
      ),
      tap(([PRICE_PREVIEWS, activeDestination]) => {
        if (PRICE_PREVIEWS && this.priceChartCardLink) {
          switch (activeDestination) {
            case JoinSection.RV_COVERAGE:
              this.hoosierState.set("activeDestination", () => null)
              this.linkService.linkClick(this.priceChartCardLink)
          }
        }
/*
        if (PRICE_PREVIEWS.response.noPromoNoEnrollPrices.length) {
          for (const pricing of PRICE_PREVIEWS.response.noPromoNoEnrollPrices) {
            if (!this.levels.find(level => level === pricing.level)) {
              this.levels.push(pricing.level)
            }
            if (pricing.rv && !this.levelsWithRv.find(level => level === pricing.level)) {
              this.levelsWithRv.push(pricing.level)
            }
          }
        }
*/
      })
    )

  get newItem(): PriceChartCard {
    return {
      description: {
        title: "",
        towingMiles: "",
        cta: "",
        price: 0
      },
      flag: {
        enabled: false,
        text: ""
      },
      image: {
        attributes: this.imageService.newItem,
        enabled: true,
      },
      link: {
        targetPath: "",
        targetOption: TargetType.SELF
      },
      membership: {
        // selectedDestinationPath: "JOIN",
        // levels: this.levels,
        // levelsWithRv: this.levelsWithRv,
        selectedLevel: "",
        selectedRv: false,
      },
      fieldType: FieldType.PRICE_CHART_CARD,
    }
  }

  newForm = (item: PriceChartCard): AbstractControl | undefined => {
    const options = {
      // formControlKeys: [
      //   "levels",
      //   "levelsWithRv"
      // ]
    }
    return this.formService.newForm(item, options)
  }

  get imageEditorOptions(): ImageEditorOptions {
    return {
      alt: false,
      cropper: true,
      description: "Membership Card",
      dimensions: "240x150",
      label: {
        placeholder: "",
        plural: "Images",
        singular: "Image"
      },
      minWidth: {
        px: 240
      },
      maxWidth: {
        px: 240
      },
      minHeight: {
        px: 150
      },
      maxHeight: {
        px: 150
      },
      target: [],
      targetOption: false,
      targetPath: false,
    }
  }
}
