<div
  [formGroup]="formGroup"
  (formValuesChanged)="formGroupValuesChanged($event)"
  [formValues]="formValues$ | async"
  *ngIf="errorMessages$ | async as errorMessages"
  avaConnectForm
  class="ava-column ava-gap-2"
>
  <div
    formGroupName="card"
    class="ava-column ava-gap-1 ava-payment-card"
    [ngClass]="{'ava-payment-invalid': formGroupCardTouched}"
  >
    <ng-container *ngIf="paymentType === 'cybersource'">
      <!-- START Cybersource form -->
      <div class="ava-row ava-gap-1 payment-card-cybersource">
        <div class="ava-column ava-gap ava-flex">
          <div
            id="payment-card-number"
            class="payment-card-number"
            [ngClass]="{ 'ng-touched': cardFormGroup.controls.number.touched, 'ng-invalid': cardFormGroup.controls.number?.errors }"
          >
            <nz-skeleton-element [nzActive]="true" nzBlock="true" nzType="input" />
          </div>
        </div>

        <div class="ava-row ava-gap ava-flex">
          <span
            nz-icon
            nzTheme="outline"
            [nzType]="cardFormGroup.controls.cardType.value | cardIcon"
            class="ava-payment-card-icon"
          ></span>
          <div class="ava-column ava-flex">
            <div
              id="payment-card-cvv"
              class="payment-card-cvv"
              [ngClass]="{'ng-touched': cardFormGroup.controls.cvv.touched, 'ng-invalid': cardFormGroup.controls.cvv?.errors}"
            >
              <nz-skeleton-element [nzActive]="true" nzBlock="true" nzType="input" />
            </div>
          </div>
        </div>
      </div>
      <!-- END Cybersource form -->

      <div class="ava-row ava-gap-1 payment-card-month-and-year">
        <ava-form-select
          [options]="months"
          [errorMessages]="errorMessages.expMonth"
          formControlName="month"
          placeholder="Exp Month"
          class="ava-flex"
        />
        <ava-form-select
          [options]="years"
          [errorMessages]="errorMessages.expYear"
          formControlName="year"
          placeholder="Exp Year"
          class="ava-flex"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="paymentType === 'shift4'">
      <form id="payment-shift4" class="ava-column ava-gap-2 shift4__form">
        <div class="ava-row">
          <label class="ava-flex shift4__control--card-label"> Card number </label>
          <div class="ava-flex">
            <div data-shift4="number" class="shift4__control--card-number">
              <nz-skeleton-element [nzActive]="true" nzBlock="true" nzType="input" />
            </div>
          </div>
        </div>
        <div class="ava-row ava-gap-2">
          <div class="ava-row ava-flex">
            <label class="ava-flex shift4__control--card-label"> Expiration </label>
            <div class="ava-flex">
              <div data-shift4="expiry" class="shift4__control--card-expiration">
                <nz-skeleton-element [nzActive]="true" nzBlock="true" nzType="input" />
              </div>
            </div>
          </div>
          <div class="ava-row ava-flex">
            <label class="ava-flex shift4__control--card-label"> Cvc </label>
            <div class="ava-flex">
              <div data-shift4="cvc" class="shift4__control--card-cvv">
                <nz-skeleton-element [nzActive]="true" nzBlock="true" nzType="input" />
              </div>
            </div>
          </div>

        </div>
      </form>
    </ng-container>


    <!-- Show card errors  --->
    <ng-container *ngIf="showInvalidCardNumber">
      <div
        *ngFor="let error of cardNumberForm?.errors | keyvalue"
        [innerHTML]="errorMessages.cardNumber[error.key] || error.key"
        class="ava-form-error"
      ></div>
    </ng-container>
    <ng-container *ngIf="showInvalidCardCvv">
      <div
        *ngFor="let error of cardCvvForm?.errors | keyvalue"
        [innerHTML]="errorMessages.cardCvv[error.key] || error.key"
        class="ava-form-error"
      ></div>
    </ng-container>

    <ng-container *ngIf="paymentType === 'shift4' && showInvalidCardExpiration">
      <div
        *ngFor="let error of cardMonthForm?.errors | keyvalue"
        [innerHTML]="errorMessages.expMonth[error.key] || error.key"
        class="ava-form-error"
      ></div>
    </ng-container>
  </div>

  <ava-form-checkbox
    *ngIf="showAutoRenew"
    formControlName="autoRenew"
    formLabel="Save time by enrolling with Auto Renewal"
  >
    <ava-icon iconTheme="fill" iconType="info-circle" [tooltipTitle]="autoRenewInfo || (saveARMessage$ | async)" />
  </ava-form-checkbox>
</div>
