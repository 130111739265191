<div [formGroup]="formGroup" class="ava-column ava-gap-2">
  <div class="ava-column ava-gap-2 ava-join-memberInfo" *ngIf="errorMessages$ | async  as errorMessages">
    <ng-container formGroupName="account">
      <ava-form-input [errorMessages]="errorMessages.firstName" placeholder="First Name" formControlName="firstName" />
      <div class="ava-row ava-gap-2">
        <ava-form-input
          [errorMessages]="errorMessages.lastName"
          class="ava-flex"
          placeholder="Last Name"
          formControlName="lastName"
        />
        <ava-form-select
          [options]="nameSuffix"
          allowClear="true"
          formControlName="suffix"
          placeholder="Suffix"
          class="ava-join-memberInfo__suffix"
        />
      </div>
      <ng-container *ngIf="showAddressAutocomplete; else staticAddress">
        <ava-form-smarty-address
          [includeOnlyStates]="[formGroup.value.account.state]"
          [errorMessages]="errorMessages.address1"
          placeholder="Address 1"
          formControlName="address1"
          zipcodeFormControlName="zipcode"
          stateFormControlName="state"
          cityFormControlName="city"
        />
      </ng-container>
      <ng-template #staticAddress>
        <ava-form-input [errorMessages]="errorMessages.address1" placeholder="Address 1" formControlName="address1" />
      </ng-template>
      <ava-form-input placeholder="Address 2" formControlName="address2" />
      <ava-form-input [errorMessages]="errorMessages.city" placeholder="City" formControlName="city" />
      <div class="ava-row ava-gap-2">
        <ava-form-select
          [errorMessages]="errorMessages.state"
          [options]="memberStates"
          [showSearch]="true"
          placeholder="State"
          formControlName="state"
          class="ava-flex"
        />
        <ava-form-input
          [errorMessages]="errorMessages.zipcode"
          placeholder="Zip Code"
          formControlName="zipcode"
          class="ava-flex"
        />
      </div>
      <div *ngIf="showBirthday || showPhoneNumber" class="ava-row ava-gap-2 ava-join-memberInfo__group-controls">
        <ava-form-date
          *ngIf="showBirthday"
          [errorMessages]="errorMessages.birthday"
          (dateChange)="dateChange.emit($event)"
          placeholder="Date of Birth (mm/dd/yyyy)"
          formControlName="birthday"
          class="ava-flex"
        />
        <ava-form-tel
          *ngIf="showPhoneNumber"
          [errorMessages]="errorMessages.phone"
          placeholder="Phone"
          formControlName="phone"
          class="ava-flex"
        />
      </div>
      <div class="ava-row ava-gap-2 ava-join-memberInfo__group-controls">
        <ava-form-input
          [errorMessages]="errorMessages.email"
          placeholder="Email"
          formControlName="email"
          class="ava-flex"
        />
        <ng-container *ngIf="showPassword">
          <ava-form-password
            [errorMessages]="errorMessages.password"
            placeholder="Password"
            formControlName="password"
            class="ava-flex"
            controlId="member-info-password"
          />
        </ng-container>
      </div>
    </ng-container>
    <div class="ava-column ava-gap-2">
      <ng-container *ngIf="showCardFormat">
        <ng-container formGroupName="membership">
          <ava-form-radio
            controlId="member-info-cardFormat"
            [options]="cardTypes"
            formLabel="Choose Your AAA Card Format"
            formControlName="cardFormat"
          />
        </ng-container>
      </ng-container>
      <div *ngIf="showAccidentMedicalPlan" class="ava-row" formGroupName="account">
        <ava-form-checkbox
          [formLabel]="'Accident Medical ' + (accidentMedicalPlanPrice ? (accidentMedicalPlanPrice | currency) : '') + ' Plan'"
          formControlName="accidentMedicalPlan"
          (changed)="accidentMedicalChanged.emit($event)"
        />
        <ng-container *ngIf="medicalPlanText$ | async as medicalPlanText">
          <ava-icon
            iconType="info-circle"
            iconTheme="fill"
            tooltipPlacement="top"
            [tooltipTitle]="medicalPlanText"
            (tooltipClicked)="tooltipClicked()"
          />
        </ng-container>
      </div>
    </div>
  </div>
</div>
