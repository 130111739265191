<ng-container *ngFor="let field of fields">
  <ng-container [ngSwitch]="field.type">
    <ng-container *ngSwitchCase="FieldComponentType.ICON">
      <div ava-render-icon [props]="field.props"></div>
    </ng-container>
    <ng-container *ngSwitchCase="FieldComponentType.PARAGRAPH">
      <p ava-render-paragraph [props]="field.props"></p>
    </ng-container>
    <ng-container *ngSwitchCase="FieldComponentType.SPAN">
      <span ava-render-span [props]="field.props"></span>
    </ng-container>
    <ng-container *ngSwitchCase="FieldComponentType.HEADER2">
      <h2 ava-render-h2 [props]="field.props"></h2>
    </ng-container>
    <ng-container *ngSwitchCase="FieldComponentType.HEADER3">
      <h2 ava-render-h3 [props]="field.props"></h2>
    </ng-container>
    <ng-container *ngSwitchCase="FieldComponentType.BR"><br /></ng-container>
    <ng-container *ngSwitchCase="FieldComponentType.SECTION">
      <section ava-render [props]="field.props" [fields]="field.fieldGroup"></section>
    </ng-container>
  </ng-container>
</ng-container>
