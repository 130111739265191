import { LastNameSuffix } from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import { PriceMemberItem } from "@aaa/emember/store-price-preview"
import { formatPhoneNumber } from "../utils/format-phone-number"
import { ClubOption } from "../../../types/club-option"
import {
  AccountStatus,
  ClubApp,
  ClubAppName,
  getClubContactPhone,
  getClubTermsAndConditionUrl,
  getPrivacyUrl,
} from "@aaa/emember/types"
import { z } from "zod"
import { RequestError } from "../generic-errors"
import { MembershipOffer, MembershipOfferItem } from "../price-offers/helpers/types"

export const getClubOptions = (clubId: string, zipcode: string): ClubOption => {
  const phoneNumber = getClubContactPhone(clubId, zipcode)

  return {
    clubId: clubId as ClubApp,
    name: ClubAppName[clubId as ClubApp],
    mainClass: ClubAppName[clubId as ClubApp].toLowerCase(),
    phoneNumber: phoneNumber,
    phoneNumberView: formatPhoneNumber(phoneNumber),
    termsAndConditionUrl: getClubTermsAndConditionUrl(clubId as ClubApp),
    privacyUrl: getPrivacyUrl(clubId as ClubApp),
  }
}

export const SessionDocResponseSchema = z.object({
  meta: z
    .object({
      acknowledged: z.boolean(),
      event: z.string(),
      isError: z.boolean(),
      method: z.string(),
      operation: z.boolean().nullable(),
      responseKey: z.string(),
      updated: z.number(),
    })
    .optional(),
})
export interface SessionDocResponseObject {
  // error: SessionDocError
  meta: {
    acknowledged: boolean
    event: string
    isError: boolean
    method: string
    operation: string | null
    responseKey: string
    updated: number
    cache?: any
    handleEventExecutionTime?: number
  }
}
export interface ValidateResponse<VD> {
  validationData: VD
  executionData: string
}
export type PhoneType = "cell" | "home" | "business"
export type AccountPhone = { [key in PhoneType]: string }
export type AccountAddress = {
  street1: string
  street2: string
  city: string
  state: string
  zip: string
}

export enum MembershipPlanAction {
  UPGRADE = "upgrade",
  DOWNGRADE = "downgrade",
  RENEW = "renew",
}
export type AccountDetailsPaymentSummary = {
  label: string
  value: number
}

export type MembershipAssociateDetails = {
  firstName: string
  lastName: string
  membershipNumber?: string // required for edit
  removeAssociate?: boolean
  middleIntial?: string
  nameSuffix?: LastNameSuffix
  // not using in UI atm
  dob?: string
  email?: string
}

export interface AccountIncludedPrimaryOffers {
  primary: MembershipOffer
  primaryOptional: MembershipOffer[]
}

export interface AccountIncludedAssociateOffers {
  associate: MembershipOffer[]
  associateOptional: MembershipOffer[]
}

export interface AccountDetails {
  // available offers for current account
  includedPrimaryOffers: AccountIncludedPrimaryOffers
  // available offers for current account
  includedAssociateOffers: AccountIncludedAssociateOffers[]
  address: AccountAddress
  associateCount: number | null
  // this will be replaced with associateOfferItems
  associates: MembershipAssociateDetails[]
  autoRenew: boolean | null
  balance: number | null
  cardNumber: string
  code: MembershipCode | null
  email: string
  expireDate?: string | null
  firstName: string
  lastName: string
  memberNumber: string
  middleInitial: string
  nameSuffix: string
  phone: AccountPhone
  status: AccountStatus
  paymentSummary: AccountDetailsPaymentSummary[]
  // all available offer items from api
  // is for form join/gift etc
  availableOffers: MembershipOfferItem[]
}

export interface ValidateSucceededResponseObject<T = any> {
  executionData: string
  response: T
}

export interface ValidateFailedResponseObject<T = any> {
  response: T
  error: RequestError
}

export interface PricePreview {
  membershipCode: string

  // promocode
  promoPrice?: number
  promoCode?: string
  promoDescription?: string

  //
  primaryFeePrice?: number
  primaryFeeCode?: string
  primaryFeeDescription?: string

  associateFeePrice: number
  associateFeeCode: string
  associateFeeDescription: string

  enrollmentFeePrice?: number
  enrollmentFeeCode?: string
  enrollmentFeeDescription?: string

  primaryMedicalPrice?: number
  primaryMedicalCode?: string
  primaryMedicalDescription?: string

  associateMedicalPrice?: number
  associateMedicalCode?: string
  associateMedicalDescription?: string

  prices: PriceMemberItem[]
}

export interface MembershipCode<Level = string, MembershipType = string, DuesComponentCode = string, Label = string> {
  label: Label
  level: Level
  rv: boolean
  membershipType: MembershipType
  duesComponentCode: DuesComponentCode
}

export interface MembershipPlan extends MembershipCode, PricePreview {}

export interface MembershipCard<Level = string> {
  filePath: string
  membershipLabel: Level
  textColor: string
}
