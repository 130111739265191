<ng-container *ngIf="globalState$ | async as g">

  <ng-container *ngFor="let memberAlertMessage of g.memberAlertMessages">
    <nz-alert
      nzBanner
      nzShowIcon="false"
      [nzType]="memberAlertMessage.type"
      [nzMessage]="memberAlertMessage.message"
      [nzCloseable]="false"
      class="multiblock-component-alert"
    ></nz-alert>
  </ng-container>
  <!--
    <nz-alert
      nzBanner
      nzType="error"
      nzMessage="Error text text text text text text"
      [nzCloseable]="true"
    ></nz-alert>
    <ng-template #PromoSuccess>
      <div style="display: grid; place-content: center">
          Success! Your Special Discount is Applied.
      </div>
    </ng-template>
  -->

  <!-- show only the template block that is being edited -->
  <div
    *ngIf="g.adminMode && g.templateEditor && g.templateEditSelected"
    (click)="pageClicked()"
  >

    <ava-block-wrapper-page-width-wrapper
      [blockId]="g.templateEditSelected.id"
      [stateId]="g.templateEditSelected.id"
      [pageColumnIndex]="0"
      [pageRowId]="g.templateEditSelected.id"
      [pageRowIndex]="0"
      [pageStateId]="stateId"
    ></ava-block-wrapper-page-width-wrapper>
  </div>

  <!-- load all page blocks, page title (hidden), and migrate button (temporarily) -->
  <ng-container *ngIf="pageBlockState$ | async as p">
    <button
      *ngIf="!p.blockValue && g.adminUser && g.allOldBlocksArray && p.migrate"
      nz-button nzType="default"
      style="color: red; position: fixed; top: 25px; right: 15px;"
      (click)="transferAllBlocks(g.allOldBlocksArray)"
    >
      migrate {{ g.allOldBlocksArray?.length }} blocks
    </button>

    <!--    <nz-code-editor [ngModel]="p | json" class="editor" style="height: 400px" nzEdit></nz-code-editor>-->

    <div
      *ngIf="g.pageBlock && (!g.adminMode || !g.templateEditor || !g.templateEditSelected)"
      (click)="pageClicked()"
    >
      <!--
            cdkDropList
            id="pageList"
            [cdkDropListConnectedTo]="['createList']"
            cdkDropListDisabled
            (cdkDropListDropped)="drop($event)"
      -->
      <h1
        *ngIf="g.pageBlock.pageTitle"
        style="font-size: 0; width: 1px; height: 1px; display: inline-block; overflow: hidden; position: absolute; clip: rect(1px,1px,1px,1px);"
      >
        {{ g.pageBlock.pageTitle }}
      </h1>
      <!--
            <div
              *ngIf="g.templateEditor"
              style="width: 100%; display: flex; justify-content: center"
            >
              <button
                nz-button nzSize="small"
                nzType="primary"
                (click)="toggleTemplateEditor()"
              >
                Exit Template Editor
              </button>
            </div>
      -->

      <!-- loop through and load all rows in the pageBlock, these must all be of BlockReference type (with a .blockId) -->
      <ava-block-wrapper-page-width-wrapper
        *ngFor="let rowId of rowIds; let rowIndex = index"
        [blockId]="rows[rowId]?.['blockId']"
        [stateId]="rowId"
        [pageColumnIndex]="0"
        [pageRowId]="rowId"
        [pageRowIndex]="rowIndex"
        [pageStateId]="stateId"
      ></ava-block-wrapper-page-width-wrapper>

      <!--
            cdkDrag
      -->

    </div>
  </ng-container>

  <div style="position: relative">
    <ava-join-renew></ava-join-renew>
    <div
      *ngIf="g.adminMode"
      style="position: absolute; top: 0; left: 0; height: 100%; width: 100%"
    ></div>
  </div>

</ng-container>

<!-- Lazy-load admin module and these components if user has permissions -->
<!-- These are positioned so that they are not part of the pageBlock cdkDropList -->
<ng-template #pageToolbar></ng-template>
<ng-template #editMultiblock></ng-template>
<ng-template #createPanel></ng-template>
