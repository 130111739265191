import { APP_INITIALIZER, NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzDrawerService } from "ng-zorro-antd/drawer"
import { NzIconModule, NzIconService } from "ng-zorro-antd/icon"
import { NzMessageService } from "ng-zorro-antd/message"
import { NzNotificationService } from "ng-zorro-antd/notification"
import { HttpErrorInterceptorProvider } from "../../services/http-error-interceptor"
import { en_US, NZ_I18N } from "ng-zorro-antd/i18n"
import { UserTrackingService } from "@angular/fire/compat/analytics"
import { RouterModule, RouterOutlet } from "@angular/router"
import { EmemberComponent } from "./emember.component"
import { routes } from "./emember.routing"
import { NotFoundComponent } from "./components/not-found-component"
import { ZipcodeFormComponent } from "./components/zipcode-form/zipcode-form.component"
import { ReactiveFormsModule } from "@angular/forms"
import { FormInputComponent } from "../share/form/controls/input"
import { AvaDividerComponent } from "../share/ava-divider/ava-divider.component"
import { ButtonComponent } from "../share/ava-button/ava-button.component"
import { DataLayerService } from "../share/services/data-layer.service"
import { membershipInitializing } from "./emeber.initializer"
import { SpinnerComponent } from "../share/ava-spinner"

@NgModule({
  declarations: [EmemberComponent, NotFoundComponent, ZipcodeFormComponent],
  imports: [
    CommonModule,
    RouterOutlet,
    NzIconModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormInputComponent,
    AvaDividerComponent,
    ButtonComponent,
    SpinnerComponent,
  ],
  exports: [EmemberComponent],
  providers: [
    NzIconService,
    NzMessageService,
    NzDrawerService,
    NzNotificationService,
    DataLayerService,
    {
      provide: APP_INITIALIZER,
      useFactory: membershipInitializing,
      multi: true,
    },
    HttpErrorInterceptorProvider,
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    UserTrackingService,
  ],
})
export class EmemberModule {}
