import { createFeatureSelector, createSelector } from "@ngrx/store"
import { MembershipCode, PricePreview } from "../types/types"
import { FilterMembershipCode, PricePreviewState } from "./price-preview.model"
import { getMembershipCodes, getQueryMembershipLevel } from "@aaa/emember/store-membership"

export function findMembershipCodeBy(membershipCodes: MembershipCode[], filter: FilterMembershipCode = {}) {
  // level: string, rv: boolean
  return membershipCodes.find((code) => {
    let condition = true

    if (filter.level) {
      condition = code.level === filter.level
    }

    if (filter.rv === false || filter.rv === true) {
      condition = condition && filter.rv === code.rv
    }

    if (filter.duesComponentCode) {
      condition = condition && filter.duesComponentCode === code.duesComponentCode
    }

    return condition
  })
}

export function findPreviewPriceByMembershipCode(prices: PricePreview[], membershipCode: MembershipCode | undefined) {
  return prices.find((price) => price.membershipCode === membershipCode?.duesComponentCode)
}

export function findPricePreviewBy(
  prices: PricePreview[],
  membershipCodes: MembershipCode[],
  filter: FilterMembershipCode,
) {
  const membershipCode = findMembershipCodeBy(membershipCodes, filter)
  return findPreviewPriceByMembershipCode(prices, membershipCode)
}

export const PRICE_PREVIEW_FEATURE_KEY = "price-preview"

export const getPricePreviewState = createFeatureSelector<PricePreviewState>(PRICE_PREVIEW_FEATURE_KEY)
export const getPriceMap = createSelector(getPricePreviewState, ({ pricesMap }) => pricesMap)
export const getPrices = createSelector(getPricePreviewState, ({ prices }) => prices)
export const getPriceQuery = createSelector(getPricePreviewState, ({ priceQuery }) => priceQuery)
export const getPricePreview = createSelector(getPrices, getPriceMap, (prices, pricesMap) =>
  prices.map((price) => pricesMap[price]),
)
export const getPricePreviewMemberQuantity = createSelector(
  getPricePreviewState,
  ({ memberQuantity }) => memberQuantity,
)
export const getPricePreviewAndMembershipCodes = createSelector(
  getPricePreview,
  getMembershipCodes,
  (prices, membershipCodes) => ({ prices, membershipCodes }),
)

export const getPreviewPriceLevelBy = (filter: FilterMembershipCode) =>
  createSelector(getPricePreviewAndMembershipCodes, ({ prices, membershipCodes }) =>
    findPricePreviewBy(prices, membershipCodes, filter),
  )

export const getRvUpgradeLevel = createSelector(getPricePreview, getQueryMembershipLevel, (prices, membershipLevel) => {
  const pricePreview = prices.find((price) => price.membershipCode == membershipLevel?.duesComponentCode)
  const upgradeLevel = {
    label: "",
    level: "",
    price: 0,
  }

  if (pricePreview && membershipLevel) {
    upgradeLevel.label = membershipLevel.label
    upgradeLevel.level = membershipLevel.level
    upgradeLevel.price = (pricePreview.primaryFeePrice || 0) + (pricePreview.enrollmentFeePrice || 0)
  }

  return upgradeLevel
})

export const getPreviewPriceLevelPriceUpgrade = (filter: FilterMembershipCode) =>
  createSelector(getPricePreviewMemberQuantity, getPreviewPriceLevelBy(filter), (memberQuantity, pricePreview) => {
    let price = 0

    if (pricePreview) {
      price = pricePreview?.prices.find((price) => price.members === memberQuantity)?.price || 0
    }

    return price
  })
