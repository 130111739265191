import { Component, inject } from "@angular/core"
import { NzModalRef } from "ng-zorro-antd/modal"
import { ButtonComponent } from "../ava-button/ava-button.component"

@Component({
  selector: "ava-autorenew-confirmation",
  template: `
    <p>
      You have entered a promo code that requires Automatic Renewal. To opt out of Automatic Renewal, the promo code
      must be removed. If you remove the promo code, you will not receive the promotional price.
    </p>
    <p>Are you sure you would like to remove your promo code?</p>

    <br />

    <div class="ava-row ava-gap-2 ava-row-reverse">
      <ava-button (click)="Yes()" type="primary-outline">Yes</ava-button>
      <ava-button (click)="No()">No</ava-button>
    </div>
  `,
  styles: [
    `
      :host {
        padding: 1rem;
        display: block;
      }
    `,
  ],
  standalone: true,
  imports: [ButtonComponent],
})
export class AutorenewConfirmationComponent {
  readonly modal = inject(NzModalRef)

  No() {
    this.modal.destroy(false)
  }

  Yes() {
    this.modal.destroy(true)
  }
}
