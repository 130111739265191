import { Component, Inject, Input, OnInit } from "@angular/core"
import { RxEffects } from "@rx-angular/state/effects"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../../services/state"
import { RxState } from "@rx-angular/state"
import { tap } from "rxjs"
import { ValidateStatus } from "../../../join-renew.service"
import { HOOSIER_RX_STATE, HoosierState } from "../../hoosier.service"
import { CheckboxOptions } from "../../form-field-options/options-interfaces"

@Component({
  selector: "ava-hoosier-auto-renew",
  templateUrl: "./auto-renew.html"
})
export class AutoRenewComponent implements OnInit {
  @Input() options: CheckboxOptions = {} as CheckboxOptions

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
  ) {
  }

  couponCode$ = this.globalState.select("userSession", "couponCode")
    .pipe(
      tap((couponCode: string | false) => {
        if (!couponCode) {
          this.options.validateStatus = ValidateStatus.VALID
        }
        if (couponCode) {
          this.options.validateStatus = ValidateStatus.DISABLED
        }
      })
    )

  ngOnInit(): void {
    this.rxEffects.register(this.couponCode$)
  }

}
