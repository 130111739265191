import { Component, Inject } from "@angular/core"
import { GLOBAL_RX_STATE, GlobalState } from "../../services/state"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { tap } from "rxjs/operators"
import { filter } from "rxjs"
import firebase from "firebase/compat/app"
import "firebase/firestore"
import Timestamp = firebase.firestore.Timestamp

@Component({
  selector: "ava-root",
  template: ` <ava-emember></ava-emember> `,
})
export class AppComponent {
  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rxEffects: RxEffects,
  ) {
    rxEffects.register(this.location$)
  }

  location$ = this.globalState.select("location", "pathname").pipe(
    filter((pathname) => pathname === "/dev-claims"),
    tap(() => {
      const claimsDoc: {
        [key: string]: { memberNumber: string; roles: string[]; email: string; name: string } | Timestamp | number
      } = {
        updatedTimestamp: Timestamp.now(),
        updatedTimestampMillis: Timestamp.now().toMillis(),
      }

      for (const project of this.devClaimsProjects) {
        if (project.projectId === this.globalState.get("environment", "firebaseConfig", "projectId")) {
          for (const user of project.users) {
            if (user.uid) {
              claimsDoc[user.uid] = {
                email: user.email,
                memberNumber: "1234567890123456",
                roles: ["administrator", "authenticated"],
                name: user.name,
              }
            }
          }
        }
      }
    }),
  )

  devClaimsProjects = [
    {
      projectId: "aaa-colorado-1",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "myWduGq205VRlC29jtPRpKqOEo63",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "4rr8G2Y6roQwNqqPvwRfpSffzRI2",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "w4aXS02v9Md8FkRf6PRUsfud7ts2",
        },
      ],
    },
    {
      projectId: "aaa-colorado-2",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "76nPptawpNRpRVOqGYv8UOdRdIP2",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "se42PJpLrrNdRjqLhYpHdPp2W7k2",
        },
      ],
    },
    {
      projectId: "aaa-hoosier-1",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "x3wuE6dpM3SLFVbjR6YN5KhSdgv1",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "EyFi51iHUuSSzhStwu9rUZE8SAw1",
        },
      ],
    },
    {
      projectId: "aaa-hoosier-2",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "tSgxV2eatQZdWZvCIHYZF2YYJ2b2",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "UisUXVbAMwURL4Dk2eKIj0vNKx53",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "Nd46pEPKM1NTt6pOyQ7n7JTqRln2",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "ugpEkREKiNMEcA6sDDbG87tPy7d2",
        },
      ],
    },
    {
      projectId: "aaa-midstates-1",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "VbHhFgd1M5ZTc1npRVRLwKs5PSY2",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "RBZXKDY2okcIXRiecBKX7OcBt0j1",
        },
      ],
    },
    {
      projectId: "aaa-midstates-2",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "w1iCeU9r4hT1fdbNgQIZebLelky2",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "2d3s4teKNaOhUHlWIoMO5KgwuOC2",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "v1EG6NDr66SYWQdh38RgzlVTw9n1",
        },
      ],
    },
    {
      projectId: "aaa-minneapolis-1",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "qA4nZdoFMHUs7cXUxuIO5EDC4w23",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "ydm0JlHibCZVYqngWLW0Ff4M0u13",
        },
      ],
    },
    {
      projectId: "aaa-minneapolis-2",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "17gp28cYb2eEt1Swn2hOJhz4iK83",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "XN4btPvbUUWFztvPEqwYnf2KWrz2",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "cfRqIvb6dGdgvdpwkFpUXTBYXR83",
        },
      ],
    },
    {
      projectId: "aaa-northway-1",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "D9OJrasJIuVNoOx8Kd6u75PkcKC3",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "eocv9MZxnVO1ZkNSU8dPtPZM7mH2",
        },
      ],
    },
    {
      projectId: "aaa-northway-2",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "tYOh3tRrE7Ri3n1enHzFg44InDr2",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "EKlHThWwnBZUXmE7XVeUtit97zH3",
        },
      ],
    },
    {
      projectId: "aaa-southjersey-1",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "sD7QRcoOIzY0mRHlmEXwEUItfAh2",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "8KP8Sgeaw4ZX3Bid0wRUupMlqMg1",
        },
      ],
    },
    {
      projectId: "aaa-southjersey-2",
      users: [
        {
          name: "Dorin Turturean",
          email: "dorin.turturean@avagate.com",
          uid: "4ulRHr2MUEVrEDEWVSvIII3zvG42",
        },
        {
          name: "Lauren Chapman",
          email: "lchapman@aaashare.com",
          uid: "6ZVG73X4xbcrpKQ5H0s8Z05pEPv2",
        },
        {
          name: "Lowell Evergreen",
          email: "lowell.evergreen@avagate.com",
          uid: "",
        },
        {
          name: "Lowell Johnson",
          email: "lowell.johnson@avagate.com",
          uid: "yqNUINTDXjXjSu6hsgwco0h4FRh1",
        },
      ],
    },
  ]
}
