import { createReducer, on } from "@ngrx/store"
import { MembershipOffersState } from "./membership-offers.models"
import { MembershipOffersActions } from "./membership-offers.actions"

export const initialStateMembershipOffers: MembershipOffersState = {
  quantity: 1,
  page: "",
}

export const membershipOffersReducer = createReducer(
  initialStateMembershipOffers,
  on(MembershipOffersActions.increaseMemberQuantity, (state) => {
    const maxQuantity = 10
    const quantity = state.quantity + 1 < maxQuantity ? state.quantity + 1 : state.quantity

    return { ...state, quantity }
  }),
  on(MembershipOffersActions.decreaseMemberQuantity, (state) => {
    const minQuantity = 1
    const quantity = state.quantity - 1 >= minQuantity ? state.quantity - 1 : state.quantity

    return { ...state, quantity }
  }),
  on(MembershipOffersActions.setPage, (state, { page }) => ({ ...state, page })),
)
