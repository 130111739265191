import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core"
import { AbstractControl, FormArray, FormGroup } from "@angular/forms"
import { WINDOW } from "@ng-web-apis/common"
import { map, tap } from "rxjs/operators"
import { combineLatest, combineLatestWith, debounceTime, filter, Observable, of } from "rxjs"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { Block } from "./services/block"
import { FieldType } from "../services/field"
import { FormService } from "../services/form"
import { Image } from "../fields/image/service"
import { ColorService } from "../services/color"
import { MultiblockStyle } from "./services/style"
import { Link, LinkService, TargetType } from "../services/link"
import { StorageService } from "../services/storage"
import { BlockService } from "./services/block"
import { DataReadService } from "../services/data-read"
import { TemplateType } from "../options/template/service"
import { RevisionReadService } from "../services/revision-read"
import { RevisionWriteService } from "../services/revision-write"
import { ThemeService } from "../options/theme/service"
import { BreakpointWidth, Style } from "../services/style"
import { BlockBackground, GLOBAL_RX_STATE, GlobalState, StateService } from "../services/state"
import { BackgroundImageEditorOptions, BackgroundImageService } from "../helpers/background-image/service"
import { BlockState } from "./services/state"
// import { SwiperComponent } from "swiper/angular"
// import Swiper, { A11y, Autoplay, Navigation, Pagination, Scrollbar, SwiperOptions } from "swiper"

// Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, A11y])

export interface ColumnsAsCards {
  colors: { background: string, text: string }[]
  enabled: boolean
  links: Link[]
}

interface ComponentState {
  columnWrapperHeight: number
}

@Component({
  selector: "ava-multiblock-view",
  templateUrl: "./view.html",
  providers: [RxState, RxEffects],
})
export class MultiblockViewComponent implements OnInit, AfterViewInit {
  @Input() blockId: string | undefined
  @Input() stateId: string | undefined
  @ViewChild("componentWidth") componentWidthElementRef: ElementRef | undefined
  @ViewChildren("columnsWrapper") columnsWrapperElementRef: QueryList<ElementRef> | undefined
  // @ViewChild("swiperRef", { static: false }) swiperRef?: SwiperComponent

  globalState$ = this.globalState.select()
  blockState: RxState<BlockState> | undefined
  blockState$: Observable<BlockState> | undefined
  parentBlockState: RxState<BlockState> | undefined
  parentBlockState$: Observable<BlockState> | undefined
  formGroup: FormGroup | undefined
  columns: AbstractControl[] | Block["columns"] = []
  columnGap: number | undefined

  styles = this.multiblockStyle.styles
  border = this.styleService.border
  options = this.multiblockService.options

  backgroundImageHeight: number | undefined

  /*
    config: SwiperOptions = {
      slidesPerGroup: 1,
      direction: "horizontal",
      slidesPerView: 3,
      slideToClickedSlide: true,
      mousewheel: true,
      scrollbar: true,
      // effect: "slide",
      watchSlidesProgress: false,
      navigation: true,
      keyboard: true,
      speed: 6000,
      pagination: false,
      freeMode: false,
      autoplay: {
        delay: 2000,
        disableOnInteraction: true
      },
      loop: true,
      breakpoints: {
        100: {
          slidesPerView: 2
        },
        1000: {
          slidesPerView: 3
        },
      }
    }
  */

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private componentState: RxState<ComponentState>,
    private multiblockService: BlockService,
    private multiblockStyle: MultiblockStyle,
    private backgroundImageService: BackgroundImageService,
    private colorService: ColorService,
    private dataReadService: DataReadService,
    private formService: FormService,
    private linkService: LinkService,
    private revisionReadService: RevisionReadService,
    private revisionWriteService: RevisionWriteService,
    private stateService: StateService,
    private storageService: StorageService,
    private styleService: Style,
    private themeService: ThemeService,
    private rxEffects: RxEffects,
  ) {
  }

  previewSize$ = this.globalState.select("previewSize")
    .pipe(
      combineLatestWith(
        this.globalState.select("previewMode"),
      ),
      tap(([size, mode]) => {
        console.log(size)
        console.log(mode)
        // this.onResize()
      }),
    )

  get backgroundImageHeight$(): Observable<any> {
    if (this.blockState) {
      return combineLatest([
        this.componentState.select("columnWrapperHeight"),
        this.blockState.select("blockWidth"),
        this.blockState.select("background", "height"),
        this.blockState.select("background", "width"),
      ])
        .pipe(
          debounceTime(100),
          filter(([, blockWidth, backgroundHeight, backgroundWidth]) => {
            return !!blockWidth && !!backgroundHeight && !!backgroundWidth
          }),
          tap(([columnWrapperHeight, blockWidth, backgroundHeight, backgroundWidth]) => {
            let backgroundImageHeight = blockWidth * backgroundHeight / backgroundWidth
            if (columnWrapperHeight && columnWrapperHeight > backgroundImageHeight - 50) {
              backgroundImageHeight = columnWrapperHeight + 50
            }

            this.backgroundImageHeight = backgroundImageHeight
          }),
        )
    }
    return of(null)
  }

  /*
    get backgroundImageHeight$(): Observable<any> {
      if (this.blockState && this.columnsWrapperElementRef) {
        return combineLatest([
          this.columnsWrapperElementRef.changes,
          this.blockState.select("blockWidth"),
          this.blockState.select("background", "height"),
          this.blockState.select("background", "width"),
        ])
          .pipe(
            tap(([columnsWrapperElementRef, blockWidth, backgroundHeight, backgroundWidth]) => {
              const columnWrapperHeight = columnsWrapperElementRef.first.nativeElement.getBoundingClientRect().height
              if (blockWidth && backgroundHeight && backgroundWidth) {
                let backgroundImageHeight = blockWidth * backgroundHeight / backgroundWidth
                if (columnWrapperHeight && columnWrapperHeight > backgroundImageHeight - 50) {
                  backgroundImageHeight = columnWrapperHeight + 50
                }

                this.backgroundImageHeight = backgroundImageHeight
              }
            }),
          )
      }
      return of(null)
    }
  */

  get setBackgroundImageRows$(): Observable<any> {
    if (this.blockState) {
      return this.blockState.select("blockValue", "columns")
        .pipe(
          combineLatestWith(
            this.blockState.select("blockValue", "rows"),
            this.blockState.select("blockValue", "template", "type"),
          ),
          tap(([columns, rows, templateType]: [Block["columns"], Block["rows"], TemplateType | null]) => {
            if (this.blockState && templateType) {
              const backgroundImageRows = this.backgroundImageService.getBackgroundImageRows(columns[0]?.rows || [], rows, templateType)
              this.blockState.set("backgroundImageRows", () => backgroundImageRows)
            }
          }),
        )
    }
    return of(null)
  }

  /*
  get backgroundImageHeight$(): Observable<any> {
    if (this.blockState && this.columnsWrapperElementRef) {
      return combineLatest([
        this.columnsWrapperElementRef.changes,
        this.blockState.select("blockWidth"),
        this.blockState.select("background", "height"),
        this.blockState.select("background", "width"),
      ])
        .pipe(
          tap(([columnsWrapperElementRef, blockWidth, backgroundHeight, backgroundWidth]) => {
            const columnWrapperHeight = columnsWrapperElementRef.first.nativeElement.getBoundingClientRect().height
            if (blockWidth && backgroundHeight && backgroundWidth) {
              let backgroundImageHeight = blockWidth * backgroundHeight / backgroundWidth
              if (columnWrapperHeight && columnWrapperHeight > backgroundImageHeight - 50) {
                backgroundImageHeight = columnWrapperHeight + 50
              }

              this.backgroundImageHeight = backgroundImageHeight
            }
          }),
        )
    }
    return of(null)
  }
*/

  get setImageEditorOptions$(): Observable<any> {
    if (this.blockState) {
      return this.blockState.select("backgroundImageRows")
        .pipe(
          combineLatestWith(
            this.blockState.select("blockValue", "columns"),
            this.blockState.select("blockValue", "template", "type"),
          ),
          tap(([backgroundImageRows, columns, templateType]: [BlockState["backgroundImageRows"], Block["columns"], TemplateType | null]) => {
            if (this.blockState && templateType) {
              const imageOptions = this.backgroundImageService.getImageEditorOptions(columns, templateType, this.multiblockService.options.image, backgroundImageRows)
              this.blockState.set("imageOptions", () => imageOptions)
            }
          }),
        )
    }
    return of(null)
  }

  /*
    get backgroundImageSideEffects$(): Observable<any> {
      return this.blockState.select("imageOptions")
        .pipe(
          combineLatestWith(
            this.blockState.select("blockWidth"),
            this.blockState.select("backgroundImageRows"),
            this.blockState.select("blockValue", "rows"),
            this.blockState.select("blockValue", "columns"),
            this.blockState.select("blockValue", "options", "alignment")
          ),
          tap(([imageOptions, width, backgroundImageRows, rows, columns, alignment]: [
            BlockState["imageOptions"],
            number,
            BlockState["backgroundImageRows"],
            Block["rows"],
            Block["columns"],
            AlignmentOption
          ]) => {
            const rowIds = columns[0]?.rows || []
            const backgroundImageEditorOptions = imageOptions as unknown as BackgroundImageEditorOptions
            const background: BlockBackground = this.getBackground(width, backgroundImageRows, rows, rowIds, backgroundImageEditorOptions)
            const style = this.blockState.get("style")
            /!**
             * default values
             *!/
            style.position.bottom = undefined
            style.position.top = 0
            style.positionType = "relative"
            /!**
             * conditionally overwrite the defaults
             *!/
            if (background.url) {
              if (alignment === "left") {
                style.position.bottom = undefined
                style.position.top = 50
              }
              if (alignment === "center") {
                style.position.bottom = 0
                style.position.top = undefined
              }
              if (width > 900) {
                style.positionType = "absolute"
              }
            }
            this.blockState.set("background", () => background)
            this.blockState.set("style", () => style)
          })
        )
    }
  */

  get setColumnsAsCards$(): Observable<any> {
    if (this.blockState) {
      return this.blockState.select("blockValue", "columns")
        .pipe(
          combineLatestWith(
            this.blockState.select("blockValue", "rows"),
            this.blockState.select("blockValue", "template", "type"),
          ),
          tap(([columns, rows, templateType]: [Block["columns"], Block["rows"], TemplateType | null]) => {
            if (this.blockState && templateType) {
              const columnsAsCards = this.getColumnsAsCards(columns, rows, templateType)
              this.blockState.set("columnsAsCards", () => columnsAsCards)
            }
          }),
        )
    }
    return of(null)
  }

  get setCarousel$(): Observable<any> {
    if (this.blockState) {
      return this.blockState.select("blockValue", "columns")
        .pipe(
          combineLatestWith(
            this.blockState.select("blockValue", "template", "type"),
          ),
          tap(([columns, templateType]: [Block["columns"], TemplateType | null]) => {
            if (this.blockState) {
              this.blockState.set("carousel", () => templateType === TemplateType.ICON_CARD && columns.length > 3)
            }
          }),
        )
    }
    return of(null)
  }

  get setPosition$(): Observable<any> {
    if (this.blockState && this.parentBlockState) {
      return this.blockState.select("blockWidth")
        .pipe(
          combineLatestWith(
            this.parentBlockState.select("type"),
          ),
          tap(([width, parentBlockTypeDeprecated]: [number | undefined, BlockState["type"]]) => {
            if (width) {
              const contentStyles = this.styles.content(width)
              if (parentBlockTypeDeprecated === "PAGE") {
                contentStyles.left.px = 0
              }
              if (this.blockState) {
                const style = this.blockState.get("style")
                style.position.left = contentStyles.left.px
                this.blockState.set("style", () => style)
              }
            }
          }),
        )
    }
    return of(null)
  }

  /*
    registerPreviewSideEffects(): void {
      this.rxEffects.register(this.globalState.select("previewSize")
        .pipe(
          combineLatestWith(
            this.globalState.select("previewMode")
          ),
          tap(([size, mode]) => {
            console.log(size)
            console.log(mode)
            // this.onResize()
          })
        ))
    }
  */

  columns$ = this.globalState.select("adminMode")
    .pipe(
      tap(adminMode => {
        if (adminMode) {
          const formArray = this.formGroup?.get(["columns"]) as FormArray
          this.columns = formArray?.controls
        }
        if (!adminMode && this.blockState) {
          this.columns = this.blockState.get("block", "columns")
        }
      }),
    )

  get columnGap$(): Observable<any> {
    if (this.blockState) {
      return this.blockState.select("blockValue", "options", "columnGap", "px")
        .pipe(
          tap(columnGap => {
            this.columnGap = columnGap
          }),
        )
    }
    return of(null)
  }

  ngOnInit(): void {
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId]
      this.formGroup = this.formService.forms[this.blockState.get("block", "id")]
    }
    if (this.blockState) {
      this.blockState$ = this.blockState.select()
      this.parentBlockState = this.stateService.states[this.blockState.get("parentBlockStateId")]
      this.blockState.set("columnsAsCards", () => {
        return {
          colors: [],
          enabled: false,
          links: [],
        }
      })
      this.blockState.set("type", () => "MULTIBLOCK")
    }
    if (this.parentBlockState) {
      this.parentBlockState$ = this.parentBlockState.select()
    }

    this.rxEffects.register(this.columns$)
    this.rxEffects.register(this.setPosition$)
    this.rxEffects.register(this.setBackgroundImageRows$)
    this.rxEffects.register(this.setImageEditorOptions$)
    this.rxEffects.register(this.setColumnsAsCards$)
    this.rxEffects.register(this.setCarousel$)
    this.rxEffects.register(this.columnGap$)

    // this.rxEffects.register(this.setBackgroundColor$)
    // this.rxEffects.register(this.backgroundImageSideEffects$)
  }

  ngAfterViewInit(): void {
    this.onResize()
    this.rxEffects.register(this.backgroundImageHeight$)
    // this.registerPreviewSideEffects()
  }

  @HostListener("window:resize")
  onResize(): void {
    this.componentState.set("columnWrapperHeight", () => this.columnsWrapperElementRef?.first?.nativeElement.getBoundingClientRect().height)
    const width = this.componentWidthElementRef?.nativeElement.getBoundingClientRect().width
    if (width && this.blockState) {
      this.blockState.set("blockWidth", () => width)

      const previewMode = this.globalState.get("previewMode")
      const previewSize = this.globalState.get("previewSize")
      if (previewMode && previewSize && previewSize < width) {
        // this.blockState.set({ blockWidth: previewSize })
      } else {
        // this.blockState.set({ blockWidth: width })
      }
    }
  }

  /**
   * TODO: belongs in either blockState or componentState
   */
  get highlight(): { [key: string]: boolean } {
    return this.multiblockService.highlight
  }

  /**
   * helpers
   */

  getColumnsAsCards(columns: Block["columns"], rows: Block["rows"], templateType: TemplateType): ColumnsAsCards {
    const columnsAsCards: ColumnsAsCards = {
      colors: [],
      enabled: templateType === TemplateType.FOUR_COLUMN_WITH_IMAGE,
      links: [],
    }
    if (templateType === TemplateType.FOUR_COLUMN_WITH_IMAGE) {
      for (const [columnIndex, column] of columns.entries()) {
        columnsAsCards.links.push({ targetPath: "", targetOption: TargetType.SELF })
        columnsAsCards.colors.push({ background: "#e6e6e6", text: "#00a0df" })
        for (const rowId of column.rows) {
          if (rows[rowId].fieldType === FieldType.IMAGE) {
            const image: Image = rows[rowId] as Image
            if (image.targetPath) {
              columnsAsCards.links[columnIndex] = {
                targetOption: image.targetOption,
                targetPath: image.targetPath,
              }
              break
            }
          }
        }
      }
    }
    return columnsAsCards
  }

  /*
    setImageEditorOptions(): void {
      const backgroundImageRows = this.backgroundImageService.getBackgroundImageRows(this.blockValue)
      const imageOptions = this.backgroundImageService.getImageEditorOptions(this.blockValue, this.thisService.options.image, backgroundImageRows)
      const background: BlockBackground = this.getBackground(imageOptions as unknown as BackgroundImageEditorOptions)
      this.blockState.set({
        background: background,
        backgroundImageRows: backgroundImageRows,
        imageOptions: imageOptions,
      })
    }
  */

  /**
   * TODO: rewrite with new backgroundImages option, images are now stored in options object instead of rows
   * calculate:
   *   text color and alignment
   *   height and width
   *   correct background image
   */
  getBackground(width: number, backgroundImageRows: BlockState["backgroundImageRows"], rows: Block["rows"], rowIds: string[], imageOptions: BackgroundImageEditorOptions): BlockBackground {
    let background: BlockBackground = {
      height: 0,
      width: 0,
      image: undefined,
      url: "",
    }
    if (width && backgroundImageRows?.[0]) {
      const images: { [key: string]: Image } = {}
      images["desktop"] = rows[rowIds[0]] as Image
      if (backgroundImageRows[1]) {
        images["tablet"] = rows[rowIds[1]] as Image
        if (backgroundImageRows[2]) {
          images["mobile"] = rows[rowIds[2]] as Image
        }
      }
      if (width < BreakpointWidth.SMALL) {
        background = {
          height: imageOptions.mobile?.maxHeight.px || imageOptions.tablet?.maxHeight.px || imageOptions.desktop?.maxHeight.px,
          width: imageOptions.mobile?.maxWidth.px || imageOptions.tablet?.maxWidth.px || imageOptions.desktop?.maxWidth.px,
          image: images.mobile || images.tablet || images.desktop,
          url: this.getBgUrl(images.mobile) || this.getBgUrl(images.tablet) || this.getBgUrl(images.desktop),
        }
      }
      if (width >= BreakpointWidth.SMALL && width < BreakpointWidth.MEDIUM) {
        background = {
          height: imageOptions.tablet?.maxHeight.px || imageOptions.desktop?.maxHeight.px,
          width: imageOptions.tablet?.maxWidth.px || imageOptions.desktop?.maxWidth.px,
          image: images.tablet || images.desktop,
          url: this.getBgUrl(images.tablet) || this.getBgUrl(images.desktop),
        }
      }
      if (width >= BreakpointWidth.MEDIUM) {
        background = {
          height: imageOptions.desktop?.maxHeight.px,
          width: imageOptions.desktop?.maxWidth.px,
          image: images.desktop,
          url: this.getBgUrl(images.desktop),
        }
      }
    }
    // if (!this.stateService.deepEqual(this.blockState.get("background"), background)) {
    return background
    // this.stateService.setState(this.stateId, { background: background })
    // }
  }

  getBgUrl(image: Image): string {
    if (image?.filePath) {
      return "url(" + this.getFileUrl(image.filePath) + ")"
    }
    return ""
  }

  getFileUrl(path: string): string {
    return this.storageService.getFileUrl(path)
  }

  /**
   * template methods
   */

  navigateTo(pathName: string): void {
    if (pathName !== this.globalState.get("location", "pathname")) {
      this.globalState.set("adminMode", () => false)
      this.linkService.navigateTo(pathName)
    }
  }

  /*
    onSwiper(swiper: Swiper): void {
      // console.log(swiper)
    }
  */

  onSlideChange(): void {
    // console.log("slide change")
  }

}
