import { AccountStatus } from "@aaa/emember/types"

export const getMembershipStatus = (status?: string): AccountStatus => {
  switch (status) {
    case "P":
      return AccountStatus.PENDING
    case "C":
      return AccountStatus.CANCELLED
    case "A":
      return AccountStatus.ACTIVE
    default:
      return AccountStatus.UNKNOWN
  }
}
