import { Injectable } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { RxEffects } from "@rx-angular/state/effects"
import { FormService } from "../../services/form"
import { FieldBase } from "../../block/services/block"
import { FieldType } from "../../services/field"
import {
  QuillEditor, QuillEditorOptions, QuillEditorService, QuillEditorStyles
} from "../quill-editor/service"
import { PriceChartCard, PriceChartCardService } from "../../modules/join-renew/hoosier/multiblocks/price-chart-card/service"

export interface ComparisonChart extends FieldBase {
  rows: Row[]
  footerText: string
}

export interface Row {
  columns: Column[]
}

export interface Column {
  heading: string
  textArea: QuillEditor
  info: string
  priceChartCard: PriceChartCard
}

@Injectable({
  providedIn: "root"
})
export class ComparisonChartService {

  constructor(
    private rxEffects: RxEffects,
    private formService: FormService,
    private quillEditorService: QuillEditorService,
    private priceChartCardService: PriceChartCardService,
  ) {
  }

  get newItem(): ComparisonChart {
    return {
      rows: [this.newRow],
      footerText: "",
      fieldType: FieldType.COMPARISON_CHART,
    }
  }

  get newRow(): Row {
    return {
      columns: [this.newColumn, this.newColumn, this.newColumn, this.newColumn]
    }
  }

  get newPriceChartCard(): PriceChartCard {
    return {
      description: {
        cta: "",
        ctaStyle: "STYLE_1",
        price: 0
      },
      membership: {
        // selectedDestinationPath: "JOIN",
        // levels: this.priceChartCardService.levels,
        // levelsWithRv: this.priceChartCardService.levelsWithRv,
        selectedLevel: "",
        selectedRv: false,
      },
      fieldType: FieldType.PRICE_CHART_CARD,
    }
  }

  get newColumn(): Column {
    return {
      heading: "",
      textArea: {
        fieldType: FieldType.QUILL_EDITOR,
        value: ""
      },
      info: "",
      priceChartCard: this.newPriceChartCard
    }
  }

  newForm = (item: ComparisonChart): AbstractControl | undefined => {
    const options = { formControlKeys: [] }
    return this.formService?.newForm(item, options)
  }

  textAreaStyles = (): QuillEditorStyles => {
    return {
      color: undefined,
      fontSize: {
        px: 17
      },
      fontWeight: 200,
      textAlign: undefined
    }
  }

  get textAreaOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      options: [],
      toolbarOptions: {
        buttons: this.quillEditorService.buttonOptions
      },
      label: {
        placeholder: "",
        singular: ""
      },
      formArray: {
        max: 1,
        min: 0
      }
    }
/*
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({})
    }
*/
    return options
  }


}
