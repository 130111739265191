import { ActivatedRouteSnapshot, CanActivateChildFn, Router } from "@angular/router"
import { inject } from "@angular/core"
import { LocationRefService } from "../../share/services/location-ref.service"

export const defaultRouting: CanActivateChildFn = ({ queryParams }: ActivatedRouteSnapshot) => {
  const router = inject(Router)
  const location = inject(LocationRefService)
  const pathName = location.nativeLocation.pathname

  if (pathName.indexOf("/join") !== -1) {
    router.navigate(["join"], { queryParams })
    return false
  }

  if (pathName.indexOf("/account") !== -1) {
    router.navigate(["account"], { queryParams })
    return false
  }

  if (pathName.indexOf("/renew") !== -1) {
    router.navigate(["renew"], { queryParams })
    return false
  }

  if (pathName.indexOf("/gift") !== -1) {
    router.navigate(["gift-offers"], { queryParams })
    return false
  }

  return true
}

export default defaultRouting
