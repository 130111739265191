import { FormControl, FormGroup } from "@angular/forms"
import { AccountDetails } from "@aaa/emember/store-types"
import { RequestStatus } from "../../../types/request-status"
import { FormGroupValue } from "../../modules/share/form.utils"
import { RequestError } from "../generic-errors"
import { BillingForm } from "../../modules/share/billing-form"

export enum MembershipUpgradePlanFormStep {
  PAYMENT = "payment",
  CONFIRMATION = "confirmation",
}

export interface MembershipUpgradePlanForm {
  level: FormControl<string>
  rv: FormControl<boolean>
  billing: FormGroup<BillingForm>
}

export interface MembershipUpgradePlanState {
  formActiveStep: MembershipUpgradePlanFormStep
  formValues: FormGroupValue<MembershipUpgradePlanForm>
  validationRequestStatus: RequestStatus
  validationError: RequestError | null
  executionData: any
  executionRequestStatus: RequestStatus
  executionError: RequestError | null
  accountDetails: AccountDetails | null
}

export type MembershipUpgradePlanPayParam = {
  accountDetails: AccountDetails
  formValues: FormGroupValue<MembershipUpgradePlanForm>
  executionData: string
  totalCost: number
}
