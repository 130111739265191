import { createReducer, on } from "@ngrx/store"
import { FeatureFlagsAction } from "./feature-flags.actions"
import { FeatureFlagsState } from "./types"
import { flagStates } from "./feature-flags.state"

export const initialState: FeatureFlagsState = {
  loaded: false,
  flags: flagStates,
}

export const featureFlagsReducer = createReducer(
  initialState,
  on(FeatureFlagsAction.loaded, (state, action) => ({
    ...state,
    flags: action.flags,
    loaded: true,
  })),
)
