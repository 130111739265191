<nz-form-item
  *ngIf="formGroup && options"
  [formGroup]="formGroup"
  style="margin: 0"
>
  <nz-form-control
    [nzErrorTip]="options.tip[ValidateStatus.ERROR]"
    [nzSuccessTip]="options.tip[ValidateStatus.SUCCESS]"
    [nzWarningTip]="options.tip[ValidateStatus.WARNING]"
    [nzValidatingTip]="options.tip[ValidateStatus.PENDING]"
    [nzHasFeedback]="true"
    [nzValidateStatus]="options.validateStatus"
    [nzDisableAutoTips]="true"
  >
    <nz-input-group
      [nzSuffix]="options.config.inputGroupSuffix // don't dynamically show/hide suffix, it causes field to lose focus"
      [class]="showLabel ? 'show-label' : 'hide-label' // use hide-label to hide suffix, show-label is just a placeholder"
    >
      <input
        nz-input
        [formControlName]="options.fieldName"
        [attr.inputmode]="options.config.inputMode"
        [autocomplete]="options.config.autocomplete"
        [nzAutocomplete]="auto"
        [type]="options.config.type"
        [placeholder]="options.config.placeholder"
        (blur)="enableValidators()"
        [avaDateFormat]="options.fieldName === 'dob'"
        [avaPhoneFormat]="options.config.type === 'tel'"
      >
      <nz-autocomplete #auto [compareWith]="compareFunction">
        <nz-auto-option
          *ngFor="let option of options.config.addressAutocompleteOptions"
          [nzValue]="option.text"
          [nzLabel]="option.streetLine"
          (selectionChange)="selectionChange($event)"
        >
          {{ option.text }}
        </nz-auto-option>
      </nz-autocomplete>
    </nz-input-group>
  </nz-form-control>
</nz-form-item>
