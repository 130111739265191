import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators"
import { MembershipUpdate } from "./membership-update.actions"
import { of, withLatestFrom } from "rxjs"
import { AccountDetails, ValidateSucceededResponseObject } from "../types/types"
import { getMembershipUpdateAccountDetails, getMembershipUpdateExecutionData } from "./membership-update.selectors"
import { getMembershipNumber } from "@aaa/emember/store-membership"
import { ExecuteService } from "../services/execute.service"
import { ClubApp } from "@aaa/emember/types"
import {
  MembershipMGetMemberInfoEventPayload,
  MembershipMMethod,
  MembershipMOperationExecuteChangeEventPayload,
  MembershipMValidateChangeEventPayload,
  MUserData,
  ThreePointAuth,
} from "@aaa/interface-joinRenew-membership-membershipM"
import { M } from "../m.type"
import { FormGroupValue } from "../../modules/share/form.utils"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { Operation, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { getThreePointAuth } from "@aaa/emember/store-account"
import { RequestError, RequestErrorType } from "../generic-errors"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import { checkMembershipErrorsMSystem } from "../check-membership-errors-m-system"
import { MembershipUpdateForm } from "./membership-update.models"
import { checkCybersourcePaymentValidation } from "../check-cybersource-payment-validation"
import { parseDateToApi } from "../utils/parse-date-to-api"
import { MembershipOfferSummary } from "../price-offers/helpers/types"
import { checkOperationErrorsMSystem } from "../check-operation-errors-m-system"
import { AnalyticsPurchaseEvent } from "../../../types/analytics-purchase-event"
import { DataLayerService } from "../../modules/share/services/data-layer.service"
import { AppAnalyticsEvents } from "../../../types/analytics-events"
import { getTransactionId } from "../utils/get-transaction-id"

@Injectable({ providedIn: "root" })
export class MembershipUpdateMSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Shelby, ClubApp.Northampton]))
  executeService = inject(ExecuteService)
  dataLayer = inject(DataLayerService)

  setSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpdate.recostValidateSucceeded),
      mergeMap(({ response }: ValidateSucceededResponseObject<M.ValidateResponseObject>) => {
        const { Result } = response.response.validateResponse
        const totalCost = Number(Result.Membership[0].DuesTotal[0].$.duesAmount)

        return [MembershipUpdate.setTotalCost({ totalCost })]
      }),
    ),
  )

  recostValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpdate.recostValidate),
      withLatestFrom(this.store.select(getMembershipNumber), this.store.select(getThreePointAuth)),
      switchMap(([{ params }, membershipNumber, threePointAuth]) => {
        const getMemberInfo$ = of(null).pipe(
          switchMap(() =>
            this.getMemberInfo(membershipNumber, threePointAuth).pipe(map(({ executionData }) => executionData)),
          ),
        )
        const getRecostValidation = (executionData: string) =>
          of(executionData).pipe(
            switchMap((executionData) => {
              return this.recostValidation(
                params.formValues,
                params.offers,
                params.payment,
                executionData,
                threePointAuth,
              ).pipe(map((validation) => ({ executionData, validation })))
            }),
          )

        return of(null).pipe(
          switchMap(() => getMemberInfo$),
          switchMap((executionData) =>
            getRecostValidation(executionData).pipe(
              tap(({ validation }) => {
                const accountDetails = new M.AccountInfo(
                  validation.response.validateResponse.Result.Membership[0],
                  validation.response.validateResponse.Result.MembershipOffering,
                )
                this.store.dispatch(MembershipUpdate.setAccountDetails({ accountDetails }))
              }),
            ),
          ),
          map(({ validation, executionData }) => {
            return MembershipUpdate.recostValidateSucceeded({
              executionData: executionData,
              response: validation,
            })
          }),
          catchError((error) => of(MembershipUpdate.recostValidateFailed({ error }))),
        )
      }),
    ),
  )

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpdate.pay),
      withLatestFrom(
        this.store.select(getMembershipUpdateAccountDetails),
        this.store.select(getMembershipUpdateExecutionData),
        this.store.select(getThreePointAuth),
      ),
      switchMap(([{ params }, accountDetails, executionData, threePointAuth]) =>
        this.pay(
          accountDetails,
          params.offers,
          params.formValues,
          executionData,
          params.totalCost,
          params.payment,
          threePointAuth,
        ).pipe(
          map((accountDetails) => MembershipUpdate.paySucceeded({ accountDetails })),
          catchError((error) => of(MembershipUpdate.payFailed({ error }))),
        ),
      ),
    ),
  )

  pay(
    accountDetails: AccountDetails | null,
    offers: MembershipOfferSummary,
    formValues: FormGroupValue<MembershipUpdateForm>,
    executionData: string,
    totalCost: number,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
    threePointAuth: ThreePointAuth,
  ) {
    const primary: MUserData = {
      type: "primary",
      membershipNumber: threePointAuth.memberNumber,
      options: offers.optionalPrimary.map((o) => o.code),
    }
    const associates: MUserData[] = (formValues.associates || []).map((associate, associateIndex) => {
      return {
        type: "associate",
        ...(!associate.membershipNumber && {
          dob: parseDateToApi(associate.birthday),
          email: associate.email,
          firstName: associate.firstName,
          lastName: associate.lastName,
        }),
        membershipNumber: associate.membershipNumber,
        options: (offers.associates[associateIndex]?.associateOptional || []).map(({ code }) => code),
      }
    })
    const membershipEvent: MembershipMOperationExecuteChangeEventPayload = {
      executionData,
      threePointAuth,
      method: MembershipMMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
      changePropsData: {
        autoRenew: !!payment.formValues.autoRenew,
        users: [primary, ...associates],
        membershipType: formValues.membershipType,
      },
    }
    const paymentPayload: PaymentCybersourceOperationExecuteEventPayload = {
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(accountDetails?.address?.street1),
          address2: String(accountDetails?.address?.street2),
          administrativeArea: String(accountDetails?.address?.state),
          buildingNumber: "",
          country: "US",
          district: String(accountDetails?.address?.state),
          email: String(accountDetails?.email || "fallback@avagate.com"),
          firstName: String(accountDetails?.firstName),
          lastName: String(accountDetails?.lastName),
          locality: String(accountDetails?.address.city),
          phoneNumber: String(accountDetails?.phone.cell),
          postalCode: String(accountDetails?.address.zip),
        },
        amountDetails: {
          totalAmount: String(totalCost),
          currency: "USD",
        },
        creditCardBrandedName: payment.formValues?.card?.cardName || "",
      },
    }
    const payload: OperationExecutePayload = {
      membershipEvent: membershipEvent,
      paymentEvent: paymentPayload,
      operation: Operation.UPDATE,
    }

    return this.executeService.execute<M.ValidateResponseObject, M.ExecutePaymentResponseObject>(payload).pipe(
      map(({ validateObject, paymentObject, operationObject }) => {
        const paymentError = !!paymentObject?.meta?.isError
        if (paymentError) {
          checkCybersourcePaymentValidation(paymentObject.error)
        }

        const membershipError = !!validateObject?.meta?.isError
        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject?.error, validateObject)
        }

        if (!validateObject) {
          throw new RequestError(RequestErrorType.UpgradedMembership, validateObject)
        }

        const operationError = !!operationObject?.meta?.isError
        if (operationError) {
          checkOperationErrorsMSystem(operationObject.error, operationObject)
        }

        const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
          currency: "USD",
          transaction_id: getTransactionId(paymentObject),
          value: totalCost,
          items: [{ quantity: 1, price: totalCost, item_id: "primary", item_name: AppAnalyticsEvents.AccountUpdate }],
          context: "ava-store " + AppAnalyticsEvents.AccountUpdate,
        }
        this.dataLayer.purchaseEvent(analyticsEventParams)

        return new M.AccountInfo(validateObject.response.validateResponse.Result.Membership[0])
      }),
    )
  }

  recostValidation(
    formValues: FormGroupValue<MembershipUpdateForm>,
    offers: MembershipOfferSummary,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
    executionData: string,
    threePointAuth: ThreePointAuth,
  ) {
    const primary: MUserData = {
      type: "primary",
      membershipNumber: threePointAuth.memberNumber,
      options: offers.optionalPrimary.map((o) => o.code),
    }
    const associates: MUserData[] = (formValues.associates || []).map((associate, associateIndex) => {
      return {
        type: "associate",
        ...(!associate.membershipNumber && {
          firstName: associate.firstName,
          lastName: associate.lastName,
          dob: parseDateToApi(associate.birthday),
          email: associate.email,
        }),
        membershipNumber: associate.membershipNumber,
        options: (offers.associates[associateIndex]?.associateOptional || []).map(({ code }) => code),
      }
    })
    const payload: MembershipMValidateChangeEventPayload = {
      method: MembershipMMethod.VALIDATE_CHANGE,
      executionData,
      threePointAuth,
      changePropsData: {
        autoRenew: !!payment.formValues.autoRenew,
        users: [primary, ...associates],
        membershipType: formValues.membershipType,
      },
    }

    return this.executeService.membershipQuery<M.ValidateResponseObject>(payload).pipe(
      map((validateObject) => {
        const membershipError = validateObject?.meta?.isError

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject?.error, validateObject)
        }

        return validateObject
      }),
    )
  }

  getMemberInfo(membershipNumber: string, threePointAuth: ThreePointAuth) {
    const payload: MembershipMGetMemberInfoEventPayload = {
      method: MembershipMMethod.GET_MEMBER_INFO,
      membershipNumber,
      threePointAuth,
    }

    return this.executeService.membershipQuery<M.MemberInfoResponseObject>(payload).pipe(
      map((validateObject) => {
        const membershipError = !!validateObject?.meta?.isError

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject?.error, validateObject)
        }

        return validateObject.response
      }),
    )
  }
}
