import { CanActivateChildFn } from "@angular/router"
import { inject } from "@angular/core"
import { of } from "rxjs"
import { tap } from "rxjs/operators"
import { Store } from "@ngrx/store"
import { AppStore } from "@aaa/emember/store-types"
import { MembershipOfferPage, MembershipOffersActions } from "@aaa/emember/store-membership-offers"

export const setPageMembershipOffer =
  (page: MembershipOfferPage): CanActivateChildFn =>
  () => {
    const store = inject(Store<AppStore>)

    return of(true).pipe(tap(() => store.dispatch(MembershipOffersActions.setPage({ page }))))
  }

export default setPageMembershipOffer
