import {
  Membership,
  MembershipDuesComponent,
  MembershipOffering,
} from "@aaa/interface-joinRenew-membership-membershipM"
import { MembershipOfferItem, MembershipOfferCondition, OfferOperator } from "./types"

const convertOperand = (operator: string): OfferOperator => {
  switch (operator) {
    case "ge":
      return "moreThanOrEqual"
    case "le":
      return "lessThanOrEqual"
    default:
      return "equal"
  }
}

const medicalRiderRule = (component: MembershipOffering["DuesComponent"][0]): MembershipOfferCondition[] => {
  if (component.$.componentCode.startsWith("MED")) {
    return [
      {
        value: true,
        operator: "equal",
        apply: "medicalRider",
      },
    ]
  }

  return []
}

const normalizeDescription = (component: MembershipOffering["DuesComponent"][0]): MembershipOfferItem => {
  const [description] = component.$.componentDescription.split("|")

  return {
    offering: "optional",
    description,
    code: component.$.componentCode,
    amount: Number(component.$.componentAmount),
    selectedByDefault: false,
    conditions: [],
  }
}

const promoCodeRule = (component: MembershipOffering["DuesComponent"][0]): MembershipOfferCondition[] => {
  const [description, promoCode] = component.$.componentDescription.split("|")

  if (component.$.componentDescription === `${description}|${promoCode}`) {
    const conditions: MembershipOfferCondition[] = [
      {
        value: true,
        operator: "equal",
        apply: "promoCode",
      },
    ]

    if (component.$.autoRenew === "Y") {
      conditions.push({
        value: true,
        operator: "equal",
        apply: "autorenew",
      })
    }

    return conditions
  }

  return []
}

const convertRule = (rules: MembershipDuesComponent["Rule"]): MembershipOfferCondition[] => {
  const conditions: MembershipOfferCondition[] = []

  if (rules) {
    rules.forEach((rule) => {
      if (rule.AssociateCount) {
        rule.AssociateCount.forEach((condition) => {
          conditions.push({
            apply: "associateCount",
            value: Number(condition.$.value),
            operator: convertOperand(condition.$.operand),
          })
        })
      }
    })
  }

  return conditions
}

export function convertMembershipOfferComponents(offering?: MembershipOffering | null): MembershipOfferItem[] {
  const components: MembershipOfferItem[] = []

  if (offering) {
    offering.AssociateOffering?.forEach((offering) => {
      offering.DuesComponent.forEach((component, index) => {
        components.push({
          ...normalizeDescription(component),
          offering: index === 0 ? "associate" : "associateOptional",
          selectedByDefault: offering?.$?.selectOptionByDefault == "Y",
          conditions: [...convertRule(component.Rule), ...promoCodeRule(component)],
        })
      })
    })

    offering.OptionalOfferingAssociate?.forEach((offering) => {
      offering.DuesComponent.forEach((component) => {
        components.push({
          ...normalizeDescription(component),
          offering: "associateOptional",
          selectedByDefault: offering?.$?.selectOptionByDefault == "Y",
          conditions: [...convertRule(component.Rule), ...medicalRiderRule(component), ...promoCodeRule(component)],
        })
      })
    })

    offering.DuesComponent?.forEach((component, index) => {
      components.push({
        ...normalizeDescription(component),
        offering: index === 0 ? "primary" : "optionalPrimary",
        selectedByDefault: true,
        conditions: [...convertRule(component.Rule), ...medicalRiderRule(component), ...promoCodeRule(component)],
      })
    })

    offering.OptionalOfferingPrimary?.forEach((offering) => {
      offering.DuesComponent.forEach((component) => {
        components.push({
          ...normalizeDescription(component),
          offering: "optionalPrimary",
          selectedByDefault: offering?.$?.selectOptionByDefault == "Y",
          conditions: [...convertRule(component.Rule), ...medicalRiderRule(component), ...promoCodeRule(component)],
        })
      })
    })

    offering.OptionalOffering?.forEach((offering) => {
      offering.DuesComponent.forEach((component) => {
        components.push({
          ...normalizeDescription(component),
          offering: "optional",
          selectedByDefault: offering?.$?.selectOptionByDefault === "Y",
          conditions: [...convertRule(component.Rule), ...promoCodeRule(component)],
        })
      })
    })
  }

  return components
}

export function convertMembership(membership: Membership): MembershipOfferItem[] {
  const components: MembershipOfferItem[] = []

  if (membership?.PrimaryMember?.length) {
    membership.PrimaryMember[0].DuesComponent.forEach((duesComponent, index) => {
      components.push({
        ...normalizeDescription(duesComponent),
        offering: index === 0 ? "primary" : "optionalPrimary",
        selectedByDefault: true,
        conditions: [
          ...convertRule(duesComponent.Rule),
          ...medicalRiderRule(duesComponent),
          ...promoCodeRule(duesComponent),
        ],
      })
    })
  }

  if (membership?.AssociateMember?.length) {
    const offering = membership?.AssociateMember[0]
    membership.AssociateMember[0].DuesComponent.forEach((duesComponent, index) => {
      components.push({
        ...normalizeDescription(duesComponent),
        offering: index === 0 ? "associate" : "associateOptional",
        selectedByDefault: offering.$?.selectOptionByDefault == "Y",
        conditions: [...convertRule(duesComponent.Rule), ...promoCodeRule(duesComponent)],
      })
    })
  }

  return components
}
