import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core"
import { ControlContainer, FormArray, FormGroup, ReactiveFormsModule } from "@angular/forms"
import { NzIconModule } from "ng-zorro-antd/icon"
import { CommonModule } from "@angular/common"
import { LetModule } from "@ngrx/component"
import { FormInputComponent } from "../form/controls/input"
import { FormSelectComponent } from "../form/controls/select"
import { ButtonComponent } from "../ava-button/ava-button.component"
import { FormDateComponent } from "../form/controls/date"
import { FormPasswordComponent } from "../form/controls/password"
import { FormRadioComponent } from "../form/controls/radio"
import { FormTelComponent } from "../form/controls/tel"
import { FormArrayValue } from "../form.utils"
import { MembershipAssociateForm } from "./membership-associate-form.model"
import { MembershipAssociateVm } from "./membership-associate.vm"
import { nameSuffix } from "../../../constants/name-suffix"
import { FormCheckBoxComponent } from "../form/controls/checkbox"
import { NzToolTipModule } from "ng-zorro-antd/tooltip"
import { Store } from "@ngrx/store"
import { AppStore } from "@aaa/emember/store-types"
import { getMembershipAssociateFormErrors } from "../../../store/form-messages"
import { AvaIconComponent } from "../ava-icon/ava-icon.component"
import { FormCheckBoxMedicalComponent } from "../form/controls/checkbox-medical"
import { AvaDialogService } from "../services/ava-dialog.service"
import { NzButtonModule } from "ng-zorro-antd/button"

@Component({
  selector: "ava-membership-associate-form",
  templateUrl: "./membership-associate-form.html",
  styleUrls: ["./membership-associate-form.scss"],
  imports: [
    FormInputComponent,
    FormSelectComponent,
    ReactiveFormsModule,
    NzIconModule,
    ButtonComponent,
    FormDateComponent,
    FormPasswordComponent,
    FormRadioComponent,
    FormTelComponent,
    LetModule,
    CommonModule,
    FormCheckBoxComponent,
    NzToolTipModule,
    AvaIconComponent,
    FormCheckBoxMedicalComponent,
    NzButtonModule,
  ],
  standalone: true,
})
export class MembershipAssociateFormComponent implements OnChanges {
  dialog = inject(AvaDialogService)
  membershipAssociateVm = inject(MembershipAssociateVm)
  controlContainer = inject(ControlContainer)
  store = inject(Store<AppStore>)
  @Input() accidentMedicalPlanPrice = 0
  @Input() showAccidentMedicalPlan: boolean | null = false
  @Input() totalAssociates = 0
  @Input() maxAssociate = 9
  @Input() formValues: FormArrayValue<FormGroup<MembershipAssociateForm>> = []
  @Output() addAssociate = new EventEmitter()
  @Output() removeAssociate = new EventEmitter<number>()
  @Output() accidentMedicalChanged = new EventEmitter()
  errorMessages$ = this.store.select(getMembershipAssociateFormErrors)
  readonly nameSuffix = nameSuffix

  get formArray(): FormArray<FormGroup<MembershipAssociateForm>> {
    let control = this.membershipAssociateVm.create()
    if (this.controlContainer) {
      control = this.controlContainer.control as FormArray<FormGroup<MembershipAssociateForm>>
    }

    return control
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formValues) {
      this.updateAssociatesFormArray(this.formValues)
    }
  }

  updateAssociatesFormArray(values: FormArrayValue<FormGroup<MembershipAssociateForm>>) {
    const associates = values || []
    if (this.formArray.length === 0 && associates.length > 0) {
      associates
        .map((values) => this.membershipAssociateVm.createAssociate(values))
        .forEach((associate, index) => this.formArray.insert(index, associate))
    }
  }

  addAssociates() {
    if (this.formArray.valid && this.formArray.length + this.totalAssociates < this.maxAssociate) {
      this.addAssociate.emit()
    }
  }

  removeAssociates(index: number) {
    this.removeAssociate.emit(index)
  }
}
