import { Inject, Injectable } from "@angular/core"
import firebase from "firebase/compat/app"
import "firebase/firestore"
import { interval } from "rxjs"
import { tap } from "rxjs/operators"
import { RxEffects } from "@rx-angular/state/effects"
import { HOOSIER_RX_STATE, HoosierState } from "../hoosier.service"
import { RxState } from "@rx-angular/state"
import Timestamp = firebase.firestore.Timestamp
import { AlertMessage, AlertMessageService, AlertType } from "../../../../services/alert-message"

@Injectable({
  providedIn: "root"
})
export class TimeoutErrorService {

  constructor(
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private alertMessageService: AlertMessageService,
  ) {
    rxEffects.register(this.timeout$)
  }

  timeout$ = interval(1000)
    .pipe(
      tap(() => {
        const hoosierState = this.hoosierState.get()
        const eventKeys = [
          hoosierState.MEMBER_LOOKUP_KEY,
          hoosierState.PAYMENT_CAPTURE_CONTEXT_KEY,
          hoosierState.PRICE_PREVIEWS_KEY,
          hoosierState.VALIDATE_ASSOCIATES_KEY,
          hoosierState.VALIDATE_AUTO_RENEW_KEY,
          hoosierState.VALIDATE_JOIN_KEY,
          hoosierState.VALIDATE_LEVEL_KEY,
          hoosierState.VALIDATE_RENEW_KEY
        ]
        eventKeys.map(eventKey => {
          if (eventKey) {
            const keyTimestamp = Number(eventKey)
            const keyDuration = Timestamp.now().toMillis() - keyTimestamp
            if (keyDuration > 60 * 1000) {
              this.alertMessageService.set(AlertMessage.ERROR_CRITICAL, AlertType.ERROR)
            }
          }
        })
      }),
    )

}
