export function interfaceJoinRenewMembershipMembershipM(): string {
  return "interface-join-renew-membership-membership-m"
}

import { EventPayloadSchema, Operation, OperationEventResponseSchema } from "@aaa/interface-joinRenew-joinRenewLib"
import { z } from "zod"

export enum MembershipMMethod {
  IS_ALIVE = "IS_ALIVE",

  PRICE_PREVIEWS = "PRICE_PREVIEWS",

  GET_MEMBER_INFO = "GET_MEMBER_INFO",
  GET_OFFERINGS = "GET_OFFERINGS",
  VALIDATE_JOIN = "VALIDATE_JOIN",
  VALIDATE_CHANGE = "VALIDATE_CHANGE",

  // VALIDATE_CHANGE_LEVEL = "VALIDATE_CHANGE_LEVEL", // not yet implemented
  // VALIDATE_CHANGE_ASSOCIATES = "VALIDATE_CHANGE_ASSOCIATES", // not yet implemented

  OPERATION_EXECUTE = "OPERATION_EXECUTE",
}

export const MUserDataSchema = z.object({
  type: z.enum(["primary", "associate"]), // 1 primary, 0-8 associates
  membershipNumber: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  dob: z.string().optional(), // yyyymmdd or yyyyddmm need to test which
  email: z.string().optional(),
  homePhone: z.string().optional(),
  cellPhone: z.string().optional(), // TODO any rules about phoneType?
  options: z.array(z.string()).transform((val) => (Array.isArray(val) ? val : [])),
})
export type MUserData = z.infer<typeof MUserDataSchema>

export const AddressDataSchema = z.object({
  address1: z.string(),
  address2: z.string().optional(),
  cityName: z.string(),
  stateProv: z.string(),
  postalCode: z.string(),
})
export type AddressData = z.infer<typeof AddressDataSchema>

export const PromoDataSchema = z.object({
  promoCode: z.string().optional(),
  couponCode: z.string().optional(),
  programCode: z.string().optional(),
})
export type PromoData = z.infer<typeof PromoDataSchema>

export const MembershipDonorInformationSchema = z.object({
  donorInformation: z.object({
    namePrefix: z.string().optional(),
    firstName: z.string(),
    middleInitial: z.string().optional(),
    lastName: z.string(),
    nameSuffix: z.string().optional(),

    homePhone: z.string().optional(), // do we need to show all 3 of these?
    businessPhone: z.string().optional(),
    cellPhone: z.string().optional(),

    email: z.string(),
  }),
  address: z.object({
    // which of these are actually required?
    address1: z.string(),
    address2: z.string().optional(),
    cityName: z.string(),
    stateProv: z.string(),
    postalCode: z.string(),
  }),
  giftOptions: z.object({
    // these may be extended apparently, for now the api only supports 1 option
    renewalType: z.literal("P"),
    sendMbrCardTo: z.literal("D"),
  }),
})
export type MembershipDonorInformation = z.infer<typeof MembershipDonorInformationSchema>

export const JoinDataSchema = z.object({
  membershipType: z.string(), // level: ex: B
  autoRenew: z.boolean().optional(),
  address: AddressDataSchema.optional(),
  userData: z.array(MUserDataSchema),
  promoData: PromoDataSchema.optional(),
  options: z.array(z.string()).optional(), // list of OptionalOffering.DuesComponent.componentCode,
  donorInfo: MembershipDonorInformationSchema.optional(),
  gift: z.boolean().optional(),
})
export type JoinData = z.infer<typeof JoinDataSchema>

export const ChangePropsDataSchema = z.object({
  membershipType: z.string().optional(),
  autoRenew: z.boolean().optional(), // true = add, false = remove, undefined = ignore
  address: AddressDataSchema.optional(),
  options: z.array(z.string()).optional(), // list of OptionalOffering.DuesComponent.componentCode,
  users: z.array(MUserDataSchema).optional(),
})
export type ChangePropsData = z.infer<typeof ChangePropsDataSchema>

export const ThreePointAuthSchema = z.object({
  lastName: z.string(),
  zip: z.string(),
  memberNumber: z.string(),
})

export type ThreePointAuth = z.infer<typeof ThreePointAuthSchema>

export const MembershipMEventPayloadSchema = z
  .object({
    method: z.nativeEnum(MembershipMMethod),
    responseType: z.any(),
  })
  .extend(EventPayloadSchema.shape)
export type MembershipMEventPayload = z.infer<typeof MembershipMEventPayloadSchema>

export interface MembershipMIsAliveEventPayload extends MembershipMEventPayload {
  method: MembershipMMethod.IS_ALIVE

  responseType?: MembershipMIsAliveResponse
}

export interface MembershipMIsAliveResponse {
  isAlive: boolean
}

export interface MembershipMPricePreviewsEventPayload extends MembershipMEventPayload {
  method: MembershipMMethod.PRICE_PREVIEWS
  zip: string
  promoCode?: string
  responseType?: MembershipMGetOfferingsResponse
}

export interface MembershipMPricePreviewsResponse<T = any> {
  pricePreviewsResponse: T
  prices: MembershipMPrices[]
}

export const MembershipMPricesSchema = z.object({
  metadata: z.any(),
  prices: z.array(
    z.object({
      associates: z.number(),
      price: z.number(),
    }),
  ),
})
export type MembershipMPrices = z.infer<typeof MembershipMPricesSchema>

export interface MembershipMGetMemberInfoEventPayload extends MembershipMEventPayload {
  method: MembershipMMethod.GET_MEMBER_INFO
  membershipNumber: string
  threePointAuth: ThreePointAuth

  responseType?: MembershipMMemberInfoResponse
}

export interface MembershipMMemberInfoResponse<T = any> {
  memberInfoResponse: T
  executionData: string
}

export interface MembershipMGetOfferingsEventPayload extends MembershipMEventPayload {
  method: MembershipMMethod.GET_OFFERINGS
  promoCode?: string

  zip: string

  responseType?: MembershipMGetOfferingsResponse
}

export interface MembershipMGetOfferingsResponse<T = any> {
  offeringsResponse: T
  executionData: string
}

export const MembershipMValidateChangeResponseSchema = z.object({
  validateResponse: z.any(),
  exectionData: z.string(),
})
export type MembershipMValidateChangeResponse = z.infer<typeof MembershipMValidateChangeResponseSchema>

export const MembershipMValidateChangeEventPayloadSchema = z
  .object({
    method: z.enum([MembershipMMethod.VALIDATE_CHANGE]),
    changePropsData: ChangePropsDataSchema.optional(),
    threePointAuth: ThreePointAuthSchema,
    executionData: z.string(), // MembershipMMemberInfoResponse.executionData
    responseType: MembershipMValidateChangeResponseSchema.optional(),
  })
  .extend(MembershipMEventPayloadSchema.shape)
export type MembershipMValidateChangeEventPayload = z.infer<typeof MembershipMValidateChangeEventPayloadSchema>

export interface MembershipMValidateJoinEventPayload extends MembershipMEventPayload {
  method: MembershipMMethod.VALIDATE_JOIN

  joinData: JoinData
  executionData: string // MembershipMGetOfferingsResponse.executionData

  responseType?: MembershipMValidateJoinResponse
}

export interface MembershipMValidateJoinResponse<T = any> {
  validateResponse: T
  executionData: string
}

export const MembershipMOperationExecuteEventPayloadSchema = z
  .object({
    method: z.enum([MembershipMMethod.OPERATION_EXECUTE]),
    operation: z.nativeEnum(Operation),
    executionData: z.string(),
    payment: z.any().optional(),
    responseType: OperationEventResponseSchema.optional(),
  })
  .extend(MembershipMEventPayloadSchema.shape)
export type MembershipMOperationExecuteEventPayload = z.infer<typeof MembershipMOperationExecuteEventPayloadSchema>

export const MembershipMOperationExecuteJoinEventPayloadSchema = z
  .object({
    operation: z.enum([Operation.JOIN]),
    joinData: JoinDataSchema,
  })
  .extend(MembershipMOperationExecuteEventPayloadSchema.shape)
export type MembershipMOperationExecuteJoinEventPayload = z.infer<
  typeof MembershipMOperationExecuteJoinEventPayloadSchema
>

export const MembershipMOperationExecuteRenewEventPayloadSchema = z
  .object({
    operation: z.enum([Operation.RENEW]),
    threePointAuth: ThreePointAuthSchema,
    autoRenew: z.boolean().optional(),
  })
  .extend(MembershipMOperationExecuteEventPayloadSchema.shape)
export type MembershipMOperationExecuteRenewEventPayload = z.infer<
  typeof MembershipMOperationExecuteRenewEventPayloadSchema
>

export const MembershipMOperationExecuteChangeEventPayloadSchema = z
  .object({
    operation: z.enum([Operation.UPDATE]),
    threePointAuth: ThreePointAuthSchema,
    changePropsData: ChangePropsDataSchema.optional(),
  })
  .extend(MembershipMOperationExecuteEventPayloadSchema.shape)
export type MembershipMOperationExecuteChangeEventPayload = z.infer<
  typeof MembershipMOperationExecuteChangeEventPayloadSchema
>

export enum MMembershipType {
  Basic = "B",
  Plus = "P",
  PlusRv = "R",
  Premier = "E",
  PremierRv = "V",
}

export const MMembershipNames = [
  { code: "B", name: "Basic" },
  { code: "P", name: "Plus" },
  { code: "R", name: "Plus RV" },
  { code: "E", name: "Premier" },
  { code: "V", name: "Premier RV" },
]

export function getMembershipTypeFromCode(code: string) {
  if (!code) return ""
  const entry = MMembershipNames.find((m) => m.code.toUpperCase() === code.toUpperCase())
  if (entry) return entry.name
  return ""
}

export const MBooleanSchema = z.enum(["N", "Y"])
export type MBoolean = z.infer<typeof MBooleanSchema>

export const MMembershipCodeSchema = z.enum([
  "BP",
  "PP",
  "RP",
  "EP",
  "VP",
  "JF",
  "MED750",
  "DAB",
  "PA",
  "EA",
  "BA",
  "JFW",
])
export type MMembershipCode = z.infer<typeof MMembershipCodeSchema>

export const MembershipDueComponentItemSchema = z.object({
  componentCode: MMembershipCodeSchema,
  componentAmount: z.string(), //"57.00"
  componentCurrency: z.string(), //"USD"
  componentDescription: z.string(),
  autoRenew: MBooleanSchema.optional(),
  requiresCreditCard: MBooleanSchema.optional(),
  pricing: z.string().optional(), //"G"
})
export type MembershipDueComponentItem = z.infer<typeof MembershipDueComponentItemSchema>

export const MembershipDuesComponentSchema = z.object({
  $: MembershipDueComponentItemSchema,
  Rule: z
    .array(
      z.object({
        AssociateCount: z.array(
          z.object({
            $: z.object({
              value: z.string(), // "1"
              operand: z.string(), // "ge" | "le"
            }),
          }),
        ),
      }),
    )
    .optional(),
})
export type MembershipDuesComponent = z.infer<typeof MembershipDuesComponentSchema>

export const MembershipInformationSchema = z.object({
  membershipNumber: z.string(), // "6201880240214006"
  firstName: z.string(),
  lastName: z.string(),
  membershipType: z.nativeEnum(MMembershipType),
  middleInitial: z.string(),
  dob: z.string(),
  homePhone: z.string().default(""),
  cellPhone: z.string().default(""),
  businessPhone: z.string().default(""),
  nameSuffix: z.string(),
  title: z.string(),
  email: z.string(), // "dorin.turturean shelby@avagate.com"
  selectOptionByDefault: MBooleanSchema.optional(),
})
export type MembershipInformation = z.infer<typeof MembershipInformationSchema>

export const MembershipAddressInformationSchema = z.object({
  stateProv: z.string(),
  cityName: z.string(),
  address2: z.string(),
  address1: z.string(),
  postalCode: z.string(),
})
export type MembershipAddressInformation = z.infer<typeof MembershipAddressInformationSchema>

export const MembershipDuesTotalSchema = z.object({
  $: z.object({
    duesAmount: z.string(),
    hasRenewal: MBooleanSchema,
  }),
})
export type MembershipDuesTotal = z.infer<typeof MembershipDuesTotalSchema>

export const MembershipAddressSchema = z.object({
  $: MembershipAddressInformationSchema,
})
export type MembershipAddress = z.infer<typeof MembershipAddressSchema>

export const MembershipMemberSchema = z.object({
  $: MembershipInformationSchema,
  DuesComponent: z.array(MembershipDuesComponentSchema),
})
export type MembershipMember = z.infer<typeof MembershipMemberSchema>

export const MResponseSchema = z.object({
  errorText: z.string().optional(), //"Unable to process. Duplicate membership joins appear to have been received today."
  returnCode: z.string().optional(),
  type: z.string().optional(),
  userMessage: z.string().optional(),
})
export type MResponse = z.infer<typeof MResponseSchema>

export const MembershipSchema = z.object({
  PrimaryMember: z.array(MembershipMemberSchema),
  $: z.object({
    promotionalCode: z.string(),
    hasAutoRenew: z.enum(["Y", "N"]),
    // Y yes user is signed up for AR
    // N no user not on AR
    renewalStatus: z.enum(["A", "D", "C", "S"]),
    // A auto renew - can't pay manually
    // D due (money due)
    // C current - don't owe money
    // S suspended - don't let them do anything
    monthly: z.string(), // "N"
    expDate: z.string(), // 20230831
  }),
  Address: z.array(MembershipAddressSchema),
  AssociateMember: z.array(MembershipMemberSchema).optional(),
  DuesTotal: z.array(MembershipDuesTotalSchema),
})
export type Membership = z.infer<typeof MembershipSchema>

export const MembershipOfferingInformationSchema = z.object({
  offerId: z.string(),
  offerName: z.string().optional(),
  offerDescription: z.string(),
  towMiles: z.string().optional(),
  membershipType: z.nativeEnum(MMembershipType).optional(),
  monthly: MBooleanSchema.optional(),
  allowPromo: MBooleanSchema.optional(),
  selectOptionByDefault: MBooleanSchema.optional(),
})
export type MembershipOfferingInformation = z.infer<typeof MembershipOfferingInformationSchema>

export const MMembershipOfferingSchema = z.object({
  $: MembershipOfferingInformationSchema,
  DuesComponent: z.array(MembershipDuesComponentSchema),
})
export type MMembershipOffering = z.infer<typeof MMembershipOfferingSchema>

export const MembershipOfferingSchema = z.object({
  $: MembershipOfferingInformationSchema,
  DuesComponent: z.array(MembershipDuesComponentSchema),
  AssociateOffering: z.array(MMembershipOfferingSchema),
  OptionalOffering: z.array(MMembershipOfferingSchema),
  OptionalOfferingPrimary: z.array(MMembershipOfferingSchema),
  OptionalOfferingAssociate: z.array(MMembershipOfferingSchema),
})
export type MembershipOffering = z.infer<typeof MembershipOfferingSchema>

export const MembershipInfoSchema = z.object({
  Result: z
    .object({
      $: MResponseSchema,
      Membership: z.array(MembershipSchema),
    })
    .passthrough(),
})
export type MembershipInfo = z.infer<typeof MembershipInfoSchema>

export const ChangeRequestResponseSchema = z.object({
  Result: z.object({
    $: MResponseSchema,
  }),
})

export type ChangeRequestResponse = z.infer<typeof ChangeRequestResponseSchema>

export const JoinRequestResponseSchema = z.object({
  Result: z.object({
    $: MResponseSchema,
  }),
})

export type JoinRequestResponse = z.infer<typeof JoinRequestResponseSchema>
