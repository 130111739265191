import { Injectable } from "@angular/core"
import { environment } from "../../environments/environment"
import { HttpClient, HttpErrorResponse } from "@angular/common/http"
import { BehaviorSubject, Observable, throwError } from "rxjs"
import { catchError, tap } from "rxjs/operators"
import GeocoderGeometry = google.maps.GeocoderGeometry

@Injectable({
  providedIn: "root"
})
export class GeocodingService {
  private postalGeometrySubject = new BehaviorSubject<google.maps.GeocoderGeometry>({} as GeocoderGeometry)
  postalGeometry$: Observable<google.maps.GeocoderGeometry>

  constructor(
    public http: HttpClient,
  ) {
    this.postalGeometry$ = this.postalGeometrySubject.asObservable()
  }

  public unsetPostalGeometry(): void {
    this.postalGeometrySubject.next({} as GeocoderGeometry)
  }

  lookupPostalGeometry(postal: number): void {
    const url = "https://maps.googleapis.com/maps/api/geocode/json"
    const addr = "?address=" + postal
    const key = "&key=" + environment.firebaseConfig.apiKey

    this.http
      .get<{ results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus }>(url + addr + key)
      .subscribe((response: { results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus }) => {
        const postalGeometry: google.maps.GeocoderGeometry = response["results"][0].geometry
        this.postalGeometrySubject.next(postalGeometry)
      })
    /*
    <{ results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus }>
          const response = await request.toPromise().catch(() => {
            //
          })

          if (!response['results'][0] || response['results'][0].types[0] !== "postal_code") {
            return false
            // return { validatorError: "no results" }
          }


          return true

        } catch (e) {
          console.log("getGeometryForZip lookup error")
          console.log(e)
          return false
          // return { validatorError: "connection error" }
        }
    */
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error)
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${ error.status }, body was: `, error.error)
    }
    // Return an observable with a user-facing error message.
    return throwError(
      "Something bad happened; please try again later.")
  }

  /*
           "postalGeometry" : {
            "bounds" : {
               "northeast" : {
                  "lat" : 40.77154910000001,
                  "lng" : -111.891074
               },
               "southwest" : {
                  "lat" : 40.7416009,
                  "lng" : -111.9143649
               }
            },
            "location" : {
               "lat" : 40.7563925,
               "lng" : -111.8985922
            },
            "location_type" : "APPROXIMATE",
            "viewport" : {
               "northeast" : {
                  "lat" : 40.77154910000001,
                  "lng" : -111.891074
               },
               "southwest" : {
                  "lat" : 40.7416009,
                  "lng" : -111.9143649
               }
            }
         },
  */

}
