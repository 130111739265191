import { Component, EventEmitter, Input, Output } from "@angular/core"
import { NzButtonModule } from "ng-zorro-antd/button"
import { CommonModule } from "@angular/common"
import { NzIconModule } from "ng-zorro-antd/icon"
import { NzNoAnimationModule } from "ng-zorro-antd/core/no-animation"
import { NZ_WAVE_GLOBAL_CONFIG } from "ng-zorro-antd/core/wave"

@Component({
  standalone: true,
  selector: "ava-button",
  templateUrl: "./ava-button.component.html",
  providers: [
    {
      provide: NZ_WAVE_GLOBAL_CONFIG,
      useValue: {
        disabled: true,
      },
    },
  ],
  imports: [NzNoAnimationModule, NzButtonModule, NzIconModule, CommonModule],
  styleUrls: ["./ava-button.component.scss"],
})
export class ButtonComponent {
  @Input() type: "primary" | "primary-outline" | "default" | "dashed" | "link" | "text" = "primary"
  @Input() shape: "circle" | "round" | "" = ""
  @Input() size: "large" | "small" | "default" = "default"
  @Input() isLoading: boolean | null = false
  @Input() disabled: boolean | null = false
  @Input() leftIcon = ""
  @Input() iconSize = 14
  @Input() iconTheme: "fill" | "outline" | "twotone" = "outline"
  @Input() block: boolean | null = false
  @Output() clicked = new EventEmitter()

  clickedBtn(event: Event) {
    this.clicked.emit()
  }
}
