import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { of, withLatestFrom } from "rxjs"
import { catchError, map, switchMap } from "rxjs/operators"
import { ClubApp } from "@aaa/emember/types"
import { MembershipAssociateAddActions } from "./membership-associate-add.actions"
import { ExecuteService } from "../services/execute.service"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import {
  MembershipMzpGetMembershipCostsChange,
  MembershipMzpMethod,
  MembershipMzpOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipMzp"
import { getShortMembershipNumber } from "@aaa/emember/store-account"
import {
  getMembershipAssociateAddAccountDetails,
  getMembershipAssociateExecutionData,
  getMembershipAssociateFormValues,
  MembershipAssociateAddAccount,
} from "./membership-associate-add.selectors"
import { FormGroupValue } from "../../modules/share/form.utils"
import { MembershipAssociateAddForm } from "./membership-associate-add.models"
import { AccountDetails, ValidateSucceededResponseObject } from "@aaa/emember/store-types"
import { Mzp } from "../mzp.type"
import { getPriceOfferRawLevelOffers, MembershipLevelOffer } from "@aaa/emember/store-price-offers"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { Operation, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { RequestError, RequestErrorType } from "../generic-errors"
import { getPayment } from "@aaa/emember/store-payment"
import { checkCybersourcePaymentValidation } from "../check-cybersource-payment-validation"
import { AnalyticsPurchaseEvent } from "../../../types/analytics-purchase-event"
import { DataLayerService } from "../../modules/share/services/data-layer.service"
import { AppAnalyticsEvents } from "../../../types/analytics-events"
import { getTransactionId } from "../utils/get-transaction-id"

@Injectable({ providedIn: "root" })
export class MembershipAssociateAddMzpSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.MidStates]))
  executeService = inject(ExecuteService)
  dataLayer = inject(DataLayerService)

  updateSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.recostValidateSucceeded),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(getMembershipAssociateFormValues),
            this.store.select(getPriceOfferRawLevelOffers),
          ),
        ),
      ),
      map(
        ([{ response }, formValues, offers]: [
          ValidateSucceededResponseObject<Mzp.MemberInfo>,
          FormGroupValue<MembershipAssociateAddForm>,
          MembershipLevelOffer | null,
        ]) => {
          const newResponse = structuredClone(response)
          const associates = formValues.associates || []
          const totalNewAssociates = associates.length

          // Description: small patch response with name those new members
          associates.forEach((associate, index) => {
            const position = newResponse.customers.length - totalNewAssociates + index

            if (newResponse.customers[position]) {
              newResponse.customers[position] = {
                ...newResponse.customers[position],
                firstName: associate.firstName || "",
                lastName: associate.lastName || "",
              }
            }
          })

          const accountDetails = new Mzp.AccountInfo(newResponse, offers)

          return MembershipAssociateAddActions.setAccountDetails({ accountDetails })
        },
      ),
    ),
  )

  recostValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.recostValidate, MembershipAssociateAddActions.validatePromoCode),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(getMembershipAssociateFormValues),
            this.store.select(getShortMembershipNumber),
          ),
        ),
      ),
      switchMap(([, formValues, membershipNumber]) => {
        return this.recostValidation(formValues, membershipNumber).pipe(
          map((res) => MembershipAssociateAddActions.recostValidateSucceeded(res)),
          catchError((error) => of(MembershipAssociateAddActions.recostValidateFailed({ error }))),
        )
      }),
    ),
  )

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.pay),
      withLatestFrom(
        this.store.select(getMembershipAssociateAddAccountDetails),
        this.store.select(getMembershipAssociateExecutionData),
        this.store.select(MembershipAssociateAddAccount.getBalance),
        this.store.select(getPayment),
      ),
      switchMap(([{ formValues }, accountDetails, executionData, totalCost, payment]) =>
        this.pay(accountDetails, formValues, executionData, totalCost, payment).pipe(
          map((accountDetails) => MembershipAssociateAddActions.paySucceeded({ accountDetails })),
          catchError((error) => of(MembershipAssociateAddActions.payFailed({ error }))),
        ),
      ),
    ),
  )

  pay(
    accountDetails: AccountDetails | null,
    formValues: Partial<FormGroupValue<MembershipAssociateAddForm>>,
    executionData: string,
    totalCost: number,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
  ) {
    const membershipEvent: MembershipMzpOperationExecuteEventPayload = {
      executionData,
      operation: Operation.UPDATE,
      method: MembershipMzpMethod.OPERATION_EXECUTE,
    }
    const paymentPayload: PaymentCybersourceOperationExecuteEventPayload = {
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(formValues?.billing?.billingTo?.address1 || ""),
          address2: String(formValues?.billing?.billingTo?.address2 || ""),
          administrativeArea: String(formValues?.billing?.billingTo?.state || ""),
          buildingNumber: "",
          country: "US",
          district: String(formValues?.billing?.billingTo?.state || ""),
          email: String(accountDetails?.email || "fallback@avagate.com"),
          firstName: String(formValues.billing?.billingTo?.firstName || ""),
          lastName: String(formValues.billing?.billingTo?.lastName || ""),
          locality: String(formValues.billing?.billingTo?.city || ""),
          phoneNumber: String(accountDetails?.phone.cell || ""),
          postalCode: String(formValues.billing?.billingTo?.zipcode || ""),
        },
        amountDetails: {
          totalAmount: String(totalCost),
          currency: "USD",
        },
        creditCardBrandedName: payment.formValues?.card?.cardName || "",
      },
    }
    const payload: OperationExecutePayload = {
      membershipEvent: membershipEvent,
      paymentEvent: paymentPayload,
      operation: Operation.UPDATE,
    }

    return this.executeService.execute<Mzp.MemberAssociateAddPayResponseObject>(payload).pipe(
      map(({ paymentObject, operationObject, validateObject }) => {
        const paymentError = !!paymentObject?.meta?.isError
        if (paymentError) {
          checkCybersourcePaymentValidation(paymentObject)
        }

        if (operationObject?.error) {
          throw new RequestError(RequestErrorType.MembershipAddAssociateError, operationObject)
        }

        if (validateObject?.response?.mzpResponse) {
          const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
            currency: "USD",
            transaction_id: getTransactionId(paymentObject),
            value: totalCost,
            items: [
              { quantity: 1, price: totalCost, item_id: "primary", item_name: AppAnalyticsEvents.AccountAddAssociates },
            ],
            context: "ava-store " + AppAnalyticsEvents.AccountAddAssociates,
          }
          this.dataLayer.purchaseEvent(analyticsEventParams)

          return new Mzp.AccountInfo(validateObject?.response?.mzpResponse)
        } else {
          throw new RequestError(RequestErrorType.MembershipAddAssociateError, validateObject)
        }
      }),
    )
  }

  recostValidation(formValues: Partial<FormGroupValue<MembershipAssociateAddForm>>, memberNumber: string) {
    const payload: MembershipMzpGetMembershipCostsChange = {
      memberNumber,
      flow: "ASSOCIATE",
      method: MembershipMzpMethod.GET_MEMBERSHIP_COSTS_CHANGE,
      customers: (formValues.associates || []).map((associate) => {
        return {
          medical: !!associate.accidentMedicalPlan,
          email: associate.email,
          suffix: associate.suffix,
          lastName: associate.lastName,
          firstName: associate.firstName,
        }
      }),
    }

    return this.executeService.membershipQuery<Mzp.MembershipAddAssociateRecostValidationResponseObject>(payload).pipe(
      map(({ error, response }) => {
        const membershipError = !!error

        if (membershipError) {
          throw new RequestError(RequestErrorType.MembershipAddAssociateError, error)
        }

        return {
          executionData: response.executionData,
          response: response.validationData,
        }
      }),
    )
  }
}
