<ng-container *ngIf="componentState$ | async as c">
  <ng-container *ngIf="c.image?.filePath">
    <a
      *ngIf="c.image.targetPath"
      [href]="c.image.targetPath"
      (click)="imageClick($event)"
    >
      <img
        [src]="getFileUrl(c.image.filePath) | nzSanitizer: 'url'"
        [alt]="c.image.altText || 'image'"
        style="width: 100%; cursor: pointer"
        [style.border-radius]="style.borderRadius"
      >
    </a>
    <img
      *ngIf="!c.image.targetPath"
      [src]="getFileUrl(c.image.filePath) | nzSanitizer: 'url'"
      [alt]="c.image.altText || 'image'"
      style="width: 100%"
      [style.border-radius]="style.borderRadius"
    >
  </ng-container>
</ng-container>
