import { Store } from "@ngrx/store"
import { delay, filter, first, interval, of, switchMap, withLatestFrom } from "rxjs"
import { inject } from "@angular/core"
import { map, tap } from "rxjs/operators"
import { AppStore } from "@aaa/emember/store-types"
import { getClubId, isMembershipInitialized, MembershipActions } from "@aaa/emember/store-membership"
import { CookiesService } from "../share/services/cookies.service"
import { AvaDialogService } from "../share/services/ava-dialog.service"
import { ClubApp } from "@aaa/emember/types"
import { ZipcodeFormComponent } from "./components/zipcode-form/zipcode-form.component"
import { isSessionInitialized, SessionActions } from "@aaa/emember/store-session"
import { environment } from "../../../environments/environment"
import { GoogleTagManagerService } from "../share/services/google-tag-manager.service"
import { LocationRefService } from "../share/services/location-ref.service"

export function membershipInitializing() {
  const dialog = inject(AvaDialogService)
  const store = inject(Store<AppStore>)
  const location = inject(LocationRefService)
  const cookies = inject(CookiesService)
  const googleTagManager = inject(GoogleTagManagerService)
  const updateZipcodeFromCookie = (clubId: ClubApp) =>
    of(true).pipe(
      map(() => cookies.getZipInfo()),
      filter((info) => !!info.zipcode && info.clubId === clubId),
      tap(({ zipcode }) => store.dispatch(MembershipActions.setZipcode({ zipcode }))),
      delay(10),
      map(() => true),
    )
  const validZipcode = (zipcode: string) => !!zipcode
  const membershipInitializeSucceeded = () => {
    store.dispatch(MembershipActions.initialize({ env: environment }))

    return store.select(isMembershipInitialized).pipe(first((status) => status))
  }
  const sessionInitializeSucceeded = () => {
    store.dispatch(SessionActions.initialize())

    return store.select(isSessionInitialized).pipe(first((status) => status))
  }

  return () =>
    of(true).pipe(
      // Add tag manager
      tap(() => googleTagManager.init(environment.googleTagManager)),
      // Wait until membership initialized
      switchMap(() => membershipInitializeSucceeded()),
      // Wait until session initialized
      switchMap(() => sessionInitializeSucceeded()),
      //
      switchMap((payload) =>
        of(payload).pipe(withLatestFrom(store.select(getClubId), location.getSearchParam("rclub", ["rClub", "RCLUB"]))),
      ),
      map(([, clubId, rClub]) => ({ clubId, rClub })),
      switchMap(({ clubId, rClub }) => {
        // Update cookies and state with zipcode from query params
        const zipInfo = cookies.getZipInfo()
        // stateZipcode   |   cookieZipcode
        // --------------------------------
        // +					          -			updateCookie
        // +					          +			updateState
        // -					          +			updateState
        // -					          -			popup

        // set state zipcode from cookies
        if (validZipcode(zipInfo.zipcode) && zipInfo.clubId === clubId) {
          console.log("updateState ", zipInfo.zipcode)
          return of(zipInfo.zipcode).pipe(
            tap((zipcode) => store.dispatch(MembershipActions.setZipcode({ zipcode }))),
            delay(10),
            map(() => true),
          )
        }

        // check additional rClub parameter
        if (!validZipcode(zipInfo.zipcode) && rClub === clubId) {
          return of(true).pipe(
            tap(() => cookies.setZipCode(environment.zipcode, rClub)),
            tap(() => store.dispatch(MembershipActions.setZipcode({ zipcode: environment.zipcode }))),
            delay(10),
          )
        }

        // # Rollback changes WSSV3-1143
        if ([ClubApp.Northway].includes(clubId)) {
          console.log("show popup for Northway")
          const modal = dialog.openComponent({
            nzContent: ZipcodeFormComponent,
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzClassName: "ava-dialog-small",
          })

          return interval(500).pipe(
            switchMap(() => updateZipcodeFromCookie(clubId)),
            first(),
            tap(() => modal.close()),
          )
        }

        console.log("wait for cookies zipcode")
        return interval(500).pipe(
          switchMap(() => updateZipcodeFromCookie(clubId)),
          first(),
        )
      }),
    )
}
