import { Component, Inject, Input } from "@angular/core"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { WINDOW } from "@ng-web-apis/common"
import { tap } from "rxjs/operators"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../../services/state"
import { HOOSIER_RX_STATE, HoosierState } from "../../hoosier.service"
import { BreakpointWidth } from "../../../../../services/style"
import { Membership } from "../../services/member-info"
import { connectSuiteMembershipCodes } from "@aaa/interface-joinRenew-membership-membershipConnectSuite"

interface ConfirmedMember {
  expirationDate: string
  fullName: string
  membershipNumber: string
  membershipLevel: string
  membershipUserId: string
}

@Component({
  selector: "ava-confirmation-join-view",
  templateUrl: "./view.html",
  providers: [RxState, RxEffects],
})
export class ConfirmationJoinViewComponent {
  @Input() stateId: string | undefined
  @Input() rowId: string | undefined
  globalState$ = this.globalState.select()

  members: ConfirmedMember[] = []
  membership: Membership | undefined
  flexDirection = "row"

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects
  ) {
    rxEffects.register(this.members$)
    // rxEffects.register(this.mockMembers$)
    rxEffects.register(this.flexDirection$)
  }

  flexDirection$ = this.globalState.select("pageWidth")
    .pipe(
      tap(pageWidth => {
        let flexDirection = "row"
        if (pageWidth <= BreakpointWidth.MEDIUM) {
          flexDirection = "column"
        }
        this.flexDirection = flexDirection
      })
    )

/*
  mockMembers$ = this.globalState.select("adminMode")
    .pipe(
      tap(adminMode => {
        let members: ConfirmedMember[] = []
        if (adminMode) {
          const membership = JOINCONFIRMATION_MOCK.response.response.membership
          // const memberLookupMembership = this.hoosierState.get("MEMBER_LOOKUP").response.memberInfo.membership
          // if (memberLookupMembership) {
          //   members = this.getMembersFromMembershipResponse(memberLookupMembership as JoinMembershipResponse)
          // }
          // if (!memberLookupMembership) {
            members = this.getMembersFromMembershipResponse(membership as Membership)
          // }
        }
        if (!adminMode && this.membership) {
          members = this.getMembersFromMembershipResponse(this.membership as Membership)
        }
        this.members = members
      })
    )
*/

  members$ = this.hoosierState.select("MEMBERSHIP")
    .pipe(
      tap((MEMBERSHIP) => {
        const membership = MEMBERSHIP?.response.response?.membership
        if (membership) {
          this.window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
          this.membership = membership
          this.members = this.getMembersFromMembershipResponse(membership)
        }
      })
    )

  getMembersFromMembershipResponse(membership: Membership): ConfirmedMember[] {
    const membershipNumberFormatted = (membershipNumber: string) => [
      ...membershipNumber.slice(0, 3), " ",
      ...membershipNumber.slice(3, 6), " ",
      ...membershipNumber.slice(6, 15), " ",
      ...membershipNumber.slice(15),
    ].join("")

    const expirationDateFormatted = (date: string) => [
      ...date.slice(4, 6), "/",
      ...date.slice(6, 8), "/",
      ...date.slice(0, 4),
    ].join("")

    const members: ConfirmedMember[] = []
    const primary = membership.primaryMember
    members.push({
      expirationDate: expirationDateFormatted(membership.attributes.expDate),
      membershipLevel: connectSuiteMembershipCodes.find(code => code.membershipType === primary.attributes.membershipType)?.label || "",
      membershipNumber: membershipNumberFormatted(primary.attributes.membershipNumber),
      membershipUserId: primary.attributes.email || "",
      fullName: primary.attributes.firstName + " " + primary.attributes.lastName + " " + primary.attributes.nameSuffix
    })
    if (membership.associateMember?.length) {
      /**
       * iterate over list of associates from response
       * pull email and name values from membershipPayload
       */
      for (const associate of membership.associateMember) {
        members.push({
          expirationDate: expirationDateFormatted(membership.attributes.expDate),
          membershipLevel: connectSuiteMembershipCodes.find(code => code.membershipType === associate.attributes.membershipType)?.label || "",
          membershipNumber: membershipNumberFormatted(associate.attributes.membershipNumber),
          membershipUserId: associate.attributes.email || "none",
          fullName: associate.attributes.firstName + " " + associate.attributes.lastName + " " + associate.attributes.nameSuffix
        })
      }
    }
    return members
  }

}
