import { compress, decompress } from "lz-string"
import { environment } from "../../../environments/environment"

// Todo: need to move into environment
const isTesting = environment.emulator || environment.ngServe

export function storageCompress(message: string) {
  // check if parsed message
  return isTesting ? message : compress(message)
}

export function storageDecompress(message: string) {
  return isTesting ? message : decompress(message)
}
