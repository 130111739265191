import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { AccountInfoSummary, PriceItem } from "./summary.model"
import { MembershipOfferSummary } from "../price-offers/helpers/types"

export const SummaryActions = createActionGroup({
  source: "Summary",
  events: {
    Set: props<{ totalCost?: number; accounts?: AccountInfoSummary[]; priceItems?: PriceItem[] }>(),
    "Set Offers": props<{ offers: MembershipOfferSummary }>(),
    "Set Total Cost": props<{ totalCost: number }>(),
    Reset: emptyProps(),
  },
})
