import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms"

declare type FormTypeIsAny<T, Y, N> = 0 extends 1 & T ? Y : N
export declare type FormTypedOrUntyped<T, Typed, Untyped> = FormTypeIsAny<T, Untyped, Typed>
export declare type FormValue<T extends AbstractControl | undefined> = T extends AbstractControl<any, any>
  ? T["value"]
  : never

export declare type FormGroupValue<
  T extends {
    [K in keyof T]?: AbstractControl<any>
  },
> = FormTypedOrUntyped<
  T,
  Partial<{
    [K in keyof T]: FormValue<T[K]>
  }>,
  {
    [key: string]: any
  }
>

export declare type FormArrayValue<T extends AbstractControl<any>> = FormTypedOrUntyped<T, Array<FormValue<T>>, any[]>

export function updateTreeValidity(control: AbstractControl) {
  if (control instanceof FormGroup) {
    const group = control as FormGroup

    for (const field in group.controls) {
      const c = group.controls[field]

      updateTreeValidity(c)
    }
  } else if (control instanceof FormArray) {
    const group = control as FormArray

    for (const field in group.controls) {
      const c = group.controls[field]

      updateTreeValidity(c)
    }
  }

  control.markAsDirty()
  control.markAsTouched()
  control.updateValueAndValidity({ onlySelf: true })
}

export enum FormMode {
  VIEW = "view",
  EDIT = "edit",
}

export type FormControlOption = {
  visible: boolean
  defaultValue: any
}

export type FormControlToOptions<T> = T extends FormControl
  ? FormControlOption
  : T extends FormGroup
  ? FormControlOptions<T["value"]>
  : T extends FormArray
  ? Array<FormControlOptions<T["value"]>>
  : T extends undefined
  ? FormControlOption
  : FormControlOption

export type FormControlOptions<T> = {
  [K in keyof T]: FormControlToOptions<T[K]>
}

export interface AllValidationErrors {
  controlName: string
  errorName: string
  errorValue: any
}

export function getFormValidationErrors(control: AbstractControl | FormGroup | FormArray | any) {
  const errors: AllValidationErrors[] = []

  if (control instanceof FormGroup) {
    Object.keys(control.controls).forEach((controlName) => {
      const ctrl = control.controls[controlName]

      if (!(ctrl instanceof FormControl)) {
        errors.push(...getFormValidationErrors(ctrl))
      } else if (ctrl.errors) {
        Object.keys(ctrl.errors).forEach((errorName) => {
          errors.push({
            controlName,
            errorName,
            errorValue: (ctrl.errors && ctrl.errors[errorName]) || "",
          })
        })
      }
    })
  }

  if (control instanceof FormArray) {
    Object.keys(control.controls).forEach((_, index) => {
      const formGroup = control.controls[index]

      errors.push(...getFormValidationErrors(formGroup))
    })
  }

  return errors
}
