<div #container>
  <div
    *ngIf="membershipCard"
    style="position: relative"
    [style.cursor]="printable ? 'pointer' : undefined"
    (click)="printElem()"
  >
    <img [src]="membershipCard.filePath" alt="membership card">
    <div
      style="position: absolute; top: 50%; left: 10px; display: flex; flex-direction: column"
      [style.color]="membershipCard.textColor"
    >
      <div>
        {{membershipCardData.membershipLabel}}
        Membership
      </div>
      <div>
        {{membershipCardData.membershipNumber}}
      </div>
      <div>
        {{membershipCardData.fullName}}
      </div>
      <div>
        Expires:
        {{membershipCardData.expirationDate}}
      </div>
    </div>
  </div>

</div>
