import { Inject, Injectable } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { tap } from "rxjs"
import firebase from "firebase/compat/app"
import "firebase/auth"
import { GLOBAL_RX_STATE, GlobalState } from "./state"
import { RxState } from "@rx-angular/state"
import { WebEnvironment } from "../interfaces/window"
import { RxEffects } from "@rx-angular/state/effects"
import { LinkService } from "./link"
import IdTokenResult = firebase.auth.IdTokenResult
import { Store } from "@ngrx/store"
import { AppStore } from "@aaa/emember/store-types"

export interface IdTokenResultWithClaims extends IdTokenResult {
  claims: IdTokenResultClaims
}

export interface IdTokenResultClaims {
  [key: string]: any
  memberNumber: string
  roles: string[]
}

@Injectable({
  providedIn: "root"
})
export class JwtAuthService {
  signedIn: boolean = false

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private store: Store<AppStore>,
    private rxEffects: RxEffects,
    private afAuth: AngularFireAuth,
    private linkService: LinkService,
  ) {
    /**
     * "claims.roles" is the only data being used from globalState.afAuthIdTokenResult
     * "claims.token" may be used soon for server-side user validation
     * "claims.token" os a client token
     * "windowMetaData.user.token" is a server token, and is used for user login, then we get the client token from idTokenResult.claims
     * "claims.roles" and "claims.memberNumber" are used in this.idTokenResult$ side effect
     */
    rxEffects.register(this.idTokenResult$)
  }

  idTokenResult$ = this.afAuth.idTokenResult
    .pipe(
      tap((idTokenResult: firebase.auth.IdTokenResult | null) => {
        this.globalState.set("afAuthIdTokenResult", () => idTokenResult)

        // console.log(this.globalState.get("windowMetaData", "user", "token"))
        // console.log(idTokenResult)

        if (!idTokenResult && !this.signedIn) {
          this.signInUser()
        }

        if (idTokenResult) {
          this.signedIn = true

          const webEnv = this.globalState.get("windowMetaData", "webEnv")?.toUpperCase()
          const environment = this.globalState.get("environment")
          if (!environment.emulator && !environment.ngServe && webEnv !== WebEnvironment.APP_TEST) {
            /**
             * Log out the current firebase user if their roles or member number
             * are different from the data from our windowMetaData object.
             */
            const drupalUser = this.globalState.get("windowMetaData", "user")
            const tokenNumber = idTokenResult.claims.memberNumber || ""
            const metaNumber = drupalUser?.memberNumber || ""

            const array1: string[] = idTokenResult.claims.roles
            const array2: string[] = drupalUser?.roles

            let arraysAreEqual = false
            if (array1 && array1.length === array2?.length) {
              const sortedArray1 = array1.sort()
              const sortedArray2 = array2.sort()
              if (sortedArray1.every((value: string, index: number) => value === sortedArray2[index])) {
                arraysAreEqual = true
              }
            }

            if (tokenNumber === metaNumber && arraysAreEqual) {
              //
            }

            if (tokenNumber !== metaNumber || !arraysAreEqual) {
              if (tokenNumber !== metaNumber) {
                console.log(idTokenResult)
                console.log(tokenNumber)
                console.log(metaNumber)
              }
              if (!arraysAreEqual) {
                console.log(array1)
                console.log(array2)
              }
              this.signOutUser()
            }
          }
          /**
           * Do nothing if in emulator mode.
           * User already signed in with popup login and has an idTokenResult.
           */
        }

      })
    )

  private signOutUser(): void {
    console.log("signOutUser()")
    firebase.auth()
      .signOut()
      .then(() => {
        this.linkService.reloadPage() // to login with new jwtToken
        this.signedIn = false
      })
      .catch(() => {
        // An error happened.
      })
  }

  private signInUser() {
    if (!this.signedIn) {

      if (this.globalState.get("environment")?.emulator
        || this.globalState.get("environment")?.ngServe
        || this.globalState.get("windowMetaData", "webEnv")?.toUpperCase() === WebEnvironment.APP_TEST
      ) {
        const provider = new firebase.auth.GoogleAuthProvider()
        this.afAuth
          .signInWithPopup(provider)
          .then(() => {
            // console.log("signed in with popup")
            this.signedIn = true
          })
          .catch(() => {
            // An error happened.
          })
      }

      if (!this.globalState.get("environment").emulator
        && !this.globalState.get("environment").ngServe
        && this.globalState.get("windowMetaData", "webEnv").toUpperCase() !== WebEnvironment.APP_TEST
        && this.globalState.get("windowMetaData", "user", "token")
      ) {
        this.afAuth
          .signInWithCustomToken(this.globalState.get("windowMetaData", "user", "token"))
          .then((userCredential) => {
            console.log("signed in with token")
            this.signedIn = true
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

}
