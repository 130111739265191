import { Injectable } from "@angular/core"
import { of } from "rxjs"
import { map } from "rxjs/operators"

// This interface is optional, showing how you can add strong typings for custom globals.
// Just use "Window" as the type if you don't have custom global stuff
export interface ICustomLocation extends Location {}

function getLocation(): any {
  return location
}

@Injectable({ providedIn: "root" })
export class LocationRefService {
  get nativeLocation(): ICustomLocation {
    return getLocation() as ICustomLocation
  }

  getSearchParam(paramName: string, derivations: string[] = []) {
    return of(true).pipe(
      map(() => {
        const searchParams = new URLSearchParams(this.nativeLocation.search)
        const param =
          searchParams.get(paramName) ||
          derivations.reduce((acc, name) => {
            if (searchParams.get(name)) {
              return searchParams.get(name) as string
            }

            return acc
          }, "") ||
          ""

        return param
      }),
    )
  }
}
