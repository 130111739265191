<div
  *ngIf="formGroup"
  [formGroup]="formGroup"
>
  <quill-editor
    #quillEditorComponent
    theme="bubble"
    formControlName="value"
    bounds="self"
    [customOptions]="customOptions"
    [modules]="modules"
    style="margin: 0; padding: 0; font-family: proxima_nova, sans-serif; display: block"
    [style.color]="styles.color"
    [style.font-size.px]="styles.fontSize?.px"
    [style.font-weight]="styles.fontWeight"
    [style.text-align]="styles.textAlign"
    [style.line-height]="styles.lineHeight || 1.235"
    [placeholder]="options.label.placeholder"
    (onEditorCreated)="onEditorCreated()"
  ></quill-editor>
</div>
