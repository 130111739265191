<ng-container *ngIf="globalState$ | async as g">
  <div
    *ngIf="comparisonChart && g.screenSize === ScreenSize.DESKTOP"
    style="display: flex; flex-direction: column"
  >
    <div
      *ngFor="let _ of rowsLengthArray; let rowsIndex = index; let rowsOdd = odd;"
      style="display: flex; gap: 5px"
    >
      <div
        *ngFor="let _ of columnsLengthArray; let columnsIndex = index"
        style="flex: 1; position: relative; padding: 20px; display: flex; gap: 10px; flex-direction: column; justify-content: center;"
        [style.align-items]="columnsIndex === 0 ? 'start' : 'center'"
        [style.background-color]="rowsOdd ? '#f5f5f5' : undefined"
      >
        <div
          *ngIf="comparisonChart.rows[rowsIndex].columns[columnsIndex].heading"
          [style.font-size.px]="rowsIndex === 0 && columnsIndex === 0 ? 32 : rowsIndex === 0 ? 24 : 18"
          [style.font-weight]="rowsIndex === 0 ? 300 : 400"
          [style.color]="rowsIndex !== 0 && columnsIndex === 0 ? '#25538f' : undefined"
        >
          {{ comparisonChart.rows[rowsIndex].columns[columnsIndex].heading }}
        </div>

        <div
          *ngIf="comparisonChart.rows[rowsIndex].columns[columnsIndex].textArea.value"
          style="width: 100%"
          [style.text-align]="rowsIndex !== 0 && columnsIndex !== 0 ? 'center' : undefined"
        >
          <ava-quill-view
            [fieldPath]="['rows', rowsIndex.toString(), 'columns', columnsIndex.toString(), 'textArea']"
            [stateId]="stateId"
            [rowId]="rowId"
            [options]="textAreaOptions"
            [stylesFunction]="textAreaStylesFunction"
          ></ava-quill-view>
        </div>

        <div
          *ngIf="comparisonChart.rows[rowsIndex].columns[columnsIndex].priceChartCard?.description.cta"
          style="width: 100%"
        >
          <ava-price-chart-card-view
            [fieldPath]="['rows', rowsIndex.toString(), 'columns', columnsIndex.toString(), 'priceChartCard']"
            [stateId]="stateId"
            [rowId]="rowId"
            [typeCTA]="true"
          ></ava-price-chart-card-view>
        </div>

        <div
          *ngIf="comparisonChart.rows[rowsIndex].columns[columnsIndex].info"
          nz-tooltip="true"
          nzTooltipColor="#25538F"
          [nzTooltipTrigger]="g.adminMode && !g.previewMode ? null : 'hover'"
          [nzTooltipTitle]="comparisonChart.rows[rowsIndex].columns[columnsIndex].info"
          style="position: absolute; top: 0; right: 0; padding: 10px; display: grid; place-content: center"
        >
          <i nz-icon nzType="info-circle" nzTheme="fill" style="color: #25538F"></i>
        </div>

      </div>
    </div>

    <div
      *ngIf="rowsLengthArray.length !== comparisonChart.rows.length || g.adminMode"
      style="padding: 16px; background-color: #E3E8EF; display: grid; place-content: center; cursor: pointer;"
      (click)="showAllRows()"
    >
      <div style="color: #25538f; display: flex; gap: 10px; align-items: center; user-select: none">
        <div style="font-size: 24px; font-weight: 200">
          {{ comparisonChart.footerText }}
        </div>
        <div>
          <i nz-icon nzType="icomoon:chevron-down"></i>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="comparisonChart && g.screenSize !== ScreenSize.DESKTOP"
    style="display: flex; flex-direction: column"
  >
    <ng-container *ngFor="let _ of rowsLengthArray; let rowsIndex = index; let rowsOdd = odd; let rowsLast = last">
      <nz-affix [nzOffsetTop]="g.adminUser ? 57 : 0">
        <div *ngIf="rowsIndex === 0" style="display: flex">
          <ng-container *ngFor="let _ of columnsLengthArray; let columnsIndex = index">
            <div
              *ngIf="columnsIndex !== 0 && comparisonChart.rows[rowsIndex].columns[columnsIndex].priceChartCard?.description.cta"
              style="flex: 1"
            >
              <ava-price-chart-card-view
                [fieldPath]="['rows', rowsIndex.toString(), 'columns', columnsIndex.toString(), 'priceChartCard']"
                [stateId]="stateId"
                [rowId]="rowId"
                [typeCTA]="true"
              ></ava-price-chart-card-view>

            </div>
          </ng-container>
        </div>
      </nz-affix>

      <ng-container *ngIf="rowsIndex !== 0">

        <div
          *ngIf="comparisonChart.rows[rowsIndex].columns[0].heading"
          style="display: grid; place-content: center; position: relative; width: 100%"
        >
          <div
            style="font-size: 24px; color: #25538f; text-align: center"
          >
            {{ comparisonChart.rows[rowsIndex].columns[0].heading }}
          </div>
          <div
            *ngIf="comparisonChart.rows[rowsIndex].columns[0].info"
            nz-tooltip="true"
            nzTooltipColor="#25538F"
            [nzTooltipTrigger]="g.adminMode && !g.previewMode ? null : 'hover'"
            [nzTooltipTitle]="comparisonChart.rows[rowsIndex].columns[0].info"
            style="position: absolute; top: 0; right: 0; padding: 10px; display: grid; place-content: center"
          >
            <i nz-icon nzType="info-circle" nzTheme="fill" style="color: #25538F"></i>
          </div>
        </div>

        <div
          *ngIf="columnsLengthArray.length"
          style="display: flex; gap: 5px"
        >
          <ng-container *ngFor="let _ of columnsLengthArray; let columnsIndex = index">
            <ng-container *ngIf="columnsIndex !== 0">
              <div
                style="flex: 1; position: relative; padding: 5px; display: flex; gap: 10px; flex-direction: column; justify-content: center;"
                [style.align-items]="'center'"
              >
                <div
                  *ngIf="rowsIndex !== 0 && columnsIndex !== 0 && comparisonChart.rows[rowsIndex].columns[columnsIndex].textArea.value"
                  style="width: 100%"
                  [style.text-align]="rowsIndex !== 0 && columnsIndex !== 0 ? 'center' : undefined"
                >
                  <ava-quill-view
                    [fieldPath]="['rows', rowsIndex.toString(), 'columns', columnsIndex.toString(), 'textArea']"
                    [stateId]="stateId"
                    [rowId]="rowId"
                    [options]="textAreaOptions"
                    [stylesFunction]="textAreaStylesFunction"
                  ></ava-quill-view>
                </div>

                <div
                  *ngIf="comparisonChart.rows[rowsIndex].columns[columnsIndex].priceChartCard?.description.cta"
                  style="width: 100%"
                >
                  <ava-price-chart-card-view
                    [fieldPath]="['rows', rowsIndex.toString(), 'columns', columnsIndex.toString(), 'priceChartCard']"
                    [stateId]="stateId"
                    [rowId]="rowId"
                    [typeCTA]="true"
                  ></ava-price-chart-card-view>
                </div>

                <div
                  *ngIf="comparisonChart.rows[rowsIndex].columns[columnsIndex].info"
                  nz-tooltip="true"
                  nzTooltipColor="#25538F"
                  [nzTooltipTrigger]="g.adminMode && !g.previewMode ? null : 'hover'"
                  [nzTooltipTitle]="comparisonChart.rows[rowsIndex].columns[columnsIndex].info"
                  style="position: absolute; top: 0; right: 0; padding: 10px; display: grid; place-content: center"
                >
                  <i nz-icon nzType="info-circle" nzTheme="fill" style="color: #25538F"></i>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <nz-divider *ngIf="!rowsLast"></nz-divider>
      </ng-container>
    </ng-container>

    <div
      *ngIf="rowsLengthArray.length !== comparisonChart.rows.length || g.adminMode"
      style="padding: 16px; background-color: #E3E8EF; display: grid; place-content: center; cursor: pointer;"
      (click)="showAllRows()"
    >
      <div style="color: #25538f; display: flex; gap: 10px; align-items: center; user-select: none">
        <div style="font-size: 24px; font-weight: 200">
          {{ comparisonChart.footerText }}
        </div>
        <div>
          <i nz-icon nzType="icomoon:chevron-down"></i>
        </div>
      </div>
    </div>
  </div>
</ng-container>
