import { Inject, Injectable } from "@angular/core"
import {
  Action,
  AngularFirestore,
  DocumentChangeAction,
  DocumentData,
  DocumentSnapshot
} from "@angular/fire/compat/firestore"
import { LOCAL_STORAGE, LOCATION } from "@ng-web-apis/common"
import { WindowRefService } from "../modules/share/services/window-ref.service"
import { combineLatestWith, debounceTime, Observable } from "rxjs"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { map, take, tap } from "rxjs/operators"
import { Block } from "../block/services/block"
import { GLOBAL_RX_STATE, GlobalState } from "./state"
import { TemplateType } from "../options/template/service"
import { FieldType } from "./field"
import { TargetType } from "./link"
import { Title } from "@angular/platform-browser"
import { compress as lzStringCompress, decompress as lzStringDecompress } from "lz-string"
import { Button } from "../fields/button/service"
import { ColorType } from "./color"
import { ThemeType } from "../options/theme/service"
import { AlignmentType } from "../options/alignment/service"

@Injectable({
  providedIn: "root"
})
export class DataReadService {
  multiblockRef = this.afs
    .collection("wss-aaa-web")
    .doc(this.window.nativeWindow.metaData.clubId)
    .collection("data")
    .doc("multiblock")
  blockPath = "data"
  editRevisionPath = "revisions"
  blockRef = this.multiblockRef
    .collection(this.blockPath)
  revisionRef = this.multiblockRef
    .collection(this.editRevisionPath)
  templateBlocksArray = this.multiblockRef
    .collection(this.blockPath, ref => {
      return ref
        .where("blockTemplate", "==", true)
    })
    .snapshotChanges()
    .pipe(
      map(documents => this.pipeDataTransform(documents))
    )
  oldBlockRef = this.afs
    .collection("wss-aaa-web")
    .doc(this.window.nativeWindow.metaData.clubId)
    .collection("multiblock")
  allOldBlocksArray = this.oldBlockRef
    .valueChanges({ idField: "id" }) as Observable<Block[]>


  constructor(
    private window: WindowRefService,
    @Inject(LOCAL_STORAGE)
    private localStorage: Storage,
    @Inject(LOCATION)
    private location: Location,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rxEffects: RxEffects,
    private afs: AngularFirestore,
    private title: Title,
  ) {
    globalState.set({
      blocks: {},
      blocksArray: [],
      sharedBlocks: {},
      sharedBlocksArray: [],
      templateBlocks: {},
      templateBlocksArray: [],
    })
    rxEffects.register(this.connectPublishedBlocksAndSharedBlocks$)
    rxEffects.register(this.connectTemplateBlocks$)
    // rxEffects.register(this.dataCache$)
    rxEffects.register(this.blocks$)
    rxEffects.register(this.sharedBlocks$)
    rxEffects.register(this.templateBlocks$)
  }

  dataCache$ = this.globalState.select("pageBlock")
    .pipe(
      combineLatestWith(
        this.globalState.select("blocksArray"),
        this.globalState.select("sharedBlocksArray"),
        this.globalState.select("templateBlocksArray"),
      ),
      debounceTime(3000),
      tap(([pageBlock, blocksArray, sharedBlocksArray, templateBlocksArray]: [Block, Block[], Block[], Block[]]) => {
        if (Object.keys(pageBlock).length) {
          this.title.setTitle(pageBlock.pageTitle + " | " + this.globalState.get("site", "name"))
        }

        const localStorageData = this.localStorage.getItem("data")
        /*
                if (localStorageData) {
                  const storedData = JSON.parse(lzString.decompress(localStorageData))
                  const data = {
                    pageBlock: [
                      { [pageBlock.pathName]: pageBlock }, ...storedData.pageBlock
                    ],
                    blocksArray: [
                      ...blocksArray, ...storedData.blocksArray
                    ],
                    sharedBlocksArray: [
                      ...sharedBlocksArray, ...storedData.sharedBlocksArray
                    ],
                    templateBlocksArray: [
                      ...templateBlocksArray, ...storedData.templateBlocksArray
                    ]
                  }
                  this.localStorage.setItem("data", lzString.compress(JSON.stringify(data)))
                  console.log(JSON.stringify(data).length)
                  console.log(lzString.compress(JSON.stringify(data)).length)
                }
        */
        // if (!localStorageData) {
        const data = {
          pageBlock: {
            [pageBlock.pathName]: pageBlock
          },
          blocksArray: [
            ...blocksArray
          ],
          sharedBlocksArray: [
            ...sharedBlocksArray
          ],
          templateBlocksArray: [
            ...templateBlocksArray
          ]
        }
        // console.log("dataStorage")
        this.localStorage.setItem("data", lzStringCompress(JSON.stringify(data)))

        // }

        /**
         * early warning about large data size
         * convert bytes into MB and round to 1 decimal place
         * browser localStorage limit might be 5 MB
         */
        const byteSize = new Blob([lzStringCompress(JSON.stringify(data))]).size
        const mbSize = Math.ceil(byteSize / 100000) / 10
        // console.warn(byteSize.toLocaleString("en-US"))
        if (mbSize > 1) {
          console.warn(byteSize.toLocaleString("en-US"))
          console.warn("cache data size approaching " + mbSize + " MB, max-limit is 5 MB")
        }
      })
    )

  connectTemplateBlocks$ = this.globalState.select("loadCreatePanel")
    .pipe(
      take(1),
      tap(() => {
        this.globalState.connect("templateBlocksArray", this.templateBlocksArray)
      })
    )

  blocks$ = this.globalState.select("blocksArray")
    .pipe(
      tap(blocksArray => {
        if (blocksArray.length) {
          const object: { [key: string]: Block } = {}
          if (blocksArray) {
            for (const block of blocksArray) {
              object[block.id] = block
            }
          }
          this.globalState.set("blocks", () => object)
        }
      })
    )

  templateBlocks$ = this.globalState.select("templateBlocksArray")
    .pipe(
      tap(array => {
        if (array.length) {
          const object: { [key: string]: Block } = {}
          if (array) {
            for (const block of array) {
              object[block.id] = block
            }
          }
          this.globalState.set("templateBlocks", () => object)
        }
      })
    )

  sharedBlocks$ = this.globalState.select("sharedBlocksArray")
    .pipe(
      tap(array => {
        if (array.length) {
          const object: { [key: string]: Block } = {}
          if (array) {
            for (const block of array) {
              object[block.id] = block
            }
          }
          this.globalState.set("sharedBlocks", () => object)
        }
      })
    )

  connectPublishedBlocksAndSharedBlocks$ = this.globalState.select("location", "pathname")
    .pipe(
      tap(pathname => {
        /**
         * initialize with cached data if it is present
         */
        const localStorageData = this.localStorage.getItem("data")
        if (localStorageData) {
          const data = this.parseJSONObject(lzStringDecompress(localStorageData)) as any
          if (data) {
            // this.globalState.set("pageBlock", () => data.pageBlock[pathname])
            this.globalState.set("sharedBlocksArray", () => data.sharedBlocksArray)
            this.globalState.set("templateBlocksArray", () => data.templateBlocksArray)
            this.globalState.set("blocksArray", () => data.blocksArray)
          }
        }
        // pathname = pathname
        //   .replace("homepage", "")
        //   .replace(/\/$/g, "") // remove trailing "/"
        this.globalState.connect("allOldBlocksArray", this.allOldBlocksArray)
        this.globalState.connect("pageBlock", this.pageBlock(pathname))
        this.globalState.connect("sharedBlocksArray", this.sharedBlocksArray(pathname))
        this.globalState.connect("blocksArray", this.blocksArray(pathname)
          .pipe(
            tap((blocksArray: Block[]) => {
              const blockTemplateTypes = {} as { [key: string]: boolean }
              for (const block of blocksArray) {
                if (block.template.type) {
                  blockTemplateTypes[block.template.type] = true
                }
              }
              this.globalState.set("blockTemplateTypesLoaded", () => blockTemplateTypes)
            })
          )
        )
        // this.globalState.connect("templateBlocksArray", this.getTemplateBlocks())

        /**
         * convert blocksArray into a keyed blocks object [blockId: block]
         */
        /*
                this.globalState.connect("blocks", this.globalState.select("blocksArray"),
                  (globalState, blocksArray) => {
                    const blocksObject: { [key: string]: Block } = {}
                    if (blocksArray) {
                      for (const block of blocksArray) {
                        blocksObject[block.id] = block
                      }
                    }
                    return blocksObject
                  }
                )
                this.globalState.connect("templateBlocks", this.globalState.select("templateBlocksArray"),
                  (globalState, templateBlocksArray) => {
                    const blocksObject: { [key: string]: Block } = {}
                    if (templateBlocksArray) {
                      for (const block of templateBlocksArray) {
                        blocksObject[block.id] = block
                      }
                    }
                    return blocksObject
                  }
                )
                this.globalState.connect("sharedBlocks", this.globalState.select("sharedBlocksArray"),
                  (globalState, sharedBlocksArray) => {
                    const blocksObject: { [key: string]: Block } = {}
                    if (sharedBlocksArray) {
                      for (const block of sharedBlocksArray) {
                        blocksObject[block.id] = block
                      }
                    }
                    return blocksObject
                  }
                )
        */
      })
    )

  parseJSONObject(jsonString: string): object | false {
    try {
      const o = JSON.parse(jsonString)
      if (o && typeof o === "object") {
        return o
      }
    } catch (e) {
      console.log()
    }
    return false
  }

  get createId(): string {
    return this.afs.createId()
  }


  editRevisionFromDb$(blockId: string): Observable<Action<DocumentSnapshot<DocumentData>>> {
    return this.revisionRef
      .doc(blockId)
      .snapshotChanges()
  }

  sharedBlocksArray(pathname: string): Observable<Block[]> {
    return this.multiblockRef
      .collection(this.blockPath, ref => {
        return ref
          .where("pathNames", "array-contains-any", [pathname])
          .where("blockShared", "==", true)
      })
      .snapshotChanges()
      .pipe(
        map(documents => {
          return this.pipeDataTransform(documents)
        })
      )
  }

  pageBlock(pathname: string): Observable<Block> {
    return this.multiblockRef
      .collection(this.blockPath, ref => {
        return ref
          .where("pathName", "==", pathname)
          .where("blockType", "==", "container")
          .where("containerType", "==", "page")
          .where("blockShared", "==", false)
          .where("blockTemplate", "==", false)
      })
      .snapshotChanges()
      .pipe(
        map(documents => {
          const blocksArray = this.pipeDataTransform(documents)
          if (blocksArray.length > 1) {
            console.error("multiple page blocks: " + blocksArray.length)
            for (const block of blocksArray) {
              console.error(block)
            }
          }
          // console.log(blocksArray[0])
          /**
           * clear pageBlock if we don't get any matches from database
           */
          if (!blocksArray.length) {
            this.globalState.set("pageBlock", () => ({} as Block))
          }
          return blocksArray[0] // return just the first result. If there is more than one then we may need to fix something.
        })
      )
  }

  blocksArray(pathname: string): Observable<Block[]> {
    return this.multiblockRef
      .collection(this.blockPath, ref => {
        return ref
          .where("pathName", "==", pathname)
          .where("blockType", "==", "multiblock")
          .where("blockShared", "==", false)
          .where("blockTemplate", "==", false)
      })
      .snapshotChanges()
      .pipe(
        map(documents => {
          return this.pipeDataTransform(documents)
        })
      )
  }

  private pipeDataTransform(documents: (DocumentChangeAction<DocumentData>)[]): Block[] {
    return documents.map(document => {
      const data = document.payload.doc.data()
      data.id = document.payload.doc.id
      // delete data["id"]

      // console.log(data.id)
      // console.log(JSON.parse(JSON.stringify(data)))

      const l = []

      /**
       * new options 5/2023
       */
      if (data.options && data.options.themeToggle === undefined) {
        delete data.options.themeToggle
      }
      if (data.options && data.options.themeEnabled === undefined) {
        data.options.themeEnabled = true
      }
      if (data.options && data.options.alignmentEnabled === undefined) {
        data.options.alignmentEnabled = true
      }
      if (data.options && data.options.backgroundColorToggle === undefined) {
        delete data.options.backgroundColorToggle
      }
      if (data.options?.alignment) {
        switch (data.options.alignment) {
          case "center":
            data.options.alignment = AlignmentType.CENTER
            break
          case "left":
            data.options.alignment = AlignmentType.LEFT
            break
          case "right":
            data.options.alignment = AlignmentType.RIGHT
            break
        }
      }

      /**
       * Drupal generated blocks will omit multiblock managed fields.
       * We need to create those fields here, if they don't already exist.
       * These can be removed when we no longer import live data from Drupal
       */
      if (data.blockType === "office") {
        data["fields"].images = data["fields"].images || []
        l.push("office image to images[]")
      }

      /**
       * status.created and status.revised
       */
      /*
            data.status.created = {
              seconds: data.status.created.seconds,
              nanoseconds: data.status.created.nanoseconds
            }
            data.status.revised = {
              seconds: data.status.revised.seconds,
              nanoseconds: data.status.revised.nanoseconds
            }
      */


      /**
       * begin data migration scripts
       * these can be removed when all content is known to have been updated
       */

      // convert colors
      Object.keys(data.rows).map(rowId => {
        const row = data.rows[rowId]
        if (row.fieldType === FieldType.BUTTON) {
          const buttonField = row as Button
          switch (buttonField.color as string) {
            case "00":
            case "10":
            case "20":
            case "30":
            case "40":
            case "41":
              buttonField.color = ("C_" + buttonField.color) as ColorType
              break
            case "darkgray":
              buttonField.color = ColorType.C_11
              break
            case "gray":
              buttonField.color = ColorType.C_12
              break
          }
        }
      })

      // console.log(data)


      // convert theme

      switch (data.options?.theme) {
        case "opt1":
          data.options.theme = ThemeType.OPT_01
          break
        case "opt2":
          data.options.theme = ThemeType.OPT_02
          break
        case "opt3":
          data.options.theme = ThemeType.OPT_03
          break
      }


      //

      if (data.blockType === "multiblock" && !data.template) {
        l.push("add multiblock template")
        data.template = {
          category: "",
          description: "",
          icon: "",
          name: ""
        }
      }

      data.blockTemplate = data.blockTemplate || false
      data.blockShared = data.blockShared || false
      data.pathNames = data.pathNames || []
      if (data.parents !== undefined) {
        l.push("delete data.parents")
        delete data.parents
      }

      if (data.pathNames?.length) {
        l.push("pathNames[] to pathNames as string")
        data.pathNames = data.pathNames.filter((pathName: null) => pathName !== null)
      }

      if (data.blockType !== "multiblock" && data.blockType !== "container") {
        if (data.columns) {
          l.push("remove columns")
          delete data.columns
        }
        if (data.rows) {
          l.push("remove rows")
          delete data.rows
        }
      }

      if (data.columns === undefined) {
        l.push("create columns")
        data.columns = []
      }

      /**
       * convert fields to rows
       * convert column.fieldRefs to column.rows
       */
      if (data.blockType === "multiblock" || data.blockType === "container") {

        if (data.childBlockIds) {
          l.push("move childBlockIds into columns")
          data.columns.push({ rows: data.childBlockIds })
          delete data.childBlockIds
        }

        if (data.fields) {
          l.push("move fields into rows")
          data.rows = data.fields
          delete data.fields
        }
        if (data.columns) {
          for (const [columnIndex, column] of data.columns.entries()) {
            if (column.width === undefined) {
              l.push("columns width object")
              data.columns[columnIndex].width = {
                percent: null,
                px: 50,
                type: "percent"
              }
            }
            if (column.width.type === undefined) {
              l.push("columns width.type")
              data.columns[columnIndex].width.type = "percent"
            }
            if (column.width.px === undefined) {
              l.push("columns width.px")
              data.columns[columnIndex].width.px = 50
            }
            if (column.fieldRefs) {
              l.push("column.fieldRefs to columns rows")
              data.columns[columnIndex].rows = column.fieldRefs
              delete data.columns[columnIndex].fieldRefs
            }
          }
        }

        /*
                if (data.columns && !data.rows) {
                  if (!data.rows) {
                    l.push("data.rows object")
                    data.rows = {}
                  }
                  const newColumns = []
                  for (const column of data.columns) {
                    const newColumn = { accordion: false, rows: [] }
                    for (const rowId of column.rows) {
                      if (data.rows[rowId] !== undefined) {
                        l.push("add rowId")
                        newColumn.rows.push(rowId)
                      }
                      if (data.rows[rowId] === undefined) {
                        l.push("create data.rows as blockRef from columns rowId")
                        const newRowId = this.afs.createId()
                        newColumn.rows.push(newRowId)
                        data.rows[newRowId] = {
                          blockId: rowId,
                          // label: "Row",
                          type: FieldType.BLOCK_REFERENCE
                        }
                      }
                    }
                    newColumns.push(newColumn)
                  }
                  l.push("replace data.columns")
                  data.columns = newColumns
                }
        */

        if (data.options && data.options.breakpointsEnabled === undefined) {
          l.push("breakpointsEnabled")
          data.options.breakpointsEnabled = true
        }

        if (data.rows) {
          for (const rowId in data.rows) {
            if (data.rows[rowId].label === undefined) {
              // l.push(".")
              // data.rows[rowId].label = "Row"
            }
          }
        }

        if (data.columns) {
          for (const [columnIndex, column] of data.columns.entries()) {
            if (column.label === undefined) {
              // l.push(".")
              // data.columns[columnIndex].label = "Column"
            }
          }
        }

      }

      if (data.blockType === "multiblock") {
        if (!data.template.type) {
          l.push("template.type")
          data.template.type = null
        }

        if (data.options.columnGap === true || data.options.columnGap === false) {
          l.push("options.columnGap")
          data.options.columnGap = {
            px: 0
          }
        }
        if (data.options.alignment === undefined) {
          l.push("options.alignment")
          data.options.alignment = "left"
        }


      }


      // console.log(data)


      /*
            if (data.rows) {
              for (const rowId in data.rows) {
                if (data.rows[rowId]["blockRef"] !== undefined) {
                  data.rows[rowId].blockId = data.rows[rowId]["blockRef"]
                  delete data.rows[rowId]["blockRef"]
                }
              }
            }
      */

      // if (data.pathNames[0] === "/columns") {
      //   data.pathNames[1] = "/column"
      // }

      // data.columns[0] = data.columns[0] || { accordion: false, rows: [] }

      /*
            if (!data.blockShared) {
              data.instances[0] = data.instances[0] || {
                id: this.afs.createId(),
                pathName: data.pathName
              }
            }
            if (data.blockShared) {
              for (const pathNameIndex of data.pathNames) {
                data.instances[pathNameIndex] = data.instances[pathNameIndex] || {
                  id: this.afs.createId(),
                  pathName: ""
                }
              }
            }
      */

      switch (data.template?.type) {
        case "none":
          l.push("data.template?.type")
          data.template.type = TemplateType.NONE
          break
        case "banner":
          l.push("data.template?.type")
          data.template.type = TemplateType.BANNER
          break
        case "bannerLarge":
          l.push("data.template?.type")
          data.template.type = TemplateType.BANNER_LARGE
          break
        case "blockRef":
          l.push("data.template?.type")
          data.template.type = TemplateType.BLOCK_REFERENCE
          break
        case "callToAction":
          l.push("data.template?.type")
          data.template.type = TemplateType.CALL_TO_ACTION
          break
        case "card":
          l.push("data.template?.type")
          data.template.type = TemplateType.CARD
          break
        case "column":
          l.push("data.template?.type")
          data.template.type = TemplateType.COLUMN
          break
        case "feature":
          l.push("data.template?.type")
          data.template.type = TemplateType.FEATURE
          break
        case "featureWithLogiform":
          l.push("data.template?.type")
          data.template.type = TemplateType.FEATURE_WITH_LOGIFORM
          break
        case "featureDestination":
          l.push("data.template?.type")
          data.template.type = TemplateType.FEATURE_DESTINATION
          break
        case "featureMagazineArticlesReference":
          l.push("data.template?.type")
          data.template.type = TemplateType.FEATURE_MAGAZINE_ARTICLES_REFERENCE
          break
        case "fourColumnProduct":
          l.push("data.template?.type")
          data.template.type = TemplateType.FOUR_COLUMN_PRODUCT
          break
        case "formColumnWithImage":
          l.push("data.template?.type")
          data.template.type = TemplateType.FOUR_COLUMN_WITH_IMAGE
          break
        case "gallery":
          l.push("data.template?.type")
          data.template.type = TemplateType.GALLERY
          break
        case "iconCard":
          l.push("data.template?.type")
          data.template.type = TemplateType.ICON_CARD
          break
        case "imageStack":
          l.push("data.template?.type")
          data.template.type = TemplateType.IMAGE_STACK
          break
        case "joinRenew":
          l.push("data.template?.type")
          data.template.type = TemplateType.JOIN
          break
        case "logiform":
          l.push("data.template?.type")
          data.template.type = TemplateType.LOGIFORM
          break
        case "multiWidget":
          l.push("data.template?.type")
          data.template.type = TemplateType.MULTI_WIDGET
          break
        case "multiWidgetTabs":
          l.push("data.template?.type")
          data.template.type = TemplateType.MULTI_WIDGET_TABS
          break
        case "oneColumnText":
          l.push("data.template?.type")
          data.template.type = TemplateType.ONE_COLUMN_TEXT
          break
        case "oneColumnTextWithButton":
          l.push("data.template?.type")
          data.template.type = TemplateType.ONE_COLUMN_TEXT_WITH_BUTTON
          break
        case "promotionOrOffer":
          l.push("data.template?.type")
          data.template.type = TemplateType.PROMOTION_OR_OFFER
          break
        case "threeColumnListWithIcons":
          l.push("data.template?.type")
          data.template.type = TemplateType.THREE_COLUMN_LIST_WITH_ICONS
          break
        case "title":
          l.push("data.template?.type")
          data.template.type = TemplateType.TITLE
          break
        case "twoOrThreeColumnText":
          l.push("data.template?.type")
          data.template.type = TemplateType.TWO_OR_THREE_COLUMN_TEXT
          break
      }

      const rowIds = Object.keys(data.rows)
      for (const rowId of rowIds) {
        if (data.rows[rowId].label !== undefined) {
          l.push("delete rows label")
          delete data.rows[rowId].label
        }
        if (data.rows[rowId].type !== undefined) {
          l.push("delete rows type")
          data.rows[rowId].fieldType = data.rows[rowId].type
          delete data.rows[rowId].type
        }
        switch (data.rows[rowId].targetOption) {
          case "self":
            l.push("targetOption")
            data.rows[rowId].targetOption = TargetType.SELF
            break
          case "modal":
            l.push("targetOption")
            data.rows[rowId].targetOption = TargetType.MODAL
            break
          case "new":
            l.push("targetOption")
            data.rows[rowId].targetOption = TargetType.NEW
            break
          case "phone":
            l.push("targetOption PHONE")
            data.rows[rowId].targetOption = TargetType.PHONE
            break
        }
        switch (data.rows[rowId].fieldType) {
          case "button":
            l.push("fieldType BUTTON")
            data.rows[rowId].fieldType = FieldType.BUTTON
            break
          case "blockRef":
            l.push("fieldType BLOCK_REFERENCE")
            data.rows[rowId].fieldType = FieldType.BLOCK_REFERENCE
            break
          case "image":
            l.push("fieldType IMAGE")
            data.rows[rowId].fieldType = FieldType.IMAGE
            break
          case "javascript":
            l.push("fieldType JAVASCRIPT")
            data.rows[rowId].fieldType = FieldType.JAVASCRIPT
            break
          case "video":
            l.push("fieldType VIDEO")
            data.rows[rowId].fieldType = FieldType.VIDEO
            break
          case "quillEditor":
            l.push("fieldType QUILL_EDITOR")
            data.rows[rowId].fieldType = FieldType.QUILL_EDITOR
            break
          case "joinPriceMemberQuantity":
            l.push("fieldType PRICE_CHART_MEMBER_QUANTITY")
            data.rows[rowId].fieldType = FieldType.PRICE_CHART_MEMBER_QUANTITY
            break
        }
      }

      /**
       * end of data migration scripts
       * finalize tne data object
       */

      // console.log(data["options"])

      /**
       * set "status.session" key to local session
       */
      data.status.session = this.globalState.get("userSession", "sessionId")

      /**
       * output logMessages if they exist
       */
      if (l.length) {
        console.log(l)
      }

      /**
       * save id and data to blocks array
       */
      return data as Block
    })
  }

  legacyHandleRouteAndAnalytics(): void {

    // const environment = this.globalState.get("environment")
    /*
        if (true) { // (environment.emulator || environment.ngServe || this.metaData.webEnv === WebEnvironment.APP_TEST) {
          /!**
           * whenever a route change occurs, rebuild the contentCollection with page docs for the new route.
           *!/
          /!*
                router.events
                  .pipe(
                    filter(event => event instanceof NavigationEnd)
                  )
                  .subscribe((event: NavigationEnd) => {
                    // const pathName = event.urlAfterRedirects
                    // console.log(event.url)
                    // globalState.set("pathName", () => event.url.split("?")[0]) // TODO: I think this is not required now, using location.pathname
                  })
          *!/
        } else {

          /!**
           * This is a firebase analytics event, not the Drupal configured analytics event.
           *
           * Disabled the following event because we turned on global pageView logging (in custom-element.module.ts).
           * Drupal reloads every page anyways, and we should log page_views to represent actual app loads.
           *
           * Will want to add "screen_view" events separately, for example:
           *   with router event changes in appMode
           *   for the agent-scheduler steps
           *   battery quoter results and confirmation
           *   etc, etc
           *!/
          /!*
                const analyticsPageViewEvent: AnalyticsPageViewEvent = {
                  eventName: "page_view",
                  eventParams: {
                    page_title: document.title,
                    page_location: window.location.href,
                    page_path: this.pathName,
                  }
                }
                this.analyticsService.sendFirebasePageViewEvent(analyticsPageViewEvent)
          *!/
        }
    */

  }
}


/**
 Pb7eNZGMFoT5ewBJn2sPBzkJBomn8i4KKDhXG1tVL5c=
 */
