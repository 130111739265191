import { Component, HostBinding, Input, ViewEncapsulation } from "@angular/core"
import { NgIf } from "@angular/common"
import { FieldH3Props } from "../types"

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[ava-render-h3]",
  template: `
    <ng-container *ngIf="props">
      <ng-container *ngIf="props.textBold; else default">
        <strong>{{ props?.text }}</strong>
      </ng-container>
      <ng-template #default>
        <span>{{ props?.text }}</span>
      </ng-template>
    </ng-container>
  `,
  standalone: true,
  imports: [NgIf],
  encapsulation: ViewEncapsulation.None,
})
export class RenderH3Component {
  @Input() props?: FieldH3Props
  @HostBinding("class") get getClass() {
    return this.props?.cls || ""
  }
}
