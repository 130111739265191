import { Inject, Injectable } from "@angular/core"
import firebase from "firebase/compat/app"
import "firebase/firestore"
import { combineLatestWith, debounceTime, filter, Observable, of, takeWhile, tap } from "rxjs"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { WINDOW } from "@ng-web-apis/common"
import { JoinRenewService, OpStatus } from "../../join-renew.service"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../services/state"
import {
  AccountMembershipView,
  AccountSection,
  CheckoutStep,
  Flow,
  HOOSIER_RX_STATE,
  HoosierService,
  HoosierState,
  Section,
  SessionDocResponse,
  SessionDocResponseObject,
  View,
} from "../hoosier.service"
import { FormArray, FormGroup } from "@angular/forms"
import { PricePreviewsService } from "./price-previews"
import { FixMemberNamesService } from "../services/fix-member-names"
import { Membership } from "../services/member-info"
import { AlertGroup, AlertMessage, AlertMessageService, AlertType } from "../../../../services/alert-message"
import { AnalyticsService } from "../../../../services/analytics"
import { MemberLookupResponse } from "./member-lookup"
import { FormService, FormStatus } from "../../../../services/form"
import { LinkService } from "../../../../services/link"
import { ValidationData } from "../services/validation-data"
import { NzNotificationService } from "ng-zorro-antd/notification"
import Timestamp = firebase.firestore.Timestamp
import {
  connectSuiteMembershipCodes,
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload,
  MembershipConnectSuiteRecostValidateChangeAssociatesResponse,
  MembershipConnectSuiteRecostValidateJoinEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import { EventName } from "@aaa/interface-joinRenew-joinRenewLib"

export interface ValidateAssociatesResponseObject extends SessionDocResponseObject {
  error: ValidateAssociatesError
  // meta: SessionDocMeta
  response: ValidateAssociatesResponse
}

export interface ValidateAssociatesResponse extends MembershipConnectSuiteRecostValidateChangeAssociatesResponse {
  validationData: ValidationData
  executionData: string
}

export interface ValidateAssociatesError {
  isAutoRenew: string
  responseText: string
  version: string
  totalCost: string
  balance: string
  responseCode: string
  promotionalCode: string
}

/*
export interface ValidationData {
  // attributes: {
  //   isAutoRenew: string
  //   balance: string
  //   responseText: string
  //   version: string
  //   totalCost: string
  //   responseCode: string
  // }
  // membership: ValidateMembership
}
*/

interface ValidateMembership extends Membership {
  // costSummary?: {
  //   autoRenewDiscount: string
  //   discountAmount: string
  //   solicitationDiscount: string
  //   solicitationRequiresAutoRenew: string
  //   totalCost: string
  // }
}

enum AssociatesStep {
  ASSOCIATES,

  ASSOCIATES_ADD,
  ASSOCIATES_ADD_VALIDATE,
  ASSOCIATES_ADD_PAYMENT,
  ASSOCIATES_ADD_PAYMENT_EXECUTE,
  ASSOCIATES_ADD_CONFIRMATION,

  ASSOCIATES_REMOVE,
  ASSOCIATES_REMOVE_VALIDATE,
  ASSOCIATES_REMOVE_EXECUTE,
  ASSOCIATES_REMOVE_CONFIRMATION,
}

/**
 * recostValidateRenew for:
 *   quickRenew (with memberNumber, lastName, and postalCode)
 *   myAccountRenew (with logged in member)
 */

@Injectable({
  providedIn: "root",
})
export class ValidateAssociatesService {
  form: FormGroup
  numberOfRetries: number = 0
  fallbackValidate = false

  constructor(
    private pricePreviewsService: PricePreviewsService,
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private joinRenewService: JoinRenewService,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
    private alertMessageService: AlertMessageService,
    private fixMemberNamesService: FixMemberNamesService,
    private analyticsService: AnalyticsService,
    private formService: FormService,
    private linkService: LinkService,
    private notification: NzNotificationService,
  ) {
    this.form = hoosierService.form
    rxEffects.register(this.MEMBER_LOOKUP_Associates$)
    rxEffects.register(this.associateCount$)
    rxEffects.register(this.recostValidateAssociates$)
    this.form.get("changeAssociatesPayload")?.enable() // initialize all renewPayload formControl.valueChanges observables
    rxEffects.register(this.VALIDATE_KEY$)
    rxEffects.register(this.VALIDATE$)
    rxEffects.register(this.retryOnError$)
    rxEffects.register(this.activeChange$)
  }

  get MEMBER_LOOKUP_Associates$(): Observable<any> {
    return this.hoosierState.select("MEMBER_LOOKUP").pipe(
      tap((MEMBER_LOOKUP) => {
        if (MEMBER_LOOKUP) {
          let associateCount = 0
          const memberLookupResponse: MemberLookupResponse = MEMBER_LOOKUP.response
          const associatesFormArray = this.form.get(["changeAssociatesPayload", "associates"]) as FormArray
          associatesFormArray.clear({ emitEvent: false })
          if (memberLookupResponse.memberInfo.membership.associateMember) {
            for (const associateMember of memberLookupResponse.memberInfo.membership.associateMember) {
              associatesFormArray.push(this.formService.newForm(associateMember.attributes), { emitEvent: false })
            }
            associateCount = memberLookupResponse.memberInfo.membership.associateMember.length
          }
          this.form.get("changeAssociatesPayload")?.patchValue(
            {
              associateCount: associateCount,
            },
            { emitEvent: false },
          )
        }
      }),
    )
  }

  get associateCount$(): Observable<any> {
    const associatesForm = this.form.get(["changeAssociatesPayload", "associates"])
    if (associatesForm) {
      return associatesForm.valueChanges.pipe(
        tap((associates: MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload["associates"]) => {
          if (associates) {
            this.form
              .get(["changeAssociatesPayload"])
              ?.patchValue({ associateCount: associates.length }, { emitEvent: false })
          }
        }),
      )
    }
    return of(null)
  }

  activeChange$ = this.hoosierState.select("activeChange").pipe(
    combineLatestWith(
      this.hoosierState.select("formStatus"),
      this.hoosierState.select("VALIDATE_ASSOCIATES_STATUS"),
      this.hoosierState.select("activeCheckoutStep"),
      this.hoosierState.select("activeDestination"),
    ),
    tap(
      ([activeChange, formStatus, VALIDATE_ASSOCIATES_STATUS, activeCheckoutStep, activeDestination]: [
        number | boolean | null,
        FormStatus,
        OpStatus,
        CheckoutStep | null,
        Section | View | CheckoutStep | null,
      ]) => {
        if (
          activeChange &&
          this.hoosierState.get("activeView") === AccountMembershipView.ASSOCIATES_ADD &&
          formStatus !== FormStatus.PENDING &&
          VALIDATE_ASSOCIATES_STATUS !== OpStatus.RUNNING &&
          VALIDATE_ASSOCIATES_STATUS !== OpStatus.IS_ERROR
        ) {
          let hasError: boolean
          switch (activeCheckoutStep) {
            case null:
              this.hoosierState.set("activeChange", () => null)
              hasError = this.associatesSectionHasError()
              if (hasError) {
                console.log(this.form)
              }
              if (!hasError) {
                this.hoosierState.set("activeCheckoutStep", () => activeDestination as CheckoutStep)
              }
              break
            case CheckoutStep.PAYMENT:
              this.hoosierState.set("activeChange", () => null)
              hasError = this.paymentSectionHasError()
              if (hasError) {
                console.log(this.form)
              }
              if (!hasError) {
                this.hoosierState.set("activeCheckoutStep", () => activeDestination as CheckoutStep)
              }
          }
        }
      },
    ),
  )

  get recostValidateAssociates$(): Observable<any> {
    const autoRenewForm = this.form.get(["changeAssociatesPayload", "autoRenew"])
    if (autoRenewForm) {
      return this.hoosierState.select("activeView").pipe(
        combineLatestWith(this.hoosierState.select("activeCheckoutStep"), autoRenewForm.valueChanges),
        debounceTime(100), // debounce is required, or else we get previous autoRenew value
        tap(([activeView, activeCheckoutStep, autoRenew]: [View | null, CheckoutStep | null, boolean]) => {
          const changeAssociatesPayload: MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload =
            this.form.get("changeAssociatesPayload")?.value
          const membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload = this.form
            .get("membershipPayload")
            ?.getRawValue()
          changeAssociatesPayload.promoCode = membershipPayload.membership.promoCode || ""
          switch (activeView) {
            case AccountMembershipView.ASSOCIATES:
            case AccountMembershipView.ASSOCIATES_ADD:
            case AccountMembershipView.ASSOCIATES_REMOVE:
              switch (activeCheckoutStep) {
                case CheckoutStep.VALIDATE:
                case CheckoutStep.PAYMENT:
                  this.recostValidateAssociates(changeAssociatesPayload)
              }
          }
        }),
      )
    }
    return of(null)
  }

  retryOnError$ = this.hoosierState.select("VALIDATE_ASSOCIATES_STATUS").pipe(
    takeWhile(() => this.numberOfRetries < 3),
    debounceTime(10000),
    tap((status) => {
      if (status === OpStatus.IS_ERROR) {
        this.alertMessageService.set(AlertMessage.ERROR_RETRY, AlertType.INFO)
        this.recostValidateAssociates(this.form.get("changeAssociatesPayload")?.value, true)
      }
    }),
  )

  VALIDATE_KEY$ = this.hoosierState.select("VALIDATE_ASSOCIATES_KEY").pipe(
    combineLatestWith(this.hoosierState.select("sessionDoc", "responses", "membership", "connectsuite")),
    tap(([VALIDATE_CHANGE_ASSOCIATES_KEY, connectsuite]: [string | null, SessionDocResponse]) => {
      if (VALIDATE_CHANGE_ASSOCIATES_KEY && connectsuite[VALIDATE_CHANGE_ASSOCIATES_KEY]) {
        this.hoosierState.set("VALIDATE_ASSOCIATES_KEY", () => null)

        const responseTime = Timestamp.now().toMillis() - parseInt(VALIDATE_CHANGE_ASSOCIATES_KEY)
        if (this.globalState.get("adminUser")) {
          console.log(responseTime, "milliseconds - VALIDATE_ASSOCIATES")
        }
        const validateResponseObject = connectsuite[VALIDATE_CHANGE_ASSOCIATES_KEY] as ValidateAssociatesResponseObject
        const isError = validateResponseObject.meta.isError

        if (!isError) {
          this.alertMessageService.clearAll(AlertGroup.ERROR)

          // this.hoosierState.set("activeSection", () => AccountSection.MEMBERSHIP)
          // this.hoosierState.set("activeView", () => AccountMembershipView.CHANGE_LEVEL_CONFIRMATION)

          this.hoosierState.set("VALIDATE_ASSOCIATES_STATUS", () => OpStatus.SUCCESS)
          this.hoosierState.set("VALIDATE_ASSOCIATES", () => validateResponseObject)
          this.hoosierState.set("VALIDATE_ASSOCIATES_ERROR", () => null)
          const promotionalCode = validateResponseObject.response.validationData.attributes.promotionalCode
          if (promotionalCode || !this.fallbackValidate) {
            this.hoosierState.set("PROMO_CODE_STATUS", () => OpStatus.SUCCESS)
          }
          this.fallbackValidate = false // reset after previous promotionalCode check
        }

        if (isError) {
          this.hoosierState.set("VALIDATE_ASSOCIATES_STATUS", () => OpStatus.IS_ERROR)
          this.hoosierState.set("VALIDATE_ASSOCIATES", () => null)
          this.hoosierState.set("VALIDATE_ASSOCIATES_ERROR", () => validateResponseObject)
          switch (validateResponseObject.error.responseCode) {
            case "012": {
              // invalid promoCode
              this.hoosierState.set("PROMO_CODE_STATUS", () => OpStatus.IS_ERROR)

              /**
               * set userSession.promoCode to false
               */
              this.globalState.set("userSession", (oldState) => {
                oldState.userSession.promoCode = false
                return oldState.userSession
              })

              /**
               * re-run recost without promoCode
               */
              this.fallbackValidate = true
              const changeAssociatesPayload: MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload =
                this.form.get("changeAssociatesPayload")?.value
              changeAssociatesPayload.promoCode = ""
              this.recostValidateAssociates(changeAssociatesPayload)

              break
            }
            default:
              this.alertMessageService.set(AlertMessage.ERROR_CRITICAL, AlertType.ERROR)
          }
        }
      }
    }),
  )

  VALIDATE$ = this.hoosierState.select("VALIDATE_ASSOCIATES").pipe(
    combineLatestWith(this.hoosierState.select("activeView"), this.hoosierState.select("activeCheckoutStep")),
    filter(([VALIDATE_ASSOCIATES]) => !!VALIDATE_ASSOCIATES),
    filter(([, , activeCheckoutStep]) => {
      const promoCodeStatus = this.hoosierState.get("PROMO_CODE_STATUS")
      return promoCodeStatus === OpStatus.SUCCESS || activeCheckoutStep === CheckoutStep.VALIDATE
    }),
    tap(
      ([VALIDATE_ASSOCIATES, activeView]: [
        ValidateAssociatesResponseObject | null,
        View | null,
        CheckoutStep | null,
      ]) => {
        const balance = Number(VALIDATE_ASSOCIATES?.response?.validationData?.attributes?.balance)
        const totalCost = Number(VALIDATE_ASSOCIATES?.response?.validationData?.attributes?.totalCost)
        const membershipType =
          VALIDATE_ASSOCIATES?.response?.validationData?.membership?.primaryMember?.attributes?.membershipType
        const membershipCode = connectSuiteMembershipCodes.find(
          (membershipCode) => membershipCode.membershipType === membershipType,
        )
        const membershipCodeLabel = membershipCode?.label
        switch (activeView) {
          case AccountMembershipView.ASSOCIATES_ADD:
            if (!isNaN(balance) && membershipCodeLabel) {
              this.form.get(["paymentPayload", "executionData", "amountDetails", "totalAmount"])?.setValue(balance)
              this.hoosierState.set("summary", (oldState) => {
                oldState.summary.balance = balance
                oldState.summary.totalCost = totalCost
                oldState.summary.membershipCodeLabel = membershipCodeLabel
                return oldState.summary
              })
              this.hoosierState.set("activeCheckoutStep", () => CheckoutStep.PAYMENT)
            }
            break
          case AccountMembershipView.ASSOCIATES_REMOVE:
            this.hoosierState.set("activeCheckoutStep", () => CheckoutStep.EXECUTE)
            break
        }
      },
    ),
  )

  setActiveView(view: AccountMembershipView): void {
    this.linkService.replaceUrlArgument(Flow.ACCOUNT, Flow.ACCOUNT + "/" + AccountSection.MEMBERSHIP + "/" + view)
  }

  recostValidateAssociates(
    associatesPayload: MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload,
    retry?: boolean,
  ): void {
    if (retry) {
      this.numberOfRetries++
    }
    if (!retry) {
      this.numberOfRetries = 0
    }
    const responseKeyTimestampString = Timestamp.now().toMillis().toString()
    this.hoosierState.set("VALIDATE_ASSOCIATES_KEY", () => responseKeyTimestampString)
    this.hoosierState.set("VALIDATE_ASSOCIATES_STATUS", () => OpStatus.RUNNING)

    associatesPayload.responseKey = responseKeyTimestampString
    associatesPayload.method = MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ASSOCIATES

    if (associatesPayload.associates) {
      for (const [index, associate] of associatesPayload.associates.entries()) {
        const associateDob = associate.dob
        if (associateDob?.length === 10) {
          associatesPayload.associates[index].dob =
            associateDob.slice(6, 10) + associateDob.slice(0, 2) + associateDob.slice(3, 5)
        }
      }
    }

    if (this.globalState.get("adminUser")) {
      // console.log(associatesPayload)
    }
    this.joinRenewService
      .sendToEventCoordinatorReceiver(EventName.MEMBERSHIP_QUERY, associatesPayload)
      .then((response) => {
        if (this.globalState.get("adminUser")) {
          console.log(
            EventName.MEMBERSHIP_QUERY +
              "---" +
              MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ASSOCIATES +
              "---" +
              responseKeyTimestampString +
              "---" +
              response,
          )
        }
      })
      .catch((error) => {
        this.hoosierState.set("VALIDATE_ASSOCIATES_STATUS", () => OpStatus.FAILED)
        // console.log(error)
      })
  }

  associatesSectionHasError(): boolean {
    let hasError = false
    if (!this.form.get(["changeAssociatesPayload", "associates"])?.valid) {
      this.notification.blank("", "Please complete Associates Information")
      hasError = true
    }
    return hasError
  }

  paymentSectionHasError() {
    let hasError = false

    if (!this.form.get(["creditCard", "expirationMonth"])?.value) {
      this.notification.blank("", "Please choose your Credit Card Expiration Month")
      hasError = true
    }
    if (!this.form.get(["creditCard", "expirationYear"])?.value) {
      this.notification.blank("", "Please choose your Credit Card Expiration Year")
      hasError = true
    }
    if (!this.form.get(["creditCard", "numberIsValid"])?.value) {
      this.notification.blank("", "Please enter a valid credit card number")
      hasError = true
    }
    if (!this.form.get(["creditCard", "securityCodeIsValid"])?.value) {
      this.notification.blank("", "Please enter 3-digit credit card verification code")
      hasError = true
    }
    if (
      !this.form.get("useMemberInfoForBilling")?.value &&
      !this.form.get(["paymentPayload", "executionData", "billTo"])?.valid
    ) {
      hasError = true
    }
    return hasError
  }
}
