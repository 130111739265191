import { Component, Input } from "@angular/core"
import { OfficeAgentsModule } from "../modules/office-agents/office-agents.module"

@Component({
  selector: "ava-office-agents-custom-element",
  template: `
    <ava-office-agents [officeId]="officeId" [params]="params"></ava-office-agents>
  `,
  standalone: true,
  imports: [
    OfficeAgentsModule
  ],
})
export class OfficeAgentsCustomElementComponent {
  @Input() officeId: string | undefined
  @Input() params: string | undefined
}
