<button
  class="ava-button"
  type="button"
  nz-button
  [ngClass]="type"
  [nzBlock]="block"
  [disabled]="disabled"
  [nzType]="type"
  [nzShape]="shape"
  [nzSize]="size"
  [nzLoading]="isLoading"
  (click)="!disabled && clickedBtn($event)"
>
  <ng-container *ngIf="leftIcon">
    <i [style.font-size]="iconSize + 'px'" nz-icon [nzTheme]="iconTheme" [hidden]="false" [nzType]="leftIcon"></i>
  </ng-container>
  <span class="ava-button-label">
    <ng-content></ng-content>
  </span>
</button>
