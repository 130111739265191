import { Inject, Injectable } from "@angular/core"
import firebase from "firebase/compat/app"
import "firebase/firestore"
import { RxState } from "@rx-angular/state"
import { CheckoutStep, HOOSIER_RX_STATE, HoosierState, JoinSection, Section, View } from "../hoosier.service"
import { OpStatus } from "../../join-renew.service"
import Timestamp = firebase.firestore.Timestamp

@Injectable({
  providedIn: "root",
})
export class TransitionService {

  constructor(
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
  ) {
  }

  changeStep(previousStep: Section | View | CheckoutStep | null, nextStep: Section | View | CheckoutStep | null, status: OpStatus): void {
    if (previousStep !== nextStep
      && status !== OpStatus.RUNNING
      && status !== OpStatus.IS_ERROR
    ) {
/*
      if (previousStep === JoinSection.MEMBER_INFO
        || previousStep === JoinSection.SUMMARY
      ) {
        nextStep = CheckoutStep.VALIDATE
      }
*/
      // console.log([previousStep, nextStep, status])
      this.hoosierState.set("activeDestination", () => nextStep)
      this.hoosierState.get("form").markAllAsTouched()
      this.hoosierState.set("showActiveGroupValidationErrors", () => true)
      this.hoosierState.set("showActiveGroupValidationErrors", () => false)
      this.hoosierState.set("activeChange", () => Timestamp.now().toMillis())
    }
  }


}
