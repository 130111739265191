import { Directive, ElementRef, Input, OnInit } from "@angular/core"
import { NgControl } from "@angular/forms"
import { RxEffects } from "@rx-angular/state/effects"
import { Observable, of, tap } from "rxjs"

@Directive({
  selector: "[avaPhoneFormat]"
})
export class PhoneFormatDirective implements OnInit {
  @Input() avaPhoneFormat: boolean | undefined
  previousLength: number = 0

  constructor(
    private rxEffects: RxEffects,
    private elementRef: ElementRef,
    private control: NgControl,
  ) {
  }

  get avaPhoneFormat$(): Observable<any> {
    return this.control.valueChanges
      ?.pipe(
        tap(rawString => {
          if (rawString.length >= this.previousLength) {
            const inputElement: HTMLInputElement = this.elementRef.nativeElement
            let string = ""
            const separator = "-"
            for (const character of rawString) {
              if (/^\d$/.test(character) || character === separator) {
                string += character
              }
            }
            string = string
              .replace(/^(\d{3})(\d{3})(\d{4})$/, "$1" + separator + "$2" + separator + "$3")
            string = string
              .replace(/^(\d{3})(\d{3}-\d{4})$/, "$1" + separator + "$2")
            if (string.length === 3) {
              string = string + separator
            }
            if (string.length === 7) {
              string = string + separator
            }
            if (string.length > 12) {
              string = string.substring(0, 12)
            }
            inputElement.value = string
            this.previousLength = string.length
          }
          if (rawString.length < this.previousLength) {
            this.previousLength = rawString.length
          }
        })
      ) || of(null)
  }

  ngOnInit(): void {
    if (this.avaPhoneFormat) {
      this.rxEffects.register(this.avaPhoneFormat$)
    }
  }

}
