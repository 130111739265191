import { FormArray, FormControl, FormGroup } from "@angular/forms"
import { AccountDetails } from "../types/types"
import { RequestStatus } from "../../../types/request-status"
import { FormGroupValue } from "../../modules/share/form.utils"
import { RequestError } from "../generic-errors"
import { MembershipAssociateForm } from "../../modules/share/membership-associate-form"
import { MembershipOfferSummary } from "../price-offers/helpers/types"
import { PaymentForm } from "@aaa/emember/share/payment-form"

export enum MembershipUpdateFormStep {
  UPDATE = "update",
  PAYMENT = "payment",
  CONFIRMATION = "confirmation",
}

export type MembershipUpdateForm = {
  firstName: FormControl<string>
  lastName: FormControl<string>
  accidentMedicalPlan: FormControl<boolean>
  associates: FormArray<FormGroup<MembershipAssociateForm>>
  promoCode: FormControl<string>
  membershipType: FormControl<string>
}

export type MembershipUpdateState = {
  formActiveStep: MembershipUpdateFormStep
  formValues: FormGroupValue<MembershipUpdateForm>
  validationRequestStatus: RequestStatus
  validationError: RequestError | null
  executionData: any
  executionRequestStatus: RequestStatus
  executionError: RequestError | null
  accountDetails: AccountDetails | null
  totalCost: number
}

export type MembershipRecostParams = {
  formValues: FormGroupValue<MembershipUpdateForm>
  offers: MembershipOfferSummary
  totalCost: number
  payment: { token: string; formValues: FormGroupValue<PaymentForm> }
}
