import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from "@angular/core"
import { FormGroup, ValidationErrors } from "@angular/forms"
import { RxState } from "@rx-angular/state"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../../services/state"
import { tap } from "rxjs/operators"
import { combineLatestWith, Observable, of } from "rxjs"
import { RxEffects } from "@rx-angular/state/effects"
import {
  AccountMembershipView, HOOSIER_RX_STATE, HoosierService, HoosierState, JoinView, QuickRenewView, View,
} from "../../hoosier.service"
import { CybersourceService } from "../../services/cybersource"
import { PaymentFormOptionsService } from "../../form-field-options/payment"
import { PaymentService } from "../../services/payment"
import {
  PaymentCaptureContextService
} from "../../events/payment-capture-context"

@Component({
  selector: "ava-hoosier-payment",
  templateUrl: "./payment.html",
})
export class PaymentComponent implements OnInit, AfterViewInit {
  @ViewChild("componentWidth") componentWidthElementRef: ElementRef | undefined
  globalState$ = this.globalState.select()
  hoosierState$ = this.hoosierState.select()
  form: FormGroup | undefined
  invalidFlexMicroForm: boolean = false
  options = this.paymentFormOptionsService.options
  flexDirection: "row" | "column" = "row"

  constructor(
    private paymentCaptureContextService: PaymentCaptureContextService, // required
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private hoosierService: HoosierService,
    private cybersourceService: CybersourceService,
    private paymentFormOptionsService: PaymentFormOptionsService,
    private paymentService: PaymentService,
  ) {
    this.setMonths()
    this.setYears()
  }

  get invalidFlexMicroForm$(): Observable<any | undefined> {
    const numberIsValidForm = this.form?.get(["creditCard", "numberIsValid"])
    const securityCodeIsValidForm = this.form?.get(["creditCard", "securityCodeIsValid"])
    if (numberIsValidForm && securityCodeIsValidForm) {
      return this.hoosierState.select("showActiveGroupValidationErrors")
        .pipe(
          combineLatestWith(
            numberIsValidForm.valueChanges,
            securityCodeIsValidForm.valueChanges,
            this.hoosierState.select("activeView"),
          ),
          tap(([showActiveGroupValidationErrors, creditCardNumberIsValid, creditCardSecurityCodeIsValid, activeView]
                 : [boolean, boolean, boolean, View | null]) => {
            if (showActiveGroupValidationErrors) {
              switch (activeView) {
                case AccountMembershipView.RENEW:
                case AccountMembershipView.AUTO_RENEW:
                case AccountMembershipView.AUTO_RENEW_CARD:
                case JoinView.PAYMENT:
                case QuickRenewView.SUMMARY_AND_PAYMENT:
                  this.invalidFlexMicroForm = !creditCardNumberIsValid || !creditCardSecurityCodeIsValid
                  if (!creditCardNumberIsValid) {
                    this.form?.get(["creditCard", "numberIsValid"])?.setErrors({ notValid: true })
                  }
                  if (!creditCardSecurityCodeIsValid) {
                    this.form?.get(["creditCard", "securityCodeIsValid"])?.setErrors({ notValid: true })
                  }
              }
            }
            if (creditCardNumberIsValid) {
              this.form?.get(["creditCard", "numberIsValid"])?.setErrors(null as ValidationErrors | null)
            }
            if (creditCardSecurityCodeIsValid) {
              this.form?.get(["creditCard", "securityCodeIsValid"])?.setErrors(null as ValidationErrors | null)
            }
            if (creditCardNumberIsValid && creditCardSecurityCodeIsValid) {
              this.invalidFlexMicroForm = false
            }
          }),
        )
    }
    return of(undefined)
  }

  ngOnInit(): void {
    this.form = this.hoosierService.form
    this.rxEffects.register(this.invalidFlexMicroForm$)
    // this.cybersourceService.getCaptureContext()
  }

  ngAfterViewInit(): void {
    this.form?.get(["creditCard", "numberIsValid"])?.enable()
    this.form?.get(["creditCard", "securityCodeIsValid"])?.enable()
    this.onResize()
  }

  @HostListener("window:resize")
  onResize(): void {
    const width = this.componentWidthElementRef?.nativeElement.getBoundingClientRect().width
    if (width <= 400) {
      this.flexDirection = "column"
    } else {
      this.flexDirection = "row"
    }
  }

  setMonths(): void {
    for (let index = 0; index <= 11; index++) {
      const month: number = index + 1
      const stringMonth: string = month.toString()
      const monthLabel: string = stringMonth.length === 1 ? "0" + stringMonth : stringMonth
      this.options.expirationMonth.selectOptions.push({ label: monthLabel, value: month })
    }
  }

  setYears(): void {
    const now = new Date()
    const expirationYear = now.getFullYear()
    for (let index = 0; index <= 20; index++) {
      const year: number = expirationYear + index
      this.options.expirationYear.selectOptions.push({ label: year.toString(), value: year })
    }
  }

  reloadFlexMicroFormFields(): void {
    this.paymentCaptureContextService.paymentCaptureContext()
    // this.cybersourceService.getCaptureContext()
  }
}
