import { FeatureName, Flag } from "./types"
import { AppEnv, ClubApp, Environment } from "@aaa/emember/types"

export const flagStates: { [key: string]: Flag } = {}

export function getFeatureFlagStateByClubId(env: Environment): Flag[] {
  const flags: Flag[] = []
  const clubId = env.clubId

  if (clubId === ClubApp.Hoosier) {
    flags.push(
      ...[
        { name: FeatureName.ACCOUNT_INFORMATION, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_HOME_ADDRESS, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_PHONE_NUMBER, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_EMAIL, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_PASSWORD, active: true },
        { name: FeatureName.ACCOUNT_INSURANCE, active: true },
        { name: FeatureName.ACCOUNT_TRAVEL, active: true },
        { name: FeatureName.ACCOUNT_FINANCIAL, active: true },
        { name: FeatureName.ACCOUNT_LOGOUT, active: true },
        { name: FeatureName.ACCOUNT_EMAIL_PREFERENCES, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_RENEW, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_CARD, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_AUTO_RENEW, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_CHANGE_PLAN, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_ASSOCIATE_ADD, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_ASSOCIATE_VIEW, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_CANCEL, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_GIFT, active: true },
        { name: FeatureName.MEMBERSHIP_ASSOCIATE_AUTO_RENEW, active: true },
        { name: FeatureName.MEMBERSHIP_ASSOCIATE_PROMO_CODE, active: true },
      ],
    )
  }

  if (clubId === ClubApp.MidStates) {
    flags.push(
      ...[
        { name: FeatureName.ACCOUNT_INFORMATION, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_HOME_ADDRESS, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_PHONE_NUMBER, active: true },
        // { name: FeatureName.ACCOUNT_INFORMATION_EMAIL, active: true },
        // { name: FeatureName.ACCOUNT_INFORMATION_PASSWORD, active: true },
        { name: FeatureName.ACCOUNT_INSURANCE, active: true },
        { name: FeatureName.ACCOUNT_TRAVEL, active: true },
        { name: FeatureName.ACCOUNT_FINANCIAL, active: true },
        { name: FeatureName.ACCOUNT_LOGOUT, active: true },
        { name: FeatureName.ACCOUNT_EMAIL_PREFERENCES, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_RENEW, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_CARD, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_AUTO_RENEW, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_CHANGE_PLAN, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_ASSOCIATE_ADD, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_ASSOCIATE_VIEW, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_CANCEL, active: true },
        // { name: FeatureName.ACCOUNT_MEMBERSHIP_GIFT, active: true },
        { name: FeatureName.MEMBERSHIP_ASSOCIATE_PROMO_CODE, active: true },
      ],
    )
  }

  if (clubId === ClubApp.Shelby || clubId === ClubApp.Northampton) {
    flags.push(
      ...[
        { name: FeatureName.ACCOUNT_INFORMATION, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_HOME_ADDRESS, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_PHONE_NUMBER, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_EMAIL, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_CARD, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_AUTO_RENEW, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_UPDATE, active: true },
        // { name: FeatureName.ACCOUNT_MEMBERSHIP_HOME_ADDRESS, active: true },
        // { name: FeatureName.ACCOUNT_MEMBERSHIP_PHONE_NUMBER, active: true },
        // { name: FeatureName.ACCOUNT_MEMBERSHIP_EMAIL, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_CANCEL, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_ASSOCIATE_VIEW, active: true },
        { name: FeatureName.ACCOUNT_LOGOUT, active: true },
      ],
    )
  }

  if (clubId === ClubApp.SouthJersey) {
    flags.push(
      ...[
        { name: FeatureName.ADDRESS_AUTOCOMPLETE, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_HOME_ADDRESS, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_PHONE_NUMBER, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_EMAIL, active: true },
        { name: FeatureName.ACCOUNT_INFORMATION_PASSWORD, active: true },
        { name: FeatureName.ACCOUNT_INSURANCE, active: true },
        { name: FeatureName.ACCOUNT_TRAVEL, active: true },
        { name: FeatureName.ACCOUNT_FINANCIAL, active: true },
        { name: FeatureName.ACCOUNT_LOGOUT, active: true },
        { name: FeatureName.ACCOUNT_EMAIL_PREFERENCES, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_RENEW, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_CARD, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_AUTO_RENEW, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_CHANGE_PLAN, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_ASSOCIATE_ADD, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_ASSOCIATE_VIEW, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_CANCEL, active: true },
        { name: FeatureName.ACCOUNT_MEMBERSHIP_GIFT, active: true },
        { name: FeatureName.MEMBERSHIP_ASSOCIATE_AUTO_RENEW, active: true },
        { name: FeatureName.MEMBERSHIP_ASSOCIATE_PROMO_CODE, active: true },
      ],
    )
  }

  if (env.appEnv === AppEnv.TEST) {
    flags.push({ name: FeatureName.QUICK_RENEW_PATCH_FORM_FROM_URL, active: true })
  }

  return flags
}
