import { Component, forwardRef, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzFormModule } from "ng-zorro-antd/form"
import { NzGridModule } from "ng-zorro-antd/grid"
import { NzInputModule } from "ng-zorro-antd/input"
import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from "@angular/forms"
import { NzSelectModule } from "ng-zorro-antd/select"
import { FormBaseControlComponent } from "../base"

@Component({
  selector: "ava-form-select",
  template: `
    <nz-form-item [ngClass]="{ 'ava-label-hide': !formLabel }">
      <nz-form-label class="ava-form-label" [nzFor]="controlId" [nzNoColon]="true" [nzSpan]="7">
        {{ formLabel || placeholder }}
      </nz-form-label>
      <nz-form-control [nzValidateStatus]="formControlStatus" [nzErrorTip]="errors" nzHasFeedback>
        <nz-select
          [formControl]="formControl"
          [nzPlaceHolder]="placeholder"
          [nzId]="controlId"
          [nzAllowClear]="allowClear"
          [nzShowSearch]="showSearch"
          class="ava-form-control"
        >
          <nz-option *ngFor="let option of options" [nzValue]="option[valueKey]" [nzLabel]="option[displayLabel]" />
        </nz-select>

        <ng-template #errors let-control>
          <ng-container *ngIf="formControl?.errors">
            <ng-container *ngFor="let error of formControl.errors | keyvalue">
              <span class="ava-form-error">
                {{ errorMessages && errorMessages[error.key] ? errorMessages[error.key] : error.key }}
              </span>
            </ng-container>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      @import "../../../../../styles/ava-responsive";

      :host {
        width: 100%;

        ::ng-deep nz-select-item {
          @include formControlInputFontStyle();
        }

        ::ng-deep .ant-select-selection-placeholder,
        ::ng-deep .ant-input-suffix {
          @include formControlPlaceholderStyle();
        }

        .ava-label-hide nz-form-label {
          width: 0;
          overflow: hidden;
          position: absolute;
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, NzFormModule, NzGridModule, NzInputModule, ReactiveFormsModule, NzSelectModule],
  standalone: true,
})
export class FormSelectComponent extends FormBaseControlComponent {
  @Input() displayLabel = "label"
  @Input() valueKey = "value"
  @Input() options: any[] = []
  @Input() allowClear = false
  @Input() showSearch = false
}
