import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { AccountDetails, ValidateSucceededResponseObject } from "../types/types"
import {
  MembershipRenewForm,
  MembershipRenewFormStep,
  MembershipRenewPayParams,
  MembershipRenewRecostParams,
} from "./membership-renew.models"
import { changedForm, setFormActiveStep } from "../../modules/share/ngrx-helpers"
import { RequestError } from "../generic-errors"

export const MembershipRenewActions = createActionGroup({
  source: "Membership Renew",
  events: {
    ...setFormActiveStep<MembershipRenewFormStep>(),
    ...changedForm<MembershipRenewForm>(),
    "Validate Form Step": props<{ validateStep: MembershipRenewFormStep; params: MembershipRenewPayParams }>(),
    "Validate Billing": emptyProps(),
    "Validate Billing Succeeded": emptyProps(),
    "Validate Billing Failed": emptyProps(),
    "Recost Validate": props<{ params: MembershipRenewRecostParams }>(),
    "Recost Validate Succeeded": props<ValidateSucceededResponseObject>(),
    "Recost Validate Failed": props<{ error: RequestError }>(),
    Pay: props<{ params: MembershipRenewPayParams }>(),
    "Pay Succeeded": props<{ accountDetails?: AccountDetails }>(),
    "Pay Failed": props<{ error: RequestError }>(),
    "Set Account Details": props<{ accountDetails: AccountDetails }>(),
    Reset: emptyProps(),
    "Remove Error": emptyProps(),
  },
})
