import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { AccountDetails } from "../types/types"
import { of, withLatestFrom } from "rxjs"
import { catchError, map, switchMap } from "rxjs/operators"
import { FormGroupValue } from "../../modules/share/form.utils"
import { getMembershipNumber } from "@aaa/emember/store-membership"
import { getPayment } from "@aaa/emember/store-payment"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { ExecuteService } from "../services/execute.service"
import { ClubApp } from "@aaa/emember/types"
import { Operation, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { getAccountDetails, getThreePointAuth } from "@aaa/emember/store-account"
import { RequestError, RequestErrorType } from "../generic-errors"
import { MembershipARActions } from "./membership-auto-renew.actions"
import { getMembershipAutoRenewFormValues } from "./membership-auto-renew.selectors"
import { MembershipAutoRenewForm } from "./membership-auto-renew.models"
import {
  Membership,
  MembershipMGetMemberInfoEventPayload,
  MembershipMMethod,
  MembershipMOperationExecuteChangeEventPayload,
  ThreePointAuth,
} from "@aaa/interface-joinRenew-membership-membershipM"
import { M } from "../m.type"
import { checkMembershipErrorsMSystem } from "../check-membership-errors-m-system"
import { checkCybersourcePaymentValidation } from "../check-cybersource-payment-validation"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import { checkOperationErrorsMSystem } from "../check-operation-errors-m-system"
import { getClearCacheSettings } from "../utils/get-cache-settings"
import { AnalyticsPurchaseEvent } from "../../../types/analytics-purchase-event"
import { DataLayerService } from "../../modules/share/services/data-layer.service"
import { getTransactionId } from "../utils/get-transaction-id"
import { AppAnalyticsEvents } from "../../../types/analytics-events"

@Injectable({ providedIn: "root" })
export class MembershipAutoRenewMEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Northampton, ClubApp.Shelby]))
  executeService = inject(ExecuteService)
  dataLayer = inject(DataLayerService)

  removeCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipARActions.removeCard),
      withLatestFrom(this.store.select(getMembershipNumber), this.store.select(getThreePointAuth)),
      switchMap(([, membershipNumber, threePointAuth]) => {
        return of(null).pipe(
          switchMap(() => this.getMemberInfo(membershipNumber, threePointAuth)),
          switchMap((executionData) => this.removeCard(executionData, threePointAuth)),
          map((accountDetails) => MembershipARActions.removeCardSucceeded({ accountDetails })),
          catchError((error) => of(MembershipARActions.removeCardFailed({ error }))),
        )
      }),
    ),
  )

  updateCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipARActions.updateCard),
      withLatestFrom(
        this.store.select(getMembershipNumber),
        this.store.select(getMembershipAutoRenewFormValues),
        this.store.select(getAccountDetails),
        this.store.select(getPayment),
        this.store.select(getThreePointAuth),
      ),
      switchMap(([, membershipNumber, formValues, accountDetails, payment, threePointAuth]) => {
        return of(null).pipe(
          switchMap(() => this.getMemberInfo(membershipNumber, threePointAuth)),
          switchMap((executionData) =>
            this.saveOrUpdateCard(membershipNumber, executionData, formValues, accountDetails, payment, threePointAuth),
          ),
          map((accountDetails) => MembershipARActions.updateCardSucceeded({ accountDetails })),
          catchError((error) => of(MembershipARActions.updateCardFailed({ error }))),
        )
      }),
    ),
  )

  addCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipARActions.addCard),
      withLatestFrom(
        this.store.select(getMembershipNumber),
        this.store.select(getMembershipAutoRenewFormValues),
        this.store.select(getAccountDetails),
        this.store.select(getPayment),
        this.store.select(getThreePointAuth),
      ),
      switchMap(([, membershipNumber, formValues, accountDetails, payment, threePointAuth]) => {
        return of(null).pipe(
          switchMap(() => this.getMemberInfo(membershipNumber, threePointAuth)),
          switchMap((executionData) =>
            this.saveOrUpdateCard(membershipNumber, executionData, formValues, accountDetails, payment, threePointAuth),
          ),
          map((accountDetails) => MembershipARActions.addCardSucceeded({ accountDetails })),
          catchError((error) => of(MembershipARActions.addCardFailed({ error }))),
        )
      }),
    ),
  )

  saveOrUpdateCard(
    membershipNumber: string,
    executionData: string,
    formValues: FormGroupValue<MembershipAutoRenewForm>,
    accountDetails: AccountDetails | null,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
    threePointAuth: ThreePointAuth,
  ) {
    const membershipEvent: MembershipMOperationExecuteChangeEventPayload = {
      operation: Operation.UPDATE,
      changePropsData: {
        autoRenew: true,
      },
      executionData,
      threePointAuth,
      method: MembershipMMethod.OPERATION_EXECUTE,
    }
    const paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(formValues.billing?.billingTo?.address1),
          address2: String(formValues.billing?.billingTo?.address2),
          administrativeArea: String(formValues.billing?.billingTo?.state),
          buildingNumber: "",
          country: "US",
          district: String(formValues.billing?.billingTo?.state),
          email: String(accountDetails?.email || "fallback@avagate.com"),
          firstName: formValues.billing?.billingTo?.firstName || "",
          lastName: formValues.billing?.billingTo?.lastName || "",
          locality: String(formValues.billing?.billingTo?.city),
          phoneNumber: String(accountDetails?.phone.cell),
          postalCode: String(accountDetails?.address.zip),
        },
        amountDetails: { totalAmount: "0", currency: "USD" },
        creditCardBrandedName: payment.formValues.card?.cardName || "",
      },
      operation: Operation.UPDATE,
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
    }
    const payload: OperationExecutePayload = {
      membershipEvent,
      paymentEvent,
      cacheSettings: getClearCacheSettings(membershipNumber),
      operation: Operation.UPDATE,
    }

    return this.executeService.execute<M.MemberInfoUpdateResponseObject, M.ExecutePaymentResponseObject>(payload).pipe(
      map(({ validateObject, paymentObject, operationObject }) => {
        const paymentError = !!paymentObject?.meta?.isError
        if (paymentError) {
          checkCybersourcePaymentValidation(paymentObject.error)
        }

        const membershipError = !validateObject || !!validateObject?.meta?.isError
        if (membershipError) {
          throw new RequestError(RequestErrorType.AutoRenewMembership, validateObject)
        }

        const operationError = !!operationObject?.meta?.isError
        if (operationError) {
          checkOperationErrorsMSystem(operationObject.error, operationObject)
        }

        const memberInfo = validateObject.response.response.Result.Membership[0] as Membership
        const accountDetails = new M.AccountInfo(memberInfo)
        const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
          currency: "USD",
          transaction_id: getTransactionId(paymentObject),
          value: 0,
          items: [{ quantity: 1, price: 0, item_id: "primary", item_name: AppAnalyticsEvents.AccountAutoRenewal }],
          context: "ava-store " + AppAnalyticsEvents.AccountAutoRenewal,
        }
        this.dataLayer.purchaseEvent(analyticsEventParams)

        // Small fix because API requires 1-2 second to update
        accountDetails.autoRenew = true

        return accountDetails
      }),
    )
  }

  removeCard(executionData: string, threePointAuth: ThreePointAuth) {
    const membershipEvent: MembershipMOperationExecuteChangeEventPayload = {
      operation: Operation.UPDATE,
      changePropsData: { autoRenew: false },
      executionData,
      threePointAuth,
      method: MembershipMMethod.OPERATION_EXECUTE,
    }
    const payload: OperationExecutePayload = {
      membershipEvent,
      operation: Operation.UPDATE,
    }

    return this.executeService.execute<M.MemberInfoUpdateResponseObject>(payload).pipe(
      map(({ validateObject, operationObject }) => {
        const membershipError = !validateObject || !!validateObject?.meta?.isError
        if (membershipError) {
          throw new RequestError(RequestErrorType.RemoveAutoRenewMembership, validateObject)
        }

        const operationError = !!operationObject?.meta?.isError
        if (operationError) {
          checkOperationErrorsMSystem(operationObject.error, operationObject)
        }

        const memberInfo = validateObject.response.response.Result.Membership[0] as Membership
        const accountDetails = new M.AccountInfo(memberInfo)

        // Small fix because API requires 1-2 second to update
        accountDetails.autoRenew = false

        return accountDetails
      }),
    )
  }

  getMemberInfo(membershipNumber: string, threePointAuth: ThreePointAuth) {
    const payload: MembershipMGetMemberInfoEventPayload = {
      membershipNumber,
      threePointAuth,
      method: MembershipMMethod.GET_MEMBER_INFO,
    }

    return this.executeService.membershipQuery<M.MemberInfoResponseObject>(payload).pipe(
      map((validateObject) => {
        const membershipError = !!validateObject?.meta?.isError

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject.error, validateObject)
        }

        return validateObject.response.executionData
      }),
    )
  }
}
