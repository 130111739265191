<ng-container *ngIf="globalState$ | async as g">

  <!-- html view wrapper -->
  <div
    *ngIf="!g.adminMode || g.previewMode || g.selectedField !== stateId + '-' + rowId"
    style="position: relative"
    [style.color]="styles.color"
    [style.font-size.px]="styles.fontSize?.px || 17"
    [style.font-weight]="styles.fontWeight"
    [style.line-height]="styles.lineHeight || 1.235"
    [style.margin-bottom.px]="styles.marginBottom?.px || 0"
    [style.margin-left.px]="styles.marginLeft?.px || 0"
    [style.margin-right.px]="styles.marginRight?.px || 0"
    [style.margin-top.px]="styles.marginTop?.px || 0"
    [style.padding-bottom.px]="styles.paddingBottom?.px || 0"
    [style.padding-left.px]="styles.paddingLeft?.px || 0"
    [style.padding-right.px]="styles.paddingRight?.px || 0"
    [style.padding-top.px]="styles.paddingTop?.px || 0"
    [style.text-align]="styles.textAlign || undefined"
  >
    <!-- html view -->
    <quill-view-html
      *ngIf="tokenizedValue"
      [content]="tokenizedValue"
      (click)="processLinks($event)"
    ></quill-view-html>

    <!-- overlay to capture first click when in admin mode -->
    <div
      *ngIf="g.adminMode && !g.previewMode && g.selectedField !== stateId + '-' + rowId"
      style="position: absolute; height: 100%; width: 100%; top: 0; left: 0"
      (click)="enableInlineEdit($event)"
      (pointerdown)="$event.stopPropagation()"
      (pointerup)="$event.stopPropagation()"
    ></div>
  </div>

  <!-- wysiwyg editor -->
  <ava-quill-form-quill-inline
    *ngIf="g.adminMode && !g.previewMode && g.selectedField === stateId + '-' + rowId"
    [fieldPath]="fieldPath"
    [stateId]="stateId"
    [rowId]="rowId"
    [options]="options"
    [stylesFunction]="stylesFunction"
    (pointerdown)="$event.stopPropagation()"
    (pointerup)="$event.stopPropagation()"
  ></ava-quill-form-quill-inline>

</ng-container>
