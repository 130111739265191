import { Injectable } from "@angular/core"
import { ColorService, ColorType } from "../../services/color"

export interface Theme {
  type: ThemeType
  backgroundColor: string | undefined
  description: string
  fontColor: string | undefined
  label: string
}

export enum ThemeType {
  BANNER_1 = "BANNER_1",
  BANNER_2 = "BANNER_2",
  CTA_1 = "CTA_1",
  CTA_2 = "CTA_2",
  CTA_3 = "CTA_3",
  FEATURE_1 = "FEATURE_1",
  FEATURE_2 = "FEATURE_2",
  FEATURE_3 = "FEATURE_3",
  OCT_1 = "OCT_1",
  OCT_2 = "OCT_2",
  OCT_3 = "OCT_3",
  OPT_01 = "OPT_01",
  OPT_02 = "OPT_02",
  OPT_03 = "OPT_03",
  TITLE_1 = "TITLE_1",
  TITLE_2 = "TITLE_2",
}

@Injectable({
  providedIn: "root"
})
export class ThemeService {
  themeOptions: ThemeType[] = [
    ThemeType.OPT_01,
    ThemeType.OPT_02,
    ThemeType.OPT_03
  ]

  constructor(
    private colorService: ColorService,
  ) {
  }

  theme(themeType: ThemeType): Theme | undefined {
    return this.themes.find(theme => theme.type === themeType)
  }

  get themes(): Theme[] {
    return [
      {
        type: ThemeType.BANNER_1,
        backgroundColor: this.colorService.color(ColorType.C_10)?.rgba,
        description: "",
        label: "Black Text",
        fontColor: this.colorService.color(ColorType.C_10)?.rgba
      },
      {
        type: ThemeType.BANNER_2,
        backgroundColor: this.colorService.color(ColorType.C_20)?.rgba,
        description: "",
        label: "White Text",
        fontColor: this.colorService.color(ColorType.C_20)?.rgba
      },
      {
        type: ThemeType.CTA_1,
        backgroundColor: this.colorService.color(ColorType.C_41)?.rgba,
        description: "",
        label: "Dark Blue",
        fontColor: this.colorService.color(ColorType.C_20)?.rgba
      },
      {
        type: ThemeType.CTA_2,
        backgroundColor: this.colorService.color(ColorType.C_12)?.rgba,
        description: "",
        label: "Gray",
        fontColor: this.colorService.color(ColorType.C_10)?.rgba
      },
      {
        type: ThemeType.CTA_3,
        backgroundColor: this.colorService.color(ColorType.C_11)?.rgba,
        description: "",
        label: "Dark Gray",
        fontColor: this.colorService.color(ColorType.C_10)?.rgba
      },
      {
        type: ThemeType.FEATURE_1,
        backgroundColor: this.colorService.color(ColorType.C_41)?.rgba,
        description: "",
        label: "Dark Blue",
        fontColor: this.colorService.color(ColorType.C_20)?.rgba
      },
      {
        type: ThemeType.FEATURE_2,
        backgroundColor: this.colorService.color(ColorType.C_12)?.rgba,
        description: "",
        label: "Gray",
        fontColor: this.colorService.color(ColorType.C_10)?.rgba
      },
      {
        type: ThemeType.FEATURE_3,
        backgroundColor: this.colorService.color(ColorType.C_20)?.rgba,
        description: "",
        label: "White",
        fontColor: this.colorService.color(ColorType.C_10)?.rgba
      },
      {
        type: ThemeType.OCT_1,
        backgroundColor: this.colorService.color(ColorType.C_41)?.rgba,
        description: "",
        label: "Dark Blue",
        fontColor: this.colorService.color(ColorType.C_20)?.rgba
      },
      {
        type: ThemeType.OCT_2,
        backgroundColor: this.colorService.color(ColorType.C_12)?.rgba,
        description: "",
        label: "Gray",
        fontColor: this.colorService.color(ColorType.C_10)?.rgba
      },
      {
        type: ThemeType.OCT_3,
        backgroundColor: this.colorService.color(ColorType.C_20)?.rgba,
        description: "",
        label: "White",
        fontColor: this.colorService.color(ColorType.C_10)?.rgba
      },
      {
        type: ThemeType.OPT_01,
        backgroundColor: this.colorService.color(ColorType.C_20)?.rgba,
        description: "",
        label: "White",
        fontColor: this.colorService.color(ColorType.C_10)?.rgba
      },
      {
        type: ThemeType.OPT_02,
        backgroundColor: this.colorService.color(ColorType.C_12)?.rgba,
        description: "",
        label: "Gray",
        fontColor: this.colorService.color(ColorType.C_10)?.rgba
      },
      {
        type: ThemeType.OPT_03,
        backgroundColor: this.colorService.color(ColorType.C_41)?.rgba,
        description: "",
        label: "Dark Blue",
        fontColor: this.colorService.color(ColorType.C_20)?.rgba
      },
      {
        type: ThemeType.TITLE_1,
        backgroundColor: this.colorService.color(ColorType.C_20)?.rgba,
        description: "",
        label: "White",
        fontColor: this.colorService.color(ColorType.C_10)?.rgba
      },
      {
        type: ThemeType.TITLE_2,
        backgroundColor: this.colorService.color(ColorType.C_12)?.rgba,
        description: "",
        label: "Gray",
        fontColor: this.colorService.color(ColorType.C_10)?.rgba
      },
    ]
  }

  /*
    get types(): ThemeTypes {
      return {
        banner1: {
          backgroundColorOption: ColorType.C_10,
          description: "",
          label: "Black Text",
          fontColorOption: ColorType.C_10
        },
        banner2: {
          backgroundColorOption: ColorType.C_20,
          description: "",
          label: "White Text",
          fontColorOption: ColorType.C_20
        },
        cta1: {
          backgroundColorOption: ColorType.C_41,
          description: "",
          label: "Dark Blue",
          fontColorOption: ColorType.C_20
        },
        cta2: {
          backgroundColorOption: ColorType.C_12,
          description: "",
          label: "Gray",
          fontColorOption: ColorType.C_10
        },
        cta3: {
          backgroundColorOption: ColorType.C_11,
          description: "",
          label: "Dark Gray",
          fontColorOption: ColorType.C_10
        },
        feature1: {
          backgroundColorOption: ColorType.C_41,
          description: "",
          label: "Dark Blue",
          fontColorOption: ColorType.C_20
        },
        feature2: {
          backgroundColorOption: ColorType.C_12,
          description: "",
          label: "Gray",
          fontColorOption: ColorType.C_10
        },
        feature3: {
          backgroundColorOption: ColorType.C_20,
          description: "",
          label: "White",
          fontColorOption: ColorType.C_10
        },
        oct1: {
          backgroundColorOption: ColorType.C_41,
          description: "",
          label: "Dark Blue",
          fontColorOption: ColorType.C_20
        },
        oct2: {
          backgroundColorOption: ColorType.C_12,
          description: "",
          label: "Gray",
          fontColorOption: ColorType.C_10
        },
        oct3: {
          backgroundColorOption: ColorType.C_20,
          description: "",
          label: "White",
          fontColorOption: ColorType.C_10
        },
        opt1: {
          backgroundColorOption: ColorType.C_20,
          description: "",
          label: "White",
          fontColorOption: ColorType.C_10
        },
        opt2: {
          backgroundColorOption: ColorType.C_12,
          description: "",
          label: "Gray",
          fontColorOption: ColorType.C_10
        },
        opt3: {
          backgroundColorOption: ColorType.C_41,
          description: "",
          label: "Dark Blue",
          fontColorOption: ColorType.C_20
        },
        title1: {
          backgroundColorOption: ColorType.C_20,
          description: "",
          label: "White",
          fontColorOption: ColorType.C_10
        },
        title2: {
          backgroundColorOption: ColorType.C_12,
          description: "",
          label: "Gray",
          fontColorOption: ColorType.C_10
        },
      }
    }
  */

}
