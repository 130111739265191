import { Inject, Injectable } from "@angular/core"
import {
  CheckboxOptions, InputOptions, SelectOption, SelectOptions
} from "./options-interfaces"
import { Validators } from "@angular/forms"
import { ValidateStatus } from "../../join-renew.service"
import { ValidatorService } from "../../../../services/validator"
import { AccountMembershipView, JoinView, QuickRenewView } from "../hoosier.service"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../services/state"
import { RxState } from "@rx-angular/state"

export interface PaymentOptions {
  address1: InputOptions
  address2: InputOptions
  administrativeArea: SelectOptions
  autoRenew: CheckboxOptions
  creditCardNumberIsValid: SelectOptions
  creditCardSecurityCodeIsValid: SelectOptions
  dob: InputOptions
  email: InputOptions
  expirationMonth: SelectOptions
  expirationYear: SelectOptions
  firstName: InputOptions
  lastName: InputOptions
  locality: InputOptions
  nameSuffix: SelectOptions
  postalCode: InputOptions
  promoCode: InputOptions
  useMemberInfoForBilling: CheckboxOptions
}

@Injectable({
  providedIn: "root"
})
export class PaymentFormOptionsService {
  dateOfBirthValidatorPattern = Validators
    .pattern(/^(0[1-9]|1[012])[^0-9]*(0[1-9]|[12][0-9]|3[01])[^0-9]*(19[0-9][0-9]|20[01][0-9]|202[012])$/)

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private validatorService: ValidatorService,
  ) {
  }

  get options(): PaymentOptions {
    return {
      autoRenew: {
        content: "Save time by enrolling with Auto Renewal",
        fieldName: "autoRenew",
        fieldPath: ["membershipPayload", "membership"],
        validateGroup: [],
        validateStatus: null,
        asyncValidators: [],
        validators: [],
      },
      dob: {
        config: {
          autocomplete: "bday",
          inputGroupSuffix: "Date of Birth",
          inputMode: "numeric",
          placeholder: "Date of Birth (mm/dd/yyyy)",
          type: "email",
        },
        fieldName: "dob",
        fieldPath: ["membershipPayload", "associates"],
        tip: {
          [ValidateStatus.ERROR]: "Please enter your birthdate MM/DD/YYYY",
        },
        validateGroup: [],
        validateStatus: null,
        asyncValidators: [],
        validators: [this.dateOfBirthValidatorPattern],
      },
      firstName: {
        config: {
          autocomplete: "given-name",
          inputGroupSuffix: "First Name",
          inputMode: "text",
          placeholder: "First Name",
          type: "text",
        },
        fieldName: "firstName",
        fieldPath: ["paymentPayload", "executionData", "billTo"],
        tip: {
          [ValidateStatus.ERROR]: "Please use letters only.",
        },
        validateGroup: [
          AccountMembershipView.ASSOCIATES_ADD,
          AccountMembershipView.LEVEL,
          AccountMembershipView.RENEW,
          JoinView.PAYMENT,
          QuickRenewView.SUMMARY_AND_PAYMENT,
        ],
        validateStatus: null,
        asyncValidators: [],
        validators: [Validators.required, c => this.validatorService.validateIsTextStringFunction(c)],
      },
      lastName: {
        config: {
          autocomplete: "family-name",
          inputGroupSuffix: "Last Name",
          inputMode: "text",
          placeholder: "Last Name",
          type: "text",
        },
        fieldName: "lastName",
        fieldPath: ["paymentPayload", "executionData", "billTo"],
        tip: {
          [ValidateStatus.ERROR]: "Please use letters only.",
        },
        validateGroup: [
          AccountMembershipView.ASSOCIATES_ADD,
          AccountMembershipView.LEVEL,
          AccountMembershipView.RENEW,
          JoinView.PAYMENT,
          QuickRenewView.SUMMARY_AND_PAYMENT,
        ],
        validateStatus: null,
        asyncValidators: [],
        validators: [Validators.required, c => this.validatorService.validateIsTextStringFunction(c)],
      },
      address1: {
        config: {
          autocomplete: "street-address",
          inputGroupSuffix: "Address 1",
          inputMode: "text",
          placeholder: "Address 1",
          type: "text",
        },
        fieldName: "address1",
        fieldPath: ["paymentPayload", "executionData", "billTo"],
        tip: {
          [ValidateStatus.ERROR]: "Invalid address1",
        },
        validateGroup: [
          AccountMembershipView.ASSOCIATES_ADD,
          AccountMembershipView.LEVEL,
          AccountMembershipView.RENEW,
          JoinView.PAYMENT,
          QuickRenewView.SUMMARY_AND_PAYMENT,
        ],
        validateStatus: ValidateStatus.NULL,
        asyncValidators: [],
        validators: [],
      },
      address2: {
        config: {
          autocomplete: "",
          inputGroupSuffix: "Address 2",
          inputMode: "text",
          placeholder: "Address 2",
          type: "text",
        },
        fieldName: "address2",
        fieldPath: ["paymentPayload", "executionData", "billTo"],
        tip: {
          [ValidateStatus.ERROR]: "Invalid address2",
        },
        validateGroup: [
          AccountMembershipView.ASSOCIATES_ADD,
          AccountMembershipView.LEVEL,
          AccountMembershipView.RENEW,
          JoinView.PAYMENT,
          QuickRenewView.SUMMARY_AND_PAYMENT,
        ],
        validateStatus: ValidateStatus.NULL,
        asyncValidators: [],
        validators: [],
      },
      locality: {
        config: {
          autocomplete: "",
          inputGroupSuffix: "City",
          inputMode: "text",
          placeholder: "City",
          type: "text",
        },
        fieldName: "locality",
        fieldPath: ["paymentPayload", "executionData", "billTo"],
        tip: {
          [ValidateStatus.ERROR]: "Invalid City",
        },
        validateGroup: [
          AccountMembershipView.ASSOCIATES_ADD,
          AccountMembershipView.LEVEL,
          AccountMembershipView.RENEW,
          JoinView.PAYMENT,
          QuickRenewView.SUMMARY_AND_PAYMENT,
        ],
        validateStatus: ValidateStatus.NULL,
        asyncValidators: [],
        validators: [],
      },
      postalCode: {
        config: {
          autocomplete: "postal-code",
          inputGroupSuffix: "Zip Code",
          inputMode: "numeric",
          placeholder: "Zip Code",
          type: "text",
        },
        fieldName: "postalCode",
        fieldPath: ["paymentPayload", "executionData", "billTo"],
        tip: {
          [ValidateStatus.ERROR]: "Invalid Zip Code",
        },
        validateGroup: [
          AccountMembershipView.ASSOCIATES_ADD,
          AccountMembershipView.LEVEL,
          AccountMembershipView.RENEW,
          JoinView.PAYMENT,
          QuickRenewView.SUMMARY_AND_PAYMENT,
        ],
        validateStatus: null,
        asyncValidators: [],
        validators: [Validators.pattern(/^\d\d\d\d\d$/)],
      },
      email: {
        config: {
          autocomplete: "email",
          inputGroupSuffix: "Email",
          inputMode: "email",
          placeholder: "Email Address",
          type: "email",
        },
        fieldName: "email",
        fieldPath: ["membershipPayload", "primary"],
        tip: {
          [ValidateStatus.ERROR]: "Invalid Email Address",
        },
        validateGroup: [
          AccountMembershipView.ASSOCIATES_ADD,
          AccountMembershipView.LEVEL,
          AccountMembershipView.RENEW,
          JoinView.PAYMENT,
          QuickRenewView.SUMMARY_AND_PAYMENT,
        ],
        validateStatus: null,
        asyncValidators: [c => this.validatorService.emailIsAvailable(c)],
        validators: [Validators.required, c => this.validatorService.email(c)],
      },
      promoCode: {
        config: {
          autocomplete: "",
          inputGroupSuffix: "Promo Code",
          inputMode: "text",
          placeholder: "Promo Code",
          type: "text",
        },
        fieldName: "promoCode",
        fieldPath: ["membershipPayload", "membership"],
        tip: {
          [ValidateStatus.ERROR]: "Invalid Promo Code",
          [ValidateStatus.PENDING]: "verifying the promo code",
        },
        validateGroup: [
          AccountMembershipView.ASSOCIATES_ADD,
          AccountMembershipView.LEVEL,
          AccountMembershipView.RENEW,
          JoinView.PAYMENT,
          QuickRenewView.SUMMARY_AND_PAYMENT,
        ],
        validateStatus: null,
        asyncValidators: [],
        validators: [],
      },
      administrativeArea: {
        allowClear: false,
        errorMessage: "",
        fieldName: "administrativeArea",
        fieldPath: ["paymentPayload", "executionData", "billTo"],
        selectOptions: this.paymentStates,
        placeholder: "",
        tip: {},
        validateGroup: [],
        validateStatus: null,
        asyncValidators: [],
        validators: [],
      },
      nameSuffix: {
        allowClear: true,
        errorMessage: "",
        fieldName: "nameSuffix",
        fieldPath: ["membershipPayload", "associates"],
        selectOptions: [
          {
            label: "Jr",
            value: "Jr"
          },
          {
            label: "Sr",
            value: "Sr"
          },
          {
            label: "I",
            value: "I"
          },
          {
            label: "II",
            value: "II"
          },
          {
            label: "III",
            value: "III"
          },
          {
            label: "IV",
            value: "IV"
          },
          {
            label: "V",
            value: "V"
          },
          {
            label: "VI",
            value: "VI"
          },
          {
            label: "VII",
            value: "VII"
          },
        ],
        placeholder: "suffix",
        tip: {},
        validateGroup: [],
        validateStatus: null,
        asyncValidators: [],
        validators: [],
      },
      expirationMonth: {
        allowClear: false,
        errorMessage: "",
        fieldName: "expirationMonth",
        fieldPath: ["creditCard"],
        selectOptions: [],
        placeholder: "Exp Month",
        tip: {
          [ValidateStatus.ERROR]: "Required"
        },
        validateGroup: [
          AccountMembershipView.ASSOCIATES_ADD,
          AccountMembershipView.LEVEL,
          AccountMembershipView.RENEW,
          JoinView.PAYMENT,
          QuickRenewView.SUMMARY_AND_PAYMENT,
        ],
        validateStatus: null,
        asyncValidators: [],
        validators: [Validators.required],
      },
      expirationYear: {
        allowClear: false,
        errorMessage: "",
        fieldName: "expirationYear",
        fieldPath: ["creditCard"],
        selectOptions: [],
        placeholder: "Exp Year",
        tip: {
          [ValidateStatus.ERROR]: "Required"
        },
        validateGroup: [
          AccountMembershipView.ASSOCIATES_ADD,
          AccountMembershipView.LEVEL,
          AccountMembershipView.RENEW,
          JoinView.PAYMENT,
          QuickRenewView.SUMMARY_AND_PAYMENT,
        ],
        validateStatus: null,
        asyncValidators: [],
        validators: [Validators.required],
      },
      creditCardNumberIsValid: {
        allowClear: false,
        errorMessage: "Please enter a valid credit card number",
        fieldName: "numberIsValid",
        fieldPath: ["creditCard"],
        selectOptions: [],
        placeholder: "",
        tip: {},
        validateGroup: [],
        validateStatus: null,
        asyncValidators: [],
        validators: [],
      },
      creditCardSecurityCodeIsValid: {
        allowClear: false,
        errorMessage: "Please enter 3-digit credit card verification code",
        fieldName: "securityCodeIsValid",
        fieldPath: ["creditCard"],
        selectOptions: [],
        placeholder: "",
        tip: {},
        validateGroup: [],
        validateStatus: null,
        asyncValidators: [],
        validators: [],
      },
      useMemberInfoForBilling: {
        content: "",
        fieldName: "useMemberInfoForBilling",
        fieldPath: [],
        validateGroup: [],
        validateStatus: null,
        asyncValidators: [],
        validators: [],
      },
    }
  }

  get paymentStates(): SelectOption[] {
    return [
      { value: "AL", label: "Alabama" },
      { value: "AK", label: "Alaska" },
      { value: "AS", label: "American Samoa" },
      { value: "AZ", label: "Arizona" },
      { value: "AR", label: "Arkansas" },
      { value: "CA", label: "California" },
      { value: "CO", label: "Colorado" },
      { value: "CT", label: "Connecticut" },
      { value: "DE", label: "Delaware" },
      { value: "DC", label: "District Of Columbia" },
      { value: "FM", label: "Federated States Of Micronesia" },
      { value: "FL", label: "Florida" },
      { value: "GA", label: "Georgia" },
      { value: "GU", label: "Guam" },
      { value: "HI", label: "Hawaii" },
      { value: "ID", label: "Idaho" },
      { value: "IL", label: "Illinois" },
      { value: "IN", label: "Indiana" },
      { value: "IA", label: "Iowa" },
      { value: "KS", label: "Kansas" },
      { value: "KY", label: "Kentucky" },
      { value: "LA", label: "Louisiana" },
      { value: "ME", label: "Maine" },
      { value: "MH", label: "Marshall Islands" },
      { value: "MD", label: "Maryland" },
      { value: "MA", label: "Massachusetts" },
      { value: "MI", label: "Michigan" },
      { value: "MN", label: "Minnesota" },
      { value: "MS", label: "Mississippi" },
      { value: "MO", label: "Missouri" },
      { value: "MT", label: "Montana" },
      { value: "NE", label: "Nebraska" },
      { value: "NV", label: "Nevada" },
      { value: "NH", label: "New Hampshire" },
      { value: "NJ", label: "New Jersey" },
      { value: "NM", label: "New Mexico" },
      { value: "NY", label: "New York" },
      { value: "NC", label: "North Carolina" },
      { value: "ND", label: "North Dakota" },
      { value: "MP", label: "Northern Mariana Islands" },
      { value: "OH", label: "Ohio" },
      { value: "OK", label: "Oklahoma" },
      { value: "OR", label: "Oregon" },
      { value: "PW", label: "Palau" },
      { value: "PA", label: "Pennsylvania" },
      { value: "PR", label: "Puerto Rico" },
      { value: "RI", label: "Rhode Island" },
      { value: "SC", label: "South Carolina" },
      { value: "SD", label: "South Dakota" },
      { value: "TN", label: "Tennessee" },
      { value: "TX", label: "Texas" },
      { value: "UT", label: "Utah" },
      { value: "VT", label: "Vermont" },
      { value: "VI", label: "Virgin Islands" },
      { value: "VA", label: "Virginia" },
      { value: "WA", label: "Washington" },
      { value: "WV", label: "West Virginia" },
      { value: "WI", label: "Wisconsin" },
      { value: "WY", label: "Wyoming" },
    ]
  }

}
