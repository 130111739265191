import { Component, Input, OnChanges, SimpleChanges } from "@angular/core"
import { NgIf } from "@angular/common"
import { NzAlertModule } from "ng-zorro-antd/alert"
import { AlertType } from "./types"
import { delay, first, of, tap } from "rxjs"

@Component({
  selector: "ava-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
  standalone: true,
  imports: [NgIf, NzAlertModule],
})
export class AlertComponent implements OnChanges {
  @Input() message: string | null = ""
  @Input() timeout = 10000
  @Input() description = ""
  @Input() nzShowIcon = false
  @Input() type: AlertType = AlertType.INFO
  @Input() closeable: boolean = false

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message && changes.message.currentValue) {
      this.setTimeOut()
    }
  }

  setTimeOut() {
    of(false)
      .pipe(
        delay(this.timeout),
        first(),
        tap(() => (this.message = "")),
        tap(() => (this.description = "")),
      )
      .subscribe()
  }
}
