import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { of, withLatestFrom } from "rxjs"
import { catchError, concatMap, map, switchMap } from "rxjs/operators"
import { ClubApp } from "@aaa/emember/types"
import { ExecuteService } from "../services/execute.service"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import {
  AccountActions,
  AccountAddressValues,
  AccountPhoneValues,
  getShortMembershipNumber,
} from "@aaa/emember/store-account"
import { Operation, OperationEventResponse, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import { RequestError, RequestErrorType } from "../generic-errors"
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import { getClearCacheSettings } from "../utils/get-cache-settings"
import {
  MembershipMzpGetMembershipCostsChange,
  MembershipMzpMethod,
  MembershipMzpOperationExecuteEventPayload,
  MzpOperationExecuteResponse,
} from "@aaa/interface-joinRenew-membership-membershipMzp"
import { Mzp } from "../mzp.type"

@Injectable({ providedIn: "root" })
export class AccountMzpSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.MidStates]))
  executeService = inject(ExecuteService)

  updateAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updateAddress),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getShortMembershipNumber)))),
      switchMap(([{ data }, memberNumber]) =>
        // 1. Validate
        this.validateAddress(data, memberNumber).pipe(
          concatMap((executionData) =>
            // 2. Save
            this.saveAddress(executionData, memberNumber),
          ),
          map(() => AccountActions.updateAddressSucceeded({})),
          catchError((error) => of(AccountActions.updateAddressFailed({ error }))),
        ),
      ),
    ),
  )

  updatePhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updatePhone),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getShortMembershipNumber)))),
      switchMap(([{ data }, memberNumber]) =>
        // 1. Validate
        this.validatePhone(data, memberNumber).pipe(
          concatMap((executionData) =>
            // 2. Save
            this.savePhone(executionData, memberNumber),
          ),
          map(() => AccountActions.updatePhoneSucceeded({})),
          catchError((error) => of(AccountActions.updatePhoneFailed({ error }))),
        ),
      ),
    ),
  )

  validateAddress(data: Partial<AccountAddressValues>, memberNumber: string) {
    const payload: MembershipMzpGetMembershipCostsChange = {
      memberNumber,
      method: MembershipMzpMethod.GET_MEMBERSHIP_COSTS_CHANGE,
      address: {
        addressLine1: data.address1 || "",
        addressLine2: data.address2,
        city: data.city || "",
        postalCode: data.zipcode || "",
        state: data.state || "",
      },
    }

    return this.executeService.membershipQuery<Mzp.MembershipChangeAddressValidationResponseObject>(payload).pipe(
      map((validateObject) => {
        if (validateObject.meta?.isError) {
          throw new RequestError(RequestErrorType.UpdateAddress, validateObject)
        }

        return validateObject.response.executionData
      }),
    )
  }

  saveAddress(executionData: string, memberNumber: string | null) {
    const operation = Operation.UPDATE
    const membershipEvent: MembershipMzpOperationExecuteEventPayload = {
      operation,
      executionData,
      cacheSettings: getClearCacheSettings(memberNumber),
      method: MembershipMzpMethod.OPERATION_EXECUTE,
    }
    const executePayload: OperationExecutePayload = {
      membershipEvent,
      operation,
    }

    return this.executeService.execute<OperationEventResponse<MzpOperationExecuteResponse>>(executePayload).pipe(
      map(({ validateObject }) => {
        console.log("validateObject", validateObject)
        // Todo: need to implement when api fails for save
        return validateObject
      }),
    )
  }

  validatePhone(data: AccountPhoneValues, memberNumber: string) {
    const payload: MembershipMzpGetMembershipCostsChange = {
      method: MembershipMzpMethod.GET_MEMBERSHIP_COSTS_CHANGE,
      memberNumber,
      customers: [
        {
          associateId: "00",
          phones: [
            {
              type: "HOME",
              number: String(data.home),
            },
            {
              type: "WORK",
              number: String(data.business),
            },
            {
              type: "CELL",
              number: String(data.cell),
            },
          ],
        },
      ],
    }

    return this.executeService.membershipQuery<Mzp.MembershipChangePhoneValidationResponseObject>(payload).pipe(
      map((validateObject) => {
        if (validateObject.meta?.isError) {
          throw new RequestError(RequestErrorType.UpdatePhone, validateObject)
        }

        return validateObject.response.executionData
      }),
    )
  }

  savePhone(executionData: string, memberNumber: string | null) {
    const operation = Operation.UPDATE
    const membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
      operation,
      executionData,
      cacheSettings: getClearCacheSettings(memberNumber),
      method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
    }
    const payload: OperationExecutePayload = {
      membershipEvent,
      operation,
      paymentEvent: null,
    }

    return this.executeService.execute<OperationEventResponse<MzpOperationExecuteResponse>>(payload).pipe(
      map(({ validateObject, operationObject }) => {
        console.log("validateObject", validateObject)

        return validateObject
      }),
    )
  }
}
