import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { AccountDetails, ValidateSucceededResponseObject } from "../types/types"
import { of, withLatestFrom } from "rxjs"
import { catchError, map, switchMap } from "rxjs/operators"
import { MembershipAssociateAddActions } from "./membership-associate-add.actions"
import {
  getMembershipAssociateAddAccountDetails,
  getMembershipAssociateAddSummary,
  MembershipAssociateAddAccount,
  getMembershipAssociateExecutionData,
  getMembershipAssociateFormValues,
} from "./membership-associate-add.selectors"
import { FormGroupValue } from "../../modules/share/form.utils"
import { MembershipAssociateAddForm } from "./membership-associate-add.models"
import { getMembershipNumber } from "@aaa/emember/store-membership"
import { getPayment, getPaymentAutoRenewValue } from "@aaa/emember/store-payment"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { ExecuteService } from "../services/execute.service"
import { ClubApp } from "@aaa/emember/types"
import {
  MembershipMGetMemberInfoEventPayload,
  MembershipMMethod,
  MembershipMOperationExecuteChangeEventPayload,
  MembershipMValidateChangeEventPayload,
  MMembershipCode,
  ThreePointAuth,
} from "@aaa/interface-joinRenew-membership-membershipM"
import { M } from "../m.type"
import { Operation, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { getThreePointAuth } from "@aaa/emember/store-account"
import { RequestError, RequestErrorType } from "../generic-errors"
import { checkMembershipErrorsMSystem } from "../check-membership-errors-m-system"
import { checkCybersourcePaymentValidation } from "../check-cybersource-payment-validation"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import { MembershipOfferSummary } from "../price-offers/helpers/types"
import { checkOperationErrorsMSystem } from "../check-operation-errors-m-system"
import { AnalyticsPurchaseEvent } from "../../../types/analytics-purchase-event"
import { DataLayerService } from "../../modules/share/services/data-layer.service"
import { AppAnalyticsEvents } from "../../../types/analytics-events"
import { getTransactionId } from "../utils/get-transaction-id"

@Injectable({ providedIn: "root" })
export class MembershipAssociateAddMSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Northampton, ClubApp.Shelby]))
  executeService = inject(ExecuteService)
  dataLayer = inject(DataLayerService)

  updateSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.recostValidateSucceeded),
      map(({ response }: ValidateSucceededResponseObject<M.RecostValidateResponseObject>) => {
        const { Result } = response.response.validateResponse
        const accountDetails = new M.AccountInfo(Result.Membership[0], Result.MembershipOffering)

        return MembershipAssociateAddActions.setAccountDetails({ accountDetails })
      }),
    ),
  )

  recostValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.recostValidate, MembershipAssociateAddActions.validatePromoCode),
      withLatestFrom(
        this.store.select(getMembershipAssociateFormValues),
        this.store.select(getMembershipAssociateAddSummary),
        this.store.select(getMembershipNumber),
        this.store.select(getPaymentAutoRenewValue),
        this.store.select(getThreePointAuth),
      ),
      switchMap(([, formValues, summary, membershipNumber, autoRenew, threePointAuth]) => {
        return of(true).pipe(
          switchMap(() => this.getMemberInfo(membershipNumber, threePointAuth)),
          switchMap((executionData) =>
            this.recostValidation(formValues, summary, executionData, autoRenew, threePointAuth).pipe(
              map((validation) => ({ executionData, validation })),
            ),
          ),
          map(({ validation, executionData }) =>
            MembershipAssociateAddActions.recostValidateSucceeded({
              executionData,
              response: validation,
            }),
          ),
          catchError((error) => of(MembershipAssociateAddActions.recostValidateFailed({ error }))),
        )
      }),
    ),
  )

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.pay),
      withLatestFrom(
        this.store.select(getMembershipAssociateAddAccountDetails),
        this.store.select(getMembershipAssociateFormValues),
        this.store.select(getMembershipAssociateExecutionData),
        this.store.select(MembershipAssociateAddAccount.getBalance),
        this.store.select(getPayment),
        this.store.select(getThreePointAuth),
      ),
      switchMap(([, accountDetails, formValues, executionData, totalCost, payment, threePointAuth]) =>
        this.pay(accountDetails, formValues, executionData, totalCost, payment, threePointAuth).pipe(
          map((accountDetails) => MembershipAssociateAddActions.paySucceeded({ accountDetails })),
          catchError((error) => of(MembershipAssociateAddActions.payFailed({ error }))),
        ),
      ),
    ),
  )

  pay(
    accountDetails: AccountDetails | null,
    formValues: Partial<FormGroupValue<MembershipAssociateAddForm>>,
    executionData: string,
    totalCost: number,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
    threePointAuth: ThreePointAuth,
  ) {
    const membershipEvent: MembershipMOperationExecuteChangeEventPayload = {
      executionData,
      threePointAuth,
      method: MembershipMMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
      changePropsData: {
        users: (formValues?.associates || []).map((associate) => {
          return {
            firstName: associate.firstName,
            lastName: associate.lastName,
            email: associate.email,
            type: "associate",
            options: [...(associate.accidentMedicalPlan ? ["MED750"] : "")] as MMembershipCode[],
          }
        }),
      },
    }
    const paymentPayload: PaymentCybersourceOperationExecuteEventPayload = {
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(formValues?.billing?.billingTo?.address1),
          address2: String(formValues?.billing?.billingTo?.address2),
          administrativeArea: String(formValues?.billing?.billingTo?.state),
          buildingNumber: "",
          country: "US",
          district: String(formValues?.billing?.billingTo?.state),
          email: String(accountDetails?.email || "fallback@avagate.com"),
          firstName: String(formValues.billing?.billingTo?.firstName),
          lastName: String(formValues.billing?.billingTo?.lastName),
          locality: String(formValues.billing?.billingTo?.city),
          phoneNumber: String(accountDetails?.phone.cell),
          postalCode: String(formValues.billing?.billingTo?.zipcode),
        },
        amountDetails: {
          totalAmount: String(totalCost),
          currency: "USD",
        },
        creditCardBrandedName: payment.formValues?.card?.cardName || "",
      },
    }
    const payload: OperationExecutePayload = {
      membershipEvent: membershipEvent,
      paymentEvent: paymentPayload,
      operation: Operation.UPDATE,
    }

    return this.executeService.execute<M.ValidateResponseObject, M.ExecutePaymentResponseObject>(payload).pipe(
      map(({ validateObject, paymentObject, operationObject }) => {
        const paymentError = !!paymentObject?.meta?.isError
        if (paymentError) {
          checkCybersourcePaymentValidation(paymentObject.error)
        }

        const membershipError = !!validateObject?.meta?.isError
        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject?.error, validateObject)
        }

        if (!validateObject?.response) {
          throw new RequestError(RequestErrorType.AddAssociate, paymentObject)
        }

        const operationError = !!operationObject?.meta?.isError
        if (operationError) {
          checkOperationErrorsMSystem(operationObject.error, operationObject)
        }

        const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
          currency: "USD",
          transaction_id: getTransactionId(paymentObject),
          value: totalCost,
          items: [
            { quantity: 1, price: totalCost, item_id: "primary", item_name: AppAnalyticsEvents.AccountAddAssociates },
          ],
          context: "ava-store " + AppAnalyticsEvents.AccountAddAssociates,
        }
        this.dataLayer.purchaseEvent(analyticsEventParams)

        return new M.AccountInfo(validateObject.response.validateResponse.Result.Membership[0])
      }),
    )
  }

  getMemberInfo(membershipNumber: string, threePointAuth: ThreePointAuth) {
    const payload: MembershipMGetMemberInfoEventPayload = {
      membershipNumber,
      threePointAuth,
      method: MembershipMMethod.GET_MEMBER_INFO,
    }

    return this.executeService
      .membershipQuery<M.MemberInfoResponseObject>(payload)
      .pipe(map((memberInfo) => memberInfo.response.executionData || ""))
  }

  recostValidation(
    formValues: Partial<FormGroupValue<MembershipAssociateAddForm>>,
    offers: MembershipOfferSummary,
    executionData: string,
    autoRenew: boolean,
    threePointAuth: ThreePointAuth,
  ) {
    const payload: MembershipMValidateChangeEventPayload = {
      method: MembershipMMethod.VALIDATE_CHANGE,
      executionData,
      threePointAuth,
      changePropsData: {
        autoRenew: autoRenew,
        users: (formValues?.associates || []).map((associate) => ({
          type: "associate",
          lastName: associate.lastName,
          firstName: associate.firstName,
          email: associate.email,
          options: offers.associates.reduce((acc, { associateOptional }) => {
            const optionalCodes = associateOptional.map((o) => o.code)

            acc.push(...optionalCodes)

            return acc
          }, [] as string[]),
        })),
      },
    }

    return this.executeService.membershipQuery<M.RecostValidateResponseObject>(payload).pipe(
      map((validateObject) => {
        const membershipError = !!validateObject?.meta?.isError

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject?.error, validateObject)
        }

        return validateObject
      }),
    )
  }
}
