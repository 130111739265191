<div [style]="'margin: auto'">
  <div>
    <div [style]="'font-size: 20px; font-weight: 500; padding-top: 20px'">
      Notifications Feed
    </div>
    <nz-card
      [nzTitle]="bannerTitle"
      *ngFor="let entry of entries; let entryIndex = index"
      nzBorderless
      class="blue-banner"
      [style]="'width: 100%'"
      [nzBodyStyle]="{'display': 'grid', 'grid-gap': '20px', 'justify-items': 'start'}"
    >
      <ng-template #bannerTitle>
        {{ entry.source }}
        =>
        {{ entry.messageId }}
      </ng-template>
      <div>
        <div>
          Initial Notification:
          {{ entry.timeInitial?.seconds * 1000 | date : "MM/dd/yy h:mm:ss a"}}
        </div>
        <div>
          Latest Notification:
          {{ entry.timeLastSent?.seconds * 1000 | date : "MM/dd/yy h:mm:ss a"}}
        </div>
        <div>
          Count:
          {{ entry.timesLimited }}
        </div>
      </div>
<!--
      <div *ngIf="window['metaData']['user']['id'] === '1'">
        <div *ngFor="let messageBody of entry.messageBodies; let index = index">
          <div (click)="toggleCollapsed(entryIndex, index)">
            {{messageBody.time?.seconds * 1000 | date : "MM/dd/yy h:mm:ss a"}}
          </div>
          <div
            [style]="'transition: all 1s cubic-bezier(0.4, 0, 0.2, 1); overflow-y: scroll; overflow-x: scroll; opacity: 0; transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);'"
            #collapsedWrapper
            id="collapsed-wrapper-{{entryIndex}}-{{index}}"
          >
            <pre [style]="'text-align: left; margin: 0;'">
              {{messageBody.body}}
            </pre>
          </div>
        </div>
      </div>
-->
      <button
        nz-button [nzType]="'primary'"
        (click)="resetRateLimit(entry.source, entry.messageId, false); $event.preventDefault()"
      >
        Clear this entry
      </button>
    </nz-card>

  </div>
</div>
