import { Component, ViewChild, ViewContainerRef } from "@angular/core"
import { Toast, ToastPackage, ToastrService } from "ngx-toastr"

@Component({
  selector: "ava-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent extends Toast {
  @ViewChild("viewComponent", { read: ViewContainerRef }) viewComponentRef: ViewContainerRef | null = null

  constructor(protected override toastrService: ToastrService, public override toastPackage: ToastPackage) {
    super(toastrService, toastPackage)
  }
}
