import { createFeatureSelector, createSelector } from "@ngrx/store"
import { getClubId, getClubOption, getMembershipCodes, getMembershipState } from "@aaa/emember/store-membership"
import { format } from "date-fns-tz"
import { AccountStatus, ClubApp } from "@aaa/emember/types"
import { ThreePointAuth } from "@aaa/interface-joinRenew-membership-membershipM"
import { getPriceOfferLevels } from "@aaa/emember/store-price-offers"
import { ListItem } from "../../modules/share/ava-list"
import { AccountLink, AccountMenuItem, AccountState, LinkType } from "./account.model"
import { RequestStatus } from "../../../types/request-status"
import { ConfirmedMember } from "../../modules/share/membership-card-detail-list/types"
import { FormGroupValue } from "../../modules/share/form.utils"
import { BillingToForm } from "../../modules/share/billing-form"
import { AvaTab } from "../../modules/share/ava-tabs"
import { getFeatureFlags } from "../feature-flags"
import { FeatureName } from "../feature-flags/types"
import { expiredFromNow } from "../utils/expired-from-now"
import { getErrorMessage } from "../generic-errors"
import { MembershipPlanAction } from "@aaa/emember/store-types"
import { accountReservationLinks } from "../../constants/account-reservation-links"

export const ACCOUNT_FEATURE_KEY = "account"

export const getAccountState = createFeatureSelector<AccountState>(ACCOUNT_FEATURE_KEY)
export const getAccountDetails = createSelector(getMembershipState, ({ accountDetails }) => accountDetails)
export const getAccountDetailsMembershipNumber = createSelector(
  getAccountDetails,
  (accountDetails) => accountDetails?.memberNumber || "",
)
export const getShortMembershipNumber = createSelector(getAccountDetailsMembershipNumber, (membershipNumber) =>
  membershipNumber.slice(6, 13),
)
export const getThreePointAuth = createSelector(getAccountDetails, (accountDetails) => {
  return {
    zip: accountDetails?.address.zip || "",
    lastName: accountDetails?.lastName || "",
    memberNumber: accountDetails?.memberNumber || "",
  } as ThreePointAuth
})
export const getAccountDetailsStatus = createSelector(
  getAccountDetails,
  (accountDetails) => accountDetails?.status || null,
)
export const getAccountDetailsBalance = createSelector(
  getAccountDetails,
  (accountDetails) => accountDetails?.balance || null,
)
export const getAccountDetailMembershipCode = createSelector(
  getAccountDetails,
  (accountDetails) => accountDetails?.code || null,
)
export const getCurrentMembershipLevelIndex = createSelector(
  getAccountDetailMembershipCode,
  getPriceOfferLevels,
  (membershipCode, levels) => levels.findIndex((l) => l.membershipType === membershipCode?.membershipType) || 0,
)
//
export const getAccountDetailsPhones = createSelector(getAccountDetails, (accountDetails) =>
  accountDetails ? accountDetails.phone : null,
)
export const getAccountDetailsEmail = createSelector(getAccountDetails, (accountDetails) =>
  accountDetails ? accountDetails.email : "",
)
export const getAccountAssociates = createSelector(getAccountDetails, (accountDetails) =>
  accountDetails ? accountDetails.associates : [],
)

export const getTotalAccountAssociates = createSelector(getAccountAssociates, (associates) => associates.length)

export const getBillingAddress = createSelector(getAccountDetails, (accountDetails) => {
  if (accountDetails) {
    return {
      firstName: accountDetails.firstName,
      lastName: accountDetails.lastName,
      address1: accountDetails.address.street1,
      address2: accountDetails.address.street2,
      city: accountDetails.address.city,
      state: accountDetails.address.state,
      zipcode: accountDetails.address.zip,
    } as FormGroupValue<BillingToForm>
  } else {
    return {}
  }
})
export const getIsLoadingAddressForm = createSelector(
  getAccountState,
  ({ addressFormRequestStatus }) => addressFormRequestStatus === RequestStatus.RUNNING,
)
export const getAddressFormError = createSelector(
  getAccountState,
  ({ addressFormRequestError }) => addressFormRequestError,
)
export const getAddressFormErrorMessage = createSelector(getAddressFormError, getClubOption, (error, options) =>
  getErrorMessage(error, options),
)
export const getEditAccountAddress = createSelector(getAccountState, ({ editAddress }) => editAddress)

export const getIsLoadingPhoneForm = createSelector(
  getAccountState,
  ({ phoneFormRequestStatus }) => phoneFormRequestStatus === RequestStatus.RUNNING,
)
export const getIsLoadingEmailForm = createSelector(
  getAccountState,
  ({ emailFormRequestStatus }) => emailFormRequestStatus === RequestStatus.RUNNING,
)
export const getEmailFormError = createSelector(getAccountState, ({ emailFormRequestError }) => emailFormRequestError)
export const getEmailFormErrorMessage = createSelector(getEmailFormError, getClubOption, (error, options) =>
  getErrorMessage(error, options),
)
export const getEmailFormMode = createSelector(getAccountState, ({ editEmailFormMode }) => editEmailFormMode)

export const getEditAccountPhoneType = createSelector(getAccountState, ({ editPhoneType }) => editPhoneType)
export const getPhoneFormError = createSelector(getAccountState, ({ phoneFormRequestError }) => phoneFormRequestError)
export const getPhoneFormErrorMessage = createSelector(getPhoneFormError, getClubOption, (error, options) =>
  getErrorMessage(error, options),
)
export const getCardNumber = createSelector(
  getAccountDetails,
  (accountDetails) => (accountDetails && accountDetails.cardNumber) || "",
)
export const getAutoRenew = createSelector(getAccountDetails, (accountDetails) => !!accountDetails?.autoRenew)

export const getAccountExpireDate = createSelector(getAccountDetails, (accountDetails) => {
  if (accountDetails?.expireDate) {
    return new Date(accountDetails?.expireDate)
  }

  return null
})

export const getAccountExpired = createSelector(getAccountExpireDate, (expireDate) => {
  if (expireDate) {
    return expiredFromNow(expireDate)
  }

  return false
})

export const getAccountStatusPending = createSelector(
  getAccountDetails,
  (accountDetails) => accountDetails?.status === AccountStatus.PENDING,
)

export const getAccountStatusText = createSelector(getAccountExpireDate, getAccountExpired, (expireDate, isExpired) => {
  if (expireDate) {
    const formatDate = format(expireDate, "MM/dd/yyyy")

    return isExpired ? "EXPIRED " + formatDate : "EXPIRES " + formatDate
  }

  return ""
})

export const getAccountMenuItems = createSelector(
  getAccountDetails,
  getFeatureFlags,
  getClubOption,
  (accountDetails, flags, options) => {
    const menuItems: AccountMenuItem[] = []

    if (flags[FeatureName.ACCOUNT_INFORMATION] && accountDetails) {
      let description = "Change Email, Password"

      if (options.clubId === ClubApp.Northampton || options.clubId === ClubApp.Shelby) {
        description = "Update Home Address, Phone Number"
      }

      menuItems.push({
        description,
        icon: "icons:account",
        title: "Account Information",
        url: "/account/information",
      })
    }
    if (flags[FeatureName.ACCOUNT_MEMBERSHIP] && accountDetails) {
      let description = "Change Membership, Add Members"

      if (options.clubId === ClubApp.Northampton || options.clubId === ClubApp.Shelby) {
        description = "Renew Membership, View Associates"
      }

      menuItems.push({
        description,
        icon: "icons:membership",
        title: "Membership",
        url: "/account/membership",
      })
    }
    if (flags[FeatureName.ACCOUNT_INSURANCE]) {
      menuItems.push({
        icon: "icons:insurance",
        title: "Insurance",
        description: "Pay, Manage Insurance",
        url: "/account/insurance",
      })
    }
    if (flags[FeatureName.ACCOUNT_TRAVEL]) {
      menuItems.push({
        icon: "icons:travel",
        title: "Travel",
        description: "My Reservation, New Plans",
        url: "/account/travel",
      })
    }
    if (flags[FeatureName.ACCOUNT_FINANCIAL]) {
      menuItems.push({
        icon: "icons:financial",
        title: "Financial",
        description: "Credit Card, Identity Theft Protection",
        url: "/account/financial",
      })
    }

    return menuItems
  },
)

export const getAccountLinks = createSelector(getAccountDetails, getFeatureFlags, (accountDetails, flags) => {
  const menuLinkItems: AccountLink[] = []

  if (flags[FeatureName.ACCOUNT_EMAIL_PREFERENCES] && accountDetails) {
    menuLinkItems.push({
      path: "/enewsletters",
      text: "Manage Email Preferences",
      type: LinkType.ManageEmailPreferences,
    })
  }
  if (flags[FeatureName.ACCOUNT_LOGOUT] && accountDetails) {
    menuLinkItems.push({
      path: "/account/logout",
      text: "Log Out of AAA.com",
      type: LinkType.LogOut,
    })
  }

  return menuLinkItems
})

export const getAccountInformationMenu = createSelector(
  getAccountDetailsStatus,
  getClubId,
  getFeatureFlags,
  (accountStatus, clubId, flags) => {
    const menuItems: ListItem[] = []
    const showEditForm = accountStatus ? [AccountStatus.ACTIVE, AccountStatus.PENDING].includes(accountStatus) : false

    if (showEditForm && flags[FeatureName.ACCOUNT_INFORMATION_HOME_ADDRESS]) {
      menuItems.push({ title: "Home Address", url: "/account/information/address" })
    }

    if (showEditForm && flags[FeatureName.ACCOUNT_INFORMATION_PHONE_NUMBER]) {
      menuItems.push({ title: "Phone Number", url: "/account/information/phone" })
    }

    if (flags[FeatureName.ACCOUNT_INFORMATION_EMAIL]) {
      if (clubId === ClubApp.Hoosier) {
        menuItems.push({ title: "Update Email", url: "/account/edit", fullUrl: true })
      } else if (showEditForm) {
        menuItems.push({ title: "Update Email", url: "/account/information/email" })
      }
    }

    if (flags[FeatureName.ACCOUNT_INFORMATION_PASSWORD]) {
      if (clubId === ClubApp.Hoosier) {
        menuItems.push({ title: "Update Password", url: "/account/edit", fullUrl: true })
      } else if (showEditForm) {
        menuItems.push({ title: "Update Password", url: "/account/information/password" })
      }
    }

    return menuItems
  },
)

export const getAccountMembershipMenu = createSelector(
  getFeatureFlags,
  getClubOption,
  getAccountDetails,
  getAccountExpired,
  getAccountDetailsStatus,
  (flags, option, accountDetails, accountExpired, accountStatus) => {
    const menu: ListItem[] = []

    if (accountStatus === AccountStatus.CANCELLED) {
      return menu
    }

    if (flags[FeatureName.ACCOUNT_MEMBERSHIP_RENEW] && accountExpired) {
      menu.push({ title: "Renew Membership", url: "/account/membership/renew" })
    }

    if (flags[FeatureName.ACCOUNT_MEMBERSHIP_CARD]) {
      menu.push({ title: "Card Services", url: "/account/membership/card-service" })
    }

    if (flags[FeatureName.ACCOUNT_MEMBERSHIP_AUTO_RENEW]) {
      const pcsClubs = option.clubId === ClubApp.Shelby || option.clubId === ClubApp.Northampton

      if ((pcsClubs && accountDetails?.status !== AccountStatus.PENDING) || !pcsClubs) {
        menu.push({
          title: `Automatic Renewal (${accountDetails?.autoRenew ? "On" : "Off"})`,
          url: "/account/membership/auto-renew",
        })
      }
    }

    if (flags[FeatureName.ACCOUNT_MEMBERSHIP_CHANGE_PLAN]) {
      menu.push({ title: "Change You Membership Plan", url: "/account/membership/plans" })
    }

    if (flags[FeatureName.ACCOUNT_MEMBERSHIP_UPDATE] && accountExpired) {
      menu.push({ title: "Update You Membership Plan", url: "/account/membership/update" })
    }

    if (flags[FeatureName.ACCOUNT_MEMBERSHIP_ASSOCIATE_VIEW]) {
      let url = "/account/membership/associates"

      if (accountDetails?.associates?.length === 0 && option.clubId === ClubApp.Hoosier) {
        url = "/account/membership/associate/add"
      }

      menu.push({
        title: `Associate Members (${accountDetails?.associates?.length || 0})`,
        url,
      })
    }

    if (flags[FeatureName.ACCOUNT_MEMBERSHIP_CANCEL]) {
      menu.push({ title: `Cancel Membership`, url: "/account/membership/cancel" })
    }

    if (flags[FeatureName.ACCOUNT_MEMBERSHIP_GIFT]) {
      if (option.clubId === ClubApp.MidStates) {
        menu.push({ title: "Gift Membership", url: "/gift-membership", fullUrl: true })
      } else {
        menu.push({ title: "Gift Membership", url: "/account/membership/gift" })
      }
    }

    if (flags[FeatureName.ACCOUNT_MEMBERSHIP_HOME_ADDRESS]) {
      menu.push({
        title: "Home Address",
        url: "/account/information/address",
      })
    }

    if (flags[FeatureName.ACCOUNT_MEMBERSHIP_PHONE_NUMBER]) {
      menu.push({
        title: "Phone Number",
        url: "/account/information/phone",
      })
    }

    if (flags[FeatureName.ACCOUNT_MEMBERSHIP_EMAIL]) {
      menu.push({
        title: "Email",
        url: "/account/information/email",
      })
    }

    return menu
  },
)

export const getAccountMemberCards = createSelector(
  getAccountDetails,
  getAccountExpireDate,
  (accountDetails, expireDate) => {
    if (accountDetails) {
      const expiresDateFormatted = expireDate ? format(expireDate, "MM/dd/yyyy") : ""
      const primary = {
        membershipNumber: accountDetails.memberNumber,
        expirationDate: expiresDateFormatted,
        fullName: `${accountDetails.firstName} ${accountDetails.lastName}`,
        membershipLabel: accountDetails.code?.label || "",
        membershipUserId: "",
      }
      const associates = accountDetails.associates.map<ConfirmedMember>((associate) => {
        return {
          membershipNumber: associate.membershipNumber,
          expirationDate: expiresDateFormatted,
          fullName: `${associate.firstName} ${associate.lastName}`,
          membershipLabel: accountDetails.code?.label || "",
          membershipUserId: "",
        }
      })

      return [primary, ...associates]
    } else {
      return []
    }
  },
)

export const getAccountMemberCardByIndex = (index: number) =>
  createSelector(getAccountMemberCards, (accountMemberCards) => accountMemberCards[index])

export const getMemberCardTabs = createSelector(getAccountDetails, (accountDetails) => {
  if (accountDetails) {
    const primary: AvaTab = {
      label: `${accountDetails.firstName} ${accountDetails.lastName}`,
      id: 0,
      leftIcon: "idcard",
    }
    const associates = accountDetails.associates.map<AvaTab>((associate, index) => ({
      label: `${associate.firstName} ${associate.lastName}`,
      leftIcon: "idcard",
      id: index + 1,
    }))

    return [primary, ...associates]
  } else {
    return []
  }
})

export const showGiftInformation = createSelector(getClubOption, (option) =>
  [
    ClubApp.Hoosier,
    // ClubApp.MidStates
  ].includes(option.clubId),
)
export const getClubCardTitle = createSelector(getClubOption, (option) => `AAA ${option.name} Motor Club`)

export const getShowRenewBtn = createSelector(getAccountDetails, getClubOption, (accountDetails, option) => {
  if (accountDetails && [ClubApp.Hoosier, ClubApp.MidStates].includes(option.clubId)) {
    return accountDetails.status === AccountStatus.PENDING
  }

  if (accountDetails && (option.clubId === ClubApp.Northampton || option.clubId === ClubApp.Shelby)) {
    return accountDetails.autoRenew === false
  }

  return true
})
export const getButtonMembershipLabel = createSelector(getAccountDetailsStatus, (accountDetailsStatus) => {
  switch (accountDetailsStatus) {
    case AccountStatus.ACTIVE:
      return "no payment due"
    case AccountStatus.CANCELLED:
      return "Renew Membership"
    case AccountStatus.PENDING:
      return "Pay Balance"
    case AccountStatus.UNKNOWN:
    default:
      return "Renew Membership"
  }
})
export const activeRenewBtnUrl = createSelector(getAccountDetails, getClubOption, (accountDetails, option) => {
  if (accountDetails?.status === AccountStatus.PENDING) {
    if (accountDetails && (option.clubId === ClubApp.Northampton || option.clubId === ClubApp.Shelby)) {
      return "/account/membership/update"
    }

    return "/account/membership/renew"
  }

  return "/account/membership"
})

export const getMembershipPlanAction = (membershipType: string) =>
  createSelector(getMembershipCodes, getAccountDetailMembershipCode, (membershipCodes, currentMembershipCode) => {
    const planIndex = membershipCodes.findIndex((membershipCode) => membershipCode.membershipType === membershipType)
    const currentPlanIndex = membershipCodes.findIndex(
      (membershipCode) => membershipCode.membershipType === currentMembershipCode?.membershipType,
    )

    if (planIndex < currentPlanIndex) {
      return MembershipPlanAction.DOWNGRADE
    } else if (planIndex > currentPlanIndex) {
      return MembershipPlanAction.UPGRADE
    } else {
      return MembershipPlanAction.RENEW
    }
  })

export const getCurrentMembershipLevelOffer = createSelector(
  getPriceOfferLevels,
  getAccountDetailMembershipCode,
  (offerLevels, membershipCodes) => {
    if (membershipCodes && offerLevels) {
      const result = offerLevels.find(({ membershipType }) => membershipType === membershipCodes.membershipType)

      return result || null
    }

    return null
  },
)

export const getAllowChosenMembershipLevel = (action: MembershipPlanAction) =>
  createSelector(getClubId, getCurrentMembershipLevelOffer, (clubId, currentLevelOffer) => {
    if (clubId === ClubApp.Hoosier) {
      return currentLevelOffer?.rv
        ? action === MembershipPlanAction.RENEW
        : action === MembershipPlanAction.RENEW || action === MembershipPlanAction.UPGRADE
    } else {
      return action === MembershipPlanAction.RENEW || action === MembershipPlanAction.UPGRADE
    }
  })
export const getReservationLink = createSelector(getClubId, (clubId) => accountReservationLinks(clubId))
