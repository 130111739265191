import { Inject, Injectable } from "@angular/core"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../services/state"
import { RxState } from "@rx-angular/state"
import { PaymentCybersourceOperationExecuteEventPayload } from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { MembershipConnectSuiteRecostValidateJoinEventPayload } from "@aaa/interface-joinRenew-membership-membershipConnectSuite"

@Injectable({
  providedIn: "root"
})
export class MemberInfoToBillingService {

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
  ) {
  }

  convertMemberInfoToBillingInfo(membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload): PaymentCybersourceOperationExecuteEventPayload["executionData"]["billTo"] {
    const address = membershipPayload.membership.address
    const primary = membershipPayload.primary
    return {
      address1: address.address1 || "",
      address2: address.address2 || "",
      administrativeArea: address.StateProv || "",
      buildingNumber: "",
      country: "US",
      district: address.StateProv || "",
      email: primary?.email || "fallback@avagate.com",
      firstName: primary?.firstName || "",
      lastName: primary?.lastName || "",
      locality: address?.cityName || "",
      phoneNumber: primary?.cellPhone || "",
      postalCode: address.postalCode,
    }
  }

  copyMemberInfoToPaymentPayload(membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload): void {
    const billingInfo = this.convertMemberInfoToBillingInfo(membershipPayload)

  }

}
