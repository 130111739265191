<div *ngIf="errorMessages$ | async  as errorMessages" class="ava-column ava-gap-2 ava-membership-associates">
  <ng-container *ngFor="let associateGroup of formArray.controls; let i = index">
    <div [attr.id]="'membership-associate-' + i" [formGroup]="associateGroup" class="ava-column ava-gap-2">
      <div class="ava-row ava-space-between ava-align-center">
        <div class="ava-form-label">Associate {{totalAssociates + 1 + i}}</div>
        <button
          *ngIf="!associateGroup.controls.membershipNumber?.value"
          (click)="removeAssociates(i)"
          (keydown.enter)="removeAssociates(i)"
          class="membership-associate__btn--remove"
          type="button"
          nz-button
        >
          <span tabindex="0">
          <i [style.font-size]="'14px'" nz-icon nzTheme="outline" nzType="close"></i>
          Remove
          </span>
        </button>
      </div>
      <ava-form-input [errorMessages]="errorMessages.firstName" placeholder="First Name" formControlName="firstName" />
      <div class="ava-row ava-gap-1">
        <ava-form-input
          [errorMessages]="errorMessages.lastName"
          class="ava-flex"
          placeholder="Last Name"
          formControlName="lastName"
        />
        <ava-form-select
          placeholder="Suffix"
          [options]="nameSuffix"
          formControlName="suffix"
          class="ava-membership-associates__suffix"
        />
      </div>
      <div class="ava-row ava-gap-2 membership-associate__email">
        <ava-form-date
          [errorMessages]="errorMessages.birthday"
          placeholder="Date of Birth (mm/dd/yyyy)"
          formControlName="birthday"
          class="ava-flex"
        />
        <ava-form-input
          [errorMessages]="errorMessages.email"
          placeholder="Email"
          formControlName="email"
          class="ava-flex"
        />
      </div>
      <div class="ava-row" *ngIf="showAccidentMedicalPlan">
        <ava-form-checkbox-medical
          formControlName="accidentMedicalPlan"
          [accidentMedicalPlanPrice]="accidentMedicalPlanPrice"
          (changed)="accidentMedicalChanged.emit($event)"
        />
      </div>
    </div>
  </ng-container>
  <div
    tabindex="0"
    (click)="addAssociates()"
    (keydown.enter)="addAssociates()"
    [ngClass]="{
  'ava-membership-associates__add--disabled': formArray.invalid || (formArray.length + totalAssociates) >= maxAssociate,
  }"
    class="ava-membership-associates__add"
  >
    <i [style.font-size]="'36px'" nz-icon nzTheme="twotone" [hidden]="false" nzType="plus-circle"></i>
    <p>
      Add Additional Member(s) <br />
      *Must live in the same household.
    </p>
  </div>
</div>
