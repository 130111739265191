import { Component, Input } from "@angular/core"
import { AgentSchedulerModule } from "../modules/agent-scheduler/agent-scheduler.module"

@Component({
  selector: "ava-agent-scheduler-custom-element",
  template: `
    <ava-agent-scheduler [params]="params"></ava-agent-scheduler>
  `,
  standalone: true,
  imports: [
    AgentSchedulerModule
  ],
})
export class AgentSchedulerCustomElementComponent {
  @Input() params: string | undefined
}
