import { Component, inject } from "@angular/core"
import { Store } from "@ngrx/store"
import { NzIconService } from "ng-zorro-antd/icon"
import { getClubOption } from "@aaa/emember/store-membership"
import { NavigationEnd, Router } from "@angular/router"
import { AppStore } from "@aaa/emember/store-types"
import { DocumentRefService } from "../share/services/document-ref.service"
import { environment } from "../../../environments/environment"
import { selectUrl } from "../../store/router/router.selectors"

@Component({
  selector: "ava-emember",
  templateUrl: "./emember.component.html",
  styleUrls: ["./emember.component.scss"],
})
export class EmemberComponent {
  store = inject(Store<AppStore>)
  nzIconService = inject(NzIconService)
  document = inject(DocumentRefService)
  router = inject(Router)
  clubOptions$ = this.store.select(getClubOption)
  url$ = this.store.select(selectUrl)
  showSpinner = true

  constructor() {
    this.nzIconService.changeAssetsSource(environment.baseHref)
    this.initScrollToTop()
  }

  initScrollToTop() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.document.scrollToId("ava-emember")
      }

      if (!(event instanceof NavigationEnd)) {
        return
      }
    })
  }

  onDeactivate() {
    this.showSpinner = true
  }

  onActive() {
    this.showSpinner = false
  }
}
