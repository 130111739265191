import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { AccountDetails, ValidateSucceededResponseObject } from "../types/types"
import { MembershipRecostParams, MembershipUpdateForm, MembershipUpdateFormStep } from "./membership-update.models"
import { changedForm, setFormActiveStep } from "../../modules/share/ngrx-helpers"
import { RequestError } from "../generic-errors"

export const MembershipUpdate = createActionGroup({
  source: "Membership Update",
  events: {
    ...setFormActiveStep<MembershipUpdateFormStep>(),
    ...changedForm<MembershipUpdateForm>(),
    "Validate Form Step": props<{ validateStep: MembershipUpdateFormStep; params: MembershipRecostParams }>(),
    "Validate Associate": emptyProps(),
    "Validate Associate Succeeded": emptyProps(),
    "Validate Associate Failed": emptyProps(),
    "Recost Validate": props<{ params: MembershipRecostParams }>(),
    "Recost Validate Succeeded": props<ValidateSucceededResponseObject>(),
    "Recost Validate Failed": props<{ error: RequestError }>(),
    "Set Account Details": props<{ accountDetails: AccountDetails }>(),
    "Set Total Cost": props<{ totalCost: number }>(),
    "Changed Membership Type": props<{ membershipType: string }>(),
    Pay: props<{ params: MembershipRecostParams }>(),
    "Pay Succeeded": props<{ accountDetails?: AccountDetails }>(),
    "Pay Failed": props<{ error: RequestError }>(),
    Reset: emptyProps(),
    "Remove Error": emptyProps(),
  },
})
