import { AccountDetails } from "../types/types"
import { RequestStatus } from "../../../types/request-status"
import { FormControl, FormGroup } from "@angular/forms"
import { BillingForm } from "../../modules/share/billing-form"
import { FormGroupValue } from "../../modules/share/form.utils"
import { RequestError } from "../generic-errors"
import { PaymentForm } from "@aaa/emember/share/payment-form"

export enum MembershipRenewFormStep {
  PAYMENT = "payment",
  CONFIRMATION = "confirmation",
}

export interface MembershipRenewForm {
  billing: FormGroup<BillingForm>
  promoCode: FormControl<string>
}

export interface MembershipRenewState {
  formActiveStep: MembershipRenewFormStep
  formValues: FormGroupValue<MembershipRenewForm>
  validationRequestStatus: RequestStatus
  validationError: RequestError | null
  executionData: any
  executionRequestStatus: RequestStatus
  executionError: RequestError | null
  accountDetails: AccountDetails | null
}

export type MembershipRenewPayParams = {
  accountDetails: AccountDetails
  formValues: FormGroupValue<MembershipRenewForm>
  payment: { token: string; formValues: FormGroupValue<PaymentForm> }
  totalCost: number
  executionData: string
}

export type MembershipRenewRecostParams = {
  accountDetails: AccountDetails
  autoRenew: boolean
}
