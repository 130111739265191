import { Component, OnInit } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Router, RouterOutlet } from "@angular/router"
import { EmemberModule } from "../modules/emember/emember.module"

@Component({
  selector: "ava-emember-element",
  template: ` <ava-emember></ava-emember> `,
  standalone: true,
  imports: [CommonModule, RouterOutlet, EmemberModule],
})
export class AvaEmemberCustomElementComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.initialNavigation()
  }
}
