import { createReducer, on } from "@ngrx/store"
import { MembershipAutoRenewFormStep, MembershipAutoRenewState } from "./membership-auto-renew.models"
import { MembershipARActions } from "./membership-auto-renew.actions"
import { RequestStatus } from "../../../types/request-status"

export const initialStateMembershipAutoRenew: MembershipAutoRenewState = {
  formActiveStep: MembershipAutoRenewFormStep.ADD_CARD,
  formValues: {},
  removeCardRequestStatus: RequestStatus.UNSENT,
  removeCardError: null,
  addCardRequestStatus: RequestStatus.UNSENT,
  addCardError: null,
  updateCardRequestStatus: RequestStatus.UNSENT,
  updateCardError: null,
}

export const membershipAutoRenewReducer = createReducer(
  initialStateMembershipAutoRenew,
  on(MembershipARActions.removeCard, (state) => ({
    ...state,
    removeCardError: null,
    removeCardRequestStatus: RequestStatus.RUNNING,
  })),
  on(MembershipARActions.removeCardSucceeded, (state) => ({
    ...state,
    removeCardRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipARActions.removeCardFailed, (state, { error }) => ({
    ...state,
    removeCardError: error,
    removeCardRequestStatus: RequestStatus.FAILED,
  })),

  on(MembershipARActions.updateCard, (state) => ({
    ...state,
    updateCardError: null,
    updateCardRequestStatus: RequestStatus.RUNNING,
  })),
  on(MembershipARActions.updateCardSucceeded, (state) => ({
    ...state,
    updateCardRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipARActions.updateCardFailed, (state, { error }) => ({
    ...state,
    updateCardError: error,
    updateCardRequestStatus: RequestStatus.FAILED,
  })),

  on(MembershipARActions.addCard, (state) => ({
    ...state,
    addCardError: null,
    addCardRequestStatus: RequestStatus.RUNNING,
  })),
  on(MembershipARActions.addCardSucceeded, (state) => ({
    ...state,
    addCardRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipARActions.addCardFailed, (state, { error }) => ({
    ...state,
    addCardError: error,
    addCardRequestStatus: RequestStatus.FAILED,
  })),
  on(MembershipARActions.setFormActiveStep, (state, { activeStep }) => ({
    ...state,
    formActiveStep: activeStep,
  })),
  on(MembershipARActions.changedForm, (state, { values }) => ({ ...state, formValues: values })),
  on(MembershipARActions.removeError, (state) => ({ ...state, validationError: null, executionError: null })),
  on(MembershipARActions.reset, () => initialStateMembershipAutoRenew),
)
