import { convertMembershipOfferComponents } from "./m-membership"
import { MembershipLevelOffer, MembershipsLevel } from "@aaa/emember/store-price-offers"
import { getTowMiles } from "./get-tow-miles"
import { ClubApp } from "@aaa/emember/types"
import { MembershipOffering } from "@aaa/interface-joinRenew-membership-membershipM"

export function getMembershipOffering(offerings: MembershipOffering[]) {
  return offerings.reduce((acc, offering) => {
    if (offering?.$?.membershipType) {
      const prop = String(offering?.$?.membershipType)
      acc[prop] = convertMembershipOfferComponents(offering)
    }
    return acc
  }, {} as MembershipLevelOffer)
}

export function getMembershipLevels(offerings: MembershipOffering[], clubId: ClubApp) {
  return offerings.map<MembershipsLevel>((offering) => {
    const name = String(offering?.$?.offerName).split(" ").slice(0, -1).join(" ").trim()
    const rv = String(offering.$.offerName).indexOf("RV") !== -1
    const level = String(offering.$.offerName).split(" ")[0].trim().toUpperCase()
    const towMiles = Number(offering.$.towMiles || getTowMiles(clubId, level))

    return {
      membershipType: String(offering?.$?.membershipType),
      name: name,
      rv: rv,
      level: level,
      towMiles: towMiles,
    }
  })
}
