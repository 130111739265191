import { Component, Input } from "@angular/core"
import { NzUploadFile } from "ng-zorro-antd/upload"

@Component({
  selector: "ava-file-manager-preview",
  templateUrl: "./file-manager-preview.component.html"
})
export class FileManagerPreviewComponent {
  @Input() file: NzUploadFile | undefined

}
