<div style="display: flex; flex-wrap: nowrap; align-items: center">
  <ava-hoosier-checkbox [options]="options">
    <div style="display: flex; align-items: center">
      {{ options.content }}
    </div>
  </ava-hoosier-checkbox>
  <div
    nz-tooltip=""
    [nzTooltipTitle]="autoRenewTemplate"
    nzTooltipPlacement="bottom"
    style="padding: 10px"
    (click)="$event.preventDefault()"
  >
    <i nz-icon nzType="info-circle" nzTheme="fill" style="color: #25538F"></i>
  </div>
</div>

<ng-template #autoRenewTemplate>
  <div style="font-style: italic">
    When it's time to renew your membership,
    your annual dues will automatically be charged
    to the credit card approximately 20 days prior
    to your membership expiration date.
  </div>
</ng-template>
