import { Component, Inject, OnInit, Renderer2, RendererFactory2 } from "@angular/core"
import { LOCATION, WINDOW } from "@ng-web-apis/common"
import { CommonModule, DOCUMENT } from "@angular/common"
import { GLOBAL_RX_STATE, GlobalState } from "../services/state"
import { RxState } from "@rx-angular/state"
import { MultiblockModule } from "../modules/multiblocks/multiblock.module"

interface Link {
  href: string | null
  urlAndArgsArray: string[]
  url: string
  argsAndAnchorArray: string[]
  args: string
  anchor: string
  argsArray: string[]
  argsKeyValueArray: string[][]
  addUrlArgs: boolean
}

@Component({
  selector: "ava-multiblock-custom-element",
  templateUrl: "./multiblock.component.html",
  standalone: true,
  imports: [CommonModule, MultiblockModule],
})
export class MultiblockCustomElementComponent implements OnInit {
  renderer: Renderer2
  showLiveChatForMN = false

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(LOCATION)
    private location: Location,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  ngOnInit(): void {
    this.appendUrlArgsToLink()
    this.dynamicDiscountsLinkForCO()
    this.liveChatForSJ()
    this.liveChatForMN()
  }

  /**
   * Minneapolis chat bot
   *
   <script type="text/javascript" src=https://us-east.dx.dialpad.com/static/common/js/koopid-embed.min.js></script>
   <link href=https://us-east.dx.dialpad.com/static/common/css/koopid.css rel="stylesheet">
   <script>kpde.server = https://us-east.dx.dialpad.com;</script>

   <div style="display:inline;position:fixed;z-index:9999;bottom:0px;right:15px;"><img style="position:absolute; float:right; right:10px; bottom:20px; z-index:9999; height:75px; width: 75px;" id="kpd_koopidtag" data-kpdprovemail=6658418484740096@aaaminneapolis.com data-kpdguest="true" data-kpdembedded="true" data-kpdtag="VC Test_102697" src=https://us-east.dx.dialpad.com/kpd-static/providers/1827645/images/bot-icon.png></div>
   *
   * changing position:absolute to position:fixed makes it visible on drupal pages
   */
  private liveChatForMN(): void {
    switch (this.globalState.get("environment", "firebaseConfig", "projectId")) {
      case "aaa-minneapolis-1":
      case "aaa-minneapolis-2": {
        this.showLiveChatForMN = true
        const scriptSrc: string = "https://us-east.dx.dialpad.com/static/common/js/koopid-embed.min.js"
        const cssSrc: string = "https://us-east.dx.dialpad.com/static/common/css/koopid.css"
        this.addCssToHead(cssSrc)
        this.addJsToHead(scriptSrc).onload = () => {
          if (this.window.kpde) {
            this.window.kpde.server = "https://us-east.dx.dialpad.com"
          }
        }
      }
    }
  }

  private liveChatForSJ(): void {
    switch (this.globalState.get("environment", "firebaseConfig", "projectId")) {
      case "aaa-southjersey-1":
      case "aaa-southjersey-2": {
        const scriptSrc = "https://aaasj-stage.quiq-api.com/app/webchat/index.js"
        this.addJsToHead(scriptSrc).onload = () => {
          window.Quiq({ contactPoint: "membership" })
        }
      }
    }
  }

  private dynamicDiscountsLinkForCO(): void {
    const anonymousLinkURL = "https://www.aaa.com/partnerRedirect"
    const anonymousLinkArg0 = "PID=1255"
    const memberLinkURL = "https://www.aaa.com/partnerRedirectWithLogin"

    if (
      this.globalState.get("windowMetaData", "clubId") === "006" &&
      parseInt(this.globalState.get("windowMetaData", "user", "id")) > 0
    ) {
      const linkElements: HTMLElement[] = [].slice.call(document.querySelectorAll("a"))
      for (const linkElement of linkElements) {
        const link: Link = this.parseLinkElement(linkElement)
        if (link.url === anonymousLinkURL && link.argsArray[0] === anonymousLinkArg0) {
          const memberLinkHref = link.href?.replace(anonymousLinkURL, memberLinkURL)
          if (memberLinkHref) {
            linkElement.setAttribute("href", memberLinkHref)
          }
        }
      }
    }
  }

  private appendUrlArgsToLink(): void {
    const linkElements: HTMLElement[] = [].slice.call(document.querySelectorAll("a"))
    for (const linkElement of linkElements) {
      const link: Link = this.parseLinkElement(linkElement)
      for (const arg of link.argsKeyValueArray) {
        if (arg[0] === "URLARGS") {
          link.addUrlArgs = true
          link.argsArray.splice(link.argsKeyValueArray.indexOf(arg), 1)
        }
      }

      if (link.addUrlArgs) {
        const urlArgs = this.location.search.toLowerCase().replace("?", "")?.split("&")
        for (const arg of urlArgs) {
          link.argsArray.push(arg)
        }
        const args = link.argsArray.length ? "?" + link.argsArray.join("&") : ""
        const anchor = link.anchor ? "#" + link.anchor : ""
        linkElement.setAttribute("href", link.url + args + anchor)
      }
    }
  }

  private parseLinkElement(linkElement: HTMLElement): Link {
    const link: Link = {
      href: "",
      urlAndArgsArray: [],
      url: "",
      argsAndAnchorArray: [],
      args: "",
      anchor: "",
      argsArray: [],
      argsKeyValueArray: [],
      addUrlArgs: false,
    }
    link.href = linkElement.getAttribute("href")
    link.urlAndArgsArray = link.href?.split("?") || []
    if (link.urlAndArgsArray.length) {
      link.url = link.urlAndArgsArray[0]
      if (link.urlAndArgsArray[1]) {
        link.argsAndAnchorArray = link.urlAndArgsArray[1].split("#")
        if (link.argsAndAnchorArray?.length) {
          link.args = link.argsAndAnchorArray[0]
          if (link.argsAndAnchorArray[1]) {
            link.anchor = link.argsAndAnchorArray[1]
          }
          link.argsArray = link.args.split("&")
          for (const arg of link.argsArray) {
            const keyValueArray = arg.split("=")
            link.argsKeyValueArray.push([keyValueArray[0], keyValueArray[1]])
          }
        }
      }
    }
    return link
  }

  /*
  enableAccessiBePlugin(): void {
    this.window.acsbJS.init({
      statementLink: "",
      footerHtml: "",
      hideMobile: false,
      hideTrigger: false,
      disableBgProcess: false,
      language: "en",
      position: "right",
      leadColor: "#146FF8",
      triggerColor: "#146FF8",
      triggerRadius: "50%",
      triggerPositionX: "right",
      triggerPositionY: "bottom",
      triggerIcon: "people",
      triggerSize: "bottom",
      triggerOffsetX: 20,
      triggerOffsetY: 20,
      mobile: {
        triggerSize: "small",
        triggerPositionX: "right",
        triggerPositionY: "bottom",
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        triggerRadius: "20"
      }
    })
  }
*/

  addJsToHead(src: string): HTMLScriptElement {
    const element = this.renderer.createElement("script")
    element.type = "text/javascript"
    element.src = src
    const head = this.document.querySelector("head")
    this.renderer.appendChild(head, element)
    return element
  }

  addCssToHead(src: string): void {
    const element = this.renderer.createElement("link")
    element.type = "text/css"
    element.rel = "stylesheet"
    element.href = src

    const head = this.document.querySelector("head")
    this.renderer.appendChild(head, element)
  }
}
