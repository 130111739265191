import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { map } from "rxjs/operators"
import { FeatureFlagsAction } from "./feature-flags.actions"
import { MembershipActions } from "@aaa/emember/store-membership"
import { getFeatureFlagStateByClubId } from "./feature-flags.state"

@Injectable({ providedIn: "root" })
export class FeatureFlagsEffects {
  actions$ = inject(Actions)
  initializeFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipActions.initializeSucceeded),
      map(({ env }) => {
        const flags = getFeatureFlagStateByClubId(env)
        const flagsMap = flags.reduce((acc, flag) => ({ ...acc, [flag.name]: flag }), {})

        return FeatureFlagsAction.loaded({ flags: flagsMap })
      }),
    ),
  )
}
