import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { MetaWindow } from "../../interfaces/window"
import { Video, VideoOptions, VideoService, VideoStyles } from "./service"
import { TokenService } from "../../services/token"
import { Block, BlockService } from "../../block/services/block"
import { GLOBAL_RX_STATE, GlobalState, StateService } from "../../services/state"
import { LinkService } from "../../services/link"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { DataReadService } from "../../services/data-read"
import { RxState } from "@rx-angular/state"
import { BlockState } from "../../block/services/state"

@Component({
  selector: "ava-video-view",
  templateUrl: "./view.html"
})
export class VideoViewComponent implements OnInit, OnDestroy {
  // @Input() blockState: RxState<BlockState> | undefined
  blockState: RxState<BlockState> | undefined  // b$: Observable<BlockState>
  // g$ = this.globalState.select()

  @Input() stateId: string | undefined
  @Input() blockField: Video | undefined
  @Input() rowId: string | undefined
  @Input() formArrayPath: (string | number)[] = []
  @Input() index: number | undefined
  @Input() options: VideoOptions | undefined
  @Input() stylesFunction: ((block: Block, width: number, columnIndex?: number) => VideoStyles) | undefined
  fieldName: string | undefined
  private subscription: { [key: string]: Subscription } = {}
  // initialized: boolean
  safeUrl: SafeResourceUrl | undefined
  styles: VideoStyles | undefined

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private dataReadService: DataReadService,
    private videoService: VideoService,
    private linkService: LinkService,
    private tokenService: TokenService,
    public stateService: StateService,
    // private router: Router,
    private domWindow: Window,
    private sanitizer: DomSanitizer,
    private blockService: BlockService,
  ) {
  }

  ngOnInit(): void {
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId]
    }
    if (this.blockState && typeof this.stylesFunction === "function") {
      const blockValue = this.blockState.get("blockValue")
      const blockWidth = this.blockState.get("blockWidth")
      if (blockValue && blockWidth) {
        this.styles = this.stylesFunction(blockValue, blockWidth, this.index)
      }
    }

/*
    if (this.rowId) {
      this.fieldName = this.rowId
    }
    if (this.formArrayPath && this.index !== undefined) {
      this.fieldName = this.formArrayPath.join("-") + "-" + this.index.toString()
    }

    if (this.rowId) {
      this.blockField = this.block.rows[this.rowId] as Video
    }
    this.setSafeUrl(this.blockField)
    this.subscription["adminMode"] = this.stateService.adminMode$
      .subscribe(adminMode => {
        if (adminMode && this.state?.form) {
          this.setSafeUrl(this.form.value)
          if (!this.initialized) {
            this.initialized = true
            this.subscription["form"] = this.form.valueChanges
              .subscribe(changes => {
                if (this.adminMode) {
                  this.setSafeUrl(changes)
                }
              })
          }
        }
        if (!adminMode) {
          this.setSafeUrl(this.blockField)
        }
      })
*/
  }

  ngOnDestroy(): void {
    for (const key in this.subscription) {
      this.subscription[key].unsubscribe()
    }
  }

  get window(): MetaWindow {
    return this.domWindow as unknown as MetaWindow
  }

}
