import { ConnectSuite } from "../../connect-suite.type"
import { MembershipOfferItem } from "./types"
import { convertMembershipOfferComponents } from "./connect-suite-membership"
import { MembershipLevelOffer, MembershipsLevel } from "@aaa/emember/store-price-offers"
import { ConnectSuiteMembershipLevel } from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import capitalize from "lodash/capitalize"
import { getTowMiles } from "./get-tow-miles"
import { ClubApp } from "@aaa/emember/types"

export function getMembershipOffering(res: ConnectSuite.PricePreviewsResponseObject) {
  const offerings = res.response.promoNoEnrollResponse.data
  const enrollFee = Number(res.response.enrollFee)
  const levelOffers = offerings.membershipOffering.reduce((acc, offering) => {
    const membershipType = String(offering?.attributes.membershipType)
    const offerItems: MembershipOfferItem[] = []

    if (enrollFee) {
      offerItems.push({
        offering: "optionalPrimary",
        code: "waiveEnrollFee",
        description: "One-Time Enrollment Fee",
        amount: res.response.enrollFee,
        selectedByDefault: true,
        conditions: [],
      })
    }

    if (offering?.attributes.membershipType) {
      offerItems.push(...convertMembershipOfferComponents(offering))
    }

    acc[membershipType] = offerItems
    return acc
  }, {} as MembershipLevelOffer)

  return levelOffers
}

export function getMembershipLevels(res: ConnectSuite.PricePreviewsResponseObject, clubApp: ClubApp) {
  const offerings = res.response.promoNoEnrollResponse.data
  const levels = offerings.membershipOffering.map<MembershipsLevel>((m) => {
    const offerName = String(m.attributes.offerName).split(" ")
    const name = offerName
      .map((name) =>
        name === "BASIC" && clubApp === ClubApp.Hoosier
          ? capitalize(ConnectSuiteMembershipLevel.CLASSIC)
          : capitalize(name),
      )
      .join(" ")

    const level = offerName.map((name) =>
      name === "BASIC" ? ConnectSuiteMembershipLevel.CLASSIC.toUpperCase() : name.toUpperCase(),
    )[0]

    const rv = String(m.attributes.offerName).indexOf("RV") !== -1

    return {
      membershipType: m.attributes.membershipType,
      rv: rv,
      level: level,
      name: name,
      towMiles: getTowMiles(ClubApp.Hoosier, level),
    }
  })

  return levels
}
