<ng-container *ngIf="globalState$ | async as g">
  <ng-container *ngIf="blockState$ | async as b">
    <div
      *ngIf="(blockVisible && b.blockValue?.options?.visibility?.[g.screenSize]) || g.adminMode"
    >
      <ava-multiblock-view
        [blockId]="blockId"
        [stateId]="stateId"
      ></ava-multiblock-view>
    </div>
  </ng-container>
</ng-container>
