import { createReducer, on } from "@ngrx/store"
import { MembershipUpgradePlanFormStep, MembershipUpgradePlanState } from "./membership-upgrade-plan.models"
import { MembershipUpgradePlan } from "./membership-upgrade-plan.actions"
import { RequestStatus } from "../../../types/request-status"

export const initialStateMembershipUpgradePlan: MembershipUpgradePlanState = {
  formActiveStep: MembershipUpgradePlanFormStep.PAYMENT,
  formValues: {},
  validationRequestStatus: RequestStatus.UNSENT,
  validationError: null,
  executionData: null,
  executionRequestStatus: RequestStatus.UNSENT,
  executionError: null,
  accountDetails: null,
}

export const membershipUpgradePlanReducer = createReducer(
  initialStateMembershipUpgradePlan,
  on(MembershipUpgradePlan.recostValidate, (state) => ({
    ...state,
    validationRequestStatus: RequestStatus.RUNNING,
    validationError: null,
    executionData: null,
  })),
  on(MembershipUpgradePlan.recostValidateSucceeded, (state, { executionData }) => ({
    ...state,
    executionData,
    validationError: null,
    validationRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipUpgradePlan.recostValidateFailed, (state, { error }) => ({
    ...state,
    validationError: error,
    validationRequestStatus: RequestStatus.FAILED,
    executionData: "",
  })),
  on(MembershipUpgradePlan.pay, (state) => ({
    ...state,
    executionRequestStatus: RequestStatus.RUNNING,
    executionError: null,
  })),
  on(MembershipUpgradePlan.paySucceeded, (state) => ({
    ...state,
    executionError: null,
    executionRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipUpgradePlan.payFailed, (state, { error }) => ({
    ...state,
    executionError: error,
    executionRequestStatus: RequestStatus.FAILED,
  })),
  on(MembershipUpgradePlan.setFormActiveStep, (state, { activeStep }) => ({ ...state, formActiveStep: activeStep })),
  on(MembershipUpgradePlan.updateLevelForm, (state, { level, rv }) => ({
    ...state,
    formValues: { ...state.formValues, level, rv },
  })),
  on(MembershipUpgradePlan.updateBillingForm, (state, { billing }) => ({
    ...state,
    formValues: { ...state.formValues, billing },
  })),
  on(MembershipUpgradePlan.changedForm, (state, { values }) => ({
    ...state,
    formValues: { ...state.formValues, ...values },
  })),
  on(MembershipUpgradePlan.removeError, (state) => ({ ...state, validationError: null, executionError: null })),
  on(MembershipUpgradePlan.setAccountDetails, (state, { accountDetails }) => ({ ...state, accountDetails })),
  on(MembershipUpgradePlan.reset, () => initialStateMembershipUpgradePlan),
)
