import { Injectable } from "@angular/core"
import { DataReadService } from "./data-read"
import { StateService } from "./state"
import { FormService } from "./form"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { RevisionWriteService } from "./revision-write"
import { Block } from "../block/services/block"

/**
 * All blocks are stored in Firestore as documents.
 * The dataReadService subscribes to all blocks for the page and overwrites the block.status.session key with the current "session" value.
 * Each block-component listens to its block, keeping the page synchronized with Firestore.
 * All blacks have an associated "form" which is synchronized with a "revision" (a copy) of that form.
 * The "form" is generated client-side and stored as an Angular reactive FormGroup.
 * The "revision" is stored in Firebase as a document in a sub-collection of its block document.
 * The "session" is a unique key generated when the browser session begins (e.g. each tab is a new session).
 *
 * When an admin loads a page of blocks we do the following for each block:
 * 1. if its revision exists, generate the form from the revision, assign our session to the form.
 * 2. if its revision does not exist, generate the form from the block, then write to the revision.
 * 3. continuously monitor a diff between the form and the block, showing indicators (save and cancel) when not in sync.
 * 4. when the "form-subscription" emits changes (write to revision in Firestore only if our own changes, based on session value):
 *    if the change is from our session, write to the revision.
 *    if the change is not from our session, assign our session to the form quietly (do not trigger the "form-subscription")
 *    TODO: detect if there are no changes, even if the session is different (is causing infinite loop when 2 sessions are editing the same block)
 * 5. when the "revision-subscription" emits changes (do not write to revision in Firestore):
 *    if the change is from our session, do nothing.
 *    if the change is not from our session, replace our form with the revision using our current session value.
 *
 */

@Injectable({
  providedIn: "root"
})
export class RevisionReadService {
/*
  revisionState: {
    [key: string]: {
      editsSub?: Subscription
      formSub?: Subscription
      initialized?: boolean
      newForm?: (block: Block) => BlockState
    }
  } = {}
*/

  constructor(
    private afs: AngularFirestore,
    private dataReadService: DataReadService,
    private formService: FormService,
    private revisionWriteService: RevisionWriteService,
    private stateService: StateService,
  ) {
  }

  setupListenersFromRows(stateId: string, block: Block): void {
/*
    if (block.columns) {
      for (const [columnIndex, column] of block.columns.entries()) {
        if (column.rows === undefined) {
          console.log(block)
        }
        if (column.rows) {
          for (const [rowIndex, rowId] of column.rows.entries()) {
            if (block.rows === undefined) {
              console.log(block.id)
            }
            if (block.rows?.[rowId] === undefined) {
              console.log(rowId)
            }
            if (block.rows?.[rowId]?.type === "blockRef") {
              this.stateService.setState(rowId, {
                blockId: block.rows[rowId].blockId,
                parentColumnIndex: columnIndex,
                parentRowIndex: rowIndex,
                parentStateId: stateId
              })
              this.setupListeners(rowId)
            }
          }
        }
      }
    }
*/
  }

/*
  setupListeners(stateId: string): void {
    const state = this.stateService.state[stateId]
    const blockId = state ? state.blockId || stateId : null
    const block = this.dataReadService.blocks[blockId] as Block
    if (block) {
      // this.setupListenersFromRows(block)

      if (this.revisionState[stateId] === undefined) {
        this.revisionState[stateId] = {
          editsSub: this.dataReadService.editRevision(blockId)
            .subscribe(document => {
              const blockRevision = document.payload.data() as Block
              /!**
               * initialize editing revision document
               *!/
              if (!blockRevision) {
                console.log("blockRevision does not exist, create one")
                console.log(block)
                const blockCopy: Block = JSON.parse(JSON.stringify(block))
                blockCopy.status.session = this.stateService.session
                this.revisionWriteService.updateEditRevisions([blockCopy])
                return
              }

              this.setupListenersFromRows(stateId, blockRevision)
              // this.stateService.setStateFromRows(blockRevision)

              /!**
               * delete block revision if it is out of date
               *!/

              // if (blockRevision["pathnames"] !== undefined) {
              //   this.dataReadService.deleteBlockEditingDocument(blockId)
              //   return
              // }

              // if (blockRevision.blockType === "column" && blockRevision["instanceOptions"] === undefined) {
              //   this.dataReadService.deleteBlockEditingDocument(blockId)
              //   return
              // }

              // if (blockRevision.blockType === "multiblock") {
              //   this.dataReadService.deleteBlockEditingDocument(blockId)
              //   return
              // }

              if (blockRevision["fields"] !== undefined && (blockRevision.blockType === "multiblock" || blockRevision.blockType === "container")) {
                this.revisionWriteService.deleteEditRevision(blockId)
                return
              }

              if (blockRevision["childBlockIds"] !== undefined) {
                this.revisionWriteService.deleteEditRevision(blockId)
                return
              }

              if (blockRevision["parents"] !== undefined) {
                this.revisionWriteService.deleteEditRevision(blockId)
                return
              }

              if (blockRevision.blockTemplate === undefined) {
                this.revisionWriteService.deleteEditRevision(blockId)
                return
              }

              if (blockRevision.blockShared === undefined) {
                this.revisionWriteService.deleteEditRevision(blockId)
                return
              }

              if (blockRevision.columns === undefined) {
                this.revisionWriteService.deleteEditRevision(blockId)
                return
              }

              if (blockRevision.blockType === "multiblock" || blockRevision.blockType === "container") {

                for (const column of blockRevision.columns) {
                  if (column.width === undefined) {
                    this.revisionWriteService.deleteEditRevision(blockId)
                    return
                  }
                  if (column.width["type"] === undefined) {
                    this.revisionWriteService.deleteEditRevision(blockId)
                    return
                  }
                  if (column.width["px"] === undefined) {
                    this.revisionWriteService.deleteEditRevision(blockId)
                    return
                  }
                }

                if (blockRevision["options"] && blockRevision["options"].breakpointsEnabled === undefined) {
                  this.revisionWriteService.deleteEditRevision(blockId)
                  return
                }

                if (blockRevision["options"] && blockRevision["options"].alignment === undefined) {
                  this.revisionWriteService.deleteEditRevision(blockId)
                  return
                }

                if (blockRevision.rows) {
                  for (const rowId in blockRevision.rows) {
                    if (blockRevision.rows[rowId].label === undefined) {
                      this.revisionWriteService.deleteEditRevision(blockId)
                      return
                    }
                  }
                }

                if (blockRevision.columns) {
                  for (const column of blockRevision.columns) {
                    if (column.label === undefined) {
                      this.revisionWriteService.deleteEditRevision(blockId)
                      return
                    }
                  }
                }
              }

              if (blockRevision.blockType === "multiblock") {
                if (blockRevision["template"].type === undefined) {
                  this.revisionWriteService.deleteEditRevision(blockId)
                  return
                }
              }


              /!**
               * END delete block revision if it is out of date
               *!/


              blockRevision["status"]["session"] = blockRevision["status"]["session"] || this.stateService.session

              if (!stateId) {
                console.log("stateId is missing")
                return
              }
              if (this.revisionState[stateId] === undefined) {
                console.log("this.revisionState[stateId] is missing: " + stateId)
                // this.setupListeners(stateId)
                return
              }
              /!**
               * load form from the edit revision
               *!/
              if (!this.revisionState[stateId].initialized) {
                this.formListener(stateId, blockRevision)
                this.revisionState[stateId].initialized = true
              }
              /!**
               * reload the form if incoming change is not from this session
               *!/
              if (this.revisionState[stateId].initialized && blockRevision.status.session !== this.stateService.session) {
                this.reloadForm(stateId, blockRevision)
              }
            })
        }
      }
    }
  }
*/

/*
  destroyListeners(stateId: string): void {
    this.revisionState[stateId]?.editsSub?.unsubscribe()
    this.revisionState[stateId]?.formSub?.unsubscribe()
    this.revisionState[stateId] = undefined
    this.stateService.resetState(stateId)
  }
*/

/*
  reloadForm(stateId: string, blockRevision: Block): void {
    this.revisionState[stateId].formSub?.unsubscribe()
    this.revisionState[stateId].formSub = undefined
    this.stateService.resetState(stateId, "form")
    blockRevision["status"]["session"] = this.stateService.session
    this.formListener(stateId, blockRevision)
  }
*/

/*
  formListener(stateId: string, blockRevision: Block): void {
    this.formService.createStateForm(stateId, blockRevision)
    // console.log(blockRevision.blockType)
    // console.log(blockRevision)
    if (this.revisionState[stateId].formSub === undefined) {
      // console.log(blockRevision.blockType)
      this.revisionState[stateId].formSub = this.stateService.state[stateId].form.valueChanges
        .pipe(
          debounceTime(500)
        )
        .subscribe(blockValue => {
          // console.log("form changes")
          // console.log(blockValue.blockType)
          // console.log(blockValue)
          // this.setupListenersFromRows(blockValue)
          /!**
           * write to editing revision if changes are our own changes
           *!/
          if (blockValue.status.session === this.stateService.session) {
            console.log("sessions match - write formValue changes to editRevision")
            this.revisionWriteService.updateEditRevisions([blockValue])
          }
          /!**
           * quietly replace session id with this.session if changes are from another session
           *!/
          if (blockValue.status.session !== this.stateService.session) {
            this.stateService.state[stateId].form.patchValue({ status: { session: this.stateService.session } }, { emitEvent: false })
          }
          /!**
           * refresh editing state (save and cancel icons)
           *!/
          this.stateService.compareBlockState(stateId)
        })
    }
  }
*/

}
