import {
  connectSuiteMembershipCodes,
  ConnectSuiteMembershipLevel,
  MembershipConnectSuiteMemberLookupResponse,
} from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import {
  getConnectsuiteAccountAddress,
  getConnectsuiteAccountPhone,
  getConnectsuiteMembershipAssociates,
} from "./connectsuite-utils"
import { getMembershipStatus } from "./utils/get-membership-status"
import { Operation, OperationEventResponse } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  AccountAddress,
  AccountDetails,
  AccountIncludedAssociateOffers,
  AccountDetailsPaymentSummary,
  AccountIncludedPrimaryOffers,
  AccountPhone,
  MembershipAssociateDetails,
  SessionDocResponseObject,
  ValidateResponse,
} from "./types/types"
import { parse } from "date-fns"
import { z } from "zod"
import { AccountStatus } from "@aaa/emember/types"
import { AccountInfoSummary } from "@aaa/emember/store-summary"
import { MembershipOfferItem } from "./price-offers/helpers/types"
import capitalize from "lodash/capitalize"

export namespace ConnectSuite {
  export const membershipCodes = connectSuiteMembershipCodes
  // Todo: Need to move in interfaces into functions all this types

  export const MembershipOfferingDuesComponentSchema = z.object({
    rule: z.object({
      associateCount: z.object({
        attributes: z.object({
          value: z.string(), //"2",
          operand: z.string(), //"gt"
        }),
      }),
    }),
    attributes: z.object({
      componentAmount: z.string(),
      componentCode: z.string(), //"BS",
      componentCurrency: z.string(), // "USD",
      requiresCreditCard: z.string(), //"YES",
      autoRenew: z.string(), //"NO",
      componentDescription: z.string(), //"Associate Member",
      pricingType: z.string(), //"G"
    }),
  })

  export type MembershipOfferingDuesComponent = z.infer<typeof MembershipOfferingDuesComponentSchema>

  export const MembershipOfferingAssociateOptionalSchema = z.object({
    duesComponent: z.array(MembershipOfferingDuesComponentSchema),
    attributes: z.object({
      OfferId: z.string(), //"1",
      OfferDescription: z.string(), //"Enroll in payment plan?  A monthly service charge is required."
      offerName: z.string().optional(), //"Enroll in payment plan?  A monthly service charge is required."
      selectOptionBydefault: z.string().optional(), //"N",
      membershipType: z.string().optional(), //"Enroll in payment plan?  A monthly service charge is required."
    }),
  })

  export type MembershipOfferingAssociateOptional = z.infer<typeof MembershipOfferingAssociateOptionalSchema>

  export const MembershipOfferingOptionalSchema = z.object({
    duesComponent: z.object({
      attributes: z.object({
        componentAmount: z.string(), //"1.00",
        componentCode: z.string(), //"PPM",
        componentCurrency: z.string(), //"USD",
        requiresCreditCard: z.string(), //"Y",
        autoRenew: z.string(), //"YES",
        componentDescription: z.string(), //"Payment Plan Service Charge",
        pricingType: z.string(), //"G"
      }),
    }),
    attributes: z.object({
      OfferId: z.string(), //"1",
      selectOptionBydefault: z.string().optional(), //"N",
      OfferDescription: z.string(), //"Enroll in payment plan?  A monthly service charge is required."
    }),
  })

  export const MembershipDuesComponentAttributes = z.object({
    attributes: z.object({
      componentAmount: z.string(),
      componentCode: z.string(),
      requiresCreditCard: z.string(),
      componentDescription: z.string(),
      componentCurrency: z.string(),
      autoRenew: z.string(),
      pricingType: z.string(),
    }),
  })
  export type MembershipDuesComponentAttributes = z.infer<typeof MembershipDuesComponentAttributes>

  export type MembershipOfferingOptional = z.infer<typeof MembershipOfferingOptionalSchema>

  export const MembershipOfferingSchema = z.object({
    associateOfferings: z.array(MembershipOfferingAssociateOptionalSchema),
    duesComponent: MembershipDuesComponentAttributes,
    attributes: z.object({
      membershipType: z.string(),
      offerName: z.string(),
      OfferId: z.string(),
      offerDescription: z.string(),
    }),
    optionalOfferings: z.array(MembershipOfferingOptionalSchema), // don't use OptionalOfferings[]
  })

  export type MembershipOffering = z.infer<typeof MembershipOfferingSchema>

  export const PricePreviewSchema = z.object({
    error: z
      .object({
        responseText: z.string(), //"The coupon code has already been redeemed.",
        version: z.string(), // "1.3",
        responseCode: z.string(), // "203",
        waiveEnrollFee: z.string(), // "false"
      })
      .optional(),
    prices: z.preprocess(
      (obj) => (Array.isArray(obj) ? obj : []),
      z.array(
        z.object({
          members: z.number(),
          price: z.number().default(0),
        }),
      ),
    ),
    level: z.nativeEnum(ConnectSuiteMembershipLevel),
    rv: z.boolean().default(false),
    primaryFee: z.preprocess((val) => (Number.isInteger(val) ? val : 0), z.number()),
    associateFee: z.preprocess((val) => (Number.isInteger(val) ? val : 0), z.number()),
  })

  export const PricePreviewResponseSchema = z
    .object({
      promoNoEnrollPrices: z.array(PricePreviewSchema),
      noPromoNoEnrollPrices: z.array(PricePreviewSchema),
      promoNoEnrollResponse: z.object({
        data: z.object({
          membershipOffering: z.array(MembershipOfferingSchema),
          attributes: z.object({
            promotionalCode: z.string().optional(), //"WOWSALE23W",
            waiveEnrollFee: z.string(), //"true",
            responseText: z.string(), //"Success",
            version: z.string(), //"1.3",
            responseCode: z.string(), //"000",
            couponCode: z.string().optional(),
          }),
        }),
        error: z
          .object({
            waiveEnrollFee: z.string(), // "false"
            responseText: z.string(),
            version: z.string(), //
            responseCode: z.string(), //"211"
          })
          .optional(),
      }),
      enrollFee: z.number(),
      promoCode: z.string().optional(),
    })
    .passthrough()

  export type PricePreviewResponse = z.infer<typeof PricePreviewResponseSchema>

  export interface ExecuteMembershipResponse {
    ok: boolean
    operation: Operation
  }
  export interface ExecuteMembershipError {
    balance: string // "0.0",
    responseText: string // "We were unable to locate your membership using the number provided.",
    version: string // "1.3",
    responseCode: string // "022"
  }
  export interface ExecuteMembershipResponseObject extends SessionDocResponseObject {
    error: ExecuteMembershipError
    // meta: SessionDocMeta
    response: ExecuteMembershipResponse
  }

  export interface MemberLookupResponse extends MembershipConnectSuiteMemberLookupResponse {
    memberInfo: MemberInfo
  }

  export interface MemberLookupResponseObject extends SessionDocResponseObject {
    error: ExecuteMembershipError
    // meta: SessionDocMeta
    response: MemberLookupResponse
  }

  export interface MemberInfoAttributes {
    balance: string // useful within memberLookup
    responseCode: string
    responseText: string
    version: string
    clubId?: string
    isAutoRenew?: string
    waiveEnrollFee?: string
    couponCode?: string
    totalCost?: string // as of 12/15 only use for join, or use membership.costSummary.totalCost
    errorCode?: number
    error?: string
    promotionalCode?: string
  }

  export interface MemberInfo {
    membershipOffering: MembershipOffering[]
    attributes: MemberInfoAttributes
    membership: Membership
    payment: {
      contract: any
      transaction: {
        approvalCode: string
        avsMatch: string
        code: string
        cvvMatch: string
        txnId: string
      }
    }
  }
  export interface MemberBase {
    duesComponent: {
      attributes: {
        autoRenew: string // "NO", hypothetically >> are we allowed to add autoRenew (NO means they are already on autoRenew)
        componentAmount: string // "57.00",
        componentCode: string // "BS",
        componentCurrency: string // "USD",
        componentDescription: string // "Membership Dues: First1663471903 Last1663471903"
        pricingType: string // "G",
        requiresCreditCard: string // "YES",
      }
    }
  }
  export interface MemberAttributes {
    aaaJoinDate: string
    clubJoinDate: string
    dob: string
    email?: string
    firstName: string
    lastName: string
    membershipNumber: string
    membershipType: string
    middleIntial: string
    nameSuffix: string
    sex: string
    title: string
  }
  export interface AssociateMemberAttributes extends MemberAttributes {
    relationship: string
    removeAssociate: string
  }
  export interface AssociateMember extends MemberBase {
    attributes: AssociateMemberAttributes
  }
  export interface PrimaryMemberAttributes extends MemberAttributes {
    cellPhone: string
    homePhone: string
    businessPhone: string
  }
  export interface PrimaryMember extends MemberBase {
    attributes: PrimaryMemberAttributes
  }
  export interface Payment {
    attributes?: {
      autoRenew: string // "Y",
      paymentType: string // "CC"
    }
    account: {
      accountHolder: {
        attributes: {
          firstName: string
          lastName: string
          middleInitial: string
        }
      } | null
      address: {
        attributes: {
          cityName: string
          address2: string
          StateProv: string
          address1: string
          postalCode: string
        }
      } | null
      attributes?: {
        expMonth: string // "3",
        expYear: string // "2023",
        type: string // "Visa",
        cardNumber: string // "************1111"
      }
    }
    contract: string | null
    transaction?: {
      avsMatch: string
      txnId: string
      code: string
      cvvMatch: string
      approvalCode: string
    }
  }

  export interface Membership {
    address: {
      attributes: {
        address1: string
        address2: string
        cityName: string
        postalCode: string
        StateProv: string
      }
    }
    associateMember: AssociateMember[]
    attributes: {
      doNotRenew: string
      expDate: string
      membershipStatus: string
      promotionalCode: string
      reasonCode: string
      remainingAssociateCount: string
      renewalStatus: string
      specialHandling?: string
    }
    membershipCredit: {
      attributes: {
        creditAmount: string
      }
    }
    payment: Payment
    primaryMember: PrimaryMember
    costSummary?: {
      autoRenewDiscount: string
      discountAmount: string
      solicitationDiscount: string
      solicitationRequiresAutoRenew: string
      waiveEnrollDiscount: string
      totalCost: string
    }
    membershipOptions?: { duesComponent: MembershipDuesComponentAttributes[] }
  }

  export interface PricePreviewsResponseObject extends SessionDocResponseObject {
    error: any
    response: PricePreviewResponse
  }

  export interface MemberQuickRenewResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: ValidateResponse<MemberInfo>
    account: AccountInfo
  }

  export interface ValidateRenewResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: ValidateResponse<MemberInfo>
  }

  export interface MemberRenewResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: ValidateResponse<MemberInfo>
  }

  export interface MemberAutoRenewResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: OperationEventResponse<MemberInfo>
  }

  export interface MemberAutoRenewValidateResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: ValidateResponse<MemberInfo>
  }

  export interface MemberUpgradeResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: ValidateResponse<MemberInfo>
  }

  export interface ValidateChangeAddressResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: ValidateResponse<MemberInfo>
  }

  export interface ValidateAssociatesResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: ValidateResponse<MemberInfo>
  }

  export interface ValidateChangePhoneResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: ValidateResponse<MemberInfo>
  }

  export interface JoinRecostValidationResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: ValidateResponse<MemberInfo> & { missingRequirements: string }
  }

  export interface MembershipAssociateRecostValidationResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: ValidateResponse<MemberInfo> & { missingRequirements: string }
  }

  export interface JoinExecuteResponseObject extends SessionDocResponseObject {
    error: MemberInfoAttributes
    response: {
      ok: boolean
      operation: Operation
      response: MemberInfo
    }
  }

  export class AccountInfo implements AccountDetails {
    includedPrimaryOffers: AccountIncludedPrimaryOffers
    includedAssociateOffers: AccountIncludedAssociateOffers[]
    address: AccountAddress
    associateCount: number | null
    associates: MembershipAssociateDetails[]
    autoRenew: boolean | null
    balance: number | null
    cardNumber: string
    code: {
      label: string
      level: ConnectSuiteMembershipLevel
      rv: boolean
      membershipType: string
      duesComponentCode: string
    } | null
    email: string
    firstName: string
    lastName: string
    memberNumber: string
    middleInitial: string
    nameSuffix: string
    phone: AccountPhone
    status: AccountStatus
    expireDate?: string | null
    paymentSummary: AccountDetailsPaymentSummary[]
    availableOffers: MembershipOfferItem[]

    constructor(memberInfo?: MemberInfo) {
      const membershipCode = connectSuiteMembershipCodes.find(
        (code) => code.membershipType === memberInfo?.membership.primaryMember.attributes.membershipType,
      )

      const membershipPayloadAssociates = getConnectsuiteMembershipAssociates(memberInfo)
      // Todo: need to move to selector
      this.expireDate = memberInfo?.membership?.attributes?.expDate
        ? parse(memberInfo.membership.attributes.expDate, "yyyyMMdd", new Date()).toISOString()
        : null
      this.autoRenew = "Y" === memberInfo?.membership?.payment?.attributes?.autoRenew
      this.balance = memberInfo ? parseFloat(memberInfo.attributes?.balance) : null
      this.cardNumber = memberInfo?.membership?.payment?.account?.attributes?.cardNumber || ""
      this.code = membershipCode || null
      this.status = getMembershipStatus(memberInfo?.membership?.attributes?.membershipStatus)
      this.includedPrimaryOffers = {
        primary: {
          code: this.code?.duesComponentCode || "",
          amount: this.balance || 0,
          selectedByDefault: true,
          offering: "primary",
          description: this.code?.label || "",
        },
        primaryOptional: [],
      }
      /**
       * the following are available from the membership payload object
       *   - MembershipLookupService.copyMemberLookupToMembershipPayload()
       * and don't need to be defined here TODO: remove them when costs component is using membershipPayload
       */
      this.address = getConnectsuiteAccountAddress(memberInfo)
      this.associates = membershipPayloadAssociates
      this.includedAssociateOffers = []
      this.associateCount = membershipPayloadAssociates.length
      this.email = memberInfo?.membership?.primaryMember?.attributes?.email || ""
      this.firstName = capitalize(memberInfo?.membership?.primaryMember?.attributes?.firstName || "")
      this.lastName = capitalize(memberInfo?.membership?.primaryMember?.attributes?.lastName || "")
      this.memberNumber = memberInfo?.membership?.primaryMember?.attributes?.membershipNumber || ""
      this.middleInitial = memberInfo?.membership?.primaryMember?.attributes?.middleIntial || ""
      this.nameSuffix = memberInfo?.membership?.primaryMember?.attributes?.nameSuffix || ""
      this.phone = getConnectsuiteAccountPhone(memberInfo)
      this.paymentSummary = memberInfo ? this.getPaymentSummary(memberInfo) : []
      this.availableOffers = []
    }

    getPaymentSummary(memberInfo: MemberInfo) {
      const paymentSummary: AccountDetailsPaymentSummary[] = []
      const primarySummary: AccountInfoSummary = {
        value: Number(memberInfo.membership.primaryMember.duesComponent.attributes.componentAmount),
        label: "AAA " + this.code?.label || "" + " Membership",
      }
      const associatesSummaries: AccountInfoSummary[] = (memberInfo.membership.associateMember || []).map(
        (associate: any) => ({
          label: this.code?.label || "" + " Associate Membership",
          value: Number(associate.duesComponent.attributes.componentAmount),
        }),
      )

      paymentSummary.push(primarySummary, ...associatesSummaries)

      return paymentSummary
    }
  }
}
