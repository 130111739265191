import checkExistMembershipLevel from "../guards/check-exist-membership-level"
import { Routes } from "@angular/router"
import { EmemberComponent } from "../emember.component"
import defaultRouting from "../guards/default-routing.northampton"
import loadMembership from "../guards/load-membership.login"
import authenticatedMembership from "../guards/authenticated-membership.northampton"
import { environment } from "../../../../environments/environment"
import setPageMembershipOffer from "../guards/set-page-membership-offer"
import setPageJoin from "../guards/set-page-join"
import setPageTitle from "../guards/set-page-title"
import loadPriceOffer from "../guards/load-price-offer"

export const routes: Routes = [
  {
    path: "join",
    loadChildren: () => import("../../join/join.module").then((m) => m.JoinModule),
    canActivate: [setPageTitle, checkExistMembershipLevel, loadPriceOffer, setPageJoin("join")],
  },
  {
    path: "gift",
    loadChildren: () => import("../../join/join.module").then((m) => m.JoinModule),
    canActivate: [setPageTitle, loadPriceOffer, setPageJoin("gift")],
  },
  {
    path: "account",
    loadChildren: () => import("../../account/account.module").then((m) => m.AccountModule),
    canActivate: [setPageTitle, authenticatedMembership, loadMembership],
  },
  {
    path: "renew",
    loadChildren: () => import("../../quick-renew/quick-renew.module").then((m) => m.QuickRenewModule),
    canActivate: [setPageTitle],
  },
  {
    path: "offers",
    loadChildren: () =>
      import("../../membership-offers/membership-offers.module").then((m) => m.MembershipOffersModule),
    canActivate: [setPageTitle, loadPriceOffer, setPageMembershipOffer("offers")],
  },
  {
    path: "gift-offers",
    loadChildren: () =>
      import("../../membership-offers/membership-offers.module").then((m) => m.MembershipOffersModule),
    canActivate: [setPageTitle, loadPriceOffer, setPageMembershipOffer("gift-offers")],
  },
  {
    path: "login",
    loadChildren: () => import("../../membership-login/membership-login.module").then((m) => m.MembershipLoginModule),
    canActivate: [setPageTitle],
  },
  {
    path: "contact",
    loadComponent: () => import("../../contact/contact.component").then((c) => c.ContactComponent),
    canActivate: [setPageTitle],
  },
  {
    path: "",
    component: EmemberComponent,
    canActivate: [...(!environment.ngServe ? [defaultRouting] : [])],
  },
]
