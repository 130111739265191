import { createSelector, MemoizedSelector } from "@ngrx/store"
import { AccountDetails, AccountDetailsPaymentSummary } from "@aaa/emember/store-types"
import { ClubOption } from "../../../../types/club-option"
import { MembershipOfferSummary } from "../../../store/price-offers/helpers/types"
import { ClubApp } from "@aaa/emember/types"

export const AccountDetailsSelectors = (
  accountDetails: MemoizedSelector<any, AccountDetails | null>,
  clubOption: MemoizedSelector<any, ClubOption>,
) => {
  const getAvailableOffers = createSelector(accountDetails, (a) => a?.availableOffers || [])
  const getPaymentSimpleSummary = createSelector(accountDetails, (a) => {
    const simpleSummary: AccountDetailsPaymentSummary[] = [
      {
        label: a?.code?.label + " Plan",
        value: a?.balance || 0,
      },
    ]

    return simpleSummary
  })
  const getPaymentSummary = createSelector(accountDetails, (a) => {
    return (a?.paymentSummary || []).map<AccountDetailsPaymentSummary>((p, index) => {
      const label =
        index === 0
          ? `${a?.firstName} ${a?.lastName}`
          : `${a?.associates[index - 1].firstName} ${a?.associates[index - 1].lastName}`
      return {
        label,
        value: p.value,
      }
    })
  })
  const getMembershipType = createSelector(accountDetails, (a) => a?.code?.membershipType || "")
  const getBalance = createSelector(accountDetails, (a) => a?.balance || 0)
  const getIncludedOffers = createSelector(accountDetails, (a) => {
    const result: MembershipOfferSummary = {
      primary: undefined,
      optionalPrimary: [],
      associates: [],
      optional: [],
    }

    if (a) {
      result.primary = { ...a.includedPrimaryOffers.primary, description: `${a.firstName} ${a.lastName}` }
      result.optionalPrimary.push(...a.includedPrimaryOffers.primaryOptional)
      result.associates.push(
        ...a.includedAssociateOffers.map(({ associate, associateOptional }, index) => {
          const associateInfo = a.associates[index]
          return {
            associate: [
              {
                ...associate[0],
                description: `${associateInfo.firstName} ${associateInfo.lastName}`,
              },
            ],
            associateOptional,
          }
        }),
      )
    }

    return result
  })

  const getMedicalPrice = (offering: "primary" | "associate") =>
    createSelector(getAvailableOffers, clubOption, (summary, option) => {
      const result = summary
        .filter((o) => {
          return offering === "primary" ? o.offering === "optionalPrimary" : "associateOptional"
        })
        .filter((o) => {
          switch (option.clubId) {
            case ClubApp.Northampton:
            case ClubApp.Shelby:
              return o.code.startsWith("MED")

            case ClubApp.MidStates:
              return o.code.startsWith("MD")
          }
          return false
        })

      return result.length ? result[0]?.amount : 0
    })

  return {
    getAvailableOffers,
    getPaymentSummary,
    getPaymentSimpleSummary,
    getIncludedOffers,
    getBalance,
    getMedicalPrice,
    getMembershipType,
  }
}
