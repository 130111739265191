import { Component } from "@angular/core"
import { NotificationModule } from "../modules/notification/notification.module"

@Component({
  selector: "ava-notification-custom-element",
  template: `
    <ava-notification></ava-notification>
  `,
  standalone: true,
  imports: [
    NotificationModule
  ],
})
export class NotificationCustomElementComponent {
}
