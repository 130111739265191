import { createFeatureSelector, createSelector } from "@ngrx/store"
import { CardFormat } from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import { getClubId, getClubOption, getShowAccidentMedicalPlan, getZipcode } from "@aaa/emember/store-membership"
import { getAllStates } from "../states/states.selectors"
import { ClubApp } from "@aaa/emember/types"
import { getErrorMessage, RequestErrorType } from "../generic-errors"
import { JoinFormStep, JoinState } from "./join.models"
import {
  getProgramCouponCode,
  getPriceOfferLevelOffers,
  getPriceOfferLevels,
} from "../price-offers/price-offers.selectors"
import { OfferFilter } from "../price-offers/helpers/types"
import { getPaymentFormValues } from "@aaa/emember/store-payment"
import { currentSummaryOffer } from "../price-offers/helpers/current-summary-offer"
import { promocodeWarningRequrieAutorenew } from "../price-offers/helpers/promocode-warning-requrie-autorenew"
import {
  hoosierTermAndCondition,
  midstatesTermAndCondition,
  northamptonTermAndCondition,
  shelbyTermAndCondition,
  southJerseyTermAndCondition,
} from "../../modules/share/constants/term-and-conditions"
import { getStateByZipcode } from "../../modules/share/utils/get-state-by-zipcode"
import { paymentAutoRenewInfo } from "../../modules/share/constants/payment-auto-renew-info"
import { FormGroupValue } from "../../modules/share/form.utils"
import { MembershipAssociateForm } from "../../modules/share/membership-associate-form"
import { RequestStatus } from "../../../types/request-status"

export const JOIN_FEATURE_KEY = "join"

export const getJoinState = createFeatureSelector<JoinState>(JOIN_FEATURE_KEY)
export const getJoinFormExecutionData = createSelector(getJoinState, ({ executionData }) => executionData || "")
export const getJoinFormActiveStep = createSelector(getJoinState, ({ formActiveStep }) => formActiveStep)
export const getFormValues = createSelector(getJoinState, ({ formValues }) => formValues)
export const getJoinFormConfirmedMembers = createSelector(getJoinState, ({ members }) => members)
export const getJoinFormTotalCost = createSelector(getJoinState, ({ totalCost }) => totalCost)
export const getJoinFormPage = createSelector(getJoinState, ({ page }) => page)
export const getExecutionError = createSelector(getJoinState, ({ executionError }) => executionError)
export const getExecutionRequestStatus = createSelector(
  getJoinState,
  ({ executionRequestStatus }) => executionRequestStatus,
)
export const getJoinValidationRequestStatus = createSelector(
  getJoinState,
  ({ validationRequestStatus }) => validationRequestStatus,
)
export const getJoinValidationError = createSelector(getJoinState, ({ validationError }) => validationError)
export const getJoinValidationMessageError = createSelector(
  getJoinValidationError,
  getClubOption,
  (validationError, option) => getErrorMessage(validationError, option),
)

export const getJoinExecutionMessageError = createSelector(
  getExecutionError,
  getClubOption,
  (validationError, option) => getErrorMessage(validationError, option),
)

export const getJoinMessageError = createSelector(
  getJoinValidationMessageError,
  getJoinExecutionMessageError,
  (validationError, executionError) => validationError || executionError || "",
)

export const getIsLoadingExecution = createSelector(
  getExecutionRequestStatus,
  (status) => status === RequestStatus.RUNNING,
)
export const getIsLoadingRecostValidation = createSelector(
  getJoinValidationRequestStatus,
  getJoinValidationError,
  (status, requestStatus) =>
    status === RequestStatus.RUNNING ||
    // this condition it is for case to continue showing loading if promo code is invalid
    (requestStatus?.type === RequestErrorType.MembershipInvalidPromoCode && status === RequestStatus.FAILED),
)

export const getDefaultActiveStep = createSelector(getJoinFormPage, (page) => {
  if (page === "join") {
    return "memberInfo" as JoinFormStep
  }

  if (page === "gift") {
    return "giftReceiverInfo" as JoinFormStep
  }

  return ""
})

export const getJoinFormStates = createSelector(getZipcode, getAllStates, (zipcode, states) => {
  const stateCode = getStateByZipcode(zipcode)

  return stateCode ? states.filter((state) => [stateCode].includes(state.value)) : []
})
export const getJoinFormValues = createSelector(
  getFormValues,
  getJoinFormStates,
  getZipcode,
  (formValues, states, zipCode) => {
    const state = states[0]
    const zipcode = zipCode
    const cardFormat = CardFormat.PLASTIC,
      newFormValues = { ...formValues }

    if (!newFormValues?.memberInfo?.membership?.cardFormat) {
      newFormValues.memberInfo = {
        ...newFormValues?.memberInfo,
        membership: {
          ...newFormValues?.memberInfo?.membership,
          cardFormat,
        },
      }
    }

    if (!newFormValues?.memberInfo?.account?.state) {
      newFormValues.memberInfo = {
        ...newFormValues?.memberInfo,
        account: { ...newFormValues?.memberInfo?.account, state: state.value },
      }
    }

    if (!newFormValues?.memberInfo?.account?.zipcode) {
      newFormValues.memberInfo = {
        ...newFormValues?.memberInfo,
        account: { ...newFormValues?.memberInfo?.account, zipcode },
      }
    }

    if (!newFormValues?.giftInfo?.account?.state) {
      newFormValues.giftInfo = {
        ...newFormValues?.giftInfo,
        account: { ...newFormValues?.giftInfo?.account, state: state.value },
      }
    }

    if (!newFormValues?.giftInfo?.account?.zipcode) {
      newFormValues.giftInfo = {
        ...newFormValues?.giftInfo,
        account: { ...newFormValues?.giftInfo?.account, zipcode },
      }
    }

    return newFormValues
  },
)

export const getJoinFormMembershipLevelChanged = createSelector(
  getJoinFormValues,
  (values) => values.memberInfo?.membership?.membershipLevel || "",
)
export const getJoinFormMembershipRvChanged = createSelector(
  getJoinFormValues,
  (values) => !!values.memberInfo?.membership?.rv,
)

export const getJoinFormAccountValues = createSelector(
  getJoinFormValues,
  (formValues) => formValues.memberInfo?.account,
)

export const getJoinFormGiftAccountValues = createSelector(
  getJoinFormValues,
  (formValues) => formValues.giftInfo?.account,
)

export const getJoinFormMembershipAssociatesValues = createSelector(
  getJoinFormValues,
  (formValues) => (formValues.memberInfo?.membershipAssociates || []) as FormGroupValue<MembershipAssociateForm>[],
)

export const getJoinFormSelectedLevel = createSelector(getJoinFormValues, getPriceOfferLevels, (formValues, levels) => {
  const level = formValues.memberInfo?.membership?.membershipLevel || ""
  const rv = !!formValues.memberInfo?.membership?.rv
  const selectedLevel = levels.find((l) => l.level === level && l.rv == rv) || null

  return selectedLevel
})

export const getJoinFormMembershipType = createSelector(
  getJoinFormSelectedLevel,
  (selected) => selected?.membershipType || "",
)

export const getJoinFormOfferFilter = createSelector(
  getJoinFormValues,
  getPaymentFormValues,
  getJoinFormPage,
  (formValues, paymentFormValues, page) => {
    const medicalRider =
      page === "join"
        ? !!formValues.memberInfo?.account?.accidentMedicalPlan
        : !!formValues.giftInfo?.account?.accidentMedicalPlan
    const filter: OfferFilter = {
      medicalRider: medicalRider,
      autoRenew: !!paymentFormValues.autoRenew,
      promoCode: !!formValues.memberInfo?.membership?.promoCode,
      associates: (formValues.memberInfo?.membershipAssociates || []).map((associate) => {
        return { medicalRider: !!associate.accidentMedicalPlan }
      }),
    }

    return filter
  },
)

export const getJoinFormOfferSummary = createSelector(
  getPriceOfferLevelOffers,
  getJoinFormMembershipType,
  getJoinFormOfferFilter,
  currentSummaryOffer,
)

export const getJoinFormMedicalPrice = (offering: "primary" | "associate") =>
  createSelector(
    getJoinFormMembershipType,
    getPriceOfferLevelOffers,
    getClubOption,
    (membershipType, membershipOffers, option) => {
      if (membershipType && membershipOffers) {
        const price =
          membershipOffers[membershipType]
            .filter((m) => {
              if (offering === "primary") {
                return m.offering === "optionalPrimary"
              } else {
                return m.offering === "associateOptional"
              }
            })
            .find((m) => {
              switch (option.clubId) {
                case ClubApp.Northampton:
                case ClubApp.Shelby:
                  return m.code.startsWith("MED")

                case ClubApp.MidStates:
                  return m.code.startsWith("MD")
              }

              return false
            })?.amount || 0

        return price
      }
      return 0
    },
  )

export const getJoinFormPromoCodeWarningRequireAutoRenew = createSelector(
  getPriceOfferLevelOffers,
  getJoinFormMembershipType,
  getJoinFormOfferFilter,
  promocodeWarningRequrieAutorenew,
)

export const getJoinFormMemberInfoLabel = createSelector(getJoinFormPage, (page) => {
  if (page === "join") return "Member Information"

  if (page === "gift") return "Your Information"

  return ""
})

export const getJoinFormShowGiftRecipient = createSelector(getJoinFormPage, (page) => page === "gift")

export const getShowMedicalPlanCurrentClub = createSelector(
  getShowAccidentMedicalPlan,
  getJoinFormPage,
  (showMedicalPlan, page) => page === "join" && showMedicalPlan,
)
export const getShowMedicalPlanGiftInfo = createSelector(
  getShowAccidentMedicalPlan,
  getJoinFormPage,
  (showMedicalPlan, page) => page === "gift" && showMedicalPlan,
)
export const getShowPhoneNumber = createSelector(getJoinFormPage, (page) => page === "join")
export const getShowBirthday = createSelector(getJoinFormPage, (page) => page === "join")

export const getTermAndConditions = createSelector(getJoinFormPage, getClubId, (page, clubId) => {
  if (clubId === ClubApp.Hoosier) return hoosierTermAndCondition(page)
  if (clubId === ClubApp.MidStates) return midstatesTermAndCondition(page)
  if (clubId === ClubApp.Northampton) return northamptonTermAndCondition(page)
  if (clubId === ClubApp.Shelby) return shelbyTermAndCondition(page)
  if (clubId === ClubApp.SouthJersey) return southJerseyTermAndCondition(page)

  return []
})
export const getPaymentAutoRenewInfo = createSelector(
  getJoinFormPage,
  getProgramCouponCode,
  getClubId,
  (page, programCode, clubId) => paymentAutoRenewInfo(page, clubId, programCode),
)
