import { Component } from "@angular/core"
import { MyAccountCtaComponent } from "../modules/my-account-cta/component"

@Component({
  imports: [MyAccountCtaComponent],
  standalone: true,
  template: ` <ava-my-account-cta /> `,
  selector: "ava-my-account-cta-custom-element",
})
export class MyAccountCtaCustomElementComponent {}
