import { Inject, Injectable } from "@angular/core"
import firebase from "firebase/compat/app"
import { combineLatestWith, debounceTime, interval, takeWhile, tap } from "rxjs"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { JoinRenewService, OpStatus } from "../../join-renew.service"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../services/state"
import {
  Flow, HOOSIER_RX_STATE, HoosierService, HoosierState, SessionDocResponse, SessionDocResponseObject,
} from "../hoosier.service"
import { AlertGroup, AlertMessage, AlertMessageService, AlertType } from "../../../../services/alert-message"
import { EventName } from "@aaa/interface-joinRenew-joinRenewLib"
import { PaymentCybersourceMethod } from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { ScriptsService } from "../../../../services/scripts"
import Timestamp = firebase.firestore.Timestamp

export interface PaymentCaptureContextResponseObject extends SessionDocResponseObject {
  error: any
  // meta: SessionDocMeta
  response: string
}

@Injectable({
  providedIn: "root",
})
export class PaymentCaptureContextService {
  captureContextAgeTimestamp = 0
  numberOfRetries: number = 0

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private joinRenewService: JoinRenewService,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
    private alertMessageService: AlertMessageService,
    private scriptsService: ScriptsService,
  ) {
    rxEffects.register(this.paymentCaptureContext$)
    rxEffects.register(this.PAYMENT_CAPTURE_CONTEXT_KEY$)
    rxEffects.register(this.retryOnError$)
    /**
     * Cybersource does not provide this script in npm, we need to load it manually.
     */
    const scriptSrc = "https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js"
    scriptsService.addJsToHead(scriptSrc).onload = () => {
      this.paymentCaptureContext()
      rxEffects.register(this.refreshCaptureContext$)
    }
  }

  paymentCaptureContext$ = this.hoosierState.select("activeFlow")
      .pipe(
        tap((activeFlow: Flow | null) => {
            switch (activeFlow) {
              case Flow.JOIN:
              case Flow.GIFT:
              case Flow.ACCOUNT:
                // this.paymentCaptureContext()
                break
              case Flow.QUICK_RENEW:
              case Flow.RENEW:
            }
        }),
      )

  refreshCaptureContext$ = interval(12 * 60 * 1000) // 12 minutes
    .pipe(
      tap(() => {
        this.paymentCaptureContext()
      }),
    )

  retryOnError$ = this.hoosierState.select("PAYMENT_CAPTURE_CONTEXT_STATUS")
    .pipe(
      takeWhile(() =>  this.numberOfRetries < 3),
      debounceTime(10000),
      tap(status => {
        if (status === OpStatus.IS_ERROR) {
          this.alertMessageService.set(AlertMessage.ERROR_RETRY, AlertType.INFO)
          this.paymentCaptureContext(true)
        }
      })
    )

  PAYMENT_CAPTURE_CONTEXT_KEY$ = this.hoosierState.select("PAYMENT_CAPTURE_CONTEXT_KEY")
    .pipe(
      combineLatestWith(
        this.hoosierState.select("sessionDoc", "responses", "payment", "cybersource"),
      ),
      tap(([PAYMENT_CAPTURE_CONTEXT_KEY, cybersource]: [string | null, SessionDocResponse]) => {
        if (PAYMENT_CAPTURE_CONTEXT_KEY && cybersource[PAYMENT_CAPTURE_CONTEXT_KEY]) {
          this.captureContextAgeTimestamp = Timestamp.now().toMillis()
          this.hoosierState.set("PAYMENT_CAPTURE_CONTEXT_KEY", () => null)

          const responseTime = Timestamp.now().toMillis() - parseInt(PAYMENT_CAPTURE_CONTEXT_KEY)

          if (this.globalState.get("adminUser")) {
            console.log(responseTime, "milliseconds - PAYMENT_CAPTURE_CONTEXT")
          }

          const isError: boolean = cybersource[PAYMENT_CAPTURE_CONTEXT_KEY].meta.isError

          if (!isError) {
            this.alertMessageService.clearAll(AlertGroup.ERROR)
            this.hoosierState.set("PAYMENT_CAPTURE_CONTEXT", () => cybersource[PAYMENT_CAPTURE_CONTEXT_KEY] as PaymentCaptureContextResponseObject)
            this.hoosierState.set("PAYMENT_CAPTURE_CONTEXT_ERROR", () => null)
            this.hoosierState.set("PAYMENT_CAPTURE_CONTEXT_STATUS", () => OpStatus.SUCCESS)
          }
          if (isError) {
            this.hoosierState.set("PAYMENT_CAPTURE_CONTEXT_ERROR", () => cybersource[PAYMENT_CAPTURE_CONTEXT_KEY] as PaymentCaptureContextResponseObject)
            this.hoosierState.set("PAYMENT_CAPTURE_CONTEXT_STATUS", () => OpStatus.IS_ERROR) // triggers retry
          }
        }
      }),
    )

  paymentCaptureContext(retry?: boolean): void {
    if (!this.hoosierState.get("EXECUTE_KEY")) {
      if (retry) {
        this.numberOfRetries++
      }
      if (!retry) {
        this.numberOfRetries = 0
      }
      const responseKey = Timestamp.now().toMillis().toString()
      this.hoosierState.set("PAYMENT_CAPTURE_CONTEXT", () => null)
      this.hoosierState.set("PAYMENT_CAPTURE_CONTEXT_KEY", () => responseKey)
      this.hoosierState.set("PAYMENT_CAPTURE_CONTEXT_STATUS", () => OpStatus.RUNNING)

      const payload = {
        method: PaymentCybersourceMethod.CAPTURE_CONTEXT,
        responseKey: responseKey,
      }

      if (this.globalState.get("adminUser")) {
        // console.log(payload)
      }

      this.joinRenewService.sendToEventCoordinatorReceiver(EventName.PAYMENT_QUERY, payload)
        .then(response => {
          if (this.globalState.get("adminUser")) {
            console.log(EventName.PAYMENT_QUERY + "---" + PaymentCybersourceMethod.CAPTURE_CONTEXT + "---" + response)
          }
        })
        .catch(error => {
          // console.log(error)
        })

    }
  }

}
