import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { StateMachineService } from "../../services/state-machine.service"
import { FormService } from "../../services/form.service"
import { FormControl } from "@angular/forms"
import { Subscription } from "rxjs"
import { Agent, FormValues } from "@aaa/interface-agentScheduler"

@Component({
  selector: "ava-appointment-card",
  templateUrl: "./appointment-card.component.html"
})
export class AppointmentCardComponent implements OnInit, OnDestroy {
  @Input() formId: string | undefined
  formValues: FormValues | undefined
  agent: Agent | undefined
  selectedDateTime: Date | undefined | null
  selectedDateTimeEnd: Date | undefined | null
  timeSlotSubscription: Subscription | undefined
  date: FormControl | undefined
  timeSlot: FormControl | undefined


  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {
  }

  ngOnInit(): void {
    if (this.formId) {
      this.formValues = this.formService.formValues[this.formId]
      this.date = this.formService.form[this.formId].get("date") as FormControl
      this.timeSlot = this.formService.form[this.formId].get("timeSlot") as FormControl
      if (this.timeSlot.value?.date && this.timeSlot.value.time) {
        this.setSelectedDateTime(this.timeSlot.value)
      }
      this.timeSlotSubscription = this.timeSlot.valueChanges.subscribe(timeSlot => {
        if (timeSlot) {
          this.setSelectedDateTime(timeSlot)
        } else {
          this.selectedDateTime = this.selectedDateTimeEnd = null
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.timeSlotSubscription?.unsubscribe()
  }

  topicText(topicId: string): string {
    return this.formValues?.topics.find(topic => topic.id === topicId)?.text || ""
  }

  meetingText(): string {
    return this.formValues?.meetings.find(meeting => meeting.id === this.formValues?.selectedMeetingId)?.text || ""
  }

  regionText(): string {
    return this.formValues?.regions.find(region => region.id === this.formValues?.selectedRegionId)?.text || ""
  }

  private setSelectedDateTime(timeSlot: { date: { year: number; month: number; day: number | undefined }; time: { hour: number; minute: number | undefined } }): void {
    this.selectedDateTime = new Date(
      timeSlot.date.year,
      timeSlot.date.month - 1,
      timeSlot.date.day,
      timeSlot.time.hour,
      timeSlot.time.minute,
      0,
      0
    )
    this.selectedDateTimeEnd = new Date(
      timeSlot.date.year,
      timeSlot.date.month - 1,
      timeSlot.date.day,
      timeSlot.time.hour + 1,
      timeSlot.time.minute,
      0,
      0
    )

  }

}
