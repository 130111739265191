import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild
} from "@angular/core"
import { SwStateMachineService } from "../../services/sw-state-machine.service"
import { FormGroup, Validators, FormControl, FormArray } from "@angular/forms"
import { MapInfoWindow, GoogleMap } from "@angular/google-maps"
import { Subscription } from "rxjs"
import { GeocodingService } from "../../../../services/geocoding"
import { Category, FormValue } from "@aaa/interface-smartWeather"
import { MetaWindow } from "../../../../interfaces/window"
import GeocoderGeometry = google.maps.GeocoderGeometry
import { Loader } from "@googlemaps/js-api-loader"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../services/state"
import { RxState } from "@rx-angular/state"

@Component({
  selector: "ava-smart-weather-location-form",
  templateUrl: "./location-form.component.html"
})
export class LocationFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("container", { static: false }) container: ElementRef | undefined
  @Input() eventIndex: number | undefined
  @Input() title: string | undefined
  // @Input() data: any
  locationInfoForm: FormGroup
  location: any | undefined
  eventType: "SAVE" | "CANCEL" | "RELOAD" | "RETRY" | undefined
  categoryList: string[] = [
    "TEMPERATURE",
    "VISIBILITY",
    "LIGHTNING",
    "TROPICAL",
    "TSUNAMI",
    "MARINE",
    "SEVERE",
    "HAZARD",
    "WINTER",
    "OTHER",
    "FLOOD",
    "WIND",
    "HAIL",
    "FIRE"
  ]
  stateSubscription: Subscription
  geometrySubscription: Subscription | undefined
  swsSmsState: never | undefined
  swsSmsStateValue: any
  validatorOptions = {
    name: [
      Validators.required,
      Validators.minLength(1)
    ],
    postal: [
      Validators.required,
      this.validPostal
    ],
    phone: [
      Validators.required,
      Validators.pattern("([().+ -]*[0-9]{1}){10,11}[().+ -]*?"),
    ],
    email: [
      Validators.required,
      Validators.email,
    ]
  }
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow | undefined
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap | undefined
  mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    fullscreenControl: false,
    gestureHandling: "none",
    streetViewControl: false,
    clickableIcons: false,
    zoom: 3,
  }
  center: google.maps.LatLng | google.maps.LatLngLiteral = {
    lat: 39.50,
    lng: -98.35
  }
  postalBounds: {
    east: number
    north: number
    south: number
    west: number
  } = { east: 0, north: 0, south: 0, west: 0 }
  postalOptions: google.maps.RectangleOptions = {
    fillColor: "blue",
    fillOpacity: 0.4,
    strokeWeight: 0,
  }
  gis: {
    lat: number | undefined
    lon: number | undefined
  } = { lat: undefined, lon: undefined }
  gridTemplateColumns: string | undefined
  gridTemplateRows2x2: string | undefined
  gridTemplateRows1x2: string | undefined
  window: MetaWindow
  validating: boolean = false
  name: FormControl
  postal: FormControl
  emails: FormArray
  phones: FormArray
  categories: FormArray
  mapLoaded = false

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private swSms: SwStateMachineService,
    private geocoding: GeocodingService,
    private changeDetectorRef: ChangeDetectorRef,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow

    this.location = swSms.editorLocation
    // console.log(this.location)
    this.stateSubscription = swSms.state$
      .subscribe(state => {
        this.swsSmsState = state as never
        this.swsSmsStateValue = state.value
      })

    const formValue: FormValue = {
      name: this.location.name,
      postal: this.location.postal,
      phones: this.location.paths.sms,
      emails: this.location.paths.email,
      categories: this.categoryList
    }
    // this.buildForm(formValue)
    this.locationInfoForm = new FormGroup({
      name: new FormControl(formValue.name),
      postal: new FormControl(formValue.postal),
      phones: new FormArray(formValue.phones.map(phone => new FormControl(phone))),
      emails: new FormArray(formValue.emails.map(email => new FormControl(email))),
      categories: new FormArray(formValue.categories.map(category => new FormControl(category)))
    })
    this.name = this.locationInfoForm.get("name") as FormControl
    this.postal = this.locationInfoForm.get("postal") as FormControl
    this.emails = this.locationInfoForm.get("emails") as FormArray
    this.phones = this.locationInfoForm.get("phones") as FormArray
    this.categories = this.locationInfoForm.get("categories") as FormArray


    this.geocoding.unsetPostalGeometry()

    this.initField("phone", this.phones?.value.length)
    this.initField("email", this.emails?.value.length)
  }

  ngOnInit(): void {
    const loader = new Loader({
      apiKey: this.globalState.get("environment", "firebaseConfig", "apiKey"),
      version: "weekly",
    })
    loader
      .importLibrary("maps")
      .then(() => {
        this.mapLoaded = true

      })
      .catch((error) => {
        console.error(error)
        //
      })
  }

  ngAfterViewInit(): void {
    this.onResize()
    if (this.map) {
      this.geometrySubscription = this.geocoding.postalGeometry$
        .subscribe((geo: GeocoderGeometry) => {
          if (this.map) {
            if (geo?.bounds) {
              this.postalBounds = {
                east: geo.bounds.getNorthEast().lng(),
                north: geo.bounds.getNorthEast().lat(),
                south: geo.bounds.getSouthWest().lat(),
                west: geo.bounds.getSouthWest().lng()
              }
              this.map.fitBounds({
                east: geo.viewport.getNorthEast().lng(),
                north: geo.viewport.getNorthEast().lat(),
                south: geo.viewport.getSouthWest().lat(),
                west: geo.viewport.getSouthWest().lng()
              })
              const zoom = this.map.getZoom()
              if (zoom !== undefined) {
                this.map.zoom = zoom - 1
              }

              this.gis = {
                lat: geo.location.lat(),
                lon: geo.location.lng(),
              }

              this.changeDetectorRef.detectChanges()
            } else {
              this.map.center = this.center
              this.map.zoom = this.mapOptions.zoom || 0
              this.gis = {
                lat: undefined,
                lon: undefined
              }
            }
          }
        })
    }
    this.ngModelChangePostal(this.locationInfoForm?.value?.postal, true)
  }

  validPostal(c: FormControl): null | { validatorError: never } {
    console.log(c)
    const numbersReg = /^\d+$/
    let error
    if (!numbersReg.test(c.value)) {
      error = "zip should contain only numbers"
    } else if (c.value.length > 5) {
      error = "too long"
    } else if (c.value.length < 5) {
      error = "too short"
    }
    // if (error) {
    // this.geocoding.unsetPostalGeometry()
    // }
    return !error ? null : {
      validatorError: error
    } as { validatorError: never }
  }

  // postalLookup(): AsyncValidatorFn {
  //   return (c: AbstractControl): Observable<ValidationErrors> => {
  //     return from(this.geocoding.lookupPostalGeometry(c.value))
  //   }
  // }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe()
    }
    if (this.geometrySubscription) {
      this.geometrySubscription?.unsubscribe()
    }
  }

  initField(type: string, length: number | undefined): void {
    /**
     * If length is passed in, and it is greater than 0, then do not add the field.
     * This supports having at least one initial form field when initializing,
     * but also no extra empty fields if there is at least one.
     */
    if (!length) {
      if (type === "phone") {
        this.addPhone("")
      }
      if (type === "email") {
        this.addEmail("")
      }
    }
  }

  addPhone(value: string): void {
    this.validating = false
    this.phones?.push(new FormControl(value))
  }

  removePhone(index: number): void {
    this.phones?.removeAt(index)
  }

  addEmail(value: string): void {
    this.validating = false
    this.emails?.push(new FormControl(value))
  }

  removeEmail(index: number): void {
    this.emails?.removeAt(index)
  }

  formSubmit(form: FormGroup): void {
    if (this.swsSmsState === "cloudSync") return

    if (this.eventType === "CANCEL") {
      this.swSms.sendEvent(this.eventType)
      return
    }

    if (this.eventType === "RELOAD") {
      this.swSms.sendEvent(this.eventType)
      return
    }

    this.validating = true
    this.locationInfoForm?.markAllAsTouched()
    this.ngModelChangeName(this.locationInfoForm?.value.name, true)
    this.ngModelChangePostal(this.locationInfoForm?.value.postal, true)
    this.locationInfoForm?.value.emails.forEach((email: string, index: number) => this.ngModelChangeEmail(email, true, index))
    this.locationInfoForm?.value.phones.forEach((phone: string, index: number) => this.ngModelChangePhone(phone, true, index))

    /**
     * Process form data and send to state machine before triggering the RETRY or SAVE events.
     */
    const categories = this.categoryList.filter((x, i) => !!form.value.categories[i]) as Category[]
    this.swSms.editorLocation = {
      categories: categories,
      name: form.value.name,
      postal: form.value.postal,
      gis: this.gis,
      paths: {
        email: form.value.emails,
        sms: form.value.phones,
      }
    }

    if (this.locationInfoForm?.valid) {
      if (this.eventType === "RETRY" || this.eventType === "SAVE") {
        console.log(this.eventIndex)
        this.swSms.sendEvent(this.eventType, this.eventIndex)
      }
    }

  }

  @HostListener("window:resize")
  onResize(): void {
    // console.log(this.container?.nativeElement.offsetWidth)
    this.gridTemplateColumns = "100%"
    this.gridTemplateRows2x2 = "repeat(4, auto)"
    this.gridTemplateRows1x2 = "repeat(2, auto)"
    if (this.container?.nativeElement.offsetWidth >= 700) {
      this.gridTemplateColumns = "repeat(2, calc(50% - 12px))"
      this.gridTemplateRows2x2 = "repeat(2, auto)"
      this.gridTemplateRows1x2 = "repeat(1, auto)"
      this.changeDetectorRef.detectChanges()
    }
  }

  ngModelChangePostal(postal: string, validating: boolean): void {
    if (this.validateIsPostal(postal)) {
      this.geocoding.lookupPostalGeometry(parseInt(postal, 10))
    } else {
      if (validating) {
        this.postal?.setErrors({ "incorrect": true })
      }
      this.geocoding.unsetPostalGeometry()
    }
    if (!validating) {
      this.postal?.markAsPristine()
      this.postal?.updateValueAndValidity()
    }
    // this.changeDetectorRef.detectChanges()
  }

  ngModelChangeName(name: string, validating: boolean): void {
    if (validating) {
      if (!name || name.length < 1) {
        this.name?.setErrors({ "incorrect": true })
      }
      if (!this.validateIsAlphaNumericString(name)) {
        this.name?.setErrors({ "incorrect": true })
      }
    } else {
      this.name?.markAsPristine()
      this.name?.updateValueAndValidity()
    }
  }

  ngModelChangeEmail(email: string, validating: boolean, index: number): void {
    if (validating) {
      if (!this.validateIsEmail(email)) {
        this.emails?.get([index])?.setErrors({ "incorrect": true })
      } else {
        this.emails?.get([index])?.markAsPristine()
        this.emails?.get([index])?.updateValueAndValidity()
      }
    }
  }

  ngModelChangePhone(phone: string, validating: boolean, index: number): void {
    if (validating) {
      if (!this.validateIsPhone(phone) || phone?.length > 14) {
        // console.log(this.phones.get([index]))
        this.phones?.get([index])?.setErrors({ "incorrect": true })
      } else {
        this.phones?.get([index])?.markAsPristine()
        this.phones?.get([index])?.updateValueAndValidity()
      }
    }
  }

  validateIsEmail(email: string): boolean {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    return regex.test(email)
  }

  validateIsPhone(phone: string): boolean {
    const regex = /([().+ -]*[0-9]){10,11}[().+ -]*?/
    return regex.test(phone)
  }

  validateIsAlphaNumericString(string: string): boolean {
    const regex = /^\w+/g
    return regex.test(string)
  }

  validateIsPostal(postal: string): boolean {
    const regex = /^[0-9]*$/g
    return postal.length === 5 && regex.test(postal)
  }

}
