import { Component, Input, OnInit } from "@angular/core"
import { StateMachineService } from "../../../services/state-machine.service"
import { FormService } from "../../../services/form.service"
import { environment } from "../../../../../../environments/environment"
import { FormValues, Region } from "@aaa/interface-agentScheduler"

@Component({
  selector: "ava-insurance-options",
  templateUrl: "./insurance-options.component.html",
})
export class InsuranceOptionsComponent implements OnInit {
  @Input() formId: string | undefined
  formValue: FormValues | undefined
  regions: Region[] = []
  formAgentValue: any | undefined

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {
  }

  ngOnInit(): void {
    if (this.formId) {
      this.formValue = this.formService.formValues[this.formId]
      this.formAgentValue = this.formService.form[this.formId]?.get(["agent"])?.value
    }
    if (this.formValue) {
      this.formValue.selectedRegionId = ""

      if (environment.ngServe) {
        // this.sms.sendEvent('NEXT')
      }


      if (this.formAgentValue) {
        /**
         * filter regions options
         * show only regions options that the selected agent supports.
         */
        this.regions = this.formValue.regions
          .filter(region => this.formAgentValue.regionIds.some((regionId: string) => regionId === region.id))

        /**
         * If selectedRegionId is not populated then select the first region.id with region.default:true
         * this is kinda hacky....but
         *    look for default region in the filtered list of regions,
         *    if not found, then use the first one from the filtered list.
         */
        if (!this.formValue.selectedRegionId) {
          this.formValue.selectedRegionId = this.regions.find(region => !!region.default)?.id || ""
        }
        if (!this.formValue.selectedRegionId) {
          this.formValue.selectedRegionId = this.regions[0].id
        }

      }
      if (!this.formAgentValue) {
        /**
         * load all regions options
         */
        this.regions = this.formValue.regions
        /**
         * If selectedRegionId is not populated then select the first region.id with region.default:true
         */
        if (!this.formValue.selectedRegionId) {
          this.formValue.selectedRegionId = this.formValue.regions.find(region => !!region.default)?.id || ""
        }
      }
    }
  }

}
