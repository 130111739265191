import { Inject, Injectable } from "@angular/core"
import { Button } from "../fields/button/service"
import { Image } from "../fields/image/service"
import { GLOBAL_RX_STATE, GlobalState } from "./state"
import { RxState } from "@rx-angular/state"
import { WINDOW, LOCATION, HISTORY } from "@ng-web-apis/common"

export interface Link {
  targetOption: TargetType
  targetPath: string
}

export enum TargetType {
  EXTERNAL = "EXTERNAL",
  SELF = "SELF",
  NEW = "NEW",
  MODAL = "MODAL",
  PHONE = "PHONE",
}

export interface TargetOption {
  type: TargetType,
  label: string,
  value: string
}

@Injectable({
  providedIn: "root"
})
export class LinkService {

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(LOCATION)
    private location: Location,
    @Inject(HISTORY)
    private history: History,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
  ) {
  }

  targetType(targetType: TargetType): TargetOption | undefined {
    return this.targetOptions.find(targetOption => targetOption.type === targetType)
  }

  get targetOptions(): TargetOption[] {
    return [
      {
        type: TargetType.SELF,
        label: "Same",
        value: "_self"
      },
      {
        type: TargetType.NEW,
        label: "New",
        value: "_blank"
      },
      {
        type: TargetType.MODAL,
        label: "Modal",
        value: "modal"
      },
      {
        type: TargetType.PHONE,
        label: "Phone",
        value: "tel"
      },
    ]
  }

  href(link: Button | Image | Link): string {
    switch (link.targetOption) {
      case TargetType.PHONE:
        return "tel://" + link.targetPath
      default:
        return link.targetPath
    }
  }

  linkClick(link: Button | Image | Link): void {
    link.targetOption = link.targetOption || TargetType.SELF

    if (!this.globalState.get("adminMode") || this.globalState.get("previewMode")) {
      /**
       * If not in previewMode but editing in adminMode, ignore the link click
       * If in previewMode, a link click will exit the user out of both adminMode and previewMode and
       *   execute the targetOption action
       * If not in either adminMode or previewMode,
       *   execute the targetOption action
       */
      this.globalState.set("adminMode", () => false)
      this.globalState.set("previewMode", () => false)

      if (link.targetPath.startsWith("https://www.facebook.com")) {
        link.targetOption = TargetType.NEW
      }
      /**
       * get link arguments and hash
       * get url arguments
       * combine them
       * replace link arguments with combined arguments append the link hash
       */
      // console.log(link)

      switch (link.targetOption) {
        case TargetType.EXTERNAL:
          this.location.href = link.targetPath // TODO: check that this has an https://domain
          break
        case TargetType.NEW:
          this.window.open(link.targetPath, "external", "noreferrer")
          break
        case TargetType.SELF: {
          /**
           * capture if link.targetPath is the same as current location.pathname
           * queryParams in globalState.location.search are automatically updated
           */
          const pathname = link.targetPath
            .split("?")[0]
            .split("#")[0]
            .replace(/^\//g, "")
            .replace(/\/$/g, "")
            .toLowerCase()
          const locationPathname = this.location.pathname.toLowerCase()
            .replace(/^\//g, "")
            .replace(/\/$/g, "")
            .toLowerCase()
          if (pathname === locationPathname) {
            // console.log("pushState: " + link.targetPath)
            this.history.pushState({}, "", link.targetPath)
            this.processUrlPathname()
            this.processLocationSearch()

            break
          }

          console.log("navigateTo: " + link.targetPath)
          this.navigateTo(link.targetPath)
          break
        }
        case TargetType.MODAL:
          break
        case TargetType.PHONE:
          this.window.location.href = "tel://" + link.targetPath
          break
        default:

      }
    }
  }

  navigateTo(targetPath: string): void {
    targetPath = targetPath
      .replace(/^\//g, "")

    const appMode = this.globalState.get("windowMetaData", "appMode")
    if (!appMode) {
      this.location.href = this.location.origin + "/" + targetPath
    }

    if (appMode) {
      console.log(targetPath)
      this.history.pushState({}, "", targetPath)
      this.processUrlPathname()

      /**
       * TODO: strip queryParams and fragments from targetPath and append them as NavigationExtras
       */
      // const extras: NavigationExtras = {
      //   fragment: "fragment",
      //   preserveFragment: true,
      //   queryParamsHandling: "merge",
      //   queryParams: {
      //     step: "review",
      //     email: "email@test.com"
      //   }
      // }
      // this.router.navigate(["/" + targetPath, extras]).catch(() => {})
    }
  }

  addUrlArgument(key: string): void {
    const locationSearchArgs: { key: string, value: string }[] = JSON.parse(JSON.stringify(this.globalState.get("locationSearchArgs")))

  }

  replaceUrlArgument(keyToSearchAndRemove: string, keyToAdd: string): void {
    const newArgs = this.globalState.get("locationSearchArgs").filter(argument => {
      return !argument.key
        .toUpperCase()
        .replace(/-/g, "_")
        .includes(keyToSearchAndRemove.toUpperCase())
    })
    const newArg = {
      key: keyToAdd.toLowerCase().replace(/_/g, "-"),
      value: ""
    }
    newArgs.push(newArg)

    const newArgStrings = []
    for (const arg of newArgs) {
      const argValue = arg.value ? "=" + arg.value : ""
      newArgStrings.push(arg.key + argValue)
    }

    const newArgString = newArgs.length ? "?" + newArgStrings.join("&") : ""

    const link: Link = {
      targetOption: TargetType.SELF,
      targetPath: this.globalState.get("location", "pathname") + newArgString
    }
    this.linkClick(link)
  }

  changeUrlArgument(key: string, value: string): void {
    const locationSearchArgs: { key: string, value: string }[] = JSON.parse(JSON.stringify(this.globalState.get("locationSearchArgs")))

    /**
     * check if url args already has a parameter(key) argument
     * and add one if not already present
     */
    const foundKeyInArgs = locationSearchArgs
      .find(locationSearchArg => locationSearchArg.key.toUpperCase() === key.toUpperCase())
    if (!foundKeyInArgs) {
      locationSearchArgs.push({ key: key, value: "" })
    }

    /**
     * add parameter(value) to the parameter(key) argument
     */
    const newArgs: string[] = []
    for (const locationSearchArg of locationSearchArgs) {
      if (locationSearchArg.key.toUpperCase() === key.toUpperCase()) {
        locationSearchArg.value = value
      }
      newArgs.push(locationSearchArg.key + "=" + locationSearchArg.value)
    }

    /**
     * add new value to the url using linkClick() method.
     * This triggers a pushState and then processes url and arguments
     */
    const link: Link = {
      targetOption: TargetType.SELF,
      targetPath: this.globalState.get("location", "pathname") + "?" + newArgs.join("&")
    }

    this.linkClick(link)
  }

  reloadPage(): void {
    this.location.reload()
  }

  processUrlPathname(): void {
    this.globalState.set("location", () => {
      const location = JSON.parse(JSON.stringify(this.location))
      location.href = location.href
        .replace("homepage", "")
        .toLowerCase()
      location.pathname = location.pathname.toLowerCase()
        .replace("homepage", "")
        .replace(/\/$/g, "") // remove trailing "/"
        .toLowerCase()
      if (location.pathname.length === 0) {
        location.pathname = "/"
      }
      return location
    })
  }

  processLocationSearch(): void {
    this.globalState.set("locationSearchArgs", () => {
      const urlArguments = []
      if (this.location.search) {
        const search = this.location.search.toLowerCase().replace("?", "")
        const argStrings = search.split("&")
        for (const argString of argStrings) {
          const key = decodeURIComponent(argString.split("=")[0])
          const value = decodeURIComponent(argString.split("=")[1])
          urlArguments.push({ key: key, value: value || "" })
        }
      }
      return urlArguments
    })
  }

  findYourLocalClub(): void {
    this.location.href = "https://www.aaa.com/stop"
  }

}
