import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { catchError, map, switchMap } from "rxjs/operators"
import { MembershipUpgradePlan } from "./membership-upgrade-plan.actions"
import { of, withLatestFrom } from "rxjs"
import { getPayment } from "../payment/payment.selectors"
import { MembershipCode, ValidateSucceededResponseObject } from "../types/types"
import { getMembershipUpgradePlan } from "./membership-upgrade-plan.selectors"
import { getMembershipNumber } from "@aaa/emember/store-membership"
import { ExecuteService } from "../services/execute.service"
import { ClubApp } from "@aaa/emember/types"
import {
  MembershipMGetMemberInfoEventPayload,
  MembershipMMethod,
  MembershipMOperationExecuteChangeEventPayload,
  MembershipMValidateChangeEventPayload,
  ThreePointAuth,
} from "@aaa/interface-joinRenew-membership-membershipM"
import { M } from "../m.type"
import { FormGroupValue } from "../../modules/share/form.utils"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { Operation, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { getThreePointAuth } from "@aaa/emember/store-account"
import { RequestError, RequestErrorType } from "../generic-errors"
import { checkCybersourcePaymentValidation } from "../check-cybersource-payment-validation"
import { checkMembershipErrorsMSystem } from "../check-membership-errors-m-system"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import { checkOperationErrorsMSystem } from "../check-operation-errors-m-system"
import { MembershipUpgradePlanPayParam } from "./membership-upgrade-plan.models"
import { AnalyticsPurchaseEvent } from "../../../types/analytics-purchase-event"
import { DataLayerService } from "../../modules/share/services/data-layer.service"
import { getTransactionId } from "../utils/get-transaction-id"
import { AppAnalyticsEvents } from "../../../types/analytics-events"

@Injectable({ providedIn: "root" })
export class MembershipUpgradePlanMSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Northampton, ClubApp.Shelby]))
  executeService = inject(ExecuteService)
  dataLayer = inject(DataLayerService)

  setSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.recostValidateSucceeded),
      map(({ response }: ValidateSucceededResponseObject<M.ValidateResponseObject>) => {
        const { Result } = response.response.validateResponse
        const accountDetails = new M.AccountInfo(Result.Membership[0], Result.MembershipOffering)

        return MembershipUpgradePlan.setAccountDetails({ accountDetails })
      }),
    ),
  )

  recostValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.recostValidate),
      withLatestFrom(
        this.store.select(getMembershipUpgradePlan),
        this.store.select(getMembershipNumber),
        this.store.select(getThreePointAuth),
      ),
      switchMap(([, membershipCode, membershipNumber, threePointAuth]) => {
        return of(true).pipe(
          switchMap(() => this.getMemberInfo(membershipNumber, threePointAuth)),
          switchMap((executionData) =>
            this.recostValidation(membershipCode as MembershipCode, executionData, threePointAuth).pipe(
              map((validation) => ({ executionData, validation })),
            ),
          ),
          map(({ validation, executionData }) => {
            return MembershipUpgradePlan.recostValidateSucceeded({
              executionData: executionData,
              response: validation,
            })
          }),
          catchError((error) => of(MembershipUpgradePlan.recostValidateFailed({ error }))),
        )
      }),
    ),
  )

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.pay),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(getPayment),
            this.store.select(getMembershipUpgradePlan),
            this.store.select(getThreePointAuth),
          ),
        ),
      ),
      switchMap(([{ params }, payment, membershipCode, threePointAuth]) =>
        this.pay(params, payment, membershipCode as MembershipCode, threePointAuth).pipe(
          map(() => MembershipUpgradePlan.paySucceeded()),
          catchError((error) => of(MembershipUpgradePlan.payFailed({ error }))),
        ),
      ),
    ),
  )

  pay(
    params: MembershipUpgradePlanPayParam,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
    membershipCode: MembershipCode,
    threePointAuth: ThreePointAuth,
  ) {
    const membershipEvent: MembershipMOperationExecuteChangeEventPayload = {
      executionData: params.executionData,
      threePointAuth,
      method: MembershipMMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
      changePropsData: {
        users: [],
        membershipType: membershipCode.membershipType,
      },
    }
    const paymentPayload: PaymentCybersourceOperationExecuteEventPayload = {
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(params.formValues.billing?.billingTo?.address1),
          address2: String(params.formValues.billing?.billingTo?.address2),
          administrativeArea: String(params.formValues.billing?.billingTo?.state),
          buildingNumber: "",
          country: "US",
          district: String(params.formValues.billing?.billingTo?.state),
          email: String(params.accountDetails?.email || "fallback@avagate.com"),
          firstName: String(params.formValues.billing?.billingTo?.firstName),
          lastName: String(params.formValues.billing?.billingTo?.lastName),
          locality: String(params.formValues.billing?.billingTo?.city),
          phoneNumber: String(params.accountDetails?.phone.cell),
          postalCode: String(params.formValues.billing?.billingTo?.zipcode),
        },
        amountDetails: {
          totalAmount: String(params.totalCost),
          currency: "USD",
        },
        creditCardBrandedName: payment.formValues?.card?.cardName || "",
      },
    }
    const payload: OperationExecutePayload = {
      membershipEvent: membershipEvent,
      paymentEvent: paymentPayload,
      operation: Operation.UPDATE,
    }

    return this.executeService.execute<M.ValidateResponseObject, M.ExecutePaymentResponseObject>(payload).pipe(
      map(({ validateObject, paymentObject, operationObject }) => {
        const paymentError = !!paymentObject?.meta?.isError
        if (paymentError) {
          checkCybersourcePaymentValidation(paymentObject.error)
        }

        const membershipError = validateObject?.meta?.isError
        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject?.error, validateObject)
        }

        const operationError = !!operationObject?.meta?.isError
        if (operationError) {
          checkOperationErrorsMSystem(operationObject.error, operationObject)
        }

        const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
          currency: "USD",
          transaction_id: getTransactionId(validateObject),
          value: params.totalCost,
          items: [
            { quantity: 1, price: params.totalCost, item_id: "primary", item_name: AppAnalyticsEvents.AccountUpgrade },
          ],
          context: "ava-store " + AppAnalyticsEvents.AccountUpgrade,
        }
        this.dataLayer.purchaseEvent(analyticsEventParams)

        return validateObject
      }),
    )
  }

  getMemberInfo(membershipNumber: string, threePointAuth: ThreePointAuth) {
    const payload: MembershipMGetMemberInfoEventPayload = {
      membershipNumber,
      threePointAuth,
      method: MembershipMMethod.GET_MEMBER_INFO,
    }

    return this.executeService
      .membershipQuery<M.MemberInfoResponseObject>(payload)
      .pipe(map((memberInfo) => memberInfo.response.executionData || ""))
  }

  recostValidation(membershipCode: MembershipCode, executionData: string, threePointAuth: ThreePointAuth) {
    const payload: MembershipMValidateChangeEventPayload = {
      method: MembershipMMethod.VALIDATE_CHANGE,
      executionData,
      threePointAuth,
      changePropsData: {
        users: [],
        membershipType: membershipCode.membershipType,
      },
    }

    return this.executeService.membershipQuery<M.ValidateResponseObject>(payload).pipe(
      map((validateObject) => {
        const membershipError = validateObject?.meta?.isError

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject?.error, validateObject)
        }

        if (String(validateObject.error).startsWith("unable to find offering")) {
          throw new RequestError(RequestErrorType.UpgradedMembership, validateObject)
        }

        return validateObject
      }),
    )
  }
}
