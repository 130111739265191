import { PhoneType } from "../types/types"
import { FormMode } from "../../modules/share/form.utils"
import { RequestError } from "../generic-errors"
import { RequestStatus } from "../../../types/request-status"

export enum LinkType {
  ManageEmailPreferences,
  LogOut,
}

export type AccountLink = {
  path: string
  text: string
  type: LinkType
}

export type AccountMenuItem = {
  icon: string
  title: string
  description: string
  url: string
}

export interface AccountState {
  // Address
  addressFormRequestStatus: RequestStatus
  addressFormRequestError: RequestError | null
  editAddress: boolean
  // Phone
  phoneFormRequestStatus: RequestStatus
  phoneFormRequestError: RequestError | null
  editPhoneType: PhoneType | null
  // Email
  emailFormRequestStatus: RequestStatus
  emailFormRequestError: RequestError | null
  editEmailFormMode: FormMode
}

export type AccountEmailValues = Partial<{ email: string }>
export type AccountPhoneValues = Partial<{ [key in PhoneType]: string }>
export type AccountAddressValues = {
  address1: string
  address2: string
  city: string
  state: string
  zipcode: string
}
