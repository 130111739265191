import { usAutocompletePro, core } from "smartystreets-javascript-sdk"
import * as SmartySDK from "smartystreets-javascript-sdk"
import { environment } from "../../../../environments/environment"
const SmartyCore = SmartySDK.core

export class SmartStreetsClient implements core.Client<usAutocompletePro.Lookup, usAutocompletePro.Lookup> {
  sender: any

  send(lookup: usAutocompletePro.Lookup): Promise<usAutocompletePro.Lookup> {
    throw new Error("Method not implemented.")
  }
}

export function smartyStreetsProvider() {
  return {
    provide: SmartStreetsClient,
    useFactory: () => {
      if (environment.smarty) {
        const credentials = new SmartyCore.SharedCredentials(environment.smarty?.license)
        const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([environment.smarty.authId])

        return clientBuilder.buildUsAutocompleteProClient()
      }

      return null
    },
  }
}
