import { CanActivateChildFn } from "@angular/router"
import { inject } from "@angular/core"
import { of } from "rxjs"
import { Title } from "@angular/platform-browser"
import { environment } from "../../../../environments/environment"

export const setPageTitle: CanActivateChildFn = () => {
  const title = inject(Title)

  if (environment.appEnv === "test" && title.getTitle().trim() === "") {
    title.setTitle(environment.appEnv)
  }

  return of(true)
}

export default setPageTitle
