import { Component, Inject, Input, OnInit } from "@angular/core"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { GLOBAL_RX_STATE, GlobalState, StateService } from "../../services/state"
import { BlockState } from "../../block/services/state"
import { combineLatestWith, Observable, of, pluck } from "rxjs"
import { ComparisonChart, ComparisonChartService } from "./service"
import { tap } from "rxjs/operators"
import {
  QuillEditorOptions, QuillEditorService, QuillEditorStyles
} from "../quill-editor/service"
import { MultiblockStyle } from "../../block/services/style"
import { ScreenSize } from "../../services/style"
import { FieldType } from "../../services/field"
import { Field } from "../../block/services/block"

interface ComponentState {
  showAllRows: boolean
}

@Component({
  selector: "ava-comparison-chart-view",
  templateUrl: "./view.html",
  providers: [RxState, RxEffects],
})
export class ComparisonChartViewComponent implements OnInit {
  @Input() stateId: string | undefined
  @Input() rowId: string | undefined
  globalState$ = this.globalState.select()
  blockState: RxState<BlockState> | undefined
  textAreaOptions: QuillEditorOptions | undefined
  textAreaStylesFunction: (() => QuillEditorStyles) | undefined

  comparisonChart: ComparisonChart | undefined
  rowsLengthArray: any[] = []
  columnsLengthArray: any[] = []
  ScreenSize = ScreenSize

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private componentState: RxState<ComponentState>,
    private rxEffects: RxEffects,
    private stateService: StateService,
    private quillEditorService: QuillEditorService,
    private styleService: MultiblockStyle,
    private thisService: ComparisonChartService,
  ) {
    componentState.set("showAllRows", () => false)
  }

  get comparisonChartRows$(): Observable<any> {
    if (this.rowId && this.blockState) {
      return this.blockState.select("blockValue", "rows", this.rowId)
        .pipe(
          combineLatestWith(
            this.componentState.select("showAllRows"),
          ),
          tap(([blockRow, showAllRows]: [Field, boolean]) => {
            const rowsLengthArray = []
            if (blockRow.fieldType === FieldType.COMPARISON_CHART) {
              const comparisonChart = JSON.parse(JSON.stringify(blockRow))
              // console.log(blockRow)
              // const headerRowCopy = JSON.parse(JSON.stringify(comparisonChart.rows[0]))
              // headerRowCopy.columns[0].heading = ""
              // comparisonChart.rows[comparisonChart.rows.length] = headerRowCopy
              // console.log(comparisonChart)

              const numberOfRows = showAllRows ? comparisonChart.rows.length : 5
              const rowsLength = comparisonChart.rows.slice(0, numberOfRows).length
              const columnsLength = comparisonChart.rows[0].columns.length

              this.rowsLengthArray = Array(rowsLength)
              this.columnsLengthArray = Array(columnsLength)
              this.comparisonChart = comparisonChart
            }
          })
        )
    }
    return of(null)
  }

  ngOnInit(): void {
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId]
    }

    this.textAreaOptions = this.thisService.textAreaOptions
    this.textAreaStylesFunction = this.thisService.textAreaStyles

    this.rxEffects.register(this.comparisonChartRows$)
  }

  showAllRows(): void {
    this.componentState.set("showAllRows", () => true)
  }

}
