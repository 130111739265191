import { inject, Injectable } from "@angular/core"
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms"
import { FormGroupValue } from "../form.utils"
import { ValidatorService } from "../form/validators/validator.service"

export interface CardForm {
  number: FormControl<string>
  cvv: FormControl<string>
  month: FormControl<number>
  year: FormControl<number>
  cardName: FormControl<string>
  cardType: FormControl<string>
}
export interface PaymentForm {
  autoRenew: FormControl<boolean>
  card: FormGroup<CardForm>
}
export const getPaymentCardMonths = () => {
  const options: { label: string; value: number }[] = []

  for (let index = 0; index <= 11; index++) {
    const month = index + 1
    const stringMonth = String(month)
    const monthLabel = stringMonth.length === 1 ? "0" + stringMonth : stringMonth

    options.push({ label: monthLabel, value: month })
  }

  return options
}
export const getPaymentCardYears = () => {
  const options: { label: string; value: number }[] = []
  const now = new Date()
  const expirationYear = now.getFullYear()

  for (let index = 0; index <= 20; index++) {
    const year = expirationYear + index

    options.push({ label: String(year), value: year })
  }

  return options
}

@Injectable({ providedIn: "root" })
export class PaymentFormVm {
  fb = inject(FormBuilder)
  validator = inject(ValidatorService)
  formGroup = this.create()

  create(partialValues: Partial<FormGroupValue<PaymentForm>> = {}) {
    const form = this.fb.group<PaymentForm>(
      {
        autoRenew: new FormControl(true, { nonNullable: true }),
        card: this.fb.group({
          number: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
          cvv: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
          month: new FormControl(0, { nonNullable: true, validators: [Validators.required, Validators.min(1)] }),
          year: new FormControl(0, {
            nonNullable: true,
            validators: [Validators.required, Validators.min(new Date().getFullYear())],
          }),
          cardName: new FormControl("", { nonNullable: true }),
          cardType: new FormControl("", { nonNullable: true }),
        }, { validators: [this.validator.minMonthValue("month", "year")] }),
      },
    )

    form.patchValue(partialValues)

    return form
  }
}
