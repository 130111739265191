import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { JoinForm, JoinFormStep, JoinPage, JoinPayParams } from "./join.models"
import { ValidateSucceededResponseObject } from "../types/types"
import { ConfirmedMember } from "../../modules/share/membership-card-detail-list/types"
import { changedForm, setFormActiveStep } from "../../modules/share/ngrx-helpers"
import { RequestError } from "../generic-errors"
import { FormGroupValue } from "../../modules/share/form.utils"
import { MembershipsLevel } from "@aaa/emember/store-price-offers"
import { Cybersource } from "../cybersource.type"

export const JoinActions = createActionGroup({
  source: "Join",
  events: {
    // Forms Actions
    // ...formActions<JoinFormStep, JoinForm >(),
    ...setFormActiveStep<JoinFormStep>(),
    ...changedForm<JoinForm>(),

    // Form sections actions
    "Validate Form Step": props<{
      validateStep: JoinFormStep
      currentStep: JoinFormStep
      formValues: FormGroupValue<JoinForm>
    }>(),
    "Add Associate": props<{ quantity: number }>(),
    "Remove Associate": props<{ index: number }>(),
    "Validate Gift Recipient": emptyProps(),
    "Validate Gift Recipient Succeeded": emptyProps(),
    "Validate Gift Recipient Info Failed": emptyProps(),
    "Validate Member Info": emptyProps(),
    "Validate Member Info Succeeded": emptyProps(),
    "Validate Member Info Failed": emptyProps(),
    "Validate Billing Info": emptyProps(),
    "Validate Billing Info Succeeded": emptyProps(),
    "Validate Billing Info Failed": emptyProps(),
    //[START] GET OFFERS ONLY FOR M SYSTEM
    "Validate Get Offers": emptyProps(),
    "Validate Get Offers Succeeded": props<{ executionData: string; offeringsResponse: any }>(),
    "Validate Get Offers Failed": props<{ error: RequestError }>(),
    //[START] GET OFFERS ONLY FOR M SYSTEM
    "Validate Promo Code": props<{ formValues: FormGroupValue<JoinForm> }>(),
    "Recost Validate": props<{ formValues: FormGroupValue<JoinForm> }>(),
    "Recost Validate Succeeded": props<ValidateSucceededResponseObject>(),
    "Recost Validate Failed": props<{ error: RequestError }>(),
    "Retry Recost Validate": props<{ formValues: FormGroupValue<JoinForm> }>(),
    "Update Recost Validate": props<{ formValues: FormGroupValue<JoinForm> }>(),
    Pay: props<{ params: JoinPayParams }>(),
    "Pay Succeeded": props<{ membership: any; payment: Cybersource.ExecutePaymentResponseObject | null }>(),
    "Pay Failed": props<{ error: RequestError }>(),
    "Pay Gift": props<{ params: JoinPayParams }>(),
    "Pay Gift Succeeded": props<{ membership: any }>(),
    "Pay Gift Failed": props<{ error: RequestError }>(),
    "Set Confirmed Members": props<{ members: ConfirmedMember[] }>(),
    "Reset Confirmed members": emptyProps(),
    "Set Total Cost": props<{ totalCost: number }>(),
    "Set Page": props<{ page: JoinPage }>(),
    "Changed Membership Level": props<{ level: string; rv: boolean }>(),
    "Reset Form": emptyProps(),
    "Reset Error": emptyProps(),
    // Query actions
    "Changed Query Promo Code": props<{ promoCode?: string }>(),
    "Changed Query Coupon Code": props<{ couponCode?: string; programCode?: string }>(),
    "Changed Query Membership": props<{ membershipLevel: MembershipsLevel }>(),
  },
})
