import { NgModule } from "@angular/core"
import { AppComponent } from "./app.component"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { SETTINGS as FIRESTORE_SETTINGS } from "@angular/fire/compat/firestore"
import { environment } from "../../../environments/environment"
import { RouterModule, RouterOutlet } from "@angular/router"
import { rxStateProvider } from "../../modules/share/providers/rx-state.provider"
import { CommonModule } from "@angular/common"
import { AppStoreModule } from "../../store/store.module"
import { AngularFireAnalyticsModule, UserTrackingService } from "@angular/fire/compat/analytics"
import { AngularFireAuthModule } from "@angular/fire/compat/auth"
import { AngularFireFunctionsModule } from "@angular/fire/compat/functions"
import { AngularFireModule } from "@angular/fire/compat"
import { AngularFireStorageModule } from "@angular/fire/compat/storage"
import { AngularFirestoreModule } from "@angular/fire/compat/firestore"
import { QuillModule } from "ngx-quill"
import { en_US, NZ_I18N } from "ng-zorro-antd/i18n"
import { CONFIG as AngularFireAnalytics_CONFIG } from "@angular/fire/compat/analytics"
import { HOOSIER_RX_STATE, HoosierState } from "../../modules/join-renew/hoosier/hoosier.service"
import { NzModalService } from "ng-zorro-antd/modal"
import { MultiblockModule } from "../../modules/multiblocks/multiblock.module"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { EmemberModule } from "../../modules/emember/emember.module"
import { HttpClientModule } from "@angular/common/http"
import { NgxLocationStrategiesModule } from "../../services/location-strategy/ngx-location-strategies.module"

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    RouterOutlet,
    CommonModule,
    AppStoreModule,
    RouterModule.forRoot([]),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule,
    QuillModule.forRoot(),
    HttpClientModule,
    MultiblockModule,
    EmemberModule,
    NgxLocationStrategiesModule.forRoot({ routeQueryName: "app", stickyParams: ["page"] }),
  ],
  providers: [
    // added provider for app-component just to work
    NzModalService,
    rxStateProvider(),
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: FIRESTORE_SETTINGS,
      useValue: environment.emulator
        ? {
            host: "localhost:8080",
            ssl: false,
          }
        : undefined,
    },
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: AngularFireAnalytics_CONFIG,
      useValue: {
        send_page_view: false,
        anonymize_ip: true,
      },
    },
    {
      provide: HOOSIER_RX_STATE,
      useFactory: () => new RxState<HoosierState>(),
    },
    RxEffects,
    RxState,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
