import {
  AfterViewInit, Component, ElementRef, HostListener, Inject, Input, ViewChild, ViewContainerRef
} from "@angular/core"
import { tap } from "rxjs/operators"
import { Observable } from "rxjs"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { Border } from "../../services/style"
import { FormService } from "../../services/form"
import { BlockState } from "../services/state"
import { PersonalizeService } from "../../options/personalize/service"
import { GLOBAL_RX_STATE, GlobalState, StateService } from "../../services/state"

@Component({
  selector: "ava-block-wrapper-page-width-wrapper",
  templateUrl: "./page-width-wrapper.html",
  providers: [RxState, RxEffects]
})
export class BlockWrapperViewComponent implements AfterViewInit {
  @Input() stateId: string | undefined
  @Input() blockId: string | undefined
  @Input() pageStateId: string | undefined
  @Input() pageColumnIndex: number | undefined
  @Input() pageRowId: string | undefined
  @Input() pageRowIndex: number | undefined

  @ViewChild("blockAnchor") blockAnchorRef: ElementRef | undefined
  @ViewChild("containerWidth") containerWidthRef: ElementRef | undefined
  @ViewChild("toolbarContainer", { read: ViewContainerRef }) toolbarContainerRef: ViewContainerRef | undefined
  @ViewChild("editFormContainer", { read: ViewContainerRef }) editFormContainerRef: any

  blockState: RxState<BlockState> | undefined
  blockState$: Observable<BlockState> | undefined
  globalState$ = this.globalState.select()
  border: Border | undefined
  // previewModeStyles: { margin: string, width: number } = { margin: undefined, width: undefined }

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rxEffects: RxEffects,
    private formService: FormService,
    private stateService: StateService,
    private personalizeService: PersonalizeService,
  ) {
    this.setBorder()
  }

  get toolbar$(): Observable<any> {
    return this.globalState.select("adminMode")
      .pipe(
        tap(adminMode => {
          if (adminMode && this.stateId && this.blockState) {
            this.formService.loadToolbar(this.toolbarContainerRef, this.stateId)
            this.blockState.set("blockAnchorRef", () => this.blockAnchorRef) // TODO: why is this.blockState undefined?
          }
        })
      )
  }

  ngAfterViewInit(): void {
    if (this.pageRowId) {
      this.blockState = this.stateService.states[this.pageRowId]
    }
    if (this.blockState) {
      this.blockState$ = this.blockState.select()
      this.rxEffects.register(this.toolbar$)
    }
  }

  @HostListener("window:resize")
  onResize(): void {
    const width = this.containerWidthRef?.nativeElement.getBoundingClientRect().width
    if (width && width !== this.globalState.get("pageWidth")) {
      // this.globalState.set("pageWidth", () => width)

/*
      const previewSize = this.globalState.get("previewSize")
      if (this.globalState.get("previewMode") && previewSize && previewSize < width) {
        this.globalState.set({ pageWidth: previewSize })
      }
*/
    }
  }

  /*
    oldBlockState = (): BlockState => {
      return {
        passwordAuth: this.state.passwordAuth,
        personalize: this.block?.status.personalize,
        personalized: this.block?.status.personalized,
        protected: this.block?.status.protected,
        selected: this.state.selected,
        unpublished: this.block?.status.unpublished,
      }
    }
  */

  showToolbar(): void {
    if (this.globalState.get("adminMode") && !this.globalState.get("editorVisible") && this.blockState) {
      this.globalState.set("editorStateId", () => this.stateId || "")
      this.blockState.set("selected", () => true)
    }
  }

  hideToolbar(): void {
    if (this.globalState.get("adminMode") && !this.globalState.get("editorVisible") && this.blockState) {
      this.blockState.set("selected", () => false)
    }
  }

  /*
    showBlock = (): boolean => {
      if (this.block) {
        return this.stateService.showBlock(this.blockState())
      }
      return false
    }
  */

  pointerUp = (event: Event): void => {
    // if (this.state.selectedField) {
    //   event.preventDefault()
    // }
  }

  pointerDown = (): void => {
    // if (this.state.selectedField) {
    //   this.stateService.setState(this.stateId, { selectedField: undefined })
    //   return
    // }
    // if (!this.previewMode && this.block.blockType !== "multiblock") {
    //   this.formService.showEditModal(this.state.modalRef, this.stateId, null)
    // }
  }

  openCreatePanel(event: Event, rowIndex: number): void {
    event.stopPropagation()
    this.globalState.set({
      createPanelRowIndex: rowIndex,
      createPanelVisible: true,
      loadCreatePanel: true,

      editorStateId: undefined,
      editorVisible: false,
      selectedField: "",
      templateEditor: false,
      templateEditSelected: undefined,
    })
  }

  setBorder(): void {
    const boxShadow = "inset 0 0 0 4px green"
    this.border = {
      boxShadow: "none",
      border: "none",
      borderRadiusPx: 10,
      outline: "none"
    }
  }

}
