import { Inject, Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { EventName, EventPayload } from "@aaa/interface-joinRenew-joinRenewLib"
import { GLOBAL_RX_STATE, GlobalState } from "../../services/state"
import { RxState } from "@rx-angular/state"
import "firebase/firestore"
import { HOOSIER_RX_STATE, HoosierState } from "./hoosier/hoosier.service"
import { environment } from "../../../environments/environment"

/**
 * TODO: refactor with all uppercase enum and lookup function for lowercase values
 * the consumer of these values is case sensitive, must be lowercase
 * type = NzFormControlStatusType
 * example: <nz-form-control [nzValidateStatus]="success">
 */
export enum ValidateStatus {
  SUCCESS = "success",
  WARNING = "warning", // would be for INVALID with some value in form.controls.errors object
  ERROR = "error",
  VALIDATING = "validating",
  NULL = "null", // "NULL" also works, but null does not,

  // these 4 are mapped to match FormControl["status"] options
  VALID = "success",
  INVALID = "error",
  PENDING = "validating",
  DISABLED = "null", // "null" removes validation feedback via [nzValidateStatus]
}

export enum OpStatus {
  RUNNING = "RUNNING",
  STOPPED = "STOPPED",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
  IS_ERROR = "IS_ERROR",
  RETRY = "RETRY",
}

@Injectable({ providedIn: "root" })
export class JoinRenewService {
  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private http: HttpClient,
  ) {}

  async sendToEventCoordinatorReceiver<T extends EventPayload>(eventName: EventName, payload: T): Promise<unknown> {
    const activeFlow = this.hoosierState.get("activeFlow") || null,
      activeView = this.hoosierState.get("activeView") || null,
      // Partial<Event>
      body = {
        eventName,
        payload,
        sessionId: this.globalState.get("userSession", "sessionId"),
        created: null, //TODO
        afAuthIdToken: this.globalState.get("afAuthIdTokenResult")?.token || "",
        context: {
          activeFlow: String(activeFlow),
          activeView: String(activeView),
        },
      },
      headers = new HttpHeaders({}),
      url = this.globalState.get("environment", "cloudFunctionsURL") + "/joinRenew-eventCoordinatorReceiver"

    if (environment.emulator || !environment.production) {
      console.groupCollapsed(`[MULTIBLOCKS] ${eventName}`)
      console.log({ url, body })
      console.groupEnd()
    }

    return lastValueFrom(this.http.request("POST", url, { headers, body }))
  }
}
