<ng-container *ngIf="hoosierState$ | async as h">
  <div style="display: flex; gap: 16px; flex-direction: column">
    <ng-container
      *ngIf="h.activeFlow === Flow.JOIN || h.activeFlow === Flow.GIFT || h.activeFlow === Flow.ACCOUNT"
    >
      <ava-hoosier-checkbox [options]="options.useMemberInfoForBilling">
        Billing address same as household address
      </ava-hoosier-checkbox>

      <ng-container *ngIf="!useMemberInfoForBillingFormControl.value">
        <ava-hoosier-input [options]="options.firstName"></ava-hoosier-input>
        <ava-hoosier-input [options]="options.lastName"></ava-hoosier-input>
        <ava-hoosier-input [options]="options.address1"></ava-hoosier-input>
        <ava-hoosier-input [options]="options.address2"></ava-hoosier-input>
        <ava-hoosier-input [options]="options.locality"></ava-hoosier-input>
        <div style="display: flex; gap: 10px">
          <ava-hoosier-select style="flex: 1" [options]="options.administrativeArea"></ava-hoosier-select>
          <ava-hoosier-input style="flex: 1" [options]="options.postalCode"></ava-hoosier-input>
        </div>
      </ng-container>
    </ng-container>


    <ng-container
      *ngIf="h.activeFlow === Flow.QUICK_RENEW"
    >
      <ava-hoosier-input [options]="options.firstName"></ava-hoosier-input>
      <ava-hoosier-input [options]="options.lastName"></ava-hoosier-input>

<!--
      <ava-hoosier-input [options]="inputOptions.address1"></ava-hoosier-input>
      <ava-hoosier-input [options]="inputOptions.address2"></ava-hoosier-input>
      <ava-hoosier-input [options]="inputOptions.locality"></ava-hoosier-input>
      <div style="display: flex; gap: 10px">
        <ava-hoosier-select style="flex: 1" [options]="selectOptions.administrativeArea"></ava-hoosier-select>
        <ava-hoosier-input style="flex: 1" [options]="inputOptions.postalCode"></ava-hoosier-input>
      </div>
      <ava-hoosier-input [options]="inputOptions.email"></ava-hoosier-input>
-->

    </ng-container>
  </div>
</ng-container>
