import { RxState } from "@rx-angular/state"
import { GLOBAL_RX_STATE, GlobalState } from "../../../services/state"

export const instanceRxState = new RxState<GlobalState>()

export function rxStateProvider() {
  return {
    provide: GLOBAL_RX_STATE,
    useFactory: () => instanceRxState,
  }
}
