<div #componentWidth>
  <ng-container *ngIf="globalState$ | async as g">
    <ng-container *ngIf="parentBlockState$ | async as p">
      <ng-container *ngIf="blockState$ | async as b">
        <div
          *ngIf="b.blockValue?.options && b.showBlock && p.type && b.style?.position"
          [style.padding-top.px]="b.blockValue.options.boxModel.margin.top.px"
          [style.padding-bottom.px]="b.blockValue.options.boxModel.margin.bottom.px"
          [style.background-color]="b.blockValue.options.backgroundColor?.rgba"
          style="position: relative; width: 100%"
          [style.box-shadow]="highlight[stateId + '--'] ? border.boxShadow : undefined"
          [style.border]="border.border"
          [style.border-radius.px]="highlight[stateId + '--'] ? border.borderRadiusPx : undefined"
          [style.outline]="border.outline"
        >
          <!--  [style.background-color]="nestedBlock ? 'transparent' : backgroundColor?.rgba"-->
<!--
          <div
            [id]="stateId + '-c' + b.parentColumnIndex.toString() + '-r' + b.parentRowIndex.toString()"
            style="display: inline-block; position: absolute; top: -40px"
          ></div>
-->

          <ng-container *ngIf="b.background?.url">
            <div
              [style.background-repeat]="'no-repeat'"
              [style.background-position]="'center top'"
              [style.background-size]="'100% 100%' // '100% 100%' or 'contain' or 'cover'"
              [style.background-image]="b.background.url"
              [style.background-color]="'#ccc'"
              [style.height.px]="backgroundImageHeight"
              [style.width.px]="b.blockWidth // creates right page margin"
            ></div>
          </ng-container>

          <div
            #columnsWrapper
            [style.left.px]="b.style.position.left"
            [style.width.px]="b.blockWidth // creates right page margin"
            [style.bottom.px]="b.style.position.bottom"
            [style.top.px]="b.style.position.top"
            [style.position]="b.style.positionType"

            [style.padding-bottom.px]="b.blockValue.options.boxModel.padding.bottom.px"
            [style.padding-top.px]="b.blockValue.options.boxModel.padding.top.px"
          >
            <div
              *ngIf="!b.carousel"
              style="display: flex; flex-wrap: wrap; justify-content: center"
              [style.gap.px]="columnGap"
            >
              <ng-container
                *ngFor="let column of b.blockValue.columns; let columnIndex = index"
              >

                <ava-multiblock-view-column
                  [stateId]="stateId"
                  [columnIndex]="columnIndex"
                ></ava-multiblock-view-column>
              </ng-container>
            </div>

<!--
            <swiper
              *ngIf="b.carousel"
              #swiperRef
              [config]="config"
              (swiper)="onSwiper($event)"
              (slideChange)="onSlideChange()"
            >
              <ng-template
                swiperSlide
                *ngFor="let column of b.blockValue.columns; let columnIndex = index"
              >
                <ava-multiblock-view-column
                  [stateId]="stateId"
                  [columnIndex]="columnIndex"
                ></ava-multiblock-view-column>
              </ng-template>
              &lt;!&ndash;        <div class="swiper-button-prev"></div>&ndash;&gt;
              &lt;!&ndash;        <div class="swiper-button-next"></div>&ndash;&gt;
            </swiper>
-->

          </div>

          <!-- shared block ribbon/label -->
          <div
            *ngIf="g.adminMode && b.blockValue.blockShared"
            style="position: absolute; top: -50px; left: -50px; height: 100px; width: 100px; transform: rotate(-45deg); display: flex; flex-direction: column"
            nz-tooltip=""
            [nzTooltipTitle]="sharedBlockIds"
            nzTooltipPlacement="bottom"
          >
            <div
              style="margin-top: auto; font-size: 14px; color: white; background-color: green; width: 100%; text-align: center; cursor: pointer; padding: 2px"
            >
              shared
            </div>
            <ng-template #sharedBlockIds>
              <div
                *ngFor="let pathName of b.blockValue.pathNames"
                style="cursor: pointer"
                (click)="navigateTo(pathName)"
              >
                {{ pathName }}
              </div>
            </ng-template>
          </div>

          <!--          <ng-container-->
          <!--            [ngTemplateOutlet]="joinRenew"-->
          <!--          ></ng-container>-->

        </div>
      </ng-container>
    </ng-container>
  </ng-container>

</div>
