import { ClubApp } from "@aaa/emember/types"
import { ConnectSuiteMembershipLevel } from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import { MzpMembershipLevel } from "@aaa/interface-joinRenew-membership-membershipMzp"
import { M } from "../../m.type"

export function getTowMiles(clubId: ClubApp, level: string) {
  switch (clubId) {
    case ClubApp.Hoosier:
      switch (level) {
        case ConnectSuiteMembershipLevel.CLASSIC:
          return 3
        case ConnectSuiteMembershipLevel.PLUS:
          return 100
        case ConnectSuiteMembershipLevel.PREMIER:
          return 200
      }
      break
    case ClubApp.MidStates:
      switch (level) {
        case MzpMembershipLevel.BASIC:
          return 3
        case MzpMembershipLevel.PLUS:
        case MzpMembershipLevel.PLUS_RV:
          return 100
        case MzpMembershipLevel.PREMIER:
        case MzpMembershipLevel.PREMIER_RV:
          return 200
      }
      break
    case ClubApp.Northampton:
      switch (level) {
        case M.MMembershipLevel.BASIC:
          return 3
        case M.MMembershipLevel.PLUS:
          return 100
        case M.MMembershipLevel.PREMIER:
          return 200
      }
      break
    case ClubApp.Shelby:
      switch (level) {
        case M.MMembershipLevel.BASIC:
          return 3
        case M.MMembershipLevel.PLUS:
          return 100
        case M.MMembershipLevel.PREMIER:
          return 200
      }
      break
  }

  return 0
}
