<div [formGroup]="formGroup" *ngIf="errorMessages$ | async as errorMessages" class="ava-column ava-gap-2">
  <ava-form-checkbox formControlName="sameBillingAddress" formLabel="Billing address same as household address" />

  <ng-container *ngIf="!formGroup?.controls?.sameBillingAddress?.value">
    <div formGroupName="billingTo" class="ava-column ava-gap-2">
      <div class="ava-column ava-gap-2">
        <ava-form-input
          [errorMessages]="errorMessages.firstName"
          placeholder="First Name"
          formControlName="firstName"
        />
        <ava-form-input
          [errorMessages]="errorMessages.lastName"
          class="ava-flex"
          placeholder="Last Name"
          formControlName="lastName"
        />
        <ava-form-input [errorMessages]="errorMessages.address1" placeholder="Address 1" formControlName="address1" />
        <ava-form-input placeholder="Address 2" formControlName="address2" />
        <ava-form-input [errorMessages]="errorMessages.city" placeholder="City" formControlName="city" />
      </div>
      <div class="ava-row ava-gap-1">
        <ava-form-select
          [errorMessages]="errorMessages.state"
          [options]="states"
          [showSearch]="true"
          placeholder="State"
          formControlName="state"
          class="ava-flex"
        />
        <ava-form-input
          [errorMessages]="errorMessages.zipcode"
          placeholder="Zip Code"
          formControlName="zipcode"
          class="ava-flex"
        />
      </div>
    </div>
  </ng-container>
</div>
