import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { tap } from "rxjs/operators"
import { AnalyticsActions } from "./analytics.actions"
import { DataLayerService } from "../../modules/share/services/data-layer.service"

@Injectable({ providedIn: "root" })
export class AnalyticsEffects {
  dataLayerService = inject(DataLayerService)
  actions$ = inject(Actions)
  store = inject(Store)

  loadMembership$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.viewedPage),
        tap(({ eventParams }) => this.dataLayerService.viewedPage(eventParams)),
      ),
    { dispatch: false },
  )
}
