import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { map, of, switchMap, withLatestFrom } from "rxjs"
import { Store } from "@ngrx/store"
import { PricePreviewActions } from "./price-preview.actions"
import { PricePreview } from "../types/types"
import { SaveCacheSettings } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  connectSuiteMembershipCodes,
  MembershipConnectSuiteMethod,
  MembershipConnectSuitePricePreviewsEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import { getCurrentTimeStamp } from "../utils/get-current-timestamp"
import { filterByClubId } from "../utils/filter-by-club-id"
import { ConnectSuite } from "../connect-suite.type"
import { ExecuteService } from "../services/execute.service"
import { ClubApp } from "@aaa/emember/types"
import { catchError } from "rxjs/operators"
import { RequestError, RequestErrorType } from "../generic-errors"
import { getZipcode } from "@aaa/emember/store-membership"

@Injectable({ providedIn: "root" })
export class PricePreviewHoosierEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubId(this.store, ClubApp.Hoosier))
  executeService = inject(ExecuteService)

  public loadPricePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricePreviewActions.load),
      withLatestFrom(this.store.select(getZipcode)),
      switchMap(([{ payload }, zipcode]) => {
        const cacheSettings: SaveCacheSettings = {
          cacheType: "save",
          context: "globalContext",
          maxAge: getCurrentTimeStamp(-24 * 3600 * 1000),
        }
        const requestPayload: MembershipConnectSuitePricePreviewsEventPayload = {
          cacheSettings: cacheSettings,
          method: MembershipConnectSuiteMethod.PRICE_PREVIEWS,
          postalCode: zipcode,
          couponCode: payload.couponCode,
          promoCode: payload.promoCode,
        }

        return this.executeService.membershipQuery<ConnectSuite.PricePreviewsResponseObject>(requestPayload).pipe(
          map(({ error, response }) => {
            if (error) {
              return PricePreviewActions.loadFailed({ error: new RequestError(RequestErrorType.Error) })
            }

            const responseParsed = ConnectSuite.PricePreviewResponseSchema.parse(response)

            if (responseParsed.promoNoEnrollPrices[0].error?.responseCode) {
              let error
              switch (responseParsed.promoNoEnrollPrices[0].error?.responseCode) {
                case "202":
                  error = new RequestError(RequestErrorType.PricePreviewReservedCouponCode)
                  break
                case "203":
                  error = new RequestError(RequestErrorType.PricePreviewRedeemedCouponCode)
                  break
                case "211":
                  error = new RequestError(RequestErrorType.PricePreviewInvalidCouponCode)
                  break

                default:
                  error = new RequestError(RequestErrorType.Error)
              }

              return PricePreviewActions.loadFailed({ error })
            }

            const enrollFee = responseParsed.enrollFee
            const pricesSource = responseParsed.promoCode
              ? responseParsed.promoNoEnrollPrices
              : responseParsed.noPromoNoEnrollPrices
            const prices = pricesSource.map<PricePreview>((price) => {
              const membershipCode =
                connectSuiteMembershipCodes.find((item) => item.level === price.level && item.rv === price.rv)
                  ?.duesComponentCode || ""
              return {
                membershipCode,
                promoCode: "",
                promoPrice: 0,
                promoDescription: "",

                primaryFeePrice: price.primaryFee,
                primaryFeeCode: "",
                primaryFeeDescription: "",

                associateFeePrice: price.associateFee || 0,
                associateFeeCode: "",
                associateFeeDescription: "",

                enrollmentFeePrice: enrollFee,
                enrollmentFeeCode: "",
                enrollmentFeeDescription: "",

                primaryMedicalPrice: 0,
                primaryMedicalCode: "",
                primaryMedicalDescription: "",

                associateMedicalPrice: 0,
                associateMedicalCode: "",
                associateMedicalDescription: "",

                prices: price.prices.map((item) => ({
                  price: item.price || 0,
                  members: item.members,
                })),
              } as PricePreview
            })

            return PricePreviewActions.loadSucceeded({ prices })
          }),
          catchError(() => of(PricePreviewActions.loadFailed({ error: new RequestError(RequestErrorType.Error) }))),
        )
      }),
    ),
  )
}
