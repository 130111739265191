import { Injectable } from "@angular/core"
import { BlockState } from "./state"
import { Block } from "./block"

@Injectable({
  providedIn: "root"
})
export class ConfigService {

  get blockStateDefaults(): BlockState {
    return {
      migrate: undefined,

      accordionStyles: {
        transform: "",
        transition: ""
      },
      background: {
        height: 0,
        width: 0,
        image: undefined,
        url: ""
      },
      backgroundImageRows: [],
      block: {} as Block,
      blockAnchorRef: undefined,
      blockEditRevision: undefined,
      blockValue: {} as Block,
      blockWidth: 0,
      carousel: false,
      columnsAsCards: undefined,
      customizing: false,
      editor: false,
      element: {},
      elementRef: undefined,
      fieldsEnabled: {},
      form: undefined,
      imageOptions: {},
      loadForm: false,
      managing: false,
      modalActive: false,
      modalRef: undefined,
      modified: false,
      nested: false,
      newItem: undefined,
      options: {
        theme: []
      },
      parentBlockStateId: "",
      parentColumnIndex: 0,
      parentRowId: "",
      parentRowIndex: 0,
      ready: false,
      selected: false,
      showBlock: false,
      style: {
        backgroundColor: "",
        backgroundHeight: 0,
        backgroundWidth: 0,
        columnDivider: {
          position: { bottom: undefined, left: 0, right: undefined, top: 0 },
          positionType: undefined,
        },
        columns: {
          backgroundColor: "",
          color: "",
          display: { flexWrap: "wrap", gap: 0, justifyContent: "center" },
          displayType: "flex",
          overflow: "hidden",
          position: { bottom: undefined, left: 0, right: undefined, top: 0 },
          positionType: undefined,
          width: 0
        },
        margin: { bottom: 0, left: 0, right: 0, top: 0 },
        paddingInner: { bottom: 0, left: 0, right: 0, top: 0 },
        paddingOuter: { bottom: 0, left: 0, right: 0, top: 0 },
        position: { bottom: undefined, left: 0, right: undefined, top: 0 },
        positionType: "relative",
        width: 0
      },
      type: undefined,
    }
  }

}
