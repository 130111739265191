import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { of, withLatestFrom } from "rxjs"
import { catchError, map, switchMap } from "rxjs/operators"
import { ExecuteService } from "../services/execute.service"
import { ClubApp } from "@aaa/emember/types"
import { Operation, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import { getAccountDetails, getShortMembershipNumber } from "@aaa/emember/store-account"
import { RequestError, RequestErrorType } from "../generic-errors"
import { MembershipARActions } from "./membership-auto-renew.actions"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import {
  MembershipMzpGetMembershipCostsChange,
  MembershipMzpMethod,
  MembershipMzpOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipMzp"
import { Mzp } from "../mzp.type"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { getClearCacheSettings } from "../utils/get-cache-settings"
import { checkCybersourcePaymentValidation } from "../check-cybersource-payment-validation"
import { FormGroupValue } from "../../modules/share/form.utils"
import { MembershipAutoRenewForm } from "./membership-auto-renew.models"
import { AccountDetails } from "@aaa/emember/store-types"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { getMembershipNumber } from "@aaa/emember/store-membership"
import { getMembershipAutoRenewFormValues } from "./membership-auto-renew.selectors"
import { getPayment } from "@aaa/emember/store-payment"
import { AnalyticsPurchaseEvent } from "../../../types/analytics-purchase-event"
import { DataLayerService } from "../../modules/share/services/data-layer.service"
import { getTransactionId } from "../utils/get-transaction-id"
import { AppAnalyticsEvents } from "../../../types/analytics-events"

@Injectable({ providedIn: "root" })
export class MembershipAutoRenewMzpSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.MidStates]))
  executeService = inject(ExecuteService)
  dataLayer = inject(DataLayerService)

  removeCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipARActions.removeCard),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getShortMembershipNumber)))),
      switchMap(([, membershipNumber]) => {
        return of(null).pipe(
          switchMap(() => this.getARMemberInfo(false, membershipNumber)),
          switchMap((executionData) => this.removeCard(executionData)),
          map((accountDetails) => MembershipARActions.removeCardSucceeded({ accountDetails })),
          catchError((error) => of(MembershipARActions.removeCardFailed({ error }))),
        )
      }),
    ),
  )

  updateCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipARActions.updateCard),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getShortMembershipNumber)))),
      switchMap(([, membershipNumber]) => {
        return of(null).pipe(
          switchMap(() => this.getARMemberInfo(true, membershipNumber)),
          switchMap((executionData) =>
            of(executionData).pipe(
              withLatestFrom(
                this.store.select(getMembershipNumber),
                this.store.select(getMembershipAutoRenewFormValues),
                this.store.select(getAccountDetails),
                this.store.select(getPayment),
              ),
            ),
          ),
          switchMap(([executionData, membershipNumber, formValues, accountDetails, payment]) =>
            this.saveOrUpdateCard(executionData, membershipNumber, formValues, accountDetails, payment),
          ),
          map((accountDetails) => MembershipARActions.updateCardSucceeded({ accountDetails })),
          catchError((error) => of(MembershipARActions.updateCardFailed({ error }))),
        )
      }),
    ),
  )

  addCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipARActions.addCard),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getShortMembershipNumber)))),
      switchMap(([, membershipNumber]) => {
        return of(null).pipe(
          switchMap(() => this.getARMemberInfo(true, membershipNumber)),
          switchMap((executionData) =>
            of(executionData).pipe(
              withLatestFrom(
                this.store.select(getMembershipNumber),
                this.store.select(getMembershipAutoRenewFormValues),
                this.store.select(getAccountDetails),
                this.store.select(getPayment),
              ),
            ),
          ),
          switchMap(([executionData, membershipNumber, formValues, accountDetails, payment]) =>
            this.saveOrUpdateCard(executionData, membershipNumber, formValues, accountDetails, payment),
          ),
          map((accountDetails) => MembershipARActions.addCardSucceeded({ accountDetails })),
          catchError((error) => of(MembershipARActions.addCardFailed({ error }))),
        )
      }),
    ),
  )

  saveOrUpdateCard(
    executionData: string,
    membershipNumber: string,
    formValues: FormGroupValue<MembershipAutoRenewForm>,
    accountDetails: AccountDetails | null,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
  ) {
    const membershipEvent: MembershipMzpOperationExecuteEventPayload = {
      executionData,
      operation: Operation.UPDATE,
      method: MembershipMzpMethod.OPERATION_EXECUTE,
    }
    const paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(formValues.billing?.billingTo?.address1 || ""),
          address2: String(formValues.billing?.billingTo?.address2 || ""),
          administrativeArea: String(formValues.billing?.billingTo?.state || ""),
          buildingNumber: "",
          country: "US",
          district: String(formValues.billing?.billingTo?.state || ""),
          email: String(accountDetails?.email || "fallback@avagate.com"),
          firstName: formValues.billing?.billingTo?.firstName || "",
          lastName: formValues.billing?.billingTo?.lastName || "",
          locality: String(formValues.billing?.billingTo?.city),
          phoneNumber: String(accountDetails?.phone.cell || ""),
          postalCode: String(accountDetails?.address.zip || ""),
        },
        amountDetails: { totalAmount: "0", currency: "USD" },
        creditCardBrandedName: payment.formValues.card?.cardName || "",
      },
      operation: Operation.UPDATE,
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
    }
    const payload: OperationExecutePayload = {
      membershipEvent,
      paymentEvent,
      cacheSettings: getClearCacheSettings(membershipNumber),
      operation: Operation.UPDATE,
    }

    return this.executeService.execute<Mzp.MemberAssociateAddPayResponseObject, any>(payload).pipe(
      map(({ validateObject, paymentObject, operationObject }) => {
        const paymentError = !!paymentObject?.meta?.isError
        if (paymentError) {
          checkCybersourcePaymentValidation(paymentObject.error)
        }

        const membershipError = !validateObject || !!validateObject?.meta?.isError
        if (membershipError) {
          throw new RequestError(RequestErrorType.AutoRenewMembership, validateObject)
        }

        if (operationObject?.error) {
          throw new RequestError(RequestErrorType.AutoRenewMembership, operationObject)
        }

        const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
          currency: "USD",
          transaction_id: getTransactionId(paymentObject),
          value: 0,
          items: [{ quantity: 1, price: 0, item_id: "primary", item_name: AppAnalyticsEvents.AccountAutoRenewal }],
          context: "ava-store " + AppAnalyticsEvents.AccountAutoRenewal,
        }
        this.dataLayer.purchaseEvent(analyticsEventParams)

        const accountDetails = new Mzp.AccountInfo(validateObject.response.mzpResponse)

        return accountDetails
      }),
    )
  }

  removeCard(executionData: string) {
    const membershipEvent: MembershipMzpOperationExecuteEventPayload = {
      executionData,
      operation: Operation.UPDATE,
      method: MembershipMzpMethod.OPERATION_EXECUTE,
    }
    const payload: OperationExecutePayload = {
      membershipEvent,
      paymentEvent: null,
      operation: Operation.UPDATE,
    }

    return this.executeService.execute<Mzp.MemberAssociateAddPayResponseObject>(payload).pipe(
      map(({ validateObject, operationObject }) => {
        const membershipError = !validateObject || !!validateObject?.meta?.isError
        if (membershipError) {
          throw new RequestError(RequestErrorType.RemoveAutoRenewMembership, validateObject)
        }
        const accountDetails = new Mzp.AccountInfo(validateObject.response.mzpResponse)

        return accountDetails
      }),
    )
  }

  getARMemberInfo(autoRenew: boolean, memberNumber: string) {
    const payload: MembershipMzpGetMembershipCostsChange = {
      memberNumber,
      autoRenew,
      flow: "AR",
      method: MembershipMzpMethod.GET_MEMBERSHIP_COSTS_CHANGE,
    }

    return this.executeService.membershipQuery<Mzp.MembershipAddAssociateRecostValidationResponseObject>(payload).pipe(
      map((validateObject) => {
        // Todo: implement membership error message
        const membershipError = !!validateObject?.meta?.isError

        return validateObject.response.executionData
      }),
    )
  }
}
