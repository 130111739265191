import {Component, Inject, Input, OnInit} from "@angular/core"
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http"
import {AzureConnectorService} from "../../services/azure-connector.service"
import {JwtAuthService} from "../../../../services/jwt-auth"
import {AngularFirestore} from "@angular/fire/compat/firestore"
import {differenceInCalendarDays} from "date-fns"

import {environment} from "../../../../../environments/environment"
import {FormService} from "../../services/form.service"
import {FormValues} from "@aaa/interface-agentScheduler"
import {GLOBAL_RX_STATE, GlobalState} from "../../../../services/state"
import {RxState} from "@rx-angular/state"
import {RxEffects} from "@rx-angular/state/effects"
import {filter, tap} from "rxjs"

interface Tools {
  info: {
    roles: string[]
    enabled: boolean
    projectId: string
    endpointUrl: string
  }
  reports: {
    roles: string[]
    enabled: boolean
    today: Date
    range: {
      start: string | null
      end: string | null
    }
  }
  connection: {
    roles: string[]
    enabled: boolean
    loading: boolean
    results: string | {
      email: string
      status: number | undefined
      message: string | undefined
    }[]
    appId: string | undefined
  }
  hours: {
    roles: string[]
    enabled: boolean
    loading: boolean
    results: string | {
      email: string
      source: string
      timezone: string
      hours: string
    }[]
  }
  fnConfig: {
    roles: string[]
    enabled: boolean
    setup: () => Promise<void>
    doc: string
  }
}

@Component({
  selector: "ava-tools",
  templateUrl: "./tools.component.html",
  providers: [RxEffects],
})
export class ToolsComponent implements OnInit {
  @Input() formId: string | undefined
  globalState$ = this.globalState.select()
  formValues: FormValues | undefined
  today = new Date()
  date: string | undefined
  showTools: boolean = false
  showAccordion: boolean = false
  tools: Tools = {
    info: {
      roles: ["administrator", "webmaster"],
      enabled: false,
      projectId: environment.firebaseConfig?.projectId,
      endpointUrl: environment.cloudFunctionsURL,
    },
    reports: {
      roles: ["administrator", "webmaster"],
      enabled: false,
      today: new Date(),
      range: {
        start: null,
        end: null
      },
    },
    connection: {
      roles: ["administrator", "webmaster"],
      enabled: false,
      loading: false,
      results: "",
      appId: ""
    },
    hours: {
      roles: ["administrator", "webmaster"],
      enabled: false,
      loading: false,
      results: [],
    },
    fnConfig: {
      roles: ["administrator", "webmaster"],
      enabled: false,
      setup: this.setupFnConfig.bind(this),
      doc: ""
    }
  }

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rxEffects: RxEffects,
    private http: HttpClient,
    public acs: AzureConnectorService,
    public jwt: JwtAuthService,
    public afs: AngularFirestore,
    private formService: FormService,
  ) {
    rxEffects.register(this.showTools$)
  }

  showTools$ = this.globalState.select("afAuthIdTokenResult")
    .pipe(
      filter(afAuthIdTokenResult => afAuthIdTokenResult?.claims?.roles),
      tap(afAuthIdTokenResult => {
        const roles = afAuthIdTokenResult?.claims.roles
        const toolsSections = [this.tools.info, this.tools.reports, this.tools.connection, this.tools.hours, this.tools.fnConfig]
        for (const section of toolsSections) {
          section.enabled = section.roles.some(sr => roles?.some((ur: string) => ur.toLowerCase() === sr.toLowerCase()))
          if (section.enabled && ("setup" in section)) {
            section.setup().then()
          }
          this.showTools = this.showTools || section.enabled
        }
      })
    )

  ngOnInit(): void {
    if (this.formId) {
      this.formValues = this.formService.formValues[this.formId]
    }
    // const toolsSections = [this.tools.info, this.tools.reports, this.tools.connection, this.tools.hours, this.tools.fnConfig]
    // this.jwt.roles$.subscribe(userRoles => {
    //   for (const section of toolsSections) {
    //     section.enabled = section.roles.some(sr => userRoles?.some(ur => ur.toLowerCase() === sr.toLowerCase()))
    //     if (section.enabled && ("setup" in section)) {
    //       section.setup()
    //     }
    //     this.showTools = this.showTools || section.enabled
    //   }
    // })
  }

  async connectionAgentsRefresh(): Promise<void> {
    if (this.tools.connection.loading) return
    this.tools.connection.loading = true
    this.tools.connection.results = "refreshing agent availability. this can take a while for larger clubs..."
    this.tools.connection.appId = "getting azure app ID..."
    const availabilityRefresh = await this.acs.availabilityRefresh(
      this.formValues?.meta.clubId || "",
      "tools component",
      this.formValues?.meta.tenantId || "",
      this.formValues?.agents.map(agent => agent.email) || [],
      true,
    )
    this.tools.connection.results = availabilityRefresh
      .map(document => ({
        email: document.agentEmail,
        status: document.status,
        message: document.message,
      }))
    this.tools.connection.appId = await this.acs.statusAzureApp(
      this.formValues?.meta.clubId || ""
    )
    this.tools.connection.loading = false
  }

  async hoursGetAgentHours(): Promise<void> {
    if (this.tools.hours.loading) return
    this.tools.hours.loading = true
    this.tools.hours.results = "getting agent work hours..."
    const clubId = this.formValues?.meta.clubId
    if (clubId) {
      const workHoursRef = this.afs.firestore
        .collection("wss-aaa-web")
        .doc(this.formValues?.meta.clubId)
        .collection("apps")
        .doc("agent-scheduler")
        .collection("data")
        .doc("gcf")
        .collection("work-hours")

      const workHoursDocs = await workHoursRef.get()
      if (!workHoursDocs.empty) {
        const results: Tools["hours"]["results"] = []
        workHoursDocs.forEach(doc => {
          const data = doc.data()
          if (doc.id === "000index") {
            return
          }
          results.push({
            email: data.agentEmail,
            source: data.source,
            timezone: data.timezone,
            hours: data.hours
          })
        })
        this.tools.hours.results = results
      } else {
        this.tools.hours.results = "failed"
      }
    }
    this.tools.hours.loading = false
  }

  async reportsDownload(): Promise<void> {
    if (!this.tools.reports.range.start || !this.tools.reports.range.start) return

    const url = environment.cloudFunctionsURL + "/agentSchedulerReport?fmt=csv&type=travel&clubId=" + this.formValues?.meta.clubId || "" +
      "&start=" + this.tools.reports.range.start || "" + "&end=" + this.tools.reports.range.end || ""
    const afAuthIdTokenResult = this.globalState.get("afAuthIdTokenResult")
    const afAuthIdToken = afAuthIdTokenResult?.token || ""
    const headers = new HttpHeaders({"Authorization": "Bearer " + afAuthIdToken})
    const resp: HttpResponse<Blob> | undefined = await this.http.get<Blob>(url, {
      headers: headers,
      observe: "response",
      responseType: "blob" as "json"
    }).toPromise()
    const filename = resp?.headers.get("content-disposition")?.split("\"")[1]
    if (resp?.body && filename) {
      this.downloadFile(resp.body, filename, "application/ms-excel")
    }
  }

  downloadFile(data: Blob, filename: string, type: string): void {
    const blob = new Blob([data], {type: type})
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", filename)
    document.body.appendChild(link)
    link.click() //unreal
    window.URL.revokeObjectURL(url)
  }

  datePickerRange(event: Date[]): void {
    const dateRange: (string | null)[] = [null, null]
    if (event.length === 2) {
      dateRange[0] = event[0].toISOString()?.split("T")[0]
      dateRange[1] = event[1].toISOString()?.split("T")[0]
    }
    this.tools.reports.range.start = dateRange[0]
    this.tools.reports.range.end = dateRange[1]
  }

  async setupFnConfig(): Promise<void> {

    const clubId = this.formValues?.meta.clubId

    if (clubId) {
      this.afs
        .collection("wss-aaa-web")
        .doc(clubId)
        .collection("apps")
        .doc("agent-scheduler")
        .valueChanges()
        .subscribe(item => {
          this.tools.fnConfig.doc = JSON.stringify(item, null, 2)
          // console.log(item);
        })
    }
  }

  datePickerDisabledDates = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0

}
