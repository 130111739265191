import {
  Component, OnInit, ViewChild, AfterViewInit, Input, HostListener, ElementRef, ChangeDetectorRef, Inject,
} from "@angular/core"
import { StateMachineService } from "../../../services/state-machine.service"
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps"
import { environment } from "../../../../../../environments/environment"
import { FormService } from "../../../services/form.service"
import { FormControl } from "@angular/forms"
import { Agent, AgentType, FormValues, Meeting, Office, Region, ShareWith, Topic } from "@aaa/interface-agentScheduler"
import { Loader } from "@googlemaps/js-api-loader"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../../services/state"
import { RxState } from "@rx-angular/state"

interface Marker {
  position: google.maps.LatLngLiteral
  options: google.maps.MapOptions
  title: string
  info: {
    title: string
    addr: string
    agents: string
    officeId: string
  }
}

@Component({
  selector: "ava-agents-list",
  templateUrl: "./agents-list.component.html",
})
export class AgentsListComponent implements OnInit, AfterViewInit {
  @Input() formId: string | undefined
  @Input() containerWidth: number | undefined
  debugMode: boolean = false
  testMode: boolean = false
  showAllAgents: boolean = false

  agents: Agent[] = []
  agentOfficeIds: string[] = []
  filteredAgents: Agent[] = []
  showingAll: boolean = true
  filterName: string = ""

  @ViewChild(MapInfoWindow, { static: false }) mapInfoWindow: MapInfoWindow | undefined
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap | undefined
  mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    zoom: 10,
  }
  defaultMarkerOptions: google.maps.MarkerOptions = {
    // animation: google.maps.Animation.DROP,
    draggable: false,
    icon: {
      path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
      fillColor: "#00a0df",
      fillOpacity: 1,
      strokeColor: "#000",
      strokeWeight: 2,
      scale: 1,
    },
    zIndex: undefined,
  }
  selectedMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
      fillColor: "red",
      fillOpacity: 1,
      strokeColor: "#000",
      strokeWeight: 2,
      scale: 1,
    },
    zIndex: 999,
  }
  markers: Marker[] = []
  center: { lat: number; lng: number; } = { lat: 0, lng: 0 }
  initialBounds: google.maps.LatLngBounds | undefined
  infoData = {
    title: "",
    addr: "",
    agents: "",
    officeId: "",
  }
  formValues: FormValues | undefined
  agent: FormControl | undefined

  typeIds: { [key: string]: AgentType } = {}
  officeIds: { [key: string]: Office } = {}
  topicIds: { [key: string]: Topic } = {}
  regionIds: { [key: string]: Region } = {}
  meetingIds: { [key: string]: Meeting } = {}
  shareWithIds: { [key: string]: ShareWith } = {}
  mapLoaded = false

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private sms: StateMachineService,
    public formService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    const loader = new Loader({
      apiKey: this.globalState.get("environment", "firebaseConfig", "apiKey"),
      version: "weekly",
    })
    loader
      .importLibrary("maps")
      .then(() => {
        this.mapLoaded = true

        this.debugMode = window.location.search.toLowerCase().includes(("debugMode").toLowerCase())
        this.showAllAgents = window.location.search.toLowerCase().includes(("showAllAgents").toLowerCase())

        if (this.formId) {
          this.formValues = this.formService.formValues[this.formId]
          this.agent = this.formService.form[this.formId].get("agent") as FormControl
        }
        if (this.formValues) {
          this.typeIds = this.formValues.types.reduce((accumulator, value) => ({ ...accumulator, [value.id]: value }), {})
          this.officeIds = this.formValues.types.reduce((accumulator, value) => ({ ...accumulator, [value.id]: value }), {})
          this.topicIds = this.formValues.topics.reduce((accumulator, value) => ({ ...accumulator, [value.id]: value }), {})
          this.regionIds = this.formValues.regions.reduce((accumulator, value) => ({
            ...accumulator,
            [value.id]: value,
          }), {})
          this.meetingIds = this.formValues.meetings.reduce((accumulator, value) => ({
            ...accumulator,
            [value.id]: value,
          }), {})
          this.shareWithIds = this.formValues.shareWith.reduce((accumulator, value) => ({
            ...accumulator,
            [value.id]: value,
          }), {})

          const agentFlowEmail = this.formValues.overrides.agentEmail || false

          if (agentFlowEmail) {
            this.agent?.setValue(this.formValues.agents.find(a => a.email.toLocaleLowerCase() === agentFlowEmail.toLocaleLowerCase()))
            this.agents = [this.agent?.value]
          }
          if (!agentFlowEmail) {
            const agents = this.formValues.agents
              .filter(agent => !this.formValues?.selectedTopicIds ? true : (agent.topicIds.some(id => this.formValues?.selectedTopicIds.some(topicId => topicId === id))))
              .filter(agent => !this.formValues?.selectedRegionId ? true : (agent.regionIds.some(id => id === this.formValues?.selectedRegionId)))
              .filter(agent => !this.formValues?.selectedMeetingId ? true : (agent.meetingIds.some(id => id === this.formValues?.selectedMeetingId)))
            this.agents = this.showAllAgents ? this.formValues.agents : agents
          }

          this.filteredAgents = this.agents
          const agentsOffices: { [key: string]: string } = {}
          this.agents.forEach(agent => {
            agent.officeIds.forEach(agentOfficeId => {
              agentsOffices[agentOfficeId] = agentOfficeId
            })
          })
          this.agentOfficeIds = Object.values(agentsOffices)

          if (this.agentOfficeIds.length) {
            this.center = this.officeToLatLng(this.formValues.offices.find(office => office.id === this.agentOfficeIds[0]))
          }

          this.initialBounds = new google.maps.LatLngBounds()
          this.agentOfficeIds.forEach(agentOfficeId => {
            const agentOffice: Office | undefined = this.formValues?.offices.find(office => office.id === agentOfficeId)
            const officePnt = this.officeToLatLng(agentOffice)
            const agentsCount: number | undefined = this.formValues?.agents.reduce((counter: number, agent: Agent) => {
              if (agent.officeIds.find(officeId => officeId === agentOfficeId)) {
                return counter + 1
              }
              return counter
            }, 0)
            if (agentOffice) {
              this.markers.push({
                position: officePnt as { lat: number; lng: number; },
                title: agentOffice.name,
                options: this.defaultMarkerOptions,
                info: {
                  title: agentOffice.name,
                  addr: agentOffice.addrLine1 + "\n" + agentOffice.addrLine2,
                  agents: agentsCount + " agent" + (agentsCount !== 1 ? "s" : ""),
                  officeId: agentOffice.id,
                },
              })
            }
            this.initialBounds?.extend(officePnt as { lat: number; lng: number; })
          })
        }


        //
      })
      .catch((error) => {
        console.error(error)
        //
      })
  }

  ngAfterViewInit(): void {
    this.recenterMap()

    if (environment.ngServe) {
      // this.sendEvent(this.formValues.agents.find(agent => agent.fName === "Kermit"))
    }
  }

  recenterMap(): void {
    if (this.map) {
      if (this.agentOfficeIds.length > 1 && this.initialBounds) {
        this.map.fitBounds(this.initialBounds)
      } else {
        if (this.center) {
          this.map.center = this.center
        }
        this.map.zoom = 13
      }
    }
  }

  sendEvent(agent: Agent): void {
    this.agent?.setValue(agent)
    this.sms.sendEvent("NEXT")
  }

  agentOffice(officeId: string): string {
    return this.formValues?.offices.find(office => office.id === officeId)?.name || ""
  }

  /*
    mapClick(event: Event): void {
      if (!event["noClick"]) {
        // disabled this close method, it was firing along with the open() method, thereby disabling the popup entirely
        // this.info.close();
      }
    }
  */

  openInfo(markerElement: MapMarker, marker: Marker): void {
    this.infoData = {
      title: marker.info.title,
      addr: marker.info.addr,
      agents: marker.info.agents,
      officeId: marker.info.officeId,
    }
    this.mapInfoWindow?.open(markerElement)
  }

  resetFilter(): void {
    this.showingAll = true
    this.filteredAgents = this.agents
    for (const marker of this.markers) {
      marker.options = this.defaultMarkerOptions
    }
    this.recenterMap()
  }

  filter(officeId: string): void {
    this.showingAll = false
    this.filterName = this.formValues?.offices.find(office => office.id === officeId)?.name || ""
    // this.mapMarkers.find(m => m.info.officeId === officeId).options = this.selectedMarkerOptions;
    for (const marker of this.markers) {
      if (marker.info.officeId === officeId) {
        marker.options = this.selectedMarkerOptions
      } else {
        marker.options = this.defaultMarkerOptions
      }
    }
    this.filteredAgents = this.agents.filter(agent => agent.officeIds.some(agentOfficeId => agentOfficeId === officeId))
    this.mapInfoWindow?.close()
  }

  officeToLatLng(office: Office | undefined): { lat: number, lng: number } {
    if (office) {
      return {
        lat: + office.geoLoc[0],
        lng: + office.geoLoc[1],
      }
    }
    return { lat: 0, lng: 0 }
  }

  /*
    getCircularReplacer = () => {
      const seen = new WeakSet()
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return
          }
          seen.add(value)
        }
        return value
      }
    }
  */

}
