import { ClubApp } from "@aaa/emember/types"
import { compareCharts as compareChartsHoosier } from "./compares-charts.hoosier"
import { compareCharts as compareChartsNorthampton } from "./compares-charts.northampton"
import { compareCharts as compareChartsNorthway } from "./compares-charts.northway"
import { compareCharts as compareChartsShelby } from "./compares-charts.shelby"
import { compareCharts as compareChartsMidstates } from "./compares-charts.midstates"
import { compareCharts as compareChartsSouthJersey } from "./compares-charts.southjersey"

export const compareLevels = {
  [ClubApp.Hoosier]: compareChartsHoosier,
  [ClubApp.MidStates]: compareChartsMidstates,
  [ClubApp.Shelby]: compareChartsShelby,
  [ClubApp.Northampton]: compareChartsNorthampton,
  [ClubApp.Colorado]: compareChartsNorthampton,
  [ClubApp.Minneapolis]: compareChartsNorthampton,
  [ClubApp.Northway]: compareChartsNorthway,
  [ClubApp.SouthJersey]: compareChartsSouthJersey,
  [ClubApp.Empty]: compareChartsNorthampton,
}
