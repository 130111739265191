import { Injectable } from "@angular/core"
import {
  ContactRecord,
  GetContactParameters,
  GetContactResponse,
  SmartWeatherMethod,
  WriteContactParameters,
  WriteContactResponse
} from "@aaa/interface-smartWeather"
import { environment } from "../../../../environments/environment"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import firebase from "firebase/compat/app"
import "firebase/auth"

@Injectable({
  providedIn: 'root'
})
export class GcpConnectorService {
  setup: boolean = false
  clubId: string | undefined

  constructor(
    private http: HttpClient
  ) { }

  setupService(clubId: string): void {
    this.clubId = clubId
    this.setup = true
  }

  async getContact(memberNumber: string): Promise<GetContactResponse | undefined> {
    if (!this.setup || !this.clubId) return
    // return {
    //   memberNumber: "4290061907642014",
    //   contact: {
    //     "firstName": "first",
    //     "lastName": "last",
    //     "locations": [{
    //       "name": "22",
    //       "addr1": "123 n street st",
    //       "city": "slc",
    //       "state": "ut",
    //       "country": "US",
    //       "postal": "84101",
    //       "gis": {"lat": "40", "lon": "-112"},
    //       "paths": {"email": ["prog@a.com"], "sms": []},
    //       "categories": []
    //     }, {
    //       "name": "1",
    //       "addr1": "123 n street st",
    //       "city": "slc",
    //       "state": "ut",
    //       "country": "US",
    //       "postal": "84101",
    //       "gis": {"lat": "40", "lon": "-112"},
    //       "paths": {"email": ["prog@a.com"], "sms": []},
    //       "categories": []
    //     }, {
    //       "name": "33",
    //       "addr1": "123 n street st",
    //       "city": "slc",
    //       "state": "ut",
    //       "country": "US",
    //       "postal": "84101",
    //       "gis": {"lat": "40", "lon": "-112"},
    //       "paths": {"email": ["prog@a.com"], "sms": []},
    //       "categories": []
    //     }]
    //   }
    // }

    const getContactParams: GetContactParameters = {
      clubId: this.clubId,
      method: SmartWeatherMethod.GET_CONTACT,
      memberNumber: memberNumber
    }

    //not sure if this should be cached, or if they should expire quickly
    const authToken = await firebase.auth().currentUser?.getIdToken() //can fail
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + authToken })

    // console.log(getContactParams)
    // console.log(headers)

    // console.log('GET' + environment.cloudFunctionsURL)

    try {
      return await this.http.request<GetContactResponse>(
        'POST',
        environment.cloudFunctionsURL + '/smartWeather',
        {
          body: getContactParams,
          headers: headers
        }
      ).toPromise();

    } catch (e) {
      console.log('getContact exception' + e);
      throw e;
    }
  }

  async writeContact(contact: ContactRecord, memberNumber: string): Promise<WriteContactResponse | undefined> {
    if (!this.setup || !this.clubId) return
    const writeContactParams: WriteContactParameters = {
      clubId: this.clubId,
      method: SmartWeatherMethod.WRITE_CONTACT,
      memberNumber: memberNumber,
      contact: contact
    }
    // console.log(writeContactParams)

    //not sure if this should be cached, or if they should expire quickly
    const authToken = await firebase.auth().currentUser?.getIdToken(); //can fail
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + authToken });

    // console.log('WRITE' + environment.cloudFunctionsURL);

    try {
      return await this.http.request<WriteContactResponse>(
        'POST',
        environment.cloudFunctionsURL + '/smartWeather',
        {
          body: writeContactParams,
          headers: headers
        }
      ).toPromise()
    } catch (e) {
      console.log('writeContact exception')
      console.log(e)
      throw e
    }
  }
}
