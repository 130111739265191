import { Injectable } from "@angular/core"
import { StateService } from "../../services/state"
import { ImageEditorOptions } from "../../fields/image/service"
import { Block } from "../../block/services/block"
import { TemplateType } from "../../options/template/service"
import { BlockState } from "../../block/services/state"
import { FieldType } from "../../services/field"
import { TargetOption, TargetType } from "../../services/link"

export interface BackgroundImageEditorOptions {
  desktop: ImageEditorOptions
  mobile: ImageEditorOptions
  tablet: ImageEditorOptions
}

@Injectable({
  providedIn: "root"
})
export class BackgroundImageService {
  backgroundImageRows: { [key: string]: BlockState["backgroundImageRows"] } = {}
  imageOptions: { [key: string]: BlockState["imageOptions"] } = {}

  constructor(
    private stateService: StateService,
  ) {
  }

  /**
   * We only show the first row of (up to) three background image rows.
   * Here we create simple array of up to three booleans if each of the first three rows are background images.
   * This is used to:
   *   set background image row label
   *   hide some of the background image rows
   *   create imageOptions for all background images
   *     (gets passed to image form, then image form presents uploader and cropper for hidden background image rows)
   *   offset the non-background image row indexes
   *   offset the non-background image drag-and-drop indexes
   */
  getBackgroundImageRows(rowIds: Block["columns"][0]["rows"], rows: Block["rows"], templateType: TemplateType): BlockState["backgroundImageRows"] {
    const backgroundImageRows = []
    switch (templateType) {
      case TemplateType.BANNER:
      case TemplateType.BANNER_LARGE:
      case TemplateType.JOIN:
        if (rows[rowIds[0]]?.fieldType === FieldType.IMAGE) {
          backgroundImageRows.push(true)
          if (rows[rowIds[1]]?.fieldType === FieldType.IMAGE) {
            backgroundImageRows.push(true)
            if (rows[rowIds[2]]?.fieldType === FieldType.IMAGE) {
              backgroundImageRows.push(true)
            }
          }
        }
        break
      default:
    }
    return backgroundImageRows
  }

  getImageEditorOptions(columns: Block["columns"], templateType: TemplateType, imageServiceOptions: ImageEditorOptions, backgroundImageRows: BlockState["backgroundImageRows"]): BlockState["imageOptions"] {
    const imageOptions: { [key: string]: ImageEditorOptions } = {}
    const rowIds: string[] = []
    for (const column of columns) {
      for (const rowId of column.rows) {
        rowIds.push(rowId)
        imageOptions[rowId] = imageServiceOptions
      }
    }

    /**
     * overriding image options for banner and largeBanner background images
     * These will be column[0], rows[0, 1, 2] if they are images
     */
    switch (templateType) {
      case TemplateType.BANNER:
        if (backgroundImageRows[0]) {
          imageOptions["desktop"] = imageOptions[rowIds[0]] = {
            alt: true,
            cropper: false,
            description: "Desktop",
            dimensions: "1440x480",
            label: {
              placeholder: "",
              plural: "Images",
              singular: "Image"
            },
            minWidth: {
              px: 1440
            },
            maxWidth: {
              px: 1440
            },
            minHeight: {
              px: 480
            },
            maxHeight: {
              px: 480
            },
            target: [TargetType.SELF, TargetType.MODAL, TargetType.NEW],
            targetOption: true,
            targetPath: true,
          }
        }
        if (backgroundImageRows[1]) {
          imageOptions["tablet"] = imageOptions[rowIds[1]] = {
            alt: false,
            cropper: true,
            description: "Tablet",
            dimensions: "768x320",
            label: {
              placeholder: "",
              plural: "Images",
              singular: "Image"
            },
            minWidth: {
              px: 768
            },
            maxWidth: {
              px: 768
            },
            minHeight: {
              px: 320
            },
            maxHeight: {
              px: 320
            },
            target: [],
            targetOption: false,
            targetPath: false,
          }
        }
        if (backgroundImageRows[2]) {
          imageOptions["mobile"] = imageOptions[rowIds[2]] = {
            alt: false,
            cropper: true,
            description: "Mobile",
            dimensions: "544x350",
            label: {
              placeholder: "",
              plural: "Images",
              singular: "Image"
            },
            minWidth: {
              px: 544
            },
            maxWidth: {
              px: 544
            },
            minHeight: {
              px: 350
            },
            maxHeight: {
              px: 350
            },
            target: [],
            targetOption: false,
            targetPath: false,
          }
        }
        break
      case TemplateType.BANNER_LARGE:
        if (backgroundImageRows[0]) {
          imageOptions["desktop"] = imageOptions[rowIds[0]] = {
            alt: true,
            cropper: false,
            description: "Desktop",
            dimensions: "1440x769",
            label: {
              placeholder: "",
              plural: "Images",
              singular: "Image"
            },
            minWidth: {
              px: 1440
            },
            maxWidth: {
              px: 1440
            },
            minHeight: {
              px: 769
            },
            maxHeight: {
              px: 769
            },
            target: [TargetType.SELF, TargetType.MODAL, TargetType.NEW],
            targetOption: true,
            targetPath: true,
          }
        }
        if (backgroundImageRows[1]) {
          imageOptions["tablet"] = imageOptions[rowIds[1]] = {
            alt: false,
            cropper: true,
            description: "Tablet",
            dimensions: "768x411",
            label: {
              placeholder: "",
              plural: "Images",
              singular: "Image"
            },
            minWidth: {
              px: 768
            },
            maxWidth: {
              px: 768
            },
            minHeight: {
              px: 411
            },
            maxHeight: {
              px: 411
            },
            target: [],
            targetOption: false,
            targetPath: false,
          }
        }
        if (backgroundImageRows[2]) {
          imageOptions["mobile"] = imageOptions[rowIds[2]] = {
            alt: false,
            cropper: true,
            description: "Mobile",
            dimensions: "544x350",
            label: {
              placeholder: "",
              plural: "Images",
              singular: "Image"
            },
            minWidth: {
              px: 544
            },
            maxWidth: {
              px: 544
            },
            minHeight: {
              px: 350
            },
            maxHeight: {
              px: 350
            },
            target: [],
            targetOption: false,
            targetPath: false,
          }
        }
        break
    }
    return imageOptions

    // this.stateService.setState(stateId, { imageOptions: imageOptions })
  }

}
