import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { bufferCount, map, switchMap, tap } from "rxjs/operators"
import { PaymentActions } from "../payment/payment.actions"
import { concat, first, of, withLatestFrom } from "rxjs"
import { Store } from "@ngrx/store"
import { MembershipUpgradePlan } from "./membership-upgrade-plan.actions"
import { MembershipUpgradePlanFormStep } from "./membership-upgrade-plan.models"
import { getMembershipUpgradePlanFormActiveStep } from "./membership-upgrade-plan.selectors"
import { getMembershipNumber, MembershipActions } from "@aaa/emember/store-membership"
import { updateTreeValidity } from "../../modules/share/form.utils"
import { MembershipUpgradePlanVm } from "../../modules/membership-upgrade-plan/membership-upgrade-plan.vm"

@Injectable({ providedIn: "root" })
export class MembershipUpgradePlanEffects {
  actions$ = inject(Actions)
  store = inject(Store)
  membershipUpgradeVm = inject(MembershipUpgradePlanVm)

  validateFormStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.validateFormStep),
      withLatestFrom(this.store.select(getMembershipUpgradePlanFormActiveStep)),
      switchMap(([{ validateStep, params }, currentActiveStep]) => {
        const validatePayment = currentActiveStep === MembershipUpgradePlanFormStep.PAYMENT

        if (validatePayment) {
          const validateBillingAddress$ = of(null).pipe(
              tap(() => this.store.dispatch(MembershipUpgradePlan.validateBillingInfo())),
              switchMap(() => this.actions$.pipe(ofType(MembershipUpgradePlan.validateBillingInfoSucceeded), first())),
            ),
            validatePayment$ = of(null).pipe(
              tap(() => this.store.dispatch(PaymentActions.validate())),
              switchMap(() => this.actions$.pipe(ofType(PaymentActions.validateSucceeded), first())),
            ),
            validateGenerateToken$ = of(null).pipe(
              tap(() => this.store.dispatch(PaymentActions.generateToken())),
              switchMap(() => this.actions$.pipe(ofType(PaymentActions.generateTokenSucceeded), first())),
            ),
            validatePay$ = of(null).pipe(
              tap(() => this.store.dispatch(MembershipUpgradePlan.pay({ params }))),
              switchMap(() => this.actions$.pipe(ofType(MembershipUpgradePlan.paySucceeded), first())),
            ),
            validators = [validateBillingAddress$, validatePayment$, validateGenerateToken$, validatePay$]

          return concat(...validators).pipe(
            bufferCount(validators.length),
            map(() =>
              MembershipUpgradePlan.setFormActiveStep({ activeStep: MembershipUpgradePlanFormStep.CONFIRMATION }),
            ),
          )
        }

        return of(MembershipUpgradePlan.setFormActiveStep({ activeStep: validateStep }))
      }),
    ),
  )

  validatePaymentStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.validateBillingInfo),
      map(() => {
        const billingInvalid = this.membershipUpgradeVm.formGroup.status !== "VALID"

        if (billingInvalid) {
          updateTreeValidity(this.membershipUpgradeVm.formGroup.controls.billing)

          return MembershipUpgradePlan.validateBillingInfoFailed()
        }

        return MembershipUpgradePlan.validateBillingInfoSucceeded()
      }),
    ),
  )

  loadMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.paySucceeded),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getMembershipNumber)))),
      map(([, membershipNumber]) => MembershipActions.load({ membershipNumber: String(membershipNumber) })),
    ),
  )
}
