import { Inject, Injectable } from "@angular/core"
import firebase from "firebase/compat/app"
import "firebase/firestore"
import { combineLatestWith, interval, tap } from "rxjs"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { JoinRenewService, OpStatus } from "../../join-renew.service"
import { CybersourceService } from "../services/cybersource"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../services/state"
import {
  AccountInformationView,
  AccountMembershipView,
  CheckoutStep,
  Flow,
  HOOSIER_RX_STATE,
  HoosierForm,
  HoosierService,
  HoosierState,
  JoinSection,
  JoinView,
  MobileAppRenewView,
  QuickRenewSection,
  QuickRenewView,
  SessionDocResponse,
  SessionDocResponseObject,
  View,
} from "../hoosier.service"
import { FormService, FormStatus } from "../../../../services/form"
import { FormGroup } from "@angular/forms"
import { AlertMessage, AlertMessageService, AlertType } from "../../../../services/alert-message"
import { Membership, Payment } from "../services/member-info"
import { ExecutePaymentResponseObject } from "../services/payment"
import { MemberInfoToBillingService } from "../services/member-info-to-billing"
import { AnalyticsService } from "../../../../services/analytics"
import { MemberLookupService } from "./member-lookup"
import CryptoJS from "crypto-js"
import {
  ClearCacheSettings,
  EventName,
  Operation,
  OperationExecutePayload,
} from "@aaa/interface-joinRenew-joinRenewLib"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import Timestamp = firebase.firestore.Timestamp

export interface ExecuteMembershipResponseObject extends SessionDocResponseObject {
  error: ExecuteMembershipError
  // meta: SessionDocMeta
  response: ExecuteMembershipResponse
}

export interface ExecuteMembershipError {

}

export interface ExecuteMembershipResponse {
  ok: boolean,
  operation: Operation
  response: {
    attributes: {
      clubId: string
      isAutoRenew: string
      responseCode: string
      responseText: string
      version: string
      waiveEnrollFee: string
    },
    membership: ExecuteMembership
  }
}

export interface ExecuteMembership extends Membership {
  payment: ExecuteMembershipPayment
}

export interface ExecuteMembershipPayment extends Payment {
  contract: any
  transaction: {
    approvalCode: string
    avsMatch: string
    code: string
    cvvMatch: string
    txnId: string
  }
}

@Injectable({
  providedIn: "root",
})
export class ExecuteService {
  form: FormGroup

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private joinRenewService: JoinRenewService,
    private hoosierService: HoosierService,
    private cybersourceService: CybersourceService,
    private alertMessageService: AlertMessageService,
    private formService: FormService,
    private memberInfoToBillingService: MemberInfoToBillingService,
    private memberLookupService: MemberLookupService,
    private analyticsService: AnalyticsService,
  ) {
    this.form = hoosierService.form
    rxEffects.register(this.EXECUTE_KEY_membershipAndPayment$)
    rxEffects.register(this.EXECUTE_KEY_membershipOnly$)
    // rxEffects.register(this.timeout$)

    this.rxEffects.register(this.executeActiveView$)
    this.rxEffects.register(this.executeActiveCheckoutStep$)
  }

  /*
    form$ = this.hoosierState.select("form")
      .pipe(
        tap(form => {
          if (form && !this.form) {
            this.form = form
            this.rxEffects.register(this.executeActiveView$)
            this.rxEffects.register(this.executeActiveCheckoutStep$)
          }
        }),
      )
  */

  executeActiveView$ = this.hoosierState.select("activeFlow")
    .pipe(
      combineLatestWith(
        this.hoosierState.select("activeView"),
        this.hoosierState.select("formStatus"), // this picks up on async validator completion
      ),
      tap(([activeFlow, activeView, formStatus]: [Flow | null, View | null, FormStatus]) => {
        if (formStatus === FormStatus.VALID
          && (activeView === JoinView.EXECUTE || activeView === QuickRenewView.EXECUTE)
          && !this.hoosierState.get("VALIDATE_ASSOCIATES_KEY")
          && !this.hoosierState.get("VALIDATE_LEVEL_KEY")
          && !this.hoosierState.get("VALIDATE_JOIN_KEY")
          && !this.hoosierState.get("VALIDATE_RENEW_KEY")
          && !this.hoosierState.get("VALIDATE_AUTO_RENEW_KEY")
        ) {
          const VALIDATE_JOIN_executionData = this.hoosierState.get("VALIDATE_JOIN")?.response.executionData
          const VALIDATE_RENEW_executionData = this.hoosierState.get("VALIDATE_RENEW")?.response.executionData
          switch (activeFlow) {
            case Flow.JOIN:
            case Flow.GIFT:
              if (VALIDATE_JOIN_executionData) {
                this.flexMicroFormCreateToken(Operation.JOIN, VALIDATE_JOIN_executionData)
              }
              break
            case Flow.QUICK_RENEW:
              if (VALIDATE_RENEW_executionData) {
                this.flexMicroFormCreateToken(Operation.RENEW, VALIDATE_RENEW_executionData)
              }
              break
          }
        }
      }),
    )

  executeActiveCheckoutStep$ = this.hoosierState.select("activeView")
    .pipe(
      combineLatestWith(
        this.hoosierState.select("formStatus"), // this picks up on async validator completion
        this.hoosierState.select("activeCheckoutStep"),
      ),
      tap(([activeView, formStatus, activeCheckoutStep]: [View | null, FormStatus, CheckoutStep | null]) => {
        if (formStatus === FormStatus.VALID
          && activeCheckoutStep === CheckoutStep.EXECUTE
          && !this.hoosierState.get("VALIDATE_ASSOCIATES_KEY")
          && !this.hoosierState.get("VALIDATE_LEVEL_KEY")
          && !this.hoosierState.get("VALIDATE_JOIN_KEY")
          && !this.hoosierState.get("VALIDATE_RENEW_KEY")
          && !this.hoosierState.get("VALIDATE_AUTO_RENEW_KEY")
          && !this.hoosierState.get("VALIDATE_CHANGE_ADDRESS_KEY")
          && !this.hoosierState.get("VALIDATE_CHANGE_PHONE_KEY")
        ) {
          const VALIDATE_RENEW_executionData = this.hoosierState.get("VALIDATE_RENEW")?.response.executionData
          const VALIDATE_LEVEL_executionData = this.hoosierState.get("VALIDATE_LEVEL")?.response.executionData
          const VALIDATE_ASSOCIATES_executionData = this.hoosierState.get("VALIDATE_ASSOCIATES")?.response.executionData
          const VALIDATE_AUTO_RENEW_executionData = this.hoosierState.get("VALIDATE_AUTO_RENEW")?.response.executionData
          const VALIDATE_CHANGE_ADDRESS_executionData = this.hoosierState.get("VALIDATE_CHANGE_ADDRESS")?.response.executionData
          const VALIDATE_CHANGE_PHONE_executionData = this.hoosierState.get("VALIDATE_CHANGE_PHONE")?.response.executionData
          switch (activeView) {
            case AccountInformationView.ADDRESS:
              if (VALIDATE_CHANGE_ADDRESS_executionData) {
                this.execute(Operation.UPDATE, VALIDATE_CHANGE_ADDRESS_executionData, null)
              }
              break
            case AccountInformationView.PHONE:
              if (VALIDATE_CHANGE_PHONE_executionData) {
                this.execute(Operation.UPDATE, VALIDATE_CHANGE_PHONE_executionData, null)
              }
              break
            case MobileAppRenewView.RENEW:
            case AccountMembershipView.RENEW:
              if (VALIDATE_RENEW_executionData) {
                this.flexMicroFormCreateToken(Operation.UPDATE, VALIDATE_RENEW_executionData)
              }
              break
            case AccountMembershipView.LEVEL:
              if (VALIDATE_LEVEL_executionData) {
                this.flexMicroFormCreateToken(Operation.UPDATE, VALIDATE_LEVEL_executionData)
              }
              break
            case AccountMembershipView.ASSOCIATES_ADD:
              if (VALIDATE_ASSOCIATES_executionData) {
                this.flexMicroFormCreateToken(Operation.UPDATE, VALIDATE_ASSOCIATES_executionData)
              }
              break
            case AccountMembershipView.ASSOCIATES_REMOVE:
              if (VALIDATE_ASSOCIATES_executionData) {
                this.execute(Operation.UPDATE, VALIDATE_ASSOCIATES_executionData, null)
              }
              break
            case AccountMembershipView.AUTO_RENEW:
            case AccountMembershipView.AUTO_RENEW_CARD:
              if (VALIDATE_AUTO_RENEW_executionData) {
                this.flexMicroFormCreateToken(Operation.UPDATE, VALIDATE_AUTO_RENEW_executionData)
              }
              break
            case AccountMembershipView.AUTO_RENEW_REMOVE:
              if (VALIDATE_AUTO_RENEW_executionData) {
                this.execute(Operation.UPDATE, VALIDATE_AUTO_RENEW_executionData, null)
              }
              break
          }
        }
      }),
    )

  /*
    EXECUTE_STATUS$ = this.hoosierState.select("EXECUTE_STATUS")
      .pipe(
        combineLatestWith(
          this.hoosierState.select("activeFlow"),
        ),
        tap(([EXECUTE_STATUS, activeFlow]: [OpStatus, Flow]) => {
          if (EXECUTE_STATUS === OpStatus.SUCCESS) {
          }
        })
      )
  */

  timeout$ = interval(1000)
    .pipe(
      tap(() => {
        const EXECUTE_KEY = this.hoosierState.get("EXECUTE_KEY")
        if (EXECUTE_KEY) {
          const keyTimestamp = parseInt(EXECUTE_KEY)
          if (keyTimestamp) {
            if ((Timestamp.now().toMillis() - keyTimestamp) > (60 * 1000)) {
              this.alertMessageService.set(AlertMessage.ERROR_CRITICAL, AlertType.ERROR)
            }
          }
        }
      }),
    )

  EXECUTE_KEY_membershipOnly$ = this.hoosierState.select("EXECUTE_KEY")
    .pipe(
      combineLatestWith(
        this.hoosierState.select("sessionDoc", "responses", "membership", "connectsuite"),
        this.hoosierState.select("activeView"),
      ),
      tap(([EXECUTE_KEY, connectsuite, activeView]: [string | null, SessionDocResponse, View | null]) => {
        if (EXECUTE_KEY && connectsuite[EXECUTE_KEY]) {
          const responseTime = Timestamp.now().toMillis() - parseInt(EXECUTE_KEY)
          const membershipResponseObject = connectsuite[EXECUTE_KEY] as ExecuteMembershipResponseObject
          const membershipError: boolean = membershipResponseObject.meta.isError
          switch (activeView) {
            case AccountInformationView.ADDRESS:
            case AccountInformationView.PHONE:
            case AccountMembershipView.ASSOCIATES_REMOVE:
            case AccountMembershipView.AUTO_RENEW_REMOVE:
              this.hoosierState.set("activeCheckoutStep", () => null)

              /**
               * response from server
               */
              this.alertMessageService.clear(AlertMessage.ERROR_CRITICAL)

              if (this.globalState.get("adminUser")) {
                console.log(responseTime, "milliseconds - EXECUTE")
              }

              /**
               * response from Connectsuite
               */
              this.hoosierState.set("EXECUTE_KEY", () => null)

              if (!membershipError) {
                /**
                 * Connectsuite operation is successful
                 */
                this.hoosierState.get("form").reset(this.hoosierService.newHoosierItem)
                this.hoosierState.set("MEMBERSHIP", () => membershipResponseObject)
                this.hoosierState.set("MEMBERSHIP_ERROR", () => null)
                this.memberLookupService.memberLookup(this.globalState.get("windowMetaData", "user", "memberNumber"), "MEMBER_LOOKUP_KEY")
              }

              if (membershipError) {
                /**
                 * Connectsuite operation has an error
                 */
                this.hoosierState.set("MEMBERSHIP", () => null)
                this.hoosierState.set("MEMBERSHIP_ERROR", () => membershipResponseObject)
              }

              /**
               * transition the user to the CONFIRMATION section after successful payment
               * The CONFIRMATION section will present Connectsuite failure messaging if needed
               */
              switch (activeView) {
                case AccountMembershipView.ASSOCIATES_REMOVE:
                  this.hoosierState.set("activeView", () => AccountMembershipView.ASSOCIATES)
                  this.hoosierState.set("activeCheckoutStep", () => CheckoutStep.CONFIRMATION)
                  break
                case AccountMembershipView.AUTO_RENEW_REMOVE:
                  this.hoosierState.set("activeView", () => AccountMembershipView.AUTO_RENEW)
                  this.hoosierState.set("activeCheckoutStep", () => CheckoutStep.CONFIRMATION)
              }
          }
        }

      }),
    )

  EXECUTE_KEY_membershipAndPayment$ = this.hoosierState.select("EXECUTE_KEY")
    .pipe(
      combineLatestWith(
        this.hoosierState.select("sessionDoc", "responses", "membership", "connectsuite"),
        this.hoosierState.select("sessionDoc", "responses", "payment", "cybersource"),
      ),
      tap(([EXECUTE_KEY, connectsuite, cybersource]: [string | null, SessionDocResponse, SessionDocResponse]) => {
        if (EXECUTE_KEY && cybersource[EXECUTE_KEY]) {
          this.alertMessageService.clear(AlertMessage.ERROR_CRITICAL)

          const responseTime = Timestamp.now().toMillis() - parseInt(EXECUTE_KEY)
          if (this.globalState.get("adminUser")) {
            console.log(responseTime, "milliseconds - EXECUTE")
          }

          /**
           * check if payment error from Cybersource
           */
          const paymentResponseObject = cybersource[EXECUTE_KEY] as ExecutePaymentResponseObject
          const paymentError: boolean = paymentResponseObject.meta.isError
          if (paymentError) {
            this.hoosierState.set("EXECUTE_KEY", () => null)
            this.hoosierState.set("PAYMENT", () => null)
            this.hoosierState.set("PAYMENT_ERROR", () => paymentResponseObject)
          }

          if (connectsuite[EXECUTE_KEY]) {
            /**
             * response from Connectsuite
             */
            this.hoosierState.set("EXECUTE_KEY", () => null)
            const paymentResponseObject = cybersource[EXECUTE_KEY] as ExecutePaymentResponseObject
            const paymentError: boolean = paymentResponseObject.meta.isError
            if (!paymentError) {
              /**
               * payment is successful
               */
              const membershipResponseObject = connectsuite[EXECUTE_KEY] as ExecuteMembershipResponseObject
              const membershipError: boolean = membershipResponseObject.meta.isError
                || membershipResponseObject.response.response?.attributes?.responseCode !== "000"

              this.hoosierState.set("PAYMENT", () => paymentResponseObject)
              this.hoosierState.set("PAYMENT_ERROR", () => null)
              this.memberLookupService.memberLookup(this.globalState.get("windowMetaData", "user", "memberNumber"), "MEMBER_LOOKUP_KEY")

              if (!membershipError) {
                /**
                 * Connectsuite operation is successful
                 */
                this.hoosierState.get("form").reset(this.hoosierService.newHoosierItem)
                this.hoosierState.set("MEMBERSHIP", () => membershipResponseObject)
                this.hoosierState.set("MEMBERSHIP_ERROR", () => null)
              }

              if (membershipError) {
                /**
                 * Connectsuite operation has an error
                 */
                this.hoosierState.set("MEMBERSHIP", () => null)
                this.hoosierState.set("MEMBERSHIP_ERROR", () => membershipResponseObject)
              }

              /**
               * transition the user to the CONFIRMATION section after successful payment
               * The CONFIRMATION section will present Connectsuite failure messaging if needed
               */
              switch (this.hoosierState.get("activeFlow")) {
                case Flow.MOBILE_APP_RENEW:
                  switch (this.hoosierState.get("activeView")) {
                    case AccountMembershipView.LEVEL:
                    case AccountMembershipView.RENEW:
                      this.hoosierState.set("activeCheckoutStep", () => CheckoutStep.CONFIRMATION)
                      break

                    default:
                      this.hoosierState.set("activeCheckoutStep", () => null)
                  }
                  break
                case Flow.GIFT:
                case Flow.JOIN:
                  this.globalState.set("userSession", oldState => {
                    oldState.userSession.couponCode = ""
                    oldState.userSession.promoCode = ""
                    return oldState.userSession
                  })
                  this.hoosierState.set("activeSection", () => JoinSection.CONFIRMATION)
                  this.hoosierState.set("activeView", () => JoinView.CONFIRMATION)
                  break
                case Flow.QUICK_RENEW:
                  this.hoosierState.set("activeSection", () => QuickRenewSection.CONFIRMATION)
                  this.hoosierState.set("activeView", () => QuickRenewView.CONFIRMATION)
                  break
                case Flow.ACCOUNT:
                  switch (this.hoosierState.get("activeView")) {
                    case AccountMembershipView.ASSOCIATES_ADD:
                      this.hoosierState.set("activeView", () => AccountMembershipView.ASSOCIATES)
                      this.hoosierState.set("activeCheckoutStep", () => CheckoutStep.CONFIRMATION)
                      break
                    case AccountMembershipView.RENEW:
                    case AccountMembershipView.LEVEL:
                      this.hoosierState.set("activeCheckoutStep", () => CheckoutStep.CONFIRMATION)
                      break
                    case AccountMembershipView.AUTO_RENEW:
                    case AccountMembershipView.AUTO_RENEW_CARD:
                      this.hoosierState.set("activeView", () => AccountMembershipView.AUTO_RENEW)
                      this.hoosierState.set("activeCheckoutStep", () => CheckoutStep.CONFIRMATION)
                      break
                    default:
                      this.hoosierState.set("activeCheckoutStep", () => null)
                  }
              }
            }
          }
        }
      }),
    )

  flexMicroFormCreateToken(operation: Operation, membershipExecutionData: string): void {
    if (membershipExecutionData) {
      // this.hoosierState.set("EXECUTE_STATUS", () => OpStatus.RUNNING)

      const expirationMonth = this.form.get(["creditCard", "expirationMonth"])?.value.toString()
      const expirationYear = this.form.get(["creditCard", "expirationYear"])?.value.toString()
      const options = {
        expirationMonth: expirationMonth.length === 1 ? "0" + expirationMonth : expirationMonth,
        expirationYear: expirationYear,
      }

      this.hoosierState.set("PAYMENT_CYBERSOURCE_GENERATE_TOKEN", () => OpStatus.RUNNING)
      this.cybersourceService.flexMicroForm?.createToken(options, (error, flexMicroFormToken) => {
        this.hoosierState.set("PAYMENT_CYBERSOURCE_GENERATE_TOKEN", () => OpStatus.STOPPED)

        if (error) {
          this.hoosierState.set("PAYMENT_TOKEN_ERROR", () => error)
          // this.hoosierState.set("EXECUTE_STATUS", () => OpStatus.IS_ERROR)
          this.alertMessageService.set(AlertMessage.ERROR_CRITICAL, AlertType.ERROR)
        }
        if (!error && flexMicroFormToken) {
          this.hoosierState.set("PAYMENT_TOKEN_ERROR", () => null)
          this.execute(operation, membershipExecutionData, flexMicroFormToken)
        }
        /**
         * TODO: if timeout...
         */
      })
    }
  }

  execute(operation: Operation, membershipExecutionData: string, flexMicroFormToken: string | null): void {
    const hoosierForm = this.hoosierService.form.getRawValue() as HoosierForm
    const responseKeyTimestampString = Timestamp.now().toMillis().toString()
    this.hoosierState.set("EXECUTE_KEY", () => responseKeyTimestampString)

    let paymentPayload: PaymentCybersourceOperationExecuteEventPayload | null = null
    if (flexMicroFormToken) {
      paymentPayload = hoosierForm.paymentPayload
      paymentPayload.executionData.flexMicroFormToken = flexMicroFormToken
      paymentPayload.method = PaymentCybersourceMethod.OPERATION_EXECUTE
      paymentPayload.operation = operation
      paymentPayload.responseKey = responseKeyTimestampString

      paymentPayload.executionData.billTo.country = "US"
      if (hoosierForm.useMemberInfoForBilling) {
        paymentPayload.executionData.billTo = this.memberInfoToBillingService.convertMemberInfoToBillingInfo(hoosierForm.membershipPayload)
      }
      paymentPayload.executionData.billTo.phoneNumber = hoosierForm.membershipPayload.primary?.cellPhone || ""
      // paymentPayload.executionData.billTo.email = hoosierForm.membershipPayload.primary.email !== "NONE@" && hoosierForm.membershipPayload.primary.email
      //   ? hoosierForm.membershipPayload.primary.email
      //   : "fallback@avagate.com"
      paymentPayload.executionData.billTo.email = "fallback@avagate.com"
    }

    const cacheSettings: ClearCacheSettings = {
      cacheType: "clear",
      clearContext: this.hoosierState.get("accountDetails", "memberNumber"),
    }

    const membershipEventPayload: MembershipConnectSuiteOperationExecuteEventPayload = {
      cacheSettings: cacheSettings,
      executionData: membershipExecutionData,
      method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
      operation: operation,
      responseKey: responseKeyTimestampString,
    }

    if (this.hoosierState.get("activeFlow") === Flow.JOIN) {
      membershipEventPayload.loginCredentials = {
        email: hoosierForm.membershipPayload.primary?.email,
        encryptedPassword: CryptoJS.AES.encrypt(hoosierForm.password, "Z9ekXiSFpyh2IXvE").toString(),
        password: hoosierForm.password,
        zip: hoosierForm.membershipPayload.membership.address.postalCode,
        iso: "",
        club: "",
        household: "",
        associate: "",
        check_digit: "",
      }
    }

    const operationExecutePayload: OperationExecutePayload = {
      membershipEvent: membershipEventPayload,
      operation: operation,
      paymentEvent: paymentPayload,
      responseKey: responseKeyTimestampString,
    }

    if (this.globalState.get("adminUser")) {
      // console.log(operationExecutePayload)
      // console.log(cacheSettings.clearContext)
      // return
    }

    this.joinRenewService
      .sendToEventCoordinatorReceiver(EventName.OPERATION_EXECUTE, operationExecutePayload)
      .then(response => {
        if (this.globalState.get("adminUser")) {
          console.log(EventName.OPERATION_EXECUTE + "---" + operation + "---" + responseKeyTimestampString + "---" + response)
        }
      })
      .catch(error => {
        this.alertMessageService.set(AlertMessage.ERROR_CRITICAL, AlertType.ERROR)
        this.hoosierState.set("EXECUTE_KEY", () => null)
        // console.error(error)
      })
  }

}
