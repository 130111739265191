import {Injectable} from "@angular/core"
import {FieldType} from "../../services/field";
import { FieldBase } from "../../block/services/block"

interface AgentScheduler extends FieldBase {
  // label?: string
}

@Injectable({
  providedIn: "root",
})
export class AgentSchedulerService {

  get newItem(): AgentScheduler {
    return {
      fieldType: FieldType.AGENT_SCHEDULER,
    }
  }

  get testPayload(): any{
    return {
      "meta": {
      "clubId": "071",
        "user": {
        "firstName": "Admin",
          "lastName": "User",
          "email": "adminuser@aaashare.com",
          "memberNumber": "4290711234567884",
          "roles": [
          "authenticated user",
          "administrator"
        ],
          "userId": "1",
          "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay1ncnY5MUBhYWEtc291dGhqZXJzZXktMi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLWdydjkxQGFhYS1zb3V0aGplcnNleS0yLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwiYXVkIjoiaHR0cHM6XC9cL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbVwvZ29vZ2xlLmlkZW50aXR5LmlkZW50aXR5dG9vbGtpdC52MS5JZGVudGl0eVRvb2xraXQiLCJpYXQiOjE2ODU1NDM4NjgsImV4cCI6MTY4NTU0NzQ2OCwidWlkIjoiY2x1YklkOjA3MTt1c2VySWQ6MSIsImNsYWltcyI6eyJyb2xlcyI6WyJhdXRoZW50aWNhdGVkIHVzZXIiLCJhZG1pbmlzdHJhdG9yIl0sIm1lbWJlck51bWJlciI6IjQyOTA3MTEyMzQ1Njc4ODQifX0.CAY_NP64Ha0MMYxcYkt2hNY5QarK3mrGhOGZYZNIw6qwecP15Wpg9rGy5AZ80OY5qAqvZfiIb1AaA7rV702JHeSjIUkw5iRzQorRDsUdcWnH4w8j2Qbfe_aHmtGk6GA1kzWb5DVe6Y0hxLe_Twvv-pRhzN4xhMavHNRMWI5WCkdmdS-gedfggfRUe2Rm96Du5jRkgDo-mconaxGwK4rMrj1EUV3SwOkwZoRL_lPjc6EgFg5R9gREfQjGHsna18TK8SsSpvXLHshM-Rg-LEbCsxoK-WyLhmhQA__jD1Ys-H84q7Hxz6JGM0MNk8FdgbWy-LIGrvsX4yTm2HYG0SlOjA"
      },
      "webEnv": "test",
        "sessionId": "nq-yNUzl_eisxmV-QLqINRPEH4Xoohj3atruSXIM2Hs",
        "tenantId": "522ebbbe-549c-4a37-b964-e52c4b20e0f9",
        "appMode": "TRAVEL"
    },
      "agents": [
      {
        "fName": "Cheryl",
        "lName": "Chambers",
        "email": "cchambers@aaasj.com",
        "typeIds": [
          "3378"
        ],
        "about": "I have enjoyed 30 years in the Travel Industry and one of reasons I love travel so much is for the adventure!\r\rI have been to many interesting countries in Europe, including: Egypt, Greece, Croatia, Czech Republic, Hungary, Slovakia,&nbsp; Austria, Germany, Italy, Spain, Netherlands, France, England and Denmark.&nbsp; I have also enjoyed traveling to Central &amp; South America, places such as Costa Rica and Mexico, and I also try to vacation in the Caribbean when possible! Plus, I've loved many adventures like hiking the Grand Canyon and Yosemite, and been to many places in North America (including Hawaii).\r\rSome of my certifications include: Viking Cruises, Oceania Cruises, Sandals and Beaches Resorts, Hawaii Specialist, Honeymoon and Celebration Travel.",
        "phone": "856-679-2669   ",
        "image": "https://test.southjersey.aaa.com/sites/default/files/styles/agent_search_result/public/CherylChambers400.jpg?itok=UYV9APQh",
        "officeIds": [
          "8362"
        ],
        "topicIds": [
          "3387",
          "3382",
          "3383",
          "3384",
          "3385",
          "3386"
        ],
        "regionIds": [
          "3358",
          "3359",
          "3360",
          "3361",
          "3362",
          "3363",
          "3364",
          "3365",
          "3366",
          "3367",
          "3368"
        ],
        "meetingIds": [
          "3380",
          "3381"
        ],
        "shareWithIds": [
          "3388"
        ]
      },
      {
        "fName": "Tina Marie",
        "lName": "Harding",
        "email": "tharding@aaasj.com",
        "typeIds": [
          "3378"
        ],
        "about": "Tina Marie Harding has been working in the Travel Industry for over 27 years across different areas. Her passion is for anything Disney, having travelled there so many times that she's lost count. She's also had the opportunity to go on many cruises and travel to most of the Caribbean and even some European countries as well. Tina lives locally with her husband &amp; two children and enjoys the beach &amp; watching her kids play sports.\r",
        "phone": "856-679-2689",
        "image": "https://test.southjersey.aaa.com/sites/default/files/styles/agent_search_result/public/TinaH400.jpg?itok=QEPe2TE_",
        "officeIds": [
          "8362"
        ],
        "topicIds": [
          "3387",
          "3382",
          "3383",
          "3384",
          "3385",
          "3386"
        ],
        "regionIds": [
          "3358",
          "3359",
          "3360",
          "3361",
          "3362",
          "3363",
          "3364",
          "3365",
          "3366",
          "3367",
          "3368"
        ],
        "meetingIds": [
          "3380",
          "3381"
        ],
        "shareWithIds": [
          "3388"
        ]
      },
      {
        "fName": "Susan",
        "lName": "Romer",
        "email": "sromer@aaasj.com",
        "typeIds": [
          "3378"
        ],
        "about": "Susan Romer has over 25 years of experience in the Travel industry. In 2013 she was a top sales producer and received The Million Dollar Agent award. She is a Certified Travel Consultant and has received certifications with Sandals/Beaches Resorts as well as titles as an Authorized Disney Vacation Planner and Cruise Specialist. She is always willing to go above and beyond for her clients' needs to make sure they are totally satisfied. She especially enjoys working with and booking groups. Her favorite vacation spots are Europe, Mexico, the Caribbean, Walt Disney World and Cruising. In her spare time, Susan enjoys spending time with her&nbsp;and two daughters Rachael and Alisha, whose active schedules include Softball, Dance and Cheer.\r",
        "phone": "856-679-2673   ",
        "image": "https://test.southjersey.aaa.com/sites/default/files/styles/agent_search_result/public/SusanRomer400.jpg?itok=jlbtvN1H",
        "officeIds": [
          "8362"
        ],
        "topicIds": [
          "3382",
          "3387",
          "3383",
          "3384",
          "3385",
          "3386"
        ],
        "regionIds": [
          "3358",
          "3359",
          "3360",
          "3361",
          "3362",
          "3363",
          "3364",
          "3365",
          "3366",
          "3367",
          "3368"
        ],
        "meetingIds": [
          "3380",
          "3381"
        ],
        "shareWithIds": [
          "3388"
        ]
      },
      {
        "fName": "Carol",
        "lName": "Symington",
        "email": "csymington@aaasj.com",
        "typeIds": [
          "3378"
        ],
        "about": "Carol Symington has worked in the Travel Industry for over 30 years, spending the last 9 years with AAA South Jersey. She loves working with her clients, many of whom she's become friends in making their travel plans. She loves seeing the way couples look at each other while making their honeymoon plans, the happiness she sees as others prepare to celebrate a special milestone, and the smiles and excitement in the eyes of her very young clients who are awaiting that special trip to Disney. Carol enjoys working in her community as a board member for the Greater Swedesboro Business Association, teaching Religious Education in her parish, and spending time with her four grandkids.\r",
        "phone": "856-679-2676 ",
        "image": "https://test.southjersey.aaa.com/sites/default/files/styles/agent_search_result/public/CarolSymington400.jpg?itok=hLVbW4rf",
        "officeIds": [
          "8364"
        ],
        "topicIds": [
          "3387",
          "3382",
          "3383",
          "3384",
          "3385",
          "3386"
        ],
        "regionIds": [
          "3358",
          "3359",
          "3360",
          "3361",
          "3362",
          "3363",
          "3364",
          "3365",
          "3366",
          "3367",
          "3368"
        ],
        "meetingIds": [
          "3380",
          "3381"
        ],
        "shareWithIds": [
          "3388"
        ]
      },
      {
        "fName": "Jacquelyne",
        "lName": "Jacquet",
        "email": "jjacquet@aaasj.com",
        "typeIds": [
          "3378"
        ],
        "about": "I have over 20 years' experience in the travel industry, helping people plan their vacations from honeymoons to family vacations.&nbsp; Throughout the years I have traveled through Europe, US, the Caribbean, and Mexico, all giving me plenty of personal experience when it comes to travel. One of my most memorable vacations was traveling to Machu Picchu in Peru, which had some of the most beautiful views when at the mountaintop. When I am not traveling, I enjoy camping with my family, reading, and walks with my German Shorthair Pointers Molly and Finnegan.\r\rI am certified with Disney College of Knowledge, Viking River Cruise, Princess Cruises and Sandals &amp; Beaches Resorts.\r",
        "phone": "856-679-2683 ",
        "image": "https://test.southjersey.aaa.com/sites/default/files/styles/agent_search_result/public/JacquelyneWhite400.jpg?itok=v9j4UPK5",
        "officeIds": [
          "8363"
        ],
        "topicIds": [
          "3387",
          "3382",
          "3383",
          "3384",
          "3385",
          "3386"
        ],
        "regionIds": [
          "3358",
          "3359",
          "3360",
          "3361",
          "3362",
          "3363",
          "3364",
          "3365",
          "3366",
          "3367",
          "3368"
        ],
        "meetingIds": [
          "3380",
          "3381"
        ],
        "shareWithIds": [
          "3388"
        ]
      },
      {
        "fName": "Tracy",
        "lName": "Grier-Cossaboon",
        "email": "tcossaboon@aaasj.com",
        "typeIds": [
          "3378"
        ],
        "about": "I have over 30 years' experience in the Travel Industry. My first job was at Haddon Holidays, a tour operator doing packages to Hawaii, Australia, New Zealand and the South Pacific.&nbsp;This enabled me to travel to Hawaii three times, and go to Australia and New Zealand during my time there.&nbsp; To this day, Hawaii and New Zealand are at the top of my favorite places to go.&nbsp;\r\rI have traveled to many Caribbean Islands \u2013 St. Lucia and Jamaica are especially memorable for me. &nbsp;In the good ole USA, some of my favorite places I've been are Sedona, San Francisco and Key West. I do think however, my favorite trip was our CEO trip to Switzerland \u2013 such a wonderful trip and beautiful country.\r\rCustomer Service and listening to what the clients want is my top priority. I thoroughly enjoy planning and working with people to plan their perfect vacation.&nbsp; I consider many of my longtime clients friends and feel very blessed to have a career that I love and to have met so many wonderful people during my journey\r",
        "phone": "856-679-2684   ",
        "image": "https://test.southjersey.aaa.com/sites/default/files/styles/agent_search_result/public/TracyCossaboon400.jpg?itok=5-ODkGKV",
        "officeIds": [
          "8363"
        ],
        "topicIds": [
          "3387",
          "3382",
          "3383",
          "3384",
          "3385",
          "3386"
        ],
        "regionIds": [
          "3358",
          "3359",
          "3360",
          "3361",
          "3362",
          "3363",
          "3364",
          "3365",
          "3366",
          "3367",
          "3368"
        ],
        "meetingIds": [
          "3380",
          "3381"
        ],
        "shareWithIds": [
          "3388"
        ]
      },
      {
        "fName": "Regina",
        "lName": "Kurcheski",
        "email": "rkurcheski@aaasj.com",
        "typeIds": [
          "3378"
        ],
        "about": "I have been involved in different facets of the travel industry over the course of 20 years and have been working at AAA South Jersey since I moved in New Jersey with my family in 2017.&nbsp; I have experience in various different types of travel, such as: Leisure, Corporate, Group &amp; Event travel. As for destination I am skilled in the following: River and Ocean Cruising, Caribbean, European Travel, Disney, US National Parks,&nbsp; and Hawaii.&nbsp; One of my favorite places to visit are the Hawaiian Islands,&nbsp;where I have visited several times, the array of Natural Beauty from Beaches, Volcanoes, Canyons, &amp; Tropical Rain Forest is truly stunning.\r",
        "phone": "856-679-2695",
        "image": "https://test.southjersey.aaa.com/sites/default/files/styles/agent_search_result/public/Gina%20Travel%20Profile%20Pic_0.jpg?itok=gxOnMr21",
        "officeIds": [
          "8362"
        ],
        "topicIds": [
          "3387",
          "3382",
          "3383",
          "3384",
          "3385",
          "3386"
        ],
        "regionIds": [
          "3358",
          "3359",
          "3360",
          "3361",
          "3362",
          "3363",
          "3364",
          "3365",
          "3366",
          "3367",
          "3368"
        ],
        "meetingIds": [
          "3380",
          "3381"
        ],
        "shareWithIds": [
          "3388"
        ]
      }
    ],
      "offices": [
      {
        "id": "8362",
        "name": "Voorhees Office",
        "addrLine1": "700 Laurel Oak Road",
        "addrLine2": "Voorhees, NJ 08043",
        "geoLoc": [
          "39.845284800000",
          "-74.982777800000"
        ]
      },
      {
        "id": "8363",
        "name": "Millville Office",
        "addrLine1": "2190 North 2nd Street",
        "addrLine2": "Millville, NJ 08332",
        "geoLoc": [
          "39.423211400000",
          "-75.040522800000"
        ]
      },
      {
        "id": "8364",
        "name": "Sewell Office",
        "addrLine1": "380 Egg Harbor Rd",
        "addrLine2": "Sewell, NJ 08080",
        "geoLoc": [
          "39.753035056602",
          "-75.075641870499"
        ]
      },
      {
        "id": "9506",
        "name": "Cherry Hill Office",
        "addrLine1": "2000 Marlton Pike W",
        "addrLine2": "Cherry Hill, NJ 08002",
        "geoLoc": [
          "39.920679690676",
          "-75.034628257672"
        ]
      }
    ],
      "meetings": [
      {
        "id": "3380",
        "text": "Phone",
        "response": "",
        "default": "0",
        "online": 0,
        "type": "phone"
      },
      {
        "id": "3381",
        "text": "In-person",
        "response": "",
        "default": "0",
        "online": 0,
        "type": "inPerson"
      }
    ],
      "regions": [
      {
        "id": "3358",
        "text": "I'm not sure yet",
        "response": "",
        "default": "0"
      },
      {
        "id": "3359",
        "text": "Africa",
        "response": "",
        "default": "0"
      },
      {
        "id": "3360",
        "text": "Antarctica",
        "response": "",
        "default": "0"
      },
      {
        "id": "3361",
        "text": "Asia",
        "response": "",
        "default": "0"
      },
      {
        "id": "3362",
        "text": "Australia &amp; Oceania",
        "response": "",
        "default": "0"
      },
      {
        "id": "3363",
        "text": "Canada",
        "response": "",
        "default": "0"
      },
      {
        "id": "3364",
        "text": "Europe",
        "response": "",
        "default": "0"
      },
      {
        "id": "3365",
        "text": "Mexico / Caribbean / Hawaii",
        "response": "",
        "default": "0"
      },
      {
        "id": "3366",
        "text": "North America",
        "response": "",
        "default": "0"
      },
      {
        "id": "3367",
        "text": "South America",
        "response": "",
        "default": "0"
      },
      {
        "id": "3368",
        "text": "Other",
        "response": "",
        "default": "0"
      }
    ],
      "topics": [
      {
        "id": "3387",
        "text": "Vacation &amp; Tours",
        "icon": "icon-icon-travel"
      },
      {
        "id": "3382",
        "text": "Cruise Vacations",
        "icon": "icon-cruise"
      },
      {
        "id": "3383",
        "text": "Flights, Hotels, Cars",
        "icon": "icon-icon-airplane"
      },
      {
        "id": "3384",
        "text": "Groups &amp; Weddings",
        "icon": "icon-group"
      },
      {
        "id": "3385",
        "text": "Travel Documents",
        "icon": "icon-safety-inspection"
      },
      {
        "id": "3386",
        "text": "Travel Insurance",
        "icon": "icon-health1"
      }
    ],
      "types": [
      {
        "id": "3378",
        "text": "Travel",
        "response": ""
      },
      {
        "id": "3379",
        "text": "Insurance",
        "response": ""
      }
    ],
      "shareWith": [
      {
        "id": "3388",
        "email": ""
      }
    ]
    }
  }


}
