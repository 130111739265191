import { createFeatureSelector, createSelector } from "@ngrx/store"
import { getClubId, getClubOption } from "@aaa/emember/store-membership"
import { QuickRenewFormState } from "./quick-renew.models"
import { RequestStatus } from "../../../types/request-status"
import { ClubApp } from "@aaa/emember/types"
import { getErrorMessage } from "../generic-errors"
import { AccountDetailsSelectors } from "../../modules/share/ngrx-helpers/account-details.selectors"

export const QUICK_RENEW_FORM_FEATURE_KEY = "quick-renew-form"

export const getQuickRenewFormState = createFeatureSelector<QuickRenewFormState>(QUICK_RENEW_FORM_FEATURE_KEY)
export const getQuickRenewExecutionData = createSelector(getQuickRenewFormState, ({ executionData }) => executionData)
export const getQuickRenewExecutionError = createSelector(
  getQuickRenewFormState,
  ({ executionError }) => executionError,
)
export const getQuickRenewExecutionRequestStatus = createSelector(
  getQuickRenewFormState,
  ({ executionRequestStatus }) => executionRequestStatus,
)

export const getQuickRenewValidationRequestStatus = createSelector(
  getQuickRenewFormState,
  ({ validationRequestStatus }) => validationRequestStatus,
)
export const getQuickRenewErrors = createSelector(
  getQuickRenewFormState,
  ({ validationError, executionError }) => validationError || executionError,
)
export const getQuickRenewAccountDetails = createSelector(
  getQuickRenewFormState,
  ({ accountDetails }) => accountDetails,
)
export const QuickRenewAccountDetails = AccountDetailsSelectors(getQuickRenewAccountDetails, getClubOption)

// Combination selectors
export const getQuickRenewErrorMessage = createSelector(getQuickRenewErrors, getClubOption, (error, option) =>
  getErrorMessage(error, option),
)
export const getQuickRenewConfirmationReturnLink = createSelector(getClubId, (clubId) => {
  if (clubId === ClubApp.Hoosier) {
    return "https://hoosier.aaa.com/my-account"
  }

  if (clubId === ClubApp.MidStates) {
    return "https://midstates.aaa.com/my-account"
  }

  return ""
})

export const getQuickRenewIsLoadingValidation = createSelector(
  getQuickRenewValidationRequestStatus,
  (status) => status === RequestStatus.RUNNING,
)
export const getQuickRenewIsLoadingExecution = createSelector(
  getQuickRenewExecutionRequestStatus,
  (status) => status === RequestStatus.RUNNING,
)

export const getQuickRenewShowCardHolderForm = createSelector(getClubId, (clubId) => {
  if (clubId === ClubApp.Hoosier || clubId === ClubApp.Shelby || clubId === ClubApp.Northampton) {
    return true
  }

  if (clubId === ClubApp.MidStates) {
    return false
  }

  return false
})
