export enum ClubApp {
  Hoosier = "023",
  MidStates = "238",
  Shelby = "160",
  Northampton = "188",
  Colorado = "006",
  Minneapolis = "057",
  Northway = "104",
  SouthJersey = "071",
  Avagate = "",
  Empty = "",
}
export const ClubAppName = {
  [ClubApp.Hoosier]: "Hoosier",
  [ClubApp.MidStates]: "MidStates",
  [ClubApp.Shelby]: "Shelby",
  [ClubApp.Northampton]: "Northampton",
  [ClubApp.Colorado]: "Colorado",
  [ClubApp.Minneapolis]: "Minneapolis",
  [ClubApp.Northway]: "Northway",
  [ClubApp.SouthJersey]: "SouthJersey",
  [ClubApp.Avagate]: "Avagate",
}

export const getClubContactPhone = (clubId: string, zipcode: string) => {
  if (clubId === ClubApp.Hoosier) return "8444622246"
  if (clubId === ClubApp.MidStates) return "8002221469"
  if (clubId === ClubApp.Shelby) return "9374923167"
  if (clubId === ClubApp.SouthJersey) return "8557725551"
  if (clubId === ClubApp.Northampton) {
    if (zipcode === "18045") return "6102582371"
    if (zipcode === "17901") return "5706224991"

    return "6102582371"
  }

  return ""
}
export const getClubTermsAndConditionUrl = (clubId: string) => {
  switch (clubId) {
    case ClubApp.Hoosier:
      return "https://www.hoosier.aaa.com/terms-conditions"
    case ClubApp.MidStates:
      return "https://www.midstates.aaa.com/terms-conditions"
    case ClubApp.Northampton:
      return "https://northampton.aaa.com/getmedia/c99fa4e7-2fcd-4fe9-b73a-d849dc6ec74c/AAA-Northampton-CountyHandbook.pdf"
    case ClubApp.SouthJersey:
      return "https://www.southjersey.aaa.com/terms-conditions"
    default:
      return ""
  }
}

export const getPrivacyUrl = (clubId: string) => {
  switch (clubId) {
    case ClubApp.Hoosier:
      return "https://www.hoosier.aaa.com/privacy-policy"
    case ClubApp.MidStates:
      return "https://www.midstates.aaa.com/privacy-policy"
    case ClubApp.Northampton:
      return "https://northampton.aaa.com/privacy-policy/"
    case ClubApp.SouthJersey:
      return "https://www.southjersey.aaa.com/privacy-policy"
    default:
      return ""
  }
}

export enum AppEnv {
  PROD = "prod",
  TEST = "test",
}
