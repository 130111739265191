import { JwtAuthService } from "../../services/jwt-auth"
import { ContactRecord } from "@aaa/interface-smartWeather"
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { GcpConnectorService } from "./services/gcp-connector.service"
import { SwStateMachineService } from "./services/sw-state-machine.service"
import { combineLatestWith, Subscription, tap } from "rxjs"
import { StateSyncService } from "./services/state-sync.service"
import { AnalyticsService } from "../../services/analytics"
import { DOCUMENT } from "@angular/common"
import { GLOBAL_RX_STATE, GlobalState } from "../../services/state"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"

@Component({
  selector: "ava-smart-weather",
  templateUrl: "./smart-weather.component.html"
})
export class SmartWeatherComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("container") container: ElementRef | undefined
  globalState$ = this.globalState.select()
  containerWidth: number | undefined
  buttonWrapperStyles: { [key: string]: string } = {}
  stateSubscription: Subscription | undefined
  userSubscription: Subscription | undefined
  testGet: ContactRecord | undefined
  state: {
    cloudSync: boolean
    cloudSyncError: boolean
    list: boolean
    location: boolean
  } | undefined
  locations: any[] = []

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rxEffects: RxEffects,
    private auth: JwtAuthService,
    private gcpConnector: GcpConnectorService,
    private swSms: SwStateMachineService,
    private stateSyncService: StateSyncService,
    private analyticsService: AnalyticsService,
  ) {
    rxEffects.register(this.setup$)
    rxEffects.register(this.swSmsState$)
  }

  setup$ = this.globalState.select("afAuthIdTokenResult")
    .pipe(
      combineLatestWith(
        this.globalState.select("windowMetaData", "user"),
        this.globalState.select("windowMetaData", "clubId"),
      ),
      tap(async ([afAuthIdTokenResult, user, clubId]) => {
        if (afAuthIdTokenResult && user?.firstName && user?.lastName && user?.memberNumber && clubId) {
          await this.gcpConnector.setupService(clubId)
          await this.stateSyncService.setupService(clubId, user.memberNumber)
          this.swSms.setupService(user.memberNumber, user.firstName, user.lastName)
        }
      })
    )

  swSmsState$ = this.swSms.state$
    .pipe(
      tap(swSmsState => {
        if (swSmsState) {
          this.state = {
            cloudSync: swSmsState.value === "cloudSync",
            cloudSyncError: swSmsState.value === "cloudSyncError",
            list: swSmsState.value === "list",
            location: swSmsState.value === "location"
          }
          if (swSmsState.context.locations) {
            this.locations = swSmsState.context.locations
          }
        }
      })
    )


  ngOnInit(): void {
    // this.metaData = this.window["metaData"]
    // console.log(JSON.stringify(params, null, 2))

/*
    const metaData = this.globalState.get("windowMetaData")
    const clubId = metaData.clubId
    const memberNumber = metaData.user.memberNumber
    const firstName = metaData.user.firstName
    const lastName = metaData.user.lastName
*/

/*
    if (memberNumber) {
      // this.auth.setupService(this.metaData.user.token)
      this.userSubscription = this.auth.user$
        .subscribe(async user => {
          if (user) {
            console.log(user)
            // console.log(this.window.metaData)
            await this.gcpConnector.setupService(clubId)
            await this.stateSyncService.setupService(clubId, memberNumber)
            this.swSms.setupService(memberNumber, firstName, lastName)
          }
        })
*/
/*
      this.stateSubscription = this.swSms.state$
        .subscribe(state => {
          if (state) {
            this.state = {
              cloudSync: state.value === "cloudSync",
              cloudSyncError: state.value === "cloudSyncError",
              list: state.value === "list",
              location: state.value === "location"
            }
            if (state.context.locations) {
              this.locations = state.context.locations
            }
          }
        })
*/

    // this.testWrite = await this.gcpConnector.writeContact(testRecord)
    // console.log(JSON.stringify(this.testWrite, null, 2))

    // this.testGet = await this.gcpConnector.getContact()
    // console.log(JSON.stringify(this.testGet, null, 2))
    const userId = this.globalState.get("windowMetaData", "user", "id")
    if (userId && userId !== "1") {
      this.analyticsService.sendPage(window.location.pathname.toLowerCase() + "/smart-weather-form")
    }

  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription?.unsubscribe()
    }
    if (this.stateSubscription) {
      if (this.stateSubscription) {
        this.stateSubscription.unsubscribe()
      }
    }
    if (this.stateSyncService) {
      this.stateSyncService?.unsubscribe()
    }
  }

  ngAfterViewInit(): void {
    this.onResize()
  }

  @HostListener("window:resize")
  onResize(): void {
    this.containerWidth = this.container?.nativeElement.offsetWidth

    this.buttonWrapperStyles = {}
    if (this.containerWidth && this.containerWidth >= 760) {
      this.buttonWrapperStyles = { "max-width": "50%" }
    }
    if (this.containerWidth && this.containerWidth >= 1080) {
      // this.gridTemplateColumns = { "grid-template-columns": "210px auto 300px" }
    }

  }

}
