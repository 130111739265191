import { createReducer, on } from "@ngrx/store"
import { SummaryState } from "./summary.model"
import { SummaryActions } from "./summary.actions"

export const initialStateSummary: SummaryState = {
  totalCost: 0,
  accounts: [],
  priceItems: [],
  offers: {
    primary: { offering: "primary", selectedByDefault: true, code: "", description: "", amount: 0 },
    optionalPrimary: [],
    associates: [],
    optional: [],
  },
}

export const summaryReducer = createReducer(
  initialStateSummary,
  on(SummaryActions.set, (state, { totalCost, priceItems, accounts }) => ({
    ...state,
    priceItems: priceItems || state.priceItems,
    totalCost: totalCost || state.totalCost,
    accounts: accounts || state.accounts,
  })),
  on(SummaryActions.setOffers, (state, { offers }) => ({
    ...state,
    offers,
  })),
  on(SummaryActions.setTotalCost, (state, { totalCost }) => ({
    ...state,
    totalCost,
  })),
  on(SummaryActions.reset, (state) => ({ ...state, ...initialStateSummary })),
)
