import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { catchError, map, switchMap } from "rxjs/operators"
import { MembershipUpgradePlan } from "./membership-upgrade-plan.actions"
import { Observable, of, withLatestFrom } from "rxjs"
import { Operation, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteOperationExecuteEventPayload,
  MembershipConnectSuiteRecostValidateChangeLevelEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import { FormGroupValue } from "../../modules/share/form.utils"
import { getPayment, getPaymentAutoRenewValue } from "../payment/payment.selectors"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import { AccountDetails, ValidateSucceededResponseObject } from "../types/types"
import { ConnectSuite } from "../connect-suite.type"
import { getMembershipUpgradePlanFormValues } from "./membership-upgrade-plan.selectors"
import { MembershipUpgradePlanForm, MembershipUpgradePlanPayParam } from "./membership-upgrade-plan.models"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { ExecuteService } from "../services/execute.service"
import { AccountStatus, ClubApp } from "@aaa/emember/types"
import { getAccountDetails, getAccountDetailsStatus } from "@aaa/emember/store-account"
import { RequestError, RequestErrorType } from "../generic-errors"
import { checkOperationErrorsConnectSuiteSystem } from "../check-operation-errors-connect-suite-system"
import { getClearCacheSettings } from "../utils/get-cache-settings"
import { AnalyticsPurchaseEvent } from "../../../types/analytics-purchase-event"
import { DataLayerService } from "../../modules/share/services/data-layer.service"
import { getTransactionId } from "../utils/get-transaction-id"
import { AppAnalyticsEvents } from "../../../types/analytics-events"

@Injectable({ providedIn: "root" })
export class MembershipUpgradePlanConnectSuitSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Hoosier, ClubApp.SouthJersey]))
  executeService = inject(ExecuteService)
  dataLayer = inject(DataLayerService)

  setSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.recostValidateSucceeded),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getAccountDetailsStatus)))),
      map(([data, accountStatus]) => {
        const { response } = data.response as ConnectSuite.MemberUpgradeResponseObject
        const accountDetails = new ConnectSuite.AccountInfo(response.validationData)

        if (accountStatus !== AccountStatus.PENDING) {
          accountDetails.balance = Number(response?.validationData.attributes.totalCost)
        }

        return MembershipUpgradePlan.setAccountDetails({ accountDetails })
      }),
    ),
  )

  recostValidateMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.recostValidate),
      withLatestFrom(
        this.store.select(getMembershipUpgradePlanFormValues),
        this.store.select(getPaymentAutoRenewValue),
        this.store.select(getAccountDetails),
      ),
      switchMap(([, formValues, autoRenew, accountDetails]) =>
        this.recostValidate(formValues, autoRenew, accountDetails).pipe(
          map((res) => MembershipUpgradePlan.recostValidateSucceeded(res)),
          catchError((error) => of(MembershipUpgradePlan.recostValidateFailed({ error }))),
        ),
      ),
    ),
  )

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpgradePlan.pay),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getPayment)))),
      switchMap(([{ params }, payment]) =>
        this.pay(params, payment).pipe(
          map(() => MembershipUpgradePlan.paySucceeded()),
          catchError((error) => of(MembershipUpgradePlan.payFailed({ error }))),
        ),
      ),
    ),
  )

  pay(params: MembershipUpgradePlanPayParam, payment: { token: string; formValues: FormGroupValue<PaymentForm> }) {
    const membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
        executionData: params.executionData,
        operation: Operation.UPDATE,
        cacheSettings: getClearCacheSettings(),
        method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
      },
      paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
        executionData: {
          flexMicroFormToken: payment.token,
          billTo: {
            address1: String(params.formValues.billing?.billingTo?.address1),
            address2: String(params.formValues?.billing?.billingTo?.address2),
            administrativeArea: String(params.formValues?.billing?.billingTo?.state),
            buildingNumber: "",
            country: "US",
            district: String(params.formValues?.billing?.billingTo?.state),
            email: String(params.accountDetails?.email || "fallback@avagate.com"),
            firstName: params.formValues?.billing?.billingTo?.firstName || "",
            lastName: params.formValues?.billing?.billingTo?.lastName || "",
            locality: String(params.formValues?.billing?.billingTo?.city),
            phoneNumber: String(params.accountDetails?.phone.cell),
            postalCode: String(params.formValues?.billing?.billingTo?.zipcode),
          },
          amountDetails: {
            totalAmount: String(params.totalCost),
            currency: "USD",
          },
          creditCardBrandedName: payment.formValues.card?.cardName || "",
        },
        method: PaymentCybersourceMethod.OPERATION_EXECUTE,
        operation: Operation.UPDATE,
      },
      payload: OperationExecutePayload = {
        membershipEvent,
        paymentEvent,
        operation: Operation.UPDATE,
      }

    return this.executeService.execute<ConnectSuite.MemberUpgradeResponseObject>(payload).pipe(
      map(({ validateObject, operationObject }) => {
        if (validateObject?.meta.isError) {
          throw new RequestError(RequestErrorType.UpgradedMembership, validateObject)
        }

        const operationError = !!operationObject?.meta?.isError
        if (operationError) {
          checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject)
        }

        const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
          currency: "USD",
          transaction_id: getTransactionId(validateObject),
          value: params.totalCost,
          items: [
            { quantity: 1, price: params.totalCost, item_id: "primary", item_name: AppAnalyticsEvents.AccountUpgrade },
          ],
          context: "ava-store " + AppAnalyticsEvents.AccountUpgrade,
        }
        this.dataLayer.purchaseEvent(analyticsEventParams)

        return validateObject
      }),
    )
  }

  recostValidate(
    formValues: FormGroupValue<MembershipUpgradePlanForm>,
    autoRenew: boolean,
    accountDetails: AccountDetails | null,
  ): Observable<ValidateSucceededResponseObject<ConnectSuite.MemberUpgradeResponseObject>> {
    const payload: MembershipConnectSuiteRecostValidateChangeLevelEventPayload = {
      method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_LEVEL,
      autoRenew: autoRenew,
      memberNumber: accountDetails?.memberNumber || "",
      newLevel: formValues as MembershipConnectSuiteRecostValidateChangeLevelEventPayload["newLevel"],
    }

    return this.executeService.membershipQuery<ConnectSuite.MemberUpgradeResponseObject>(payload).pipe(
      map((validateObject) => {
        const isError = validateObject.meta.isError,
          executionData = validateObject?.response?.executionData || ""

        if (isError) {
          throw new RequestError(RequestErrorType.Error)
        } else {
          return { executionData, response: validateObject }
        }
      }),
    )
  }
}
