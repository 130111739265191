import { Inject, Injectable, TemplateRef } from "@angular/core"
import { GLOBAL_RX_STATE, GlobalState } from "./state"
import { RxState } from "@rx-angular/state"

export interface MemberAlertMessage {
  id: AlertMessage
  message: string | TemplateRef<void> | null
  type: AlertType
}

export enum AlertType {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

export enum AlertGroup {
  ERROR = "ERROR",
  T_MOBILE = "T_MOBILE",
  PROMO_CODE = "PROMO_CODE",
}

export enum AlertMessage {
  ERROR_CRITICAL = "ERROR_CRITICAL",
  ERROR_RETRY = "ERROR_RETRY",

  PROMO_CODE_LOOKUP = "PROMO_CODE_LOOKUP",
  PROMO_CODE_VALID = "PROMO_CODE_VALID",
  PROMO_CODE_INVALID = "PROMO_CODE_INVALID",

  T_MOBILE_FAILS = "T_MOBILE_FAILS",
  T_MOBILE_INVALID = "T_MOBILE_INVALID",
  T_MOBILE_LOOKUP = "T_MOBILE_LOOKUP",
  T_MOBILE_REDEEMED = "T_MOBILE_REDEEMED",
  T_MOBILE_RESERVED = "T_MOBILE_RESERVED",
  T_MOBILE_SUCCESS = "T_MOBILE_SUCCESS",
}

export interface AlertMessageMessage {
  [key: string]: MemberAlertMessage["message"]
}


@Injectable({
  providedIn: "root"
})
export class AlertMessageService {
  message: AlertMessageMessage = {}

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
  ) {
  }

  set(alertMessage: AlertMessage, type: AlertType): void {
    this.globalState.set("memberAlertMessages", () => [{ id: alertMessage, message: this.message[alertMessage], type }])
  }

  clear(alertMessage: AlertMessage): void {
    if (alertMessage) {
      this.globalState.set("memberAlertMessages", oldState => {
        if (oldState.memberAlertMessages.find(alert => alert.id === alertMessage)) {
          oldState.memberAlertMessages.splice(oldState.memberAlertMessages.findIndex(alert => alert.id === alertMessage), 1)
        }
        return oldState.memberAlertMessages
      })
    }
  }

  clearAll(alertGroup?: AlertGroup): void {
    for (const alertMessage of this.enumKeys(AlertMessage)) {
      if (!alertGroup || alertMessage.includes(alertGroup)) {
        this.clear(AlertMessage[alertMessage])
      }
    }
  }

  enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]
  }

}
