<nz-form-item [ngClass]="{'ava-label-hide': !formLabel}">
  <nz-form-label class="ava-form-label" [nzFor]="controlId" [nzNoColon]="true" [nzSpan]="7">
    {{ formLabel || placeholder }}
  </nz-form-label>
  <nz-form-control [nzValidateStatus]="formControlStatus" [nzErrorTip]="errors" nzHasFeedback>
    <nz-date-picker
      [formControl]="formControl"
      [nzId]="controlId"
      [nzPlaceHolder]="placeholder"
      [nzFormat]="dateFormat"
      [nzDisabledDate]="disabledDate"
      nzShowToday="false"
      nzMode="date"
      class="ava-form-control"
    />
    <ng-template #errors let-control>
      <ng-container *ngIf="formControl?.errors">
        <ng-container *ngIf="(formControl.errors | keyvalue)[0] as error">
          <div
            class="ava-form-error"
            [innerHTML]="errorMessages && errorMessages[error.key] ? errorMessages[error.key] : error.key"
          ></div>
        </ng-container>
      </ng-container>
    </ng-template>
  </nz-form-control>
</nz-form-item>
