import { createReducer, on } from "@ngrx/store"
import { MembershipUpdateFormStep, MembershipUpdateState } from "./membership-update.models"
import { MembershipUpdate } from "./membership-update.actions"
import { RequestStatus } from "../../../types/request-status"

export const initialStateMembershipUpdate: MembershipUpdateState = {
  formActiveStep: MembershipUpdateFormStep.UPDATE,
  formValues: {},
  validationRequestStatus: RequestStatus.UNSENT,
  validationError: null,
  executionData: null,
  executionRequestStatus: RequestStatus.UNSENT,
  executionError: null,
  accountDetails: null,
  totalCost: 0,
}

export const membershipUpdateReducer = createReducer(
  initialStateMembershipUpdate,
  on(MembershipUpdate.recostValidate, (state) => ({
    ...state,
    validationRequestStatus: RequestStatus.RUNNING,
    validationError: null,
    executionData: null,
  })),
  on(MembershipUpdate.recostValidateSucceeded, (state, { executionData }) => ({
    ...state,
    executionData,
    validationError: null,
    validationRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipUpdate.recostValidateFailed, (state, { error }) => ({
    ...state,
    validationError: error,
    validationRequestStatus: RequestStatus.FAILED,
    executionData: "",
  })),
  on(MembershipUpdate.pay, (state) => ({
    ...state,
    executionRequestStatus: RequestStatus.RUNNING,
    executionError: null,
  })),
  on(MembershipUpdate.paySucceeded, (state) => ({
    ...state,
    executionError: null,
    executionRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipUpdate.payFailed, (state, { error }) => ({
    ...state,
    executionError: error,
    executionRequestStatus: RequestStatus.FAILED,
  })),
  on(MembershipUpdate.changedMembershipType, (state, { membershipType }) => ({
    ...state,
    formValues: {
      ...state.formValues,
      membershipType,
    },
  })),
  on(MembershipUpdate.setFormActiveStep, (state, { activeStep }) => ({ ...state, formActiveStep: activeStep })),
  on(MembershipUpdate.changedForm, (state, { values }) => ({ ...state, formValues: values })),
  on(MembershipUpdate.setAccountDetails, (state, { accountDetails }) => {
    return {
      ...state,
      accountDetails,
      formValues: {
        ...state.formValues,
        firstName: state.formValues.firstName ? state.formValues.firstName : accountDetails.firstName,
        lastName: state.formValues.lastName ? state.formValues.lastName : accountDetails.lastName,
        accidentMedicalPlan: !!accountDetails.includedPrimaryOffers.primaryOptional.find(
          (p) => p.code.startsWith("MED") || p.code.startsWith("MD"),
        ),
        associates: accountDetails.associates.map((a, index) => {
          const associate = accountDetails.includedAssociateOffers[index]

          return {
            ...(state?.formValues?.associates ? state.formValues.associates[index] : {}),
            firstName: a.firstName,
            lastName: a.lastName,
            membershipNumber: a.membershipNumber,
            accidentMedicalPlan: !!associate.associateOptional?.find(
              (o) => o.code.startsWith("MED") || o.code.startsWith("MD"),
            ),
          }
        }),
      },
    }
  }),
  on(MembershipUpdate.setTotalCost, (state, { totalCost }) => ({ ...state, totalCost })),
  on(MembershipUpdate.removeError, (state) => ({ ...state, validationError: null, executionError: null })),
  on(MembershipUpdate.reset, () => initialStateMembershipUpdate),
)
