import { Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzSpinModule } from "ng-zorro-antd/spin"

@Component({
  standalone: true,
  selector: "ava-spinner",
  template: `
    <div style="display: grid; place-content: center; padding: 50px; gap: 20px">
      <div *ngIf="title" style="text-align: center">{{ title }}</div>
      <div style="text-align: center; color: white">
        <nz-spin nzSize="large" nzSimple></nz-spin>
      </div>
    </div>
  `,
  imports: [NzSpinModule, CommonModule],
})
export class SpinnerComponent {
  @Input() title: string = ""
}
