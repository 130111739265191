import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SmartWeatherComponent } from "./smart-weather.component"
import { LocationFormComponent } from "./components/location-form/location-form.component"
import { ListComponent } from "./components/list/list.component"
import { LoadingComponent } from "./components/loading/loading.component"
import { ErrorComponent } from "./components/error/error.component"
import { ReactiveFormsModule } from "@angular/forms"
import { GoogleMapsModule } from "@angular/google-maps"
import { NzButtonModule } from "ng-zorro-antd/button"
import { NzIconModule } from "ng-zorro-antd/icon"
import { NzInputModule } from "ng-zorro-antd/input"
import { NzFormModule } from "ng-zorro-antd/form"
import { NzRadioModule } from "ng-zorro-antd/radio"
import { NzCheckboxModule } from "ng-zorro-antd/checkbox"
import { NzDividerModule } from "ng-zorro-antd/divider"
import { NzModalModule } from "ng-zorro-antd/modal"
import { NzCardModule } from "ng-zorro-antd/card"

@NgModule({
  exports: [
    SmartWeatherComponent,
  ],
  declarations: [
    SmartWeatherComponent,
    LocationFormComponent,
    ListComponent,
    LoadingComponent,
    ErrorComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
    NzFormModule,
    NzRadioModule,
    NzCheckboxModule,
    NzDividerModule,
    NzModalModule,
    NzCardModule,
  ],
  providers: []
})
export class SmartWeatherModule {
}
