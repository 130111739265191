import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { forkJoin, map, of, switchMap, withLatestFrom } from "rxjs"
import { Store } from "@ngrx/store"
import { PriceOffersActions } from "./price-offers.actions"
import { M } from "../m.type"
import {
  MembershipMMethod,
  MembershipMPricePreviewsEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipM"
import { ExecuteService } from "../services/execute.service"
import { ClubApp } from "@aaa/emember/types"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import { catchError } from "rxjs/operators"
import { RequestError, RequestErrorType } from "../generic-errors"
import { getClubId } from "@aaa/emember/store-membership"
import { checkPriceOfferErrorsMSystem } from "../check-price-offer-errors-m-system"
import { getMembershipLevels, getMembershipOffering } from "./helpers/parse-price-offer-m-system"
import { getSaveCacheSettings } from "../utils/get-cache-settings"

@Injectable({ providedIn: "root" })
export class PriceOffersMSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Northampton, ClubApp.Shelby]))
  executeService = inject(ExecuteService)

  loadPriceOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceOffersActions.load),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getClubId)))),
      switchMap(([{ query }, clubId]) => {
        const hasCouponCode = !!query.couponCode
        const hasPromoCode = !!query.promoCode
        const requestPayload: MembershipMPricePreviewsEventPayload = {
          cacheSettings: getSaveCacheSettings(),
          method: MembershipMMethod.PRICE_PREVIEWS,
          promoCode: query.couponCode || query.promoCode,
          zip: query.zipCode || "",
        }
        const priceWithoutDiscountPayload: MembershipMPricePreviewsEventPayload = {
          cacheSettings: getSaveCacheSettings(),
          method: MembershipMMethod.PRICE_PREVIEWS,
          promoCode: "",
          zip: query.zipCode || "",
        }

        return forkJoin({
          discount: this.executeService.membershipQuery<M.PricePreviewsResponseObject>(requestPayload),
          withoutDiscount:
            this.executeService.membershipQuery<M.PricePreviewsResponseObject>(priceWithoutDiscountPayload),
        }).pipe(
          map(({ discount, withoutDiscount }) => {
            const hasError = !!discount.error
            let error: RequestError | null = null

            if (discount.error && withoutDiscount.error) {
              return PriceOffersActions.loadFailed({
                error: new RequestError(RequestErrorType.PricePreviewError, withoutDiscount),
              })
            }

            if (hasError) {
              error = checkPriceOfferErrorsMSystem(discount.error, discount, hasCouponCode, hasPromoCode)
            }

            if (error) {
              const resultWithoutDiscountMembershipOffering =
                withoutDiscount.response.pricePreviewsResponse.Result.MembershipOffering
              const levelOffers = getMembershipOffering(resultWithoutDiscountMembershipOffering),
                levels = getMembershipLevels(resultWithoutDiscountMembershipOffering, clubId)

              return PriceOffersActions.loadFailed({ error, levelOffers, levels })
            } else {
              const resultWithoutDiscountMembershipOffering =
                withoutDiscount.response.pricePreviewsResponse.Result.MembershipOffering
              const resultDiscountMembershipOffering = discount.response.pricePreviewsResponse.Result.MembershipOffering
              const levelOffers = getMembershipOffering(resultDiscountMembershipOffering),
                levels = getMembershipLevels(resultDiscountMembershipOffering, clubId),
                rawLevelOffers = getMembershipOffering(resultWithoutDiscountMembershipOffering)

              return PriceOffersActions.loadSucceeded({ levelOffers, rawLevelOffers, levels })
            }
          }),
          catchError((error) =>
            of(PriceOffersActions.loadFailed({ error: new RequestError(RequestErrorType.PricePreviewError, error) })),
          ),
        )
      }),
    ),
  )
}
