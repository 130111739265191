import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { MembershipAssociateAddStep, MembershipAssociateAddForm } from "./membership-associate-add.models"
import { AccountDetails, ValidateSucceededResponseObject } from "../types/types"
import { changedForm, setFormActiveStep } from "../../modules/share/ngrx-helpers"
import { RequestError } from "../generic-errors"
import { FormGroupValue } from "../../modules/share/form.utils"

export const MembershipAssociateAddActions = createActionGroup({
  source: "Membership Associate Add",
  events: {
    // Form action reuse
    ...setFormActiveStep<MembershipAssociateAddStep>(),
    ...changedForm<MembershipAssociateAddForm>(),
    "Validate Form Step": props<{
      validateStep: MembershipAssociateAddStep
      formValues: FormGroupValue<MembershipAssociateAddForm>
    }>(),
    // Form actions
    "Validate Associate": emptyProps(),
    "Validate Associate Succeeded": emptyProps(),
    "Validate Associate Failed": emptyProps(),
    "Validate Billing": emptyProps(),
    "Validate Billing Succeeded": emptyProps(),
    "Validate Billing Failed": emptyProps(),
    "Validate Promo Code": emptyProps(),
    "Recost Validate": emptyProps(),
    "Recost Validate Succeeded": props<ValidateSucceededResponseObject>(),
    "Recost Validate Failed": props<{ error: RequestError }>(),
    "Set Account Details": props<{ accountDetails: AccountDetails }>(),
    Pay: props<{ formValues: FormGroupValue<MembershipAssociateAddForm> }>(),
    "Pay Succeeded": props<{ accountDetails?: AccountDetails }>(),
    "Pay Failed": props<{ error: RequestError }>(),
    Reset: emptyProps(),
    "Remove Error": emptyProps(),
  },
})
