export function interfaceJoinRenewPaymentPaymentCybersource(): string {
  return "interface-join-renew-payment-payment-cybersource"
}

import { EventPayload, Operation, OperationEventResponse } from "@aaa/interface-joinRenew-joinRenewLib"

/**
 * Payment Subsystem Events (Cybersource)
 */

export enum PaymentCybersourceMethod {
  CAPTURE_CONTEXT = "CAPTURE_CONTEXT",

  OPERATION_EXECUTE = "OPERATION_EXECUTE"
  // SUBMIT_PAYMENT = 'SUBMIT_PAYMENT'
}

export interface PaymentCybersourceEventPayload extends EventPayload {
  method: PaymentCybersourceMethod;
}

export interface PaymentCybersourceCaptureContextEventPayload extends PaymentCybersourceEventPayload {
  method: PaymentCybersourceMethod.CAPTURE_CONTEXT;
}

export interface PaymentCybersourceOperationExecuteEventPayload extends PaymentCybersourceEventPayload {
  method: PaymentCybersourceMethod.OPERATION_EXECUTE;
  operation: Operation | null;

  executionData: {
    flexMicroFormToken: string;
    creditCardBrandedName: string;
    amountDetails: {
      totalAmount: string; //'102.21'
      currency: "USD"; //'USD' - hard code this?
    };
    billTo: {
      country: string; //'US'
      firstName: string; //'John';
      lastName: string; //'Deo';
      phoneNumber: string; //'4158880000';
      address1: string; //'test';
      postalCode: string; //'94105';
      locality: string; //'San Francisco';
      administrativeArea: string; //'MI';
      email: string; //'test@cybs.com';
      address2: string; //'Address 2';
      district: string; //'MI';
      buildingNumber: string; //'123';
    };
  };

  responseType?: OperationEventResponse;
}
