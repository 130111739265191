import { AfterViewInit, Component, EventEmitter, forwardRef, inject, Input, OnDestroy, Output } from "@angular/core"
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { NzFormModule } from "ng-zorro-antd/form"
import { NzInputModule } from "ng-zorro-antd/input"
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete"
import { NzDatePickerModule } from "ng-zorro-antd/date-picker"
import { en_US, NzI18nService } from "ng-zorro-antd/i18n"
import { differenceInCalendarDays } from "date-fns"
import { FormBaseControlComponent } from "../base"
import { delay, Subject, takeUntil } from "rxjs"
import { distinctUntilChanged } from "rxjs/operators"

@Component({
  selector: "ava-form-date",
  templateUrl: "./date.html",
  styleUrls: ["./date.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDateComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, NzFormModule, NzInputModule, ReactiveFormsModule, NzAutocompleteModule, NzDatePickerModule],
  standalone: true,
})
export class FormDateComponent extends FormBaseControlComponent implements AfterViewInit, OnDestroy {
  i18n = inject(NzI18nService)
  @Input() dateFormat = "MM/dd/yyyy"
  @Output() dateChange = new EventEmitter<string>()
  today = new Date()
  alive$ = new Subject()
  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0
  constructor() {
    super()
    this.i18n.setLocale(en_US)
  }

  ngAfterViewInit() {
    this.formControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.alive$), delay(0))
      .subscribe((value) => this.dateChange.emit(value))
  }

  ngOnDestroy() {
    this.alive$.next(null)
    this.alive$.complete()
  }
}
