import { Inject, Injectable } from "@angular/core"
import { VideoStyles } from "../../fields/video/service"
import { ThemeService, ThemeType } from "../../options/theme/service"
import { QuillEditorStyles } from "../../fields/quill-editor/service"
import { Alignment, AlignmentType } from "../../options/alignment/service"
import { Image, ImageStyles } from "../../fields/image/service"
import { StorageService } from "../../services/storage"
import { JavascriptStyles } from "../../fields/javascript/service"
import { ColorService, ColorType } from "../../services/color"
import { BoxModel, BoxModelService } from "../../options/box-model/service"
import { Breakpoints, BreakpointWidth, ContentStyles, Style } from "../../services/style"
import { GLOBAL_RX_STATE, GlobalState, StateService } from "../../services/state"
import { RxState } from "@rx-angular/state"
import { Block, Options } from "./block"
import { TemplateType } from "../../options/template/service"

export interface BlockStyles {
  block: {
    backgroundColor: string | undefined
    cursor: "pointer" | undefined
    position: "relative" | "absolute"
    width: {
      px: number
    }
    justifyContent: Alignment["justifyContent"] | undefined
    marginBottom: {
      px: number
    }
    marginTop: {
      px: number
    }
    paddingBottom: {
      px: number
    }
    paddingTop: {
      px: number
    }
  }
}

/*
export interface ColumnStyles {
  column1: {
    width: {
      px: number
    }
  }
  column2: {
    width: {
      px: number
    }
  }
}
*/

export interface Styles {
  block: (block: Block, width: number, options: Options) => BlockStyles,
  content: (width: number) => ContentStyles,
  javascript: (block: Block, width: number) => JavascriptStyles,
  image: (block: Block, width: number) => ImageStyles | undefined,
  textArea: (block: Block, width: number, backgroundUrl: boolean) => QuillEditorStyles,
  video: (block: Block, width: number) => VideoStyles | undefined,
}

@Injectable({
  providedIn: "root"
})
export class MultiblockStyle {

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalRxState: RxState<GlobalState>,
    private boxModelService: BoxModelService,
    private colorService: ColorService,
    private themeService: ThemeService,
    private stateService: StateService,
    private storageService: StorageService,
    private styleService: Style,
  ) {
  }

  get bp(): Breakpoints {
    return this.styleService.breakpoints
  }

  get styles(): Styles {
    return {
      block: (block: Block, width: number, options: Options) => this.blockStyles(block, width, options),
      content: (width: number) => this.contentStyles(width),
      textArea: (block: Block, width: number, backgroundUrl: boolean) => this.textAreaStyles(block, width, backgroundUrl),
      javascript: (block: Block, width: number) => this.javascriptStyles(block, width),
      image: (block: Block, width: number) => this.imageStyles(block, width),
      video: (block: Block, width: number) => this.videoStyles(block, width),
    }
  }

  contentStyles = (width: number): ContentStyles => {
    return this.styleService.contentStyles(width)
  }

  blockStyles = (block: Block, width: number, options: Options): BlockStyles => {
    console.log(block.template)
    let backgroundColor: string | undefined = ""
    if (block.template.type !== TemplateType.JOIN_BANNER && width < this.bp.medium) {
      backgroundColor = this.colorService.color(ColorType.C_41)?.rgba
    }
    return {
      block: {
        backgroundColor: backgroundColor,
        cursor: undefined,
        position: width >= this.bp.medium ? "absolute" : "relative",
        width: {
          px: width
        },
        justifyContent: undefined,
        marginBottom: {
          px: this.margin(block)?.bottom.px
        },
        marginTop: {
          px: this.margin(block)?.top.px
        },
        paddingBottom: {
          px: this.padding(block)?.bottom.px
        },
        paddingTop: {
          px: this.padding(block)?.top.px
        },
      },
    }
  }

/*
  columnStyles = (block: Block, width: number, options: Options): BlockStyles => {
    return {
        backgroundColor: width >= this.bp.medium ? undefined : this.colorService.types["41"],
        cursor: undefined,
        position: width >= this.bp.medium ? "absolute" : "relative",
        width: {
          px: width
        },
        justifyContent: undefined,
    }
  }
*/

  textAreaStyles = (block: Block, width: number, backgroundUrl: boolean): QuillEditorStyles => {
    let color: string | undefined = undefined
    if (block?.options.theme === ThemeType.OPT_03 && (block.template.type !== TemplateType.JOIN_BANNER || width >= BreakpointWidth.LARGE)) {
      color = "white"
    }
    return {
      color: color,
      fontSize: {
        px: 17
      },
      fontWeight: 200,
      textAlign: block?.options.alignment === AlignmentType.CENTER || (backgroundUrl && width <= 900) ? "center" : "left"
    }
  }

  javascriptStyles = (block: Block, width: number): JavascriptStyles => {
    return {
      backgroundColor: undefined
    }
  }

  imageStyles = (block: Block, width: number): ImageStyles | undefined => {
    return
  }

  videoStyles = (block: Block, width: number): VideoStyles | undefined => {
    return
  }

  backgroundImage = (images: Image[], width: number): string | undefined => {
    const urls = images.map(image => image.filePath ? this.getFileUrl(image.filePath) : "")
    let url = urls[2] || urls[0]
    if (width >= this.bp.small) {
      url = urls[1] || urls[0]
    }
    if (width >= this.bp.medium) {
      url = urls[0]
    }
    return urls[0] ? "url(" + url + ")" : undefined
  }

  /**
   * Helpers
   */

  getFileUrl(path: string): string {
    return this.storageService.getFileUrl(path)
  }

  margin(block: Block): BoxModel["margin"] {
    return block?.options.boxModel.margin
  }

  padding(block: Block): BoxModel["padding"] {
    return block?.options.boxModel.padding
  }

/*
  columnWidth(index: number): { px: number } {
    for (let i = 0; i < 8; i++) {
      console.log((i + 1).toString(10))
    }

    return { px: 0 }
  }
*/

}
