import { Injectable } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { FieldType } from "../../services/field"
import { FormService } from "../../services/form"
import { FieldBase } from "../../block/services/block"
import { TargetType } from "../../services/link"
import { ColorType } from "../../services/color"

export interface Button extends FieldBase {
  color: ColorType | null
  targetOption: TargetType
  targetPath: string
  value: string
}

export interface ButtonOption {
  color: ColorType[]
  targetTypes: TargetType[]
}

export interface ButtonOptions {
  options: ButtonOption
  label: {
    placeholder: string
    plural: string
    singular: string
  }
}

export interface ButtonStyle {
  backgroundColorClass?: string
  borderBottom?: string
  borderRadius?: { px: number }
  fontWeight?: { value: number }
  fontSize?: { px: number }
  fontColor?: { hex: string, rgba: string }
  size: "small" | "default" | "large"
  type: "primary" | "default" | "link" | "dashed" | "text"
}

@Injectable({
  providedIn: "root"
})
export class ButtonService {
  buttonStyle: ButtonStyle = {
    size: "default",
    type: "primary"
  }

  constructor(
    private formService: FormService,
  ) {
  }

  get newItem(): Button {
    return {
      color: null,
      targetPath: "",
      targetOption: TargetType.SELF,
      fieldType: FieldType.BUTTON,
      value: "",
    }
  }

  newForm = (item: Button): AbstractControl | undefined => {
    return this.formService?.newForm(item)
  }

  get options(): ButtonOptions {
    return {
      options: {
        color: [
          ColorType.C_41,
          ColorType.C_30
        ],
        targetTypes: [
          TargetType.SELF,
          TargetType.NEW,
          TargetType.MODAL
        ],
      },
      label: {
        placeholder: "text",
        plural: "Buttons",
        singular: "Button"
      },
    }
  }

}
