<div *ngIf="clubOption$ | async as clubOption" class="ava-flex ava-column ava-gap-2">
  <div class="ava-flex ava-row">
    <p class="ava-header-bold ava-test-color--primary">
      Welcome to AAA {{ clubOption.name }} {{ clubOption.clubId !== ClubApp.Northway ? "County" : "" }}
    </p>
    <img
      width="40%"
      src="https://www.hoosier.aaa.com/sites/all/themes/aaav3/images/aaa-logo.png"
      alt="Welcome to AAA {{ clubOption.name }} {{ clubOption.clubId !== ClubApp.Northway ? 'County' : '' }}"
    />
  </div>

  <p class="ava-flex ava-row ava-text-bold">To enjoy customized local content, please enter your ZIP Code below.</p>

  <form [formGroup]="formGroup">
    <ng-container *ngIf="errorMessages$ | async as errorMessages">
      <div class="ava-flex ava-row ava-gap-2">
        <ava-form-input
          [errorMessages]="errorMessages.zipcode"
          beforeLabel="Zip Code"
          placeholder="zipcode"
          formControlName="zipcode"
          class="ava-flex"
        />
        <ava-button
          type="primary"
          [isLoading]="isLoading$ | async"
          [disabled]="formGroup.invalid"
          (clicked)="submitForm()"
        >
          Go
        </ava-button>
      </div>
      <ng-container *ngIf="failed$ | async">
        <div class="zipcode-control__error" [innerHTML]="errorMessages.zipcode.zipcodeIsNotInsideClub"></div>
      </ng-container>
    </ng-container>
  </form>

  <ava-divider />

  <p [innerHTML]="zipcodeFormDescription$ | async" class="ava-text"></p>
</div>
