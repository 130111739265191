import { AccountStatus } from "@aaa/emember/types"
import {
  AccountAddress,
  AccountDetails,
  AccountIncludedAssociateOffers,
  AccountDetailsPaymentSummary,
  AccountIncludedPrimaryOffers,
  AccountPhone,
  MembershipAssociateDetails,
  MembershipCode,
  SessionDocResponseSchema,
} from "./types/types"
import {
  Membership,
  MembershipMPricesSchema,
  MembershipOffering,
  MembershipOfferingSchema,
  MembershipSchema,
  MMembershipCode,
  MMembershipType,
  MResponseSchema,
} from "@aaa/interface-joinRenew-membership-membershipM"
import { makeOperationEventResponse } from "@aaa/interface-joinRenew-joinRenewLib"
import { LastNameSuffix } from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import { parse } from "date-fns"
import { z } from "zod"
import { Cybersource } from "./cybersource.type"
import { RequestError, RequestErrorType } from "./generic-errors"
import { first } from "./utils/first"
import { MembershipOffer, MembershipOfferItem } from "./price-offers/helpers/types"
import { convertMembershipOfferComponents } from "./price-offers/helpers/m-membership"

export namespace M {
  export enum MMembershipLevel {
    BASIC = "BASIC",
    PLUS = "PLUS",
    PREMIER = "PREMIER",
  }

  export type MMembershipLabel = "Basic" | "Plus" | "Plus RV" | "Premier" | "Premier RV"
  export const membershipCodes: MembershipCode<MMembershipLevel, MMembershipType, MMembershipCode, MMembershipLabel>[] =
    [
      {
        label: "Basic",
        level: MMembershipLevel.BASIC,
        rv: false,
        membershipType: MMembershipType.Basic,
        duesComponentCode: "BP",
      },
      {
        label: "Plus",
        level: MMembershipLevel.PLUS,
        rv: false,
        membershipType: MMembershipType.Plus,
        duesComponentCode: "PP",
      },
      {
        label: "Plus RV",
        level: MMembershipLevel.PLUS,
        rv: true,
        membershipType: MMembershipType.PlusRv,
        duesComponentCode: "RP",
      },
      {
        label: "Premier",
        level: MMembershipLevel.PREMIER,
        rv: false,
        membershipType: MMembershipType.Premier,
        duesComponentCode: "EP",
      },
      {
        label: "Premier RV",
        level: MMembershipLevel.PREMIER,
        rv: true,
        membershipType: MMembershipType.PremierRv,
        duesComponentCode: "VP",
      },
    ]

  export const MembershipErrorSchema = z
    .object({
      data: z
        .object({
          Result: z.object({ $: MResponseSchema }),
        })
        .optional(),
      authError: z.boolean().optional(),
    })
    .passthrough()
    .optional()
    .nullable()
  export type MembershipError = z.infer<typeof MembershipErrorSchema>

  export const PricePreviewsResponseObjectSchema = z
    .object({
      error: MembershipErrorSchema,
      response: z.object({
        pricePreviewsResponse: z.object({
          Result: z.object({
            MembershipOffering: z.array(MembershipOfferingSchema),
          }),
        }),
        prices: z.array(MembershipMPricesSchema),
      }),
    })
    .extend(SessionDocResponseSchema.shape)
    .passthrough()
  export type PricePreviewsResponseObject = z.infer<typeof PricePreviewsResponseObjectSchema>

  export const GetOfferingsResponseResponseObjectSchema = z
    .object({
      error: MembershipErrorSchema,
      response: z.object({
        offeringsResponse: z.object({
          Result: z.object({
            MembershipOffering: z.array(MembershipOfferingSchema),
          }),
        }),
        executionData: z.string(),
      }),
    })
    .extend(SessionDocResponseSchema.shape)
    .passthrough()
  export type GetOfferingsResponseResponseObject = z.infer<typeof GetOfferingsResponseResponseObjectSchema>

  export const RecostValidateResponseObjectSchema = z
    .object({
      error: MembershipErrorSchema,
      response: z.object({
        validateResponse: z.object({
          Result: z.object({
            $: MResponseSchema,
            Membership: z.array(MembershipSchema),
            MembershipOffering: z.array(MembershipOfferingSchema),
          }),
        }),
      }),
    })
    .extend(SessionDocResponseSchema.shape)
    .passthrough()
  export type RecostValidateResponseObject = z.infer<typeof RecostValidateResponseObjectSchema>

  export const ValidateResponseObjectSchema = z
    .object({
      error: MembershipErrorSchema,
      response: z.object({
        validateResponse: z.object({
          Result: z.object({
            $: MResponseSchema,
            Membership: z.array(MembershipSchema),
            MembershipOffering: z.array(MembershipOfferingSchema),
          }),
        }),
      }),
    })
    .extend(SessionDocResponseSchema.shape)
    .passthrough()
  export type ValidateResponseObject = z.infer<typeof ValidateResponseObjectSchema>

  export const RecostValidateComposeResponseObjectSchema = z
    .object({
      membershipOffering: z.array(MembershipOfferingSchema),
      validationMembership: MembershipSchema,
    })
    .passthrough()
  export type RecostValidateComposeResponseObject = z.infer<typeof RecostValidateComposeResponseObjectSchema>

  export const MemberInfoResponseObjectSchema = z
    .object({
      error: MembershipErrorSchema,
      response: z.object({
        executionData: z.string(),
        memberInfoResponse: z.object({
          Result: z.object({
            Membership: z.array(MembershipSchema),
            MembershipOffering: z.array(MembershipOfferingSchema),
            $: MResponseSchema,
          }),
        }),
      }),
    })
    .extend(SessionDocResponseSchema.shape)
    .passthrough()
  export type MemberInfoResponseObject = z.infer<typeof MemberInfoResponseObjectSchema>

  export const RenewExecutePaymentResponseObjectSchema = z
    .object({
      error: MembershipErrorSchema,
      response: makeOperationEventResponse(z.any()),
    })
    .extend(SessionDocResponseSchema.shape)
    .passthrough()
  export type RenewExecutePaymentResponseObject = z.infer<typeof RenewExecutePaymentResponseObjectSchema>

  export const MemberInfoAndOfferingsResponseObjectSchema = z
    .object({
      error: MembershipErrorSchema,
      response: z.object({
        executionData: z.string(),
        memberInfoResponse: z.object({
          Result: z.object({
            MembershipOffering: z.array(MembershipOfferingSchema),
            Membership: z.array(MembershipSchema),
            $: MResponseSchema,
          }),
        }),
      }),
    })
    .extend(SessionDocResponseSchema.shape)
    .passthrough()
  export type MemberInfoAndOfferingsResponseObject = z.infer<typeof MemberInfoAndOfferingsResponseObjectSchema>

  export const MemberInfoUpdateResponseObjectSchema = z
    .object({
      error: MembershipErrorSchema,
      response: makeOperationEventResponse(
        z.object({
          Result: z.object({
            $: MResponseSchema,
            Membership: z.array(MembershipSchema),
          }),
        }),
      ),
    })
    .extend(SessionDocResponseSchema.shape)
    .passthrough()
  export type MemberInfoUpdateResponseObject = z.infer<typeof MemberInfoUpdateResponseObjectSchema>

  export const ExecuteResponseObjectSchema = z
    .object({
      error: MembershipErrorSchema,
      response: makeOperationEventResponse(
        z.object({
          Result: z.object({
            $: MResponseSchema,
            Membership: z.array(MembershipSchema),
          }),
        }),
      ),
    })
    .extend(SessionDocResponseSchema.shape)
    .passthrough()
  export type ExecuteResponseObject = z.infer<typeof ExecuteResponseObjectSchema>

  export const ExecutePaymentResponseObjectSchema = z
    .object({
      error: Cybersource.CyberSourcePaymentErrorSchema,
      response: makeOperationEventResponse(z.any()),
    })
    .extend(SessionDocResponseSchema.shape)
    .passthrough()
  export type ExecutePaymentResponseObject = z.infer<typeof ExecutePaymentResponseObjectSchema>

  export class AccountInfo implements AccountDetails {
    includedPrimaryOffers: AccountIncludedPrimaryOffers
    includedAssociateOffers: AccountIncludedAssociateOffers[]
    address: AccountAddress
    associateCount: number | null
    associates: MembershipAssociateDetails[]
    autoRenew: boolean | null
    balance: number | null
    cardNumber: string
    code: MembershipCode | null
    email: string
    expireDate?: string | null
    firstName: string
    lastName: string
    memberNumber: string
    middleInitial: string
    nameSuffix: string
    phone: AccountPhone
    status: AccountStatus
    paymentSummary: AccountDetailsPaymentSummary[]
    availableOffers: MembershipOfferItem[]

    constructor(memberInfo: Membership, membershipOffering: MembershipOffering[] = []) {
      try {
        const address = first(memberInfo.Address)
        const primaryMember = first(memberInfo.PrimaryMember)
        const duesTotal = first(memberInfo?.DuesTotal)
        const associateMembers = memberInfo.AssociateMember
        const membershipCode = membershipCodes.find((code) => code.membershipType === primaryMember?.$.membershipType)
        const expireDate = memberInfo?.$?.expDate
          ? parse(memberInfo.$.expDate, "yyyyMMdd", new Date()).toISOString()
          : null

        this.address = {
          city: address?.$.cityName || "",
          state: address?.$.stateProv || "",
          street1: address?.$.address1 || "",
          street2: address?.$.address2 || "",
          zip: String(address?.$?.postalCode || "")
            .split("")
            .slice(0, 5)
            .join(""),
        }
        this.includedPrimaryOffers = this.getIncludedPrimaryOffers(memberInfo)
        this.associateCount = associateMembers?.length || 0
        this.associates = (associateMembers || []).map((associate) => {
          return {
            firstName: associate?.$.firstName || "",
            lastName: associate?.$.lastName || "",
            email: associate?.$?.email?.split(" ").join("+") || "",
            dob: associate?.$.dob || "",
            membershipNumber: associate.$.membershipNumber,
            nameSuffix: associate.$.nameSuffix as LastNameSuffix,
            middleIntial: associate.$.middleInitial,
            removeAssociate: false,
          }
        })
        this.includedAssociateOffers = this.getIncludedAssociateOffers(memberInfo)
        this.autoRenew = memberInfo?.$?.hasAutoRenew === "Y"
        this.balance = Number(duesTotal?.$?.duesAmount || 0)
        this.cardNumber = ""
        this.code = membershipCode || null
        this.email = primaryMember?.$?.email.split(" ").join("+") || ""
        // Todo:
        this.expireDate = expireDate
        this.firstName = primaryMember?.$.firstName || ""
        this.lastName = primaryMember?.$.lastName || ""
        this.memberNumber = primaryMember?.$.membershipNumber || ""
        this.middleInitial = primaryMember?.$.middleInitial || ""
        this.nameSuffix = primaryMember?.$.nameSuffix || ""
        this.phone = {
          business: primaryMember?.$.businessPhone || "",
          home: primaryMember?.$.homePhone || "",
          cell: primaryMember?.$.cellPhone || "",
        }

        switch (memberInfo.$.renewalStatus) {
          case "D":
            this.status = AccountStatus.PENDING
            break
          case "A":
          case "C":
            this.status = AccountStatus.ACTIVE
            break
          case "S":
            this.status = AccountStatus.CANCELLED
            break
          default:
            this.status = AccountStatus.GUEST
            break
        }

        this.paymentSummary = this.getPaymentSummary(memberInfo, membershipOffering)
        this.availableOffers = this.getAvailableOffers(memberInfo, membershipOffering) //convertMembershipOfferComponents(membershipOffering)
      } catch (error) {
        console.log(error)
        throw { error: new RequestError(RequestErrorType.AccountInfoInit, error) }
      }
    }

    getIncludedAssociateOffers(memberInfo: Membership) {
      const associateMembers = memberInfo.AssociateMember
      return (associateMembers || []).map((a) => {
        const associate: MembershipOffer[] = [
          {
            offering: "associate",
            code: a.DuesComponent[0].$.componentCode,
            amount: Number(a.DuesComponent[0].$.componentAmount),
            description: a.DuesComponent[0].$.componentDescription,
            selectedByDefault: true,
          },
        ]
        const associateOptional: MembershipOffer[] = [...a.DuesComponent].splice(1).map((d) => {
          return {
            offering: "associateOptional",
            amount: Number(d.$.componentAmount),
            code: d.$.componentCode,
            description: d.$.componentDescription,
            selectedByDefault: true,
          }
        })

        return { associate, associateOptional }
      })
    }

    getIncludedPrimaryOffers(memberInfo: Membership): AccountIncludedPrimaryOffers {
      const primary = memberInfo.PrimaryMember[0]
      const optionals = [...memberInfo.PrimaryMember[0].DuesComponent].slice(1)

      return {
        primary: {
          offering: "primary",
          selectedByDefault: true,
          code: primary.DuesComponent[0].$.componentCode,
          amount: Number(primary.DuesComponent[0].$.componentAmount),
          description: primary.DuesComponent[0].$.componentDescription,
        },
        primaryOptional: optionals.map<AccountIncludedPrimaryOffers["primary"]>((o) => ({
          offering: "optionalPrimary",
          selectedByDefault: true,
          amount: Number(o.$.componentAmount),
          description: o.$.componentDescription,
          code: o.$.componentCode,
        })),
      }
    }

    getPaymentSummary(memberInfo: Membership, membershipOffering: MembershipOffering[]) {
      const membershipTypePrimary = memberInfo.PrimaryMember[0].$.membershipType
      const offering = membershipOffering.find((m) => m.$.membershipType === membershipTypePrimary)
      const associateAmount = Number(offering?.AssociateOffering[0].DuesComponent[0].$.componentAmount)
      const primaryAmount = Number(offering?.DuesComponent[0].$.componentAmount)

      return [
        {
          label: `AAA ${this.code?.label} Membership`,
          value: primaryAmount,
        },
        ...this.associates.map(() => {
          return {
            label: `${this.code?.label} Associate Membership`,
            value: associateAmount,
          }
        }),
      ]
    }

    getAvailableOffers(memberInfo: Membership, membershipOffering: MembershipOffering[]) {
      const membershipTypePrimary = memberInfo.PrimaryMember[0].$.membershipType
      const offering = membershipOffering.find((m) => m.$.membershipType === membershipTypePrimary)

      return convertMembershipOfferComponents(offering)
    }
  }
}
