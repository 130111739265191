<div *ngIf="showTools">

  <button
    nz-button nzType="primary"
    (click)="showAccordion = !showAccordion"
  >
    Tools
  </button>

  <div *ngIf="showAccordion" style="margin: 10px">
    <nz-collapse>

      <nz-collapse-panel
        *ngIf="tools.info.enabled"
        nzHeader="Info"
      >
        Project ID:
        {{ tools.info.projectId }}
        <br/>
        Endpoint URL:
        {{ tools.info.endpointUrl }}
      </nz-collapse-panel>

      <nz-collapse-panel
        *ngIf="tools.reports.enabled"
        nzHeader="Reports"
      >
        <div [style]="'margin-bottom: 10px'">
          <nz-range-picker
            [(ngModel)]="date"
            (ngModelChange)="datePickerRange($event)"
            [nzDisabledDate]="datePickerDisabledDates"
          ></nz-range-picker>
        </div>
        <button
          nz-button [nzType]="'primary'"
          (click)="reportsDownload()"
          [disabled]="!tools.reports.range.start || !tools.reports.range.end"
        >
          Download Report
        </button>
      </nz-collapse-panel>

      <nz-collapse-panel
        *ngIf="tools.connection.enabled"
        nzHeader="Azure Connection"
      >
        <button
          nz-button [nzType]="'primary'"
          (click)="connectionAgentsRefresh()"
          [disabled]="tools.connection.loading"
        >
          Refresh Agents
        </button>
        <pre>
          {{ tools.connection.appId | json }}
        </pre>
        <pre>
          {{ tools.connection.results | json }}
        </pre>
      </nz-collapse-panel>

      <nz-collapse-panel
        *ngIf="tools.hours.enabled"
        nzHeader="Agent Hours"
      >
        <button
          nz-button nzType="primary"
          (click)="hoursGetAgentHours()"
          [disabled]="tools.hours.loading"
        >
          Get Agent Work Hours
        </button>
        <pre>
          {{ tools.hours.results | json }}
        </pre>
      </nz-collapse-panel>

      <nz-collapse-panel
        *ngIf="tools.fnConfig.enabled"
        nzHeader="Function Configuration"
      >
        <pre>
          {{ tools.fnConfig.doc }}
        </pre>
      </nz-collapse-panel>

    </nz-collapse>

  </div>
</div>
