import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { MembershipOfferPage } from "./membership-offers.models"

export const MembershipOffersActions = createActionGroup({
  source: "Membership Offers",
  events: {
    "Set Page": props<{ page: MembershipOfferPage }>(),
    "Increase Member Quantity": emptyProps(),
    "Decrease Member Quantity": emptyProps(),
  },
})
