import { getHash, normalizeQueryParam, normalizeSearchParamValue } from "./utils"
import { ExtraOptions } from "../types"

export class ToExternalUrl {
  hash = ""
  queryParams = ""
  appQueryParam = ""

  constructor(internalUrl: string, externalUrl: string, { routeQueryName, stickyParams }: ExtraOptions) {
    const [pathname] = internalUrl.split("?")
    const querySearch = new URLSearchParams(normalizeQueryParam(internalUrl))
    const queryExternalSearch = new URLSearchParams(normalizeQueryParam(externalUrl))

    stickyParams?.forEach((paramKey) => {
      const paramValue = queryExternalSearch.get(paramKey)
      if (paramValue) {
        querySearch.set(paramKey, paramValue)
      }
    })

    querySearch.delete(routeQueryName)

    this.hash = getHash(internalUrl)
    this.queryParams = decodeURIComponent(querySearch.toString())
    this.appQueryParam =
      normalizeSearchParamValue(pathname) !== "/" ? `${routeQueryName}=${normalizeSearchParamValue(pathname)}` : ""
  }

  getUrl() {
    const queries = []

    if (this.appQueryParam) {
      queries.push(this.appQueryParam)
    }

    if (this.queryParams.length) {
      queries.push(this.queryParams)
    }

    return `${queries.length ? "?" + queries.join("&") : ""}${this.hash}`
  }
}
