import { Component, Input } from "@angular/core"
import { AccountEmailSyncComponent } from "../modules/account-email-sync/account-email-sync.component"

@Component({
  selector: "ava-account-email-sync-custom-element",
  template: `
    <ava-account-email-sync [params]="params"></ava-account-email-sync>
  `,
  standalone: true,
  imports: [
    AccountEmailSyncComponent,
  ],
})
export class AccountEmailSyncCustomElementComponent {
  @Input() params: string | undefined
}
