import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnInit, Renderer2, ViewChild,
} from "@angular/core"
import { DOCUMENT } from "@angular/common"
import { WINDOW, LOCATION } from "@ng-web-apis/common"
import { combineLatestWith, debounceTime, Observable } from "rxjs"
import { Javascript, JavascriptOptions, JavascriptStyles } from "./service"
import { TokenService } from "../../services/token"
import { DataReadService } from "../../services/data-read"
import { GLOBAL_RX_STATE, GlobalState, StateService } from "../../services/state"
import { Block } from "../../block/services/block"
import { DomSanitizer, SafeHtml, SafeScript } from "@angular/platform-browser"
import { RxState } from "@rx-angular/state"
import { FormGroup } from "@angular/forms"
import { BlockState } from "../../block/services/state"
import { tap } from "rxjs/operators"
import { RxEffects } from "@rx-angular/state/effects"

interface ComponentState {
  changes: Javascript
  fieldName: string
  source: Javascript
  tokenizedValue: string
}

interface UnsafeHtml extends SafeHtml {
  changingThisBreaksApplicationSecurity: string
}

@Component({
  selector: "ava-javascript-view",
  templateUrl: "./view.html"
})
export class JavascriptViewComponent implements OnInit, AfterViewInit {
  @Input() stateId: string | undefined
  // @Input() form: FormGroup | undefined
  formGroup: FormGroup | undefined
  // @Input() blockState: RxState<BlockState> | undefined
  blockState: RxState<BlockState> | undefined
  @Input() admin: boolean | undefined
  @Input() rowId: string | undefined
  @Input() options: JavascriptOptions | undefined
  @Input() stylesFunction: ((block: Block, width: number) => JavascriptStyles) | undefined
  @ViewChild("wrapper", { static: false }) wrapper: ElementRef | undefined
  globalState$ = this.globalState.select()
  blockValue: Block | undefined
  field: Javascript | undefined
  fieldValue: SafeHtml | undefined
  // private subscription: { [key: string]: Subscription } = {}
  // initialized: boolean
  paramsSafeMode: boolean | undefined
  safeMode: boolean | undefined
  iframeIds: string[] = []
  // scriptElement: HTMLScriptElement
  // scriptIdentifier: string
  script: SafeScript | undefined

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(WINDOW)
    private window: Window,
    @Inject(LOCATION)
    private location: Location,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private componentState: RxState<ComponentState>,
    private rxEffects: RxEffects,
    private dataReadService: DataReadService,
    private tokenService: TokenService,
    private changeDetectorRef: ChangeDetectorRef,
    private stateService: StateService,
    private domSanitizer: DomSanitizer,
    private renderer: Renderer2,
  ) {
    rxEffects.register(this.tokenized$)
  }

  tokenized$ = this.componentState.select("source")
    .pipe(
      combineLatestWith(
        this.globalState.select("tokenRefresh")
      ),
      debounceTime(100),
      tap(([source, tokenRefresh]: [Javascript, number]) => {
        // const tokenizedValue = this.tokenService.tokenize(source.value, this.globalState.get("pageWidth"))
        // this.componentState.set("tokenizedValue", () => tokenizedValue )
      })
    )

  ngOnInit(): void {
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId]
    }
    // this.safeMode = this.adminMode
    this.window.formloaded = false
    const searchParams = new URLSearchParams(this.location.search.toLowerCase())
    this.safeMode = searchParams.get("logiform") === "false"

    if (searchParams.get("safeMode") === "true" || searchParams.get("safemode") === "true") {
      this.paramsSafeMode = true
      this.safeMode = true
    }
    if (searchParams.get("safeMode") === "false" || searchParams.get("safemode") === "false") {
      this.paramsSafeMode = false
    }

    /**
     * TODO: use the following block when in app mode and move into a service for routing/etc.
     */
    // this.subscription["activatedRoute"] = this.activatedRoute.queryParams
    //   .subscribe(params => {
    //     if (params.safeMode === "true") {
    //       this.paramsSafeMode = true
    //     }
    //     if (params.safeMode === "false") {
    //       this.paramsSafeMode = false
    //     }
    //     this.params = params
    //     this.safeMode = params.safeMode === "true"
    //     this.unsafeMode = params.safeMode === "false"
    //   })

/*
    if (this.adminMode && this.rowId) {
      this.subscription["form"] = this.state.form.get(["rows", this.rowId, "value"]).valueChanges
        .pipe(
          debounceTime(3000)
        )
        .subscribe(changes => {
          // if (this.adminMode) {
            // this.window["formloaded"] = false
            // this.safeMode = false
            this.processScript(changes)
          // }
        })

      this.subscription["previewMode"] = this.stateService.previewMode$
        .subscribe(previewMode => {
          if (previewMode && this.adminMode && this.field?.value) {
            this.safeMode = false
            // this.field = this.form.value
            this.processScript(this.field.value)
          }
        })
    }
*/

/*
    this.subscription["adminMode"] = this.stateService.adminMode$
      .subscribe(async adminMode => {
        if (this.adminMode && this.state.form) {
          this.blockValue = this.state.form.value
        }
        if (!this.adminMode) {
          this.blockValue = this.dataReadService.blocks[this.state.blockId]
        }
        if (this.blockValue) {
          this.field = this.blockValue.rows[this.rowId] as Javascript
          // if (this.window["formloaded"] === false) {
          await this.processScript(this.field.value)
          // }
        }
      })
*/
  }

  ngAfterViewInit(): void {
    this.onResize()
    this.bindIframes()
    // await this.processScript(this.field.value)
  }

  @HostListener("window:resize")
  onResize(): void {
    // this.width = this.state?.width
    this.changeDetectorRef.detectChanges()
  }

/*
  get stateId(): string {
    return this.blockState.get("parentRowId")
  }
*/

  get styles(): JavascriptStyles | undefined {
    // return this.stylesFunction(this.blockState.get("blockValue"), this.blockState.get("blockWidth"))
    return
  }

  /**
   * process script
   */

  processScript(value: string): void {
    /**
     * This is totally NOT secure,
     * Allows scripts to be loaded in the template, without any sanitizing.
     *
     * TODO: consider iframe scenario, where iframe ids are used by iframe resizer via this.bindIframes()
     */

    const safeHtml: UnsafeHtml = this.domSanitizer.bypassSecurityTrustHtml(value) as UnsafeHtml
    safeHtml.changingThisBreaksApplicationSecurity = value
    this.fieldValue = safeHtml

    if (this.wrapper) {
      const scriptNodes = this.wrapper.nativeElement.querySelectorAll("script")
      for (const scriptNode of scriptNodes) {
        this.renderer.removeChild(this.wrapper.nativeElement, scriptNode)
      }
    }

    if (value && !this.safeMode) {
      this.window["formloaded"] = false

      const div = this.renderer.createElement("div")
      div.innerHTML = value

      /**
       * get iframe ids and bind them to the iframe resizer AfterViewInit
       */
      const iframeElements = div.querySelectorAll("iframe")
      // if (iframeElements) {
        for (const iframeElement of iframeElements) {
          const id = iframeElement.getAttribute("id")
          if (id && !this.iframeIds.includes(id)) {
            this.iframeIds.push(id)
          }
        }
      // }

      /**
       * copy all the scripts from the field data
       * process tokens
       * load scripts in the DOM, the browser will automatically execute them
       */
      const scriptElements = []
      const scriptNodes = div.querySelectorAll("script")
      for (const scriptNode of scriptNodes) {
        // Create a new empty script element, HTML5 will execute it upon adding to DOM
        const scriptElement = this.renderer.createElement("script")
        // Copy all the attributes from the original script element
        for (const scriptNodeAttribute of scriptNode.attributes) {
          scriptElement.attributes.setNamedItem(<Attr>scriptNodeAttribute.cloneNode())
        }
        const script = this.tokenService.processTokens(scriptNode.textContent)
        const scriptContent = this.document.createTextNode(script)
        scriptElement.appendChild(scriptContent)
        // Remove the original script element
        // scriptNode.remove()
        // add the new element to the list
        scriptElements.push(scriptElement)
      }
      for (const scriptElement of scriptElements) {
        this.renderer.appendChild(this.wrapper?.nativeElement, scriptElement)
      }
    }
  }

  bindIframes(): void {
    for (const iframeId of this.iframeIds) {
      this.window.iFrameResize({ log: false }, "#" + iframeId)
    }
  }

  /**
   * remove javascript comments, lines with first 2 character "//"
   */
  removeJavascriptComments(script: string): string {
    return script.replace(/[\s]+\/\/[\s\S]*?\n/g, "")
  }

  removeHtml(parentElement: HTMLElement, elementTypes: string[]): void {
    elementTypes.forEach(elementType => {
      const elements = parentElement.querySelectorAll(elementType)
      elements[0]?.remove()
    })
  }

  /**
   * use renderer2
   *
   * see: https://stackoverflow.com/questions/34489916/how-to-load-external-scripts-dynamically-in-angular
   * see: https://stackoverflow.com/a/59789482/7016336
   */

}
