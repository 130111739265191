import { inject, Injectable } from "@angular/core"
import { FormBuilder, FormControl } from "@angular/forms"
import { BillingFormVm } from "../share/billing-form"
import { MembershipRenewForm } from "../../store/membership-renew/membership-renew.models"

@Injectable({ providedIn: "root" })
export class MembershipRenewVm {
  fb = inject(FormBuilder)
  billingFormVm = inject(BillingFormVm)
  formGroup = this.create()

  get billingFormGroup() {
    return this.formGroup.controls.billing
  }

  create() {
    return this.fb.group<MembershipRenewForm>({
      billing: this.billingFormVm.create(),
      promoCode: new FormControl("", { nonNullable: true }),
    })
  }
}
