<nz-form-item
  *ngIf="formGroup && options"
  [formGroup]="formGroup"
  style="margin: 0"
>
  <label
    nz-checkbox
    [formControlName]="options.fieldName"
    [nzDisabled]="options.validateStatus === ValidateStatus.DISABLED"
  >
    <ng-content></ng-content>
  </label>
</nz-form-item>
