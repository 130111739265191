import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core"
import { BatteryQuoterService } from "../../battery-quoter.service"
import {AnalyticsEvent, AnalyticsScreenViewEvent, AnalyticsService} from "../../../../services/analytics"
import { CommonModule } from "@angular/common"
import { NzButtonModule } from "ng-zorro-antd/button"
import firebase from "firebase/compat/app"
import EventName = firebase.analytics.EventName

@Component({
  selector: "ava-results",
  templateUrl: "./results.component.html",
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
  ],
})
export class ResultsComponent implements OnInit, AfterViewInit {
  included: string[] = []
  @ViewChild("container") container: ElementRef | undefined
  containerWidth: number | undefined
  gridTemplateColumns: { [key: string]: string } = {}

  constructor(
    public bs: BatteryQuoterService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.included = [
      "Mobile Battery Test",
      "Mobile Installation",
      "Battery Warranty",
      "Battery Recycling"
    ]

/*
    if (this.bs.testMode && this.bs.params) {
      const testData = ["Acura", "CL", "2002", "V6 3.2L"]
      this.bs.selections = testData
      this.bs.getBatteries(testData, this.bs.params)
    }
*/

    const event: AnalyticsEvent = {
      eventCategory: "battery_quote",
      eventAction: "quote",
      eventLabel: "battery",
      eventValue: 1
    }
    this.analyticsService.sendEvent(event)

    const analyticsScreenViewEvent: AnalyticsScreenViewEvent = {
      eventName: EventName.SCREEN_VIEW,
      eventParams: {
        firebase_screen: "Battery-Quote",
        firebase_screen_class: "Battery-Quote",
        action: "Quote-Success"
      },
    }
    this.analyticsService.sendFirebaseScreenViewEvent(analyticsScreenViewEvent)

  }

  /**
   * use ngAfterViewInit() for these, if needed:
   * this.bs.scrollIntoView()
   * this.bs.setFocus(this.requestOnlineButtonElement)
   */

  ngAfterViewInit(): void {
    this.onResize()
  }

  @HostListener("window:resize")
  onResize(): void {
    this.containerWidth = this.container?.nativeElement.offsetWidth

    this.gridTemplateColumns = { "grid-template-columns": "auto" }
    if (this.containerWidth && this.containerWidth >= 700) {
      this.gridTemplateColumns = { "grid-template-columns": "50% 50%" }
    }
    if (this.containerWidth && this.containerWidth >= 1080) {
      // this.gridTemplateColumns = { "grid-template-columns": "210px auto 300px" }
    }

  }

}
