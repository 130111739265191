import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import {
  MembershipNumberForm,
  QuickRenewForm,
  QuickRenewFormVm,
} from "../../modules/quick-renew/services/quick-renew-form.vm"
import { catchError, map, switchMap, tap } from "rxjs/operators"
import { of, withLatestFrom } from "rxjs"
import { Operation, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import {
  getQuickRenewAccountDetails,
  getQuickRenewExecutionData,
  QuickRenewAccountDetails,
} from "./quick-renew.selectors"
import { FormGroupValue } from "../../modules/share/form.utils"
import { getPayment } from "@aaa/emember/store-payment"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { AccountDetails, ValidateSucceededResponseObject } from "../types/types"
import { M } from "../m.type"
import { ExecuteService } from "../services/execute.service"
import { QuickRenewActions } from "./quick-renew.actions"
import {
  Membership,
  MembershipMGetMemberInfoEventPayload,
  MembershipMMethod,
  MembershipMOperationExecuteRenewEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipM"
import { AccountStatus, ClubApp } from "@aaa/emember/types"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { RequestError, RequestErrorType } from "../generic-errors"
import { checkCybersourcePaymentValidation } from "../check-cybersource-payment-validation"
import { checkMembershipErrorsMSystem } from "../check-membership-errors-m-system"
import { checkOperationErrorsMSystem } from "../check-operation-errors-m-system"
import { getClearCacheSettings } from "../utils/get-cache-settings"
import { DataLayerService } from "../../modules/share/services/data-layer.service"
import { AnalyticsPurchaseEvent } from "../../../types/analytics-purchase-event"
import { getTransactionId } from "../utils/get-transaction-id"
import { AppAnalyticsEvents } from "../../../types/analytics-events"

@Injectable({ providedIn: "root" })
export class QuickRenewMSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Northampton, ClubApp.Shelby]))
  formVm = inject(QuickRenewFormVm)
  executeService = inject(ExecuteService)
  dataLayer = inject(DataLayerService)

  setSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.recostValidateSucceeded),
      map(({ response }: ValidateSucceededResponseObject<M.MemberInfoAndOfferingsResponseObject>) => {
        const { Result } = response.response.memberInfoResponse
        const accountDetails = new M.AccountInfo(Result.Membership[0], Result.MembershipOffering)

        return QuickRenewActions.setAccountDetails({ accountDetails })
      }),
    ),
  )

  updatePaymentCardHolderInformation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuickRenewActions.recostValidateSucceeded),
        tap(({ response }: ValidateSucceededResponseObject<M.MemberInfoAndOfferingsResponseObject>) => {
          const account = new M.AccountInfo(response.response.memberInfoResponse.Result.Membership[0])
          this.formVm.paymentCardHolderFormGroup.patchValue({
            firstName: account?.firstName,
            lastName: account?.lastName,
          })
        }),
      ),
    { dispatch: false },
  )

  recostValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.recostValidate),
      switchMap(() =>
        this.recostValidate(this.formVm.membershipFormGroup.value).pipe(
          map((res) => QuickRenewActions.recostValidateSucceeded(res)),
          catchError((error) => of(QuickRenewActions.recostValidateFailed({ error }))),
        ),
      ),
    ),
  )

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.pay),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(getQuickRenewAccountDetails),
            this.store.select(getQuickRenewExecutionData),
            this.store.select(QuickRenewAccountDetails.getBalance),
            this.store.select(getPayment),
          ),
        ),
      ),
      switchMap(([, accountDetails, executionData, totalCost, payment]) =>
        this.pay(this.formVm.formGroup.value, accountDetails, executionData, totalCost, payment).pipe(
          map(() => QuickRenewActions.paySucceeded()),
          catchError((error) => of(QuickRenewActions.payFailed({ error }))),
        ),
      ),
    ),
  )

  pay(
    formValues: FormGroupValue<QuickRenewForm>,
    accountDetails: AccountDetails | null,
    executionData: string,
    totalCost: number,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
  ) {
    const membershipEvent: MembershipMOperationExecuteRenewEventPayload = {
        cacheSettings: getClearCacheSettings(formValues.membershipFormGroup?.membershipNumber),
        executionData: executionData,
        method: MembershipMMethod.OPERATION_EXECUTE,
        operation: Operation.UPDATE,
        threePointAuth: {
          memberNumber: formValues.membershipFormGroup?.membershipNumber || "",
          zip: formValues.membershipFormGroup?.zipcode || "",
          lastName: formValues.membershipFormGroup?.lastName || "",
        },
        autoRenew: !!payment.formValues.autoRenew,
      },
      paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
        executionData: {
          flexMicroFormToken: payment.token,
          billTo: {
            address1: String(accountDetails?.address.street1),
            address2: String(accountDetails?.address.street2),
            administrativeArea: String(accountDetails?.address.state),
            buildingNumber: "",
            country: "US",
            district: String(accountDetails?.address.state),
            email: String(accountDetails?.email || "fallback@avagate.com"),
            firstName: formValues.paymentCardHolderFormGroup?.firstName || "",
            lastName: formValues.paymentCardHolderFormGroup?.lastName || "",
            locality: String(accountDetails?.address.city),
            phoneNumber: String(accountDetails?.phone.home),
            postalCode: String(accountDetails?.address.zip),
          },
          amountDetails: { totalAmount: String(totalCost), currency: "USD" },
          creditCardBrandedName: payment.formValues.card?.cardName || "",
        },
        method: PaymentCybersourceMethod.OPERATION_EXECUTE,
        operation: Operation.UPDATE,
      },
      payload: OperationExecutePayload = {
        membershipEvent,
        paymentEvent,
        operation: Operation.UPDATE,
      }

    return this.executeService.execute<M.MemberInfoResponseObject, M.RenewExecutePaymentResponseObject>(payload).pipe(
      map(({ validateObject, paymentObject, operationObject }) => {
        const paymentError = !!paymentObject?.meta?.isError
        if (paymentError) {
          checkCybersourcePaymentValidation(paymentObject.error)
        }

        const membershipError = !!validateObject?.meta?.isError
        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject.error, validateObject)
        }

        const operationError = !!operationObject?.meta?.isError
        if (operationError) {
          checkOperationErrorsMSystem(operationObject.error, operationObject)
        }

        const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
          currency: "USD",
          transaction_id: getTransactionId(paymentObject),
          value: totalCost,
          membershipLevel: accountDetails?.code?.level,
          items: [
            { quantity: 1, price: totalCost, item_id: "primary", item_name: AppAnalyticsEvents.QuickRenewRenewal },
          ],
          context: "ava-store " + AppAnalyticsEvents.QuickRenewRenewal,
        }
        this.dataLayer.purchaseEvent(analyticsEventParams)

        return {
          membership: validateObject,
          payment: paymentObject,
        }
      }),
    )
  }

  recostValidate(formValues: FormGroupValue<MembershipNumberForm>) {
    const payload: MembershipMGetMemberInfoEventPayload = {
      method: MembershipMMethod.GET_MEMBER_INFO,
      membershipNumber: formValues.membershipNumber || "",
      threePointAuth: {
        memberNumber: formValues.membershipNumber || "",
        lastName: formValues.lastName || "",
        zip: formValues.zipcode || "",
      },
    }

    return this.executeService.membershipQuery<M.MemberInfoAndOfferingsResponseObject>(payload).pipe(
      map((validateObject) => {
        const executionData = validateObject.response?.executionData || ""
        const membershipError = !!validateObject?.meta?.isError

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject.error, validateObject)
        }

        const membership: Membership = validateObject.response.memberInfoResponse.Result.Membership[0]
        const account = new M.AccountInfo(membership)
        // hasAutoRenew == N && renewalStatus == D -> allow them to pay quick renewal
        const allowRenew = account.autoRenew === false && account.status === AccountStatus.PENDING

        if (!allowRenew) {
          throw new RequestError(RequestErrorType.MembershipNotExpiredYet, validateObject)
        }

        return { executionData, response: validateObject }
      }),
    )
  }
}
