<style>
  img {
    width: 100%;
    height: auto;
  }
</style>
<div #container>
  <ng-container *ngIf="agent!.value">
    <div
      *ngIf="containerWidth >= 760"
      style="text-align: center; margin: 10px; font-size: 30px; font-weight: 400"
    >
      Book time with
      {{ agent.value.fName }}
    </div>

    <div
      style="display: grid; grid-gap: 20px; justify-items: center; padding: 40px"
      [ngStyle]="gridTemplateColumns"
    >
      <div style="width: 210px">
        <img
          src="{{ agent.value.image }}"
          [alt]="'Travel Agent: ' + agent.value.fName + ' ' + agent.value.lName"
          style="border-radius: 10px"
        >
      </div>
      <div style="font-weight: 200; display: grid; grid-gap: 20px; align-content: flex-start;">
        <div>
          <div style="font-size: 24px; font-weight: 500">
            {{ agent.value.fName }} {{ agent.value.lName }}
          </div>
          <div *ngFor="let type of agentTypes">
            {{ type.text }}
          </div>
        </div>
        <div *ngIf="agentOffice">
          <div
            *ngIf="agentOffice.name"
            [style]="'font-size: 20px; font-weight: 500'"
          >
            {{ agentOffice.name }}
          </div>
          <div>
            {{ agentOffice.addrLine1 }}
          </div>
          <div>
            {{ agentOffice.addrLine2 }}
          </div>
          <div>
            <a href="tel:{{ agent.value.phone }}">{{ agent.value.phone }}</a>
          </div>
        </div>
        <div>
          <div style="font-size: 20px; font-weight: 500">
            About Me
          </div>
          <div>
            {{ agent.value.about }}
          </div>
        </div>
      </div>

      <ng-template #bannerTitle>
        Your Appointment
      </ng-template>
      <nz-card
        [nzTitle]="bannerTitle"
        class="blue-banner"
        style="width: 300px"
        [ngStyle]="appointmentCardStyles"
        [nzBodyStyle]="{'display': 'grid', 'grid-gap': '20px', 'padding-top': '0', 'padding-bottom': '0'}"
      >
        <div>
          <div style="font-size: 20px; font-weight: 500; margin-bottom: 10px">
            Select Date:
          </div>
          <div *ngIf="timeSlots?.length">
            <form nz-form [formGroup]="formService.form[formId]">
              <nz-date-picker
                style="width: 100%"
                formControlName="date"
                (ngModelChange)="dateChanged($event)"
                [nzDisabledDate]="datePickerDisabledDates"
                [disabled]="!timeSlots.length"
              ></nz-date-picker>
            </form>
          </div>
          <!--
                  <mat-form-field
                    appearance="outline"
                    (click)="picker.open()"
                    color="primary"
                  >
                    <input
                      matInput disabled
                      [matDatepickerFilter]='agentDateFilter'
                      [matDatepicker]='picker'
                      (dateChange)="dateChanged($event)"
                      [min]="minDate"
                      [max]="maxDate"
                    >
                  </mat-form-field>
          -->

        </div>
        <div>
          <div style="font-size: 20px; font-weight: 500; margin-bottom: 10px">
            Select available time:
          </div>
<!--
          <div *ngIf="timeSlots?.length === 0 || (dayTimeSlots.length === 0)">
            No Availability
          </div>
-->
          <div style="display: grid; grid-gap: 10px; grid-template-columns: 50% 50%">
            <ng-container *ngIf="dayTimeSlots.length">
              <button
                *ngFor="let slot of dayTimeSlots; let last = last; let even = even"
                nz-button
                [nzType]="slot.time.hour === timeSlot.value?.time?.hour ? 'primary' : 'default'"
                [ngClass]="{'no-focus': slot.time.hour === timeSlot.value?.time?.hour}"
                [ngStyle]="{'grid-column': last && even ? '1 / 3' : 'auto'}"
                (click)="$event.preventDefault(); timeSlot.setValue(slot)"
                [disabled]="!slot?.time?.hour"
              >
                {{ slot.time.hour > 12 ? slot.time.hour - 12 : slot.time.hour }}:00{{ slot.time.hour < 12 ? 'am' : 'pm' }}
              </button>
            </ng-container>
            <button
              nz-button nzType="primary"
              style="grid-column: 1 / 3"
              (click)="bookWith(); $event.preventDefault()"
              [disabled]="!timeSlot.value"
            >
              Continue
            </button>
          </div>
        </div>
      </nz-card>

    </div>
  </ng-container>
</div>
