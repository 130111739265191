import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from "@angular/core"
import { StateMachineService } from "../../../services/state-machine.service"
import { FormGroup, FormControl } from "@angular/forms"
import { AppMode, FormValues, Office, AgentType, BookingDetails, Agent } from "@aaa/interface-agentScheduler"
import { FormService, FormValue } from "../../../services/form.service"
import { differenceInCalendarDays } from "date-fns"
import { LOCATION } from "@ng-web-apis/common"
import { ValidatorService } from "../../../../../services/validator"

@Component({
  selector: "ava-user-info",
  templateUrl: "./user-info.component.html"
})
export class UserInfoComponent implements OnInit, AfterViewInit {
  @Input() formId: string | undefined
  @ViewChild("container") container: ElementRef | undefined
  formValue: FormValue | undefined
  formValues: FormValues | undefined
  formGroup: FormGroup | undefined
  containerWidth: number | undefined
  JSON: JSON = JSON
  gridTemplateColumns: { [key: string]: string } = {}
  agentOffice: Office | undefined
  agentTypes: (AgentType | undefined)[] = []
  validating: boolean = false
  // timeSlot: FormControl | undefined
  agent: FormControl | undefined
  firstName: FormControl | undefined
  lastName: FormControl | undefined
  email: FormControl | undefined
  phone: FormControl | undefined
  comment: FormControl | undefined
  // departure: FormControl | undefined
  // agentTimezone: FormControl | undefined

  constructor(
    @Inject(LOCATION)
    private location: Location,
    public sms: StateMachineService,
    public formService: FormService,
    private validatorService: ValidatorService,
  ) {
  }

  ngOnInit(): void {
    if (this.formId) {
      this.formGroup = this.formService.form[this.formId]
      this.formValue = this.formGroup.getRawValue()
      this.formValues = this.formService.formValues[this.formId]
      // this.timeSlot = this.formGroup.get("timeSlot") as FormControl
      this.agent = this.formGroup.get("agent") as FormControl
      this.firstName = this.formGroup.get("firstName") as FormControl
      this.lastName = this.formGroup.get("lastName") as FormControl
      this.email = this.formGroup.get("email") as FormControl
      this.phone = this.formGroup.get("phone") as FormControl
      this.comment = this.formGroup.get("comment") as FormControl
      // this.departure = this.formGroup.get("departure") as FormControl
      // this.agentTimezone = this.formGroup.get("agentTimezone") as FormControl
    }
    if (this.formValues && this.formValue) {
      this.agentOffice = this.formValues.offices.find(office => office.id === this.formValue?.agent?.officeIds[0])
      const agentTypes: { [key: string]: AgentType | undefined } = {}
      const agentTypeIds: Agent["typeIds"] | undefined = this.formValue.agent?.typeIds
      agentTypeIds?.forEach(typeId => {
        if (this.formValues) {
          agentTypes[typeId] = this.formValues.types.find(type => type.id === typeId)
        }
      })
      this.agentTypes = Object.values(agentTypes)
    }
  }

  formSubmit(form: FormGroup): void {
    /**
     * Validate all fields.
     */
    this.validating = true
    this.formGroup?.markAllAsTouched()
    this.ngModelChangeFirstName(form.value.firstName, true)
    this.ngModelChangeLastName(form.value.lastName, true)
    this.ngModelChangeEmail(form.value.email, true)
    this.ngModelChangePhone(form.value.phone, true)

    if (form.valid && form.value && this.formValues) {
      const bookingDetails: BookingDetails = {
        timeSlot: form.value.timeSlot,
        timezone: form.value.agentTimezone,
        duration: 59, //one hour - static for now
        agentType: this.formValues.appMode,
        agentInfo: {
          firstName: form.value.agent?.fName || "",
          lastName: form.value.agent?.lName || "",
          phone: form.value.agent?.phone || "",
        },
        topics: this.formValues.selectedTopicIds
          .map(selectedTopicId => this.formValues?.topics.find(topic => topic.id === selectedTopicId)?.text || ""),
        region: this.formValues.regions.find(region => region.id === this.formValues?.selectedRegionId)?.text || "",
        meeting: this.formValues.meetings.find(meeting => meeting.id === this.formValues?.selectedMeetingId)?.text || "",
        meetingType: this.formValues.meetings.find(meeting => meeting.id === this.formValues?.selectedMeetingId)?.type || "online",
        userInfo: {
          firstName: form.value.firstName,
          lastName: form.value.lastName,
          email: form.value.email,
          memberNumber: this.formValues.metaUser.memberNumber || "",
          phone: form.value.phone,
          departure: this.formattedDepartureRange(form?.value?.departure),
          comment: form.value.comment
        },
        location: this.formValues.offices.find(office => office.id === this.formValue?.agent?.officeIds[0]),
        shareWith: [this.formValues.shareWith.find(shareWith => shareWith.id === this.formValue?.agent?.shareWithIds[0])?.email || ""],
        appURL: this.location.href.toLowerCase().split("?")[0],
        promo: this.formValues.overrides.promo
      }

      this.sms.sendEvent("NEXT", bookingDetails)
    }
  }

  formattedDepartureRange(departure: string[]): string {
    if (departure?.length === 2) {
      return departure[0].toLocaleString().split(",")[0] +
        " - " + departure[1].toLocaleString().split(",")[0]
    } else if (this.formValues?.appMode === AppMode.TRAVEL) {
      return "not specified"
    }
    return ""
  }

  ngAfterViewInit(): void {
    this.onResize()
  }

  @HostListener("window:resize")
  onResize(): void {
    this.containerWidth = this.container?.nativeElement.offsetWidth

    this.gridTemplateColumns = { "grid-template-columns": "auto" }
    if (this.containerWidth && this.containerWidth >= 700) {
      this.gridTemplateColumns = { "grid-template-columns": "210px auto" }
    }
    if (this.containerWidth && this.containerWidth >= 1080) {
      this.gridTemplateColumns = { "grid-template-columns": "210px auto 300px" }
    }
  }

  disabledDate = (current: Date): boolean =>
    // Cannot select days before today
     differenceInCalendarDays(current, new Date) < 0


  ngModelChangeFirstName(firstName: string, validating: boolean): void {
    if (validating) {
      if (!firstName || firstName.length < 1) {
        this.firstName?.setErrors({ "incorrect": true })
      }
      if (!this.validatorService.validateIsTextString(firstName)) {
        this.firstName?.setErrors({ "incorrect": true })
      }
    } else {
      this.firstName?.markAsPristine()
      this.firstName?.updateValueAndValidity()
    }
  }

  ngModelChangeLastName(lastName: string, validating: boolean): void {
    if (validating) {
      if (!lastName || lastName.length < 1) {
        this.lastName?.setErrors({ "incorrect": true })
      }
      if (!this.validatorService.validateIsTextString(lastName)) {
        this.lastName?.setErrors({ "incorrect": true })
      }
    } else {
      this.lastName?.markAsPristine()
      this.lastName?.updateValueAndValidity()
    }
  }

  ngModelChangeEmail(email: string, validating: boolean): void {
    if (validating) {
      if (!this.validatorService.validateIsEmail(email)) {
        this.email?.setErrors({ "incorrect": true })
      }
    } else {
      this.email?.markAsPristine()
      this.email?.updateValueAndValidity()
    }
  }

  ngModelChangePhone(phone: string, validating: boolean): void {
    if (validating) {
      if (!this.validatorService.validateIsPhone(phone) || phone?.length > 14) {
        this.phone?.setErrors({ "incorrect": true })
      }
    } else {
      this.phone?.markAsPristine()
      this.phone?.updateValueAndValidity()
    }
  }

}
