import { Injectable } from "@angular/core"

export enum FieldType {
  AGENT_SCHEDULER = "AGENT_SCHEDULER",
  BATTERY_QUOTER = "BATTERY_QUOTER",
  SMART_WEATHER = "SMART_WEATHER",
  QUILL_EDITOR = "QUILL_EDITOR",
  QUILL_EDITOR_ACCORDION = "QUILL_EDITOR_ACCORDION",
  BUTTON = "BUTTON",
  IMAGE = "IMAGE",
  JAVASCRIPT = "JAVASCRIPT",
  BLOCK_REFERENCE = "BLOCK_REFERENCE",
  MULTI_BLOCK = "MULTI_BLOCK",
  PRICE_CHART_MEMBER_QUANTITY = "PRICE_CHART_MEMBER_QUANTITY",
  PRICE_CHART_CARD = "PRICE_CHART_CARD",
  VIDEO = "VIDEO",
  JOIN_RENEW_CONFIRMATION_SUCCESS = "JOIN_RENEW_CONFIRMATION_SUCCESS",
  COMPARISON_CHART = "COMPARISON_CHART",
}

export enum FieldTypeLabel {
  AGENT_SCHEDULER = "Agent Scheduler",
  BATTERY_QUOTER = "Battery Quoter",
  SMART_WEATHER = "Smart Weather",
  QUILL_EDITOR = "Quill Editor",
  QUILL_EDITOR_ACCORDION = "Quill Editor Accordion",
  BUTTON = "Button",
  IMAGE = "Image",
  JAVASCRIPT = "Javascript",
  BLOCK_REFERENCE = "Block Reference",
  MULTI_BLOCK = "Multi Block",
  PRICE_CHART_MEMBER_QUANTITY = "Price Chart Member Quantity",
  PRICE_CHART_CARD = "Price Chart Card",
  VIDEO = "Video",
  JOIN_RENEW_CONFIRMATION_SUCCESS = "Join Renew Confirmation Success",
  COMPARISON_CHART = "Comparison Chart",
}

// export type FieldTypeDeprecated = "quillEditor" | "button" | "image" | "video" | "javascript" | "blockRef" | "joinPriceMemberQuantity"

@Injectable({
  providedIn: "root"
})
export class FieldService {

}
