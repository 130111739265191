import { inject, Injectable, Type } from "@angular/core"
import { ToastrService } from "ngx-toastr"
import { NotificationComponent } from "./notification.component"
import { ActiveToast } from "ngx-toastr/toastr/toastr.service"
import { IndividualConfig } from "ngx-toastr/toastr/toastr-config"

@Injectable({ providedIn: "root" })
export class NotificationService {
  toastr = inject(ToastrService)

  openFromComponent<T>(component: Type<T>) {
    const activeToast = this.toastr.info("", "") as ActiveToast<NotificationComponent>

    activeToast.onShown.subscribe(() => {
      activeToast.portal.instance.viewComponentRef?.createComponent(component)
    })

    return activeToast
  }

  show(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<NotificationComponent> {
    return this.toastr.show(message, title, override)
  }

  success(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<NotificationComponent> {
    return this.toastr.success(message, title, override)
  }

  error(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<NotificationComponent> {
    return this.toastr.error(message, title, override)
  }

  info(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<NotificationComponent> {
    return this.toastr.info(message, title, override)
  }

  warning(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<NotificationComponent> {
    return this.toastr.warning(message, title, override)
  }

  clear() {
    return this.toastr.clear()
  }
}
