import { inject, Injectable } from "@angular/core"
import { EventName, EventPayload, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import { Store } from "@ngrx/store"
import { JoinRenewService } from "./join-renew.service"
import {
  getDefaultOperationDocById,
  getMembershipDocById,
  getPaymentById,
  SessionDocObject,
} from "@aaa/emember/store-session"
import { first, forkJoin, Observable, of, switchMap, throwError, withLatestFrom } from "rxjs"
import { AppStore } from "../types/store.model"
import { map, timeout } from "rxjs/operators"
import { getResponseKey } from "../utils/get-response-key"
import { SessionDocResponseObject } from "../types/types"
import { RequestError, RequestErrorType } from "../generic-errors"

const TIMEOUT_SECONDS = 120 * 1000

@Injectable({ providedIn: "root" })
export class ExecuteService {
  counter = 0
  joinRenewService = inject(JoinRenewService)
  store = inject(Store<AppStore>)
  get responseKey() {
    this.counter += 1

    return getResponseKey() + this.counter
  }

  execute<MembershipType = SessionDocResponseObject, PaymentType = SessionDocResponseObject>(
    payload: OperationExecutePayload,
  ) {
    const responseKey = this.responseKey,
      sources: {
        api: Observable<any>
        operation: Observable<SessionDocObject | null>
      } = {
        api: of(null),
        operation: this.store.select(getDefaultOperationDocById(responseKey)).pipe(
          first((value) => !!value),
          timeout({
            each: TIMEOUT_SECONDS,
            with: () => throwError(() => new RequestError(RequestErrorType.OperationTimeoutError)),
          }),
        ),
      }

    payload.responseKey = responseKey

    if (payload.membershipEvent) {
      payload.membershipEvent.responseKey = responseKey
    }

    if (payload.paymentEvent) {
      payload.paymentEvent.responseKey = responseKey
    }

    sources.api = this.joinRenewService.sendToEventCoordinator(EventName.OPERATION_EXECUTE, payload).pipe(
      timeout({
        each: TIMEOUT_SECONDS,
        with: () => throwError(() => new RequestError(RequestErrorType.OperationTimeoutError)),
      }),
    )

    return forkJoin(sources).pipe(
      switchMap(({ operation }) =>
        of(operation).pipe(
          withLatestFrom(
            this.store.select(getMembershipDocById<MembershipType>(responseKey)),
            this.store.select(getPaymentById<PaymentType>(responseKey)),
          ),
        ),
      ),
      map(([operationObject, validateObject, paymentObject]) => ({ operationObject, validateObject, paymentObject })),
    )
  }

  paymentQuery<PaymentType = any, Payload extends EventPayload = EventPayload>(payload: Payload) {
    const responseKey = this.responseKey,
      api = this.joinRenewService.sendToEventCoordinator(EventName.PAYMENT_QUERY, payload).pipe(
        timeout({
          each: TIMEOUT_SECONDS,
          with: () => throwError(() => new RequestError(RequestErrorType.OperationTimeoutError)),
        }),
      ),
      paymentEvent = this.store.select(getPaymentById<PaymentType>(responseKey)).pipe(
        timeout({
          each: TIMEOUT_SECONDS,
          with: () => throwError(() => new RequestError(RequestErrorType.OperationTimeoutError)),
        }),
        first((sessionDoc): sessionDoc is PaymentType => !!sessionDoc),
      )

    payload.responseKey = responseKey

    return forkJoin([paymentEvent, api]).pipe(map(([paymentObject]) => paymentObject))
  }

  membershipQuery<MembershipType = any, Payload extends EventPayload = EventPayload>(payload: Payload) {
    const responseKey = this.responseKey,
      api = this.joinRenewService.sendToEventCoordinator(EventName.MEMBERSHIP_QUERY, payload).pipe(
        timeout({
          each: TIMEOUT_SECONDS,
          with: () => throwError(() => new RequestError(RequestErrorType.OperationTimeoutError)),
        }),
      ),
      membershipEvent = this.store.select(getMembershipDocById<MembershipType>(responseKey)).pipe(
        timeout({
          each: TIMEOUT_SECONDS,
          with: () => throwError(() => new RequestError(RequestErrorType.OperationTimeoutError)),
        }),
        first((sessionDoc): sessionDoc is MembershipType => !!sessionDoc),
      )

    payload.responseKey = responseKey

    return forkJoin([membershipEvent, api]).pipe(map(([membershipObject]) => membershipObject))
  }
}
