import { AfterViewInit, Component, inject, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core"
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms"
import { AsyncPipe, NgIf } from "@angular/common"
import { BillingForm, BillingFormVm, BillingToForm } from "./billing-form.vm"
import { FormCheckBoxComponent } from "../form/controls/checkbox"
import { FormInputComponent } from "../form/controls/input"
import { FormSelectComponent } from "../form/controls/select"
import { tap } from "rxjs/operators"
import { FormGroupValue } from "../form.utils"
import { filter, Subject, takeUntil } from "rxjs"
import { Store } from "@ngrx/store"
import { AppStore } from "@aaa/emember/store-types"
import { getBillingFormErrors } from "../../../store/form-messages"

@Component({
  standalone: true,
  selector: "ava-billing-form",
  templateUrl: "./billing-form.html",
  styleUrls: ["./billing-form.scss"],
  imports: [ReactiveFormsModule, FormCheckBoxComponent, NgIf, FormInputComponent, FormSelectComponent, AsyncPipe],
})
export class BillingFormComponent implements OnDestroy, AfterViewInit, OnChanges {
  store = inject(Store<AppStore>)
  billingFormVm = inject(BillingFormVm)
  controlContainer = inject(ControlContainer)
  errorMessages$ = this.store.select(getBillingFormErrors)
  @Input() states = []
  @Input() defaultBillingTo: Partial<FormGroupValue<BillingToForm>> = {}
  get formGroup(): FormGroup<BillingForm> {
    if (this.controlContainer) {
      return this.controlContainer.control as FormGroup<BillingForm>
    }
    return this.billingFormVm.create()
  }

  get sameBillingAddress() {
    return this.formGroup.controls.sameBillingAddress
  }

  get billingTo() {
    return this.formGroup.controls.billingTo
  }

  alive$ = new Subject()

  ngAfterViewInit() {
    this.sameBillingAddress.valueChanges
      .pipe(
        filter(() => Object.keys(this.defaultBillingTo).length > 0),
        tap((checked) => checked && this.billingTo.patchValue(this.defaultBillingTo)),
        takeUntil(this.alive$),
      )
      .subscribe()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["defaultBillingTo"]) {
      this.billingTo.patchValue(this.defaultBillingTo)
    }
  }

  ngOnDestroy() {
    this.alive$.next(null)
    this.alive$.complete()
  }
}
