import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from "@angular/core"
import { FieldIconProps } from "../../types"
import { NzIconModule } from "ng-zorro-antd/icon"
import { NgIf } from "@angular/common"

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[ava-render-icon]",
  template: `
    <ng-container *ngIf="props">
      <i nz-icon [nzType]="props?.icon" ></i>
    </ng-container>
  `,
  standalone: true,
  imports: [NzIconModule, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RenderIconComponent {
  @Input() props?: FieldIconProps
  @HostBinding("class") get getClass() {
    return this.props?.cls || ""
  }
}
