import checkExistMembershipLevel from "../guards/check-exist-membership-level"
import { Routes } from "@angular/router"
import loadMembership from "../guards/load-membership.metadata"
import { EmemberComponent } from "../emember.component"
import setPageMembershipOffer from "../guards/set-page-membership-offer"
import setPageJoin from "../guards/set-page-join"
import defaultRouting from "../guards/default-routing.midstates"
import setPageTitle from "../guards/set-page-title"
import loadPriceOffer from "../guards/load-price-offer"

export const routes: Routes = [
  {
    path: "join",
    loadChildren: () => import("../../join/join.module").then((m) => m.JoinModule),
    canActivate: [setPageTitle, setPageJoin("join"), loadPriceOffer, checkExistMembershipLevel],
  },
  {
    path: "gift",
    loadChildren: () => import("../../join/join.module").then((m) => m.JoinModule),
    canActivate: [setPageTitle, setPageJoin("gift"), loadPriceOffer],
  },
  {
    path: "account",
    loadChildren: () => import("../../account/account.module").then((m) => m.AccountModule),
    canActivate: [setPageTitle, loadMembership],
  },
  {
    path: "renew",
    loadChildren: () => import("../../quick-renew/quick-renew.module").then((m) => m.QuickRenewModule),
    canActivate: [setPageTitle],
  },
  {
    path: "offers",
    loadChildren: () =>
      import("../../membership-offers/membership-offers.module").then((m) => m.MembershipOffersModule),
    canActivate: [setPageTitle, setPageMembershipOffer("offers"), loadPriceOffer],
  },
  {
    path: "gift-offers",
    loadChildren: () =>
      import("../../membership-offers/membership-offers.module").then((m) => m.MembershipOffersModule),
    canActivate: [setPageTitle, setPageMembershipOffer("gift-offers"), loadPriceOffer],
  },
  {
    path: "contact",
    loadComponent: () => import("../../contact/contact.component").then((c) => c.ContactComponent),
    canActivate: [setPageTitle],
  },
  {
    path: "",
    component: EmemberComponent,
    canActivate: [defaultRouting()],
  },
]
