import { Component, forwardRef, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzFormModule } from "ng-zorro-antd/form"
import { NzGridModule } from "ng-zorro-antd/grid"
import { NzInputModule } from "ng-zorro-antd/input"
import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from "@angular/forms"
import { FormBaseControlComponent } from "./base"
import { NzIconModule } from "ng-zorro-antd/icon"

@Component({
  selector: "ava-form-textarea",
  template: `
    <nz-form-item>
      <nz-form-label *ngIf="formLabel" [nzFor]="controlId" class="ava-form-label">{{ formLabel }}</nz-form-label>
      <nz-form-control [nzValidateStatus]="formControlStatus" [nzErrorTip]="errors" nzHasFeedback>
        <nz-input-group [nzSuffix]="suffixRef">
          <textarea
            [formControl]="formControl"
            [placeholder]="placeholder"
            [attr.id]="controlId"
            nz-input
            class="ava-form-control"
            style="border: none"
            (focusin)="showErrors = false"
            (focusout)="showErrors = true"
          ></textarea>
        </nz-input-group>

        <ng-template #errors let-control>
          <ng-container *ngIf="formControl?.errors && showErrors">
            <ng-container *ngIf="(formControl.errors | keyvalue)[0] as error">
              <div
                class="ava-form-error"
                [innerHTML]="errorMessages && errorMessages[error.key] ? errorMessages[error.key] : error.key"
              ></div>
            </ng-container>
          </ng-container>
        </ng-template>
        <ng-template #suffixRef>
          {{ formControl.value ? suffixLabel || placeholder : "" }}
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `,
  styleUrls: ["./textarea.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormTextAreaComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, NzFormModule, NzGridModule, NzInputModule, ReactiveFormsModule, NzIconModule],
  standalone: true,
})
export class FormTextAreaComponent extends FormBaseControlComponent {
  @Input() suffixLabel = ""
}
