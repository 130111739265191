import { AfterViewInit, Component, ElementRef, HostListener, Inject, ViewChild } from "@angular/core"
import { RxState } from "@rx-angular/state"
import { RxEffects } from "@rx-angular/state/effects"
import { WINDOW } from "@ng-web-apis/common"
import { tap } from "rxjs/operators"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../../services/state"
import {
  Flow, HOOSIER_RX_STATE, HoosierForm, HoosierService, HoosierState, MembershipCardData
} from "../../hoosier.service"
import { Membership } from "../../services/member-info"
import { combineLatestWith } from "rxjs"
import { JOINCONFIRMATION_MOCK } from "../../mocks/joinConfirmation"
import { connectSuiteMembershipCodes } from "@aaa/interface-joinRenew-membership-membershipConnectSuite"

export interface ConfirmedMember extends MembershipCardData {
  membershipUserId?: string
}

@Component({
  selector: "ava-hoosier-confirmation-details",
  templateUrl: "./confirmation-details.html",
  providers: [RxState, RxEffects],
})
export class ConfirmationDetailsComponent implements AfterViewInit {
  @ViewChild("componentWidth") componentWidthElementRef: ElementRef | undefined
  hoosierState$ = this.hoosierState.select()

  members: ConfirmedMember[] = []
  membership: Membership | undefined
  flexDirection: "row" | "column" = "row"
  Flow = Flow

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
  ) {
    rxEffects.register(this.PAYMENT$)
    rxEffects.register(this.MEMBERSHIP$)
    rxEffects.register(this.PAYMENT$_MEMBERSHIP_ERROR$)
    rxEffects.register(this.mockMembers$)
    rxEffects.register(this.flexDirection$)
  }

  flexDirection$ = this.globalState.select("pageWidth")
    .pipe(
      tap(pageWidth => {
        /*
                let flexDirection = "row"
                if (pageWidth <= BreakpointWidth.MEDIUM) {
                  flexDirection = "column"
                }
                this.flexDirection = flexDirection
        */
      }),
    )

  mockMembers$ = this.globalState.select("adminMode")
    .pipe(
      tap(adminMode => {
        let members: ConfirmedMember[] = []
        if (adminMode) {
          const membership: Membership = JOINCONFIRMATION_MOCK.response.response.membership
          // const memberLookupMembership = this.hoosierState.get("MEMBER_LOOKUP").response.memberInfo.membership
          // if (memberLookupMembership) {
          //   members = this.getMembersFromMembershipResponse(memberLookupMembership as JoinMembershipResponse)
          // }
          // if (!memberLookupMembership) {
          members = this.getMembersFromMembershipResponse(membership)
          // }
        }
        if (!adminMode && this.membership) {
          members = this.getMembersFromMembershipResponse(this.membership)
        }
        this.members = members
      }),
    )

  PAYMENT$ = this.hoosierState.select("PAYMENT")
    .pipe(
      tap(PAYMENT => {
        if (PAYMENT) {
          this.window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          })
        }
      }),
    )

  MEMBERSHIP$ = this.hoosierState.select("MEMBERSHIP")
    .pipe(
      tap(MEMBERSHIP => {
        if (MEMBERSHIP) {
          const membership: Membership = MEMBERSHIP.response.response.membership
          if (membership) {
            this.members = this.getMembersFromMembershipResponse(membership)
            this.membership = membership
          }
        }
      }),
    )

  PAYMENT$_MEMBERSHIP_ERROR$ = this.hoosierState.select("PAYMENT")
    .pipe(
      combineLatestWith(
        this.hoosierState.select("MEMBERSHIP_ERROR"),
      ),
      tap(([PAYMENT, MEMBERSHIP_ERROR]) => {
        if (PAYMENT && MEMBERSHIP_ERROR) {
          this.members = this.getMembersFromMembershipPayload()
        }

      }),
    )

  ngAfterViewInit(): void {
    this.onResize()
    // this.registerPreviewSideEffects()
  }

  @HostListener("window:resize")
  onResize(): void {
    const width = this.componentWidthElementRef?.nativeElement.getBoundingClientRect().width
    if (width <= 600) {
      this.flexDirection = "column"
    } else {
      this.flexDirection = "row"
    }
  }

  getMembersFromMembershipPayload(): ConfirmedMember[] {
    const members: ConfirmedMember[] = []
    const formValue = this.hoosierState.get("form").getRawValue() as HoosierForm
    const membershipPayload = formValue.membershipPayload
    members.push({
      membershipLabel: connectSuiteMembershipCodes.find(code => {
        return code.level === membershipPayload.membership.membershipLevel
          && code.rv === membershipPayload.membership.rv
      })?.label || "",
      fullName: membershipPayload.primary?.firstName + " " + membershipPayload.primary?.lastName + " " + membershipPayload.primary?.nameSuffix,
    })

    if (membershipPayload.associates?.length) {
      for (const associate of membershipPayload.associates) {
        members.push({
          membershipLabel: connectSuiteMembershipCodes.find(code => {
            return code.level === membershipPayload.membership.membershipLevel
              && code.rv === membershipPayload.membership.rv
          })?.label || "",
          fullName: associate.firstName + " " + associate.lastName + " " + associate.nameSuffix,
        })
      }
    }
    return members
  }

  getMembersFromMembershipResponse(membership: Membership): ConfirmedMember[] {
    const members: ConfirmedMember[] = []
    const primary = membership.primaryMember

    members.push({
      expirationDate: this.hoosierService.expirationDateFormatted(membership.attributes?.expDate),
      membershipLabel: connectSuiteMembershipCodes.find(code => code.membershipType === primary.attributes.membershipType)?.label || "",
      membershipNumber: this.hoosierService.membershipNumberFormatted(primary.attributes.membershipNumber),
      membershipUserId: primary.attributes.email,
      fullName: primary.attributes.firstName + " " + primary.attributes.lastName + " " + primary.attributes.nameSuffix,
    })
    if (membership.associateMember?.length) {
      /**
       * iterate over list of associates from response
       * pull email and name values from membershipPayload
       */
      for (const associate of membership.associateMember) {
        members.push({
          expirationDate: this.hoosierService.expirationDateFormatted(membership.attributes.expDate),
          membershipLabel: connectSuiteMembershipCodes.find(code => code.membershipType === associate.attributes.membershipType)?.label || "",
          membershipNumber: this.hoosierService.membershipNumberFormatted(associate.attributes.membershipNumber),
          membershipUserId: associate.attributes.email || "none",
          fullName: associate.attributes.firstName + " " + associate.attributes.lastName + " " + associate.attributes.nameSuffix,
        })
      }
    }
    return members
  }
}
