import { createReducer, on } from "@ngrx/store"
import { RequestStatus } from "../../../types/request-status"
import { MembershipActions } from "./membership.actions"
import { ClubApp } from "@aaa/emember/types"
import { MembershipState } from "./membership.models"

export const initialStateMembership: MembershipState = {
  initializeStatus: RequestStatus.UNSENT,
  zipcode: "",
  clubId: ClubApp.Empty,
  accountDetails: null,
  membershipNumber: null,
  requestStatus: RequestStatus.UNSENT,
  membershipError: null,
  membershipCodesMap: {},
  membershipCodes: [],
  membershipCards: [],
}

export const membershipReducer = createReducer(
  initialStateMembership,
  on(MembershipActions.initialize, (state) => ({ ...state, initializeStatus: RequestStatus.RUNNING })),
  on(MembershipActions.initializeSucceeded, (state, { env }) => ({
    ...state,
    initializeStatus: RequestStatus.SUCCESS,
    clubId: env.clubId || state.clubId,
    zipcode: state.zipcode || "",
  })),
  on(MembershipActions.load, (state, { membershipNumber }) => ({
    ...state,
    membershipNumber,
    membershipError: null,
    requestStatus: membershipNumber ? RequestStatus.RUNNING : state.requestStatus,
  })),
  on(MembershipActions.loadSucceeded, (state, { accountDetails }) => ({
    ...state,
    accountDetails,
    requestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipActions.loadFailed, (state, { error }) => ({
    ...state,
    accountDetails: null,
    membershipError: error,
    requestStatus: RequestStatus.FAILED,
  })),

  on(MembershipActions.login, (state, { payload }) => ({
    ...state,
    membershipNumber: payload.membershipNumber,
    accountDetails: null,
    membershipError: null,
    requestStatus: RequestStatus.RUNNING,
  })),
  on(MembershipActions.loginSucceeded, (state, { accountDetails }) => ({
    ...state,
    accountDetails,
    requestStatus: RequestStatus.SUCCESS,
  })),
  on(MembershipActions.loginFailed, (state, { error }) => ({
    ...state,
    membershipError: error,
    requestStatus: RequestStatus.FAILED,
  })),

  on(MembershipActions.setCodes, (state, { membershipCodes }) => ({
    ...state,
    membershipCodes: membershipCodes.map((membershipCode) => membershipCode.duesComponentCode),
    membershipCodesMap: membershipCodes.reduce(
      (acc, membershipCode) => ({ ...acc, [membershipCode.duesComponentCode]: membershipCode }),
      {},
    ),
  })),
  on(MembershipActions.setCards, (state, { membershipCards }) => ({ ...state, membershipCards })),
  on(MembershipActions.updateAccountDetails, (state, { accountDetails }) => ({ ...state, accountDetails })),
  on(MembershipActions.setZipcode, (state, { zipcode }) => ({ ...state, zipcode })),
  on(MembershipActions.logout, (state) => ({ ...state, membershipNumber: "", accountDetails: null })),
)
