import { Inject, Injectable } from "@angular/core"
import { DataReadService } from "./data-read"
import { GLOBAL_RX_STATE, GlobalState } from "./state"
import { RxState } from "@rx-angular/state"

@Injectable({
  providedIn: "root"
})
export class StorageService {

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private dataReadService: DataReadService,
  ) {
  }

  getFileName(filePath: string): string {
    const index = filePath.lastIndexOf("/")
    if (index === -1) {
      return filePath
    }
    if (index) {
      return filePath.substring(index)
    }
    return ""
  }

  getLibraryFilePath = (fileName: string): string => {
    return "/" + this.globalState.get("windowMetaData", "clubId") + "/multiblock/library/" + fileName
  }

  getRevisionFilePath = (): string => {
    return "/" + this.globalState.get("windowMetaData", "clubId") + "/multiblock/revisions/" + this.dataReadService.createId
  }

  getRevisionFileName = (fileName: string): string => {
    const index = fileName.lastIndexOf(".")
    if (index) {
      return this.dataReadService.createId + fileName.substring(index)
    }
    if (index === -1) {
      return this.dataReadService.createId
    }

    if (index === -1) {
      // return fileName + "--" + dimensions + "--" + this.dataReadService.createId
    }
    if (index >= 0) {
      // return fileName.substring(0, index) + "--" + dimensions + "--" + fileName.substring(index)
    }
    return ""
  }

  get fileDomain(): string {
    if (this.globalState.get("environment", "firebaseConfig", "projectId") === "avagate-wss-1") {
      return "https://www.wssfiles.com"
    } else {
      return "https://storage.googleapis.com/" + this.globalState.get("environment","firebaseConfig", "storageBucket")
    }
  }

  getFileUrl(filePath: string): string {
    if (filePath) {
      return this.fileDomain + filePath
    }
    return ""
  }

}
