<i
  [style.font-size]="iconSize + 'px'"
  nz-icon
  [nzTheme]="iconTheme"
  [nzType]="iconType"
  [hidden]="false"
  nz-tooltip
  [nzTooltipTitle]="tooltipTitle"
  [nzTooltipPlacement]="tooltipPlacement"
  (click)="tooltipClicked.emit()"
></i>
