import { Inject, Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { LOCAL_STORAGE, LOCATION } from "@ng-web-apis/common"
import { GLOBAL_RX_STATE, GlobalState } from "./state"
import { RxState } from "@rx-angular/state"
import { take, timeout } from "rxjs/operators"
import { WebEnvironment } from "../interfaces/window"
import { HOOSIER_RX_STATE, HoosierState } from "../modules/join-renew/hoosier/hoosier.service"
import { Store } from "@ngrx/store"
import { tap } from "rxjs"
import { RxEffects } from "@rx-angular/state/effects"
import { AppStore } from "@aaa/emember/store-types"
import { getPricePreviewMemberQuantity } from "@aaa/emember/store-price-preview"

/**
 * Tokens should follow the format of normal html elements, using [ and ] instead of < and >.
 * Examples:
 * [token src="email"]
 * [ token src="fullUrl"]
 * [token src="fullUrl" action="value" level="premier" code="ep" quantity="1"]
 *
 * [token src="join-renew" action="join-cost" level="premier" code="ep" quantity="1"]
 * */

export interface HTML {
  children: HTML[]
  id: string
  innerHtml: string
  level: number
  string: string
}

@Injectable({
  providedIn: "root"
})
export class TokenService {
  // svgCache: { [key: string]: string } = {}
  private readonly tokenMap: { [key: string]: string } = {}
  private allowedElements = [
    "token",
    // "h1",
    "h2", "h3", "h4", "h5", "h6",
    "blockquote",
    "br",
    "a",
    "img",
    "div", "span", "p",
    "u", "em", "strong", "b",
    "ul", "li", "ol",
    "sub", "sup",
    "i", "svg", "title", "path", "circle", "g"
  ]
  private joinPriceMemberQuantity: number = 1

  constructor(
    @Inject(LOCAL_STORAGE)
    private localStorage: Storage,
    @Inject(LOCATION)
    private location: Location,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private httpClient: HttpClient,
    private store: Store<AppStore>,
    private rxEffects: RxEffects
  ) {
    const user = globalState.get("windowMetaData", "user")
    this.tokenMap = {
      "firstname": user.firstName,
      "lastname": user.lastName,
      "email": user.email,
      "zipcode": user.zipcode,
      "membernumber": user.memberNumber,
      "fullurl": this.location.href
    }
    this.rxEffects.register(this.store.select(getPricePreviewMemberQuantity).pipe(tap((memberQuantity) => (this.joinPriceMemberQuantity = memberQuantity))))
  }

  tokenize(string: string, width?: number, align?: string, color?: string): string {
    if (string) {
      let tokenizedString = ""
      tokenizedString = this.preprocessTokens(string)
      tokenizedString = this.stripHTML(tokenizedString)
      tokenizedString = this.processTokens(tokenizedString)

      tokenizedString = this.resizeFonts(tokenizedString, width)
      tokenizedString = this.align(tokenizedString, align)
      tokenizedString = this.color(tokenizedString, color)

      return tokenizedString
    }
    return string

    /**
     * convert whitelisted html-elements to token-element * done
     * strip all html elements * done
     * replace tokens with replacements * done
     * strip non-whitelisted attributes * TODO
     * strip non-whitelisted classNames * TODO
     * evaluate value of src attributes, convert if needed * TODO
     * (to relative url or as an angular route based on app mode or custom-element mode)
     * convert token-elements back into html-elements * done
     */
  }

  /**
   * tokenize methods
   */

  private preprocessTokens(string: string): string {
    /**
     * regex to extract attributes (class, style, etc)
     * process the attributes and their values against their own whitelists
     */
    if (string) {
      return string
        .replace("&lt;", "<")
        .replace("&gt;", ">")
        .replace(
          /<([^>]*)>/g,
          (match, $1) => this.htmlToToken($1)
        )
    }
    return string
  }

  stripHTML(string: string): string {
    const parser = new DOMParser()
    const htmlDoc = parser.parseFromString(string, "text/html")
    const div = htmlDoc.createElement("div")
    div.innerHTML = string
    return div.textContent || ""
  }

  processTokens(string: string): string {
    if (string) {

      /**
       * async/await version of string.replace
       */
      const iconTokens = string.match(/\[icon([^\]]*)\]/g) || []
      for (const iconToken of iconTokens) {
        // console.log(iconTokens)
        // console.log(iconToken)
        // const iconSrc = iconToken.match(/\[icon\s+src="([#0-9a-zA-Z-;_/\s]*)"\]/)
        const iconHtml = this.processIconToken(iconToken)
        // console.log(iconHtml)
        string = string.replace(iconToken, iconHtml)
      }

      return string
        .replace(
          /\[token\s+[.a-zA-Z-:_\d\s='"]*src=['"]([.a-zA-Z-:_/\d]*)['"][.a-zA-Z-:_\d\s='"]*\]/g,
          (fullToken, src) => this.matchToken(fullToken, src)
        )
        /**
         * finally, convert all remaining [tokens] matching allowedElementsList to <html>
         */
        .replace(
          /\[([^\]]*)\]/g,
          (fullToken, tokenContents) => this.tokenToHtml(fullToken, tokenContents)
        )
    }
    return string
  }

  private resizeFonts(string: string, width?: number): string {
/*
    if (width !== undefined && width < 900) {
      return string.replace(
        /font-size:\s*([0-9]*)px/g,
        (match, $1) => "font-size: " + this.fontMap[$1] + "px"
      )
    }
*/
    return string
  }

  private align(string: string, align?: string): string {
    if (align) {
      return string.replace(
        /text-align:\s*[a-z]*[^a-z]/g,
        () => "text-align: " + align
      )
    }
    return string
  }

  private color(string: string, color?: string): string {
    if (color) {
      return string.replace(
        /color:\s*[#a-z]*^\s^;/g,
        () => "color: " + color
      )
    }
    return string
  }

  /**
   * helper methods
   */

  private htmlToToken(string: string): string {
    if (this.allowedElements.some(elementName => string.startsWith(elementName) || string.startsWith("/" + elementName))) {
      return "[" + string + "]"
    }
    /**
     * skip this element if not found in allowedElements list
     * the domParser will strip it out, leaving the textContent in place
     * I suppose we could eliminate it here, but there may be weird edge cases to consider
     */
    return "<" + string + ">"
  }

  private processIconToken(string: string): string {
    const iconType = string.match(/type="([^"]*)"/)?.[1] || ""
    const iconName = string.match(/name="([^"]*)"/)?.[1] || ""
    const iconSize = string.match(/size="([^"]*)"/)?.[1] || ""
    const iconColor = string.match(/color="([^"]*)"/)?.[1] || ""
    const iconOutline = string.match(/outline="([^"]*)"/)?.[1] || ""

    /**
     * similar icon path modification in custom-elements/multiblock/component.ts
     */
    let domain = ""
    const webEnv = this.globalState.get("windowMetaData", "webEnv").toUpperCase()
    if (webEnv === WebEnvironment.PROD || webEnv === WebEnvironment.TEST) {
      domain = "https://" + webEnv.toLowerCase() + ".millisite.com/"
    }
    const iconFilePath = domain + "assets/" + iconType + "/" + iconName + ".svg"

    let svg = ""
    const svgTransparent = "" +
      "<svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" viewBox=\"-500 -500 1000 1000\" fill=\"transparent\">" +
      "<g><rect stroke=\"none\" x=\"-500\" y=\"-500\" width=\"1000\" height=\"1000\"/></g>" +
      "</svg>"
    const svgItem = this.localStorage.getItem("svg")
    if (iconType && iconName && svgItem) {
      const svgObject = JSON.parse(svgItem) || {}
      const svgFromCache = svgObject?.[iconType]?.[iconName]
      svg = svgFromCache || svg
      // svg = this.svgCache[iconType + "-" + iconName]
      if (!svgFromCache) {
        svg = svgTransparent
        this.httpClient.get(iconFilePath, { responseType: "text" })
          .pipe(
            take(1),
            timeout(5000)
          )
          .subscribe(response => {
            // console.log(response)
            if (response.startsWith("<svg ")) {
              /**
               * Only set svg from response if text response starts with "<svg"
               *
               * Other responses include:
               *   - full html page from millisite.com when icon is not found
               *     This is expected in custom element mode, for example, while typing icon name
               *   - 404 http error in appMode, the expected response when icon is not found
               */
              svg = response
              svgObject[iconType] = svgObject[iconType] || {}
              svgObject[iconType][iconName] = svg
              this.localStorage.setItem("svg", JSON.stringify(svgObject))
              this.globalState.set("tokenRefresh", () => Date.now())
            }

          })

        /*
                try {
                  svg = await firstValueFrom(this.httpClient
                    .get(iconFilePath, { responseType: "text" })
                    .pipe(timeout(500)),
                    { defaultValue: "timed out" }
                  )
                } catch (e) {
                  /!**
                   * Hiding the console error message.
                   * In appMode this httpRequest is expected to fail when typing the icon name
                   * the user will see icon appear when they complete the filename correctly.
                   *!/
                }
        */
      }

      if (svgFromCache) {
        /**
         * add color as fill if color is provided
         * does not work with fill attribute of nested elements
         * does work with icomoon svgs
         */
        if (iconColor) {
          svg = svg.replace(/\sfill="[0-9a-z-]*"/g, "")
          svg = svg.replace("<svg ", "<svg fill=\"" + iconColor + "\"")
        }
      }
    }

    /**
     * add 1em height and width so that they will resize with a wrapper font-size
     */
    svg = svg.replace("<svg ", "<svg height=\"1em\" width=\"1em\"")

    let outlineStyles = ""
    if (iconOutline === "circle") {
      outlineStyles = `border: solid 2px #666; border-radius: 50%; padding: 20px;`
    }

    if (iconType && iconName && svg) {
      return `<i nz-icon nzType="`
        + iconType + `:` + iconName
        + `" style="`
        + `font-size: ` + iconSize + `;`
        + outlineStyles
        + `" class="anticon anticon-`
        + iconType + `:` + iconName
        + `">`
        + svg
        + `</i>`
    }

    if (this.allowedElements.some(elementName => string.startsWith(elementName) || string.startsWith("/" + elementName))) {
      return "<" + string + ">"
    }
    /**
     * do not strip this set of square brackets if does not resemble an icon definition (iconCategory:iconName)
     */
    return "[" + string + "]"
  }

  private matchToken(fullToken: string, src: string): string {
    switch (src.toLowerCase()) {
      case "join-price":
        return this.joinPriceHTML(fullToken) || ""
      default:
        return this.tokenMap[src.toLowerCase()] || ""
    }
  }

  joinPriceHTML(fullToken: string): string {
    /**
     * when the "attribute" does not exist in the fullToken, the fullToken.replace() will return the fullToken
     * appropriate checks are required if the fullToken string would cause a failure
     * examples:
     *    using .includes() for "level" and "price"
     *    the use of Number for "quantity" - this returns NaN (and is treated as false) if the result is the fullToken string
     *    converting to a boolean for "rv"
     */

    let attribute: string
    let regex: RegExp
    let regexPattern: string

    attribute = "level"
    let level: string = ""
    if (fullToken.includes(" " + attribute + "=")) {
      regexPattern = "\\[token\\s+[.a-zA-Z-:_\\d\\s='\"]*" + attribute + "=['\"]([.a-zA-Z-:_/\\d]*)['\"][.a-zA-Z-:_\\d\\s='\"]*\\]"
      regex = new RegExp(regexPattern, "g")
      level = fullToken.replace(regex, (fullToken, value) => value).toLowerCase()
    }

    attribute = "rv"
    regexPattern = "\\[token\\s+[.a-zA-Z-:_\\d\\s='\"]*" + attribute + "=['\"]([.a-zA-Z-:_/\\d]*)['\"][.a-zA-Z-:_\\d\\s='\"]*\\]"
    regex = new RegExp(regexPattern, "g")
    const rv: boolean = fullToken.replace(regex, (fullToken, value) => value).toLowerCase() === "true"

    /**
     * TODO: namespace this quantity functionality for hoosier with clubId === "023"
     */
    attribute = "quantity"
    regexPattern = "\\[token\\s+[.a-zA-Z-:_\\d\\s='\"]*" + attribute + "=['\"]([.a-zA-Z-:_/\\d]*)['\"][.a-zA-Z-:_\\d\\s='\"]*\\]"
    regex = new RegExp(regexPattern, "g")
    const quantity: number = Number(fullToken.replace(regex, (fullToken, value) => value))
      || this.joinPriceMemberQuantity
      || 1

    attribute = "price"
    let defaultPrice: string = ""
    if (fullToken.includes(" " + attribute + "=")) {
      regexPattern = "\\[token\\s+[.a-zA-Z-:_\\d\\s='\"]*" + attribute + "=['\"]([.a-zA-Z-:_/\\d]*)['\"][.a-zA-Z-:_\\d\\s='\"]*\\]"
      regex = new RegExp(regexPattern, "g")
      defaultPrice = fullToken.replace(regex, (fullToken, value) => value).toLowerCase()
    }

    /**
     * TODO: namespace this price functionality for hoosier with clubId === "023"
     */
    const pricePreviewsFunction = this.hoosierState.get("pricePreviewsFunction")
    const pricePreviewsResponse = this.hoosierState.get("PRICE_PREVIEWS")?.response
    let price: number | undefined
    if (pricePreviewsFunction && pricePreviewsResponse) {
      price = pricePreviewsFunction(pricePreviewsResponse, level, rv, quantity)
    }

    /**
     * checking for null because price could be a number equal to 0...which is also false, could also be negative (maybe?)
     */
    if (price === undefined) {
      if (level && defaultPrice) {
        return level[0].toUpperCase() + level.slice(1).toLowerCase() + (rv ? "-RV" : "") + ": " +
          "$" + parseFloat(defaultPrice).toFixed(2).toString() +
          " Per Year"
      }
      return ""
    }
    if (level) {
      return level[0].toUpperCase() + level.slice(1).toLowerCase() + (rv ? "-RV" : "") + ": " +
        "$" + price.toFixed(2).toString() +
        " Per Year"
    }
    return ""
  }

  private tokenToHtml(fullToken: string, tokenContents: string): string {
    /**
     * convert [] to <> if tokenContents is found in allowedElements
     */
    if (this.allowedElements.some(elementName => tokenContents.startsWith(elementName) || tokenContents.startsWith("/" + elementName))) {
      return "<" + tokenContents + ">"
    }
    /**
     * do not strip this set of square brackets if tokenContents are not found in allowedElements list
     */
    return fullToken
  }

  get fontMap(): { [key: number]: number } {
    return {
      13: 15,
      17: 23,
      24: 24,
      32: 28,
      44: 32,
      60: 48
    }
  }


  private parseString(string: string): string {
    /**
     * Problem: how to separate <a> tags and load them as components which may need to use router instead of href
     * Turns out we don't need to do it this way, instead we act on the click event after the link is clicked
     * leaving code here as an example of setting up DOMParser, can delete
     *
     * create temporary node (not in browser)
     */
    const parser = new DOMParser()
    const div = parser.parseFromString("", "text/html").createElement("div")
    // console.log(div)

    div.innerHTML = string
    // console.log(div.childNodes)

    const links = [].slice.call(div.querySelectorAll("a"))
    // console.log(links)


    const nodes = [].slice.call(div.childNodes)
    // console.log(nodes)

    for (const node of nodes) {
      // console.log(node)

/*
      const childNodes = [].slice.call(node.childNodes)
      for (const childNode of childNodes) {
        // console.log(childNode)
      }
*/
    }

    return string
  }
}

/**
 * html samples for regex testing:
 *
 * <div class="class1 class2" style="anystyle: value; anotherStyle: value">text</div>
 * <div>text</div>  <div>text</div>
 * <a href="test.com" class="class1 class2"  style="anystyle: value; anotherStyle: value">text</a>
 * <img src="img.com">
 * <img src="img.com"/>
 * <img src="img.com" />
 * <br>
 * <br/>
 * <br />
 *
 *
 */

/**
 * token samples
 *
 * [0]
 * [ token src="join-renew"]]
 * [ token src="join-renew" field="join-cost" level="basic"]
 */

/**
 * regex samples
 *
 * element
 * gets enclosed html elements, enclosed with "<" and ">": |<element anything>|
 * <[^>]*>
 *
 * attribute with value from element
 * any attribute with it's values (should be used inside the html regex result: |attrName="value"|
 * [\s]([a-z]+="[^"]*")
 *
 * value from attribute
 * text from within the quotation marks ""
 * "([^"]*)"
 *
 * className from (class attribute) value
 * individual classNames from within the class quotation marks class=""
 * [^\s]*
 *
 */

/**
 * icon html
 *
 <i
 nz-icon nzType="icomoon:accessibility"
 style="padding-top: 2px"
 ></i>
 */

/**
 * object element
 + `<object type="image/svg+xml" data="`
 + iconFilePath
 + `"></object>`
 *
 */

/**
 * image element
 + `<img src="`
 + iconFilePath
 + `"></img>`
 *
 */
