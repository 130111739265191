import { Inject, Injectable } from "@angular/core"
import { RxState } from "@rx-angular/state"
import { GLOBAL_RX_STATE, GlobalState } from "../../../../services/state"
import { Flow, HOOSIER_RX_STATE, HoosierState } from "../hoosier.service"
import {
  CardFormat, ConnectSuiteMembershipLevel,
  MembershipConnectSuiteRecostValidateJoinEventPayload, MembershipPayloadAssociate,
} from "@aaa/interface-joinRenew-membership-membershipConnectSuite"

@Injectable({
  providedIn: "root"
})
export class ConnectsuiteService {

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
  ) {
  }

  get donorMembershipItem(): MembershipConnectSuiteRecostValidateJoinEventPayload["donorMembership"] {
    return {
      address: {
        attributes: {
          country: "",
          address1: "",
          address2: "",
          cityName: "",
          stateProv: "IN",
          postalCode: "",
        }
      },
      cellPhone: "",
      homePhone: "",
      email: "",
      firstName: "",
      lastName: "",
      membershipNumber: "",
      middleIntial: "",
      nameSuffix: "",
      title: "",
      giftOptions: {
        giftFrom: "",
        giftMessage: "",
        giftTo: "",
        holidayGift: false,
        renewalType: "P", // "P" | "O",
        sendMbrCardTo: "D" // "P" | "D"
      }
    }
  }

  get membershipItem(): MembershipConnectSuiteRecostValidateJoinEventPayload["membership"] {
    return {
      address: this.addressItem,
      associateCount: 0,
      autoRenew: this.hoosierState.get("activeFlow") !== Flow.GIFT,
      // autoRenew is false only for GIFT Flow, this presumes that membershipPayload is reset when transitioning to GIFT Flow
      cardFormat: CardFormat.PLASTIC,
      couponCode: "",
      membershipLevel: ConnectSuiteMembershipLevel.CLASSIC,
      promoCode: "",
      rv: false,
    }
  }

  get primaryItem(): MembershipConnectSuiteRecostValidateJoinEventPayload["primary"] {
    return {
      cellPhone: "",
      businessPhone: "",
      dob: "",
      email: "",
      firstName: "",
      homePhone: "",
      lastName: "",
      middleIntial: "",
      nameSuffix: "",
      sex: "",
      title: ""
    }
  }

  get associateItem(): MembershipPayloadAssociate {
    return {
      dob: "",
      email: "",
      firstName: "",
      lastName: "",
      membershipNumber: "",
      middleIntial: "",
      nameSuffix: "",
      removeAssociate: false,
    }
  }

  get addressItem(): MembershipConnectSuiteRecostValidateJoinEventPayload["membership"]["address"] {
    return {
      address1: "",
      address2: "",
      cityName: "",
      postalCode: "", // this.globalState.get("userSession", "zipcode") || "",
      StateProv: "IN" // required and pre-filled
    }
  }

}
