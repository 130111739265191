<ava-multiblock></ava-multiblock>
<ng-container *ngIf="showLiveChatForMN">
  <div style="display:inline; position:fixed; z-index:9999; bottom:10px; right:30px; width: 100%">
    <img
      style="visibility: hidden; position:absolute; float:right; right:10px; bottom:20px; z-index:9999; height:75px; width: 75px;"
      id="kpd_koopidtag"
      data-kpdprovemail=6658418484740096@aaaminneapolis.com
      data-kpdguest="true"
      data-kpdembedded="true"
      data-kpdtag="VC Test_102697"
      src="https://us-east.dx.dialpad.com/kpd-static/providers/1827645/images/bot-icon.png"
      alt=""
    />
  </div>
</ng-container>
