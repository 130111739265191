<div #componentWidth>
  <ng-container *ngIf="globalState$ | async as g">
    <ng-container *ngIf="hoosierState$ | async as h">

      <button
        tabindex="0"
        nz-button nz-type="default"
        class="ant-btn-dark-blue"
        style="color: white; font-size: 24px; cursor: default; padding: 0; width: 100%"
        [style.background-color]="backgroundColors[card.newLevel.level]"
      >
        <div
          *ngIf="card.action === Action.RENEW"
          style="cursor: pointer"
          (click)="renew($event)"
        >
          <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
        </div>
        <div
          *ngIf="card.action === Action.UPGRADE"
          style="cursor: pointer"
          (click)="upgrade($event, card.newLevel)"
        >
          <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
        </div>
        <div
          *ngIf="card.action === Action.UPGRADE_NOT_ALLOWED_WITH_RV"
          style="cursor: pointer"

          nz-popover
          [nzPopoverContent]="downgradePopConfirmTitle"
          nzPopoverTrigger="click"
        >
          <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
        </div>
        <div
          *ngIf="card.action === Action.DOWNGRADE"
          style="cursor: pointer"

          nz-tooltip=""
          [nzTooltipTitle]="downgradePopConfirmTitle"
          [nzTooltipTrigger]="'click'"
          [nzTooltipArrowPointAtCenter]="true"
          (click)="$event.stopPropagation()"
        >
          <!-- nz-popconfirm-->
          <!-- nzPopconfirmTrigger="click"-->
          <!-- nzOkText="Yes, I confirm"-->
          <!-- [nzPopconfirmTitle]="downgradePopConfirmTitle"-->
          <!-- [nzIcon]="iconTemplate"-->
          <!-- nzOkType="ghost"-->
          <!-- (nzOnConfirm)="downgrade(card.newLevel)"-->
          <!-- >-->

          <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
        </div>
        <div
          *ngIf="card.action === Action.NONE"
          style="cursor: default"
        >
          <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
        </div>
      </button>

      <ng-template #iconTemplate></ng-template>

      <ng-template #cardTemplate>
        <div style="width: 100%; display: flex; gap: 10px; padding: 20px">
          <div style="flex: 1; text-align: left">
            <div style="font-weight: 600; font-size: 36px">
              {{ card.description.title }}
            </div>
            <div style="font-weight: 300; font-size: 20px">
              <span style="font-weight: 500">
                {{ card.description.towingMiles }}
              </span>
              of towing
            </div>
            <div style="font-weight: 300; font-size: 20px">
              {{ card.price.primary | currency }}
              Per Year
            </div>
            <div style="font-weight: 300; font-size: 14px">
              plus
              {{ card.price.associate | currency }}
              Per Associate
            </div>
          </div>
          <div
            style="display: flex; flex-direction: column; justify-content: flex-end; text-align: right; font-weight: 300; font-size: 17px"
          >
            <ng-container *ngIf="!narrowView" [ngTemplateOutlet]="currentLevelSection"></ng-container>
            <div
              #downgradeSection
              *ngIf="!narrowView && card.action === Action.DOWNGRADE"
            >
              <div style="font-size: 68px; text-align: center; color: white">
                <i nz-icon nzType="icons:arrow-down"></i>
              </div>
              <div
                *ngIf="selected && h.VALIDATE_LEVEL_KEY"
                style="text-align: center; color: white"
              >
                <nz-spin nzSize="large" nzSimple></nz-spin>
              </div>
              <div>
                Downgrade
              </div>
            </div>
            <div
              #upgradeSection
              *ngIf="!narrowView && (card.action === Action.UPGRADE || card.action === Action.UPGRADE_NOT_ALLOWED_WITH_RV)"
            >
              <div
                *ngIf="!selected || !h.VALIDATE_LEVEL_KEY"
                style="font-size: 68px; text-align: center; color: white; display: grid; place-content: center; width: 68px; height: 68px"
              >
                <i nz-icon nzType="icons:arrow-up"></i>
              </div>
              <div
                *ngIf="selected && h.VALIDATE_LEVEL_KEY"
                style="text-align: center; color: white; display: grid; place-content: center; width: 68px; height: 68px; line-height: 1"
              >
                <nz-spin nzSize="large" nzSimple></nz-spin>
              </div>
              <div>
                Upgrade
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="narrowView" [ngTemplateOutlet]="currentLevelSection"></ng-container>
        <div
          #downgradeSection
          *ngIf="narrowView && card.action === Action.DOWNGRADE"
          style="display: flex; gap: 10px; align-items: center; justify-content: center"
        >
          <div>
            Downgrade
          </div>
          <div style="font-size: 32px; text-align: center; color: white">
            <i nz-icon nzType="icons:arrow-down"></i>
          </div>
          <div
            *ngIf="selected && h.VALIDATE_LEVEL_KEY"
            style="text-align: center; color: white"
          >
            <nz-spin nzSize="large" nzSimple></nz-spin>
          </div>
        </div>
        <div
          #upgradeSection
          *ngIf="narrowView && (card.action === Action.UPGRADE || card.action === Action.UPGRADE_NOT_ALLOWED_WITH_RV)"
          style="display: flex; gap: 10px; align-items: center; justify-content: center"
        >
          <div>
            Upgrade
          </div>
          <div
            *ngIf="!selected || !h.VALIDATE_LEVEL_KEY"
            style="font-size: 32px; text-align: center; color: white"
          >
            <i nz-icon nzType="icons:arrow-up"></i>
          </div>
          <div
            *ngIf="selected && h.VALIDATE_LEVEL_KEY"
            style="text-align: center; color: white"
          >
            <nz-spin nzSize="large" nzSimple></nz-spin>
          </div>
        </div>
      </ng-template>

      <ng-template #currentLevelSection>
        <div
          *ngIf="card.action === Action.NONE || card.action === Action.RENEW"
          style="font-weight: 300; font-size: 17px"
        >
          <div>
            Current Level
          </div>
          <div>
            {{ h.accountDetails.statusText }}
          </div>
        </div>
      </ng-template>


    </ng-container>
  </ng-container>
</div>
