<nz-form-item
  *ngIf="formGroup && options"
  [formGroup]="formGroup"
  style="margin: 0"
>
  <nz-form-control
    [nzErrorTip]="options.tip[ValidateStatus.ERROR]"
    [nzSuccessTip]="options.tip[ValidateStatus.SUCCESS]"
    [nzWarningTip]="options.tip[ValidateStatus.WARNING]"
    [nzValidatingTip]="options.tip[ValidateStatus.PENDING]"
    [nzHasFeedback]="true"
    [nzValidateStatus]="options.validateStatus"
    [nzDisableAutoTips]="true"
  >
    <nz-select
      [formControlName]="options.fieldName"
      [nzShowArrow]="true"
      [nzAllowClear]="options.allowClear"
      nzSize="default"
      [nzDropdownMatchSelectWidth]="false"
      [nzPlaceHolder]="options.placeholder"
      style="width: 100%"
    >
      <nz-option
        *ngFor="let option of options.selectOptions"
        [nzValue]="option.value"
        [nzLabel]="option.label"
      ></nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>
