import { Component, Inject, Input, OnInit } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { SelectOptions } from "../form-field-options/options-interfaces"
import { HOOSIER_RX_STATE, HoosierService, HoosierState, View } from "../hoosier.service"
import { ValidateStatus } from "../../join-renew.service"
import { combineLatestWith, Observable, of, tap } from "rxjs"
import { RxEffects } from "@rx-angular/state/effects"
import { RxState } from "@rx-angular/state"

@Component({
  selector: "ava-hoosier-select",
  templateUrl: "./select.html",
})
export class SelectComponent implements OnInit {
  @Input() index: number | undefined
  @Input() options: SelectOptions | undefined
  formGroup: FormGroup | undefined
  formControl: FormControl | undefined
  ValidateStatus = ValidateStatus

  constructor(
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private hoosierService: HoosierService,
  ) {
    rxEffects.register(this.toggleValidators$)
  }

  toggleValidators$ = this.hoosierState.select("showAllValidationErrors")
    .pipe(
      combineLatestWith(
        this.hoosierState.select("showActiveGroupValidationErrors"),
        this.hoosierState.select("activeView"),
      ),
      tap(([showAllValidationErrors, showActiveGroupValidationErrors, activeView]: [boolean, boolean, View | null]) => {
        if (showAllValidationErrors || showActiveGroupValidationErrors) {
          if (this.formControl && this.options?.validators.length) {
            if (showAllValidationErrors) {
              this.formControl.addValidators(this.options.validators)
              this.formControl.addAsyncValidators(this.options.asyncValidators)
              this.formControl.updateValueAndValidity()
            }
            if (!showAllValidationErrors) {
              this.formControl.removeValidators(this.options.validators)
              this.formControl.removeAsyncValidators(this.options.asyncValidators)
            }

            if (this.options.validateGroup.find(validateGroupItem => activeView === validateGroupItem)) {
              if (showActiveGroupValidationErrors) {
                this.formControl.addValidators(this.options.validators)
                this.formControl.addAsyncValidators(this.options.asyncValidators)
                this.formControl.updateValueAndValidity()
              }
              if (!showActiveGroupValidationErrors) {
                this.formControl.removeValidators(this.options.validators)
                this.formControl.removeAsyncValidators(this.options.asyncValidators)
              }

            }
          }
        }
      })
    )

  get preventNullValue$(): Observable<any> {
    return this.formControl?.valueChanges
      .pipe(
        tap(changes => {
          if (changes === null) {
            this.formControl?.setValue("", { emitEvent: false })
          }
        })
      ) || of(null)
  }

  ngOnInit(): void {
    if (this.options) {
      const fieldPath = this.index === undefined
        ? this.options.fieldPath
        : [...this.options.fieldPath, this.index.toString()]

      this.formGroup = this.hoosierService.form.get(fieldPath) as FormGroup || this.hoosierService.form as FormGroup
      this.formControl = this.formGroup.get(this.options.fieldName) as FormControl
      this.rxEffects.register(this.preventNullValue$)
    }
  }

}
