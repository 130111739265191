import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { HoosierComponent } from "./hoosier.component"
import { SharedModule } from "../../shared.module"

import { NzAffixModule } from "ng-zorro-antd/affix"
import { NzAlertModule } from "ng-zorro-antd/alert"
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete"
import { NzButtonModule } from "ng-zorro-antd/button"
import { NzCheckboxModule } from "ng-zorro-antd/checkbox"
import { NzCodeEditorModule } from "ng-zorro-antd/code-editor"
import { NzCollapseModule } from "ng-zorro-antd/collapse"
import { NzDividerModule } from "ng-zorro-antd/divider"
import { NzFormModule } from "ng-zorro-antd/form"
import { NzIconModule } from "ng-zorro-antd/icon"
import { NzInputModule } from "ng-zorro-antd/input"
import { NzRadioModule } from "ng-zorro-antd/radio"
import { NzSelectModule } from "ng-zorro-antd/select"
import { NzSkeletonModule } from "ng-zorro-antd/skeleton"
import { NzToolTipModule } from "ng-zorro-antd/tooltip"

import { AlertComponent } from "./components/common/alert";
import { AlertsComponent } from "./components/common/alerts"
import { AssociatesComponent } from "./components/common/associates"
import { AutoRenewComponent } from "./components/common/auto-renew"
import { BillingInfoComponent } from "./components/common/billing-info"
import { ConfirmationJoinComponent } from "./components/common/confirmation-join"
import { CostsSummaryComponent } from "./components/common/costs-summary"
import { DonorInfoComponent } from "./components/common/donor-info"
import { FinancialComponent } from "./components/account/financial"
import { GiftOptionsComponent } from "./components/common/gift-options"
import { InformationComponent } from "./components/account/information"
import { InsuranceComponent } from "./components/account/insurance"
import { MemberInfoComponent } from "./components/common/member-info"
import { MembershipComponent } from "./components/account/membership"
import { PaymentComponent } from "./components/common/payment"
import { PaymentFlexMicroFormComponent } from "./components/common/payment-flex-micro-form"
import { PromoCodeComponent } from "./components/common/promo-code"
import { PromptRenewComponent } from "./components/common/prompt-renew"
import { PromptRoadsideAssistanceComponent } from "./components/common/prompt-roadside-assistance"
import { PromptRvCoverageComponent } from "./components/common/prompt-rv-coverage"
import { SidebarSummaryComponent } from "./components/common/sidebar-summary"
import { StatusComponent } from "./components/account/status"
import { SummaryComponent } from "./components/common/summary"
import { TravelComponent } from "./components/account/travel"

import { AccountComponent } from "./components/flows/account"
import { JoinComponent } from "./components/flows/join"
import { RenewComponent } from "./components/flows/renew"

import { CheckboxComponent } from "./form-fields/checkbox"
import { InputComponent } from "./form-fields/input"
import { RadioComponent } from "./form-fields/radio"
import { SelectComponent } from "./form-fields/select"
import { TextareaComponent } from "./form-fields/textarea"

import { MemberLookupComponent } from "./multiblocks/member-lookup/view"
import { SectionComponent } from "./components/account/section"
import { GuestComponent } from "./components/account/guest"
import { HomeComponent } from "./components/account/home"

import { AssociatesComponent as MembershipAssociatesComponent } from "./components/account-membership/associates"
import { AutoRenewComponent as MembershipAutoRenewComponent } from "./components/account-membership/auto-renew"
import { CancelComponent as MembershipCancelComponent } from "./components/account-membership/cancel"
import { CardServicesComponent as MembershipCardServicesComponent } from "./components/account-membership/card-services"
import { LevelCardComponent as MembershipLevelCardComponent } from "./components/account-membership/level-card"
import { LevelComponent as MembershipLevelComponent } from "./components/account-membership/level"

import { AddressComponent as InformationAddressComponent } from "./components/account-information/address"
import { EmailComponent as InformationEmailComponent } from "./components/account-information/email"
import { PasswordComponent as InformationPasswordComponent } from "./components/account-information/password"
import { PhoneComponent as InformationPhoneComponent } from "./components/account-information/phone"
import { MembershipCardComponent } from "./components/cards/membership-card"
import { NzSpinModule } from "ng-zorro-antd/spin"
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm"
import { CardGiftComponent } from "./components/cards/gift"
import { ConfirmationDetailsComponent } from "./components/common/confirmation-details"
import { NzPopoverModule } from "ng-zorro-antd/popover"
import { MobileAppRenewComponent } from "./components/flows/mobile-app-renew"

@NgModule({
  declarations: [
    AlertComponent,
    AlertsComponent,
    AssociatesComponent,
    AutoRenewComponent,
    AccountComponent,
    BillingInfoComponent,
    CardGiftComponent,
    CheckboxComponent,
    ConfirmationDetailsComponent,
    ConfirmationJoinComponent,
    CostsSummaryComponent,
    DonorInfoComponent,
    FinancialComponent,
    GiftOptionsComponent,
    GuestComponent,
    HomeComponent,
    HoosierComponent,
    InformationAddressComponent,
    InformationComponent,
    InformationEmailComponent,
    InformationPasswordComponent,
    InformationPhoneComponent,
    InputComponent,
    InsuranceComponent,
    JoinComponent,
    MemberInfoComponent,
    MemberLookupComponent,
    MembershipAssociatesComponent,
    MembershipAutoRenewComponent,
    MembershipCancelComponent,
    MembershipCardComponent,
    MembershipCardServicesComponent,
    MembershipLevelCardComponent,
    MembershipLevelComponent,
    MembershipComponent,
    PaymentComponent,
    PaymentFlexMicroFormComponent,
    PromoCodeComponent,
    PromptRenewComponent,
    PromptRoadsideAssistanceComponent,
    PromptRvCoverageComponent,
    RenewComponent,
    RadioComponent,
    SectionComponent,
    SelectComponent,
    SidebarSummaryComponent,
    StatusComponent,
    SummaryComponent,
    TextareaComponent,
    TravelComponent,
    MobileAppRenewComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    FormsModule,
    NzAffixModule,
    NzAlertModule,
    NzAutocompleteModule,
    NzButtonModule,
    NzCheckboxModule,
    NzCodeEditorModule,
    NzCollapseModule,
    NzDividerModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzRadioModule,
    NzSelectModule,
    NzSkeletonModule,
    NzToolTipModule,
    ReactiveFormsModule,
    SharedModule,
    NzSpinModule,
    NzPopconfirmModule,
    NzPopoverModule,
  ],
  providers: []
})
export class HoosierModule {
}
