import { PricePreview } from "../../types/types"
import { range } from "../../utils/range"
import {
  MembershipDuesComponent,
  MembershipOffering,
  MMembershipCode,
  MMembershipOffering,
} from "@aaa/interface-joinRenew-membership-membershipM"

const getMedicalOffer = (offers: MMembershipOffering[]): MembershipDuesComponent | null => {
  if (!offers.length) {
    return null
  }

  const result = offers
    .map((item) => {
      return item.DuesComponent.find((offer) => offer.$.componentCode.startsWith("MED"))
    })
    .filter((item) => !!item)

  if (result.length) {
    return result[0] || null
  }

  return null
}

const getEnrollmentFee = (offers: MembershipDuesComponent[]): MembershipDuesComponent | null => {
  if (!offers.length) {
    return null
  }

  const result = offers.filter((item) => item.$.componentCode === "JF")

  if (result.length) {
    return result[0] || null
  }

  return null
}

const getPrimaryFee = (offers: MembershipDuesComponent[]): MembershipDuesComponent | null => {
  if (!offers.length) {
    return null
  }

  const membershipPlans: MMembershipCode[] = ["PP", "BP", "RP", "EP", "VP"]

  const result = offers.filter((item) => membershipPlans.includes(item.$.componentCode))

  if (result.length) {
    return result[0] || null
  }

  return null
}

const getAssociateEnrollmentFee = (offers: MMembershipOffering[]): MembershipDuesComponent | null => {
  if (!offers.length) {
    return null
  }

  const result = offers[0].DuesComponent

  if (result.length) {
    return result[0]
  }

  return null
}

const getPromoCode = (offers: MembershipOffering | null | undefined, promoCode?: string) => {
  if (!offers || !promoCode) {
    return null
  }
  const searchPromoKey = `|${promoCode}`
  const includePromoCode = (dues: MembershipDuesComponent) => dues.$.componentDescription.indexOf(searchPromoKey) !== -1
  const normalizeDescription = (dues: MembershipDuesComponent) => {
    dues.$.componentDescription = dues.$.componentDescription.replace(searchPromoKey, "").trim()

    return dues
  }

  if (offers.AssociateOffering.length) {
    const findAssociateOffer = (associateOffer: MMembershipOffering) => {
      const result = associateOffer.DuesComponent.find(includePromoCode)

      if (result) {
        return normalizeDescription(result)
      }

      return result
    }

    const result = offers.AssociateOffering.map((associateOffer) => findAssociateOffer(associateOffer)).filter(
      (item) => !!item,
    )

    if (result.length) {
      return result[0]
    }
  }

  if (offers.DuesComponent.length) {
    const result = offers.DuesComponent.find(includePromoCode)

    if (result) {
      return normalizeDescription(result)
    }
  }

  return null
}

export function mMembershipOfferMapping(
  offering: MembershipOffering | null | undefined,
  options?: { promoCode: string },
): PricePreview | null {
  if (!offering) {
    return null
  }

  const primaryFeeItem = getPrimaryFee(offering.DuesComponent)
  const enrollmentFeeItem = getEnrollmentFee(offering.DuesComponent)
  const associateEnrollmentFeeItem = getAssociateEnrollmentFee(offering?.AssociateOffering || [])
  const promoCodeItem = getPromoCode(offering, options?.promoCode)
  const primaryMedicalPrice = getMedicalOffer(offering?.OptionalOfferingPrimary || [])
  const associateMedicalPrice = getMedicalOffer(offering?.OptionalOfferingAssociate || [])
  const associateFee = Number(associateEnrollmentFeeItem?.$.componentAmount || 0)
  const primaryFee = Number(primaryFeeItem?.$.componentAmount || 0)

  return {
    membershipCode: offering.DuesComponent[0].$.componentCode || "",

    promoCode: promoCodeItem?.$?.componentCode,
    promoPrice: Number(promoCodeItem?.$?.componentAmount || 0),
    promoDescription: promoCodeItem?.$?.componentDescription || "",

    primaryFeeCode: primaryFeeItem?.$?.componentCode || "",
    primaryFeePrice: Number(primaryFeeItem?.$.componentAmount || 0),
    primaryFeeDescription: primaryFeeItem?.$?.componentDescription || "",

    associateFeeCode: associateEnrollmentFeeItem?.$?.componentCode || "",
    associateFeePrice: Number(associateEnrollmentFeeItem?.$.componentAmount || 0),
    associateFeeDescription: associateEnrollmentFeeItem?.$?.componentDescription || "",

    enrollmentFeeCode: enrollmentFeeItem?.$?.componentCode || "",
    enrollmentFeePrice: Number(enrollmentFeeItem?.$?.componentAmount || 0),
    enrollmentFeeDescription: String(enrollmentFeeItem?.$?.componentDescription || ""),

    primaryMedicalPrice: Number(primaryMedicalPrice?.$.componentAmount || 0),
    primaryMedicalCode: primaryMedicalPrice?.$?.componentCode || "",
    primaryMedicalDescription: primaryMedicalPrice?.$.componentDescription || "",

    associateMedicalCode: associateMedicalPrice?.$?.componentCode || "",
    associateMedicalPrice: Number(associateMedicalPrice?.$.componentAmount || 0),
    associateMedicalDescription: associateMedicalPrice?.$.componentDescription || "",

    prices: range(9).map((value) => ({
      price: primaryFee + value * associateFee,
      members: value + 1,
    })),
  } as PricePreview
}

export function mMembershipOffersMapping(offers: MembershipOffering[]): PricePreview[] {
  return offers
    .map((offer) => mMembershipOfferMapping(offer))
    .filter((offer) => !!offer)
    .map((offer): PricePreview => offer as PricePreview)
}
