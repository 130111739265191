<button
  nz-button [nzType]="'primary'"
  (click)="swSms.sendEvent('RETRY')"
>
  Retry
</button>
<button
  nz-button [nzType]="'primary'"
  [style]="'margin-left: 20px'"
  (click)="swSms.sendEvent('RELOAD')"
>
  Cancel and Reload
</button>
