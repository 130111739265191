<ng-container *ngIf="globalState$ | async as g">
  <button
    *ngIf="styles && ((buttonField && g.adminMode && !g.previewMode) || buttonField?.value)"
    nz-button
    [nzType]="styles.type"
    [nzSize]="styles.size"
    [class]="styles.backgroundColorClass"
    [style.color]="styles.fontColor?.hex"
    [style.font-size.px]="styles.fontSize?.px"
    [style.font-weight]="styles.fontWeight?.value"
    [style.border-radius]="styles.borderRadius?.px"
  >
    <a
      [href]="buttonField.targetPath"
      (click)="linkClick($event)"
    >
      <ava-quill-view
        *ngIf="g.adminMode && !g.previewMode"
        [stateId]="stateId"
        [rowId]="rowId"
        [options]="buttonTextOptions"
        [style.border-bottom]="styles.borderBottom"
      ></ava-quill-view>

      <quill-view-html
        *ngIf="!g.adminMode || g.previewMode"
        [content]="tokenizedValue?.value"
        [style.border-bottom]="styles.borderBottom"
      ></quill-view-html>

    </a>
  </button>
</ng-container>
