export const northamptonMedicalPlanFullDescription = `
<p class="ava-header-bold">Accidental Medical Coverage</p>
<p class="ava-text">How much does it cost per year?</p>
<p class="ava-text">
  With the group rate offered to AAA members of $7.50 per year, the Accident Medical Expense Insurance Plan reimburses
  medical expenses up to $1,200 for care and treatment of an accidental bodily injury for members engaged in a wide
  variety of covered travel and sporting activities. In addition, the Plan pays Accidental Death and up to $5,000
  Dismemberment. The amount payable for dismemberment varies based on the injury suffered. Review the Description of
  Coverage for a list of covered travel and sporting activities and complete terms and conditions.
</p>
<br />
<p class="ava-text">Benefits for covered activities:</p>
<ul>
  <li><span class="ava-text">Pays up to $1,200 to reimburse covered accident related medical expenses</span></li>
  <li><span class="ava-text">Pays $5,000 Accidental Death and up to $5,000 dismemberment</span></li>
</ul>
<br />
<p class="ava-text">Sample of covered accident related medical expenses:</p>
<ul>
  <li><span class="ava-text">Emergency Room</span></li>
  <li><span class="ava-text">Hospital Room</span></li>
  <li><span class="ava-text">Prescription Drugs</span></li>
  <li><span class="ava-text">X-rays and Diagnostics</span></li>
  <li><span class="ava-text">Ambulance</span></li>
  <li><span class="ava-text">Physical Therapy</span></li>
  <li><span class="ava-text">Medical Equipment Rental</span></li>
  <li><span class="ava-text">Casts & Splints</span></li>
  <li><span class="ava-text">Dental due to bodily injury, etc.</span></li>
</ul>
<br />
<p class="ava-text">
  Please see the
  <a href="https://www.northampton.aaa.com/AAANorthampton/media/Documents/DOC-AAA-Northampton-99071602.pdf">
    Description of Coverage
  </a>
  for complete terms and conditions.
</p>
<br />
<p class="ava-text">
  Exclusions: This insurance does not apply to any accident resulting from: 1) an Insured Person riding as a passenger
  in, entering, or exiting any aircraft while acting or training as a pilot or crew member. (This exclusion does not
  apply to passengers who temporarily perform pilot or crew functions in a life threatening emergency); 2) an Insured
  Person's emotional trauma, mental or physical illness, disease, pregnancy, childbirth or miscarriage, bacterial or
  viral infection, bodily malfunctions or medical or surgical treatment thereof. (This exclusion doesn't apply to
  bacterial infection caused by an Accident or by Accidental consumption of a substance contaminated by bacteria); 3) an
  Insured Person's commission or attempted commission of a felony or being engaged in an illegal occupation; 4) an
  Insured Person being incarcerated after conviction; 5) an Insured Person being intoxicated while driving a vehicle at
  the time of an Accident. Intoxication is defined by the laws of the jurisdiction where such Accident occurs; 6) an
  Insured Person being under the influence of any narcotic unless administered on the advice of a Physician; 7) an
  Insured Person participating in military action while in active military service with the armed forces of any country
  or established international authority. (This exclusion does not apply to the first sixty (60) consecutive days of
  active military service with the armed forces of any country or established international authority; 8) an Insured
  Person's suicide, attempted suicide or intentionally self-inflicted injury; 9) a declared or undeclared War.
</p>
<br />
<p class="ava-text">
  Insurance is Underwritten by FEDERAL INSURANCE COMPANY, a member insurer of the Chubb Group of Insurance Companies.
  Actual coverage is subject to the language of the policy as written, policy number 9907-16-02. Exclusions and
  limitations apply. Chubb, Box 1615, Warren, NJ 07061-1615
</p>
<br />
<p class="ava-text">Plan Administrator & Sponsor AAA Northampton 610-258-2371</p>
<br />
<p class="ava-text">
  The policy provides limited accident insurance only. The policy does not provide coverage for legal liability. It does
  not provide basic hospital, basic medical or major medical insurance. All claims must be reported to the Plan
  Administrator within 90 days of the accident. The benefit amount is payable only if treatment is first incurred within
  90 days after the accident. Accident Medical Expense is payable only for medical expenses incurred within 52 weeks
  after the date of the accident causing the accidental bodily injury.
</p>
<br />
<p class="ava-text">
  The AAA Accident Medical Expense Program is offered with your annual AAA membership. Call or visit your local AAA
  office with any questions.
</p>
`
