import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { AccountDetails, MembershipCode } from "../types/types"
import { MembershipCard, MembershipLoginForm } from "./membership.models"
import { Environment } from "@aaa/emember/types"
import { RequestError } from "../generic-errors"

export const MembershipActions = createActionGroup({
  source: "Membership",
  events: {
    Load: props<{ membershipNumber: string }>(),
    "Load Succeeded": props<{ accountDetails: AccountDetails }>(),
    "Load Failed": props<{ error: RequestError }>(),
    Login: props<{ payload: MembershipLoginForm }>(),
    "Login Succeeded": props<{ accountDetails: AccountDetails }>(),
    "Login Failed": props<{ error: RequestError }>(),
    Initialize: props<{ env: Environment }>(),
    "Initialize Succeeded": props<{ env: Environment }>(),
    "Set Codes": props<{ membershipCodes: MembershipCode[] }>(),
    "Set Cards": props<{ membershipCards: MembershipCard[] }>(),
    "Update Account Details": props<{ accountDetails: AccountDetails }>(),
    "Set Zipcode": props<{ zipcode: string }>(),
    Logout: emptyProps(),
  },
})
