import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { catchError, map, switchMap } from "rxjs/operators"
import { Observable, of, withLatestFrom } from "rxjs"
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteOperationExecuteEventPayload,
  MembershipConnectSuiteRecostValidateChangeLevelEventPayload,
  MembershipConnectSuiteRecostValidateRenewEventPayload,
} from "@aaa/interface-joinRenew-membership-membershipConnectSuite"
import { ClubApp } from "@aaa/emember/types"
import { MobileRenewActions } from "./mobile-renew.actions"
import { AccountDetails, ValidateSucceededResponseObject } from "../types/types"
import { ConnectSuite } from "../connect-suite.type"
import { ExecuteService } from "../services/execute.service"
import { RequestError, RequestErrorType } from "../generic-errors"
import { filterByClubIds } from "../utils/filter-by-club-ids"
import {
  MobileRenewFormAction,
  MobileRenewPayParams,
  RecostChangeLevelParams,
  RecostRenewParams,
} from "./mobile-renew.models"
import { getMobileRenewFormAction } from "./mobile-renew.selectors"
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from "@aaa/interface-joinRenew-payment-paymentCybersource"
import { Operation, OperationExecutePayload } from "@aaa/interface-joinRenew-joinRenewLib"
import { getPayment } from "@aaa/emember/store-payment"
import { FormGroupValue } from "../../modules/share/form.utils"
import { PaymentForm } from "@aaa/emember/share/payment-form"
import { checkOperationErrorsConnectSuiteSystem } from "../check-operation-errors-connect-suite-system"
import { getClearCacheSettings } from "../utils/get-cache-settings"
import { Cybersource } from "../cybersource.type"
import { checkCybersourcePaymentValidation } from "../check-cybersource-payment-validation"
import { DataLayerService } from "../../modules/share/services/data-layer.service"
import { AnalyticsPurchaseEvent } from "../../../types/analytics-purchase-event"
import { AppAnalyticsEvents } from "../../../types/analytics-events"
import { getTransactionId } from "../utils/get-transaction-id"

@Injectable({ providedIn: "root" })
export class MobileRenewConnectSuiteSystemEffects {
  store = inject(Store)
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Hoosier, ClubApp.SouthJersey]))
  executeService = inject(ExecuteService)
  dataLayer = inject(DataLayerService)

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MobileRenewActions.pay),
      switchMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(getMobileRenewFormAction), this.store.select(getPayment))),
      ),
      switchMap(([{ params }, formAction, payment]) => {
        if (formAction === MobileRenewFormAction.RENEW) {
          return this.payRenew(params, payment).pipe(
            map(() => MobileRenewActions.paySucceeded()),
            catchError((error) => of(MobileRenewActions.payFailed({ error }))),
          )
        }

        if (formAction === MobileRenewFormAction.UPGRADE) {
          return this.payUpgradeLevel(params, payment).pipe(
            map(() => MobileRenewActions.paySucceeded()),
            catchError((error) => of(MobileRenewActions.payFailed({ error }))),
          )
        }

        return of(MobileRenewActions.paySucceeded())
      }),
    ),
  )

  recostValidateMobileUpgrade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MobileRenewActions.recostValidateUpgrade),
      switchMap(({ params }) =>
        this.recostValidateUpgrade(params).pipe(
          map((res) => MobileRenewActions.recostValidateSucceeded(res)),
          catchError((error) => of(MobileRenewActions.recostValidateFailed({ error }))),
        ),
      ),
    ),
  )

  recostValidateMobileRenew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MobileRenewActions.recostValidateRenew),
      switchMap(({ params }) =>
        this.recostValidateRenew(params).pipe(
          map((res) => MobileRenewActions.recostValidateSucceeded(res)),
          catchError((error) => of(MobileRenewActions.recostValidateFailed({ error }))),
        ),
      ),
    ),
  )

  payRenew(params: MobileRenewPayParams, payment: { token: string; formValues: FormGroupValue<PaymentForm> }) {
    const paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(params.accountDetails?.address.street1),
          address2: String(params.accountDetails?.address.street2),
          administrativeArea: String(params.accountDetails?.address.state),
          buildingNumber: "",
          country: "US",
          district: String(params.accountDetails?.address.state),
          email: String(params.accountDetails?.email || "fallback@avagate.com"),
          firstName: params.formValues.paymentCardHolder?.firstName || "",
          lastName: params.formValues.paymentCardHolder?.lastName || "",
          locality: String(params.accountDetails?.address.city),
          phoneNumber: String(params.accountDetails?.phone.home || params.accountDetails?.phone.cell),
          postalCode: String(params.accountDetails?.address.zip),
        },
        amountDetails: {
          totalAmount: String(params.totalCost),
          currency: "USD",
        },
        creditCardBrandedName: payment.formValues.card?.cardName || "",
      },
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
    }
    const membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
      operation: Operation.UPDATE,
      cacheSettings: getClearCacheSettings(),
      executionData: params.executionData,
      method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
    }
    const payload: OperationExecutePayload = {
      membershipEvent,
      paymentEvent,
      operation: Operation.UPDATE,
    }

    return this.executeService
      .execute<ConnectSuite.JoinExecuteResponseObject, Cybersource.ExecutePaymentResponseObject>(payload)
      .pipe(
        map(({ validateObject, paymentObject, operationObject }) => {
          const paymentError = !!paymentObject?.meta.isError
          if (paymentError) {
            checkCybersourcePaymentValidation(paymentObject.error)
          }

          // Todo need to implement if exist any error in the validateObject

          const operationError = !!operationObject?.meta?.isError

          if (operationError) {
            checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject)
          }

          const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
            currency: "USD",
            transaction_id: getTransactionId(paymentObject),
            value: params.totalCost,
            items: [
              {
                quantity: 1,
                item_id: "primary",
                item_name: AppAnalyticsEvents.MobileAppRenewal,
                price: params.totalCost,
              },
            ],
            context: "ava-store " + AppAnalyticsEvents.MobileAppRenewal,
          }
          this.dataLayer.purchaseEvent(analyticsEventParams)

          return null
        }),
      )
  }

  payUpgradeLevel(params: MobileRenewPayParams, payment: { token: string; formValues: FormGroupValue<PaymentForm> }) {
    const paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(params.accountDetails?.address.street1),
          address2: String(params.accountDetails?.address.street2),
          administrativeArea: String(params.accountDetails?.address.state),
          buildingNumber: "",
          country: "US",
          district: String(params.accountDetails?.address.state),
          email: String(params.accountDetails?.email || "fallback@avagate.com"),
          firstName: params.formValues.paymentCardHolder?.firstName || "",
          lastName: params.formValues.paymentCardHolder?.lastName || "",
          locality: String(params.accountDetails?.address.city),
          phoneNumber: String(params.accountDetails?.phone.home || params.accountDetails?.phone.cell),
          postalCode: String(params.accountDetails?.address.zip),
        },
        amountDetails: {
          totalAmount: String(params.totalCost),
          currency: "USD",
        },
        creditCardBrandedName: payment.formValues.card?.cardName || "",
      },
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
    }
    const membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
      operation: Operation.UPDATE,
      cacheSettings: getClearCacheSettings(),
      executionData: params.executionData,
      method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
    }
    const payload: OperationExecutePayload = {
      membershipEvent,
      paymentEvent,
      operation: Operation.UPDATE,
    }

    return this.executeService
      .execute<ConnectSuite.JoinExecuteResponseObject, Cybersource.ExecutePaymentResponseObject>(payload)
      .pipe(
        map(({ validateObject, operationObject, paymentObject }) => {
          if (validateObject?.meta.isError) {
            throw new RequestError(RequestErrorType.UpgradedMembership, validateObject)
          }

          const operationError = !!operationObject?.meta?.isError
          if (operationError) {
            checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject)
          }

          const analyticsEventParams: AnalyticsPurchaseEvent["eventParams"] = {
            currency: "USD",
            transaction_id: getTransactionId(paymentObject),
            value: params.totalCost,
            items: [
              {
                quantity: 1,
                item_id: "primary",
                item_name: AppAnalyticsEvents.MobileAppUpgrade,
                price: params.totalCost,
              },
            ],
            context: "ava-store " + AppAnalyticsEvents.MobileAppUpgrade,
          }
          this.dataLayer.purchaseEvent(analyticsEventParams)

          return null
        }),
      )
  }

  recostValidateRenew(params: RecostRenewParams): Observable<ValidateSucceededResponseObject<AccountDetails>> {
    const payload: MembershipConnectSuiteRecostValidateRenewEventPayload = {
      method: MembershipConnectSuiteMethod.RECOST_VALIDATE_RENEW,
      autoRenew: params.autoRenew,
      memberNumber: params.accountDetails?.memberNumber,
      verificationData: {
        lastName: params?.accountDetails?.lastName || "",
        postalCode: params.accountDetails?.address?.zip || "",
      },
    }

    return this.executeService.membershipQuery<ConnectSuite.ValidateRenewResponseObject>(payload).pipe(
      map((validateObject) => {
        const isError =
          validateObject.meta.isError ||
          validateObject.response.validationData?.membership?.attributes?.membershipStatus !== "P"
        const executionData = validateObject?.response?.executionData || ""

        if (isError) {
          const membershipStatus = validateObject.response?.validationData?.membership?.attributes?.membershipStatus
          let error: RequestError = new RequestError(RequestErrorType.Error)

          // 1. check if membership is cancelled
          if (!validateObject.meta.isError && membershipStatus === "C") {
            error = new RequestError(RequestErrorType.MembershipCancelled)
          } else if (!validateObject.meta.isError && membershipStatus !== "C") {
            // 2. check if membership is not expired yet
            error = new RequestError(RequestErrorType.MembershipNotExpiredYet)
          } else {
            const errorCode = validateObject.error?.errorCode?.toString() || validateObject?.error?.responseCode

            switch (errorCode) {
              case "1":
              case "020": // INVALID_ID("020") // "The membership number is invalid."
                error = new RequestError(RequestErrorType.MembershipInvalidNumber)
                break
              case "022": // MEMBERSHIP_NOT_FOUND("022")
                error = new RequestError(RequestErrorType.MembershipNotFound)
                break
              case "023": // INVALID_MEMBERSHIP("023")
                error = new RequestError(RequestErrorType.MembershipInvalidNumber)
                break
              case "2":
                error = new RequestError(RequestErrorType.MembershipInvalidLastName)
                break
              case "3":
                error = new RequestError(RequestErrorType.MembershipInvalidZipCode)
                break
            }
          }

          throw error
        }
        const accountDetails = new ConnectSuite.AccountInfo(validateObject.response.validationData)

        return {
          executionData,
          response: accountDetails,
        }
      }),
    )
  }

  recostValidateUpgrade(params: RecostChangeLevelParams): Observable<ValidateSucceededResponseObject<AccountDetails>> {
    const payload: MembershipConnectSuiteRecostValidateChangeLevelEventPayload = {
      method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_LEVEL,
      autoRenew: params.autoRenew,
      memberNumber: params.memberNumber,
      newLevel: params.newLevel,
    }

    return this.executeService.membershipQuery<ConnectSuite.MemberUpgradeResponseObject>(payload).pipe(
      map((validateObject) => {
        const isError = validateObject.meta.isError,
          executionData = validateObject?.response?.executionData || ""

        if (isError) {
          throw new RequestError(RequestErrorType.Error)
        } else {
          const accountDetails = new ConnectSuite.AccountInfo(validateObject.response.validationData)
          return { executionData, response: accountDetails }
        }
      }),
    )
  }
}
