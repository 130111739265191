<ng-container *ngIf="globalState$ | async as g">
  <div #container [style]="'padding: 20px 0'">
    <div
      *ngIf="!g.windowMetaData.user.memberNumber"
      [style]="'display: grid; column-gap: 20px; padding: 20px; grid-template-columns: 1fr 1fr; max-width: 50%; margin: auto'"
      [ngStyle]="buttonWrapperStyles"
    >
      <button nz-button nzType="primary">
        <a [href]="'/smart-weather-login'">
          Sign Up
        </a>
      </button>
      <button nz-button nzType="primary">
        <a [href]="'/smart-weather-login'">
          Manage Account
        </a>
      </button>
    </div>

    <nz-card
      *ngIf="g.windowMetaData.user.memberNumber && g.afAuthIdTokenResult"
      [nzTitle]="bannerTitle"
      nzBorderless
      class="blue-banner"
      [style]="'width: 100%'"
      [nzBodyStyle]="{'display': 'grid', 'grid-gap': '20px', 'padding': '10px 30px'}"
    >
      <ng-template #bannerTitle>
        Manage Your Weather Alerts
      </ng-template>
      <div [style]="'text-align: left'">
        You can manage up to 5 locations
      </div>
      <ng-container *ngIf="state">
        <ava-smart-weather-list
          *ngIf=" state.list || state.location"
        ></ava-smart-weather-list>
        <ava-smart-weather-loading *ngIf="state.cloudSync"></ava-smart-weather-loading>
        <ava-smart-weather-error *ngIf="state.cloudSyncError"></ava-smart-weather-error>
      </ng-container>
    </nz-card>
  </div>
</ng-container>
