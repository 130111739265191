import { createFeatureSelector, createSelector } from "@ngrx/store"
import { PriceOffersState } from "./price-offers.model"
import { RequestStatus } from "../../../types/request-status"
import { getClubOption } from "../membership/membership.selectors"
import { ClubApp } from "@aaa/emember/types"
import { RequestErrorType } from "../generic-errors"
import { AlertType } from "../../modules/share/alert"
import { priceOfferCouponMessages } from "../../constants/price-offer-coupon-messages"
import { getQueryParamMembershipLevel } from "../router/router.selectors"
import { TMobilePromoCodes, TMRShellPromoCodes } from "../../constants/promocodes"

export const PRICE_OFFERS_FEATURE_KEY = "price-offers"

export const getPriceOffersState = createFeatureSelector<PriceOffersState>(PRICE_OFFERS_FEATURE_KEY)
export const getPriceOfferQuery = createSelector(getPriceOffersState, ({ query }) => query)
export const getPriceOfferError = createSelector(getPriceOffersState, ({ error }) => error)
export const getPriceOfferLevelIds = createSelector(getPriceOffersState, ({ levelIds }) => levelIds)
export const getPriceOfferRecordLevels = createSelector(getPriceOffersState, ({ recordLevels }) => recordLevels)
export const getPriceOfferLevels = createSelector(getPriceOfferLevelIds, getPriceOfferRecordLevels, (ids, levels) =>
  ids.map((id) => levels[id]),
)
export const getPriceOfferRequestStatus = createSelector(getPriceOffersState, ({ requestStatus }) => requestStatus)
export const getIsLoadingPriceOffer = createSelector(getPriceOfferRequestStatus, (rs) => rs === RequestStatus.RUNNING)
export const getIsSucceededPriceOffer = createSelector(getPriceOfferRequestStatus, (rs) => rs === RequestStatus.SUCCESS)
export const getCouponCode = createSelector(getPriceOfferQuery, ({ couponCode }) => couponCode)
export const getProgramCouponCode = createSelector(getPriceOfferQuery, ({ programCode }) => programCode)
export const getHasPromoCodePriceOffer = createSelector(getPriceOfferQuery, ({ promoCode }) => !!promoCode)
export const getPriceOfferMembershipWithoutRv = createSelector(getPriceOfferLevels, (levels) =>
  levels.filter((l) => l.rv === false),
)
export const getPriceOfferLevelOffers = createSelector(getPriceOffersState, ({ levelOffers }) => levelOffers)
export const getPriceOfferRawLevelOffers = createSelector(getPriceOffersState, ({ rawLevelOffers }) => rawLevelOffers)
export const getQueryMembershipOfferLevel = createSelector(
  getPriceOfferLevels,
  getQueryParamMembershipLevel,
  (levels, { membershipLevel, rv }) => {
    const selectedMembershipLevel = levels.find((l) => l.level === membershipLevel && l.rv === rv) || null

    return selectedMembershipLevel
  },
)
export const getHasAutoRenewMembershipLevel = createSelector(
  getQueryMembershipOfferLevel,
  getPriceOfferLevelOffers,
  (level, offers) => {
    const levelOffers = level?.membershipType && offers ? offers[level.membershipType] : []

    const promoWithRequireAutorenew = levelOffers.find((offer) => {
      const hasPromo = offer.code === "promoCode"
      const requireAutoRenew = offer.conditions.find(
        (condition) => condition.apply === "autorenew" && condition.value === true,
      )

      return hasPromo && requireAutoRenew
    })

    return promoWithRequireAutorenew
  },
)
export const getPrimaryPrice = (membershipType: string) =>
  createSelector(getPriceOfferLevelOffers, (offerLevels) => {
    const offerLevel = offerLevels && offerLevels[membershipType]

    if (offerLevel && offerLevel.length) {
      return offerLevel.find((level) => level.offering === "primary")?.amount || 0
    }

    return 0
  })
export const getAssociatePrice = (membershipType: string) =>
  createSelector(getPriceOfferLevelOffers, (offerLevels) => {
    const offerLevel = offerLevels && offerLevels[membershipType]

    if (offerLevel && offerLevel.length) {
      return offerLevel.find((level) => level.offering === "associate")?.amount || 0
    }

    return 0
  })
export const getPromoCodeMessageStatus = createSelector(
  getPriceOfferRequestStatus,
  getClubOption,
  (requestStatus, option) => {
    switch (requestStatus) {
      case RequestStatus.RUNNING:
        return "Verifying promo code"
      case RequestStatus.SUCCESS:
        return "Success! Your Special Discount is Applied."
      case RequestStatus.FAILED:
        if (option.clubId === ClubApp.Northampton) {
          return "Promo Code is not active."
        }
        return ""
      default:
        return ""
    }
  },
)

export const getPriceOfferCouponDescription = createSelector(
  getPriceOfferRequestStatus,
  getPriceOfferError,
  getClubOption,
  getCouponCode,
  getProgramCouponCode,
  priceOfferCouponMessages,
)

export const getPriceOfferCouponAlertType = createSelector(
  getPriceOfferRequestStatus,
  getPriceOfferError,
  (requestStatus, error) => {
    switch (requestStatus) {
      case RequestStatus.RUNNING:
        return AlertType.WARNING
      case RequestStatus.SUCCESS:
        return AlertType.SUCCESS
      case RequestStatus.FAILED: {
        switch (error?.type) {
          case RequestErrorType.PricePreviewRedeemedCouponCode:
          case RequestErrorType.PricePreviewReservedCouponCode:
            return AlertType.WARNING
          default:
            return AlertType.WARNING
        }
      }
    }

    return AlertType.INFO
  },
)

export const getPriceOfferCouponMessage = createSelector(
  getPriceOfferRequestStatus,
  getCouponCode,
  (requestStatus, couponCode) => {
    switch (requestStatus) {
      case RequestStatus.SUCCESS:
      case RequestStatus.FAILED: {
        return `Activation Code: ${couponCode}`
      }
    }

    return ""
  },
)

export const getDoRecostValidation = createSelector(getClubOption, (option) => option.clubId === ClubApp.MidStates)

export const getIsValidProgramCode = createSelector(
  getProgramCouponCode,
  getCouponCode,
  (programCode, couponCode) =>
    !!couponCode &&
    !!programCode &&
    (TMRShellPromoCodes.includes(programCode) || TMobilePromoCodes.includes(programCode)),
)
export const getApplyOnlyForBasicLevel = createSelector(
  getProgramCouponCode,
  (programCode) => !!(programCode && TMRShellPromoCodes.includes(programCode)),
)

// Join Form controls by program code
export const getHideChangeMembershipLevel = createSelector(
  getProgramCouponCode,
  (programCode) => !!(programCode && TMRShellPromoCodes.includes(programCode)),
)
export const getIsDisabledChangeAutoRenew = createSelector(
  getProgramCouponCode,
  (programCode) =>
    !!(programCode && (TMRShellPromoCodes.includes(programCode) || TMobilePromoCodes.includes(programCode))),
)
export const getHideAddAssociate = createSelector(
  getProgramCouponCode,
  (programCode) => !!(programCode && TMRShellPromoCodes.includes(programCode)),
)
export const getHideApplyPromo = createSelector(
  getProgramCouponCode,
  (programCode) =>
    !!(programCode && (TMRShellPromoCodes.includes(programCode) || TMobilePromoCodes.includes(programCode))),
)
export const getHasProgramCode = createSelector(
  getProgramCouponCode,
  getCouponCode,
  (programCode, couponCode) => !!programCode || !!couponCode,
)
