import { Component } from "@angular/core"
import { NzDividerModule } from "ng-zorro-antd/divider"

@Component({
  selector: "ava-divider",
  templateUrl: "./ava-divider.component.html",
  styleUrls: ["./ava-divider.component.scss"],
  standalone: true,
  imports: [NzDividerModule],
})
export class AvaDividerComponent {}
