import { MemberLookupErrorResponse } from "../modules/join-renew/hoosier/events/member-lookup"
import { getMembershipStatus } from "./utils/get-membership-status"
import {
  getMzpBalanceTotal,
  getMzpCustomerPhone,
  getMzpPrimaryMemberListInfo,
  getMzpMembershipAssociates,
  getMzpMembershipCode,
  getMzpPrimaryCustomerInfo,
  getMzpMemberListInfoById,
  getMzpMembershipPrimary,
} from "./mzp-utils"
import {
  MembershipMzpGetMembershipCostsChangeResponse,
  MembershipMzpGetMembershipCostsJoinResponse,
  MembershipMzpPricePreviewsResponse,
  MzpMembershipLevel,
} from "@aaa/interface-joinRenew-membership-membershipMzp"
import { format, utcToZonedTime } from "date-fns-tz"
import { MembershipCardData } from "@aaa/emember/store-membership"
import {
  AccountAddress,
  AccountDetails,
  AccountIncludedAssociateOffers,
  AccountDetailsPaymentSummary,
  AccountIncludedPrimaryOffers,
  AccountPhone,
  MembershipAssociateDetails,
  MembershipCode,
  SessionDocResponseObject,
  ValidateResponse,
} from "./types/types"
import { AccountStatus } from "@aaa/emember/types"
import { MembershipOfferItem } from "./price-offers/helpers/types"
import { environment } from "../../environments/environment"
import { MembershipLevelOffer } from "@aaa/emember/store-price-offers"

export namespace Mzp {
  export interface MzpGetMembershipCostsJoinResponse extends MembershipMzpGetMembershipCostsJoinResponse {
    executionData: string
    validationData: MemberInfo
  }

  export type MidstateMembershipCode = "BS" | "PL" | "RV" | "PM" | "PR"

  export enum MidstatesMembershipType {
    Basic = "BS",
    Plus = "PL",
    PlusRv = "RV",
    Premier = "PM",
    Premier_RV = "PR",
  }

  export type MidStateMembershipLabel = "Basic" | "Plus" | "Plus RV" | "Premier" | "Premier RV"

  export const membershipCodes: MembershipCode<
    MzpMembershipLevel,
    MidstatesMembershipType,
    MidstateMembershipCode,
    MidStateMembershipLabel
  >[] = [
    {
      label: "Basic",
      level: MzpMembershipLevel.BASIC,
      rv: false,
      membershipType: MidstatesMembershipType.Basic,
      duesComponentCode: "BS",
    },
    {
      label: "Plus",
      level: MzpMembershipLevel.PLUS,
      rv: false,
      membershipType: MidstatesMembershipType.Plus,
      duesComponentCode: "PL",
    },
    {
      label: "Plus RV",
      level: MzpMembershipLevel.PLUS_RV,
      rv: true,
      membershipType: MidstatesMembershipType.PlusRv,
      duesComponentCode: "RV",
    },
    {
      label: "Premier",
      level: MzpMembershipLevel.PREMIER,
      rv: false,
      membershipType: MidstatesMembershipType.Premier,
      duesComponentCode: "PM",
    },
    {
      label: "Premier RV",
      level: MzpMembershipLevel.PREMIER_RV,
      rv: true,
      membershipType: MidstatesMembershipType.Premier_RV,
      duesComponentCode: "PR",
    },
  ]

  export type MembershipError = MemberLookupErrorResponse

  export interface PricePreviewsResponseObject extends SessionDocResponseObject {
    error: any
    response: MembershipMzpPricePreviewsResponse
  }
  export interface MemberLookupResponseObject extends SessionDocResponseObject {
    error: MemberLookupErrorResponse
    response: {
      memberInfo: MemberInfo
    }
  }

  export interface JoinExecuteResponseObject extends SessionDocResponseObject {
    error: MemberLookupErrorResponse
    response: {
      mzpResponse: MemberJoinInfo
      // mzpResponse: MemberInfo
    }
  }
  export interface JoinRecostValidationResponseObject extends SessionDocResponseObject {
    error: { error: string[]; promoData: { programCode: string; couponCode: string } }
    response: MzpGetMembershipCostsJoinResponse
  }
  export interface MembershipAddAssociateRecostValidationResponseObject extends SessionDocResponseObject {
    error: { error: string[]; promoData: { programCode: string; couponCode: string } }
    response: MembershipMzpGetMembershipCostsChangeResponse<MemberInfo>
  }
  export interface MembershipChangeAddressValidationResponseObject extends SessionDocResponseObject {
    error: { error: string[]; promoData: { programCode: string; couponCode: string } }
    response: MembershipMzpGetMembershipCostsChangeResponse<MemberInfo>
  }
  export interface MembershipChangePhoneValidationResponseObject extends SessionDocResponseObject {
    error: { error: string[]; promoData: { programCode: string; couponCode: string } }
    response: MembershipMzpGetMembershipCostsChangeResponse<MemberInfo>
  }
  export interface MemberQuickRenewResponseObject extends SessionDocResponseObject {
    error: MemberLookupErrorResponse
    response: ValidateResponse<MemberInfo>
  }

  export interface MemberAssociateAddPayResponseObject extends SessionDocResponseObject {
    error: MemberLookupErrorResponse
    response: {
      drupalResult: null
      mzpResponse: MemberInfo
      membershipId: string
    }
  }

  export interface CustomerPhoneList {
    lastUpdatedBy: number
    unlistedFl: boolean
    daosanitized: boolean
    allowSmsFl: boolean
    creationDt: number
    lastUpdDt: number
    phoneTypeCd: string
    badPhoneFl: boolean
    createdBy: number
    phone: string
    customerId: number
    permissionRequestedFl: boolean
    permissionReceivedFl: boolean
    customerPhoneKy: number
  }

  export interface CustomerEmailList {
    customerEmailKy: number
    lastUpdatedBy: number
    lastUpdDt: number
    createdBy: number
    customerId: number
    emailTypeCd: string
    badEmailFl: boolean
    daosanitized: boolean
    creationDt: number
    email: string
    optOutFl: boolean
  }

  export interface MemberLookupInfoFeesList {
    daosanitized: boolean
    countedFl: boolean
    createdBy: number
    createDt: number
    creationDt: number
    feeAppliedAt: number
    feeAt: number
    feeDefinitionKy: number
    feeDt: number
    feeType: string
    lastUpdatedBy: number
    lastUpdDt: number
    membershipFeesKy: number
    membershipKy: number
    memberKy: number
    paidByCd: string
    status: string
    waivedFl: boolean
    discountHistoryList: {
      amount: number
      discountCd: string //"ARMS"
      sustainableFl: boolean
      daosanitized: boolean
      source: string //"Automatic"
      riderKy: number
      countedFl: boolean
      discountKy: number
      rolloverFl: boolean
      costEffectiveDt: number
      parentDiscountHistoryKy: number
      originalAt: number
      paymentMethodCd: string //"DISCNT"
      discountHistoryKy: number
      discontinuedFl: boolean
    }[]
  }

  export interface MemberLookupInfoRiderList {
    daosanitized: boolean
    admOriginalCostAt: number
    agentId: string
    billingCategoryCd: string
    billingCd: string
    canceledDuesAt: number
    commissionCd: string
    costEffectiveDt: number
    countedFl: boolean
    createdBy: number
    creationDt: number
    doNotRenewFl: boolean
    duesAdjustmentAt: number
    duesCostAt: number
    futureCancelCreditAt: number
    lastUpdatedBy: number
    lastUpdDt: number
    membershipKy: number
    memberKy: number
    originalAgentId: string
    paidByCd: string
    paymentAt: number
    premiumAt: number
    reinstateFl: boolean
    riderCompCd: string
    amount: number
    riderDefinitionKy: number
    riderKy: number
    rollOnFl: boolean
    solicitationCd: string
    sourceOfSale: string
    status: string
    statusDt: number
    planBillingList: []
    discountHistoryList: {
      amount: number
      discountCd: string //"ARMS"
      sustainableFl: boolean
      daosanitized: boolean
      source: string //"Automatic"
      riderKy: number
      countedFl: boolean
      discountKy: number
      rolloverFl: boolean
      costEffectiveDt: number
      parentDiscountHistoryKy: number
      originalAt: number
      paymentMethodCd: string //"DISCNT"
      discountHistoryKy: number
      discontinuedFl: boolean
    }[]
  }

  export interface MemberLookupInfoCredentialHistoryList {
    daosanitized: boolean
    activeExpirationDt: number
    cancelFl: boolean
    cardFl: boolean
    cardReqDt: number
    coverageLevelCd: string
    createdBy: number
    creationDt: number
    credentialHistoryKy: number
    customerId: number
    lastUpdatedBy: number
    lastUpdDt: number
    memberKy: number
    reasonCd: string
    sendCardTo: string
    sourceCd: string
    systemRequestedFl: boolean
    waivedBy: string
    waiveFeeFl: boolean
  }

  export interface MemberLookupInfoMemberList {
    daosanitized: boolean
    activeExpirationDt: number
    admDoNotReinstateFl: boolean
    agentId: string
    associateId: string
    associateRelationCd: string
    badEmailFl: boolean
    billingCategoryCd: string
    billingCd: string
    cardOnRenewalFl: boolean
    checkDigitNr: number
    commissionCd: string
    createdBy: number
    creationDt: number
    customerId: number
    doNotRenewFl: boolean
    extendChargeFl: boolean
    freeMemberFl: boolean
    futureCancelFl: boolean
    giftKitGivenFl: boolean
    joinAaaDt: number
    joinClubDt: number
    lastUpdatedBy: number
    lastUpdDt: number
    membershipId: string
    membershipKy: number
    memberCardExpirationDt: number
    memberExpirationDt: number
    memberKy: number
    memberTypeCd: "P" | "A"
    originalAgentId: string
    reasonJoined: string
    renewMethodAtRenewal: string
    renewMethodCd:
      | "A" //A = autorenew
      | "B" // not auto renew
    rollOnFl: boolean
    sendBillTo: string
    sendCardTo: string
    solicitationCd: string
    sourceOfSale: string
    status: "P" | "A"
    statusDt: number
    stickerCount: number
    waiveBenefitsFl: boolean
    aaaCouponList: []
    credentialHistoryList: MemberLookupInfoCredentialHistoryList[]
    membershipFeesList: MemberLookupInfoFeesList[]
    memberCodeList: []
    riderList: MemberLookupInfoRiderList[]
    tmobileList: []
  }

  export interface MembershipInfo {
    daosanitized: boolean
    advancePayAt: number
    billingCategoryCd: string
    billingCd: string
    billAddrFl: boolean
    branchKy: number
    coverageLevelCd: string
    coverageLevelKy: number
    createdBy: number
    creationDt: number
    creditsAt: number
    donorRenewalFl: boolean
    dontSendPubFl: boolean
    dontSolicitFl: boolean
    driverCt: number
    duesAdjustmentAt: number
    duesCostAt: number
    excludedUnappliedAt: number
    fleetAccountFl: boolean
    futureCancelFl: boolean
    giftKitGivenFl: boolean
    holdBillFl: boolean
    lastUpdatedBy: number
    lastUpdDt: number
    lonePrimaryCt: number
    membershipId: string
    membershipKy: number
    membershipTypeCd: string
    noRefundsFl: boolean
    noSafetyFundFl: boolean
    ootCd: string
    otherPhoneFl: boolean
    refundAt: number
    rollOnFl: boolean
    safetyFundAppliedFl: boolean
    splitFl: boolean
    status: string
    statusDt: number
    tempAddrFl: boolean
    unappliedAt: number
    unlistedFl: boolean
    memberList: MemberLookupInfoMemberList[]
    discountHistoryList: {
      amount: number
      discountCd: string //"ARMS"
      sustainableFl: boolean
      daosanitized: boolean
      source: string //"Automatic"
      riderKy: number
      countedFl: boolean
      discountKy: number
      rolloverFl: boolean
      costEffectiveDt: number
      parentDiscountHistoryKy: number
      originalAt: number
      paymentMethodCd: string //"DISCNT"
      discountHistoryKy: number
      discontinuedFl: boolean
    }[]
    membershipCodeList: []
  }

  export interface MemberLookupPayment {
    paymentMethodCd: null
    nameOnCard: null
    cardType: null
    cardNumber: null
    cardExpirationMMYY: null
    cardCVV: null
    cardFirstFour: null
    cardLastFour: null
    cardAuthorizationNumber: null
    checkRoutingNumber: null
    checkAccount: null
    checkAccountType: null
    cardAddress: null
    cardCity: null
    cardState: null
    cardZip: null
    billAddressLine1: null
    billAddressLine2: null
    billAddressCity: null
    billAddressState: null
    billAddressZip: null
    firstName: null
    lastName: null
    phone: null
    email: null
    ssnLast4: null
    driverLicense: null
    checkNumber: null
    paidByCd: null
    donorId: null
    paymentAmount: null
    membershipPaymentKy: null
    componentList: null
  }

  export interface MemberLookupAddress {
    daosanitized: boolean
    addressKy: number
    addressLine1: string
    addressLine2: string
    badAddressFl: boolean
    city: string
    country: string
    createdBy: number
    creationDt: number
    customerId: number
    lastUpdatedBy: number
    lastUpdDt: number
    mailingAddressFl: boolean
    postalCode: string
    seasonalFl: boolean
    state: string
    vanityFl: boolean
  }

  export interface MemberLookupCustomer {
    daosanitized: boolean
    adaCompliantFl: boolean
    addressKy: number
    badAddressFl: boolean
    badEmailFl: boolean
    companyBadAddressFl: boolean
    createdBy: number
    creationDt: number
    customerId: number
    dontEmailFl: boolean
    dontSolicitFl: boolean
    eapCustomerFl: boolean
    eapPilotFl: boolean
    eapProgramFl: boolean
    firstName: string
    fullName: string
    lastName: string
    lastUpdatedBy: number
    lastUpdDt: number
    mastersSocialWorkFl: boolean
    searchIdxCol: string
    source: string
    typeCdxMemberFl: boolean
    typeClubFl: boolean
    typeContributorFl: boolean
    typeDonorFl: boolean
    typeEapFl: boolean
    typeGroupFl: boolean
    typeMemberFl: boolean
    typeMinorFl: boolean
    typeRecruiterFl: boolean
    typeSubscriberFl: boolean
    typeTravelerFl: boolean
    typeTravelVendorFl: boolean
    middleName: string
    customerEmailList: CustomerEmailList[]
    customerPhoneList: CustomerPhoneList[]
    memberTypeCd: string // P or A
  }

  export interface MemberLookupBalance {
    status: string
    type: "Membership" | "Member" | "Rider" | "MembershipFees"
    key: number
    amount: number
  }

  export interface MemberRiders {
    memberky: string
    riderCompCd: string // "MD"
    status: string
  }

  export interface MemberInfo {
    status: string
    associateCount: number
    memberRiders: MemberRiders[]
    membership: MembershipInfo
    payment: MemberLookupPayment
    address: MemberLookupAddress
    customers: MemberLookupCustomer[]
    autorenewals: { status: string }
    balances: MemberLookupBalance[]
    error?: string[]
  }

  export interface MemberJoinInfo {
    zipcode: string
    country: string
    coverageLevelCd: string // RV
    marketCode: string // WEB
    associateCount: number
    membership: MembershipInfo
    payment: MemberLookupPayment
    address: MemberLookupAddress
    customers: MemberLookupCustomer[]
    autorenewals: { status: string }
    balances: MemberLookupBalance[]
  }

  export class AccountInfo implements AccountDetails {
    includedPrimaryOffers: AccountIncludedPrimaryOffers
    includedAssociateOffers: AccountIncludedAssociateOffers[]
    address: AccountAddress
    associateCount: number
    associates: MembershipAssociateDetails[]
    autoRenew: boolean | null
    balance: number | null
    cardNumber: string
    code: {
      label: string
      level: MzpMembershipLevel
      rv: boolean
      membershipType: string
      duesComponentCode: string
    } | null
    email: string
    expireDate: string | null
    firstName: string
    lastName: string
    memberNumber: string
    middleInitial: string
    nameSuffix: string
    phone: AccountPhone
    status: AccountStatus
    paymentSummary: AccountDetailsPaymentSummary[]
    availableOffers: MembershipOfferItem[]

    constructor(memberInfo: MemberInfo, offers?: MembershipLevelOffer | null) {
      const { address, membership, payment, associateCount } = memberInfo
      const primaryCustomerInfo = getMzpPrimaryCustomerInfo(memberInfo)
      const primaryMembershipInfo = getMzpMembershipPrimary(memberInfo)
      const membershipListInfo = getMzpPrimaryMemberListInfo(membership.memberList)

      // Todo: need to move to selector
      this.autoRenew = membershipListInfo.renewMethodCd === "A"
      this.balance = getMzpBalanceTotal(memberInfo)
      this.cardNumber = payment.cardNumber || ""
      this.code = getMzpMembershipCode(memberInfo.membership)
      this.expireDate = utcToZonedTime(membershipListInfo.memberExpirationDt, "UTC").toISOString()
      this.status = getMembershipStatus(membership.status)
      this.includedPrimaryOffers = {
        primary: {
          offering: "primary",
          selectedByDefault: true,
          amount: this.balance,
          code: this.code?.duesComponentCode || "",
          description: this.code?.label || "",
        },
        primaryOptional: [],
      }
      this.address = {
        street1: address.addressLine1,
        street2: address.addressLine2,
        state: address.state,
        city: address.city,
        zip: address.postalCode,
      }
      this.associates = getMzpMembershipAssociates(memberInfo)
      this.includedAssociateOffers = []
      this.associateCount = associateCount
      this.email = (primaryCustomerInfo?.customerEmailList || [])[0]?.email || ""
      this.firstName = primaryCustomerInfo?.firstName || ""
      this.lastName = primaryCustomerInfo?.lastName || ""
      this.middleInitial = primaryCustomerInfo?.middleName || ""
      this.memberNumber = primaryMembershipInfo?.membershipNumber || ""
      this.nameSuffix = primaryMembershipInfo?.nameSuffix || ""
      this.phone = {
        cell: getMzpCustomerPhone(primaryCustomerInfo, "CELL"),
        home: getMzpCustomerPhone(primaryCustomerInfo, "HOME"),
        business: getMzpCustomerPhone(primaryCustomerInfo, "WORK"),
      }
      this.paymentSummary = this.getPaymentSummary(memberInfo)
      // Todo: need to implement
      if (offers && this.code?.duesComponentCode) {
        this.availableOffers = offers[this.code?.membershipType]
      } else {
        this.availableOffers = []
      }
    }

    getPaymentSummary(memberInfo: MemberInfo) {
      const paymentSummary: AccountDetailsPaymentSummary[] = memberInfo.balances
        .filter((balance) => balance.type == "Member")
        .map((balance) => {
          const member = getMzpMemberListInfoById(balance.key, memberInfo.membership.memberList)
          const label =
            member.memberTypeCd === "P"
              ? `AAA ${this.code?.label} Membership`
              : `${this.code?.label} Associate Membership`

          return { label, value: balance.amount }
        })

      return paymentSummary
    }
  }

  export class ConfirmedMember {
    membershipCardData: MembershipCardData[] = []
    constructor(payload: MemberJoinInfo) {
      const membershipPrimary = payload.membership.memberList.find((item) => item.memberTypeCd == "P")
      const membershipAssociates = payload.membership.memberList.filter((item) => item.memberTypeCd == "A")
      const customerPrimary = payload.customers.find((item) => item.customerId === membershipPrimary?.customerId)
      const memberExpirationDate = membershipPrimary?.memberExpirationDt
        ? utcToZonedTime(membershipPrimary?.memberExpirationDt, "UTC")
        : ""
      const expirationDate = memberExpirationDate ? format(memberExpirationDate, "MM/dd/yyyy") : ""
      const membershipLabel = getMzpMembershipCode(payload.membership)?.label || ""

      this.membershipCardData.push(
        {
          expirationDate,
          membershipLabel,
          fullName: customerPrimary?.fullName || "",
          membershipNumber: membershipPrimary
            ? String("438") +
              String(environment.clubId) +
              String(membershipPrimary.membershipId) +
              String(membershipPrimary.associateId) +
              String(membershipPrimary?.checkDigitNr || 0)
            : "",
        },
        ...membershipAssociates.map((associate) => {
          const customer = payload.customers.find((item) => item.customerId === associate?.customerId)

          return {
            expirationDate,
            membershipLabel,
            fullName: customer?.fullName || "",
            membershipNumber: membershipPrimary
              ? String("438") +
                String(environment.clubId) +
                String(associate.membershipId) +
                String(associate.associateId) +
                String(associate?.checkDigitNr || 0)
              : "",
          }
        }),
      )
    }
  }
}
