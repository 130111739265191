<ng-template #bannerTitle>
  {{ title }}
</ng-template>
<nz-card
  *ngIf="mapLoaded"
  [nzTitle]="bannerTitle"
  nzBorderless
  class="blue-banner"
  style="width: 100%"
  [nzBodyStyle]="{'display': 'grid', 'grid-gap': '20px', 'padding': '10px 30px'}"
>
  <form
    #container
    nz-form
    nzLayout="vertical"
    [formGroup]="locationInfoForm"
    (ngSubmit)="formSubmit(locationInfoForm)"
  >
    <div
      style="display: grid; grid-gap: 0 24px; grid-auto-flow: column; grid-auto-rows: minmax(500px, auto)"
      [style.grid-template-rows]="gridTemplateRows2x2"
      [style.grid-template-columns]="gridTemplateColumns"
    >
      <nz-form-item>
        <nz-form-label>
          Location Name
        </nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            formControlName="name"
            placeholder="enter a name for this alert here"
            type="text"
            (ngModelChange)="ngModelChangeName($event, validating)"
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>
          Types of Alerts
        </nz-form-label>
        <div style="display: grid; grid-template-columns: repeat(2, 50%)">
          <ng-container *ngFor="let category of categories.controls; let index = index">
            <nz-form-control formArrayName="categories">
              <label
                nz-checkbox
                style="display: flex; margin: 15px 0"
                [formControlName]="index"
              >
                {{ categoryList[index] | titlecase }}
              </label>
            </nz-form-control>
          </ng-container>
        </div>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>
          ZIP Code
        </nz-form-label>
        <nz-form-control
          nzHasFeedback
          nzErrorTip="Please input a valid 5 digit zip code."
        >
          <input
            nz-input
            formControlName="postal"
            autocomplete="postal"
            placeholder="enter a ZIP code here"
            type="text"
            (ngModelChange)="ngModelChangePostal($event, validating)"
          />
        </nz-form-control>
      </nz-form-item>

      <div style="max-width: 400px">
        <google-map
          id="googlemap"
          width="100%" height="350px"
          style="max-width: 400px"
          [center]="center"
          [options]="mapOptions"
        >
          <map-rectangle
            [bounds]="postalBounds"
            [options]="postalOptions"
          ></map-rectangle>
        </google-map>
      </div>
    </div>

    <div
      style="display: grid; grid-gap: 0 24px; grid-auto-flow: column; grid-auto-rows: minmax(500px, auto)"
      [ngStyle]="{'grid-template-columns': gridTemplateColumns, 'grid-template-rows': gridTemplateRows1x2}"
    >
      <div>
        <nz-form-label>
          Phone Number
        </nz-form-label>
        <nz-form-item
          *ngFor="let control of phones.controls; let index = index; let first = first"
          style="display: grid; gap: 10px; grid-template-columns: auto min-content; align-items: flex-start"
        >
          <nz-form-control
            formArrayName="phones"
            nzErrorTip="Please input phone number or delete this field."
            nzHasFeedback
          >
            <input
              nz-input
              autocomplete="tel"
              placeholder="enter your phone number here"
              [formControlName]="index"
              (ngModelChange)="ngModelChangePhone($event, validating, index)"
            />
            <!--            <i-->
            <!--              nz-icon nzType="minus-circle-o"-->
            <!--              [style]="'cursor: pointer; position: absolute; top: 50%; right: 24px; width: 38px; height: 20px; margin-top: -10px; font-size: 18px; line-height: 20px; color: #999'"-->
            <!--              (click)="removePhone(index)"-->
            <!--            ></i>-->
          </nz-form-control>
          <button nz-button nzType="primary" (click)="removePhone(index)">
            delete
          </button>
        </nz-form-item>

        <nz-form-item *ngIf="phones.controls.length < 5">
          <nz-form-control>
            <button
              nz-button nzType="dashed"
              (click)="addPhone(''); $event.preventDefault()"
            >
              <i nz-icon nzType="plus-circle-o"></i>
              Add phone
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div>
        <nz-form-label>
          Email Address
        </nz-form-label>
        <nz-form-item
          *ngFor="let control of emails.controls; let index = index; let first = first"
          style="display: grid; gap: 10px; grid-template-columns: auto min-content; align-items: flex-start"
        >
          <nz-form-control
            [formArrayName]="'emails'"
            nzErrorTip="Please input email address or delete this field."
            nzHasFeedback
          >
            <input
              nz-input
              required
              autocomplete="mail"
              placeholder="enter your email address here"
              [formControlName]="index"
              (ngModelChange)="ngModelChangeEmail($event, validating, index)"
            />
            <!--
                        <i
                          nz-icon
                          [style]="'cursor: pointer; position: absolute; top: 50%; right: 24px; width: 38px; height: 20px; margin-top: -10px; font-size: 18px; line-height: 20px; color: #999'"
                          (click)="removeEmail(index)"
                        >
                          <svg
                            viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em"
                            data-icon="minus-circle-o" aria-hidden="true"
                          >
                            <path
                              d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
                            ></path>
                            <path
                              d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                            ></path>
                          </svg>
                        </i>
            -->
          </nz-form-control>
          <button nz-button nzType="primary" (click)="removeEmail(index)">
            delete
          </button>
        </nz-form-item>

        <nz-form-item *ngIf="emails.controls.length < 1">
          <nz-form-control>
            <button
              nz-button nzType="dashed"
              (click)="addEmail(''); $event.preventDefault()"
            >
              <i nz-icon nzType="plus"></i>
              Add Email Address
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      *ngIf="swsSmsStateValue"
      style="display: flex; justify-content: center; margin: 30px 0"
    >
      <div
        *ngIf="swsSmsStateValue === 'location' || swsSmsStateValue === 'cloudSync'"
      >
        <button
          *ngIf="swsSmsStateValue !== 'cloudSync'"
          nz-button nzType="primary" type="submit"
          style="width: 120px; margin: 10px"
          (click)="eventType = 'SAVE'"
          [disabled]="this.locationInfoForm.invalid && validating"
        >
          Save
        </button>
        <button
          nz-button nzType="primary"
          style="width: 120px; margin: 10px"
          *ngIf="swsSmsStateValue === 'cloudSync'"
        >
          Saving
        </button>
        <button
          nz-button nzType="primary"
          style="width: 120px; margin: 10px"
          (click)="eventType = 'CANCEL'"
          *ngIf="swsSmsStateValue !== 'cloudSync'"
        >
          Cancel
        </button>
      </div>
      <div *ngIf="swsSmsStateValue === 'cloudSyncError'">
        <button
          nz-button nzType="primary"
          style="width: 120px; margin: 10px"
          (click)="eventType = 'RETRY'"
        >
          Retry
        </button>
        <button
          nz-button nzType="primary"
          style="width: 120px; margin: 10px"
          (click)="eventType = 'RELOAD'"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</nz-card>
